
import {
	TRACKING_CATEGORY_SPORT_CARD_SPORT_SELECTOR,
    TRACKING_CATEGORY_GRSSPORT_HOME_SPORT_SELECTOR,
} from '../../common/constants/Racing';
import {trackGaEvent} from '../trackingPixels/trackingActions';

/**
 * Send GA tracking for filtering and click on next to jump sports carousel
 * @param {string} action - ['Click'|'Filter']
 * @param {string} type - GRS Sport type
 * @param {boolean} isGRSSportHome - meant to choose 'category'
 * @return {object} action
 */
export const trackNextToJump = (action, type, isGRSSportHome) => {
	let category = getCategory(
		isGRSSportHome,
		TRACKING_CATEGORY_GRSSPORT_HOME_SPORT_SELECTOR,
		TRACKING_CATEGORY_SPORT_CARD_SPORT_SELECTOR
	);

	return trackGaEvent(category, action, type || 'Sports');
};

/**
 * Local function to choose which category to use based which page the client is at.
 * @param isGRSSportHome
 * @param homeCategory
 * @param sportCategory
 * @return {*}
 */
const getCategory = (isGRSSportHome, homeCategory, sportCategory) => {
	return isGRSSportHome ? homeCategory : sportCategory;
};