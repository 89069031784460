// Manual translation strings for Polish (PL)
export default {
	// Common & general definitions
	'1st Up': 'Pierwszy w górę',
	'2nd Up': 'Drugi w górę',
	'Non Wet': 'nie mokry',
	'Track Distance': 'Długość toru',
	Age: 'Wiek',
	BetSlip: 'Kupon',
	Career: 'Opiekun',
	Dam: 'Matka',
	Date: 'Data',
	Distance: 'Dystans',
	DOB: 'Data urodzenia',
	Firm: 'Mocny',
	fixed: 'stały',
	Fixed: 'Stały',
	Thoroughbred: 'Galop',
	THOROUGHBRED: 'GALOP',
	Good: 'Dobry',
	Greyhounds: 'Charty',
	GREYHOUNDS: 'CHARTY',
	Harness: 'Uprząż',
	HARNESS: 'UPRZĄŻ',
	Heavy: 'Ciężki',
	Night: 'Noc',
	Owners: 'Właściciel',
	place: 'miejsce',
	Place: 'Miejsce',
	race: 'wyścig',
	Race: 'Wyścig',
	Results: 'Wyniki',
	Season: 'Sezon',
	Sex: 'Płeć',
	Sire: 'Ojciec',
	Soft: 'Miękki',
	Synthetic: 'Syntetyczny',
	Track: 'Tor',
	Wet: 'Mokry',
	win: 'wygrana',
	Win: 'Wygrana',

	// Component definitions
	'Racing__Status--abandoned': 'Opuszczony',
	'Racing__Status--closed': 'Zamknięty',
	'Racing__Status--interim': 'Tymczasowy',
	'Racing__Status--paid': 'Zapłacony',
	'Racing__Status--selling': 'Sprzedawanie',
	'SpeedmapLegend__Value--Backmarker': 'Backmarker',
	'SpeedmapLegend__Value--Leader': 'Lider',
	'SpeedmapLegend__Value--Midfield': 'Środek toru',
	'SpeedmapLegend__Value--Off-pace': 'Off-pace',
	MeetingList__NextRace: 'Następny wyścig',
	RaceCard__EarlySpeed: 'Wczesna prędkość',
	RaceCard__Form: 'Formy',
	RaceCard__Speedmaps: 'Mapa prędkości',
	RaceSelectionsList__Flucs: 'Fluksy',
	RacingDateSelection__Tomorrow: 'Jutro',
	RacingDateSelection__Yesterday: 'Wczoraj',
	RacingHomePage__Next: 'Następny',
	RacingHomePage__Racing: 'Wyścigi',
	SpeedmapHeader__Barrier: 'Bariera',
	SpeedmapHeader__Lead: 'Prowadzenie',
};
