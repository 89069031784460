import React from 'react';
import styled, { css } from 'react-emotion';
import { connect } from 'react-redux';
import { RACING_THOROUGHBRED_CODE, RACING_TYPES_LOOKUP } from '../../../common/constants/Racing';
import { brand, Icon, Text } from '@tbh/ui-kit';
import { BREAKPOINTS, COLORS, DEFAULT_COLORS } from '../../../constants/themes';
import { openLoginForm } from '../../../store/authentication/authenticationActions';
import { navigateToRacingHome } from '../../../store/GRSracingHome/GRSracingHomeActions';
import { serverDateTime } from '../../../legacy/core/format';
import moment from 'moment';
import { closeMultiBetSlip, openMultiBetSlip, routeTo } from '../../../store/application/applicationActions';
import { USER_ACCOUNT_V2_BASE_URL } from '../../../pages/UserAccountV2/UserAccountPageConstants';
import { RACING_NEW_SUB_APP } from '../../../pages/GRSNewRacing/RacingNewConstrants';
import { PLACE_MULTI_BET } from '../../../store/betPlacement/betPlacementReducerNames';
import { NEXT_TO_JUMP_APP, NEXT_TO_JUMP_URL } from '../../../pages/NextToJump/NextToJumpConstrants';

const FooterNavigationContainer = styled.div(
	(props) => css`
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-evenly;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: ${brand(props).tertiary_active_color
			? brand(props).tertiary_active_color
			: DEFAULT_COLORS.df_tertiary_active_color};
		border-top: 1px solid #cacaca;
		z-index: 30000;
		width: 100%;
		height: calc(64px + env(safe-area-inset-bottom));
		padding: 0px 8px;
		padding-bottom: env(safe-area-inset-bottom);
		gap: 12px;
		@media (min-width: ${BREAKPOINTS.laptopMin}px) {
			display: none;
		}
	`,
);

export const FooterItem = styled.div(
	(props) => css`
		transition: background-color 0.75s ease;
		padding: 6px 4px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 4px;
		flex: 1;
		cursor: pointer;
		border-radius: 8px;
		width: calc(100% / 5);
		&:focus {
			background-color: #666666;
		}
		${props.active &&
			css`
				background-color: ${brand(props).color_1_dark};
			`}
	`,
);

const FooterNavigation = ({
	isAuthenticated,
	onWelcome,
	handleRacing,
	goToUserPage,
	onJoinLogin,
	page,
	count_bets,
	onBetSlipClick,
	isMultiBetSlipOpen,
	goToNextToJump,
}) => {
	return (
		<FooterNavigationContainer>
			{/* <FooterItem onClick={onWelcome} active={page === WELCOME_SUB_APP}>
				<Icon icon="home" size="1" style={{ color: 'white' }} />
				<Text tag="span" type="alternate" size="-3" strong align="center" style={{}}>
					Home
				</Text>
			</FooterItem> */}
			<FooterItem onClick={goToNextToJump} active={page === NEXT_TO_JUMP_APP}>
				<Icon icon="clock" size="1" style={{ color: 'white' }} />
				<Text tag="span" type="alternate" size="-3" strong align="center" style={{}}>
					Next Up
				</Text>
			</FooterItem>
			<FooterItem onClick={handleRacing} active={page === RACING_NEW_SUB_APP}>
				<Icon icon={RACING_TYPES_LOOKUP[RACING_THOROUGHBRED_CODE]} size="1" style={{ color: 'white' }} />
				<Text tag="span" type="alternate" size="-3" strong align="center" style={{}}>
					Racing
				</Text>
			</FooterItem>
			{/* <FooterItem
				onClick={(e) => {
					e.preventDefault();
					onBetSlipClick(isMultiBetSlipOpen);
				}}
			>
				<PrimaryButton modifiers="nav" style={{ padding: '12px' }}>
					<Text tag="span" type="alternate" size="-2" strong align="center" style={{}}>
						{count_bets > 0 ? 'Bets ' : 'Bets '} {count_bets > 0 ? count_bets : null}
					</Text>
				</PrimaryButton>
			</FooterItem> */}

			{/* <FooterItem
				onClick={isAuthenticated ? goToUserPage : onJoinLogin}
				active={page === USER_ACCOUNT_V2_SUB_APP || page === DEACTIVATE_ACCOUNT_APP}
			>
				<Icon icon="user" size="1" style={{ color: 'white' }} />
				<Text tag="span" type="alternate" size="-3" strong align="center" style={{}}>
					{isAuthenticated ? 'Account' : 'Join'}
				</Text>
			</FooterItem> */}
		</FooterNavigationContainer>
	);
};

const mapStateToProps = (state) => {
	return {
		count_bets:
			state[PLACE_MULTI_BET].selections.length +
			state[PLACE_MULTI_BET].SRMs.length +
			state[PLACE_MULTI_BET].quaddies.length +
			state[PLACE_MULTI_BET].exotics.length,
		page: state.application.selectedPage,
		isAuthenticated: state.application.authenticatedUser,
		isMultiBetSlipOpen: state.application.showMultiBetSlip,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onWelcome: () => {
			return dispatch(routeTo('/'));
		},
		goToNextToJump: () => {
			return dispatch(routeTo(NEXT_TO_JUMP_URL));
		},
		handleRacing: () => {
			dispatch(navigateToRacingHome(serverDateTime(moment())));
		},
		goToUserPage: () => {
			return dispatch(routeTo(`${USER_ACCOUNT_V2_BASE_URL}/overview`));
		},
		onJoinLogin: () => {
			return dispatch(openLoginForm());
		},
		onBetSlipClick: (isMultiBetSlipOpen) =>
			isMultiBetSlipOpen ? dispatch(closeMultiBetSlip()) : dispatch(openMultiBetSlip()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterNavigation);
