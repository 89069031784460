import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings } from '@tbh/ui-kit';

import { Input } from '@tbh/ui-kit';
import { Button } from '@tbh/ui-kit';

const StyledWithButton__Button = styled(Button)`
	${(props) => css`
		label: WithButton__Button;

		flex: 0 1 auto;
		margin-left: ${spacings(props).compact}px;
	`};
`;

const WithButton = (props) => {
	const { className, inputName, value, displayFormat, placeHolder, size, onChange, button, customInput } = props;

	const componentClasses = cx(
		css`
			label: WithButton;

			display: flex;
			align-items: flex-end;
		`,
		{
			[className]: className,
		},
	);

	let displayValue = displayFormat(value);

	let input = customInput ? (
		customInput(props, displayValue)
	) : (
		<Input
			className={css`
				flex: 2;
				align-self: flex-end;
			`}
			name={inputName}
			value={displayValue ? displayValue.toString() : ''}
			size={size}
			onChange={onChange}
			placeholder={placeHolder}
		/>
	);

	return (
		<div className={componentClasses}>
			{input}

			{button && (
				<StyledWithButton__Button
					disabled={button.isDisabled}
					type={button.type ? button.type : null}
					data={button.data}
					action={button.action}
					size={size}
				>
					{button.label}
				</StyledWithButton__Button>
			)}
		</div>
	);
};

WithButton.propTypes = {
	/** Name to give the input element */
	inputName: PropTypes.string,

	/** Value for the input element */
	value: PropTypes.string,

	/** Placeholder text for the input element */
	placeHolder: PropTypes.string,

	/**
	 * Size of the component inline with the sizing of our buttons and input components
	 *  ie. small, medium, large (default: medium)
	 */
	size: PropTypes.string,

	/** Function to call when the input field changes */
	onChange: PropTypes.func,

	/** An object containing the properties of the left button */
	button: PropTypes.shape({
		label: PropTypes.string,
		type: PropTypes.string,
		action: PropTypes.func,
		data: PropTypes.any,
		isDisabled: PropTypes.bool,
	}),

	/** Function that will pass props to a custom component */
	customInput: PropTypes.func,

	/** Function used for formatting the display of the value */
	displayFormat: PropTypes.func,

	/** Extra style class(es) to pass through */
	className: PropTypes.string,
};

WithButton.defaultProps = {
	inputName: null,
	value: '',
	placeHolder: null,
	size: 'medium',
	onChange: undefined,
	button: null,
	customInput: null,
	displayFormat: (value) => value,
	className: null,
};

export default WithButton;
