import { removeSRM } from './betPlacementActions';
import { PLACE_MULTI_BET } from './betPlacementReducerNames';

/**
 * Remove a SRM from the multi state
 *
 * @param reducerName
 * @param srmId
 * @private
 */
export const deleteRM = (srmId) => (dispatch, getState) => {
    dispatch(removeSRM(PLACE_MULTI_BET, srmId));
};



export const validatePrice = (srmId) => (dispatch, getState) => {
    const state = getState();
};
