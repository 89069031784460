import {UPDATE_DEPOSIT_LIMIT} from './depositLimitsActionsTypes';
import {CLEAR_AUTHENTICATED_USER} from '../application/applicationActionTypes';
import {UPDATE_ALL_LIMITS} from '../limitsActionsTypes';

const initialState = {
	daily_deposit_limit: {},
	weekly_deposit_limit: {},
	monthly_deposit_limit: {}
};

const depositLimits = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_DEPOSIT_LIMIT:
			return {
				...state,
				...action.payload
			};

		case UPDATE_ALL_LIMITS:
			return {
				...state,
				...action.payload.depositLimits
			};

		case CLEAR_AUTHENTICATED_USER:
			return {
				...initialState
			};

		default:
			return state;
	}
};

export default depositLimits;