export const MAX_WIDTH = 1170;
export const MIN_WIDTH = 320;
export const BET_SLIP_WIDTH = 320;

/**
 * MEDIA QUERIES
 */
export const SIZE_MOBILE = 620;
export const SIZE_TABLET = 780;
export const SIZE_DESKTOP = 1024;

/**
 RACING CONSTANTS
 */
export const RACING_APPLICATION_STYLE__RACES_ROW_HEIGHT = 36;
export const RACING_APPLICATION_STYLE__NEXT_JUMP_HEIGHT = 69;
export const RACING_APPLICATION_STYLE__NEXT_JUMP_ITEM_WIDTH = 140;
export const RACING_APPLICATION_STYLE__ODDS_BUTTON_WIDTH = 72;
export const RACING_APPLICATION_STYLE__ODDS_BUTTON_WIDTH_SMALL = 52;
export const RACING_APPLICATION_STYLE__FLUCS__WIDTH = 60;
export const RACING_APPLICATION_STYLE__BUTTON_WIDTH = 55;
export const RACING_APPLICATION_STYLE__BUTTON_WIDTH_EXPANDED = 90;

/**
 HEIGHT: used for form elements
 */
export const FORM_HEIGHT_SMALL = 23;
export const FORM_HEIGHT_MEDIUM = 32;
export const FORM_HEIGHT_LARGE = 56;

/**
 OTHER
 */
export const BORDER_RADIUS_1 = 2;
export const BORDER_RADIUS_2 = 4;
export const BORDER_RADIUS_3 = 12;
export const BOX_SHADOW = '0 2px 1px 0 rgba(0, 0, 0, 0.08)';
export const TEXT_SHADOW = '1px 1px 1px rgba(0, 0, 0, 0.3)';
export const NUMBER_PAD_BUTTON_SIZE = 75;
