/**
 * Created by Jason Kinnear on 31/1/17.
 */

const { combineReducers } = require('redux');

// Reducers
const activeTournament = require('./activeTournament/activeTournamentReducer');
const application = require('./application/applicationReducer');
const entities = require('./entities/entities');
const favourites = require('./favourites/favouritesReducer');
const sportsHome = require('./sportsHome/sportsHomeReducer');
import grsRacingHome from './GRSracingHome/GRSracingHomeReducer';
const tournamentsHome = require('./tournamentsHome/tournamentsHomeReducer');
const trackingPixels = require('./trackingPixels/trackingPixelsReducer');
const betPrompt = require('./betPrompt/betPromptReducer').default;
const betExport = require('./betExport/betExportReduce').default;
const { localStorageReducer } = require('../common/reducers/LocalStorageReducer');
import createNamedReducer from '../common/reducers/createNamedReducer';
import depositLimits from './depositLimits/depositLimitsReducer';
import lossLimits from './lossLimits/lossLimitsReducer';
import spendLimits from './spendLimits/spendLimitsReducer';
import defaultBetAmounts from './defaultBetAmounts/defaultBetAmountsReducer';
import deposits from './deposits/depositsReducer';
import featureToggles from './featureToggles/featureTogglesReducer';
import registration from './registration/registrationReducer';
import acl from './acl/aclReducer';
import modal from './modal/modalReducer';
import userAccount from './userAccount/userAccountReducer';
import betPlacement from './betPlacement/betPlacementReducer';
import dataPaginator from './dataPaginator/dataPaginatorReducer';
import withdrawalPrompt from './withdrawalPrompt/withdrawalPromptReducer';
import passwordResetPage from './passwordReset/passwordResetReducer';
import promotionsPage from './promotionsPage/promotionsPageReducer';
import theme from './theme/themeReducer';
import nextJumpHome from './nextJumpHome/NextJumpHomeReducer'; // @HW
import sportsNextJumpHome from './sportsNextJumpHome/SportsNextJumpHomeReducer'; //@HW20Jan2020
import grsSportsHome from './grsSportsHome/grsSportsHomeReducer'; // @HW24Jan2020
import betBuilderHome from './betBuilder/betBuilderReducer'; // @HW04JULY2020
// import featureRacingHome from './featureRacingHome/featureRacingHomeReducer';
import SportCricketHome from './sport_cricket/sport_cricketPageReducer';
import sideBarData from './SideBar/SideBarReducer';
import verifications from './verifications/VerificationsReducer';
import realityHoursReducer from './testReality/testReality';
import sessionTimeReducer from './setSessionTime/setSessionTime';
// Constants
import { PLACE_SINGLE_BET, PLACE_MULTI_BET } from './betPlacement/betPlacementReducerNames';
import { DATA_PAGINATOR_DEFAULT, DATA_PAGINATOR_USER_TRANSACTIONS } from './dataPaginator/dataPaginatorReducerNames';
import { DEPOSIT_DEFAULT, DEPOSIT_USER_ACCOUNT, DEPOSIT_VERIFY_CREDIT_CARD } from './deposits/depositsReducerNames';
import newNextToJumpRaces from './newNextToJumpRaces/newNextToJumpRacesReducer';
import legalRules from './legalRules/legalRulesReducer';

export default {
	acl,
	sessionTimeReducer,
	realityHoursReducer,
	activeTournament,
	application,
	betPrompt,
	depositLimits,
	[DEPOSIT_DEFAULT]: createNamedReducer(DEPOSIT_DEFAULT, deposits),
	[DEPOSIT_USER_ACCOUNT]: createNamedReducer(DEPOSIT_USER_ACCOUNT, deposits),
	[DEPOSIT_VERIFY_CREDIT_CARD]: createNamedReducer(DEPOSIT_VERIFY_CREDIT_CARD, deposits),
	lossLimits,
	modal,
	spendLimits,
	defaultBetAmounts,
	entities,
	favourites: localStorageReducer('favourites', favourites),
	featureToggles,
	sportsHome,
	registration,
	theme,
	tournamentsHome,
	trackingPixels,
	[PLACE_SINGLE_BET]: createNamedReducer(PLACE_SINGLE_BET, betPlacement),
	[PLACE_MULTI_BET]: localStorageReducer(PLACE_MULTI_BET, createNamedReducer(PLACE_MULTI_BET, betPlacement)),
	[DATA_PAGINATOR_DEFAULT]: createNamedReducer(DATA_PAGINATOR_DEFAULT, dataPaginator),
	[DATA_PAGINATOR_USER_TRANSACTIONS]: createNamedReducer(DATA_PAGINATOR_USER_TRANSACTIONS, dataPaginator),
	userAccount,
	withdrawalPrompt,
	promotionsPage,
	passwordResetPage,
	grsRacingHome,
	nextJumpHome,
	sportsNextJumpHome,
	grsSportsHome,
	betBuilderHome, // @HW25July2020
	// featureRacingHome,
	SportCricketHome,
	sideBarData, // @HW29april2022
	newNextToJumpRaces, // @HW30July2022
	verifications,
	betExport,
	legalRules,
};
