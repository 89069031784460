import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, typography, ui } from '@tbh/ui-kit';

import { Text, CollapsibleContent, CollapsibleIcon, HeaderBar, Input } from '@tbh/ui-kit';
import CurrencyInput from '../../Application/Currency/CurrencyInput/CurrencyInput';
// import BetSelection from '../BetSelection/BetSelection';
import BetAmountAndPayout from '../BetAmountAndPayout/BetAmountAndPayout';
import {
	getExoticSelectionType,
	getExoticSelectionsFromBetPrompt,
} from '../../../../store/entities/selectors/BetSelectors';
import { BET_TYPE_EXOTIC, BET_TYPE_GROUPS_EXOTIC_OBJECT } from '../../../../store/entities/constants/BetConstants';
import { StyledBetSelectionMulti__Clear, StyledBetSelectionMulti__ClearIcon } from '../MultiSRMList/MultiSRMItem';
import BetPositionDetail from '../BetSelection/BetPositionDetail/BetPositionDetail';
import numeral from 'numeral';
import BetNotificationEventClosed from '../BetNotification/BetNotificationEventClosed';

const StyledExoticBetList__HeaderCloseIcon = styled(CollapsibleIcon)(
	(props) => css`
		label: ExoticBetList__HeaderCloseIcon;

		min-width: ${spacings(props).comfortable}px;
	`,
);

const StyledExoticBetList__BulkBetInput = styled(CurrencyInput)(
	(props) => css`
		label: ExoticBetList__BulkBetInput;

		max-width: 150px;

		${Input.StyledInput__Input} {
			color: ${typography(props).color_1_alternate};
		}
	`,
);

const StyledExoticList = styled('div')(
	(props) => css`
		label: ExoticList;

		padding: 4px;
		display: flex;
		flex-direction: column;
		gap: ${spacings(props).compact}px;
	`,
);

const cssExoticBetList__Disabled = css`
	label: ExoticBetList__Disabled;

	opacity: 0.5;
	pointer-events: none;
`;

const StyledBetSelectionExotic__SelectionType = styled('div')(
	(props) => css`
		label: BetSelectionExotic__SelectionType;

		text-transform: capitalize;
		font-size: ${typography(props)['size_-2']};
		color: ${ui(props).color_success};
	`,
);

const ExoticBetList = (props) => {
	const {
		t,
		className,
		disabled,
		onStakeChange,
		handleFlexiChange,
		onRemove,
		handleSelectedCupomBet,
		exotics,
		handleBonusBetChange,
		bonus_bet_enabled,
		selectedBet,
		handleSelectedBet,
		races,
	} = props;

	const componentClasses = cx(disabled && cssExoticBetList__Disabled, {
		[className]: className,
	});

	const title = t('Exotics');

	const betSelectionExoticProps = ({
		id,
		exoticDetails,
		selections,
		stake,
		flexiAmount,
		amount,
		free_credit_flag,
		combinations,
		race_id,
	} = exotic) => {
		return {
			id,
			race_id,
			isRacing: true,
			isExotic: true,
			exoticType: exoticDetails.id,
			betTypeLabel: exoticDetails.isBoxed ? t('BetPromptContainer__AnyOrder') : t('BetPromptContainer__ExactOrder'),
			eventName: `${exoticDetails.meeting_name} ${t('Race')} #${exoticDetails.number}`,
			boxed: exoticDetails.isBoxed,
			price: 100,
			exoticSelectionType: getExoticSelectionType({ exoticDetails, selections }),
			exoticSelections: getExoticSelectionsFromBetPrompt({ exoticDetails, selections }),
			selectedProductIds: [exoticDetails.productId],
			stake,
			flexiAmount,
			amount,
			free_credit_flag,
			combinations,
			isClosed:races.find((r) => r.id == race_id && r.status != 'selling')
		};
	};

	const handleCollapse = () => {
		setIsCollapsed(!isCollapsed);
	};

	const [isCollapsed, setIsCollapsed] = React.useState(false);

	const betSelections = exotics.map((e) => betSelectionExoticProps(e));

	const positionLabel = (selection, exoticType) => {
		// switch (exoticType) {
		// 	case 'quaddie':
		// 		return `Leg ${selection.label}`;
		// 	case 'dailydouble':
		// 		return `Leg ${selection.label}`;
		// 	case 'quinella':
		// 		return numeral(selection.label).format('0o');
		// 	default:
		// 		return numeral(selection.label).format('0o');
		// }
		return numeral(selection.label).format('0o');
	};

	const onBetClick = (s) => {
		let data = null;

		if (selectedBet && selectedBet.id == s.id && selectedBet.group == 'exotics') return;

		if (!selectedBet || (selectedBet.id != s.id && selectedBet.group != 'exotics')) {
			data = {
				...s,
				group: 'exotics',
			};
		}

		handleSelectedBet(data);
	};

	return (
		<div className={componentClasses}>
			<HeaderBar
				action={handleCollapse}
				type="primary"
				className={css`
					cursor: pointer;
				`}
				nodeRight={<StyledExoticBetList__HeaderCloseIcon size="-3" isCollapsed={isCollapsed} />}
			>
				<Text type="alternate" strong size="-1">
					{title}
				</Text>
			</HeaderBar>
			<CollapsibleContent isCollapsed={isCollapsed}>
				<StyledExoticList>
					{betSelections.map((e) => (
						<StyledExoticItem
							key={e.id}
							onClick={() => onBetClick(e)}
							selectedBet={selectedBet && selectedBet.id == e.id && selectedBet.group == 'exotics'}
						>
							{e.isClosed && (
								<BetNotificationEventClosed onClick={() => onRemove(e)} />
							)}
							<div style={{ opacity: e.isClosed ? 0.3 : 1 }}>
								<div>
									<div
										className={css`
											display: flex;
											justify-content: space-between;
											item-align: center;
										`}
									>
										<Text size="-3" type="secondary">
											{e.eventName}
										</Text>
										<StyledBetSelectionMulti__Clear>
											<StyledBetSelectionMulti__ClearIcon size="-3" icon="close" action={() => onRemove(e)} />
										</StyledBetSelectionMulti__Clear>
									</div>
									<div
										className={css`
											display: flex;
											gap: 4px;
										`}
									>
										<Text size="-2" strong>
											{BET_TYPE_GROUPS_EXOTIC_OBJECT[e.exoticType].title + ' '}
										</Text>
										<StyledBetSelectionExotic__SelectionType>
											{e.exoticSelectionType}
										</StyledBetSelectionExotic__SelectionType>
									</div>
								</div>
								<div>
									{(e.exoticSelectionType === 'standout' || e.exoticSelectionType === 'standard') &&
										e.exoticSelections.map((selection) => (
											<BetPositionDetail
												key={selection.label}
												selection={selection.selection}
												label={positionLabel(selection, e.exoticType)}
												isStandout
											/>
										))}
									<Text size="-4" strong>
										{e.exoticSelectionType === 'boxed' && e.exoticSelections[0].selection + ' (BOXED)'}
									</Text>
								</div>
								<BetAmountAndPayout
									className={css`
										padding: 0;
										flex: 1;
									`}
									bonus_bet_enabled={bonus_bet_enabled}
									handleBonusBetChange={() => handleBonusBetChange(e.id, BET_TYPE_EXOTIC)}
									onInputChange={(name, value) => onStakeChange(e.id, value)}
									onFlexiChange={(name, value) => handleFlexiChange(e.id, value)}
									{...e}
									showFlexiBox={true}
									isMultiLegValid={true}
								/>
							</div>
						</StyledExoticItem>
					))}
				</StyledExoticList>
			</CollapsibleContent>
		</div>
	);
};

const StyledExoticItem = styled('div')(
	(props) => css`
		label: ExoticItem;
		position: relative;
		display: flex;
		gap: 4px;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		padding: ${spacings(props).compact}px;
		border: 2px solid ${ui(props).color_4};
		border-radius: 4px;
		${props.selectedBet && 'border: 1px solid #660809!important;'}
	`,
);

ExoticBetList.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** List of selections */
	selections: PropTypes.array.isRequired,

	user: PropTypes.array.isRequired,

	/** Action to handle the user using the bulk stake input */
	onBulkStakeChange: PropTypes.func.isRequired,

	/** Action if the stake is changed for an individual selection */
	onStakeChange: PropTypes.func.isRequired,

	/** Action to toggle open/closed the selections list */
	onSelectionToggle: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,

	/** Whether to disable the selections or not */
	disabled: PropTypes.bool,

	/** Whether the selections can have their stakes changed */
	editable: PropTypes.bool,

	/** If the selections list is open or closed */
	isCollapsed: PropTypes.bool,

	/** Tracking function for selections */
	track: PropTypes.func,

	/** Action to handle when a bet type changes for a selection */
	onBetTypeChange: PropTypes.func,

	/** Action to handle a selection being clicked on */
	onClickSelection: PropTypes.func,

	/** Function to call when the bet is to be removed from the multi bet slip */
	onRemove: PropTypes.func,

	/** Function for dismissing any error message */
	onDismiss: PropTypes.func,

	/** Function to call when the multi checkbox id toggled */
	onMultiToggle: PropTypes.func,

	defaultBetAmount: PropTypes.number,

	bet: PropTypes.number,
};
ExoticBetList.defaultProps = {
	className: '',
	disabled: false,
	editable: true,
	isCollapsed: false,
	onBetTypeChange: () => {},
	onClickSelection: () => {},
	onRemove: () => {},
	onDismiss: () => {},
	onMultiToggle: () => {},
	track: () => {},
	defaultBetAmount: 0,
	bet: 0,
	// lyabi:'',
};

export default withNamespaces()(ExoticBetList);
