'use strict';

const { MERGE_ENTITIES, REPLACE_ENTITIES } = require('../EntityActionTypes');

const { normalizeTickets } = require('../schemas/TicketSchema');
import { trackGaTransaction } from '../../trackingPixels/trackingActions';
import { fetchAuthenticatedUser } from '../../authentication/authenticationActions';

import { get, post } from '../../../common/Ajax';

import { centsAsDollars } from '../../../legacy/core/format';

/**
 * Fetch the logged in users active tickets
 *
 * @returns {function(*)}
 */
const fetchUserActiveTickets = () => (dispatch) => {
	return get('active-tickets').then((response) => {
		dispatch({
			type: MERGE_ENTITIES,
			entities: normalizeTickets(response.data.data).entities,
		});
	});
};

/**
 * Returns the action for merging a single ticket.
 *
 * @param ticket
 * @returns {Object}
 */
const mergeTicket = (ticket) => {
	return mergeTickets([ticket]);
};

/**
 * Returns the action for merging an array of tickets.
 *
 * @param tickets
 * @returns {Object}
 */
const mergeTickets = (tickets) => {
	return {
		type: MERGE_ENTITIES,
		...normalizeTickets(tickets),
	};
};

/**
 * Purchase tickets into tournaments
 *
 * @param tournament
 * @returns {Promise}
 */
const purchaseTickets = (tournament) => (dispatch) => {
	const tournaments = [tournament.id];

	return post('tickets', { tournaments }).then((response) => {
		dispatch({
			type: REPLACE_ENTITIES,
			entities: normalizeTickets(response.data.data).entities,
		});

		// Update the user account balance
		dispatch(fetchAuthenticatedUser());

		const ticket = response.data.data[0];
		const tournamentCost = tournament.buyin + tournament.entry_fee;

		const id = `${ticket.id}-${ticket.user_id}`;
		const transactionData = {
			revenue: centsAsDollars(tournamentCost),
		};
		const itemsData = [
			{
				name: 'TournamentTicket',
				sku: `${tournament.name}-${ticket.id}`,
				category: tournamentCost > 0 ? 'Buy In' : 'Entry Fee',
				price: centsAsDollars(tournamentCost),
			},
		];

		dispatch(trackGaTransaction(id, transactionData, itemsData));
	});
};

/**
 * Rebuy into a tournament
 *
 * @param ticket
 * @param tournament
 */
const rebuyIntoTournament = (ticket, tournament) => (dispatch) => {
	return post('tournaments/tickets/rebuy', {
		ticket_id: ticket.id,
	}).then((response) => {
		dispatch({
			type: REPLACE_ENTITIES,
			entities: normalizeTickets([response.data.data]).entities,
		});

		const tournamentCost = tournament.rebuy_buyin + tournament.rebuy_entry_fee;

		const id = `${ticket.id}-${ticket.user_id}`;
		const transactionData = {
			revenue: centsAsDollars(tournamentCost),
		};
		const itemsData = [
			{
				name: 'TournamentRebuy',
				sku: `${tournament.name}-${ticket.id}`,
				category: tournamentCost > 0 ? 'Buy In' : 'Entry Fee',
				price: centsAsDollars(tournamentCost),
			},
		];

		dispatch(trackGaTransaction(id, transactionData, itemsData));
	});
};

module.exports = {
	mergeTicket,
	mergeTickets,
	purchaseTickets,
	fetchUserActiveTickets,
	rebuyIntoTournament,
};
