import { createSelector } from 'reselect';
import { denormalizeNextToJumpSports } from '../../../store/entities/schemas/NextToJumpSportSchema';
import { denormalizeEvents } from '../../../store/entities/schemas/EventSchema';
/**
 * Get the Next to Jump Sports
 */
export const getNextToJumpSports = createSelector(
	(state) => state.sportsNextJumpHome.nextToJumpSports,
	(state) => state.entities,
	(ntjSportIds, entities) => {
		// Denormalize > filter events in the future > sort by start time > take first 12.
		return denormalizeNextToJumpSports(entities, ntjSportIds);
	},
);

/**
 * Prepare the Next To Jump Sports data with the market name and sport selections.
 * Return specified number of Next To Jump items.
 *
 * @param state
 * @param total - number of Next To Jump Sports items to return
 */
export const getNextToJumpSportsMarkets = (state, total = 4) => {
	const ntjSports = getNextToJumpSports(state);
	// Get the events for the nextToJumpSports
	const ntjEvents = denormalizeEvents(state.entities, ntjSports.map((sport) => sport.event_id));

	// Add the market name and selections to the Next To Jump Sports objects
	let ntj = ntjSports;
	ntj.map((sport) => {
		const event = ntjEvents.filter((event) => event.id === sport.event_id);
		const markets = event && event.length > 0 && event[0].markets;
		const market = markets && markets.length > 0 ? markets[0] : null;

		sport.marketName = market ? market.name : null;
		sport.sportSelections = market ? market.sportSelections : null;
	});

	// Filter only the next to jump items that have markets
	ntj = ntj.filter((sport) => sport.marketName);

	// Return the amount of Next To Jump Sports items defined
	return ntj.slice(0, total);
};
