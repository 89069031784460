import {
    BETBUILDER_HOME_SET_CONTAINER_LOADING_MASK,
    GRS_SHOWING_NTJ_RACES,
    BETBUILDER_HOME_SET_SHOWING_NTJ_RACES,
    BETBUILDER_HOME_RESET_FILTERS,
    BETBUILDER_HOME_SET_SHOWING_MEETINGS,
    BETBUILDER_HOME_SET_BET_BUILDER_MEETING,
    BETBUILDER_HOM_SET_SELECTED_DATE,
  } from './betBuilderHomeActionType';
  
  const initialState = {
    loadingContainer: undefined,
    showingNTJRaces: [],
    selectedMeeting: null,
    //selectedMeetingType: null,
    showingMeetings: [],
    selectedBuilderMeeting: null,
    selectedDate: undefined,
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {

      case BETBUILDER_HOM_SET_SELECTED_DATE:
        return {
            ...state,
            selectedDate: action.payload,
        };
      case BETBUILDER_HOME_RESET_FILTERS:
			return {
        ...state,
        selectedMeeting: action.meetingId || null,
        
        //selectedMeeting: (action.payload && action.payload.id) || initialState.selectedMeeting,
        //selectedMeetingType: null,
      };

      		// bet builder
		case BETBUILDER_HOME_SET_BET_BUILDER_MEETING:
			return {
				...state,
				selectedBuilderMeeting: action.payload,
			};	
      
     case GRS_SHOWING_NTJ_RACES:
        return {
            ...state,
            showingNTJGRSRaces: action.payload,
        };
      case BETBUILDER_HOME_SET_SHOWING_MEETINGS:
        return {
          ...state,
          showingMeetings: action.payload,
        };
    
      case BETBUILDER_HOME_SET_SHOWING_NTJ_RACES:
              return {
                  ...state,
                  showingNTJRaces: action.payload,
              };
      
      case BETBUILDER_HOME_SET_CONTAINER_LOADING_MASK:
        return{
          ...state,
          loadingContainer: false,
        };
      default:
        return state;
    }
  }