'use strict';
import {ADD_TRACKING_PIXEL} from './trackingPixelsActionTypes';

const initialState = {};

module.exports = (state=initialState, action) => {

	switch (action.type) {
		case ADD_TRACKING_PIXEL:

			if (!action.payload && !action.payload.id) {
				throw new Error('Adding a tracking pixel requires an ID for future references');
			}

			return {
				...state,
				[action.payload.id]: action.payload
			};
	}

	return state;
};