/**
 * Libraries
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SizeMe } from 'react-sizeme';
import { withNamespaces } from 'react-i18next';

/**
 * Functions
 */
import { createErrorBoundary } from '../../../containers/ErrorBoundary/ErrorBoundaryActions';
import {
	resetBetPrompt,
} from '../../../store/betPrompt/betPromptActions';
import { fetchTrendingBets } from '../../../store/entities/actions/TrendingBetActions';


/**
 * Containers
 */

/**
 * Components
 */
import { LoadingSpanner } from '../../../components/features/Application/LoadingSpinner/LoadingSpinner';
/**
 * Schemas
 */
import {
	buildRouteToGRSSports,
} from '../../../routes/GRSSports';


import { fetchActiveBets } from '../../../store/entities/actions/BetActions';
import { isBetReferred } from '../../../containers/Betting/bettingMemoizedSelectors';

/**
 * Styles
 */
import * as GRSSHPS from './GRSSportsHomePage.styled';

class GRSSportsHome extends Component{
    

    static defaultProps = {
		loadingContainer: false,
    };

    state = {
		/** list of events ids with loading mask flag for market groups. */
		eventsLoadingMarkets: {},
		/** Flag whether the bet prompt container is showing */
		showBetPrompt: false,
		/** Flag whether the Quick Deposit container is showing */
		showQuickDepositPrompt: false,
	};

	componentDidMount() {
		if (this.props.authenticatedUser) {
			this.props.fetchActiveBets();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.authenticatedUser && prevProps.authenticatedUser !== this.props.authenticatedUser) {
			this.props.fetchActiveBets();
		}
    }
	static PropTypes = {
        /** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The page meta to be applied */
		pageMeta: PropTypes.shape({
			title: PropTypes.string.isRequired,
			description: PropTypes.string.isRequired,
		}).isRequired,

		/**
		 * The current route based on the current state. Should only be used for updating the route between changing the
		 * selected entities in the sports home slice
		 */
		currentRoute: PropTypes.string.isRequired,

		 /** Loading mask to cover both meetings and races lists. */
		 loadingContainer: PropTypes.bool,
    };

    render() {
        const {
			t,
			bets,
			currentRoute,
			dataFullyLoaded,
			events,
			isSportsHome,
			pageMeta,
			selectedBaseCompetition,
			selectedCompetition,
			selectedSport,
			seoContent,
			useRouting,
			tournamentsEnabled,
			competitionOnly,
			loadingContainer,
        } = this.props;

        // updateRoute = false;
		return (
			<SizeMe>
				{({ size }) => (
					<GRSSHPS.StyledGRSSportsHomePage size={size}>
						<LoadingSpanner style={{display: loadingContainer ? 'block' : 'none'}}/>
						{'GRS Sport Home'}
					</GRSSHPS.StyledGRSSportsHomePage>
                )}
			</SizeMe>
        );
    }
}
const mapStateToProps = (state) => {
	const betReferred = isBetReferred(state);

    return{
		currentRoute: buildRouteToGRSSports(),
		loadingContainer: state.grsSportsHome.loadingContainer,
        betReferred: betReferred,
        betPrompt: state.betPrompt,
        authenticatedUser: state.application.authenticatedUser,
		playerBetsWidgetsEnabled: state.featureToggles.features.playerBetsWidgets.enabled,
		tournamentsEnabled: state.featureToggles.features.tournaments.enabled,

		// We should consider the case where we select an event of unknown sport.
		isSportsHome: !state.sportsHome.selectedSport && !state.sportsHome.selectedEvent,
		loadingEvents: state.sportsHome.loadingEvents,
		seoContent: state.acl.seoContent,
    };
};
const mapDispatchToProps = (dispatch) => ({
    fetchActiveBets: () => dispatch(fetchActiveBets()),
	fetchTrendingBets: () => dispatch(fetchTrendingBets()),
	resetBetPrompt: () => dispatch(resetBetPrompt()),
});
export default withNamespaces()(
	createErrorBoundary(
		connect(
			mapStateToProps,
			mapDispatchToProps,
		)(GRSSportsHome),
	),
);
