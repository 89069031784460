import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import {
	application,
	spacings,
	typography,
	media,
	Button,
	ButtonGroup,
	Text,
	brand,
	// Image,
} from '@tbh/ui-kit';

import {
	// RACING_TYPES_LOOKUP,
	RACING_THOROUGHBRED_CODE,
	RACING_GREYHOUNDS_CODE,
	RACING_HARNESS_CODE,
} from '../../../../common/constants/Racing';
import RacingIcon from '../RacingIcon/RacingIcon';
import { modeChangeOverSize } from '../../../../pages/GRSNewRacing/NewRacingHome/NewRacingHomePage';
import { TABLET_MAX_WIDTH } from '../../../../common/constants/Breakpoints';

const StyledRacingTypeFilterSelectionBlack__Button = styled(Button)(
	(props) => css`
		label: RacingTypeFilterSelectionBlack__Button;

        margin-top: 15px;
        font-weight: 800;
        line-height: 1.5;
        padding: ${spacings(props).compact}px;
		text-align: center;
		// This is to keep all the buttons at the top of the race card the same width
		//min-width: ${application(props).racing.button_width}px;
		max-width:30px;
		padding: 2px;
		height: 30px;
		border-radius: 8px;

		&:hover {
			// This is to override the specificity of the Text component
			${Text} {
				color: ${typography(props).color_1_alternate};
			}
		}

		${props.type === 'secondary' &&
			css`
				&& {
					background: ${brand(props).rch_4};
					border: 1px solid ${brand(props).rch_4};
					background: white !important;
					border: 1px solid white !important;
				}
			`};

       
		&:first-child {
			margin-left: 0; // This is to override the specificity of the button group
		}
	`,

	media(
		(props) => css`
			max-width: 30px;
			padding: 2px;
		`,
		780,
	),
);

const StyledRacingTypeFilterSelectionBlack = styled(ButtonGroup)(
	(props) => css`
		label: RacingTypeFilterSelectionBlack;
		height: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: ${spacings(props).compact}px;
		flex-flow: row;
	`,
	media(
		(props) => css`
			justify-content: flex-start;

			${StyledRacingTypeFilterSelectionBlack__Button} {
				//min-width: ${application(props).racing.button_width}px;
				max-width:30px;
			}
		`,
		780,
	),
);

const StyledFilterItem = styled('div')`
	label: FilterItem;
	${(props) => `
		margin-left: 5px;
		display: flex;
		cursor:pointer;
		flex-direction: row;
		border: 1px solid #e6e6e6;
		border-radius: 4px 4px 0px 0px;
		${props.active ? `border-bottom: 2px solid ${brand(props).rch_4} ;` : ''}

		.black-png {
			color:black;
		}
	`}
`;

const RacingTypeTagContainer = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	justify-content: center;
	width: 100%;
	border-radius: 4px;
	border: 1px solid #666;
`;

const RacingTypeTag = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 12px;
    font-weight: 550;
    cursor: pointer;
    padding: 0px 4px;
    height: 48px;
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
    
    &:first-child {
        border-radius: 4px 0px 0px 0px;
    }

    &:last-child {
        border-radius: 0px 4px 0px 0px;
    }

    ${props => props.active 
        ? 'background-color: #850000; color: #fff;' 
        : 'background-color: #fff; color: #000;'
    }

    &:hover {
        ${props => props.active 
            ? 'background-color: #850000; color: #fff;' 
            : 'background-color: #850000; color: #fff;'
        }
    }
`;


const RacingTypeFilterSelectionBlack = (props) => {
	const {
		t,
		handleSelectType,
		className,
		size,
		activeGallops,
		activeGreyhounds,
		activeHarness,
		activeAll,
		raceTypes,
		resetFilterClick,
		showAllButton,
		selectedType,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	const handleAll = () => {
		resetFilterClick();
	};

	const handleGallopsClick = () => {
		handleSelectType(RACING_THOROUGHBRED_CODE.toUpperCase());
	};

	const handleHarnessClick = () => {
		handleSelectType(RACING_HARNESS_CODE.toUpperCase());
	};

	const handleGreyhoundsClick = () => {
		handleSelectType(RACING_GREYHOUNDS_CODE.toUpperCase());
	};

	const getButtonStyle = (activeGallops, activeGreyhounds, activeHarness, activeAll) => {
		if (activeGallops) {
			return activeGallops ? 'primary' : 'secondary';
		}
		if (activeGreyhounds) {
			return activeGreyhounds ? 'primary' : 'secondary';
		}
		if (activeHarness) {
			return activeHarness ? 'primary' : 'secondary';
		}
		if (activeAll) {
			return activeAll ? 'primary' : 'secondary';
		}
		return 'secondary';
	};

	const getTextStyle = (activeGallops, activeGreyhounds, activeHarness, activeAll) => {
		if (activeGallops) {
			return activeGallops ? 'alternate' : 'primary';
		}
		if (activeGreyhounds) {
			return activeGreyhounds ? 'alternate' : 'primary';
		}
		if (activeHarness) {
			return activeHarness ? 'alternate' : 'primary';
		}

		if (activeAll) {
			return activeAll ? 'alternate' : 'primary';
		}

		return 'alternate';
	};

	const buttonStyles = {
		greyhounds: {
			button: getButtonStyle(activeGreyhounds),
			text: getTextStyle(activeGreyhounds),
		},
		harness: {
			button: getButtonStyle(activeHarness),
			text: getTextStyle(activeHarness),
		},
		gallops: {
			button: getButtonStyle(activeGallops),
			text: getTextStyle(activeGallops),
		},

		all: {
			button: getButtonStyle(activeAll),
			text: getTextStyle(activeAll),
		},
	};

	if (size.width > modeChangeOverSize || size.width > TABLET_MAX_WIDTH) {
		return (
			<StyledRacingTypeFilterSelectionBlack
				className={componentClasses}
				spacing={ButtonGroup.spacings.SPACING_TIGHT}
				right
				size={size}
			>
				<StyledFilterItem active={activeGallops} onClick={handleGallopsClick}>
					<div hover="alternate" className="black-png" type={buttonStyles.gallops.button} round>
						<RacingIcon color={'black'} type={RACING_THOROUGHBRED_CODE} />
					</div>
					{/* <Checkbox active={activeHarness} onClick={handleHarnessClick}/> */}
				</StyledFilterItem>

				<StyledFilterItem active={activeHarness} onClick={handleHarnessClick}>
					<div hover="alternate" className="black-png" type={buttonStyles.harness.button} round>
						<RacingIcon color={'black'} type={RACING_HARNESS_CODE} />
					</div>
					{/* <Checkbox active={activeGallops} onClick={handleGallopsClick}/> */}
				</StyledFilterItem>
				<StyledFilterItem active={activeGreyhounds} onClick={handleGreyhoundsClick}>
					<div
						hover="alternate"
						className="black-png"
						type={buttonStyles.greyhounds.button}
						action={handleGreyhoundsClick}
						round
					>
						<RacingIcon color={'black'} type={RACING_GREYHOUNDS_CODE} />
					</div>
					{/* <Checkbox active={activeGreyhounds} onClick={handleGreyhoundsClick}/> */}
				</StyledFilterItem>
			</StyledRacingTypeFilterSelectionBlack>
		);
	} 

	return (
		<RacingTypeTagContainer>
			<RacingTypeTag
				active={selectedType == RACING_THOROUGHBRED_CODE}
				onClick={() => handleSelectType(RACING_THOROUGHBRED_CODE)}
			>
				<RacingIcon
					color={selectedType == RACING_THOROUGHBRED_CODE ? 'white' : 'black'}
					type={RACING_THOROUGHBRED_CODE}
					width={24}
					height={24}
				/>
				Thoroughbred
			</RacingTypeTag>
			<RacingTypeTag active={selectedType == RACING_HARNESS_CODE} onClick={() => handleSelectType(RACING_HARNESS_CODE)}>
				<RacingIcon
					color={selectedType == RACING_HARNESS_CODE ? 'white' : 'black'}
					type={RACING_HARNESS_CODE}
					width={24}
					height={24}
				/>
				Harness
			</RacingTypeTag>
			<RacingTypeTag
				active={selectedType == RACING_GREYHOUNDS_CODE}
				onClick={() => handleSelectType(RACING_GREYHOUNDS_CODE)}
			>
				<RacingIcon
					color={selectedType == RACING_GREYHOUNDS_CODE ? 'white' : 'black'}
					type={RACING_GREYHOUNDS_CODE}
					width={24}
					height={24}
				/>
				Greyhounds
			</RacingTypeTag>
		</RacingTypeTagContainer>
	);
};

RacingTypeFilterSelectionBlack.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** Navigate to type */
	handleSelectType: PropTypes.func,

	/** Extra classes */
	className: PropTypes.string,

	/** List of different race types */
	raceTypes: PropTypes.array,

	activeGallops: PropTypes.bool,

	activeGreyhounds: PropTypes.bool,

	activeHarness: PropTypes.bool,

	activeAll: PropTypes.bool,

	resetFilterClick: PropTypes.func,

	showAllButton: PropTypes.bool,
};

RacingTypeFilterSelectionBlack.defaultProps = {
	handleSelectType: () => undefined,
	className: null,
	raceTypes: [],
	activeGallops: undefined,
	activeGreyhounds: undefined,
	activeHarness: undefined,
	activeAll: undefined,
	resetFilterClick: () => undefined,
	showAllButton: true,
};

export default withNamespaces()(RacingTypeFilterSelectionBlack);
