/**
 * Libs
 */
import React from 'react';
import cx from 'classnames/bind';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { spacings, media } from '@tbh/ui-kit';

/**
 * Components
 */
import Carousel from '../../Carousel/Carousel';
import CarouselItem from '../../Carousel/CarouselItem/CarouselItem';

const StyledBaseCompetitionCarousel__Item = styled(CarouselItem)(
	(props) => css`
		label: BaseCompetitionCarousel__Item;

		max-width: 120px;
		padding: ${spacings(props).compact}px;
		opacity: 1;
	`,
	(props) =>
		props.isActive &&
		css`
			opacity: 1;
			cursor: default;
			background-color:#D1D1D1;
			fontWeight: bold;
		`,
	media(
		(props) => css`
			max-width: 150px;
			padding: ${spacings(props).compact}px ${spacings(props).cozy}px;
		`,
		620,
	),
);

const BaseCompetitionCarousel = (props) => {
	const { className, items, onItemClick, size, selectedItem } = props;

	const blockClasses = cx({
		[className]: className,
	});

	/**
	 * The onClick on the Carousel item returns the id passed in. Find that id in the list of
	 * items then call the onClick handler
	 * @param id
	 */
	const handleClick = (id) => {
		let item = items.find((item) => item.id === id);
		onItemClick(item);
	};

	if (items.length === 0) {
		return false;
	}

	return (
		<Carousel className={blockClasses} padded nextButton prevButton freeMode buttonStyle="1">
			{items.map((baseCompetition) => (
				<StyledBaseCompetitionCarousel__Item
					center
					padding
					key={baseCompetition.id}
					id={baseCompetition.id}
					text={baseCompetition.name}
					action={handleClick}
					image={baseCompetition.icon}
					size={size}
					isActive={selectedItem === baseCompetition.id}
				/>
			))}
		</Carousel>
	);
};

BaseCompetitionCarousel.propTypes = {
	/** List of base competitions */
	items: PropTypes.arrayOf(
		PropTypes.shape({
			/** The competition ID */
			id: PropTypes.number.isRequired,

			/** The competition name */
			name: PropTypes.string.isRequired,

			/** The competition icon */
			icon: PropTypes.string.isRequired,
		}),
	).isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** The currently selected base competition */
	selectedItem: PropTypes.number,

	/** Action to fire when a competition is clicked on in the carousel */
	onItemClick: PropTypes.func,

	/** Extra classes */
	className: PropTypes.string,
};

BaseCompetitionCarousel.defaultProps = {
	className: null,
	onItemClick: () => {},
	selectedItem: null,
};

export default BaseCompetitionCarousel;
