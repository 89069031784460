import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { debounce } from 'lodash-es';
import styled, { css } from 'react-emotion';
import { spacings } from '@tbh/ui-kit';

import { getBackingPosition } from '../../../../../pages/Racing/RacingHome/racingSelectorsGRS';

import EarlySpeedItem from './EarlySpeedItem/EarlySpeedItem';
import { SizeMe } from 'react-sizeme';

const StyledEarlySpeed__Item = styled(EarlySpeedItem)(
	(props) => css`
		label: EarlySpeed__Item;

		&:not(:first-of-type) {
			margin-top: ${spacings(props).cozy}px;
		}
	`,
);

class EarlySpeed extends Component {

	

	static propTypes = {
		/** Handle when a selection is clicked on */
		handleClickSelection: PropTypes.func.isRequired,

		/** The list of selections in the race */
		selections: PropTypes.arrayOf(
			PropTypes.shape({
				/** The early speed position to display */
				early_speed_position: PropTypes.number,
			}),
		).isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
		}).isRequired,

		/** List of betting products on the race */
		raceProducts: PropTypes.array,

		/** Whether or not betting is allowed on the race */
		isBettingAvailable: PropTypes.bool,

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		raceProducts: [],
		isBettingAvailable: true,
	};

	constructor(props) {
		super(props);

		this.itemRef = React.createRef();

		this.state = {
			componentWidth: 0,
		};
	}

	componentDidMount() {
		setTimeout(this.updateDimensions, EarlySpeed.ANIMATION_DELAY);

		window.addEventListener('resize', this.updateDimensionsEvery);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensionsEvery);
	}
	/** Animation delay from mount ending to animating the bar graph width */
	static ANIMATION_DELAY = 500;
	/** Debounce delay for resize event */
	static DEBOUNCE_DELAY = 200;

	/**
	 * Function to update the state with the width of the component
	 */
	updateDimensions = () => {
		if (this.itemRef.current) {
			this.setState({ componentWidth: this.itemRef.current.offsetWidth });
		}
	};

	/**
	 * Debounced function to update the state with the width of the component
	 */
	updateDimensionsEvery = debounce(this.updateDimensions, EarlySpeed.DEBOUNCE_DELAY);

	render() {
		const { className, selections, handleClickSelection, isBettingAvailable, raceProducts, size } = this.props;
		const { componentWidth } = this.state;

		const componentClasses = cx({
			[className]: className,
		});

		const backingPosition = getBackingPosition(selections);

		return (
			<SizeMe>
			{({ size }) => (
			<div className={componentClasses} ref={this.itemRef}>
				{selections
					.sort((a, b) => a.barrier - b.barrier)
					.map((selection) => (
						<StyledEarlySpeed__Item
							key={selection.id}
							selection={selection}
							raceProducts={raceProducts}
							boundingWidth={componentWidth}
							backingPosition={backingPosition}
							isBettingAvailable={isBettingAvailable}
							handleClickSelection={handleClickSelection}
							size={size}
						/>
					))}
			</div>
			)}
			</SizeMe>
		);
	}
}

export default EarlySpeed;
