import { derivativeMarketSchema, derivativeSelectionSchema } from '../Entities';
import { buildEntitySchema } from './relationshipSelectorHelpers';

/**
 * Grab all entities related to derivative markets.
 * @type {*}
 */
export const getDerivativeEntities = buildEntitySchema(derivativeMarketSchema);

/**
 * Grab only entities related to derivative selections.
 * @type {*}
 */
export const getDerivativeSelectionsEntities = buildEntitySchema(derivativeSelectionSchema);
