import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, ui, Text, CollapsibleIcon, TabsItem } from '@tbh/ui-kit';

import {
	USER_ACCOUNT_SUBMENU_ITEM,
	USER_ACCOUNT_SUBMENU_HEADER,
} from '../../../../../pages/UserAccount/UserAccountPageConstants';

const StyledUserAccountNavItem = styled(TabsItem)(
	(props) => css`
		label: UserAccountNavItem;

		font-size: 12px;
		${props.active &&
			css`
			 background-color: #F9F9F9;	
			 font-weigth: 800;
			 color: #C72128;

			 &:before {
				
				//background-color: #F9F9F9;	
				
			 }
			 &: after {
				color: #C72128;
				background-color: #C72128;
			
			}
			`};

	
		${props.isColumn &&
			css`
				min-width: 50%;
			`};

		${props.icon &&
			css`
				cursor: pointer;
			`};

		${props.isMobile &&
			css`
				flex: 0 0;
				min-width: 50%;
				text-align: left;
			`};

		${props.isMobile &&
			props.type === USER_ACCOUNT_SUBMENU_HEADER &&
			css`
				margin-right: ${spacings(props).compact}px;
				flex: 0 0 100%;
				text-align: center;
				background: ${ui(props).color_1};
			`};
	`,
);

const StyledUserAccountNavItem__Arrow = styled(CollapsibleIcon)(
	(props) => css`
		label: UserAccountNavItem__Arrow;

		margin-left: ${spacings(props).compact}px;
	`,
);

const UserAccountNavItem = (props) => {
	const { className, onClick, icon, title, id, type, isColumn, isOpen, isActive, ...rest } = props;

	const handleClick = () => {
		onClick(id);
	};

	const componentClasses = cx({
		[className]: className,
	});

	const isSubmenu = type === USER_ACCOUNT_SUBMENU_ITEM;
 
	return (
		<StyledUserAccountNavItem
			className={componentClasses}
			pointer={!!onClick}
			isColumn={isColumn}
			type={type}
			icon={icon}
			action={handleClick}
			active={isActive}
			{...rest}
		>
			{/* {icon && <Icon icon={icon} push="1" size={isSubmenu ? '-3' : '-1'} />} */}
			<Text tag="span" size={isSubmenu ? '-2' : '-1'} className={isActive === true ? css`color: #c72128; font-weight:800;` : null}>
				{title}
			</Text>
			{type === USER_ACCOUNT_SUBMENU_HEADER && <StyledUserAccountNavItem__Arrow isCollapsed={!isOpen} />}
		</StyledUserAccountNavItem>
	);
};

UserAccountNavItem.propTypes = {
	/** Click handler */
	onClick: PropTypes.func.isRequired,

	/** Item identifier */
	id: PropTypes.string.isRequired,

	/** Menu Icon */
	title: PropTypes.string.isRequired,

	/** Extra classes */
	className: PropTypes.string,

	/** Menu Icon */
	icon: PropTypes.string,

	/** To apply different style */
	type: PropTypes.oneOf([USER_ACCOUNT_SUBMENU_ITEM, USER_ACCOUNT_SUBMENU_HEADER]),

	/** isMobile */
	isMobile: PropTypes.bool,

	/** Should the TabItems display in column mode (vertically) */
	isColumn: PropTypes.bool,

	/** If section header control drop down arrow state*/
	isOpen: PropTypes.bool,

	/** Whether menu is active or not */
	isActive: PropTypes.bool,
};

UserAccountNavItem.defaultProps = {
	className: '',
	icon: null,
	type: undefined,
	isMobile: false,
	isColumn: false,
	isOpen: false,
	isActive: false,
};

export default UserAccountNavItem;
