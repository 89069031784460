import { GOAT_PRODUCT_TYPE_BOOST } from '../../../common/constants/GoatProducts';
import {
	RACING_BET_TYPE_WIN,
	RACING_BET_TYPE_MARGIN,
	RACING_FIXED_NAME,
	RACING_TOTE_NAME,
	RACING_BET_TYPE_EACHWAY,
} from '../../../common/constants/Racing';
import { PRODUCT_TYPE_STANDARD } from '../../../store/entities/constants/BetConstants';

/**
 * Returns the default productId for a price, based on the selection's racing bet type
 *
 * @param selection
 * @param key
 */
export const getDefaultSelectionProductId = (selection, key) => {
	const { racingBetType } = selection;
	const price = selection.prices[`${racingBetType}_${key}`];
	let defaultProductId = Array.isArray(price)
		? price.includes(selection.product_id)
			? selection.product_id
			: null
		: price;

	// If there is no matching product id for the new bet selection, then grab the first one
	if (!defaultProductId) {
		const placePrice = selection[`${racingBetType}_${key}`];
		defaultProductId = Array.isArray(placePrice) ? placePrice[0] : placePrice;
	}

	return defaultProductId;
};

/**
 * Returns the product id and correct racing bet type for the selected goat product, if found
 *
 * @param race
 * @param isFixed
 * @param selectedGoatProduct
 * @returns {{productId, racingBetType: *}}
 */
export const getGoatProductAndBetType = (race = {}, isFixed, selectedGoatProduct) => {
	// Search for a tote BUTT product
	const goatProduct = race.products.find(
		(product) =>
			(product.product_type === selectedGoatProduct || product.bet_type === selectedGoatProduct) &&
			product.fixed === isFixed,
	);

	if (goatProduct && goatProduct.product_id) {
		// Set it as the productId with the correct racing bet type
		return {
			newProductId: goatProduct.product_id,
			newRacingBetType: selectedGoatProduct === RACING_BET_TYPE_MARGIN ? RACING_BET_TYPE_MARGIN : RACING_BET_TYPE_WIN,
		};
	}
};

/**
 * Returns the racing product id and bet type based on a variety of factors
 *
 * @param selection
 * @param race
 * @param isToteOdds
 * @param selectedGoatProduct
 * @returns {{productId: *, racingBetType: *}}
 */
export const getRacingProductIdAndBetType = (selection, race, isToteOdds, selectedGoatProduct = null) => {
	let racingBetType = selection.racingBetType;
	let productId;
	if (racingBetType === RACING_BET_TYPE_EACHWAY) {
		productId = selection.product_id;

		// Try and select the correct product to use, in the instance that boost product is being toggled
		if(!isToteOdds) {
			const productType =
				selectedGoatProduct === GOAT_PRODUCT_TYPE_BOOST ? GOAT_PRODUCT_TYPE_BOOST : PRODUCT_TYPE_STANDARD;
			const winProduct = race.products.find(
				(product) =>
					product.product_type === productType &&
					product.bet_type === RACING_BET_TYPE_WIN &&
					product.fixed === !isToteOdds,
			);
			if (winProduct) {
				productId = winProduct.product_id;
			}
		}
	}
	// Check if the currently selected product is tote or fixed
	else if (isToteOdds) {
		if (selectedGoatProduct === RACING_BET_TYPE_MARGIN && race && race.products) {
			const { newProductId, newRacingBetType } = getGoatProductAndBetType(race, !isToteOdds, selectedGoatProduct);
			productId = newProductId;
			racingBetType = newRacingBetType;
		}

		// Default to the tote odds
		if (!productId) {
			productId = getDefaultSelectionProductId(selection, RACING_TOTE_NAME);
		}
	} else {
		if (race && race.products) {
			// Check if the price has been bumped
			if (selectedGoatProduct === GOAT_PRODUCT_TYPE_BOOST) {
				// Set the BUMP product with the correct racing bet type
				const { newProductId, newRacingBetType } = getGoatProductAndBetType(race, !isToteOdds, selectedGoatProduct);
				productId = newProductId;
				racingBetType = newRacingBetType;
			} else if (selectedGoatProduct === RACING_BET_TYPE_MARGIN) {
				// Check if the selected product is fixed BUTT and search for it
				const { newProductId, newRacingBetType } = getGoatProductAndBetType(race, !isToteOdds, selectedGoatProduct);
				productId = newProductId;
				racingBetType = newRacingBetType;
			}
		}

		// Default to the fixed odds
		if (!productId) {
			productId = getDefaultSelectionProductId(selection, RACING_FIXED_NAME);
		}
	}

	return { productId, racingBetType };
};
