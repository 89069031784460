import moment from 'moment';
import { APN_FLUCS, TB_FLUCS } from '../../../common/constants/Racing';

export const selectFlucsKey = (race = {}) => {
	// Looks for available Flucs values.
	const hasApnFlucs = race.selections && race.selections.find((selection) => selection.apn_fluctuations);
	const hasTopbettaFlucs = race.selections && race.selections.find((selection) => selection.topbetta_fluctuations);

	// If no selection has flucs don't show flucs column
	if (!hasApnFlucs && !hasTopbettaFlucs) {
		return null;
	}

	const hasFixedProducts = race.products && race.products.some((product) => product.fixed);

	// Don't show flucs column
	// if neither Fixed odds nor GOAT is available
	// or the race doesn't have any fixed products available
	if ((!race.fixed_odds_enabled && !race.global_tote_available) || !hasFixedProducts) {
		return null;
	}

	/**
	 * Logic brought from legacy view 'selection_item.js.
	 *
	 * If it's more than 20min to jump, show TopBetta flucs.
	 * Otherwise, show APN if available, TB if not.
	 */
	if (moment() <= moment(race.start_date).subtract(20, 'minutes')) {
		// more then 20min to jump
		return TB_FLUCS;
	} else {
		// Otherwise show APN flucs if exists
		return hasApnFlucs ? APN_FLUCS : TB_FLUCS;
	}
};
