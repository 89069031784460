// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { Button, ButtonGroup, Icon, Text } from '@tbh/ui-kit';

// Components
import MobileInput from '../../../Registration/MobileInput/MobileInput';

const MobileAuthenticationForm = (props) => {
	const {
		t,
		className,
		country,
		loadingSendSms,
		sendSmsCode,
		validateMobileNumber,
		mobile_is_valid,
		mobile_number,
		mobile_number_error,
		brandName,
	} = props;

	const sendSMS = () => sendSmsCode(mobile_number);

	return (
		<div className={cx({ [className]: className })}>
			<Text strong paragraph>
				{t('MobileAuthenticationForm__VerifyRealPerson')}
			</Text>

			{mobile_number != '' && mobile_is_valid ? (
				<Text size="-1" paragraph>
					{t('MobileAuthenticationForm__IsValid', { brandName })}
				</Text>
			) : (
				<Text size="-1" paragraph>
					{t('MobileAuthenticationForm__SMSVerificationCode')}
				</Text>
			)}

			<div
				className={css`
					display: flex;
					margin-bottom: 20px;
				`}
			>
				<div className={css`flex:1;`}>
					<MobileInput
						country={country}
						onKeyDown={sendSmsCode}
						onChange={validateMobileNumber}
						number={mobile_number}
						valid={mobile_is_valid}
						error={mobile_number_error}
					/>
					<Text size="-2" paragraph>
						{t('MobileAuthenticationForm__SMSVerification')}
					</Text>
				</div>

				<Icon
					className={css`
						font-size: 90px;
						line-height: 90px; // for IE
						align-self: flex-start;
					`}
					icon="phone-code"
				/>
			</div>

			<ButtonGroup center>
				<Text
					className={css`
						flex: 0 1 50%;
					`}
					size="-1"
				>
					{t('MobileVerificationForm__Step1')}
				</Text>

				<Button action={sendSMS} type="primary" disabled={!mobile_is_valid} loading={loadingSendSms} bold block flex>
					{t('MobileAuthenticationForm__SendSMS')}
				</Button>
			</ButtonGroup>
		</div>
	);
};

MobileAuthenticationForm.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The mobile number */
	mobile_number: PropTypes.string.isRequired,

	/** If the number is valid */
	mobile_is_valid: PropTypes.bool.isRequired,

	/** Country to SMS to */
	country: PropTypes.string.isRequired,

	/** Action for sending the SMS code  */
	sendSmsCode: PropTypes.func.isRequired,

	/** Action to validate the mobile number */
	validateMobileNumber: PropTypes.func.isRequired,

	/** If true, the submit button will appear as loading */
	loadingSendSms: PropTypes.bool,

	/** Any errors to display for the mobile phone number */
	mobile_number_error: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,
};

MobileAuthenticationForm.defaultProps = {
	loadingSendSms: false,
	mobile_number_error: null,
	className: null,
};

export default withNamespaces()(MobileAuthenticationForm);
