// Libraries
import React, { Component } from 'react';
import { css } from 'react-emotion';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';
// Components
import { ButtonGroup } from '@tbh/ui-kit';
import { StyledMenuButtonGroup } from './TopNavigation.styled';
import { fetchAllSports } from '../../../../store/entities/actions/SportsActions';
import { connect } from 'react-redux';
import { navigateToRacingHome } from '../../../../store/GRSracingHome/GRSracingHomeActions';
import { routeTo } from '../../../../store/application/applicationActions';
import { BETBUILDER_BASE_URL } from '../../../../pages/BetBuilder/BetBuilderConstants';
import { RS_TIPS_BASE_URL, RS_TIPS_SUB_APP } from '../../../../pages/RSTips/RSTipsConstants';
import { PROMOTIONS_BASE_URL, PROMOTIONS_SUB_APP } from '../../../../pages/Promotions/PromotionsPageConstants';
import { TertiaryButton } from '../../../UI/Buttons';
import { serverDateTime } from '../../../../legacy/core/format';
import moment from 'moment';
import RacingIcon from '../../Racing/RacingIcon/RacingIcon';

class TopNavigationMasthead extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
		}).isRequired,

		/** Extra classes */
		className: PropTypes.string,

		nextNtjRaceMeetingId: PropTypes.number,

		sports: PropTypes.object,
		/** Selected type */
		meetingType: PropTypes.string,

		/** Navigate to bet builder */
		handleBetBulder: PropTypes.func,

		handleFeature: PropTypes.func,

		handleSports: PropTypes.func,

		selectedPageKey: PropTypes.string.isRequired,

		handleRacing: PropTypes.func,

		handlePromos: PropTypes.func,
	};

	static defaultProps = {
		className: '',
		nextNtjRaceMeetingId: null,
		meetingType: undefined,
		handleFeature: () => undefined,
		handleBetBulder: () => undefined,
		handleSports: () => undefined,
		handleRacing: () => undefined,
		handlePromos: () => undefined,
	};

	/*componentDidMount() {
		// Adding to all pages
		this.props.fetchSports();
	}*/

	render() {
		const {
			size,
			className,
			nextNtjRaceMeetingId,
			disabledButtons,
			meetingType,
			handleRacing,
			sports,
			handleBetBulder,
			handleFeature,
			handleSports,
			selectedPageKey,
			handlePromotions,
			handleRSTips,
			SportsIsEnabled,          // for lable sport Option 
		} = this.props;
		const componentClasses = cx({
			[className]: className,
		});
		const existSports = (sport) => {
			if (!sports) return false;

			return Object.keys(sports).length > 0;
		};
		const handleClickTodayRacing = (e) => {
			e.preventDefault();
			handleRacing();
		};

		const handleBetbuilder = (e) => {
			e.preventDefault();
			handleBetBulder();
		};

		const handleFeureClisk = () => {
			handleFeature();
		};

		const sportsClick = () => {
			handleSports();
		};

		const promosClick = () => {
			handlePromotions();
		};

		const rsTipsClick = () => {
			handleRSTips();
		};

		const getButtonStyle = (typeString, selectedPageKey) => {
			if (typeString === selectedPageKey) {
				return 'secondary';
			}

			return 'primary';
		};

		const getTextStyle = (typeString, selectedPageKey) => {
			if (typeString === selectedPageKey) {
				return 'alternate';
			}

			return 'default';
		};

		const cssStyledTopNavigation__ButtonExpanded =
			size.width < 500
				? css`
						display: none;
						text-transform: uppercase;
				  `
				: css`
						display: block;
						text-transform: uppercase;
				  `;

		const handleIsActive = (typeString, selectedPageKey) => {
			switch (typeString) {
				case 'RacingNewPage': {
					return selectedPageKey === 'RacingNewPage';
				}

				case 'BetBuilder': {
					return selectedPageKey === 'BetBuilder';
				}

				case 'FeatureRacing': {
					return selectedPageKey === 'FeatureRacing';
				}

				case 'Sports': {
					return selectedPageKey === 'Sports';
				}

				case 'Promotions': {
					return selectedPageKey === 'Promotions';
				}
				case 'RSTips': {
					return false;
				}

				default:
					return false;
			}
		};

		const TopMenuButtons = {
			RacingNewPage: {
				isActive: handleIsActive('RacingNewPage', meetingType, selectedPageKey),
				button: getButtonStyle('RacingNewPage', meetingType, selectedPageKey),
				textStyle: getTextStyle('RacingNewPage', meetingType, selectedPageKey),
				action: handleClickTodayRacing,
				title: "Today's Racing",
				icon: <RacingIcon  />,
			},
			Promotions: {
				isActive: handleIsActive('Promotions', selectedPageKey),
				button: getButtonStyle('Promotions', selectedPageKey),
				textStyle: getTextStyle('Promotions', selectedPageKey),
				action: promosClick,
				title: 'Promotions',
				icon: <i className="fa fa-tag fa-2x" aria-hidden="true" />,
			},
			// BetBuilder: {
			// 	isActive: handleIsActive('BetBuilder', selectedPageKey),
			// 	button: getButtonStyle('BetBuilder', selectedPageKey),
			// 	textStyle: getTextStyle('BetBuilder', selectedPageKey),
			// 	action: handleBetbuilder,
			// 	title: 'Bet builder',
			// 	icon: <i className="fa fa-btc fa-2x" aria-hidden="true" />,
			// },
			RSTips: {
				isActive: handleIsActive('RSTips', selectedPageKey),
				button: getButtonStyle('RSTips', selectedPageKey),
				textStyle: getTextStyle('RSTips', selectedPageKey),
				action: rsTipsClick,
				title: 'RS Tips',
				icon: <i className="fa-brands fa-free-code-camp fa-2x" aria-hidden="true" />,
			},
			Sports: {
				isActive: handleIsActive('Sports', selectedPageKey),
				button: getButtonStyle('Sports', selectedPageKey),
				textStyle: getTextStyle('Sports', selectedPageKey),
				action: sportsClick,
				title: 'Sports',
				isComingSoon: true,
				icon: <i className="fa fa-futbol-o fa-2x" aria-hidden="true" />,
			},
		};

		 // Filter and map buttons, hiding Sports if SportsIsEnabled is false
		 const listTopMenuButtons = Object.keys(TopMenuButtons)
		 .filter((key) => {
			 // Hide Sports button if SportsIsEnabled is false
			 if (key === 'Sports' && !SportsIsEnabled) {
				 return false;
			 }
			 return true;
		 })
		 .map((key) => {
			 return {
				 ...TopMenuButtons[key],
				 type: key,
			 };
		 });
 


		return (
			<StyledMenuButtonGroup className={componentClasses} left spacing={ButtonGroup.spacings.SPACING_TIGHT} size={size}>
				{listTopMenuButtons.map((menuItem) => (
					<TertiaryButton
						style={{ padding: 0 }}
						key={menuItem.type}
						isActive={menuItem.isActive}
						disable={disabledButtons.includes(menuItem.type)}
						size="small"
						mediaSize={size}
						type={menuItem.button}
						onClick={menuItem.action}
					>
						{menuItem.icon}
						<div className={cssStyledTopNavigation__ButtonExpanded}>{menuItem.title}</div>
						{menuItem.isComingSoon && (
							<span
								style={{
									marginLeft: 4,
									fontSize: '9px',
								}}
							>
								(Coming Soon)
							</span>
						)}
					</TertiaryButton>
				))}
				{/* <TertiaryButton size="small"
                    style={{ padding: 0 }}
                    disable={true}
                    mediaSize={size}
                    isActive={TopMenuButtons.RSTips.isActive}
                    type={TopMenuButtons.RSTips.button}>
                    <b>
                        <i className="fa fa-play-circle fa-2x" aria-hidden="true" />
                    </b>
                    <div className={cssStyledTopNavigation__ButtonExpanded}>{'In Play'}</div>
                </TertiaryButton> */}
			</StyledMenuButtonGroup>
		);
	}
}

const mapStateToProps = (state) => {
	const selectedPage = state.application.selectedPage;

	const disabledButtons = ['FeatureRacing', 'Sports', 'Promotions', 'RSTips'];
	return {
		sports: state.entities.sports,
		disabledButtons: disabledButtons,
		SportsIsEnabled: state.featureToggles.features.Sports ? state.featureToggles.features.Sports.enabled : false,


	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		//fetchSports: () => dispatch(fetchAllSports()),
		handleRacing: () => {
			dispatch(navigateToRacingHome(serverDateTime(moment())));
		},
		// nextNtjRaceMeetingId={nextNtjRace && nextNtjRace.meeting_id}
		// meetingType={selectedType}
		// handleRacing={this.navigateToTodaysRacing}
		handleBetBulder: () => {
			dispatch(routeTo(BETBUILDER_BASE_URL));
		},

		handleRSTips: () => {
			dispatch(routeTo(RS_TIPS_BASE_URL));
			App.startSubApp(RS_TIPS_SUB_APP);
		},

		handlePromotions: () => {
			dispatch(routeTo(PROMOTIONS_BASE_URL));
			App.startSubApp(PROMOTIONS_SUB_APP);
		},
		// handleFeature
		// handleSports
		// handlePromos
	};
};
export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(TopNavigationMasthead));
