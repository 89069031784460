import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import ReduxThunk from 'redux-thunk';
import { enableBatching } from 'redux-batched-actions';

const OPTIONS = {};

export default (reducerObject, preloadedState, options = {}) => {
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({...OPTIONS, ...options})
		: compose;

	const middleware = [...(options.middleware || []), ReduxThunk];

	return createStore(
		enableBatching(combineReducers(reducerObject)),
		preloadedState,
		composeEnhancers(applyMiddleware(...middleware)),
	);
};
