import { createAction } from '../../common/actions/actionHelpers';
import { get } from '../../common/Ajax';
import { serverDateTime } from '../../legacy/core/format';
import { SIDEBAR_CHANGE } from './SideBarActionsTypes';
import moment from 'moment';
import { 
	// navigateToNextToJumpRaceWithType,
	navigateToRacingHome } from '../GRSracingHome/GRSracingHomeActions';
import { RACING_TYPES_REVERSE_LOOKUP } from '../../common/constants/Racing';

export const fetchSideBarMeetingRaces_GRS = () => (dispatch) => {
	return get('racing/sidebar')
		.then((response) => {
			const result = response.data.result;
			const payload = {};

			// Arrange the data by meeting type
			const days = Object.keys(result);

			days.forEach((key) => {
				// console.log('find key', key);
				// TODAY, TOMORROW, DAYAFTERTOMORROW, previousResults
				let plusDay = 0;
				if (key === 'previousResults') plusDay = -1;
				if (key === 'tomorrow') plusDay = 1;
				if (key === 'dayAfterTomorrow') plusDay = 2;		
				let name = key;
				if (key === 'dayAfterTomorrow') name = serverDateTime(moment().add(plusDay, 'day')).format('dddd');
				let date = serverDateTime(moment().add(plusDay, 'day')).format('YYYY-MM-DD');
				const raceTypes = Object.keys(result[key]);
				let meetingTypes = [];
				raceTypes.forEach((type) => {
					const races = result[key][type];
					// arrange by meeting
					const meetings = [];

					// Uncomment and adjust this section if needed to handle races
					// races.forEach(race => {
					// 	const { meeting_id, meeting_name, type, race_name, race_id, race_number } = race;

					// 	const meetingIndex = meetings.findIndex(m => m.id === meeting_id);
					// 	const newRace = {
					// 		name: `Race ${race_number}`,
					// 		id: race_id,
					// 		type,
					// 		meeting_id,
					// 		step: 'step-4',
					// 		children: [],
					// 		action: () => {
					// 			dispatch(navigateToNextToJumpRaceWithType(type, date, race_id, meeting_id));
					// 		},
					// 	};

					// 	if (meetingIndex === -1) {
					// 		meetings.push({
					// 			id: meeting_id,
					// 			name: meeting_name,
					// 			type,
					// 			step: 'step-3',
					// 			children: [newRace],
					// 			action: () => {
					// 				dispatch(navigateToNextToJumpRaceWithType(type, date, race_id, meeting_id));
					// 			},
					// 		});
					// 	} else {
					// 		meetings[meetingIndex].children.push(newRace);
					// 	}
					// });

					meetingTypes.push({
						name: `${type.toUpperCase()}`,
						id: `racing_types_${key.toUpperCase() + type}`,
						type: key.charAt(0).toUpperCase(),
						children: meetings,
						action: () => dispatch(navigateToRacingHome(date, RACING_TYPES_REVERSE_LOOKUP[type])),
						step: 'step-2',
					});
				});

				payload[key] = {
					name: name + '\'s Racing',
					type: '',
					date,
					id: 'racing_day_' + key.toUpperCase(),
					children: meetingTypes.reverse(),
					action: () => dispatch(navigateToRacingHome(date)),
					step: 'step-1',
				};
			});

			return dispatch(createAction(SIDEBAR_CHANGE, payload));
		})
		.catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			console.error('NTJ racing request failed');
			throw error;
		});
};
