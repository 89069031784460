import styled, { css } from 'react-emotion';


export const Skeleton = styled('div')`
    width: ${(props) => props.width ? props.width : '80%'};
    height: ${(props) => props.height ? props.height : '16px'};
    background: ${(props) => props.black ? css`hsl(240 5% 34%)` : css`rgba(229, 231, 235, 1)`};
    border-radius: 4px;
    
    @keyframes pulseSkeleton {
        to{
            opacity: 0.5;
        }
        from{
            opacity: 1;
        }
    }

    animation: pulseSkeleton 0.8s ease-in-out infinite;
`;