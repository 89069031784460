import { normalize, denormalize } from 'normalizr';
import { selectionsSchema } from './Entities';

/**
 * Normalize structure to suit JS Applications
 *
 * @param response
 */
export const normalizeSelections = (response) => {
	return normalize(response, selectionsSchema);
};

/**
 * Denormalize entities back to a structure similar to the API response.
 *
 * @param entities
 * @param keys
 * @returns {Array}
 */
export const denormalizeSelections = (entities, keys) => {
	return denormalize(keys || Object.keys(entities.selections), selectionsSchema, entities).filter((sel) => !!sel);
};
