import { createNamedActions } from '../../common/actions/createNamedActions';
import * as dataPaginatorActions from './dataPaginatorActions';
import { DATA_PAGINATOR_USER_TRANSACTIONS } from './dataPaginatorReducerNames';

const namedActions = createNamedActions(DATA_PAGINATOR_USER_TRANSACTIONS, dataPaginatorActions);

export const {
	filterDataTable,
	setLoading,
	changePage,
	changeSize,
	changeHeaders,
	changeTotal,
	changeTotalPages,
	setData,
	setDataTable,
	modifyDataTable,
	setPageName,
} = namedActions;
