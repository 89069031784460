/**
 * Libraries
 */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { brand, spacings, Table } from '@tbh/ui-kit';
import moment from 'moment';
import { Text, Button, media} from '@tbh/ui-kit';
import GRSNJMarketRow from '../GRSNJMarketRow/GRSNJMarketRow';
import Svg from '../../../../ui-components/Svg/Svg';
import { convertToLocalDateTime } from '../../../../../common/Date';
import JsonLd from '../../../Meta/JsonLd/JsonLd';
// Functions

const StyledDefaultGRSEventItem__Icon = styled(Svg)(
	(props) => css`
		label: DefaultGRSEventItem__Icon;

		margin-right: ${spacings(props).tight}px;
		flex: 1 0 ${spacings(props).comfortable}px;
		background: ${brand(props).color_1};
		border-radius: 50%;
		max-width: ${spacings(props).comfortable}px;
	`,
);

const StyledGoToSport = styled(Button)(
	(props) => css`
		label: StyledGoToSport;

		cursor: pointer;
		width: 100%;
		background: #c72128;
		border-style: solid;
    	border-color: #c72128 #c72128 #c72128;
		padding: ${spacings(props).tight}px;
		margin-top: ${spacings(props).comfortable}px;
	`,
	(props) =>
		media(
			css`
			 padding:  ${spacings(props).comfortable}px;
			`,),
);

/**
 * Sort selections alphabetically
 *
 * @param selections
 * @returns {Array}
 */
const processSelections = (selections = []) => {
	return selections.sort((b, a) => a.name.localeCompare(b.name));
};

/**
 * The default event item. Displays the event details as well as a list of default markets for the event
 *
 * @param className
 * @param disableBetting
 * @param markets
 * @param eventId
 * @param eventStart
 * @param eventName
 * @param addToSingle
 * @param addToMulti
 * @param onEventClick
 * @param hasBump
 * @param route
 * @returns {XML}
 * @constructor
 */

const DefaultGRSEventItem = ({
	t,
	className,
	disableBetting,
	markets,
	eventId,
	eventStart,
	eventName,
	addToSingle,
	addToMulti,
	onEventClick,
	size,
	event,
	route,
	sportName,
	competitionName,
}) => {

	// Classes
	const classes = cx({
		[className]: className,
	});


	// Generate the addTo single or multi action
	const addTo = (callback, market) => {
		if (!callback) {
			return callback;
		}

		return (selection, productId) =>
			callback(eventId, market.selections.find((s) => s.id === selection), market, productId);
	};

	const handleOnHeaderClick = (e) => {
		if (e) {
			e.preventDefault();
		}
		onEventClick(eventId);
	};
	// go to sports event
	const handleClick = (e) => {
		e.preventDefault();

		//const url = 'sports/' + slugify(sportName) + '/' + slugify(competitionName) + '-'+event.competition_id+'/' + slugify(event.name) + '-' + event.id;
		//window.open(url, '_self');
	};
	// Get the local time for the event start date
	const localEventStart = convertToLocalDateTime(eventStart);


	const eventLd = {
		'@context': 'http://schema.org',
		'@type': 'SportsEvent',
		name: eventName,
		description: eventName,
		startDate: localEventStart.format(),
		endDate: localEventStart
			.clone()
			.add('60', 'minutes')
			.format(),
		url: window.location && window.location.href,
		competitor: [],
		location: {
			'@type': 'Place',
			name: eventName,
			address: eventName,
		},
		performer: [],
	};

	return (
		<div className={classes}>
			<JsonLd content={eventLd} />
			<div className={css`
                        align-items: center;
                        padding: 4px;
                        display: flex;
                        flex-flow: row wrap;
                `}>
				<div className={css`
                        flex: 0 0 30%;
                `}></div>

				<div className={css`
                        flex: 0 0 70%;
                `}>
					{
						markets.length > 0 && (
							<Table >
								<Table.TR>
									<Table.TD  >
										<Text
											align="center"
											size="-2"
											className={css`
												flex: 0 0 30px;
											`}
										>Home</Text>
									</Table.TD>
									<Table.TD >
										<Text
												align="center"
												size="-2"
												className={css`
													flex: 0 0 30px;
												`}
											>Draw</Text>
									</Table.TD>
									<Table.TD >
										<Text
												align="center"
												size="-2"
												className={css`
													flex: 0 0 30px;
												`}
											>Away</Text>
									</Table.TD>
								</Table.TR>

							</Table>
						)
					}

				</div>
			</div>
			{markets.map((market) => (
				<GRSNJMarketRow
					alignTop
					key={market.id}
					marketName={market.name}
					selections={processSelections(market.selections)}
					bettingAvailable={!disableBetting}
					addToSingle={addTo(addToSingle, market)}
					addToMulti={addTo(addToMulti, market)}
					size={size}
				/>
			))}
			<StyledGoToSport action={handleClick} type="secondary">
				{'View Full Event'}
			</StyledGoToSport>
		</div>
	);
};

DefaultGRSEventItem.propTypes = {

	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}),

	/** De-normalized race and details */
	event: PropTypes.shape({
		id: PropTypes.number.isRequired,
		name: PropTypes.string,
		/** The products available for the event */
		products: PropTypes.arrayOf(
			PropTypes.shape({
				product_id: PropTypes.number.isRequired,
				product_type: PropTypes.string.isRequired,
			}),
		).isRequired,
		start_date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
		markets: PropTypes.array,

	}).isRequired,

	/** The ID of the event */
	eventId: PropTypes.number.isRequired,

	/** The name of the event */
	eventName: PropTypes.string.isRequired,

	/** The name of sport */
	sportId: PropTypes.number.isRequired,

	/** The sport name */
	sportName: PropTypes.string.isRequired,

	/** The moment when the event starts */
	eventStart: PropTypes.instanceOf(moment).isRequired,

	/** The list of market items in the market group */
	markets: PropTypes.arrayOf(
		PropTypes.shape({
			/** The market item */
			id: PropTypes.number.isRequired,

			/** The market name */
			name: PropTypes.string.isRequired,

			/** The list of selections in the market */
			selections: PropTypes.array.isRequired,
		}),
	),

	/** Action to add the selection to the bet prompt */
	addToSingle: PropTypes.func.isRequired,

	/** Action to add the selection to the multi bet slip */
	addToMulti: PropTypes.func,

	/** Action to fire when the event is clicked on */
	onEventClick: PropTypes.func,

	/** The route for the team event */
	route: PropTypes.string,

	/** Disable betting for the event item */
	disableBetting: PropTypes.bool,

	/** Extra classes */
	className: PropTypes.string,

	action: PropTypes.func.isRequired,

	competitionName: PropTypes.string.isRequired,
};

DefaultGRSEventItem.defaultProps = {
	markets: [],
	addToMulti: null,
	className: null,
	onEventClick: null,
	disableBetting: false,
	size:undefined,
	t: undefined,
	route: null,
};

export default DefaultGRSEventItem;
