import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import {
	ui,
	// Text
} from '@tbh/ui-kit';

// import { CollapsiblePanel, HeaderBar } from '@tbh/ui-kit';
import UserAccountNavList from '../UserAccountNavList/UserAccountNavList';

import Carousel from '../../Carousel/Carousel';
import { MOBILE_MAX_WIDTH } from '../../../../common/constants/Breakpoints';
import Dropdown from './Dropdown';

const StyledUserAccountMenu__MobileContent = styled(UserAccountNavList)`
	label: UserAccountMenu__MobileContent;
	padding: 0 !important;
	${(props) =>
		css`
			position: absolute;
			top: 100%;
			box-shadow: 2px 0 10px ${ui(props).color_4};
		`};
`;

const StyledMenuAccount = styled(Carousel)`
	label: Styled_Menu_Account__Carousel;

	cursor: auto;
	width: 100%;
	height: 40px;
	background-color: white;
	border: 0.5px solid #919191;

	border-radius: 4px;

	.swiper-pagination-bullet {
		background: white;
		width: 100px;
	}
	.swiper-button-next {
		width: 40px !important;
		background-color: #00000085 !important;
		color: white !important;
	}
	.swiper-button-prev {
		width: 40px !important;
		background-color: #00000085 !important;
		color: white !important;
	}
	margin-bottom: 20px;
`;

const wrapperClassName = css`
	display: flex;
	justify-content: flex-start;

	.swiper-slide {
		width: fit-content;
	}
`;

const StyledMenuItem = styled('div')(
	(props) => css`
		label: Styled_Menu_Item__div;
		display: flex;

		height: 40px;

		background: ${props.selectedItem ? '#850000' : 'white'};
		color: ${props.selectedItem ? '#ffffff' : '#000000'};
		padding: 6px 20px;
		cursor: pointer;

		&:hover {
			color: ${props.selectedItem ? 'black' : '#006CE8'};
		}
		border-top: 1px solid ${props.selectedItem ? '#850000' : 'white'};
		border-bottom: 1px solid ${props.selectedItem ? '#850000' : 'white'};
	`,
);

class UserAccountMenu extends React.Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Menu items */
		items: PropTypes.array.isRequired,

		/** Handles active item */
		onSelect: PropTypes.func.isRequired,

		/** Extra classes */
		className: PropTypes.string,

		/** Whether desktop/mobile version */
		isMobile: PropTypes.bool,

		/** Display the header */
		showHeader: PropTypes.bool,

		/** Current selected item */
		activeItem: PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		}),
	};

	static defaultProps = {
		activeItem: null,
		className: '',
		isMobile: false,
		showHeader: true,
	};

	state = {
		activeItem: this.props.activeItem && this.props.activeItem.id,
		dropdownTitle: this.props.activeItem && this.props.t(`UserAccountNavList__${this.props.activeItem.id}--mobile`),
		isCollapsed: true,
	};

	/**
	 * Checks if the selected Menu Item is a page or a modal.
	 * Only the tabs that represent a page can be shown as active
	 *
	 * @param nextProps
	 * @param prevState
	 */
	static getDerivedStateFromProps(nextProps, prevState) {
		const { activeItem, items } = nextProps;

		// If the activeItem is a page and not a modal
		// and if it's a page, set the activeItem so the tab can be shown as active
		if (activeItem && activeItem.id !== prevState.activeItem) {
			return {
				activeItem: activeItem.id,
				dropdownTitle: nextProps.t(`UserAccountNavList__${activeItem.id}--mobile`),
			};
		}

		return null;
	}

	/**
	 * Used by mobile version, just toggle dropdown and call action.
	 */
	handleSelect = (item) => {
		this.handleToggleContent();

		if (item.id === 'verification') {
			this.props.onSelect(item);
		} else {
			this.props.onSelect(item);
		}
	};

	/**
	 * Open/Close dropdown
	 */
	handleToggleContent = () => {
		this.setState({
			isCollapsed: !this.state.isCollapsed,
		});
	};

	render() {
		const { className, isMobile, items, onSelect, showHeader, t, isBonusBets, size } = this.props;
		const { activeItem, dropdownTitle, isCollapsed } = this.state;

		const componentClasses = cx({
			[className]: className,
		});

		// Don't show the menu items if there isn't more than one item
		if (items.length <= 1) {
			return null;
		}

		const filteredItems = items;

		const verification = filteredItems.find((item) => item.id === 'verification');
		const isVerification =
			verification && verification.items && verification.items.find((item) => item.id === activeItem);

		if (size.width <= MOBILE_MAX_WIDTH) {
			return (
				<Dropdown
					options={items}
					onSelect={this.handleSelect}
					selectedOption={filteredItems.find((item) => item.id === activeItem)}
					style={{
						marginBottom: '20px',
					}}
				/>
			);
		}

		let renderMenuElement = null;
		if (isBonusBets) {
			renderMenuElement = filteredItems.map((item, i) => (
				<StyledMenuItem
					key={item.id}
					selectedItem={(!isVerification ? activeItem : verification.id) === item.id}
					onClick={() => this.handleSelect(item)}
					onItemClick={onSelect}
					// activeItem={isVerification ? verification : activeItem}
				>
					{item.title}
				</StyledMenuItem>
			));
		} else {
			renderMenuElement = filteredItems
				.filter((item) => item.title !== 'Bonus Bet Transactions')
				.map((item, i) => (
					<StyledMenuItem
						key={item.id}
						selectedItem={(!isVerification ? activeItem : verification.id) === item.id}
						onClick={() => this.handleSelect(item)}
						onItemClick={onSelect}
						// activeItem={isVerification ? verification : activeItem}
					>
						{item.title}
					</StyledMenuItem>
				));
		}

		return (
			<StyledMenuAccount
				wrapperClassName={wrapperClassName}
				nextButton
				prevButton
				buttonStyle={'1'}
				fitContent
				isActiveNavigation
			>
				{renderMenuElement}
			</StyledMenuAccount>
		);
	}
}

export default withNamespaces()(UserAccountMenu);
