import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { Tabs, TabsGroup, TabsItem } from '@tbh/ui-kit';
import ExoticBetTypeFilters from '../../ExoticBetTypeFilters/ExoticBetTypeFilters';

import {
	RACING_NON_EXOTIC_BET_TYPE_TITLE,
	// RACING_ODD_GRID_BET_TYPE_TITLE,
	RACING_NON_EXOTIC_BET_TYPE,
	RACING_EXOTIC_BET_TYPES,
	RACING_BET_TYPES,
	// RACING_ODD_GRID_BET_TYPE,
	//RACING_MULTIPLES_TYPE,
	// RACING_MORE_MARKETS_TYPE,
	// RACING_MORE_MARKETS_TYPE_TITLE,
	RACING_SAME_RACE_MULTI_TYPE,
	RACING_SAME_RACE_MULTI_TYPE_TITLE,
	RACING_BET_TYPE_EACHWAY,
	RACING_EACHWAY_LABEL,
	// RACING_ISSURE_BET_TYPE,
	// RACING_ISSURE_BET_TYPE_TITLE,
} from '../../../../../common/constants/Racing';
const cssActiveTabItems = css`
	label: ActiveTabItems;

	display: flex;
	align-items: center;
	color: #c72128;
	font-weight: 700;
`;

const StyledExoticBetTypeFilters__TabsItem = styled(TabsItem)(
	(props) => css`
		label: ExoticBetTypeFilters__TabsItem;

		${props.checkboxSize === 'medium' &&
		css`
				line-height: 22px;
			`};
	`,
);
const cssInActiveTabItems = css`
	label: InActiveTabItems;

	display: flex;
	align-items: center;
`;

let bet_types = '';

const BetTypeFiltersBasic = (props) => {
	const {
		className,
		activeBetType,
		isBoxedSelected,
		selectBetType,
		exoticsAvailable,
		raceProductBetTypes,
		quaddieRaceNumbers,
		dailyDoubleRaceNumbers,
		availablebetproduct,
		t,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});
	// console.log(availablebetproduct.find((product) => product.id === 40))

	/**
	 * Returns the new bet type & boxed status
	 *
	 * @param betType
	 * @param boxed
	 */
	const handleSelectBetType = (betType, boxed = false) => {
		// no need to change bet type if selected bet type is already active
		if (activeBetType === betType && boxed === isBoxedSelected) {
			return;
		}
		// return the new bet type and checkbox state
		selectBetType(betType, boxed);
	};

	/**
	 * Handle the selecting of a win/place bet type
	 *
	 * @param e
	 * @param betType
	 */
	const changeBetType = (betType) => {
		// Always set the boxed flag to false when selecting the win/place bet type
		handleSelectBetType(betType, false);
		bet_types = betType;
	};

	return (
		<div>
			<TabsGroup className={componentClasses} groupEnd>
				{/* <Tabs alignment="right" padding={Tabs.paddings.SPACING_TIGHT} wrappingBorder>
         <TabsItem
           className={activeBetType !== RACING_ODD_GRID_BET_TYPE ? cssInActiveTabItems :cssActiveTabItems }
           active={activeBetType === RACING_ODD_GRID_BET_TYPE}
           key={RACING_ODD_GRID_BET_TYPE}
           action={changeBetType}
           pointer
           data={RACING_ODD_GRID_BET_TYPE}
         >
           {t(RACING_ODD_GRID_BET_TYPE_TITLE)}
         </TabsItem>
       </Tabs> */}
				<Tabs alignment="right" padding={Tabs.paddings.SPACING_TIGHT} wrappingBorder>
					<TabsItem
						className={activeBetType !== RACING_NON_EXOTIC_BET_TYPE ? cssInActiveTabItems : cssActiveTabItems}
						active={activeBetType === RACING_NON_EXOTIC_BET_TYPE}
						key={RACING_NON_EXOTIC_BET_TYPE}
						action={changeBetType}
						pointer
						data={RACING_NON_EXOTIC_BET_TYPE}
					>
						{t(RACING_NON_EXOTIC_BET_TYPE_TITLE)}
					</TabsItem>
				</Tabs>
				<Tabs alignment="right" padding={Tabs.paddings.SPACING_TIGHT} wrappingBorder>
					<TabsItem
						className={activeBetType !== RACING_BET_TYPE_EACHWAY ? cssInActiveTabItems : cssActiveTabItems}
						active={activeBetType === RACING_BET_TYPE_EACHWAY}
						key={RACING_BET_TYPE_EACHWAY}
						action={changeBetType}
						pointer
						data={RACING_BET_TYPE_EACHWAY}
					>
						{t(RACING_EACHWAY_LABEL)}
					</TabsItem>
				</Tabs>
				<Tabs alignment="right" padding={Tabs.paddings.SPACING_TIGHT} wrappingBorder>
					<TabsItem
						className={activeBetType !== RACING_SAME_RACE_MULTI_TYPE ? cssInActiveTabItems : cssActiveTabItems}
						active={activeBetType === RACING_SAME_RACE_MULTI_TYPE}
						key={RACING_SAME_RACE_MULTI_TYPE}
						action={changeBetType}
						pointer
						data={RACING_SAME_RACE_MULTI_TYPE}
					//  disabled={true}
					>
						{t(RACING_SAME_RACE_MULTI_TYPE_TITLE)}
					</TabsItem>
				</Tabs>
				<ExoticBetTypeFilters
					activeBetType={activeBetType !== RACING_NON_EXOTIC_BET_TYPE ? activeBetType : null}
					isBoxedSelected={isBoxedSelected}
					selectBetType={handleSelectBetType}
					raceProductBetTypes={raceProductBetTypes}
					quaddieRaceNumbers={quaddieRaceNumbers}
					dailyDoubleRaceNumbers={dailyDoubleRaceNumbers}
				/>

				{/* <Tabs alignment="right" padding={Tabs.paddings.SPACING_TIGHT} wrappingBorder>
					<TabsItem
						className={activeBetType !== RACING_MORE_MARKETS_TYPE ? cssInActiveTabItems : cssActiveTabItems}
						active={activeBetType === RACING_MORE_MARKETS_TYPE}
						key={RACING_MORE_MARKETS_TYPE}
						action={changeBetType}
						pointer
						data={RACING_MORE_MARKETS_TYPE}
					>
						{t(RACING_MORE_MARKETS_TYPE_TITLE)}
					</TabsItem>
				</Tabs> */}
			</TabsGroup>

			<TabsGroup className={componentClasses} groupEnd>
				{bet_types === 'MoreMarkets' && (
					<Tabs
						alignment="right"
						justifyAuto
						padding={Tabs.paddings.SPACING_TIGHT}
						wrappingBorder
						className={componentClasses}
					>
						{/* <TabsItem
							className={activeBetType !== RACING_ISSURE_BET_TYPE ? cssInActiveTabItems : cssActiveTabItems}
							active={activeBetType === RACING_ISSURE_BET_TYPE}
							key={RACING_ISSURE_BET_TYPE}
							action={handleSelectBetType}
							pointer
							data={RACING_ISSURE_BET_TYPE}
						>
							{t(RACING_ISSURE_BET_TYPE_TITLE)}
						</TabsItem> */}
						{/* <TabsItem
            className={activeBetType !== RACING_BET_TYPE_NOTTO_WIN_BET_TYPE ? cssInActiveTabItems :cssActiveTabItems }
            active={activeBetType === RACING_BET_TYPE_NOTTO_WIN_BET_TYPE}
            key={RACING_BET_TYPE_NOTTO_WIN_BET_TYPE}
            action={handleSelectBetType}
            pointer
            data={RACING_BET_TYPE_NOTTO_WIN_BET_TYPE}
          >
            {t(RACING_BET_TYPE_NOTTO_WIN_BET_TYPE_TITLE)}
          </TabsItem> */}

						{/*<TabsItem*/}
						{/*  className={activeBetType !== RACING_BET_TYPE_TRAINERT_4_BET_TYPE ? cssInActiveTabItems :cssActiveTabItems }*/}
						{/*  active={activeBetType === RACING_BET_TYPE_TRAINERT_4_BET_TYPE}*/}
						{/*  key={RACING_BET_TYPE_TRAINERT_4_BET_TYPE}*/}
						{/*  action={handleSelectBetType}*/}
						{/*  pointer*/}
						{/*  data={RACING_BET_TYPE_TRAINERT_4_BET_TYPE}*/}
						{/*>*/}
						{/*  {t(RACING_BET_TYPE_TRAINERT_4_BET_TYPE_TITLE)}*/}
						{/*</TabsItem>*/}
						{/*  <TabsItem*/}
						{/*    className={activeBetType !== RACING_BET_TYPE_TRAINER_WIN_BET_TYPE ? cssInActiveTabItems :cssActiveTabItems }*/}
						{/*    active={activeBetType === RACING_BET_TYPE_TRAINER_WIN_BET_TYPE}*/}
						{/*    key={RACING_BET_TYPE_TRAINER_WIN_BET_TYPE}*/}
						{/*    action={handleSelectBetType}*/}
						{/*    pointer*/}
						{/*    data={RACING_BET_TYPE_TRAINER_WIN_BET_TYPE}*/}
						{/*  >*/}
						{/*    {t(RACING_BET_TYPE_TRAINER_WIN_BET_TYPE_TITLE)}*/}
						{/*  </TabsItem>*/}
					</Tabs>
				)}
			</TabsGroup>

			<TabsGroup className={componentClasses} groupEnd>
				{exoticsAvailable && bet_types === 'Multiples' && (
					<ExoticBetTypeFilters
						activeBetType={activeBetType !== RACING_NON_EXOTIC_BET_TYPE ? activeBetType : null}
						isBoxedSelected={isBoxedSelected}
						selectBetType={handleSelectBetType}
						raceProductBetTypes={raceProductBetTypes}
						quaddieRaceNumbers={quaddieRaceNumbers}
						dailyDoubleRaceNumbers={dailyDoubleRaceNumbers}
					/>
				)}
			</TabsGroup>
		</div>
	);
};

BetTypeFiltersBasic.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Function to handle the selecting of a bet type */
	selectBetType: PropTypes.func.isRequired,

	/** The list of products available for the race */
	raceProductBetTypes: PropTypes.array,

	/** A collection of race numbers within this bet type */
	dailyDoubleRaceNumbers: PropTypes.string,

	/** A collection of race numbers within this bet type */
	quaddieRaceNumbers: PropTypes.string,

	/** Which bet type is currently active or selected */
	activeBetType: PropTypes.oneOf([RACING_NON_EXOTIC_BET_TYPE, ...RACING_EXOTIC_BET_TYPES]).isRequired,

	/** Whether the bet type selections are boxed or not */
	isBoxedSelected: PropTypes.bool,

	/** Are exotics available for the current race */
	exoticsAvailable: PropTypes.bool,

	/** Extra style class(es) to pass through */
	className: PropTypes.string,
};

BetTypeFiltersBasic.defaultProps = {
	raceProductBetTypes: RACING_BET_TYPES,
	isBoxedSelected: false,
	exoticsAvailable: true,
	className: null,
	quaddieRaceNumbers: '',
	dailyDoubleRaceNumbers: '',
};

export default withNamespaces()(BetTypeFiltersBasic);
