import styled, { css } from 'react-emotion';
import { application, spacings, typography, ui, media, Text } from '@tbh/ui-kit';

import { ListItem } from '@tbh/ui-kit';
import GBSNjRaceBettingAdd from '../../../Racing/GBSNjRaceBettingAdd/GBSNjRaceBettingAdd';
import { FONT_SIZE_12, WEIGHTS } from '../../../../../store/application/applicationConstants';

/**
 * Define all the selection statuses & racing type codes as defined in the racing constants file
 */
import { SELECTION_LATE_SCRATCHED_STATUS, RACING_BET_TYPE_MARGIN } from '../../../../../common/constants/Racing';

export const StyledUpRaceSelectionsListItem = styled(ListItem)`
	${(props) =>
		css`
			label: UpRaceSelectionsListItem;

			list-style: none;
			padding: ${spacings(props).tight}px 0;
			
			&:not(:last-of-type) {
				padding-bottom: 2px;
				border-bottom: 1px solid #e3e3e3;
			}

			&:nth-of-type(3n + 1):hover,
			&:hover {
				background: ${ui(props).color_3};
				//cursor: pointer;
			}

			${props.selection_status === SELECTION_LATE_SCRATCHED_STATUS &&
			css`
					&:before {
						display: block;
						position: absolute;
						background: ${ui(props).color_5};
						color: ${typography(props).color_1_alternate};
						padding: ${spacings(props).tight}px;
						width: 30px;
						height: 30px;
						border-radius: 20px;
						font-size: ${FONT_SIZE_12};
						line-height: 24px;
						text-align: center;
						content: 'LS';
					}
				`};
		`};
`;
export const StyledUpRaceSelectionsListItem__Wrapper = styled('div')(
	(props) =>
		css`
			label: UpRaceSelectionsListItem__Wrapper;

			// Set a min-height so the trends don't overlap
			//min-height: ${spacings(props).roomy}px;
			display: flex;
			align-items: center;
			height:  72px;
			padding: 8px 0px;

			display: flex;
			justify-content: space-between;
		`,
);

export const StyledGRSNtjRaceSelectionsListItem__Deductions = styled(Text)`
	${(props) =>
		css`
			label: GRSNtjRaceSelectionsListItem__Deductions;

			margin-right: ${spacings(props).compact}px;
		`};
`;

export const StyledGRSNtjRaceBettingAdd = styled(GBSNjRaceBettingAdd)(
	(props) => css`
		flex: 0 0 ${application(props).racing.odds_button_width}px;
		max-width: ${application(props).racing.odds_button_width}px;
		margin: 0 ${spacings(props).constrictive}px;
		text-align: center;
		font-size: ${FONT_SIZE_12};
		font-weight: ${WEIGHTS.bold};
		border-radius: 4px !important;

		// -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
		// -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
		// box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
		span {
			padding: 2.5px;
		}
		&:last-child {
			margin-right: 0;
		}

		${props.scratched &&
		css`
				display: none;
			`};

		${props.betType === RACING_BET_TYPE_MARGIN &&
		css`
				margin: 0;
			`};

		${props.small &&
		css`
				flex: 0 0 ${application(props).racing.odds_button_width_small}px;
				max-width: ${application(props).racing.odds_button_width_small}px;
			`};
	`,
	media(
		(props) => css`
			${props.small &&
			css`
					flex: 0 0 ${application(props).racing.odds_button_width}px;
					max-width: ${application(props).racing.odds_button_width}px;
				`};
		`,
		660,
	),
);

export const StyledUpRaceSelectionPricesList = styled('ul')`
	${(props) =>
		css`
			label: UpRaceSelectionPricesList;

			margin: 0;
			padding: 0;
			display: flex;
			flex-direction: column;
			gap: ${spacings(props).compact}px;
			height: 100%;
			justify-content: center;
			align-items: center;
		`};
`;

export const StyledUpPriceListItem = styled(ListItem)`
	${(props) =>
		css`
			label: UpPriceListItem;

			list-style: none;

			float: right !important;
			padding: 0;
		`};
`;
