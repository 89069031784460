import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SizeMe } from 'react-sizeme';
import { withNamespaces } from 'react-i18next';
import Route from '../../../components/controllers/Route/Route';

//Styles
import * as RV5HPS from './RacingV5PageStyles';

import { buildRacingRouteFromV5 } from '../../../routes/RacingNew';
import { createErrorBoundary } from '../../../containers/ErrorBoundary/ErrorBoundaryActions';
import DocumentTitle from '../../../components/controllers/Meta/DocumentTitle';
import DescriptionMeta from '../../../components/controllers/Meta/DescriptionMeta';
import {
  StyledRacingV5HomePage_Bet_Container,
  StyledRacingV5HomePage_LeftMenu,
  StyledRacingV5HomePage_Main_Container,
  StyledRacingV5HomePage_Main_Container_MainContent,
  StyledRacingV5HomePage_Main_Container_TopMenu
} from './RacingV5PageStyles';


class RacingV5Page extends Component {
  static propTypes = {

  };
  static defaultProps = {

  };
  constructor(props) {
    super(props);


    this.state = {
      showBetPrompt: false,
      isMobile: props.isPhoneDevice,
    };
  }
  componentDidMount() {

  }
  componentDidUpdate(prevProps) {

  }
  render() {
    const {
      t,
      brandName,
      currentRoute
    } = this.props;

    return (
      <SizeMe>
        {({ size }) => (
          <RV5HPS.StyledRacingV5HomePage backGround_color="#fff">
            <React.Fragment>
              <Route route={currentRoute} />
              <DocumentTitle>
                {`${t('RacingHomePage__Title')}${brandName ? ` ${t('at')} ${brandName}` : ''
                  }`}
              </DocumentTitle>
              <DescriptionMeta>
                {`${t('RacingHomePage__Description')}${brandName ? ` ${t('with')} ${brandName}` : ''}. ${t(
                  'RacingHomePage__DescriptionBonus',
                )}`}
              </DescriptionMeta>
            </React.Fragment>
            <StyledRacingV5HomePage_LeftMenu backGround_color="#CA1500">
              asasas
            </StyledRacingV5HomePage_LeftMenu>
            <StyledRacingV5HomePage_Main_Container backGround_color="#000">
              <StyledRacingV5HomePage_Main_Container_TopMenu backGround_color="#fff">

              </StyledRacingV5HomePage_Main_Container_TopMenu>

              <StyledRacingV5HomePage_Main_Container_MainContent backGround_color="#fff">

              </StyledRacingV5HomePage_Main_Container_MainContent>

            </StyledRacingV5HomePage_Main_Container>
            <StyledRacingV5HomePage_Bet_Container backGround_color="#971000">

            </StyledRacingV5HomePage_Bet_Container>
          </RV5HPS.StyledRacingV5HomePage>
        )}
      </SizeMe>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    currentRoute: buildRacingRouteFromV5(state),
    brandName: state.acl.brandDetails && state.acl.brandDetails.name,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({

});

export default withNamespaces()(
  createErrorBoundary(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(RacingV5Page),
  ),
);
