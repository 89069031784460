// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, ui } from '@tbh/ui-kit';

// Components
import { Button, Icon, Link, Text } from '@tbh/ui-kit';

const StyledVerificationStatus__ProgressBar = styled('div')`
	label: VerificationStatus__ProgressBar;

	${(props) => css`
		display: block;
		height: ${spacings(props).cozy}px;
		width: 100%;
		background-color: ${ui(props).color_3};
		border-radius: 6px;
		margin-bottom: ${spacings(props).cozy}px;

		&:after {
			display: block;
			height: 100%;
			width: 33%;
			content: '';
			background: ${ui(props).color_success};
			border-radius: 6px;
		}
	`};
`;

const StyledVerificationStatus__Link = styled(Link)`
	label: VerificationStatus__Link;

	${(props) => css`
		margin-top: ${spacings(props).cozy}px;
	`};
`;

const VerificationStatus = (props) => {
	const { className, onCompleteVerification, t } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<div className={componentClasses}>
			<Text paragraph strong>
				{t('VerificationStatus__Title')}
			</Text>

			<Text align="center" paragraph>
				<Icon color="warning" icon="trophy" size="4" />
			</Text>

			<StyledVerificationStatus__ProgressBar />

			<Text size="-1" align="center" paragraph>
				{t('VerificationStatus__Tasks', { from: 1, to: 3 })}
			</Text>

			<Button action={onCompleteVerification} type="secondary">
				{t('VerificationStatus__Complete')}
			</Button>

			<StyledVerificationStatus__Link
				href="https://help.topbetta.com.au/knowledge-base/verifying-your-identity/"
				size="-2"
				align="center"
				target="blank"
				underline
			>
				{t('VerificationStatus__Why')}
			</StyledVerificationStatus__Link>
		</div>
	);
};

VerificationStatus.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Opens the intercom so the user can complete the verification */
	onCompleteVerification: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

VerificationStatus.defaultProps = {
	className: '',
};

export default withNamespaces()(VerificationStatus);
