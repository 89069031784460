import { removeObjectInArraySlice, replaceObjectInArraySlice } from '../../common/actions/actionHelpers';

import {
	BET_PLACEMENT_RESET,
	BET_PLACEMENT_CLEAR_BETS,
	BET_PLACEMENT_ADD_BET,
	BET_PLACEMENT_ADD_TOURNAMENT_BET,
	BET_PLACEMENT_SET_BETS,
	BET_PLACEMENT_SET_LEGS,
	BET_PLACEMENT_ADD_LEG,
	BET_PLACEMENT_REMOVE_LEG,
	BET_PLACEMENT_REPLACE_LEG,
	BET_PLACEMENT_SET_SELECTIONS,
	BET_PLACEMENT_ADD_TOURNAMENT_SELECTION,
	BET_PLACEMENT_ADD_SELECTION,
	BET_PLACEMENT_REMOVE_SELECTION,
	BET_PLACEMENT_REPLACE_SELECTION,
	BET_PLACEMENT_SET_ERROR,
	BET_PLACEMENT_UPDATE,
	USE_BONUS_BET,
	BET_PLACEMENT_SET_SRM,
	BET_PLACEMENT_ADD_SRM,
	BET_PLACEMENT_REMOVE_SRM,
	BET_PLACEMENT_REPLACE_SRM,
	BET_PLACEMENT_SET_LOADING,
	BET_PLACEMENT_CHANGE_SELECTION,
	BET_PLACEMENT_QUADDIES_ADD,
	BET_PLACEMENT_QUADDIES_REMOVE,
	BET_PLACEMENT_QUADDIES_REPLACE,
	BET_PLACEMENT_EXOTICS_ADD,
	BET_PLACEMENT_EXOTICS_REMOVE,
	BET_PLACEMENT_EXOTICS_REPLACE,
	BET_PLACEMENT_ADD_RACE,
	BET_PLACEMENT_REMOVE_RACE,
	BET_PLACEMENT_CLEAR_RACES,
	BET_PLACEMENT_REPLACE_RACE,
	BETSLIP_TAB,
} from './betPlacementActionTypes';

import { MAX_MULTI_SELECTIONS } from '../entities/constants/BetConstants';
import { TAB_BETSLIP } from './betPlacementConstants';

const initialState = {
	bets: [],
	boxed_flag: false,
	errorMessage: '',
	free_credit_flag: false,
	isLoading: false,
	origin: 0,
	selections: [],
	legs: [],
	SRMs: [],
	quaddies: [],
	exotics: [],
	races: [],
	maximumSelections: MAX_MULTI_SELECTIONS,
	isUseBonusBet: true,
	selectedTab: TAB_BETSLIP, // 'BETSLIP' | 'PENDING BETS'
};

const betPlacement = (state = initialState, action) => {
	switch (action.type) {
		case BET_PLACEMENT_RESET:
			return {
				...initialState,
				...action.payload,
			};

		case BET_PLACEMENT_CLEAR_BETS:
			return {
				...state,
				bets: [],
			};

		case BET_PLACEMENT_ADD_BET:
			return {
				...state,
				bets: [...state.bets, ...action.payload],
			};

		case BET_PLACEMENT_ADD_TOURNAMENT_SELECTION:
			return {
				...state,
				...action.payload,
			};

		case BET_PLACEMENT_ADD_TOURNAMENT_BET:
			return {
				...state,
				...action.payload,
			};

		case BET_PLACEMENT_SET_BETS:
			return {
				...state,
				bets: [...action.payload],
			};

		case BET_PLACEMENT_SET_LEGS:
			return {
				...state,
				legs: [...action.payload],
			};

		case BET_PLACEMENT_ADD_LEG:
			return {
				...state,
				legs: [...state.legs, ...action.payload],
			};

		case BET_PLACEMENT_REMOVE_LEG:
			return {
				...state,
				legs: removeObjectInArraySlice(action.payload, state.legs),
			};

		case BET_PLACEMENT_REPLACE_LEG:
			return {
				...state,
				legs: replaceObjectInArraySlice(action.payload, state.legs),
			};

		case BET_PLACEMENT_SET_SELECTIONS:
			return {
				...state,
				selections: [...action.payload],
			};

		case BET_PLACEMENT_ADD_SELECTION:
			return {
				...state,
				selections: [...state.selections, ...action.payload],
			};

		case BET_PLACEMENT_REMOVE_SELECTION:
			return {
				...state,
				selections: state.selections.filter(
					(selection) =>
						selection.id !== action.payload.id ||
						selection.product_id !== action.payload.product_id ||
						selection.racingBetType !== action.payload.selectedBetType,
				),
			};

		case BET_PLACEMENT_REPLACE_SELECTION:
			return {
				...state,
				// selections: replaceObjectInArraySlice(action.payload, state.selections),
				selections: state.selections.map((s) => {
					if (
						s.id === action.payload.id &&
						s.product_id === action.payload.product_id &&
						(s.racingBetType === action.payload.racingBetType || s.racingBetType === action.payload.selectedBetType)
					) {
						return action.payload;
					}
					return s;
				}),
			};
		case BET_PLACEMENT_CHANGE_SELECTION:
			return {
				...state,
				selections: state.selections.map((s) => {
					if (
						s.id === action.payload.oldSelection.id &&
						s.product_id === action.payload.oldSelection.product_id &&
						(s.racingBetType === action.payload.oldSelection.racingBetType ||
							s.racingBetType === action.payload.oldSelection.selectedBetType)
					) {
						return action.payload.newSelection;
					}
					return s;
				}),
			};

		case BET_PLACEMENT_SET_ERROR:
			return {
				...state,
				errorMessage: action.payload,
			};

		case BET_PLACEMENT_UPDATE:
			return {
				...state,
				...action.payload,
			};

		case USE_BONUS_BET:
			return {
				...state,
				isUseBonusBet: action.payload,
			};

		case BET_PLACEMENT_SET_SRM:
			return {
				...state,
				SRMs: [...action.payload],
			};

		case BET_PLACEMENT_ADD_SRM:
			return {
				...state,
				SRMs: [...state.SRMs, ...action.payload],
			};

		case BET_PLACEMENT_REMOVE_SRM:
			return {
				...state,
				SRMs: removeObjectInArraySlice(action.payload, state.SRMs),
			};

		case BET_PLACEMENT_REPLACE_SRM:
			return {
				...state,
				SRMs: replaceObjectInArraySlice(action.payload, state.SRMs),
			};

		case BET_PLACEMENT_QUADDIES_ADD:
			return {
				...state,
				quaddies: [...state.quaddies, action.payload],
			};

		case BET_PLACEMENT_QUADDIES_REMOVE:
			return {
				...state,
				quaddies: removeObjectInArraySlice(action.payload, state.quaddies),
			};

		case BET_PLACEMENT_QUADDIES_REPLACE:
			return {
				...state,
				quaddies: replaceObjectInArraySlice(action.payload, state.quaddies),
			};
		case BET_PLACEMENT_EXOTICS_ADD:
			return {
				...state,
				exotics: [...state.exotics, action.payload],
			};

		case BET_PLACEMENT_EXOTICS_REMOVE:
			return {
				...state,
				exotics: removeObjectInArraySlice(action.payload, state.exotics),
			};

		case BET_PLACEMENT_EXOTICS_REPLACE:
			return {
				...state,
				exotics: replaceObjectInArraySlice(action.payload, state.exotics),
			};

		case BET_PLACEMENT_SET_LOADING:
			return {
				...state,
				isLoading: action.payload,
			};

		case BET_PLACEMENT_ADD_RACE:
			return {
				...state,
				races: [...state.races, action.payload],
			};

		case BET_PLACEMENT_REMOVE_RACE:
			return {
				...state,
				races: state.races.filter(({ id }) => id != action.payload),
			};
		case BET_PLACEMENT_REPLACE_RACE:
			return {
				...state,
				races: replaceObjectInArraySlice(action.payload, state.races),
			};

		case BET_PLACEMENT_CLEAR_RACES:
			return {
				...state,
				races: [],
			};

		case BETSLIP_TAB:
			return {
				...state,
				selectedTab: action.payload,
			};

		default:
			return state;
	}
};

export default betPlacement;
