import styled, { css } from 'react-emotion';
import { spacings, media } from '@tbh/ui-kit';

import { Breadcrumbs } from '@tbh/ui-kit';

export const StyledGRSSportsHomePage = styled('div')(
	(props) =>
		css`
			label: GRSSportsHomePage;

			margin: ${spacings(props).compact}px;
		`,
	media(
		css`
			display: flex;
			flex-wrap: wrap;
		`,
		768,
	),
);

export const StyledGRSSportsHomePage__Breadcrumbs = styled(Breadcrumbs)(
	(props) =>
		css`
			label: GRSSportsHomePage__Breadcrumbs;

			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-bottom: ${spacings(props).compact}px;
		`,
);
