import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { Trans, withNamespaces } from 'react-i18next';
import { EVENT_KEY_ENTER } from '../../../../common/constants/Application';

import { Button, EmailInput, Text } from '@tbh/ui-kit';

const PasswordResetRequest = (props) => {
	const {
		t,
		handleChange,
		resetPassword,
		email,
		enableRequest,
		resetPasswordError,
		loadingResetPassword,
		className,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	/**
	 * Submit form when pressing enter
	 */
	const onKeyDown = (event) => {
		if (event.key === EVENT_KEY_ENTER && enableRequest) {
			resetPassword();
		}
	};

	return (
		<div className={componentClasses}>
			<Trans i18nKey="PasswordResetRequest">
				<Text size="-1" strong>
					No Worries
				</Text>
				<Text size="-1">We all have days like this!</Text>
				<Text size="-1" paragraph>
					{"We'll get you back on track in no time."}
				</Text>

				<Text size="-1" strong>
					Reset Your Password Easily
				</Text>
				<Text size="-1" paragraph>
					Enter your email (the one you used to register).
				</Text>

				<EmailInput
					label={t('EmailAddress')}
					name="email"
					value={email}
					onChange={handleChange}
					error={resetPasswordError}
					margin="compact"
					onKeyDown={onKeyDown}
				/>
				<Button type="primary" action={resetPassword} loading={loadingResetPassword} disabled={!enableRequest} block>
					Please Send Reset Email
				</Button>
			</Trans>
		</div>
	);
};

PasswordResetRequest.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Action to call when an input is changed */
	handleChange: PropTypes.func.isRequired,

	/** Action to reset a users' password */
	resetPassword: PropTypes.func.isRequired,

	/** The users' email for login */
	email: PropTypes.string,

	/** Should ethe request button be enabled */
	enableRequest: PropTypes.bool,

	/** Any errors received when trying to reset a password */
	resetPasswordError: PropTypes.string,

	/** If we are waiting for a reset password response */
	loadingResetPassword: PropTypes.bool,

	/** Extra class names */
	className: PropTypes.string,
};

PasswordResetRequest.defaultProps = {
	email: '',
	enableRequest: false,
	resetPasswordError: null,
	loadingResetPassword: false,
	className: null,
};

export default withNamespaces()(PasswordResetRequest);
