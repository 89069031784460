import {
    SPORT_NEXTJUMP_HOME_SET_CONTAINER_LOADING_MASK,
    GRS_SPORTS_HOME_SET_NTJ_IDS,
    GRS_NJ_HOME_SET_SHOWING_NTJ_SPORTS,
    GRS_SPORTS_HOME_SET_SELECTED_SPORT,
    GRS_SPORTS_HOME_SET_SELECTED_BASE_COMPETITION,
    GRS_SPORTS_HOME_SET_SELECTED_COMPETITION,
    GRS_SPORTS_HOME_SET_SELECTED_EVENT,
    GRS_SPORTS_HOME_SET_SELECTED_MARKET,
    GRS_SPORTS_SET_LOADING_EVENTS,
  } from './SportsNextJumpHomeActionType';

const initialState = {
    selectedSport: null,
    selectedSportName: null,
    selectedBaseCompetition: null,
    selectedCompetition: null,
    selectedEvent: null,
	selectedMarket: null,
    loadingContainer: undefined,
    nextToJumpSports: null,
    showingNTJSports: [],
    loadingEvents: false,
};
  
export default function(state = initialState, action) {
    let sportsName;
    switch (action.type) {
        case GRS_SPORTS_HOME_SET_SELECTED_SPORT:
			sportsName = (action.payload && action.payload.name) || state.selectedSportsName;

			if (!action.payload || !action.payload.id) {
				sportsName = null;
			}

			return {
				...state,
				selectedSport: (action.payload && action.payload.id) || initialState.selectedSport,
				selectedSportName: sportsName,
            };
        case GRS_SPORTS_HOME_SET_SELECTED_BASE_COMPETITION:
            return {
                ...state,
                selectedBaseCompetition: action.payload || initialState.selectedBaseCompetition
            };
        case GRS_SPORTS_HOME_SET_SELECTED_COMPETITION:
            return {
                ...state,
                selectedCompetition: action.payload || initialState.selectedCompetition
            };
        case GRS_SPORTS_HOME_SET_SELECTED_EVENT:
            return {
                ...state,
                selectedEvent: action.payload || initialState.selectedEvent
            }; 
        case GRS_SPORTS_HOME_SET_SELECTED_MARKET:
            return {
                ...state,
                selectedMarket: action.payload || initialState.selectedMarket
            };           
        case GRS_NJ_HOME_SET_SHOWING_NTJ_SPORTS:
			return {
				...state,
				showingNTJSports: action.payload,
			};
        case GRS_SPORTS_HOME_SET_NTJ_IDS:
            return {
				...state,
				nextToJumpSports: action.payload
			};
        case SPORT_NEXTJUMP_HOME_SET_CONTAINER_LOADING_MASK:
        return{
            ...state,
            loadingContainer: false,
        };
        case GRS_SPORTS_SET_LOADING_EVENTS:
			return {
				...state,
				loadingEvents: action.payload
			};
        default:
        return state;
    }
};