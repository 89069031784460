import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled, { css } from 'react-emotion';
import { spacings, media, brand } from '@tbh/ui-kit';

import { Image, HeaderBar, PlotElements } from '@tbh/ui-kit';
import TeamsEventHeaderStartDate from './TeamsEventHeaderStartDate/TeamsEventHeaderStartDate';
import Svg from '../../../../ui-components/Svg/Svg';

import { convertToLocalDateTime } from '../../../../../common/Date';
import { GOAT_SPORTS_BUMP_ICON_SIZE } from '../../../../../common/constants/GoatProducts';

const StyledTeamsEventHeader = styled(HeaderBar)(
	(props) => css`
		label: TeamsEventHeader;

		overflow: visible;
		padding: ${spacings(props).compact}px 0;
		margin-bottom: ${spacings(props).tight}px;
		border-radius: 50px;

		${HeaderBar.Label} {
			display: flex;
			align-items: center;
			padding-right: 0;
		}
	`,
);

const StyledTeamsEventHeader__TeamImage = styled(Image)`
	label: TeamsEventHeader__TeamImage;

	width: 45px;
	height: 45px;
	border-radius:50%;

	${media(
		css`
			width: 35px;
			height: 35px;
			border-radius:50%;
			margin-left:5px;
			margin-right:5px;
		`,
		500,
	)};
`;

const StyledTeamsEventHeader__TeamNameFirst = styled('span')(
	(props) => css`
		label: TeamsEventHeader__TeamNameFirst;

		flex: 1 1 auto;
		margin-left: ${spacings(props).compact}px;
		text-overflow: ellipsis;
		overflow: hidden;
		max-height: 45px;
	`,
);

const StyledTeamsEventHeader__TeamNameSecond = styled('span')(
	(props) => css`
		label: TeamsEventHeader__TeamNameSecond;

		flex: 1 1 auto;
		margin-right: ${spacings(props).compact}px;
		text-align: right;
		text-overflow: ellipsis;
		overflow: hidden;
		max-height: 45px;
	`,
);

const StyledTeamsEventHeader__StartDateAndIcon = styled(PlotElements)(
	(props) => css`
		label: TeamsEventHeader__StartDateAndIcon;

		flex: 1 1 auto;
		display: flex;
		margin-right: ${spacings(props).compact}px;
	`,
);

const StyledTeamsEventHeader__Icon = styled(Svg)(
	(props) => css`
		label: TeamsEventHeader__Icon;

		background: ${brand(props).color_1};
		flex: 1 0 ${spacings(props).comfortable}px;
		border-radius: 50%;
		max-width: ${spacings(props).comfortable}px;
	`,
);

/**
 * Build the component for the team image
 *
 * @param team
 * @param position
 * @param props
 */
const buildTeamImage = (team, position, props = {}) => {
	if (!team.icon) {
		return false;
	}

	return <StyledTeamsEventHeader__TeamImage src={team.icon} position={position} {...props} />;
};

const TeamsEventHeader = ({ teamA, teamB, eventStart, onClick, priceBump, route, size }) => {
	const localEventStart = convertToLocalDateTime(eventStart);

	return (
		<StyledTeamsEventHeader href={route} type={HeaderBar.types.PRIMARY} strong action={onClick}>
			<PlotElements
				align="start"
				className={css`
					flex: 1 1 50%;
				`}
			>
				{buildTeamImage(teamA, 'first', { size })}
				<StyledTeamsEventHeader__TeamNameFirst>{teamA.name}</StyledTeamsEventHeader__TeamNameFirst>
			</PlotElements>

			<StyledTeamsEventHeader__StartDateAndIcon>
				<TeamsEventHeaderStartDate
					className={css`
						white-space: nowrap;
					`}
					localEventStart={localEventStart}
				/>
				{priceBump && (
					<StyledTeamsEventHeader__Icon
						name="goat-bump"
						width={GOAT_SPORTS_BUMP_ICON_SIZE}
						height={GOAT_SPORTS_BUMP_ICON_SIZE}
					/>
				)}
			</StyledTeamsEventHeader__StartDateAndIcon>
			<PlotElements
				align="end"
				className={css`
					flex: 1 1 50%;
				`}
			>
				<StyledTeamsEventHeader__TeamNameSecond>{teamB.name}</StyledTeamsEventHeader__TeamNameSecond>
				{buildTeamImage(teamB, 'second', { size })}
			</PlotElements>
		</StyledTeamsEventHeader>
	);
};
const TeamShape = PropTypes.shape({
	/** The ID of the team */
	id: PropTypes.number.isRequired,

	/** The name of the team */
	name: PropTypes.string.isRequired,

	/** The team icon */
	icon: PropTypes.string,
});

TeamsEventHeader.propTypes = {
	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** The moment when the event starts */
	eventStart: PropTypes.instanceOf(moment).isRequired,

	/** The first team in the event */
	teamA: TeamShape.isRequired,

	/** The second team in the event */
	teamB: TeamShape.isRequired,

	/** Action to fire when the event is clicked on */
	onClick: PropTypes.func,

	/** The HREF route for the team event */
	route: PropTypes.string,

	/** Price bump product */
	priceBump: PropTypes.bool,
};

TeamsEventHeader.defaultProps = {
	onClick: function() {},
	priceBump: false,
	route: undefined,
};

export default TeamsEventHeader;
