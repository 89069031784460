import React from 'react';
import styled, { css } from 'react-emotion';
import {
	filterCountryWiseMeetingGroups,
	filterRaceTypeWiseMeetings,
	SortMeetingGroupsByRaceTime,
} from '../../../../store/entities/selectors/MeetingSelectorsGRS';
import { ArrowIcon, Text, Ticker } from '@tbh/ui-kit';
import { connect } from 'react-redux';
import CountryImage from '../CountryImage/CountryImage';
import { MeetingCountDown, StatusBox } from '../MeetingsAndRacesTable/MeetingsAndRacesTable';
import { buildMeetingGroupsFromGroupByType } from '../../../../pages/GRSNewRacing/RacingNewHome/racingSelectorsGRS';
import { navigateToMeeting, navigateToRaceWithType } from '../../../../store/GRSracingHome/GRSracingHomeActions';
import { COLORS } from '@tbh/ui-kit/src/theme/colours';
import moment from 'moment';
import { scrollToTop } from '../../../../store/application/applicationActions';
import { RACE_SELLING_STATUS } from '../../../../common/constants/Racing';

export const MeetingListContainer = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 0px 0px 4px 4px;
	gap: 4px;
	width: 100%;
`;

export const MeetingCardContainer = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px;
	border-bottom: 1px solid ${COLORS.grays[300]};
	width: 100%;
`;

export const MeetingCardLeft = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
	gap: 8px;
`;

export const MeetingContainerWrapper = styled('div')`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid ${COLORS.grays[300]};
	background-color: #fff;
	border-radius: 4px;
`;

const MeetingCard = ({ meeting, navigateToRaceWithType, size }) => {
	const [showRaceDropdown, setShowRaceDropdown] = React.useState(false);

	return (
		<MeetingContainerWrapper>
			<MeetingCardContainer onClick={() => setShowRaceDropdown(!showRaceDropdown)}>
				<MeetingCardLeft>
					<CountryImage
						country={meeting.country === 'INT' ? meeting.state : meeting.country}
						className={css`
							width: 24px;
							height: 24px;
							border-radius: 2px;
						`}
					/>
					<div>
						<Text size="-3">
							{meeting.name} ({meeting.state})
						</Text>
						{meeting.nextSellingRaceNumber && (
							<Text size="-4" type="dark">
								Race {meeting.nextSellingRaceNumber}
							</Text>
						)}
					</div>
				</MeetingCardLeft>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						gap: '8px',
						justifyContent: 'end',
					}}
				>
					{meeting.status != 'selling' && (
						<StatusBox size="-3" align="center" strong status={meeting.status}>
							{meeting.status.replaceAll('_', ' ').toUpperCase()}
						</StatusBox>
					)}
					{meeting.nextSellingRace && (
						<Ticker every={1000}>
							<MeetingCountDown nextSellingRace={meeting.nextSellingRace} />
						</Ticker>
					)}
					<ArrowIcon direction={showRaceDropdown ? 'down' : 'up'} color="black" size="-1" />
				</div>
			</MeetingCardContainer>
			{showRaceDropdown && (
				<RaceDropdown
					meetingId={meeting.id}
					races={
						meeting.status != 'selling' ? meeting.races : meeting.races.filter((r) => r.status === RACE_SELLING_STATUS)
					}
					navigateToRaceWithType={navigateToRaceWithType}
					size={size}
				/>
			)}
		</MeetingContainerWrapper>
	);
};

const RaceDropdownContainer = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 0px 0px 4px 4px;
	border-top: 1px solid #666666;
	width: 100%;
`;

const RaceDropdownCard = styled('div')(
	(props) => css`
		display: flex;
		justify-content: space-between;
		width: 100%;
		${props.isOdd && `background-color: #ececeb;`}
		padding: 8px;
	`,
);

const RaceNumber = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 4px;
	width: 36px;
	backgorund-color: #f5f5f5;
	border-radius: 4px;
	border: 1px solid #666666;
`;

const buildRaceLabel = (raceClass, raceDistance, racePrize) => {
	let string = '';
	if (raceDistance) {
		string += raceDistance + 'm';
	}

	if (raceClass) {
		if (string.length > 0) string += ' | ';
		string += raceClass;
	}

	if (racePrize !== 0 && !!racePrize) {
		if (string.length > 0) string += ' | ';
		string += racePrize;
	}

	return string;
};

const RaceDropdown = ({ meetingId, races, navigateToRaceWithType, size }) => {
	return (
		<RaceDropdownContainer>
			{races.map((r, i) => (
				<RaceDropdownCard
					isOdd={i % 2 != 0}
					key={r.id}
					onClick={() => navigateToRaceWithType(r.type, r.start_date, r.id, meetingId)}
				>
					{/* Outer container to align all elements in a row */}
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							width: '100%',
							justifyContent: 'space-between',
							alignItems: 'center',
							gap: '16px',
						}}
					>
						{/* Left Section: Race Number */}
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
							<RaceNumber>
								<Text size="-1">R{r.number}</Text>
							</RaceNumber>
						</div>

						{/* Middle Section: Race Name and Details */}
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								flexGrow: 1,
								gap: '4px',
								width: 'calc(100% - 36px - 48px - 32px)',
							}}
						>
							<Text
								size="-2"
								style={{
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
							>
								{r.name}
							</Text>
							<Text size="-3" type="light">
								{buildRaceLabel(r.class, r.distance, r.prize)}
							</Text>
						</div>

						{/* Right Section: Start Time */}
						<Text
							size="-3"
							style={{
								whiteSpace: 'nowrap',
								width: '48px',
							}}
						>
							{moment(r.start_date).format('h:mm A')}
						</Text>
					</div>
				</RaceDropdownCard>
			))}
		</RaceDropdownContainer>
	);
};

export const MeetingListMobile = ({
	filterRaceTypeList,
	filteredCountryList,
	meetingGroups,
	navigateMeeting,
	navigateToRaceWithType,
	size,
}) => {
	let filterMeetingGroup = filterRaceTypeWiseMeetings(meetingGroups, filterRaceTypeList);

	filterMeetingGroup = filterCountryWiseMeetingGroups(filterMeetingGroup, filteredCountryList);

	filterMeetingGroup = SortMeetingGroupsByRaceTime(filterMeetingGroup);
	let isEmpty = filterMeetingGroup.length === 0 || filterMeetingGroup.every((m) => m.meetings.length === 0);

	return (
		<MeetingListContainer>
			{filterMeetingGroup.map(({ meetings }, index) =>
				meetings.map((m) => <MeetingCard key={m.id} meeting={m} navigateToRaceWithType={navigateToRaceWithType} />),
			)}

			{isEmpty && (
				<MeetingCardContainer>
					<Text size="-3" align="center" style={{ width: '100%' }}>
						No meetings available
					</Text>
				</MeetingCardContainer>
			)}
		</MeetingListContainer>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		meetingGroups: buildMeetingGroupsFromGroupByType(state, ownProps.showOnlyMeetingGroup),
		loadingMeeting: state.grsRacingHome.loadingMeeting,
		showQuaddieRaces: state.grsRacingHome.showQuaddieRaces,
		showDailyDoubleRaces: state.grsRacingHome.showDailyDoubleRaces,
	};
};

const mapDispatchToProps = (dispatch) => ({
	navigateToRaceWithType: (selectedType, selectedDate, raceId, meetingId) => {
		scrollToTop();
		return dispatch(navigateToRaceWithType(selectedType, selectedDate, raceId, meetingId));
	},
	navigateMeeting: (meetingId) => dispatch(navigateToMeeting(meetingId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MeetingListMobile);
