import { RACING_NEW_SUB_APP, RACING_NEW_BASE_URL } from '../pages/GRSNewRacing/RacingNewConstrants';
import { closeSideBetSlip, scrollToTop, show404 } from '../store/application/applicationActions';
import { RACING_HOME_DONE__BATCH, RACING_HOME_LOADING__BATCH } from '../store/GRSracingHome/GRSracingHomeActionType';
import { batchActions } from 'redux-batched-actions';
import {
	SetRequestId,
	resetRacingFilters,
	setContainerLoadingMask,
	setExoticPools,
	setMeetingLoadingMask,
	setRaceTypeFilter,
	setSelectedDate,
	setSelectedType,
	setShowingMeetings,
	setShowingNTJRaces,
} from '../store/GRSracingHome/GRSracingHomeActions';
import {
	fetchMeetingsAndRaces,
	fetchMeetingWithNextRaceSelections,
	fetchMeetingAndRacesFilterByDateAndType,
	fetchMeetingAndRacesFilterByDateAndType_GRS,
} from '../store/entities/actions/MeetingActions';

import { getLoadedRaces } from '../store/entities/actions/RaceActions';

import moment from 'moment';
import { serverDateTime } from '../legacy/core/format';
import { updateMeeting } from './../store/entities/actions/MeetingActions';
import { fetchNextToJumpRaces_GRS } from '../store/entities/actions/NextToJumpRaceActions';

const { dispatch, getState } = App.store;
const GRSRouter = Backbone.Router.extend({
	routes: {
		[RACING_NEW_BASE_URL]: 'showTodaysRaces',
		[RACING_NEW_BASE_URL + '/:date']: 'showRacesForDate',
		[RACING_NEW_BASE_URL + '/:type/:date']: 'showRacesForDateWithType',
		[RACING_NEW_BASE_URL + '/:type/:date/:meeting']: 'showRacesForDateWithType',
		[RACING_NEW_BASE_URL + '/:type/:date/:meeting/race-:raceNumber-:raceId-:meetingId']: 'showRaceGRS',
		/**
		 * Routes that should redirect to welcome
		 */
	},
	/**
	 * Get today meeings
	 */
	showTodaysRaces: async () => {
		let userDate = moment();
		let serverDate = serverDateTime(userDate);
		dispatch(
			batchActions(
				[
					setContainerLoadingMask(true),
					setMeetingLoadingMask(false),
					resetRacingFilters(),
					setSelectedDate(serverDate),
					closeSideBetSlip(true),
				],
				RACING_HOME_LOADING__BATCH,
			),
		);

		App.startSubApp(RACING_NEW_SUB_APP);

		try {
			let requestId = new Date().getTime() * Math.random();
			dispatch(SetRequestId(requestId));
			let [response, ntjResponse] = await Promise.all([
				dispatch(fetchMeetingsAndRaces()),
				dispatch(fetchNextToJumpRaces_GRS()),
			]);

			if (requestId !== getState().grsRacingHome.requestId) {
				return;
			}

			scrollToTop();
			dispatch(
				batchActions(
					[
						setShowingMeetings(response.meetingIds),
						setShowingNTJRaces(ntjResponse.ntjRaceIds),
						setExoticPools(response.exoticPools),
						setContainerLoadingMask(false),
						resetRacingFilters(),
					],
					RACING_HOME_DONE__BATCH,
				),
			);
		} catch (e) {
			//console.log(e);
			dispatch(show404());
		}
	},
	/**
	 * Show all race type by date
	 * @param {*} date
	 */
	showRacesForDate: async function(date) {
		let userDate = moment(date);
		let serverDate = serverDateTime(userDate);
		if (!userDate.isValid()) {
			return this.showTodaysRaces();
		}
		dispatch(
			batchActions(
				[
					setContainerLoadingMask(true),
					setMeetingLoadingMask(false),
					resetRacingFilters(),
					setSelectedDate(serverDate),
				],
				RACING_HOME_LOADING__BATCH,
			),
		);
		App.startSubApp(RACING_NEW_SUB_APP);
		try {
			let requestId = new Date().getTime() * Math.random();
			dispatch(SetRequestId(requestId));
			let [response, ntjResponse] = await Promise.all([
				dispatch(fetchMeetingsAndRaces(serverDate.format('YYYY-MM-DD'))),
				dispatch(fetchNextToJumpRaces_GRS()),
			]);
			if (requestId !== getState().grsRacingHome.requestId) {
				return;
			}
			scrollToTop();

			dispatch(
				batchActions(
					[
						setShowingNTJRaces(ntjResponse.ntjRaceIds),
						setShowingMeetings(response.meetingIds),
						setExoticPools(response.exoticPools),
						setContainerLoadingMask(false),
						resetRacingFilters(),
						setSelectedDate(serverDate),
					],
					RACING_HOME_DONE__BATCH,
				),
			);
		} catch (e) {
			//console.log(e);
			dispatch(show404());
		}
	},

	/**
	 * show races by selecing date and meeting type
	 * @HW 12Dec2019
	 * @param {date} date
	 * @param {string} type
	 */
	showRacesForDateWithType: async function(type, date) {
		let userDate = moment(date);

		if (!userDate.isValid()) {
			return this.showTodaysRaces();
		}

		dispatch(
			batchActions(
				[
					setContainerLoadingMask(true),
					setMeetingLoadingMask(false),
					resetRacingFilters(),
					setSelectedType(type.toUpperCase()),
					closeSideBetSlip(true),
					setSelectedDate(userDate),
				],
				RACING_HOME_LOADING__BATCH,
			),
		);

		dispatch(setRaceTypeFilter(type.toUpperCase(), true));

		App.startSubApp(RACING_NEW_SUB_APP);

		try {
			let requestId = new Date().getTime() * Math.random();
			dispatch(SetRequestId(requestId));
			let serverDate = serverDateTime(userDate);
			let [response, ntjResponse] = await Promise.all([
				dispatch(fetchMeetingAndRacesFilterByDateAndType(serverDate.format('YYYY-MM-DD'), type)),
				dispatch(fetchNextToJumpRaces_GRS()),
			]);

			if (requestId !== getState().grsRacingHome.requestId) {
				return;
			}

			scrollToTop();

			dispatch(
				batchActions(
					[
						setShowingNTJRaces(ntjResponse.ntjRaceIds),
						setShowingMeetings(response.meetingIds),
						setExoticPools(response.exoticPools),
						setContainerLoadingMask(false),
						resetRacingFilters(),
						setSelectedType(type.toUpperCase()),
						setSelectedDate(userDate),
					],
					RACING_HOME_DONE__BATCH,
				),
			);

			dispatch(setRaceTypeFilter(type.toUpperCase(), true));
		} catch (e) {
			//console.log(e);
			dispatch(show404());
		}
	},

	/**
	 * Change ShowRaceGRS router
	 * @HW 25Nov2019
	 */
	showRaceGRS: async (type, date, meeting, raceNumber, raceId, meetingId) => {
		//console.log('raceId', raceId, 'meetingId', meetingId);
		raceId = Number.parseInt(raceId);
		meetingId = Number.parseInt(meetingId);
		//console.log('raceId', raceId, 'meetingId', meetingId);
		let userDate = serverDateTime(moment(date));
		if (!userDate.isValid()) {
			// return this.showRacesForDateWithType(type,userDate);
			return this.showTodaysRaces();
		}

		const loadedRaces = dispatch(getLoadedRaces());
		const raceWasLoadedPreviously = loadedRaces.some((race) => race.id === raceId);

		if (Number.isNaN(raceId) || Number.isNaN(meetingId)) {
			//return this.showRacesForDateWithType(type, userDate);
			return this.showTodaysRaces();
		}

		App.startSubApp(RACING_NEW_SUB_APP);

		if (raceWasLoadedPreviously) {
			dispatch(SetRequestId(null));
			dispatch(
				batchActions(
					[
						resetRacingFilters(raceId, meetingId),
						setMeetingLoadingMask(false),
						setSelectedType(type),
						setSelectedDate(userDate),
					],
					RACING_HOME_DONE__BATCH,
				),
			);
		} else {
			dispatch(
				batchActions(
					[
						setContainerLoadingMask(false),
						setMeetingLoadingMask(true),
						resetRacingFilters(raceId, meetingId),
						setSelectedType(type),
						setSelectedDate(userDate),
					],
					RACING_HOME_LOADING__BATCH,
				),
			);
		}
		try {
			let requestId = new Date().getTime() * Math.random();
			dispatch(SetRequestId(requestId));
			let [response, ntjResponse, meetingResponse] = await Promise.all([
				dispatch(fetchMeetingWithNextRaceSelections(meetingId, raceId)),
				dispatch(fetchNextToJumpRaces_GRS()),
				dispatch(fetchMeetingAndRacesFilterByDateAndType_GRS(userDate.format('YYYY-MM-DD'), type)),
			]);

			if (requestId !== getState().grsRacingHome.requestId) {
				return;
			}

			let foundMeeting = response.data.data.id === meetingId;
			//let date = foundMeeting ? foundMeeting.start_date : null;
			let finalDate = foundMeeting && foundMeeting.start_date == userDate ? foundMeeting.start_date : userDate;

			scrollToTop();
			dispatch(
				batchActions(
					[
						setShowingMeetings([response.data.data.id]),
						updateMeeting(response.data.data),
						setShowingMeetings(meetingResponse.meetingIds),
						setShowingNTJRaces(ntjResponse.ntjRaceIds),
						// setExoticPools(response.exoticPools),
						// setContainerLoadingMask(false),
						setMeetingLoadingMask(false),
						setSelectedType(type),
						setSelectedDate(finalDate),
						// setSelectedBetType(selectedBetType, boxed),
					],
					RACING_HOME_DONE__BATCH,
				),
			);
		} catch (e) {
			console.log(e);
			return dispatch(show404());
		}
	},
});
export default new GRSRouter();
