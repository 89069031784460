import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { ui, application, typography, spacings, List, ListItem, Button, ButtonGroup, media } from '@tbh/ui-kit';
import { slugify } from '../../../../../legacy/core/format';

import SportsFilterSelection from '../../../../../components/features/Sports/SportsFilterSelection/SportsFilterSelection';

const StyledGRSSportNextJumpFilter = styled(List)(
	(props) => css`
		label: GRSSportNextJumpFilter;

		color: ${typography(props).color_2};

        width:100%;
        margin: 5px 0 0;
        padding: 0;
        list-style: none;
        overflow: hidden;
        overflow-x: auto;
        white-space: nowrap;
    `,

);

const StyledGRSSportNextJumpFilter__ListItem = styled(ListItem)(
	(props) => css`
        label: GRSSportNextJumpFilter__ListItem;

            padding: 10px;
			display: inline-block;
			border-right: 1px solid ${typography(props).color_2};
			box-shadow: 1px 0 0 ${typography(props).color_2};
			font-size: 12px;
			letter-spacing: 0.48px;
			text-align: center;
			cursor: pointer;
			line-height: 1;
			position: relative;
			width: 95px;
			font-weight: ${typography(props).weight.bold};
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			&:hover {
				background: ${ui(props).color_3};
				cursor: pointer;
				background-color: #6c757d;
				color: #ffff;
			};

			&:active {
				cursor: pointer;
				background: ${ui(props).color_3};
			}
        `,

);

const StyledSportNextJumpHomePage__sportImg = styled('div')`
	${(props) =>
		css`
			label: SportNextJumpHomePage__sportImg;

			display: inline-block;
			margin: ${spacings(props).cozy}px;
		`};
`;

const StyledSportNextJumpHomePage__sportname = styled('span')`
	${(props) =>
		css`
			label: SportNextJumpHomePage__sportname;

			display: block;
			line-height: 1.4;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			font-size: 11.2373px;
		`};
`;

const StyledGRSSportNextJumpSelection__Button = styled(Button)(
	(props) => css`
		label: GRSSportNextJumpSelection__Button;

        margin-top: ${spacings(props).compact}px;
        font-size: 16px;
        font-weight: 800;
        line-height: 1.5;
        padding: 8px;
		text-align: center;
		// This is to keep all the buttons at the top of the race card the same width
		min-width: ${application(props).racing.button_width}px;

		&:hover {
			// This is to override the specificity of the Text component
			color: ${typography(props).color_1_alternate};

		}


		&:first-child {
			margin-left: 0; // This is to override the specificity of the button group
		}
	`,

	media(
		(props) => css`
			min-width: ${application(props).racing.button_width_expanded}px;
		`,
		780,
	),
);

const StyledGRSSportNextJumpSelection = styled(ButtonGroup)(
	(props) => css`
		label: GRSSportNextJumpSelection;

		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: ${spacings(props).compact}px;
	`,
	media(
		(props) => css`
			justify-content: space-between;

			${StyledGRSSportNextJumpSelection__Button} {
				min-width: ${application(props).racing.button_width_expanded}px;
			}
		`,
		780,
	),
);







const GRSNextSportsFilter = (props) => {
    const { t, className, onFilterItemClick, sportIds, mode, sportFilter , size} = props;

	const componentClasses = cx({
		[className]: className,
    });

    const isActive = (sportIds = [], sportId) => {
		return sportIds.includes(sportId);
    };

    return(
	<StyledGRSSportNextJumpFilter className={componentClasses} mode={mode}>

		<StyledGRSSportNextJumpSelection
			 className={componentClasses}
			 spacing={ButtonGroup.spacings.SPACING_TIGHT}
			 right
			 size={size}
		>
			{
				sportFilter.map(
					(group, key1) =>(
						<SportsFilterSelection
						{...group}
						key={group[0].id}
						id={key1}
						hover={'alternate'}
						activeSport={isActive(sportIds, key1)}
						handleSelectType={onFilterItemClick}
						size="small"
						sportIcon={slugify(group[0].sport_name)}
						sportName={group[0].sport_name}
						sportCount={(group.length)}
						sportIds={sportIds}
						group={group}
					/>

					),
				)
			}

		</StyledGRSSportNextJumpSelection>

	</StyledGRSSportNextJumpFilter>
    );
};

GRSNextSportsFilter.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func,

	/** Action to handle when a filter is clicked on */
	onFilterItemClick: PropTypes.func.isRequired,

	/** Which mode should be rendered */
	mode: PropTypes.oneOf(['horizontal', 'vertical']),

	/** List of different race types */
	sportIds: PropTypes.array,

	/** Extra classes */
	className: PropTypes.string,

	sportFilter: PropTypes.array.isRequired,

	 /** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}),


};

GRSNextSportsFilter.defaultProps = {
	sportIds: [],
	className: undefined,
	size: undefined,
	t: undefined,
  mode: undefined,
};

export default withNamespaces()(GRSNextSportsFilter);
