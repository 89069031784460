import { normalize } from 'normalizr';
import { tournamentGroupsSchema } from '../schemas/Entities';

/**
 * Normalize Tournament Groups
 *
 * @param tournamentGroups
 */
const normalizeTournamentGroups = (tournamentGroups) => {
	return normalize(tournamentGroups, tournamentGroupsSchema);
};

module.exports = {
	normalizeTournamentGroups,
};
