// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings } from '@tbh/ui-kit';

// Components
import { Link, TabsItem, Text } from '@tbh/ui-kit';
import Svg from '../../../../../ui-components/Svg/Svg';

// Constants
import {
	USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING,
	USER_ACCOUNT_MENU_ICONS,
} from '../../../../../../pages/UserAccount/UserAccountPageConstants';

const StyledAccountSummaryNavListItem = styled(TabsItem)`
	label: AccountSummaryNavListItem;

	display: inline-flex;
	align-items: center;

	${(props) => css`
		${props.isLink &&
			css`
				padding: 0; // This is needed to overwrite the tab item style for the Responsible Gambling item
			`};
	`};
`;

const StyledAccountSummaryNavListItem__Icon = styled(Svg)`
	label: AccountSummaryNavListItem__Icon;

	${(props) => css`
		margin-right: ${spacings(props).tight}px;
	`};
`;

const StyledAccountSummaryNavListItem__Link = styled(Link)`
	label: AccountSummaryNavListItem__Link;

	${(props) => css`
		display: inline-flex;
		flex: 1;
		align-items: center;

		// This is needed to overwrite the link style for the Responsible Gambling nav item
		padding: ${spacings(props).compact}px;
	`};
`;

const AccountSummaryNavListItem = (props) => {
	const { className, item, onClick, t, ...rest } = props;
	const { icon, id, title } = item;

	const componentClasses = cx({
		[className]: className,
	});

	const getSvg = () => {
		if (icon) {
			return USER_ACCOUNT_MENU_ICONS[icon];
		} else {
			return 'settings';
		}
	};

	const handleClick = () => {
		onClick(item);
	};

	const content = [
		<StyledAccountSummaryNavListItem__Icon key="list-item-icon" name={getSvg()} />,
		<Text key="list-item-title" tag="span" size="-1">
			{t(`UserAccountNavList__${id}`)}
		</Text>,
	];

	return (
		<StyledAccountSummaryNavListItem
			className={componentClasses}
			isLink={id === USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING}
			pointer={!!onClick}
			action={onClick ? handleClick : null}
			{...rest}
		>
			{id === USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING ? (
				<StyledAccountSummaryNavListItem__Link
					href="https://help.topbetta.com.au/knowledge-base/responsible-gambling-at-topbetta/"
					target="blank"
					removeUnderlineOnHover
				>
					{content}
				</StyledAccountSummaryNavListItem__Link>
			) : (
				content
			)}
		</StyledAccountSummaryNavListItem>
	);
};

AccountSummaryNavListItem.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	item: PropTypes.shape({
		/** Nav item id */
		id: PropTypes.string.isRequired,

		/** Nav item icon */
		icon: PropTypes.string,

		/** Nav item title */
		title: PropTypes.string,
	}).isRequired,

	/** Extra classes */
	className: PropTypes.string,

	/** Click handler */
	onClick: PropTypes.func,
};

AccountSummaryNavListItem.defaultProps = {
	className: '',
	onClick: null,
};

export default withNamespaces()(AccountSummaryNavListItem);
