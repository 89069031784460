/**
 * This function takes a flat array of bet selections objects and groups the selections into each race number.
 * So that they can be displayed as "Leg 1: 1,3,5,6" instead of each selection on an individual object.
 *
 * @param {object} selections
 */

export const groupBetSelectionByRaceNumber = (array) => {
	Array.prototype.groupBy = function(prop) {
		return this.reduce(function(groups, item) {
			const val = item[prop];
			groups[val] = groups[val] || [];
			const mappedItem = `${item.selection_number}. ${item.selection_name}`;
			groups[val].push(mappedItem);
			return groups;
		}, {});
	};

	const selections = array.groupBy('race_number');
	return selections;
};
export const groupBetSelectionByRaceNumberButNotName = (array) => {
	Array.prototype.groupBy = function(prop) {
		return this.reduce(function(groups, item) {
			const val = item[prop];
			groups[val] = groups[val] || [];
			const mappedItem = `${item.selection_number}`;
			groups[val].push(mappedItem);
			return groups;
		}, {});
	};

	const selections = array.groupBy('race_number');
	return selections;
};
