// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { Button, spacings, Text } from '@tbh/ui-kit';

// Actions
import { closeModal } from '../../../store/modal/modalActions';
import { routeTo } from '../../../store/application/applicationActions';
import { launchLiveChat } from '../../../store/application/applicationActions';
import { login, resetPassword } from '../../../store/authentication/authenticationActions';
import {
	navigateToRegistration,
	clearRedirectRoute,
	setLocalUser,
	navigateToGreenIdVerification,
} from '../../../store/registration/registrationActions';
import { trackGaEvent } from '../../../store/trackingPixels/trackingActions';

// Components
import { Notification, Panel } from '@tbh/ui-kit';
import ModalHeader from '../../../components/features/Application/ModalHeader/ModalHeader';
import BrandContactPhone from '../../../components/ui-components/BrandContactPhone/BrandContactPhone';
import CreateAccount from '../../../components/features/Authentication/LoginOrJoin/CreateAccount/CreateAccount';
import Login from '../../../components/features/Authentication/LoginOrJoin/Login/Login';
import ModalFooter from '../../../components/features/Application/ModalFooter/ModalFooter';
import PasswordResetContainer from '../../../containers/UserAccount/PasswordReset/PasswordResetContainer';
import PasswordResetMessage from '../../../components/features/UserAccount/PasswordResetMessage/PasswordResetMessage';

// Context

// Styles
const StyledLoginContainer__Notification = styled(Notification)(
	(props) => css`
		label: LoginContainer__Notification;
		background: #850000;
		border: none;
		width: 100%;
		font-size: 12px !important;
		// padding: 5px;
		// margin-left: 18%;
		margin-bottom: ${spacings(props).cozy}px;

		&:first-child div {
			padding: 5px 3px;
		}

		${Text} {
			padding: 0px;
			font-size: 12px;
		}
	`,
);

const StyledLoginContainer__Login = styled(Login)(
	(props) => css`
		label: LoginContainer__Login;

		padding: ${spacings(props).comfortable}px ${spacings(props).cozy}px;
	`,
);

const StyledLoginContainer__CreateAccount = styled(CreateAccount)(
	(props) => css`
		label: LoginContainer__CreateAccount;

		// padding: ${spacings(props).comfortable}px ${spacings(props).cozy}px;
	`,
);

const StyledLoginContainer__Header_Image = styled('div')(
	(props) => css`
		label: StyledLoginContainer__Header_Image;
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 20%;
		height: 20%;
		margin-top: 20px;
	`,
);

const StyleOverFlowContainer = styled('div')(
	css`
		z-index: 1;
		height: 100%;
		position: absolute;
		width: 100%;
		max-width: 100%;
		border-radius: 4px;
		background-color: rgba(0, 0, 0, 0.6);
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
	`,
);

const ConfirmationContainer = styled('div')(
	css`
		position: relative;
		border-radius: 4px;
		padding: 24px;
		background-color: #fff;
		border: 1px solid #ccc;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 8px;
	`,
);

class LoginContainer extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** navigates to the registration page */
		goToRegistration: PropTypes.func.isRequired,

		/** login the user */
		login: PropTypes.func.isRequired,

		/** opens the Intercom Live Chat support */
		launchChat: PropTypes.func.isRequired,

		/** closes the modal */
		handleClose: PropTypes.func.isRequired,

		/** resets the password */
		resetPassword: PropTypes.func.isRequired,

		/** Tracking function */
		trackGaEvent: PropTypes.func.isRequired,

		resetMastheadAndRedirect: PropTypes.func.isRequired,

		/** Show the headers around containers */
		showHeaders: PropTypes.bool,

		/** Allow for navigation actions */
		useRouting: PropTypes.bool,

		/** extra classes */
		className: PropTypes.string,

		/** if we only want to display the login form */
		simpleLogin: PropTypes.bool,

		/** custom function to fire when the user tries to login */
		onLogin: PropTypes.func,

		/** custom function to fire when the user tries to reset their password */
		onReset: PropTypes.func,

		/** call when login fails */
		handleLoginFail: PropTypes.func,

		/** hide the create account form */
		hideCreateAccount: PropTypes.bool,

		/** The route fro redirection */
		redirectRoute: PropTypes.string,

		/** Is there an error message to display */
		notificationToDisplay: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		showHeaders: true,
		useRouting: true,
		hideCreateAccount: false,
		redirectRoute: null,
		simpleLogin: false,
		onLogin: () => {},
		onReset: () => {},
		handleLoginFail: () => {},
		notificationToDisplay: '',
	};

	state = {
		error: null,
		username_error: null,
		password_error: null,
		openForgotPassword: false,
		resetPasswordSuccess: false,
		loadingLogin: false,
		proceedWithVerification: false,
	};

	/**
	 * Redirects user to the registration page
	 *
	 * @param category
	 * @param action
	 * @param label
	 */
	goToRegistration = (category, action, label) => {
		this.props.goToRegistration(category, action, label);
	};

	componentDidUpdate() {
		if (this.props.user) {
			this.props.handleClose();
		}
	}

	/**
	 * Login the user
	 *
	 * @param username
	 * @param password
	 * @param keep_logged_in
	 */
	handleLogin = async (username, password, keep_logged_in) => {
		this.setLoadingMask('loadingLogin', true);
		this.props.onLogin();
		this.clearMessage();
		try {
			const res = await this.props.login(username, password, keep_logged_in);
			if (this.props.redirectRoute) {
				this.props.resetMastheadAndRedirect(
					//this.props.redirectRoute.includes('registration') ? 'racing-v3' : this.props.redirectRoute, // ori ginal code
					this.props.redirectRoute.includes('registration') ? 'welcome' : this.props.redirectRoute, // new
				);
			} else {
				this.props.handleClose();
			}
		} catch (error) {
			this.props.handleLoginFail();
			if (error.response && error.response.status === 400) {
				let errors = error.response.data.errors;

				this.setState({
					username_error: errors.username ? errors.username[0] : null,
					password_error: errors.password ? errors.password[0] : null,
				});
			} else if (error.response && error.response.status === 401) {
				if (error.response.data.errors.includes('Account is not verified.')) {
					this.props.setLocalUser(error.response.data.user);
					this.setState({
						proceedWithVerification: true,
					});
				}
				this.setState({
					error: error.response.data.errors,
				});
			} else if (error.response && error.response.status === 429) {
				this.setState({
					error: error.response.data.errors,
				});
			} else {
				this.setState({
					error: error.message,
				});
			}
		}

		this.setLoadingMask('loadingLogin', false);
	};

	/**
	 * Sets a loading mask
	 *
	 * @param location: where in the Login the loading mask should be displayed (login or reset password)
	 * @param loading: true or false
	 */
	setLoadingMask = (location, loading = false) => {
		this.setState({
			[location]: loading,
		});
	};

	/**
	 * Clears the error message
	 */
	clearMessage = () => {
		this.setState({
			error: null,
		});
	};

	/**
	 * Opens/Closes the forgot password screen
	 *
	 * @param category
	 * @param action
	 * @param label
	 */
	toggleForgotPassword = (category, action, label) => {
		if (category && action && label) {
			this.props.trackGaEvent(category, action, label);
		}

		this.setState({
			openForgotPassword: !this.state.openForgotPassword,
			error: null,
			resetPasswordSuccess: false,
		});
	};

	/**
	 * Resets the password
	 *
	 * @param email
	 */
	resetPassword = (email) => {
		this.props.onReset();
		this.props
			.resetPassword(email)
			.then((response) => {
				let error = response.data.error;

				if (error) {
					this.setState({
						error: error,
					});
				} else {
					this.setState({
						resetPasswordSuccess: true,
					});
				}
			})
			.catch((error) => {
				if (error.response.status === 400) {
					this.setState({
						error: error.response.data.errors,
					});
				} else {
					this.setState({
						error: error.message,
					});
				}
			});
	};

	handleGoToVerification = () => {
		this.props.navigateToGreenIdVerification();
	};

	render() {
		const {
			t,
			className,
			launchChat,
			handleClose,
			simpleLogin,
			hideCreateAccount,
			notificationToDisplay,
			useRouting,
			showHeaders,
		} = this.props;

		const { error, loadingLogin, openForgotPassword, password_error, username_error } = this.state;

		const componentClasses = cx({
			[className]: className,
		});

		// If we only want the basic login form, return it
		if (simpleLogin) {
			return (
				<div className={componentClasses}>
					{(notificationToDisplay || error) && (
						<StyledLoginContainer__Notification
							strong
							type={Notification.types.COLOUR_WARNING}
							message={error ? error : notificationToDisplay}
							buttonText={t('Dismiss')}
							buttonAction={error ? this.clearMessage : undefined}
						/>
					)}
					{openForgotPassword ? (
						<Panel>
							<Panel padding={Panel.padding.SPACING_COZY}>
								<PasswordResetContainer />
							</Panel>
							<PasswordResetMessage
								loginAction={this.toggleForgotPassword}
								signupAction={useRouting ? this.goToRegistration : null}
							/>
						</Panel>
					) : (
						<Login
							hideTitle
							forgotPassword={this.toggleForgotPassword}
							login={this.handleLogin}
							username_error={username_error}
							password_error={password_error}
							loadingLogin={loadingLogin}
						/>
					)}
				</div>
			);
		}

		return (
			<div className={componentClasses} style={{ position: 'relative' }}>
				{this.state.proceedWithVerification && (
					<StyleOverFlowContainer>
						<ConfirmationContainer>
							<i
								className="fa fa-times"
								aria-hidden="true"
								style={{ color: 'red', right: '8px', position: 'absolute', top: '8px', cursor: 'pointer' }}
								onClick={() => this.setState({ proceedWithVerification: false })}
							/>
							<Text align="center" size="-1">
								To proceed with your login, please verify your account.
							</Text>
							<Button
								action={() => this.handleGoToVerification()}
								bold
								flex
								className={css`
									border-radius: 4px;
									background-color: #c72128;
									color: #ffffff;
									padding: 8px;
								`}
							>
								{t('Verify now')}
							</Button>
						</ConfirmationContainer>
					</StyleOverFlowContainer>
				)}

				{openForgotPassword ? (
					<Panel>
						{showHeaders && (
							<ModalHeader
								title={t('LoginContainer__ForgotPassword')}
								onClose={this.toggleForgotPassword}
								aside={<BrandContactPhone />}
							/>
						)}
						{error && (
							<Notification
								strong
								type={Notification.types.COLOUR_DANGER}
								message={error}
								buttonText={t('Dismiss')}
								buttonAction={this.clearMessage}
							/>
						)}
						<Panel padding={Panel.padding.SPACING_COZY}>
							<PasswordResetContainer />
						</Panel>
						<PasswordResetMessage
							loginAction={this.toggleForgotPassword}
							signupAction={useRouting ? this.goToRegistration : null}
						/>
					</Panel>
				) : (
					<div className={css``}>
						{showHeaders && (
							<ModalHeader
								title={
									hideCreateAccount ? t('Login') : <React.Fragment>{t('LoginContainer__LoginOrJoin')} </React.Fragment>
								}
								onClose={useRouting ? handleClose : null}
								aside={<BrandContactPhone />}
							/>
						)}
						{error && (
							<Notification
								strong
								type={Notification.types.COLOUR_DANGER}
								message={error}
								buttonText={t('Dismiss')}
								buttonAction={this.clearMessage}
							/>
						)}
						<StyledLoginContainer__Header_Image>
							<i className="fa fa-user-circle-o fa-5x" aria-hidden="true" />
						</StyledLoginContainer__Header_Image>
						<StyledLoginContainer__Login
							forgotPassword={this.toggleForgotPassword}
							login={this.handleLogin}
							launchChat={useRouting ? launchChat : null}
							username_error={username_error}
							password_error={password_error}
							loadingLogin={loadingLogin}
							goToRegistration={this.goToRegistration}
						/>
						<div
							className={css`
								text-align: center;
							`}
						>
							<Button
								type="primary"
								action={this.toggleForgotPassword}
								flex
								className={css`
									padding: 12px 24px;
									background-color: transparent;
									color: black;
									border-radius: 4px;
									border: 1px solid #c72128;
									&:hover {
										border-bottom: 1px solid #7b0000;
									}
								`}
							>
								{t('Login__ForgotPassword')}
							</Button>
						</div>
						{/* <StyledLoginContainer__CreateAccount createAccount={this.goToRegistration} /> */}
					</div>
				)}
				<ModalFooter />
			</div>
		);
	}
}

const mapStateToProps = (state) => {

	const authenticatedUser = state.application.authenticatedUser;

	return {
		...state,
		user: authenticatedUser,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		goToRegistration: (category, action, label) => {
			dispatch(trackGaEvent(category, action, label));
			dispatch(navigateToRegistration());
			return dispatch(closeModal('LoginContainer'));
		},
		login: (username, password, keep_logged_in) => {
			dispatch(trackGaEvent('Login Register', 'Submit', 'Login'));
			return dispatch(login(username, password, keep_logged_in));
		},
		launchChat: () => {
			dispatch(trackGaEvent('Login Register', 'Click', "Can't Access Account"));
			return dispatch(launchLiveChat());
		},
		resetPassword: (email) => {
			dispatch(trackGaEvent('Forgot Password', 'Submit', 'Send Password'));
			return dispatch(resetPassword(email));
		},
		trackGaEvent: (category, action, label) => {
			return dispatch(trackGaEvent(category, action, label));
		},
		handleClose: () => {
			return ownProps.handleClose ? ownProps.handleClose() : dispatch(closeModal('LoginContainer'));
		},
		resetMastheadAndRedirect: (route) => {
			if (route) {
				dispatch(routeTo('/' + route));
				dispatch(clearRedirectRoute());
			}
			return dispatch(closeModal('LoginContainer'));
		},
		setLocalUser: (user) => {
			dispatch(setLocalUser(user));
		},
		navigateToGreenIdVerification: () => {
			dispatch(navigateToGreenIdVerification());
			return dispatch(closeModal('LoginContainer'));
		},
	};
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(LoginContainer));
