import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { ui, spacings } from '@tbh/ui-kit';

import { formatOddsAsPrice } from '../../../../common/BetPlacement';
import { RACING_FIXED_LABEL } from '../../../../common/constants/Racing';

import { Text } from '@tbh/ui-kit';

const StyledSwapOddsButton = styled('div')`
	${(props) => css`
		label: SwapOddsButton;

		min-width: 78px;
		display: flex;
		align-self: stretch;
		flex-direction: column;
		justify-content: center;
		padding: ${spacings(props).tight}px;
		border: 1px solid ${ui(props).color_3};
		cursor: pointer;
	`};
`;

const SwapOddsButton = (props) => {
	const { action, className, isToteOdds, fixedOdds, toteOdds, toteCode, fixedCode, t } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<StyledSwapOddsButton onClick={action} className={componentClasses}>
			<Text align="center" size="-3" strong>
				{t('SwapOddsButton__Text')}
				{isToteOdds ? '' : '*'}
			</Text>
			<Text align="center" size="-4">
				{isToteOdds ? t(fixedCode) : toteCode}
			</Text>
			<Text align="center" size="-4">
				{isToteOdds ? formatOddsAsPrice(fixedOdds) : formatOddsAsPrice(toteOdds)}
			</Text>
		</StyledSwapOddsButton>
	);
};

SwapOddsButton.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Action for when the component is clicked on */
	action: PropTypes.func.isRequired,

	/** The tote odds */
	toteOdds: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

	/** The tote product code */
	toteCode: PropTypes.string.isRequired,

	/** The fixed odds */
	fixedOdds: PropTypes.number.isRequired,

	/** If the GT odds are selected, or the fixed odds are */
	isToteOdds: PropTypes.bool.isRequired,

	/** Text to display for swapping to Fixed odds */
	fixedCode: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,
};

SwapOddsButton.defaultProps = {
	fixedCode: RACING_FIXED_LABEL,
	className: '',
};

export default withNamespaces()(SwapOddsButton);
