import {sportSchema, sportSelectionSchema} from '../Entities';
import {buildEntitySchema} from './relationshipSelectorHelpers';

/**
 * Grab all entities related to sports.
 * @type {*}
 */
export const getSportsEntities = buildEntitySchema(sportSchema);

/**
 * Grab only entities related to sports selections.
 * @type {*}
 */
export const getSportSelectionsEntities = buildEntitySchema(sportSelectionSchema);