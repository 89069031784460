/**
 * Log a table in the console of all provided props and whether they have strict equality
 *
 * @param oldProps
 * @param newProps
 * @constructor
 */
const PropDiff = (oldProps, newProps) => {
	let match = {};
	Object.keys(oldProps).forEach((oldPropKey) => {
		const oldValue = oldProps[oldPropKey];
		const newValue = newProps[oldPropKey];
		match[oldPropKey] = oldValue === newValue;
	});

	console.table({ old: oldProps, new: newProps, match: match }, Object.keys(oldProps));
};

/**
 * Create a cache of the previous mapStateToProps and log how many times each individual prop changes over time
 *
 * @param mapStateToProps
 * @returns {*}
 */
export const logMapStateToPropsChanges = (mapStateToProps) => {
	window.cachedState = window.cachedState || {};

	console.group('mapStateToPropsCalled');
	console.count('Total Times Called');

	Object.keys(window.cachedState).forEach((stateKey) => {
		if (window.cachedState[stateKey] !== mapStateToProps[stateKey]) {
			console.count(stateKey);
		}
	});

	PropDiff(window.cachedState, mapStateToProps);

	console.groupEnd();

	window.cachedState = mapStateToProps;

	return mapStateToProps;
};

export default PropDiff;
