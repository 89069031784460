export const BET_PLACEMENT_RESET = 'BET_PLACEMENT_RESET';
export const BET_PLACEMENT_CLEAR_BETS = 'BET_PLACEMENT_CLEAR_BETS';
export const BET_PLACEMENT_ADD_BET = 'BET_PLACEMENT_ADD_BET';
export const BET_PLACEMENT_ADD_TOURNAMENT_BET = 'BET_PLACEMENT_ADD_TOURNAMENT_BET';
export const BET_PLACEMENT_SET_BETS = 'BET_PLACEMENT_SET_BETS';
export const BET_PLACEMENT_SET_LEGS = 'BET_PLACEMENT_SET_LEGS';
export const BET_PLACEMENT_ADD_LEG = 'BET_PLACEMENT_ADD_LEG';
export const BET_PLACEMENT_REMOVE_LEG = 'BET_PLACEMENT_REMOVE_LEG';
export const BET_PLACEMENT_REPLACE_LEG = 'BET_PLACEMENT_REPLACE_LEG';
export const BET_PLACEMENT_SET_SELECTIONS = 'BET_PLACEMENT_SET_SELECTIONS';
export const BET_PLACEMENT_ADD_TOURNAMENT_SELECTION = 'BET_PLACEMENT_ADD_TOURNAMENT_SELECTION';
export const BET_PLACEMENT_ADD_SELECTION = 'BET_PLACEMENT_ADD_SELECTION';
export const BET_PLACEMENT_REMOVE_SELECTION = 'BET_PLACEMENT_REMOVE_SELECTION';
export const BET_PLACEMENT_REPLACE_SELECTION = 'BET_PLACEMENT_REPLACE_SELECTION';
export const BET_PLACEMENT_CHANGE_SELECTION = 'BET_PLACEMENT_CHANGE_SELECTION';
export const BET_PLACEMENT_SET_ERROR = 'BET_PLACEMENT_SET_ERROR';
export const BET_PLACEMENT_UPDATE = 'BET_PLACEMENT_UPDATE';
export const USE_BONUS_BET = 'USE_BONUS_BET';
export const BET_PLACEMENT_ADD_SRM = 'BET_PLACEMENT_ADD_SRM';
export const BET_PLACEMENT_SET_SRM = 'BET_PLACEMENT_SET_SRM';
export const BET_PLACEMENT_REMOVE_SRM = 'BET_PLACEMENT_REMOVE_SRM';
export const BET_PLACEMENT_REPLACE_SRM = 'BET_PLACEMENT_REPLACE_SRM';
export const BET_PLACEMENT_SET_LOADING = 'BET_PLACEMENT_SET_LOADING';


export const BET_PLACEMENT_QUADDIES_SET = 'BET_PLACEMENT_QUADDIES_SET';
export const BET_PLACEMENT_QUADDIES_ADD = 'BET_PLACEMENT_QUADDIES_ADD';
export const BET_PLACEMENT_QUADDIES_REMOVE = 'BET_PLACEMENT_QUADDIES_REMOVE';
export const BET_PLACEMENT_QUADDIES_REPLACE = 'BET_PLACEMENT_QUADDIES_REPLACE';
export const BET_PLACEMENT_EXOTICS_REPLACE = 'BET_PLACEMENT_EXOTICS_REPLACE';
export const BET_PLACEMENT_EXOTICS_SET = 'BET_PLACEMENT_EXOTICS_SET';
export const BET_PLACEMENT_EXOTICS_ADD = 'BET_PLACEMENT_EXOTICS_ADD';
export const BET_PLACEMENT_EXOTICS_REMOVE = 'BET_PLACEMENT_EXOTICS_REMOVE';


export const BET_PLACEMENT_ADD_RACE = 'BET_PLACEMENT_ADD_RACE';
export const BET_PLACEMENT_REMOVE_RACE = 'BET_PLACEMENT_REMOVE_RACE';
export const BET_PLACEMENT_REPLACE_RACE = 'BET_PLACEMENT_REPLACE_RACE';
export const BET_PLACEMENT_CLEAR_RACES = 'BET_PLACEMENT_CLEAR_RACES';

export const BETSLIP_TAB = 'BETSLIP_TAB';