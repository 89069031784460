/**
 * Created by @HW on 24Jan2020
 */
'use strict';
// Constants
import {
    GRS_SPORT_HOME_SET_CONTAINER_LOADING_MASK,
} from './grsSportsHomeActionTypes';

// Initial state

const initialState = {
    selectedSport: null,
    loadingContainer: undefined,
};

/**
 * Reducer GRS sport home
 */
export default function grsSportsHomeReducer(state = initialState, action){
    switch (action.type) {
        case GRS_SPORT_HOME_SET_CONTAINER_LOADING_MASK:
			return {
				...state,
				loadingContainer: action.payload,
            };
        default:
			return state;
    }
    
}
