// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';
import styled, { css } from 'react-emotion';
// Components
import SelfExclusionPrompt from './SelfExclusionPrompt/SelfExclusionPrompt';
import SelfExclusionConfirmation from './SelfExclusionConfirmation/SelfExclusionConfirmation';
import { MEDIA_QUERIES } from '../../../../constants/themes';

/**
 * represent the screen that should be displayed
 */
const PROMPT_SCREEN = 'PROMPT_SCREEN';
const CONFIRMATION_SCREEN = 'CONFIRMATION_SCREEN';

const StyledSelfExclusion = styled('div')(
	(props) => css`
	label: SelfExclusion;

	max-width: 100%;

	@media ${MEDIA_QUERIES.tabletAndUp} {
	}
`,

);

class SelfExclusion extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** User's email */
		email: PropTypes.string.isRequired,

		/** Function for excluding the user */
		excludeUser: PropTypes.func.isRequired,

		/** Handle clearing the error message */
		clearError: PropTypes.func.isRequired,

		/** Error returned from the function excludeUser */
		error: PropTypes.string,

		/** Extra classes */
		className: PropTypes.string,

		/** If the request is loading or not */
		loading: PropTypes.bool,
	};

	static defaultProps = {
		error: null,
		className: null,
		loading: false,
	};

	state = {
		flow: PROMPT_SCREEN,
		email: this.props.email,
		valid: true,

		/** the reason why the user wants to be excluded */
		reason: this.props.t('SelfExclusions__Reason'),

		/** comment for the reason 'Others' */
		otherReason: null,
	};

	/**
	 * handle changes in the email input
	 *
	 * @param name
	 * @param value
	 * @param valid
	 */
	handleChange = (name, value, valid) => {
		this.setState({
			[name]: value,
			valid: valid,
		});
	};

	/**
	 * handle changes in the reason selection
	 *
	 * @param name
	 * @param value
	 */
	handleSelectionChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	/**
	 * handle changes in the textarea
	 *
	 * @param event
	 */
	onTextareaChange = (event) => {
		this.setState({
			otherReason: event.target.value,
		});
	};

	/**
	 * redirects to the confirmation page
	 */
	handleExcludeUser = () => {
		this.setState({
			flow: CONFIRMATION_SCREEN,
		});
	};

	/**
	 * redirects to the prompt screen
	 * when the 'Changed my mind' link is clicked
	 */
	handleCancel = () => {
		/**
		 * clear the error message
		 */
		this.props.clearError();

		this.setState({
			flow: PROMPT_SCREEN,
			reason: this.props.t('SelfExclusions__Reason'),
			otherReason: null,
		});
	};

	/**
	 * hook for blocking the user
	 */
	handleConfirmExclusion = () => {
		let email = this.state.email;
		let reason = this.state.reason;
		let comment = this.state.otherReason;

		let exclusionReason =
			reason === this.props.t('SelfExclusions__Others') && comment ? reason + ': ' + comment : reason;

		this.props.excludeUser(email, exclusionReason);
	};

	render() {
		const { className, error, clearError, loading } = this.props;

		const { flow, email, reason, otherReason, valid } = this.state;

		const {
			handleChange,
			onTextareaChange,
			handleSelectionChange,
			handleCancel,
			handleExcludeUser,
			handleConfirmExclusion,
		} = this;

		const selfExclusionClass = cx({
			[className]: className,
		});

		return (
			<StyledSelfExclusion className={selfExclusionClass}>
				{flow === PROMPT_SCREEN ? (
					<SelfExclusionPrompt
						email={email}
						excludeUser={handleExcludeUser}
						handleChange={handleChange}
						disabled={!valid}
					/>
				) : (
					<SelfExclusionConfirmation
						onChange={handleSelectionChange}
						onTextareaChange={onTextareaChange}
						onCancel={handleCancel}
						onConfirm={handleConfirmExclusion}
						reason={reason}
						otherReason={otherReason}
						error={error}
						clearError={clearError}
						loading={loading}
					/>
				)}
			</StyledSelfExclusion>
		);
	}
}

export default withNamespaces()(SelfExclusion);
