import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';

import { selectToggledFeatures } from './contextMemoizedSelectors';

import FeatureContext from '../../../contexts/featureContext';
import AclContext from '../../../contexts/AclContext';

/** List of Available Contexts */
const ContextMap = {
	FeatureContext: {
		contextComponent: FeatureContext,
		contextValueKey: 'featureToggles',
	},
	AclContext: {
		contextComponent: AclContext,
		contextValueKey: 'acl',
	},
	ThemeContext: {
		contextComponent: ThemeProvider,
		contextValueKey: 'theme',
	},
};

const ContextContainer = (props) => {
	// Map the provided context to the context map, and subscribe to the correct data in the store
	const mappedContext = ContextMap[props.contextList];
	const Component = mappedContext.contextComponent;
	const value = mappedContext.contextValueKey;

	if (value === 'theme') {
		return <Component theme={props[value]}>{props.children}</Component>;
	}

	return <Component.Provider value={props[value]}>{props.children}</Component.Provider>;
};

ContextContainer.propTypes = {
	/** The children to display in the component hierarchy */
	children: PropTypes.node.isRequired,

	/** The context to load and wrap the children with */
	contextList: PropTypes.oneOf(Object.keys(ContextMap)).isRequired,

	/** State for the featureToggle context */
	featureToggles: PropTypes.shape({
		/** Enable or disable this reducer as a whole */
		enableFeatureToggling: PropTypes.bool,

		/**
		 * -1 Disable all features
		 * 0 Default (enable each feature individually)
		 * 1 Enable all features
		 **/
		bulkFeatureToggler: PropTypes.number,

		/** The list of feature to toggle in the feature toggler */
		features: PropTypes.objectOf(
			PropTypes.shape({
				/** If the feature is enabled or not. NOTE: Can be overridden above */
				enabled: PropTypes.bool.isRequired,

				/** Custom value to provide with the feature */
				value: PropTypes.any,
			}),
		),
	}),
};

ContextContainer.defaultProps = {
	featureToggles: {
		enableFeatureToggling: true,
		bulkFeatureToggler: 0,
		features: {},
	},
};

const mapStateToProps = (state) => {
	return {
		featureToggles: selectToggledFeatures(state),
		acl: state.acl,
		theme: state.theme,
	};
};

export default connect(mapStateToProps)(ContextContainer);
