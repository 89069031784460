import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, brand } from '@tbh/ui-kit';
import { Text, Button } from '@tbh/ui-kit';
import moment from 'moment';
import { countdown } from '../../../../legacy/core/format';
import { moreThan2minColor, lessThan2MinColor } from '../../../../store/application/applicationConstants';

const StyledBetBuilderRaceHeaderBar = styled('div')`
	${(props) => css`
		min-height: 120px; // To Match the height of a regular header bar
		background-color: ${brand(props).color_2};
		width: 100%;
	`};
`;

const StyledBBuilderRaceHeaderBar__Heading = styled('div')`
	${(props) => css`
		label: BBuilderRaceHeaderBar__Heading;

		padding: ${spacings(props).tight}px;
		width: 100%;
		display: flex;
		border-radius: 8px 8px 0 0;
		-moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
		-webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
		height: 84px;
	`};
`;

const StyledBBuilderRaceHeaderBar__Heading_raceNumber = styled('div')`
	${(props) => css`
		label: BBuilderRaceHeaderBar__Heading_raceNumber;

		display: inline-block;

		flex: 0 0 10%;
	`};
`;

const StyledBBuilderRaceHeaderBar__Heading_More = styled('div')`
	${(props) => css`
		label: StyledBBuilderRaceHeaderBar__Heading_More;

		display: inline-block;

		flex: 0 0 80%;
		max-width: 80%;
	`};
`;

const StyledBBuilderRaceHeaderBar__Race__Name = styled('div')`
	${(props) => css`
		label: BBuilderRaceHeaderBar__Race__Name;
		display: flex;
		flex-flow: row wrap;
		margin-top: ${spacings(props).tight}px;
		margin-bottom: ${spacings(props).tight}px;
	`};
`;
const StyledBBuilderRaceHeaderBar__Race__Details = styled('div')`
	${(props) => css`
		label: BBuilderRaceHeaderBar__Race__Details;
		display: flex;
	`};
`;
const StyledBBuilderRaceHeaderBar__Race_Time = styled('div')`
	${(props) => css`
		label: BBuilderRaceHeaderBar__Race_Time;
		display: flex;
		margin-top: ${spacings(props).tight}px;
	`};
`;
const StyledBBuilderRaceHeaderBar___TimeToGoBUtton = styled(Button)(
	(props) => css`
		label: BBuilderRaceHeaderBar___TimeToGoBUtton;
		//border-radius: 12px !important;
		font-size: 12.2373px;
		display: block;
		margin-top: ${spacings(props).tight}px;
		//background: ${brand(props).rch_4};
		//border-color: ${brand(props).rch_4};
		background: ${moreThan2minColor};
		border-color: ${moreThan2minColor};
		size:small;
		width:auto;
		height:26px;
	    margin-left:130px;
		margin-top:-20px;
		position: absolute;
		color:#ffffff;
		font-weight: bold;
		border-radius: 3px !important;
		



		${props.lessThan2Min === 'less2Min' &&
		props.status === 'selling' &&
		css`
				&& {
					background-color: ${lessThan2MinColor};
					border: 1px solid ${lessThan2MinColor};
				}
			`};

	
		${props.status === 'paid' &&
		css`
				&& {
					background-color: rgb(93, 151, 251);
					border: 1px solid rgb(93, 151, 251);
				}
			`};


		${props.status === 'closed' &&
		css`
				&& {
					background-color: #c72128;
					border: 1px solid #c72128;
				}
			`};

		${props.status === 'interim' &&
		css`
				&& {
					background-color: rgb(238, 156, 21);
					border: 1px solid rgb(238, 156, 21);
				}
			`};
		
		
	`,
);

/////////////////////////////////////////////////////////////

const StyledCarouselItemBetBuilder = styled(Text)(
	(props) => css`
		label: CarouselItem;

		display: block;
		cursor: pointer;
		width: 100%;
	`,
);

const CarouselItemBetBuilder = (props) => {
	const {
		className,
		raceNumber,
		children,
		id,
		label,
		text,
		meta,
		icon,
		image,
		action,
		OpenNext2JumpItem,
		meetingStateText,
		raceText,
		showSelections,
		distance,
		country,
		start_datetime,
		lessThan2Min,
		status,
		...rest
	} = props;
	const { center } = rest;
	const carouselItemClassv3 = cx({
		[className]: className,
	});

	const componentClassesv3 = cx({
		[className]: className,
	});

	const timeTogo = () => {
		return countdown(start_datetime);
	};

	/**
	 * Builds the state and race number display string
	 *
	 * @returns {string}
	 */
	const buildStateAndRaceNumberLabel = () => {
		let stateAndRaceNumberLabel = '';

		// Show the meeting state,

		if (label) {
			stateAndRaceNumberLabel += `${label}`;
		}

		return stateAndRaceNumberLabel;
	};

	const handleClick = (e) => {
		e.preventDefault();
		action(id);
	};

	if (children) {
		return (
			<StyledCarouselItemBetBuilder {...rest} className={carouselItemClassv3}>
				{children}
			</StyledCarouselItemBetBuilder>
		);
	}

	return (
		<StyledCarouselItemBetBuilder {...rest} className={carouselItemClassv3} action={handleClick}>
			<StyledBetBuilderRaceHeaderBar noPadding className={componentClassesv3} margin={false}>
				<StyledBBuilderRaceHeaderBar__Heading>
					<StyledBBuilderRaceHeaderBar__Heading_raceNumber>
						<div
							className={css`
						color: #ffffff;
						background: #666666;
						border: 1px solid #666666;
						border-bottom: 2px solid #5c5c5c;
						text-align: center;
						margin-top: 4px;
					`}
						>
							{raceNumber}
						</div>
					</StyledBBuilderRaceHeaderBar__Heading_raceNumber>

					<StyledBBuilderRaceHeaderBar__Heading_More>
						<StyledBBuilderRaceHeaderBar__Race__Name>
							<Text
								className={css`
									color: #ffffff;
									width: 320px;
									text-align: left;
									padding-left: 8px;
								`}
								size="-2"
								strong
							>
								{raceText}
							</Text>
						</StyledBBuilderRaceHeaderBar__Race__Name>
						<StyledBBuilderRaceHeaderBar__Race__Details>
							<Text
								className={css`
									color: #ffffff;
									width: 320px;
									text-align: left;
									padding-left: 8px;
								`}
								size="-2"
							>
								Distance : {distance}
								{'m'}
							</Text>
						</StyledBBuilderRaceHeaderBar__Race__Details>
						<StyledBBuilderRaceHeaderBar__Race_Time>
							<Text
								className={css`
									color: #ffffff;
									width: 320px;
									text-align: left;
									padding-left: 8px;
								`}
								size="-2"
							>
								{' '}
								{moment(start_datetime).calendar()}{' '}
							</Text>
							{status === 'selling' ? (
								<StyledBBuilderRaceHeaderBar___TimeToGoBUtton lessThan2Min={lessThan2Min} status={status}>
									{meta}
								</StyledBBuilderRaceHeaderBar___TimeToGoBUtton>
							) : (
								<StyledBBuilderRaceHeaderBar___TimeToGoBUtton lessThan2Min={lessThan2Min} status={status}>
									{status}
								</StyledBBuilderRaceHeaderBar___TimeToGoBUtton>
							)}
						</StyledBBuilderRaceHeaderBar__Race_Time>
					</StyledBBuilderRaceHeaderBar__Heading_More>
				</StyledBBuilderRaceHeaderBar__Heading>
			</StyledBetBuilderRaceHeaderBar>
		</StyledCarouselItemBetBuilder>
	);
};
CarouselItemBetBuilder.propTypes = {
	/** ID of the carousel item */
	id: PropTypes.any.isRequired,

	/** Extra classes */
	className: PropTypes.string,

	/** Label for the carousel item */
	label: PropTypes.string,

	/** Text for the carousel item */
	text: PropTypes.string,

	/** Extra information for the carousel item */
	meta: PropTypes.string,

	/** Icon to display in the carousel item */
	icon: PropTypes.string,

	/** Image to display in the carousel item */
	image: PropTypes.string,

	/** How many slides to display */
	slides: PropTypes.string,

	/** If the carousel item should be centered */
	center: PropTypes.bool,

	/** If the carousel item should have padding */
	padding: PropTypes.bool,

	/** If the carousel item should have a margin */
	margin: PropTypes.bool,

	/** If the carousel item should have a border */
	border: PropTypes.bool,

	/** Action when the carousel item is clicked on */
	action: PropTypes.func,

	/** Children to display under the carousel item */
	children: PropTypes.node,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}),

	/** Whether to use a small amount of flex to fit more buttons */
	small: PropTypes.bool,

	meetingStateText: PropTypes.string,

	raceText: PropTypes.string,

	start_datetime: PropTypes.string,

	distance: PropTypes.string,

	raceNumber: PropTypes.number,

	lessThan2Min: PropTypes.string,

	status: PropTypes.string,
};

CarouselItemBetBuilder.defaultProps = {
	className: '',
	label: undefined,
	text: undefined,
	meta: undefined,
	icon: undefined,
	image: undefined,
	slides: undefined,
	center: undefined,
	padding: undefined,
	margin: undefined,
	border: undefined,
	action: undefined,
	children: undefined,
	small: null,
	size: undefined,
	meetingStateText: '',
	raceText: '',
	start_datetime: '',
	distance: 0,
	raceNumber: null,
	lessThan2Min: null,
	status: '',
};

export default CarouselItemBetBuilder;
