import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { media, spacings, Ticker } from '@tbh/ui-kit';

/**
 * Components
 */
import NJPCarousel from '../../Carousel/NJPCarousel';
import RaceNextJumpFilter from './RaceNextJumpFilter/RaceNextJumpFilter';
import { filterNextToJumpRaces } from '../../../../store/entities/selectors/NextToJumpRaceSelectors';

import NtjRacesCarouselItemV3 from '../NtjRacesCarouselItemV3/NtjRacesCarouselItemV3'; // new @HW added
import {
	RACING_THOROUGHBRED_CODE,
	RACING_GREYHOUNDS_CODE,
	RACING_HARNESS_CODE,
	RACING_TYPES_LOOKUP,
} from '../../../../common/constants/Racing';

import NjRaceCardContainer from '../../../../containers/Racing/NjRaceCardContainer/NjRaceCardContainer';
import PusherSubscriberGRSNjRaceChannel from '../PusherSubscribers/PusherSubscriberGRSNjRaceChannel';

const StyledRaceNextJumpV3 = styled('div')`
	label: RaceNextJumpV3;

	display: flex;
	flex-wrap: wrap;
	-ms-flex-wrap: wrap;

	display: -webkit-box;
	display: -ms-flexbox;
`;

// Size at which the mode should change over
const modeChangeOverSize = 751;

const StyledRaceNextJumpV3__Carousel = styled(NJPCarousel)(
	(props) => css`
		label: RaceNextJumpV3__NJPCarousel;
		flex: 1;
		height: auto;
		overflow: hidden;

	`,
	(props) =>
		media(
			css`
			flex: 1;
			height: auto;
			overflow: hidden;
			`,
			modeChangeOverSize,
		),
);

const StyledRaceNextJumpV3__NtjRacesCarouselItem = styled(NtjRacesCarouselItemV3)(
	(props) => css`
		label: RaceNextJumpV3__NtjRacesCarouselItemV3;
		min-height:65px;
		width: auto;
		box-sizing: border-box;
		
	`,
);

const StyledRaceNextJumpV3_carousel_body_content= styled('div')(
	(props) => css `
	label: RaceNextJumpV3_carousel_body_content;

	color:#2E2E2E;
	background-color: #f7f8f8;
	height: auto;
	width:auto;

	`,
	media(
		(props) =>
			css`
			width:auto;

			`,
			768,
	)
);

const StyleNjRaceCardContainer = styled(NjRaceCardContainer)(
	(props) => css `
	 label: NextJumpHomePage__NjRaceCardContainer;
	 width: auto;
	 //padding: ${spacings(props).tight}px;
	`,
);


const StyledRaceNextJumpV3Wrapper = styled('div')(
    (props) => css`
        label: CarouselItemV3__Wrapper;


		position: relative;
		max-width: 100%;
		padding-right: 5px;
		padding-left: 5px;
		`,
	media(
		(props) =>
			css`

				position: relative;
	
				max-width: 100%;
			`,
			768,
	)
);


const StyledRaceNextJumpHomePage__Filters_section = styled('div')`
	${(props) =>
		css`
			label: RaceNextJumpHomePage__Filters_section;


			box-sizing: border-box;
			-webkit-tap-highlight-color: transparent;
			display: flex;
		`};

	${media(
		(props) =>
			css`
				display: flex;
				padding: ${spacings(props).compact}px;
				width: 100%;
			`,
		500,
	)};
`;

const StyledGRSNextRaceList_MainSection = styled('div')(
    (props) => css`
        label: GRSNextRaceList_MainSection;

        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1 0 0;
        height: auto;
        width: 100%;
        `,
);
const StyledNextJumpRace_Header_section = styled('div')`
	${(props) =>
		css`
			label: NextJumpRace_Header_section;
			margin: ${spacings(props).tight}px;
			position: relative;
			z-index: 0; // This stops the loading mask from overlapping the header bar
			margin-bottom: 12px;
		`};
`;
const StyledNextJumpSport_maijor_header = styled('div')`
	${(props) =>
		css`
			label: NextJumpSport_maijor_header;
			
			border-bottom: 3px solid #c72128;
			clear: both;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			width: 100%;
			
		`};
		${media(
			(props) =>
				css`
					display: flex;
					flex-wrap: wrap;
					width: 100%;
				`,
			400,
		)};
`;
const StyledNextJumpRace_Major_header_h2 = styled('h2')`
	${(props) =>
		css`
			label: NextJumpSport_Major_header_h2;
			
			text-transform: uppercase;
			font-weight: bold;
			background: #c72128;
			border-color: #c72128;
			color: white;
			text-transform: uppercase;
			display: inline-block;
			margin: 0;
			padding: 7px;
			height: auto;
			position: relative;
			font-size: 14.222222222222221px;
			letter-spacing: 0.32px;
			text-align: center;
			height: 31px;
			margin-top: 21px;

		`};
`;
class RaceNextJumpV3 extends React.Component {
	static propTypes = {
		/** Extra class(es) to pass through to the component */
		className: PropTypes.string,

		/** An array of the next to jump races */
		items: PropTypes.arrayOf(
			PropTypes.shape({
				/** The id of the race */
				id: PropTypes.number.isRequired,

				/** The id of the meeting the race is a part of */
				meeting_id: PropTypes.number.isRequired,

				/** The name of the meeting */
				meeting_name: PropTypes.string.isRequired,

				/** The type of race ie. Thoroughbred, Harness, Greyhounds */
				type: PropTypes.oneOf([RACING_THOROUGHBRED_CODE, RACING_HARNESS_CODE, RACING_GREYHOUNDS_CODE]).isRequired,

				/** The number of the race within the meeting */
				race_number: PropTypes.number.isRequired,

				/** The starting time of the race */
				start_datetime: PropTypes.string.isRequired,

				race_name: PropTypes.string.isRequired,

				state: PropTypes.string.isRequired,

				distance: PropTypes.string,

				country: PropTypes.string.isRequired,

				/** Next to jump race products object */
				products: PropTypes.array,
				/** Array of objects that contain all the selection details */
				selection: PropTypes.arrayOf(
					PropTypes.shape({
						/**
						 * Selections will contain all the selection details but besides 'id' they are not needed to be defined here.
						 * They are defined in the RaceSelectionsListItem
						 */
						id: PropTypes.number.isRequired,

						/** The list of prices for the selection */
						prices: PropTypes.arrayOf(
							PropTypes.shape({
								id: PropTypes.number, //@HW
								/** The current price for a win bet with this product */
								win_odds: PropTypes.number,

								/** The current price for a place bet */
								place_odds: PropTypes.number,

								/** The current price for a margin bet */
								margin_odds: PropTypes.number,

								/** The code for this product with this product */
								product_code: PropTypes.string.isRequired,

								/** The product id (NOTE: this is not a unique id between products) */
								product_id: PropTypes.number.isRequired,
							})
						),
					}),
				),
			}),
		).isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
		}).isRequired,

		/** Function to handle the click event on each next to jump item */
		onItemClick: PropTypes.func,

		/** Action to track when a filter is applied */
		trackOnFilterClick: PropTypes.func,

		/** Whether or not to hide the next to jump filter */
		hideRaceNextJumpFilter: PropTypes.bool,

		/** Action to build the list of selection bet buttons */
		buildSelectionBetButtons: PropTypes.func.isRequired,

		/** The currently selected bet type */
		betType: PropTypes.string, // @HW

		selectionCount: PropTypes.number,

		/** Navigate to the race page */
		onGoToRace: PropTypes.func,

	};

	static defaultProps = {
		className: null,
		onItemClick: () => undefined,
		trackOnFilterClick: () => undefined,
		hideRaceNextJumpFilter: false,
		betType: '',// @HW
		selectionCount: 0,
		onGoToRace:null,
	};

	state = {
		// initially set all the type filters active
		//typeFilter: new Set([RACING_THOROUGHBRED_CODE, RACING_HARNESS_CODE, RACING_GREYHOUNDS_CODE]),
		typeFilter: new Set([]),
	};

	/**
	 * The onClick on the Carousel item returns the race id passed in.
	 * Find that id in the list of items then call the onClick handler
	 *
	 * @param id
	 */
	handleClick = (id) => {
		let item = this.props.items.find((item) => item.id === id);
		this.props.onGoToRace(item.type,item.start_datetime,item.id, item.meeting_id);

	};
	/**
	 * Handles the clicking/selecting of the race type filters
	 * @param type
	 */
	handleOnFilterClick = (type) => {
		this.saveTypeToFilters(type);
		this.props.trackOnFilterClick(RACING_TYPES_LOOKUP[type]);
	};

	/**
	 * Saves the race type filters to state
	 * @param type
	 */
	saveTypeToFilters = (type) => {
		let types = new Set(this.state.typeFilter);

		if (!type) {
			return;
		}

		if (types.has(type)) {
			types.delete(type);
		} else {
			types.add(type);
		}

		this.setState({
			typeFilter: types,
		});
	};

	resetFilterClick = () => {
		this.setState({ typeFilter: new Set([]) });
	}
	/**
	 * Checks whether the startTime is around 5min from now and returns period to set Ticker interval.
	 *
	 * @param {string} startTime YYYY-11-29T16:32:42+11:00
	 * @return {number|null} - ticker period
	 */
	getTickerInterval = (startTime = null) => {
		/**
		 * Date gets startTime and convert to milliseconds to calculate time to jump.
		 * If the result in millisecond is less then 5 minutes return 1 sec in milliseconds.
		 */
		if (Math.abs(new Date(startTime) - new Date()) <= 300000) {
			return 1000;
		}
		// null makes Ticker clear interval.
		return null;
	};
	render() {
		const {
			className,
			size ,
			onGoToRace,
			hideRaceNextJumpFilter,
		} = this.props;
		const items = filterNextToJumpRaces(this.props.items, Array.from(this.state.typeFilter));
		const componentClassesV3 = cx({
			[className]: className,
		});

		if (items.length === 0) {
			return false;
		}
		return (

			<StyledGRSNextRaceList_MainSection>
				<StyledNextJumpRace_Header_section>
					<StyledNextJumpSport_maijor_header size={size}>
						
						<StyledNextJumpRace_Major_header_h2>{'NEXT TO JUMP RACES'}</StyledNextJumpRace_Major_header_h2>

						<StyledRaceNextJumpHomePage__Filters_section>
							<div className={css`flex: 1 1 80%; `}>
								
							</div>
							<div className={css`flex: 1 1 13%;margin:4px;`}>
								{!hideRaceNextJumpFilter && (
									<RaceNextJumpFilter
										mode={size.width > modeChangeOverSize ? 'vertical' : 'horizontal'}
										onFilterItemClick={this.handleOnFilterClick}
										types={Array.from(this.state.typeFilter)}
										resetFilterClick={this.resetFilterClick}
										size={size}

									/>
								)}

							</div>
							
						</StyledRaceNextJumpHomePage__Filters_section>

					</StyledNextJumpSport_maijor_header>

				</StyledNextJumpRace_Header_section>
				

				

				<StyledRaceNextJumpV3 className={componentClassesV3}>
					
					<StyledRaceNextJumpV3__Carousel size={size} nextButton prevButton freeMode buttonStyle="1">
							{items.map(
						(nextToJumpRaceV3) => (
							<StyledRaceNextJumpV3Wrapper key={nextToJumpRaceV3.id} size={size}>
								<PusherSubscriberGRSNjRaceChannel raceId={nextToJumpRaceV3.id} />
								<Ticker every={this.getTickerInterval(nextToJumpRaceV3.start_datetime)} >
									{/* next jump header */}
									<StyledRaceNextJumpV3__NtjRacesCarouselItem
										id={nextToJumpRaceV3.id}
										raceNumber={nextToJumpRaceV3.race_number}
										type={nextToJumpRaceV3.type}
										meetingName={nextToJumpRaceV3.meeting_name}
										startTime={nextToJumpRaceV3.start_datetime}
										meetingState={nextToJumpRaceV3.state}
										raceName={nextToJumpRaceV3.race_name}
										raceDistance={nextToJumpRaceV3.distance}
                    					start_datetime={nextToJumpRaceV3.start_datetime}
										country={nextToJumpRaceV3.country}
										action={this.handleClick}
										onGoToRace={onGoToRace}
										size={size}

									/>

								
									<StyledRaceNextJumpV3_carousel_body_content size={size}>
										<StyleNjRaceCardContainer
											id={nextToJumpRaceV3.id}
											race={nextToJumpRaceV3}
											raceId={nextToJumpRaceV3.id}
											meetingId={nextToJumpRaceV3.meeting_id}
											meetingName={nextToJumpRaceV3.meeting_name}
											selections={Array.isArray(nextToJumpRaceV3.selection) ? nextToJumpRaceV3.selection : [] }
											action={this.handleClick}
											 size={size}
										/>

									</StyledRaceNextJumpV3_carousel_body_content>
								</Ticker>
							</StyledRaceNextJumpV3Wrapper>
						),
						this,
					)}
						</StyledRaceNextJumpV3__Carousel>


				</StyledRaceNextJumpV3>

			</StyledGRSNextRaceList_MainSection>


		);
	}
}

export default RaceNextJumpV3;
