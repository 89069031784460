import React from 'react';
import PropTypes from 'prop-types';
import PusherSubscribe from '../../../controllers/Pusher/PusherSubscribe.js';

import { connect } from 'react-redux';
import { PUSHER_EVENT_UPCOMING_RACES_UPDATE, } from '../../../../common/constants/Racing';
import { mergeUpcomingRaces } from '../../../../store/entities/actions/UpcomingAllRaceActions.js';

class PusherSubscriberUpcomingRacesChannel extends React.Component {
	static propTypes = {
		/** Action to update the UpComgin races */
		onRacesUpdate: PropTypes.func,
	};
	static defaultProps = {
		onRacesUpdate: () => undefined,
	};

	/**
	 * Update handler for next to jump races.
	 */
	onUpdate = (data) => {
       // console.log(data)
		this.props.onRacesUpdate(data);
	};

	render() {
		return <PusherSubscribe channel={'upcoming-races'} event={PUSHER_EVENT_UPCOMING_RACES_UPDATE} onUpdate={this.onUpdate} />;
	}
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
	onRacesUpdate: (data) => dispatch(mergeUpcomingRaces(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PusherSubscriberUpcomingRacesChannel);
