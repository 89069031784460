import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { spacings, typography, media } from '@tbh/ui-kit';

// get the default silk image
import { GENERIC_SILK, SELECTION_NOT_SCRATCHED_STATUS } from '../../../../../common/constants/Racing';
import { Image } from '@tbh/ui-kit';
import { Text } from '@tbh/ui-kit';
import { FONT_SIZE_12, FONT_SIZE_10, WEIGHTS, COLORS } from '../../../../../store/application/applicationConstants';
import { allocationWeightCalculate } from '../../../../../common/allocationWeightCalc';

const StyledRaceSelectionsDetails = styled('div')`
	${(props) =>
		css`
			label: RaceSelectionsDetails;

			width: 100%;
			display: flex;
			align-items: center;
			padding: ${spacings(props).tight}px 0;
			height: 65px;
			gap: 10px;
		`};
`;

const StyledRaceSelectionsDetails__Silk = styled('div')`
	${(props) =>
		css`
			label: RaceSelectionsDetails__Silk;

			float: left;
			width: 28px;
			min-width: 28px;
			max-height: 37px;
			margin-right: ${spacings(props).constrictive}px;
			text-align: center;
			align-self: center;

			${props.scratched &&
			css`
					opacity: 0.2;
					filter: grayscale(100%);
				`};
		`};

	${media(
				(props) =>
					css`
				margin-right: ${spacings(props).compact}px;
			`,
				500,
			)};

	${media(
				css`
			width: 45px;
			max-height: 55px;
		`,
				660,
			)};
`;

const StyledRaceSelectionsDetails__SilkImage = styled(Image)`
	height: 26px;

	${media(
	css`
			height: 37px;
		`,
	660,
)};
`;

const StyledRaceSelectionsDetails__DetailsColumn = styled('div')`
	${(props) =>
		css`
			label: RaceSelectionsDetails__DetailsColumn;

			padding-top: ${spacings(props).tight}px;
			text-overflow: ellipsis;
			overflow: hidden;
			${props.scratched &&
			css`
					opacity: 0.2;
					filter: grayscale(100%);
				`};
		`};

	${media(
				css`
			padding-top: 0;
		`,
				660,
			)};
`;

const StyledFeaTRaceSelectionsDetails__LastStartsRow = styled(Text)`
	${(props) =>
		css`
			label: FeaTRaceSelectionsDetails__LastStartsRow;

			font-size: ${FONT_SIZE_10};

			${props.scratched &&
			css`
					opacity: 0.2;
					filter: grayscale(100%);
				`};

		`};

	${media(
				(props) =>
					css`
				font-size: ${FONT_SIZE_10};
			`,
				660,
				{ sizeKey: 'mediaSize' },
			)};
`;

const StyledRaceSelectionsDetails__Name = styled(Text)`
	${(props) =>
		css`
			label: RaceSelectionsDetails__Name;
			margin-right: ${spacings(props).tight}px;
			color: rgb(0, 58, 145);
     
			&:after {
				content: ' ';
			}
		`};

	${media(
			(props) =>
				css`
			font-size: ${FONT_SIZE_12}
			color: rgb(0, 58, 145);
			`,
			660,
			{ sizeKey: 'mediaSize' },
		)};
`;

const StyledRaceSelectionsDetails__RunnerDetails = styled(Text)`
${(props) =>
		css`
		label: RaceSelectionsDetails__RunnerDetails;

		margin-right: ${spacings(props).tight}px;
		margin-bottom: 0;
		&:after {
			content: ' ';
		}
	`};
	${media(
			(props) =>
				css`
			 //font-size: ${typography(props)['size_-1']}px;
			 color: ${COLORS.secondary};
			 font-weight: ${WEIGHTS.exBold};
			 font-size: ${FONT_SIZE_10};
			`,
			660,
			{ sizeKey: 'mediaSize' },
		)};
`;

const StyledRaceSelectionsDetails__Subtitle = styled(Text)`
	${(props) =>
		css`
			label: RaceSelectionsDetails__Subtitle;

			margin: ${spacings(props).constrictive}px 0;
		`};
`;



const FeatureRaceSelectionDetails = (props) => {
	const {
		t,
		className,
		hideSilk,
		number,
		name,
		silk,
		jockey,
		trainer,
		barrier,
		last_starts_string,
		selection_status,
		subtitle,
		type_code,
		weight,
		size,
		id,// selection id
		is_jockey_hot,
		is_trainer_hot,
		hotSelection,
		allocation
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	/**
	 * Create the jockey or driver label dependant on the race type code
	 * NOTE: no need to cater for greyhounds as it is not rendered
	 * last_starts_string.substring(last_starts_string.length , 5); This is the get last _starts_string in jocky.
	 * @type {string}
	 */
	let JockeyLable = 'J';
	let Driver = 'D'; // driver
	let Trainer = 'T';
	let jockeyLabel = type_code === 'H' ? Driver : JockeyLable;
	let weightLabel = 'W';
	let last_startsLable = 'F';

	let lastFiveStarts;
	if (last_starts_string) {
		lastFiveStarts = last_starts_string.substring(0, 5);
	}

	const scratched = selection_status !== SELECTION_NOT_SCRATCHED_STATUS;
	const trainerLabel = typeof trainer === 'object' ? null : trainer;

	return (
		<StyledRaceSelectionsDetails className={componentClasses}>
			{!hideSilk && (
				<StyledRaceSelectionsDetails__Silk scratched={scratched} size={size}>
					<StyledRaceSelectionsDetails__SilkImage
						size={size}
						src={silk ? silk : GENERIC_SILK}
						alt={name + 'racing silk'}
						substituteImageSrc={GENERIC_SILK}
					/>
					{last_starts_string && (
						<StyledFeaTRaceSelectionsDetails__LastStartsRow
							size="-5"
							align="center"
							strong
							scratched={scratched}
							mediaSize={size}
						>
							{lastFiveStarts}
						</StyledFeaTRaceSelectionsDetails__LastStartsRow>
					)}

				</StyledRaceSelectionsDetails__Silk>
			)}
			{selection_status === SELECTION_NOT_SCRATCHED_STATUS ?
				<StyledRaceSelectionsDetails__DetailsColumn scratched={scratched} size={size}>
					<StyledRaceSelectionsDetails__Name
						lineThrough={scratched}
						mediaSize={size}
						size="-3"
						transform="uppercase"
						strong
					>
						{number && `${number}.`} {name}

					</StyledRaceSelectionsDetails__Name>



					{trainerLabel && (
						<StyledRaceSelectionsDetails__RunnerDetails mediaSize={size} size="-5">
							{Trainer}: {trainerLabel}
						</StyledRaceSelectionsDetails__RunnerDetails>
					)}




					{type_code && type_code !== 'G' && jockey ? (
						<StyledRaceSelectionsDetails__RunnerDetails
							mediaSize={size}
							size="-5"
							whiteSpace="nowrap"
							textOverflow="ellipsis"
						>
							{type_code !== 'H' ? (
								<span>{weightLabel}: </span>
							) : null}
							{weight > 0 && (
								<StyledRaceSelectionsDetails__RunnerDetails tag="span" mediaSize={size} size="-5">
									{weight}
									{t('AcronymKilogram')}
									<span className={css`margin-right:10px;`} />
								</StyledRaceSelectionsDetails__RunnerDetails>
							)}
							{jockeyLabel}: {jockey}{' '} {allocationWeightCalculate(weight, allocation)}
						</StyledRaceSelectionsDetails__RunnerDetails>
					) : null}


				</StyledRaceSelectionsDetails__DetailsColumn>

				: <StyledRaceSelectionsDetails__DetailsColumn scratched={scratched} size={size}>
					<StyledRaceSelectionsDetails__Name
						lineThrough={scratched}
						mediaSize={size}
						size="-3"
						transform="uppercase"
						strong
					>
						{number && `${number}.`} {name}

					</StyledRaceSelectionsDetails__Name>
					<StyledRaceSelectionsDetails__Name
						mediaSize={size}
						size="-3"
						transform="uppercase"
						strong
					>
						<StyledRaceSelectionsDetails__RunnerDetails tag="span" mediaSize={size} size="-5">
							{'Scratched'}
						</StyledRaceSelectionsDetails__RunnerDetails>
					</StyledRaceSelectionsDetails__Name>
				</StyledRaceSelectionsDetails__DetailsColumn>
			}



		</StyledRaceSelectionsDetails>
	);
};

FeatureRaceSelectionDetails.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}),

	/** Selections race number */
	id: PropTypes.number,

	/** The selection name */
	name: PropTypes.string.isRequired,

	/** The selection number */
	number: PropTypes.number,

	/** The race selection type */
	type_code: PropTypes.string,

	/** The jockey weight */
	weight: PropTypes.number,

	/** The jockey silk */
	silk: PropTypes.string,

	/** The jockey name */
	jockey: PropTypes.string,

	/** The trainer name */
	//trainer: PropTypes.oneOfType(PropTypes.string, PropTypes.object),
	trainer: PropTypes.string,

	/** The selection barrier */
	barrier: PropTypes.string,

	/** The last starts for the selection */
	last_starts_string: PropTypes.string,

	/** The selection status */
	selection_status: PropTypes.oneOf(['not scratched', 'scratched', 'late scratching']),

	/** The fixed price changes/flucs */
	fluctuations: PropTypes.string,

	/**
	 * If the silk should be hidden or not.
	 * In the race card we need to show a generic silk for the runners that don't have a silk,
	 * but hide the image for selections that don't have a silk (E.g.: in the derivative selections).
	 */
	hideSilk: PropTypes.bool,

	/** Extra text to display under the runner */
	subtitle: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,

	hotSelection: PropTypes.number,

	is_jockey_hot: PropTypes.string,

	is_trainer_hot: PropTypes.string,
};

FeatureRaceSelectionDetails.defaultProps = {
	id: null,
	className: null,
	weight: null,
	silk: null,
	jockey: null,
	trainer: null,
	barrier: null,
	last_starts_string: null,
	selection_status: null,
	fluctuations: null,
	number: null,
	subtitle: null,
	type_code: null,
	hideSilk: false,
	size: {},
	hotSelection: null,
	is_jockey_hot: null,
	is_trainer_hot: null,
};

export default withNamespaces()(FeatureRaceSelectionDetails);
