import styled, { css } from 'react-emotion';
import { media, spacings, typography } from '@tbh/ui-kit';
import { COLORS, FAMILYS, WEIGHTS } from '../../../../constants/themes';

import UPCarousel from '../../Carousel/UPCarousel';
import UpRacesCarouselItem from '../UpRacesCarouselItem/UpRacesCarouselItem'; // new @HW added
import UpRaceCardContainer from '../../../../containers/Racing/UpRaceCardContainer/UpRaceCardContainer';
import UpRaceCardContainerLoading from './../../../../containers/Racing/UpRaceCardContainer/UpRaceCardContainerLoading';

export const StyledRaceUpcoming = styled('div')`
	label: RaceUpcoming;

	display: flex;
	flex-wrap: wrap;
	-ms-flex-wrap: wrap;

	display: -webkit-box;
	display: -ms-flexbox;
`;

// Size at which the mode should change over
export const modeChangeOverSize = 751;

export const StyledRaceUpcoming__Carousel = styled(UPCarousel)(
	(props) => css`
		label: RaceUpcoming__UPCarousel;
		flex: 1;
		height: auto;
		overflow: hidden;
	`,
	(props) =>
		media(
			css`
				flex: 1;
				height: auto;
				overflow: hidden;
			`,
			modeChangeOverSize,
		),
);

export const StyledRaceUpcoming__NtjRacesCarouselItem = styled(UpRacesCarouselItem)(
	(props) => css`
		label: RaceUpcoming__NtjRacesUpCarouselItem;
		min-height: 65px;
		width: auto;
		box-sizing: border-box;
	`,
);

export const StyledRaceUpcoming_carousel_body_content = styled('div')(
	(props) => css`
		label: RaceUpcoming_carousel_body_content;

		color: #2e2e2e;
		// background-color: #f7f8f8;
		height: auto;
		width: auto;
	`,
	media(
		(props) =>
			css`
				width: auto;
			`,
		768,
	),
);

export const StyleUpRaceCardContainer = styled(UpRaceCardContainer)(
	(props) => css`
	 label: NextJumpHomePage__NjRaceCardContainer;
	 width: auto;
	 //padding: ${spacings(props).tight}px;
	`,
);

export const StyleUpRaceCardContainerLoading = styled(UpRaceCardContainerLoading)(
	(props) => css`
	 label: NextJumpHomePage__NjRaceCardContainer;
	 width: auto;
	 //padding: ${spacings(props).tight}px;
	`,
);

export const StyledRaceUpcomingWrapper = styled('div')(
	(props) => css`
		label: RaceUpcoming__Wrapper;
		box-shadow: 0px 2px 5px rgb(0 0 0 / 38%);
		position: relative;
		max-width: 100%;
		margin-right: 5px;
		margin-left: 5px;
	`,
	media(
		(props) =>
			css`
				position: relative;

				max-width: 100%;
			`,
		768,
	),
);

export const StyledUpcomingRace_Filters_section = styled('div')`
	${(props) =>
		css`
			label: UpcomingRace_Filters_section;

			box-sizing: border-box;
			-webkit-tap-highlight-color: transparent;
			width: 70%;
			display: flex;
			justify-content: end;
			align-items: center;
			padding-right: 10px;
		`};

	${media(
			(props) =>
				css`
				display: flex;
				padding: ${spacings(props).compact}px;
				width: 100%;
			`,
			500,
		)};
`;

export const StyledUpcomingRaceList_MainSection = styled('div')(
	(props) => css`
		label: UpcomingRaceList_MainSection;

		position: relative;
		display: flex;
		flex-direction: column;
		flex: 1 0 0;
		height: auto;
		width: 100%;
	`,
);
export const StyledUpcomingRace_Header_section = styled('div')`
	${(props) =>
		css`
			label: UpcomingRace_Header_section;
			// margin: 0px ${spacings(props).tight}px ${spacings(props).tight}px ${spacings(props).tight}px;
			position: relative;
			z-index: 0; // This stops the loading mask from overlapping the header bar
			// margin-bottom: 12px;
		`};
`;
export const StyledUpcoming_maijor_header = styled('div')`
	${(props) =>
		css`
			label: upcoming_maijor_header;
			background: ${COLORS.gray[600]};
			border-bottom: 1px solid ${COLORS.gray[600]};
			clear: both;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: nowrap;
			width: 100%;
			border-radius: 8px 8px 0 0 !important;
			min-height: 30px;
		`};
	${media(
			(props) =>
				css`
				display: flex;
				flex-wrap: nowrap;
				width: 100%;
			`,
			400,
		)};
`;

export const StyledUpcoming_maijor_header_title = styled('div')`
	${(props) =>
		css`
			label: Upcoming_maijor_header_title;

			clear: both;
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			padding: 16px;
		`};
	${media(
			(props) =>
				css`
				display: flex;
				padding: 16px;
				//width: fit-content;
				// margin-top: 25px;
			`,
			400,
		)};
`;
export const StyledUpcomingpRace_Major_header_h2 = styled('h2')`
	${(props) =>
		css`
			label: UpcomingpRace_Major_header_h2;
			text-transform: uppercase;
			font-weight: ${WEIGHTS.exBold};
			color: white;
			text-transform: uppercase;
			display: inline-block;
			margin: 0;
			height: auto;
			position: relative;
			font-size: 1.25rem;
			font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};
			letter-spacing: 0.32px;
			text-align: center;
			line-height: 15px;
		`};
`;
