import { useState, useRef, useEffect } from 'react';

const useBrowserSessionTime = (key, defaultValue = 0, {
    serialize = JSON.stringify,
    deserialize = JSON.parse,
} = {}) => {
    const [browserSessionTime, setBrowserSessionTime] = useState(() => {
        const valueInBrowserSessionTime = window.sessionStorage.getItem(key);
        if (valueInBrowserSessionTime) {
            return deserialize(valueInBrowserSessionTime);
        }
        return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
    });

    const pevBrowserKey = useRef(key);

    useEffect(() => {
        const prevKey = pevBrowserKey.current;
        if (prevKey !== key) {
            window.sessionStorage.removeItem(prevKey);
        }
        pevBrowserKey.current = key;
        window.sessionStorage.setItem(key, serialize(browserSessionTime));
    }, [key, serialize, browserSessionTime]);


    useEffect(() => {
        const browserSessionInterval = setInterval(() => {
            setBrowserSessionTime(prevState => prevState + 1);
        }, 1000);
        return () => clearInterval(browserSessionInterval);
    });

    return [browserSessionTime, setBrowserSessionTime];
};




export default useBrowserSessionTime;