import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces, Trans } from 'react-i18next';

import { spacings, Button, FlexText, Icon, Image, Link, LoadingMask, Text, Notification } from '@tbh/ui-kit';

// Styling
const StyledBPayDeposit = styled('div')(
	(props) => css`
		label: BPayDeposit;

		padding: ${spacings(props).cozy}px;
	`,
);

const StyledBPayDeposit__DetailsSection = styled('div')(
	(props) => css`
		label: BPayDeposit__DetailsSection;

		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: ${spacings(props).cozy}px;
		position: relative;
	`,
);

const StyledBPayDeposit__Details = styled('div')(
	(props) => css`
		label: BPayDeposit__Details;

		padding-right: ${spacings(props).cozy}px;
		flex: 1;
	`,
);

const StyledBPayDeposit__Notification = styled(Notification)(
	(props) => css`
		label: BPayDeposit__Notification;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const BPayDeposit = (props) => {
	const { t, className, logo, billerCode, bpayRef, onGotItClick } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<StyledBPayDeposit className={componentClasses}>
			<Text paragraph strong>
				{t('BPayDeposit__Title', { BPay: 'BPay' })}
			</Text>

			<StyledBPayDeposit__Notification>
				<Text size="-2">
					<Trans i18nKey="BPayDeposit__Notification">
						<strong>Note: </strong>
						Contact your bank, credit union or building society to make this payment from your cheque, savings, debit or
						credit card account.
					</Trans>
				</Text>
			</StyledBPayDeposit__Notification>

			<StyledBPayDeposit__Notification>
				<Text align="center" size="-2">
					{`${t('MoreInfo')}: `}
					<Link underline href="http://www.bpay.com.au/" target="blank">
						www.bpay.com.au
					</Link>{' '}
					<Icon icon="log-out" />
				</Text>
			</StyledBPayDeposit__Notification>

			<StyledBPayDeposit__DetailsSection>
				<LoadingMask loading={!billerCode || !bpayRef} solid />
			
				<StyledBPayDeposit__Details>
					<Text paragraph strong>
						{t('BPayDeposit__InfoTitle', { BPay: 'BPay' })}
					</Text>

					<FlexText
						className={css`
							max-width: 300px;
						`}
					>
						<Text size="-2">
							<strong>{t('BillerCode')}: </strong>
						</Text>
						<Text size="-2">{billerCode}</Text>
					</FlexText>

					<FlexText
						className={css`
							max-width: 300px;
						`}
					>
						<Text size="-2">
							<strong>{t('Ref')}: </strong>
						</Text>
						<Text size="-2">{bpayRef}</Text>
					</FlexText>
				</StyledBPayDeposit__Details>
				{logo && <Image src={logo} alt="BPay logo" />}
			</StyledBPayDeposit__DetailsSection>

			{onGotItClick && (
				<Button type="primary" block action={onGotItClick}>
					{t('GotItThanks')}
				</Button>
			)}
		</StyledBPayDeposit>
	);
};

BPayDeposit.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** On 'Got it..'click  */
	onGotItClick: PropTypes.func,

	/** Extra classes */
	className: PropTypes.string,

	/** URL for image */
	logo: PropTypes.string,

	/** Bpay Data */
	billerCode: PropTypes.string,
	bpayRef: PropTypes.string,
};

BPayDeposit.defaultProps = {
	className: '',
	logo: null,
	billerCode: null,
	bpayRef: null,
	onGotItClick: undefined,
};

export default withNamespaces()(BPayDeposit);
