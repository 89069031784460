/**
 * Created by Josef Frank on 2017/03/31.
 */
'use strict';
// Constants
import {
	SPORTS_HOME_SET_NTJ_IDS,
	SPORTS_HOME_SET_SELECTED_BASE_COMPETITION,
	SPORTS_HOME_SET_SELECTED_COMPETITION,
	SPORTS_HOME_SET_SELECTED_EVENT,
	SPORTS_HOME_SET_SELECTED_SPORT,
	SPORTS_HOME_SET_SELECTED_MARKET,
	SPORTS_SET_LOADING_EVENTS,
} from './sportsHomeActionTypes';


// const {} = require('../constants/SportsHome');

// Initial state
const initialState = {
	selectedSport: null,
	selectedSportName: null,
	selectedBaseCompetition: null,
	selectedCompetition: null,
	selectedEvent: null,
	selectedMarket: null,
	nextToJumpSports: null,
	loadingEvents: false,
};

/**
 * Reducer for the sports home
 * @param state
 * @param action
 * @returns {Object}
 */
const sportsHomeReducer = (state = initialState, action) => {
	let sportsName;
	switch (action.type) {
		case SPORTS_HOME_SET_SELECTED_SPORT:
			sportsName = (action.payload && action.payload.name) || state.selectedSportsName;

			if (!action.payload || !action.payload.id) {
				sportsName = null;
			}

			return {
				...state,
				selectedSport: (action.payload && action.payload.id) || initialState.selectedSport,
				selectedSportName: sportsName,
			};
		case SPORTS_HOME_SET_SELECTED_BASE_COMPETITION:
			return {
				...state,
				selectedBaseCompetition: action.payload || initialState.selectedBaseCompetition
			};
		case SPORTS_HOME_SET_SELECTED_COMPETITION:
			return {
				...state,
				selectedCompetition: action.payload || initialState.selectedCompetition
			};
		case SPORTS_HOME_SET_SELECTED_EVENT:
			return {
				...state,
				selectedEvent: action.payload || initialState.selectedEvent
			};
		case SPORTS_HOME_SET_SELECTED_MARKET:
			return {
				...state,
				selectedMarket: action.payload || initialState.selectedMarket
			};
		case SPORTS_HOME_SET_NTJ_IDS:
			return {
				...state,
				nextToJumpSports: action.payload
			};
		case SPORTS_SET_LOADING_EVENTS:
			return {
				...state,
				loadingEvents: action.payload
			};
	}

	// By default, return the state
	return state;
};

module.exports = sportsHomeReducer;