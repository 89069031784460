import React, { useState, useEffect, Fragment } from 'react';
import {
	StyledCheckboxDiv,
	StyledCheckboxInput,
	StyledSectionSubTitle,
	StyledSectionSubScriptionVia,
	// StyledCheckboxInputGroup
} from '../SettingsContainer';
import { StyledSection, StyledSectionTitle, StyledSectionButton, Container, Column, Row } from '../../Components';
import { withNamespaces } from 'react-i18next';
import { createErrorBoundary } from '../../../../ErrorBoundary/ErrorBoundaryActions';
import { connect } from 'react-redux';
import { setDefaultUserSettings } from '../../../../../store/defaultBetAmounts/defaultBetAmountsActions';
import styled, { css } from 'react-emotion';
import { Icon, spacings, Text } from '@tbh/ui-kit';

const RacingTypeTag = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: #000;
	font-size: 11px;
	font-weight: 550;
	cursor: pointer;
	padding: 4px 6px;
	border-radius: 6px;
	min-width: 80px;
	${(props) => props.active && 'background-color: #850000;color:#fff;'}

	&:hover {
		${(props) => (!props.active ? 'background-color: #850000;color:#fff;' : 'background-color:  #666666;')}
	}
`;

const StyledViaMarketPref = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: row;
		gap: ${spacings(props).compact}px;
	`,
);

const NotificationSection = ({ seetings, user, updateUserSetting, brandDetails }) => {
	const [form, setForm] = useState({
		market_pref: seetings.market_pref,
		mobile_optin: seetings.mobile_optin,
		email_optin: seetings.email_optin,
		phone_optin: seetings.phone_optin,
		other_optin: seetings.other_optin,
	});

	useEffect(() => {
		// Uncheck the other checkboxes if market_pref is set to false
		if (!form.market_pref) {
			setForm((prevForm) => ({
				...prevForm,
				mobile_optin: false,
				email_optin: false,
				phone_optin: false,
				other_optin: false,
			}));
		} else {
			setForm((prevForm) => ({
				...prevForm,
				mobile_optin: true,
				email_optin: true,
				phone_optin: true,
				other_optin: true,
			}));
		}
	}, [form.market_pref]);

	useEffect(() => {
		setForm({
			market_pref: seetings.market_pref,
			mobile_optin: seetings.mobile_optin,
			email_optin: seetings.email_optin,
			phone_optin: seetings.phone_optin,
			other_optin: seetings.other_optin,
		});
	}, [seetings]);

	const handleFormChange = (e) => {
		setForm({ ...form, [e.target.name]: e.target.checked });
	};

	return (
		<div>
			<Text size="1">Marketing Preferences</Text>
			<Container>
				<Text size="-1" strong>
					* Marketing Subscription
				</Text>

				<Column justifyContent="flex-start" alignItems="flex-start">
					<StyledCheckboxDiv>
						<StyledCheckboxInput
							type="checkbox"
							checked={form.market_pref}
							onChange={handleFormChange}
							name="market_pref"
							id="market_pref"
						/>
						<span htmlFor="market_pref">
							Yes, I agree to receive direct marketing material from {brandDetails.teamname}
						</span>
					</StyledCheckboxDiv>
				</Column>

				{form.market_pref && (
					<Fragment>
						<Text size="-2" strong>
							* Via
						</Text>
						<StyledViaMarketPref>
                    <StyledCheckboxDiv>
                        <StyledCheckboxInput type="checkbox"
                            checked={form.email_optin}
                            onChange={handleFormChange}
                            name="email_optin"
                            id="email_optin"
                            disabled={!form.market_pref} />
                        <span htmlFor="email_optin">Email</span>
                    </StyledCheckboxDiv>
                    <StyledCheckboxDiv>
                        <StyledCheckboxInput type="checkbox"
                            checked={form.mobile_optin}
                            onChange={handleFormChange}
                            name="mobile_optin"
                            id="mobile_optin"
                            disabled={!form.market_pref} />
                        <span htmlFor="mobile_optin">SMS</span>
                    </StyledCheckboxDiv>
                    <StyledCheckboxDiv>
                        <StyledCheckboxInput type="checkbox"
                            checked={form.phone_optin}
                            onChange={handleFormChange}
                            name="phone_optin"
                            id="phone_optin"
                            disabled={!form.market_pref} />
                        <span htmlFor="phone_optin">Phone</span>
                    </StyledCheckboxDiv>
                    <StyledCheckboxDiv>
                        <StyledCheckboxInput type="checkbox"
                            checked={form.other_optin}
                            onChange={handleFormChange}
                            name="other_optin"
                            id="other_optin"
                            disabled={!form.market_pref}
                        />
                        <span htmlFor="other_optin">Other Means</span>
                    </StyledCheckboxDiv>
						</StyledViaMarketPref>
					</Fragment>
				)}
		
				<Column justifyContent="flex-start" alignItems="flex-start">
					<StyledSectionButton
						color="#161a1d"
						fontColor="white"
						onClick={() => {
							updateUserSetting(user, form);
						}}
					>
						Save
					</StyledSectionButton>
				</Column>
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => {
	const authenticatedUser = state.application.authenticatedUser;
	const user = state.entities.users[authenticatedUser] || {};

	const settings = user.settings || {};

	return {
		user,
		brandDetails: state.acl.brandDetails,
		seetings: settings,
		market_pref: settings.market_pref != undefined ? settings.market_pref : false,
		email_optin: settings.email_optin != undefined ? settings.email_optin : false,
		mobile_optin: settings.mobile_optin != undefined ? settings.mobile_optin : false,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateUserSetting: (user, setting) => {
			dispatch(setDefaultUserSettings(user, setting));
		},
	};
};
export default withNamespaces()(createErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(NotificationSection)));
