import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { Trans, withNamespaces } from 'react-i18next';

import { application, spacings, typography, media, Button, ButtonGroup, Text , Icon } from '@tbh/ui-kit';


const StyledSportsFilterSelection__Button = styled(Button)(
	(props) => css`
		label: SportsFilterSelection__Button;

        margin-top: ${spacings(props).compact}px;
        font-size: 16px;
        font-weight: 800;
        line-height: 1.5;
        padding: 8px;
		text-align: center;
		// This is to keep all the buttons at the top of the race card the same width
		min-width: ${application(props).racing.button_width}px;

		&:hover {
			// This is to override the specificity of the Text component
			${Text} {
				color: ${typography(props).color_1_alternate};
			}
		}


		&:first-child {
			margin-left: 5px; // This is to override the specificity of the button group
		}
	`,

	media(
		(props) => css`
			min-width: ${application(props).racing.button_width_expanded}px;
		`,
		780,
	),
);

const StyledSportsFilterSelection = styled(ButtonGroup)(
	(props) => css`
		label: SportsFilterSelection;

		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: ${spacings(props).compact}px;
	`,
	media(
		(props) => css`
			justify-content: space-between;

			${StyledSportsFilterSelection__Button} {
				min-width: ${application(props).racing.button_width_expanded}px;
			}
		`,
		780,
	),
);

const cssSportsFilterSelection__Text = css`
	label: SportsFilterSelection__Text;

    line-height: inherit;
    font-size: 16px;
    font-weight: 800;
    line-height: 1.5;
`;

const SportsFilterSelection = (props) => {
    const { t, handleSelectType,   className, size ,activeSport,  sportIcon,sportName, group } = props;

	const componentClasses = cx({
		[className]: className,
    });


    const handleSportClick = () => {
        const {id} = props;
		handleSelectType(id);

    };

    const getButtonStyle = (activeSport) => {

        if(activeSport){
            return activeSport ? 'secondary' : 'primary';
        }

        return undefined;

	};

	const getTextStyle = (activeSport) => {
        if(activeSport){
            return activeSport ? 'alternate' : 'primary';
        }

        return undefined;

    };

    const cssStyledSportsFilterSelection__ButtonExpanded =
    size.width < 780
        ? css`
                display: none;
          `
        : css`
                display: inline;
          `;

    const buttonStyles = {
        sportsGroup: {
			button: getButtonStyle(activeSport),
			text: getTextStyle(activeSport),
		},

    };

    return (
	<StyledSportsFilterSelection
        className={componentClasses}
        spacing={ButtonGroup.spacings.SPACING_TIGHT}
        right
        size={size}
        >

		<StyledSportsFilterSelection__Button
				hover="alternate"
				type={buttonStyles.sportsGroup.button}
                action={handleSportClick}
                size="small"
			>
			<Text align="center" size="-3" type={buttonStyles.sportsGroup.text} className={cssSportsFilterSelection__Text}>
				<Trans i18nKey="StyledSportFilterSelection">

					<Icon icon={sportIcon} size="-1" push="1" />

					<Text
							size="-3"
							tag="span"
							type={buttonStyles.sportsGroup.text}
							className={cssStyledSportsFilterSelection__ButtonExpanded}
						>
						{sportName.toUpperCase()} {group.length > 0 && ('  (' + group.length +')')}
					</Text>

				</Trans>
			</Text>
		</StyledSportsFilterSelection__Button>

	</StyledSportsFilterSelection>
    );

};

SportsFilterSelection.propTypes = {
    /** Translation func provided by withNamespaces HOC */
    t: PropTypes.func.isRequired,

    /** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
    }).isRequired,

    /** Navigate to type */
    handleSelectType: PropTypes.func,

    /** Extra classes */
    className: PropTypes.string,

    /** List of different sport ids */
    sportIds: PropTypes.array,

    /** active sport id */
    activeSport: PropTypes.bool,

    /** sport name */
    sportName: PropTypes.string,

     /** sport icon */
    sportIcon: PropTypes.string,

    /** sport  id */
    id: PropTypes.number,

    /** sport group  */
    group: PropTypes.array,

};

SportsFilterSelection.defaultProps = {
    handleSelectType: () => undefined,
    className: null,
    sportIds: [],
    sportIcon: undefined,
    activeSport: false,
    sportName: undefined,
    id: null,
    group: [],
};

export default withNamespaces()(SportsFilterSelection);
