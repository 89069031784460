import { LEGAL_BASE_URL, LEGAL_CONSTANT } from '../pages/LegalPage/LegalConstants';
import { scrollToTop } from '../store/application/applicationActions';
import { getRule, getRules, setRule } from '../store/legalRules/legalRulesAction';

const FooterRouter = Backbone.Router.extend({
	routes: {
		[LEGAL_BASE_URL]: 'showLegalPages',
		[`${LEGAL_BASE_URL}/:page`]: 'showSelectedLegalPage',
	},

	showLegalPages: async function() {
		scrollToTop();
		App.startSubApp(LEGAL_CONSTANT);

		const rules = await App.store.dispatch(getRules());

		if (typeof rules != 'string') {
			await App.store.dispatch(getRule(rules[0].alias));
		}
	},

	showSelectedLegalPage: async function(page) {
		scrollToTop();
		App.startSubApp(LEGAL_CONSTANT);

		let rules = App.store.getState().legalRules.rules;

		if (rules.length == 0) {
			rules = await App.store.dispatch(getRules());
		}

		if (typeof rules != 'string') {
			if (page == 'contact-us') {
				App.store.dispatch(setRule(null));
			}

			const selected = rules.find((rule) => rule.alias === page);

			if (selected) {
				await App.store.dispatch(getRule(page));
			}
		}
	},
});

module.exports = new FooterRouter();
