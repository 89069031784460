// Racing icons
export const harness = '\\68';
export const dogs = '\\67';
export const greyhounds = '\\67';
export const thoroughbred = '\\72';

// All other icons
export const add = '\\e94e';
export const myBets = '\\e94c';
export const arrowRight = '\\e92e';
export const arrowLeft = '\\e92f';
export const arrowUp = '\\e94a';
export const arrowDown = '\\e94b';
export const receipt = '\\e949';
export const receiptCrossed = '\\e94d';
export const phoneCode = '\\e908';
export const money = '\\e90b';
export const visa = '\\e90d';
export const mastercard = '\\e90e';
export const wallet = '\\e90f';
export const creditCardEdit = '\\e92b';
export const cvv = '\\e938';
export const creditCardAdd = '\\e945';
export const creditCardDelete = '\\e946';
export const creditCard = '\\e947';
export const signupInfo = '\\e948';
export const sportsSpecials = '\\e911';
export const sports = '\\e942';
export const clockLight = '\\e943';
export const nextUp = '\\e943';
export const multiSpecials = '\\e944';
export const olympics = '\\e902';
export const winterOlympics = '\\e92a';
export const commonwealthGames = '\\e930';
export const athletics = '\\e931';
export const entertainment = '\\e932';
export const equestrian = '\\e933';
export const extremeSports = '\\e934';
export const featureRacing = '\\e935';
export const gaelicSports = '\\e936';
export const thoroughbredSpecials = '\\e937';
export const lawnBowls = '\\e939';
export const lotto = '\\e93a';
export const mma = '\\e93b';
export const music = '\\e93c';
export const novalty = '\\e93d';
export const politics = '\\e93e';
export const racing = '\\e93f';
export const sailing = '\\e940';
export const sportsFuture = '\\e941';
export const racingSpecials = '\\e912';
export const netball = '\\e910';
export const esports = '\\e904';
export const eSports = '\\e904';
export const viewList = '\\e301';
export const list = '\\e301';
export const viewModule = '\\e302';
export const grid = '\\e302';
export const viewStream = '\\e304';
export const group = '\\e304';
export const publish = '\\e633';
export const play = '\\e634';
export const pause = '\\e635';
export const stop = '\\e636';
export const next = '\\e637';
export const previous = '\\e638';
export const first = '\\e639';
export const last = '\\e63a';
export const fullscreen = '\\e63b';
export const resizeEnlarge = '\\e63b';
export const resizeShrink = '\\e63c';
export const eye = '\\e603';
export const show = '\\e603';
export const visible = '\\e603';
export const hide = '\\e63d';
export const volumeHigh = '\\e62c';
export const volumeMedium = '\\e62d';
export const volumeLow = '\\e62e';
export const volumeMute = '\\e62f';
export const volumeMute2 = '\\e630';
export const volumeIncrease = '\\e631';
export const volumeDecrease = '\\e632';
export const youtube = '\\e600';
export const movie = '\\e609';
export const book = '\\e60a';
export const file = '\\e60c';
export const email = '\\e623';
export const location = '\\e60b';
export const clock = '\\e624';
export const alarm = '\\e625';
export const mobile = '\\e627';
export const phone = '\\e627';
export const handheld = '\\e627';
export const undo = '\\e628';
export const redo = '\\e629';
export const bubble = '\\e60d';
export const bubbles = '\\e60e';
export const spinner = '\\e62a';
export const unlocked = '\\e611';
export const settings = '\\e601';
export const equalizer = '\\e602';
export const fire = '\\e612';
export const signup = '\\e613';
export const star = '\\e614';
export const star2 = '\\e615';
export const starHalf = '\\e615';
export const star3 = '\\e616';
export const starFull = '\\e616';
export const heart = '\\e617';
export const heartFull = '\\e617';
export const heartEmpty = '\\e618';
export const heartBroken = '\\e619';
export const thumbsUp = '\\e61a';
export const thumbsDown = '\\e61b';
export const close = '\\e61c';
export const checkmark = '\\e61d';
export const tick = '\\e61d';
export const minus = '\\e61e';
export const plus = '\\e61f';
export const googleplus = '\\e620';
export const feed = '\\e606';
export const apple = '\\e607';
export const android = '\\e608';
export const home = '\\e63e';
export const closeCircle = '\\30';
export const plusCircle = '\\2b';
export const minusCircle = '\\2d';
export const cog = '\\2e';
export const checkCircle = '\\31';
export const warning = '\\32';
export const surfing = '\\36';
export const rugbyLeague = '\\39';
export const volleyBall = '\\3a';
export const motorRacing = '\\3b';
export const motorSport = '\\3b';
export const martialArts = '\\3c';
export const jockeysChallenge = '\\6a';
export const iceHockey = '\\3e';
export const hockey = '\\3f';
export const darts = '\\41';
export const cricket = '\\42';
export const athletics2 = '\\45';
export const americanFootball = '\\47';
export const basketball = '\\3d';
export const bettabucks = '\\24';
export const rugbyUnion = '\\e926';
export const azSports = '\\e913';
export const australianRules = '\\e915';
export const baseball = '\\e916';
export const boxing = '\\e917';
export const golf = '\\e91d';
export const promotion = '\\e922';
export const promotions = '\\e922';
export const promo = '\\e922';
export const cycling = '\\e923';
export const tennis = '\\e927';
export const linkedin = '\\e900';
export const laurel = '\\e905';
export const signupCross = '\\e906';
export const signupHelp = '\\e907';
export const signupLockDashed = '\\e909';
export const signupLock = '\\e90a';
export const signupTick = '\\e90c';
export const search = '\\e2ca';
export const accountTransaction = '\\e914';
export const calendar = '\\e918';
export const cancel = '\\e919';
export const depositFunds = '\\e91a';
export const facebook = '\\e91b';
export const football = '\\e91c';
export const soccer = '\\e91c';
export const handball = '\\e91c';
export const lock = '\\e91e';
export const menu = '\\e91f';
export const motorcycling = '\\e920';
export const user = '\\e921';
export const resetPassword = '\\e924';
export const rightArrowCircle = '\\e925';
export const tournaments = '\\e928';
export const trophy = '\\e928';
export const twitter = '\\e929';
export const whatsapp = '\\e92c';
export const withdrawal = '\\e92d';
export const ticket = '\\e901';
export const logOut = '\\e903';
export const instagram = '\\ea92';