// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import { updateDepositLimit } from '../../../store/depositLimits/depositLimitsActions';
import { updateLossLimit } from '../../../store/lossLimits/lossLimitsActions';
import { updateSpendLimit } from '../../../store/spendLimits/spendLimitsActions';
import { closeModal } from '../../../store/modal/modalActions';

// Components
import ModalHeader from '../../../components/features/Application/ModalHeader/ModalHeader';
import BrandContactPhone from '../../../components/ui-components/BrandContactPhone/BrandContactPhone';
import UKLimitConfirmation from '../../../components/features/ResponsibleGambling/UKLimitConfirmation/UKLimitConfirmation';
import ModalFooter from '../../../components/features/Application/ModalFooter/ModalFooter';

class UKLimitConfirmationContainer extends Component {
	state = {
		error: null,
	};

	/**
	 * Calls the action to update the limit
	 */
	handleUpdateLimit = () => {
		let strings = this.props.period.split('_');
		let period = strings[0];
		let limitType = strings[1];

		if (limitType === 'deposit') {
			this.props.updateDepositLimit(period).catch((error) => {
				if (error.response.status === 400) {
					this.setState({
						error: error.response.data.errors,
					});
				} else {
					this.setState({
						error: error.message,
					});
				}
			});
		} else if (limitType === 'loss') {
			this.props.updateLossLimit(period).catch((error) => {
				if (error.response.status === 400) {
					this.setState({
						error: error.response.data.errors,
					});
				} else {
					this.setState({
						error: error.message,
					});
				}
			});
		} else if (limitType === 'spend') {
			this.props.updateSpendLimit(period).catch((error) => {
				if (error.response.status === 400) {
					this.setState({
						error: error.response.data.errors,
					});
				} else {
					this.setState({
						error: error.message,
					});
				}
			});
		}
	};

	/**
	 * Cancel and close the modal
	 */
	handleUndo = () => {
		this.props.handleClose();
	};

	/**
	 * Remove the error message
	 */
	handleClearError = () => {
		this.setState({
			error: null,
		});
	};

	render() {
		let { bet_limit, period, handleClose, brandName } = this.props;

		let { error } = this.state;

		let { handleUpdateLimit, handleUndo, handleClearError } = this;

		let requestedLimit = bet_limit.requested_bet_limit;
		let betLimit = bet_limit.bet_limit;

		let strings = period.split('_');
		let limitPeriod = strings[0];
		let limitType = strings[1];

		return (
			<div>
				{requestedLimit > 0 ? (
					<ModalHeader title={`${limitType} Limit Change`} onClose={handleClose} aside={<BrandContactPhone />} />
				) : (
					<ModalHeader title={`${limitType} Limit Removal`} onClose={handleClose} aside={<BrandContactPhone />} />
				)}

				<UKLimitConfirmation
					limitType={limitType}
					period={limitPeriod}
					requested_date={bet_limit.requested_date}
					bet_limit={betLimit}
					requested_bet_limit={requestedLimit}
					onConfirmLimit={handleUpdateLimit}
					onUndo={handleUndo}
					error={error}
					clearError={handleClearError}
					brandName={brandName}
				/>

				<ModalFooter />
			</div>
		);
	}
}

UKLimitConfirmationContainer.propTypes = {
	userId: PropTypes.number.isRequired,

	period: PropTypes.string.isRequired,

	// hook for updating the deposit limit
	updateDepositLimit: PropTypes.func.isRequired,

	// hook for updating the loss limit
	updateLossLimit: PropTypes.func.isRequired,

	// hook for updating the spend limit
	updateSpendLimit: PropTypes.func.isRequired,

	// user's limit
	bet_limit: PropTypes.object.isRequired,

	// hook for closing the modal
	handleClose: PropTypes.func.isRequired,

	/** Brand name */
	brandName: PropTypes.string,
};

UKLimitConfirmationContainer.defaultProps = {
	brandName: null,
};

const mapStateToProps = (state) => {
	return {
		userId: state.application.authenticatedUser,
		brandName: state.acl.brandDetails && state.acl.brandDetails.name,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateDepositLimit: (period) => {
			return dispatch(updateDepositLimit(period)).then(() => dispatch(closeModal('UKLimitConfirmationContainer')));
		},
		updateLossLimit: (period) => {
			return dispatch(updateLossLimit(period)).then(() => dispatch(closeModal('UKLimitConfirmationContainer')));
		},
		updateSpendLimit: (period) => {
			return dispatch(updateSpendLimit(period)).then(() => dispatch(closeModal('UKLimitConfirmationContainer')));
		},
		handleClose: () => {
			return dispatch(closeModal('UKLimitConfirmationContainer'));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(UKLimitConfirmationContainer);
