import {
	denormalize,
	normalize,
	schema
} from 'normalizr';
import {meetingsSchema} from './Entities';


/**
 * Denormalize entities back to a structure similar to the API response.
 *
 * @param entities
 * @param {Array} keys
 * @returns {Array}
 */
export const denormalizeMeetings = (entities, keys) => {
	return denormalize(keys || Object.keys(entities.meetings), meetingsSchema, entities).filter((meeting) => !!meeting);
};

/**
 * Normalize the meetings structure to suit our application.
 *
 * @returns {Object}
 * @param response
 */
export const normalizeMeetings = (response) => {
	return normalize(response, meetingsSchema);
};

const meetingOnlySchema = new schema.Entity('meetings');
const meetingsOnlySchema = new schema.Array(meetingOnlySchema);

/**
 * Denormalize Meetings ignoring relationships.
 *
 * @param entities
 * @param {Array} keys
 * @returns {Array}
 */
export const denormalizeMeetingsOnly = (entities, keys) => {
	return denormalize(keys || Object.keys(entities.meetings), meetingsOnlySchema, entities);
};
