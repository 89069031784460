import styled, { css } from 'react-emotion';
import { spacings } from '@tbh/ui-kit';
import { BREAKPOINTS } from '../../../../../constants/themes';


export const StyledBannerImage = styled('img')`
    label: BannerImage;
    width: 100%;
    border-radius: 4px;
`;

export const StyleBannerContent = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    padding: 12px;
`;
