import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import {
	media,
	spacings,
	ui,
	typography,
	Button,
	FlexText,
	Icon,
	Image,
	InputGroup,
	Link,
	PasswordInput,
	Text,
	UserIcon,
} from '@tbh/ui-kit';
import AclContext from '../../../../contexts/AclContext';
import { EVENT_KEY_ENTER } from '../../../../common/constants/Application';

const StyledLockedScreen__UserInfo = styled('div')(
	(props) => css`
		label: LockedScreen__UserInfo;

		display: flex;
		justify-content: space-between;
		background-color: ${ui(props).color_2};
		padding: ${spacings(props).cozy}px;
		margin: ${spacings(props).comfortable}px 0 ${spacings(props).cozy}px;
	`,
	(props) =>
		media(
			css`
				padding: ${spacings(props).comfortable}px;
			`,
			500,
		),
);

const StyledLockedScreen__InputAndButton = styled(InputGroup)(
	(props) => css`
		label: LockedScreen__InputAndButton;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const StyledLockedScreen__Button = styled(Button)(
	(props) => css`
		label: LockedScreen__Button;

		padding: 0 ${spacings(props).spacings}px;
	`,
);

const StyledLockedScreen__UserIcon = styled(UserIcon)(
	css`
		label: LockedScreen__UserIcon;

		display: none;
	`,
	(props) =>
		media(
			css`
				display: block;
				height: 90px;
				width: 90px;
				font-size: ${typography(props).size_6};
				line-height: 90px;
				margin-right: ${spacings(props).comfortable}px;
			`,
			500,
		),
);

class LockedScreen extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Clears the error message */
		clearError: PropTypes.func.isRequired,

		/** User's id */
		id: PropTypes.number.isRequired,

		/** Sends the password to login the user */
		login: PropTypes.func.isRequired,

		/** Logs the user out */
		logout: PropTypes.func.isRequired,

		/** User's username */
		username: PropTypes.string.isRequired,

		/** List of available icons to use */
		brandLogos: PropTypes.object.isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
		}).isRequired,

		/** Error message for the login */
		error: PropTypes.string,

		/** Text for the footer */
		footerMessage: PropTypes.string,

		/** If the login action is loading or not */
		loading: PropTypes.bool,

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		error: null,
		footerMessage: null,
		loading: false,
	};

	state = {
		password: '',
	};

	handleLogin = () => {
		this.props.login(this.state.password);
	};

	onPasswordChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	onKeyDown = (event) => {
		if (event.key === EVENT_KEY_ENTER) {
			this.handleLogin();
		}
	};

	render() {
		const {
			className,
			clearError,
			error,
			footerMessage,
			id,
			loading,
			logout,
			username,
			brandLogos,
			size,
			t,
		} = this.props;

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<div className={componentClasses}>
				{brandLogos.main && (
					<div
						className={css`
							width: 175px;
							margin: 0 auto;
						`}
					>
						<Image width={175} src={brandLogos.main} alt={t('Logo')} />
					</div>
				)}

				<StyledLockedScreen__UserInfo size={size}>
					<StyledLockedScreen__UserIcon id={id} name={username} size={size} />

					<div
						className={css`
							flex: 1 1 auto;
						`}
					>
						<FlexText
							className={css`
								align-items: baseline;
							`}
						>
							<Text size="1" strong>
								{username}
							</Text>
							<Text type="light">
								<Icon
									className={css`
										vertical-align: text-top;
									`}
									icon="lock"
								/>{' '}
								{t('Locked')}
							</Text>
						</FlexText>

						<Text type="light" size="-1" paragraph>
							{moment().calendar()}
						</Text>

						<StyledLockedScreen__InputAndButton>
							<PasswordInput
								value={this.state.password}
								name="password"
								onChange={this.onPasswordChange}
								onKeyDown={this.onKeyDown}
								placeholder={t('Password')}
								error={error}
								onOutsidePopoverClick={clearError}
								horizontalMargin="cozy"
								popoverError
							/>
							<StyledLockedScreen__Button action={this.handleLogin} loading={loading} type="primary">
								{t('Login')}
							</StyledLockedScreen__Button>
						</StyledLockedScreen__InputAndButton>

						<Text align="right" size="-2">
							{t('LockedScreen__NotYou')}{' '}
							<Link action={logout} size="-2" underline>
								{t('LockedScreen__SignIn')}
							</Link>
						</Text>
					</div>
				</StyledLockedScreen__UserInfo>

				{footerMessage && (
					<Text align="center" type="alternate-light" size="-1">
						<AclContext.Consumer>
							{(acl) => `${footerMessage} ${acl.gambleResponsiblyMessage ? acl.gambleResponsiblyMessage : ''}`}
						</AclContext.Consumer>
					</Text>
				)}
			</div>
		);
	}
}

export default withNamespaces()(LockedScreen);
