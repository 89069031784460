/**
 * Created by Josef Frank on 2017/03/03.
 */

import {
	normalize,
	denormalize,
} from 'normalizr';
import {racesSchema} from './Entities';

/**
 * Normalize the race structure to suit JS Applications
 *
 * @param response
 */
export const normalizeRaces = (response) => {
	return normalize(response, racesSchema);
};


/**
 * Denormalize meeting entities back to a structure similar to the API response.
 *
 * @param entities
 * @param keys
 * @returns {Array}
 */
export const denormalizeRaces = (entities, keys) => {
	return denormalize(keys || Object.keys(entities.races), racesSchema, entities).filter((race) => !!race);
};
