import React from 'react';
import { css } from 'react-emotion';
// import { Text } from '@tbh/ui-kit';
// import { FONT_SIZE_12 } from '../../../../store/application/applicationConstants';
import {
	StyledUpCarouselItem,
	StyledNJRaceHeaderBar,
	StyledNJRaceHeaderBar__Heading,
	StyledRaceHeaderBar__Heading_raceNumber,
	StyledRaceHeaderBar__Heading_flagIcon,
	StyledRaceHeaderBar__Heading_More,
	StyledNJRaceHeaderBar__Metting__NameLoading,
	StyledNJRaceHeaderBar__Flage__Details,
	StyledNextToJumpCardItem__TimeToGoBUttonLoading,
} from './UpCarouselItem.styled';

const styleFlag = css`
	width: 25px;
	height: 12px;
	margin-left: 10px;
	border-radius: 5px;
`;

const UpCarouselItemLoading = (props) => {
	return (
		<StyledUpCarouselItem size={'0'} action={() => {}} style={{ width: '100%' }}>
			<StyledNJRaceHeaderBar noPadding margin={false}>
				<StyledNJRaceHeaderBar__Heading>
					<StyledRaceHeaderBar__Heading_raceNumber>
						<div
							className={css`
								color: #ffffff;
								background: #666666;
								border: 1px solid #666666;
								border-bottom: 2px solid #5c5c5c;
								text-align: center;
								margin-top: 8px;
								width: 20px;
								height: 20px;
							`}
						/>
					</StyledRaceHeaderBar__Heading_raceNumber>

					<StyledRaceHeaderBar__Heading_flagIcon />

					<StyledRaceHeaderBar__Heading_More>
						<div>
							<StyledNJRaceHeaderBar__Metting__NameLoading width="30px" />
							<StyledNJRaceHeaderBar__Metting__NameLoading width="50px" />
							<StyledNJRaceHeaderBar__Metting__NameLoading width="80px" />
						</div>

						<StyledNJRaceHeaderBar__Flage__Details>
							<div
								className={css`
									flex: 0 0 70%;
									max-width: 67%;
								`}
							/>
							<div
								className={css`
									flex: 0 0 30%;
									max-width: 30%;
								`}
							>
								<StyledNextToJumpCardItem__TimeToGoBUttonLoading lessThan2Min={false} />
							</div>
						</StyledNJRaceHeaderBar__Flage__Details>
					</StyledRaceHeaderBar__Heading_More>
				</StyledNJRaceHeaderBar__Heading>
			</StyledNJRaceHeaderBar>
		</StyledUpCarouselItem>
	);
};

export default UpCarouselItemLoading;
