import { RACING_BET_TYPE_PLACE, RACING_BET_TYPE_WIN, RACING_BET_TYPE_MARGIN, RACING_BET_TYPE_ISSURE_BET, RACING_BET_TYPE_NOTTO_WIN_BET, RACING_BET_TYPE_TRAINER_WIN_BET, RACING_BET_TYPE_TRAINERT_4_BET, RACING_SAME_RACE_MULTI_TYPE } from '../../../common/constants/Racing';

/**
 * Filter only win/places products
 *
 * @param products
 * @return {Array.<product>}
 */

/**
 *Change Order TO win Place
 *
 */
export const getWinPlaceProducts = (products = []) => {
	return products
		.filter(
			(product) =>
				product.bet_type === RACING_BET_TYPE_WIN ||
				product.bet_type === RACING_BET_TYPE_PLACE ||
				product.bet_type === 'oddsgrid' ||
				product.bet_type === RACING_BET_TYPE_MARGIN ||
				product.bet_type === RACING_BET_TYPE_ISSURE_BET ||
				product.bet_type === RACING_BET_TYPE_NOTTO_WIN_BET ||
				product.bet_type === RACING_BET_TYPE_TRAINERT_4_BET ||
				product.bet_type === RACING_BET_TYPE_TRAINER_WIN_BET ||
				product.bet_type === RACING_SAME_RACE_MULTI_TYPE
			,
		)
		.sort((productA, productB) => {
			// Make margin products appear first
			if (productA.bet_type === RACING_BET_TYPE_MARGIN) {
				return -1;
			} else if (productB.bet_type === RACING_BET_TYPE_MARGIN) {
				return 1;
			}

			if (productA.fixed === productB.fixed) {
				// if same product, Win first change to -1 : 1
				return productA.bet_type === RACING_BET_TYPE_WIN ? 1 : 1;
			}

			// Fixed first Change to -1 : 1
			return productA.fixed ? 1 : 1;
		});
};

export const getOddsGridPrice = (selections = []) => {
	return selections.map((selection) => (
		selection.prices.map((price) => (
			price.product_id
		))
	));
};


/**
 * Filter only exotic products
 *
 * @param products
 * @return {Array.<product>}
 */
export const getExoticProducts = (products = []) => {
	return products.filter(
		(product) => product.bet_type !== RACING_BET_TYPE_WIN && product.bet_type !== RACING_BET_TYPE_PLACE,
	);
};

/**
 * Build list of win/place product choices to be shown on bet type filter's mobile versions
 * @param {Array} winPlaceProducts
 * @return {Array}
 */
export const getNonExoticProductChoices = (winPlaceProducts = []) => {
	let winPlaceAvailableProducts = [];
	const winProduct = winPlaceProducts.find((product) => product.bet_type === RACING_BET_TYPE_WIN);
	const placeProduct = winPlaceProducts.find((product) => product.bet_type === RACING_BET_TYPE_PLACE);
	const SRMProduct = winPlaceProducts.find((product) => product.bet_type === RACING_SAME_RACE_MULTI_TYPE);
	/**
	 * 'isFixed' is metadata to use when selecting the chosen product to show on mobile.
	 * In the future, we can add different metadata if we have more products categories to render.
	 */

	if (winProduct) {
		winPlaceAvailableProducts.push({
			productLabel: 'Win',
			betType: RACING_BET_TYPE_WIN,
		});
	}

	if (placeProduct) {
		winPlaceAvailableProducts.push({
			productLabel: 'Place',
			betType: RACING_BET_TYPE_PLACE,
		});
	}

	if (SRMProduct) {
		winPlaceAvailableProducts.push({
			productLabel: 'SRM',
			betType: RACING_SAME_RACE_MULTI_TYPE,
		});
	}

	return winPlaceAvailableProducts;
};

/**
 * Get the matching price for a product, and ensure it maintains it's product ID
 *
 * @param selectedProduct
 * @param prices
 * @returns {*}
 */
export const createPriceForSelectedProduct = (selectedProduct, prices = []) => {
	const price = prices.find(
		(price) => price.product_id === selectedProduct.price_id || price.product_id === selectedProduct.product_id,
	);
	// Ensure we maintain the selected product code and ID, in the case we get prices from a different one
	if (price && price.product_id !== selectedProduct.product_id) {
		return {
			...price,
			price_id: selectedProduct.price_id,
			product_id: selectedProduct.product_id,
			product_code: selectedProduct.product_code,
			product_type: selectedProduct.product_type,
			product_margin: selectedProduct.margin,
		};
	}
	return price;
};

export const findPrice = (price, selection, key) => {
	return Array.isArray(selection[key])
		? selection[key].includes(price.product_id)
		: selection[key] === price.product_id;
};

export const findProduct = (product, selection, key, betType, isFixed) => {
	return (
		product.bet_type === betType &&
		product.fixed === isFixed &&
		(Array.isArray(selection[key])
			? selection[key].includes(product.product_id) || selection[key].includes(product.price_id)
			: selection[key] === product.product_id || selection[key] === product.price_id)
	);
};
