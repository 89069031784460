import { createSelector } from 'reselect';

// Schema functions
import { denormalizeMeetings } from '../../../store/entities/schemas/MeetingSchema';

// Constants
import {
	RACING_BET_TYPE_WIN,
	RACING_GREYHOUNDS_CODE,
	RACING_MARKET_NAME,
	RACING_EXOTIC_BET_TYPES,


	/// bet builder
	BET_BUILDER_LAST_POSITION_WIN,
	BET_BUILDER_LAST_POSITION_PLACE,
	RACING_EXOTIC_BET_TYPE_ORDER_LOOKUP,
	BET_BUILDER_LAST_BIG_WIN_2,
	BET_BUILDER_LAST_BIG_WIN_5,
	BET_BUILDER_BEATEN_FAV_YES,
	BET_BUILDER_BEATEN_FAV_NO,
	BET_BUILDER_DISTANCE_WIN,
	BET_BUILDER_DISTANCE_PLACE,
	BET_BUILDER_COURSE_WIN,
	BET_BUILDER_COURSE_PLACE,
	BET_BUILDER_COURSE_DISTANCE_WIN,
	BET_BUILDER_COURSE_DISTANCE_PLACE,
	BET_BUILDER_RATE_TO_WIN_Y,
	BET_BUILDER_RATE_TO_WIN_N,
	BET_BUILDER_WET_WIN,
	BET_BUILDER_WET_PLACE,
	BET_BUILDER_STRIKE_RATE_16,
	BET_BUILDER_STRIKE_RATE_20,
	BET_BUILDER_JOCKEY_STRIKE_RATE_16,
	BET_BUILDER_JOCKEY_STRIKE_RATE_20,
	BET_BUILDER_JOCKEY_WON_HORSE_Y,
	BET_BUILDER_JOCKEY_WON_HORSE_N,
	BET_BUILDER_JOCKEY_SWITCH_Y,
	BET_BUILDER_JOCKEY_SWITCH_N,
	BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_16,
	BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_20,
	SELECTION_SCRATCHED_STATUS,
	SELECTION_NOT_SCRATCHED_STATUS
} from '../../../common/constants/Racing';
import { GOAT_PRODUCT_TYPE_BOOST } from '../../../common/constants/GoatProducts';

import { denormalizeBets } from '../../../store/entities/schemas/BetSchema';
import { denormalizeRaces } from '../../../store/entities/schemas/RaceSchema';
import { BET_TYPE_MULTI } from '../../../store/entities/constants/BetConstants';
import {
	createPriceForSelectedProduct,
} from '../../../store/entities/selectors/ProductSelectors';
import { getRacingEntities } from '../../../store/entities/schemas/relationships/RacingRelationships';
import { getBettingEntities } from '../../../store/entities/schemas/relationships/BettingRelationships';
import { fetchFixedPriceRollup, fetchRollTablePrice } from '../../../store/application/applicationSelectors';





const getMeetingsAndRacesWithBets = createSelector(
	(state) => getBettingEntities(state.entities),
	(bettingEntities) => {
		// Grab look up table of meetings and races with bets.
		return searchRacesWithBets(denormalizeBets(bettingEntities));
	},
);

/**
 * Memoized selector to get meetings list
 * @params state
 */
const getMeetings = createSelector(
	(state) => state.betBuilderHome.showingMeetings,
	(state) => getRacingEntities(state.entities),
	getMeetingsAndRacesWithBets,
	(showingMeetings, racingEntities, meetingsAndRacesWithBets) => {
		let meetings = denormalizeMeetings(racingEntities, showingMeetings);
		return signMeetingsAndRacesWithBets(meetings, meetingsAndRacesWithBets);
	},
);


/**
 * Assess state and return selected meeting.
 * @params state
 */
const getSelectedMeeting = createSelector(
	(state) => state.betBuilderHome.selectedMeeting,
	(state) => getRacingEntities(state.entities),
	(state) => getBettingEntities(state.entities),
	(selectedMeeting, racingEntities, bettingEntities) => {
		// grab lookup table of races with bets.
		let racesWithBets = searchRacesWithBets(denormalizeBets(bettingEntities), selectedMeeting);

		// denormalize like the input were multiple meetings but given needed id.
		// logic similar to getMeetings but applied to a single meeting.
		let meetings = denormalizeMeetings(racingEntities, [selectedMeeting]);

		//If no meeting is found return error.
		if (!meetings || !meetings[0]) {
			console.error('No meeting has been found!');
			return null;
		}

		let meeting = signMeetingsAndRacesWithBets(meetings, racesWithBets)[0];

		// Sort races by number
		if (Array.isArray(meeting.races)) {
			meeting.races.sort((raceA, raceB) => +raceA.number - +raceB.number);
		}
		return meeting;
	},
);

/**
 * Assess state and return  bet builder selected meeting.
 * @params state
 * @HW 09JUNE2020
 */
const getBetbuilderSelectedMeeting = createSelector(
	(state) => state.betBuilderHome.selectedBuilderMeeting ? state.betBuilderHome.selectedBuilderMeeting : state.betBuilderHome.selectedMeeting,
	(state) => getRacingEntities(state.entities),
	(state) => getBettingEntities(state.entities),
	(selectedBuilderMeeting, racingEntities, bettingEntities) => {
		// grab lookup table of races with bets.
		let racesWithBets = searchRacesWithBets(denormalizeBets(bettingEntities), selectedBuilderMeeting);

		// denormalize like the input were multiple meetings but given needed id.
		// logic similar to getMeetings but applied to a single meeting.
		let meetings = denormalizeMeetings(racingEntities, [selectedBuilderMeeting]);

		//If no meeting is found return error.
		if (!meetings || !meetings[0]) {
			console.error('No meeting has been found!');
			return null;
		}

		let meeting = signMeetingsAndRacesWithBets(meetings, racesWithBets)[0];

		// Sort races by number
		if (Array.isArray(meeting.races)) {
			meeting.races.sort((raceA, raceB) => +raceA.number - +raceB.number);
		}
		return meeting;
	},
);

/**
 * Return an array of selected meetings
 *
 * @param state
 * @param selectedMeetingId
 * @returns {Array}
 */
const getDenormalizedMeeting = (state, selectedMeetingId) => {
	return denormalizeMeetings(getRacingEntities(state.entities), [selectedMeetingId]);
};


/**
 * The slice of state where the raceId is stored for bet builder home
 * @param state
 */
const betBuilderHomeRaceId = (state) => state.betBuilderHome.selectedRace;

/**
 * The slice of state where the selected race is stored against the bet prompt selection
 * @param state
 * @returns {null}
 */
const betPromptRaceId = (state) => (state.betPrompt.selections.length ? state.betPrompt.selections[0].race_id : null);

/**
 * Assess state and return selected race. Optionally provide it the function to use to retrieve the race ID from state.
 * If you provide it with a different slice to get the Race ID from, you must also make sure you pass it into
 * any sister selectors, eg: getFlucsKey(state, tournamentsRaceId)
 *
 * Usage: getSelectedRace(state, tournamentsRaceId)
 *
 * @param state
 * @param Function
 */
const getSelectedRace = createSelector(
	(state, getRaceIdSlice = betBuilderHomeRaceId) => getRaceIdSlice(state),
	(state, getRaceIdSlice = betBuilderHomeRaceId) => getRacingEntities(state.entities),
	(selectedRace, entities) => getRaceByID(entities, selectedRace, true),
);

/**
 * Function to sort a race, on a specified key, with a comparator function
 *
 * @param race
 * @param key
 * @param comparator
 * @returns {*}
 */
const sortRaceKey = (race, key, comparator) => {
	if (Array.isArray(race[key]) && race[key].length > 0) {
		race[key].sort(comparator);
	}

	return race;
};
/**
 * The win/place result should be sorted by positions 1 to 4
 * @param runnerA
 * @param runnerB
 */
const sortResultsByPosition = (runnerA, runnerB) => runnerA.position - runnerB.position;


/**
 * The runner number will be sorted
 * @param runnerA
 * @param runnerB
 */
const sortResultsByNumber = (runnerA, runnerB) => runnerA.number - runnerB.number;

/**
 * The exotics must follow the order expressed by the constant `RACING_EXOTIC_BET_TYPE_ORDER_LOOKUP`
 * @param typeA
 * @param typeB
 */
const sortExoticResultByBetTypeOrder = (typeA, typeB) =>
	RACING_EXOTIC_BET_TYPE_ORDER_LOOKUP[typeA.bet_type] - RACING_EXOTIC_BET_TYPE_ORDER_LOOKUP[typeB.bet_type];
/**
 * Sorts selections for Greyhound races.
 *
 * A scratched selection may have a 'not scratched' one to fill the vacant barrier.
 * Selections without a matching 'not scratched' one is sorted as not scratched.
 *
 * When there are only 8 runners: scratched runners should be left at the barrier they were assigned.
 * When there are more than 8 runners: should fill barriers with not scratched selections and leave scratched ones last sorted by barrier number.
 *
 * If a runner doesn't have a barrier, move it last, but still above the scratched selections
 *
 * @param selections
 * @return {Array.<*>}
 */
export const sortGreyhoundsByBarrier = (selections = []) => {
	const scratchings = selections.filter((selection) => {
		return selection.selection_status === SELECTION_SCRATCHED_STATUS && selection.barrier && selection.barrier <= 8;
	});

	// Find scratchings to be slotted in place
	const staticScratchings = scratchings.reduce((acc, scratching) => {
		const barrierMatches = selections.filter((selection) => selection.barrier === scratching.barrier);
		if (barrierMatches.length === 1) {
			acc.push(scratching);
		}

		return acc;
	}, []);

	return selections.sort((a, b) => {
		const aNumber = parseInt(a.number);
		const bNumber = parseInt(b.number);
		let aStatus = a.selection_status.toLowerCase();
		let bStatus = b.selection_status.toLowerCase();

		// For sorting, set the status to not scratched so it fits in properly
		if (staticScratchings.find((staticScratching) => staticScratching.id === a.id)) {
			aStatus = SELECTION_NOT_SCRATCHED_STATUS;
		}
		if (staticScratchings.find((staticScratching) => staticScratching.id === b.id)) {
			bStatus = SELECTION_NOT_SCRATCHED_STATUS;
		}

		// If the first and second statuses of the greyhounds are different, rank the scratched one lower.
		const aNotScratched = aStatus === SELECTION_NOT_SCRATCHED_STATUS;
		if (aNotScratched !== (bStatus === SELECTION_NOT_SCRATCHED_STATUS)) {
			return aNotScratched ? -1 : 1;
		}

		// Sort so that those without barriers are at the bottom, but above scratched selections
		if (b.barrier === 0) {
			// Return -1
			return b.barrier - a.barrier || aNumber - bNumber;
		}

		if (a.barrier === 0) {
			// Return 1
			return b.barrier - a.barrier || aNumber - bNumber;
		}

		// If the first greyhound is scratched, both must be scratched due to the logic in the 'aNotScratched' statement.
		// As such, sort by number instead.
		// If both greyhounds are not scratched, sort by number.
		const higherNumber = Math.max(aNumber, bNumber);
		if (!aNotScratched || higherNumber <= 8) {
			return aNumber - bNumber;
		}

		// Sort by barrier
		return a.barrier - b.barrier;
	});
};

/**
 * Sort various race keys
 *
 * @param race
 * @returns {*}
 */
const sortRaceItems = (race) => {
	//Sort results to ascending order by position.
	race = sortRaceKey(race, 'displayed_results', sortResultsByPosition);
	race = sortRaceKey(race, 'displayed_exotic_results', sortExoticResultByBetTypeOrder);

	// If Greyhounds
	if (race.type && race.type.toUpperCase() === RACING_GREYHOUNDS_CODE) {
		race.selections = sortGreyhoundsByBarrier(race.selections);
	} else {
		race = sortRaceKey(race, 'selections', sortResultsByNumber);
	}

	return race;
};

/**
 * Assess state and return selected race, sorting fields as required
 *
 * @params state
 */
const getRaceByID = (entities, selectedRace, sort = true) => {
	if (selectedRace) {
		let race = denormalizeRaces(entities, [selectedRace])[0];

		if (race) {
			// Sort predefined keys on a race
			if (sort) {
				race = sortRaceItems(race);
			}

			return race;
		}
	}

	return null;
};


/**
 * Build list of bets for given race id.
 */
const buildBetsForRace = createSelector(
	(state) => getBettingEntities(state.entities),
	(state) => state.betBuilderHome.selectedRace,
	(state) => state.betPrompt.selections,
	(entities, selectedRace, selections) => {
		return getSingleBetsForRace(denormalizeBets(entities), selectedRace, selections);
	},
);




/**
 * Passes extra options down to our filter action
 *
 * @param filterAction
 * @param options
 * @returns {function(...[*]): *}
 */
const customFilterCreator = (filterAction, ...options) => {
	return (...rest) => filterAction(...options, ...rest);
};




/**
 * Create Look up object with ids for each race with active bets.
 * 'event_id' represent race
 * 'competition_id' represents meeting
 *
 * If meetingId is passed, only such meeting and its races will be flagged.
 *
 * @param bets
 * @param meetingId
 * @return {*}
 */
const searchRacesWithBets = (bets = [], meetingId) => {
	return bets.reduce((acc, bet) => {
		if (!Array.isArray(bet.bet_selections)) {
			return acc;
		}

		bet.bet_selections.forEach((selection) => {
			// Avoids creating 'undefined' key:
			if (!selection.competition_id || !selection.event_id) {
				return;
			}

			// If selection is not valid
			if (!isValidActiveRacingSelection(selection, bet)) {
				return;
			}

			// If meetingId exist but selection does not belong to meeting
			if (meetingId && selection.competition_id !== meetingId) {
				return;
			}

			//If meeting still doesn't exist, create.
			if (!acc[selection.competition_id]) {
				acc[selection.competition_id] = {};
			}
			//If race still doesn't exist, create.
			if (!acc[selection.competition_id][selection.event_id]) {
				acc[selection.competition_id][selection.event_id] = true;
			}
		});

		return acc;
	}, {});
};

/**
 * Receives denormalized bets and build list with all active bets for given race.
 *
 * @param bets
 * @param raceId
 * @param selections
 * @returns {*|Array}
 */
const getSingleBetsForRace = (bets = [], raceId, selections = []) => {
	// Backup race attached to the selection in the instance that the bet prompt is not opened from racing home
	if (!raceId && selections && selections.length) {
		const selection = selections[0];
		raceId = selection.race_id || (selection.race && selection.race.id);
	}

	return bets.reduce((acc, bet) => {
		if (!Array.isArray(bet.bet_selections)) {
			return acc;
		}

		bet.bet_selections.find((selection) => {
			// Avoid creating 'undefined' key:
			if (!selection.event_id) {
				return false;
			}

			// If selection is not valid
			if (!isValidActiveRacingSelection(selection, bet)) {
				return false;
			}

			if (selection.event_id === raceId) {
				acc.push(bet);
				return true;
			}
		});

		return acc;
	}, []);
};

/**
 * Checks whether a selection is of racing market and is not from multi bet.
 * @param selection
 * @param bet
 * @return {boolean}
 */
const isValidActiveRacingSelection = (selection, bet) => {
	return selection.market_name === RACING_MARKET_NAME && bet.bet_type !== BET_TYPE_MULTI;
};

/**
 * Given a lookup table with every meeting and race with bets.
 * Traverse meetings adding flag 'hasBets' to those meetings and races
 * that have bets.
 *
 * @param meetings
 * @param meetingsAndRacesWithBets
 * @return {Array}
 */
const signMeetingsAndRacesWithBets = (meetings = [], meetingsAndRacesWithBets = {}) => {
	// If no bet found.
	if (Object.keys(meetingsAndRacesWithBets).length === 0) {
		return meetings;
	}

	return meetings.map((meeting) => {
		// If meeting has no race with bets.
		if (!meetingsAndRacesWithBets[meeting.id]) {
			return meeting;
		}

		let races = signRacesWithBets(meeting.races, meetingsAndRacesWithBets[meeting.id]);
		return {
			...meeting,
			hasBets: true,
			races,
		};
	});
};

/**
 * Create new race objects adding field hasBets.
 * @param races
 * @param {object} racesWithBets {raceId: true [, {raceId: true} ...] }
 * @return {Array} new array with races
 */
const signRacesWithBets = (races = [], racesWithBets = {}) => {
	return races.map((race) => {
		// If race has no bets
		if (!racesWithBets[race.id]) {
			return race;
		}

		return {
			...race,
			hasBets: true,
		};
	});
};





/**
 * Grab the fixed product from a race, otherwise grab the tote product
 *
 * @param raceProducts
 * @param selectionPrices
 * @param betType
 * @param isBettingAvailable
 * @returns {*}
 */
const getBetProduct = (
	raceProducts = [],
	selectionPrices = [],
	betType = RACING_BET_TYPE_WIN,
	isBettingAvailable = true,
) => {
	let betProduct;

	if (isBettingAvailable && raceProducts && raceProducts.length) {
		betProduct = raceProducts.find((product) => product.available && product.fixed && product.bet_type === betType);

		if (
			!betProduct ||
			// Check that a price exists for the product
			!selectionPrices.some(
				(price) =>
					price.product_code === betProduct.product_code &&
					price.product_id === betProduct.product_id &&
					price[`${betType}_odds`],
			)
		) {
			betProduct = raceProducts.find((product) => product.available && !product.fixed && product.bet_type === betType);
		}
	}

	return betProduct;
};

/**
 * Builds an array of bet buttons that will be displayed for each selection
 *
 * @param prices
 * @param displayedBetProducts
 * @param betType
 * @returns {function(*): Array}
 */
const buildSelectionBetButtons = (prices, displayedBetProducts, betType) => (dispatch) => {
	let betButtons = [];
	let priceAvailable;

	const displayMultiButton = !RACING_EXOTIC_BET_TYPES.includes(betType);

	displayedBetProducts.forEach((product, index) => {
		if (!product) {
			return;
		}

		//get the price for the current product
		let price = null;
		let priceRollups = 0;
		if (prices) {
			let priceObject = createPriceForSelectedProduct(product, prices);

			// A price may not exist even if the prices array exists
			if (priceObject) {
				// check the products bet type to determine which price to return
				price = priceObject[`${product.bet_type}_odds`];
				priceAvailable = true;
			}
		}

		let initialPrice = price;

		if (!price) {
			price = product.product_code;
			initialPrice = price;
			// If there is no price and product is fixed disable betting.
			priceAvailable = !product.fixed;
		} else if (product.product_type === GOAT_PRODUCT_TYPE_BOOST) {
			// Get the number of rolls to perform
			priceRollups = dispatch(fetchRollTablePrice(true, price)).rolls;

			// Set the boosted price
			price = dispatch(fetchFixedPriceRollup(true, price));
		}

		// add the button to the selections buttons array
		betButtons.push({
			index,
			price,
			initialPrice,
			priceRollups,
			product_id: product.product_id,
			product_code: product.product_code,
			product_type: product.product_type,
			bet_type: product.bet_type,

			// Availability of multi bet is based on product
			hasMulti: displayMultiButton && product.multi_available,

			// if the product is not available then disable the bet button
			productAvailable: product.available && price && priceAvailable,
		});
	});

	return betButtons;
};

/**
 * Memoized selector to customized meetings.
 * @params state
 */
const buildCustomizedMeetingsList = createSelector(
	getMeetings,
	(meetings) => {
		return buildTodayMeetingSelector(meetings);
	},
);

/**
 * get meeting list with id, name, date
 */
const buildTodayMeetingSelector = (meetings) => {
	let customizedMeetingList = [];

	//let sortMeetings =  meetings.sort((a, b) => moment(a.start_date) - moment(b.start_date));

	// sort by mmeeting name
	let sortMeetings = meetings.sort((a, b) => a.name.localeCompare(b.name));

	sortMeetings.forEach((meeting, index) => {
		//if(meeting.next_race_date !=='Invalid date' || meeting.next_race_date == null){
		customizedMeetingList.push({
			id: meeting.id,
			name: meeting.name,
			type: meeting.type,
			start_date: meeting.start_date,
			country: meeting.country,
			state: meeting.state,
			next_race_date: meeting.next_race_date,
		});
		//	}
	});

	return customizedMeetingList;
};


/**
 * get all selection count
 * @HW 10June2020
 * @param {*} meeting 
 */
const betBuilderMeetingSelectionsCount = (meetingId, betBuilderMeeting, selectedBetBuilderMeeting, types = [], showAll) => {
	let filteList = [];
	let filtSelectedList = [];


	if (betBuilderMeeting && meetingId == betBuilderMeeting.id) {
		Object.values(betBuilderMeeting.races).map((betRace) => {
			if (showAll == false && betRace.status === 'selling' && Array.isArray(betRace.selections) && betRace.selections.length > 0) {

				betRace.selections.map((Bselection) => {
					if (Bselection.runnerstats) {
						filteList.push(Bselection.runnerstats);
					}

				});
			} else if (showAll == true && Array.isArray(betRace.selections) && betRace.selections.length > 0) {
				betRace.selections.map((Bselection) => {
					if (Bselection.runnerstats) {
						filteList.push(Bselection.runnerstats);
					}

				});
			}
		});
	}

	/// selectedBetBuilderMeeting
	if (selectedBetBuilderMeeting) {
		Object.values(selectedBetBuilderMeeting.races).map((Race) => {
			if (showAll == false && Race.status === 'selling' && Array.isArray(Race.selections) && Race.selections.length > 0) {

				Race.selections.map((Bselection) => {
					if (Bselection.runnerstats) {
						filtSelectedList.push(Bselection.runnerstats);
					}

				});
			} else if (showAll == true && Array.isArray(Race.selections) && Race.selections.length > 0) {

				Race.selections.map((Bselection) => {
					if (Bselection.runnerstats) {
						filtSelectedList.push(Bselection.runnerstats);
					}

				});
			}
		});
	}

	let filte2List = [];
	if (types.length == 0) {
		filte2List = filtSelectedList;

		const filterDatacount = filterByType(filte2List, filtSelectedList, types);
		return filterDatacount.length;
	} else {
		filte2List = filtSelectedList;
		const filterDatacount = filterByType(filte2List, filtSelectedList, types);
		return filterDatacount.length;
	}


};


/**
 * filtered selection on bet builder of races
 * @HW 15JUNE2020
 * @param {*} betBuilderMeeting 
 * @param {*} types 
 */
const betBuilderMeetingSelectionsList = ((betBuilderMeeting, selectedBetBuilderMeeting, types = [], showAll) => {
	let racesList = [];
	let filterList = [];
	let initailRaceList = [];


	if (selectedBetBuilderMeeting) {
		// make initals races list object from selectedBetBuilderMeeting
		Object.values(selectedBetBuilderMeeting.races).map((betRace) => {
			if (showAll == false && betRace.status === 'selling') {
				initailRaceList.push(betRace);
			} else if (showAll == true) {
				initailRaceList.push(betRace);
			}

		});
	}

	// make races list object
	if (betBuilderMeeting) {
		Object.values(betBuilderMeeting.races).map((betRace) => {
			if (showAll == false && betRace.status === 'selling') {
				racesList.push(betRace);
			} else if (showAll == true) {
				racesList.push(betRace);
			}
		});

	}

	racesList.map((race) => {
		initailRaceList.map((iniRace) => {

			if (race && iniRace && race.id == iniRace.id && race.selections && iniRace.selections) {

				let filterSelection = filterRunnerStats(race.selections, iniRace.selections, types);

				if (filterSelection && filterSelection.length > 0) {

					race.selections = filterSelection;
					filterList.push(race);
				}
			}
		});


	});
	return filterList;

});

/**
 * filter race selections runnerstats
 * @param {*} data 
 * @param {*} types 
 */
const filterRunnerStats = (data, initSelections, types = []) => {

	let finalfilterList = data;
	if (Array.isArray(types) && types.length == 0) {
		return initSelections;
	} else {
		// filter function

		if (types.includes(BET_BUILDER_LAST_POSITION_WIN)) {
			finalfilterList = finalfilterList.filter(selection => selection.runnerstats.last_position == 1);
		}
		if (types.includes(BET_BUILDER_LAST_POSITION_PLACE)) {
			finalfilterList = finalfilterList.filter(selection => (selection.runnerstats.last_position == 1 || selection.runnerstats.last_position == 2 || selection.runnerstats.last_position == 3));
		}

		// Last Start Big Win
		if (types.includes(BET_BUILDER_LAST_BIG_WIN_2)) {
			finalfilterList = finalfilterList.filter(selection => (selection.runnerstats.last_position == 1 && selection.runnerstats.win_margin >= 2) && (selection.runnerstats.last_position == 1 && selection.runnerstats.win_margin <= 4.9));
		}
		if (types.includes(BET_BUILDER_LAST_BIG_WIN_5)) {
			finalfilterList = finalfilterList.filter(selection => (selection.runnerstats.last_position == 1 && selection.runnerstats.win_margin >= 5));
		}
		// Beaten Favourite
		if (types.includes(BET_BUILDER_BEATEN_FAV_YES)) {
			finalfilterList = finalfilterList.filter(selection => (selection.runnerstats.is_fev == 1 && selection.runnerstats.last_position != 1));
		}
		if (types.includes(BET_BUILDER_BEATEN_FAV_NO)) {
			finalfilterList = finalfilterList.filter(selection => (selection.runnerstats.is_fev == 0 || (selection.runnerstats.is_fev == 1 && selection.runnerstats.last_position == 1)));
		}
		// Distance
		if (types.includes(BET_BUILDER_DISTANCE_WIN)) {
			finalfilterList = finalfilterList.filter(selection => selection.runnerstats.distance_stat === 'W');
		}
		if (types.includes(BET_BUILDER_DISTANCE_PLACE)) {
			finalfilterList = finalfilterList.filter(selection => (selection.runnerstats.distance_stat === 'W') || (selection.runnerstats.distance_stat === 'P'));
		}
		//Course
		if (types.includes(BET_BUILDER_COURSE_WIN)) {
			finalfilterList = finalfilterList.filter(selection => selection.runnerstats.course_stat === 'W');
		}
		if (types.includes(BET_BUILDER_COURSE_PLACE)) {
			finalfilterList = finalfilterList.filter(selection => (selection.runnerstats.course_stat === 'W') || (selection.runnerstats.course_stat === 'P'));
		}
		// Course and Distance
		if (types.includes(BET_BUILDER_COURSE_DISTANCE_WIN)) {
			finalfilterList = finalfilterList.filter(selection => selection.runnerstats.course_distance_stat === 'W');
		}
		if (types.includes(BET_BUILDER_COURSE_DISTANCE_PLACE)) {
			finalfilterList = finalfilterList.filter(selection => (selection.runnerstats.course_distance_stat === 'P') || (selection.runnerstats.course_distance_stat === 'W'));
		}
		//Rate to win
		if (types.includes(BET_BUILDER_RATE_TO_WIN_Y)) {
			finalfilterList = finalfilterList.filter(selection => selection.runnerstats.win_rate == 1);
		}
		if (types.includes(BET_BUILDER_RATE_TO_WIN_N)) {
			finalfilterList = finalfilterList.filter(selection => selection.runnerstats.win_rate == 0);
		}

		//wet
		if (types.includes(BET_BUILDER_WET_WIN)) {
			finalfilterList = finalfilterList.filter(selection => selection.runnerstats.wet_stat === 'W');
		}
		if (types.includes(BET_BUILDER_WET_PLACE)) {
			finalfilterList = finalfilterList.filter(selection => (selection.runnerstats.wet_stat === 'P') || (selection.runnerstats.wet_stat === 'W'));
		}

		// Trainer Strike Rate (ST)
		if (types.includes(BET_BUILDER_STRIKE_RATE_16)) {
			finalfilterList = finalfilterList.filter(selection => selection.runnerstats.trainer_strike_rate >= 16 && selection.runnerstats.trainer_strike_rate <= 19.9);
		}
		if (types.includes(BET_BUILDER_STRIKE_RATE_20)) {
			finalfilterList = finalfilterList.filter(selection => selection.runnerstats.trainer_strike_rate >= 20);
		}

		// Jockey Strike Rate (ST)
		if (types.includes(BET_BUILDER_JOCKEY_STRIKE_RATE_16)) {
			finalfilterList = finalfilterList.filter(selection => (selection.runnerstats.jockey_strike_rate >= 16 && selection.runnerstats.jockey_strike_rate <= 19.9));
		}
		if (types.includes(BET_BUILDER_JOCKEY_STRIKE_RATE_20)) {
			finalfilterList = finalfilterList.filter(selection => selection.runnerstats.jockey_strike_rate >= 20);
		}

		// Jockey Won on horse
		if (types.includes(BET_BUILDER_JOCKEY_WON_HORSE_Y)) {
			finalfilterList = finalfilterList.filter(selection => selection.runnerstats.jockey_runner_strike_rate > 0);
		}
		if (types.includes(BET_BUILDER_JOCKEY_WON_HORSE_N)) {
			finalfilterList = finalfilterList.filter(selection => selection.runnerstats.jockey_runner_strike_rate == 0);
		}

		// Jockey SwitchfinalfilterList
		if (types.includes(BET_BUILDER_JOCKEY_SWITCH_Y)) {
			finalfilterList = finalfilterList.filter(selection => selection.runnerstats.is_jockey_switch == 1);
		}
		if (types.includes(BET_BUILDER_JOCKEY_SWITCH_N)) {
			finalfilterList = finalfilterList.filter(selection => selection.runnerstats.is_jockey_switch == 0);
		}

		// Trainer and Jockey Strike Rate (ST)
		if (types.includes(BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_16)) {
			finalfilterList = finalfilterList.filter(selection => selection.runnerstats.trainer_jockey_strike_rate >= 16 && selection.runnerstats.trainer_jockey_strike_rate <= 19.9);
		}
		if (types.includes(BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_20)) {
			finalfilterList = finalfilterList.filter(selection => selection.runnerstats.trainer_jockey_strike_rate >= 20);
		}


		return finalfilterList;

	}

};

/**
 * selections array filter
 * @param {*} data 
 * @param {*} types 
 */
const filterByType = (data, originalData, types = []) => {

	//console.log("data length" + data.length);

	let finalfilterList = data;
	if (types.length == 0) {
		return originalData;
	} else {



		if (types.includes(BET_BUILDER_LAST_POSITION_WIN)) {
			finalfilterList = finalfilterList.filter(selection => selection.last_position == 1);
		}
		if (types.includes(BET_BUILDER_LAST_POSITION_PLACE)) {
			finalfilterList = finalfilterList.filter(selection => selection.last_position == 1 || selection.last_position == 2 || selection.last_position == 3);
		}
		// Last Start Big Win
		if (types.includes(BET_BUILDER_LAST_BIG_WIN_2)) {
			finalfilterList = finalfilterList.filter(selection => (selection.last_position == 1 && selection.win_margin >= 2) && (selection.last_position == 1 && selection.win_margin <= 4.9));
		}
		if (types.includes(BET_BUILDER_LAST_BIG_WIN_5)) {
			finalfilterList = finalfilterList.filter(selection => (selection.last_position == 1 && selection.win_margin >= 5));
		}
		// Beaten Favourite
		if (types.includes(BET_BUILDER_BEATEN_FAV_YES)) {
			finalfilterList = finalfilterList.filter(selection => (selection.is_fev == 1 && selection.last_position != 1));
		}
		if (types.includes(BET_BUILDER_BEATEN_FAV_NO)) {
			finalfilterList = finalfilterList.filter(selection => (selection.is_fev == 0 || (selection.is_fev == 1 && selection.last_position == 1)));
		}
		// Distance
		if (types.includes(BET_BUILDER_DISTANCE_WIN)) {
			finalfilterList = finalfilterList.filter(selection => selection.distance_stat === 'W');
		}
		if (types.includes(BET_BUILDER_DISTANCE_PLACE)) {
			finalfilterList = finalfilterList.filter(selection => (selection.distance_stat === 'W') || (selection.distance_stat === 'P'));
		}
		//Course
		if (types.includes(BET_BUILDER_COURSE_WIN)) {
			finalfilterList = finalfilterList.filter(selection => selection.course_stat === 'W');
		}
		if (types.includes(BET_BUILDER_COURSE_PLACE)) {
			finalfilterList = finalfilterList.filter(selection => (selection.course_stat === 'W') || (selection.course_stat === 'P'));
		}
		// Course and Distance
		if (types.includes(BET_BUILDER_COURSE_DISTANCE_WIN)) {
			finalfilterList = finalfilterList.filter(selection => selection.course_distance_stat === 'W');
		}
		if (types.includes(BET_BUILDER_COURSE_DISTANCE_PLACE)) {
			finalfilterList = finalfilterList.filter(selection => (selection.course_distance_stat === 'P') || (selection.course_distance_stat === 'W'));
		}
		//Rate to win
		if (types.includes(BET_BUILDER_RATE_TO_WIN_Y)) {
			finalfilterList = finalfilterList.filter(selection => selection.win_rate == 1);
		} types;
		if (types.includes(BET_BUILDER_RATE_TO_WIN_N)) {
			finalfilterList = finalfilterList.filter(selection => selection.win_rate == 0);
		}

		//wet
		if (types.includes(BET_BUILDER_WET_WIN)) {
			finalfilterList = finalfilterList.filter(selection => selection.wet_stat === 'W');
		}
		if (types.includes(BET_BUILDER_WET_PLACE)) {
			finalfilterList = finalfilterList.filter(selection => (selection.wet_stat === 'P') || (selection.wet_stat === 'W'));
		}

		// Trainer Strike Rate (ST)
		if (types.includes(BET_BUILDER_STRIKE_RATE_16)) {
			finalfilterList = finalfilterList.filter(selection => selection.trainer_strike_rate >= 16 && selection.trainer_strike_rate <= 19.9);
		}
		if (types.includes(BET_BUILDER_STRIKE_RATE_20)) {
			finalfilterList = finalfilterList.filter(selection => selection.trainer_strike_rate >= 20);
		}

		// Jockey Strike Rate (ST)
		if (types.includes(BET_BUILDER_JOCKEY_STRIKE_RATE_16)) {
			finalfilterList = finalfilterList.filter(selection => selection.jockey_strike_rate >= 16 && selection.jockey_strike_rate <= 19.9);
		}
		if (types.includes(BET_BUILDER_JOCKEY_STRIKE_RATE_20)) {
			finalfilterList = finalfilterList.filter(selection => selection.jockey_strike_rate >= 20);
		}

		// Jockey Won on horse
		if (types.includes(BET_BUILDER_JOCKEY_WON_HORSE_Y)) {
			finalfilterList = finalfilterList.filter(selection => selection.jockey_runner_strike_rate > 0);
		}
		if (types.includes(BET_BUILDER_JOCKEY_WON_HORSE_N)) {
			finalfilterList = finalfilterList.filter(selection => selection.jockey_runner_strike_rate == 0);
		}

		// Jockey SwitchfinalfilterList
		if (types.includes(BET_BUILDER_JOCKEY_SWITCH_Y)) {
			finalfilterList = finalfilterList.filter(selection => selection.is_jockey_switch == 1);
		}
		if (types.includes(BET_BUILDER_JOCKEY_SWITCH_N)) {
			finalfilterList = finalfilterList.filter(selection => selection.is_jockey_switch == 0);
		}

		// Trainer and Jockey Strike Rate (ST)
		if (types.includes(BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_16)) {
			finalfilterList = finalfilterList.filter(selection => selection.trainer_jockey_strike_rate >= 16 && selection.trainer_jockey_strike_rate <= 19.9);
		}
		if (types.includes(BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_20)) {
			finalfilterList = finalfilterList.filter(selection => selection.trainer_jockey_strike_rate >= 20);
		}


		return finalfilterList;
	}

};

export const getMeetingsWithOpenRaces = (state) => {

	const meetings = getMeetings(state);

	const newMeetings = meetings.filter((meeting) => {

		const isOpen = meeting.races.find(race => race.status === 'selling');

		if (isOpen) {
			return meeting;
		};
	});

	return newMeetings;
};


export {
	getMeetings,
	getSelectedMeeting,
	getSelectedRace,
	betBuilderHomeRaceId,
	betPromptRaceId,
	getRaceByID,
	buildBetsForRace,
	getDenormalizedMeeting,
	searchRacesWithBets,
	getSingleBetsForRace,
	signMeetingsAndRacesWithBets,
	signRacesWithBets,
	getBetProduct,
	buildSelectionBetButtons,

	getBetbuilderSelectedMeeting,
	buildCustomizedMeetingsList,
	buildTodayMeetingSelector,

	betBuilderMeetingSelectionsCount,
	betBuilderMeetingSelectionsList,
};
