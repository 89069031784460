import { get } from '../../../common/Ajax';
import { createAction, mergeEntities, replaceEntities } from '../../../common/actions/actionHelpers';
import { normalizeSports } from '../schemas/SportSchema';
import { normalizeNextToJumpSports } from '../schemas/NextToJumpSportSchema';
import { normalizeCompetitions } from '../schemas/CompetitionSchema';
import { slugify } from '../../../legacy/core/format';
import { SPORTS_FEATURE_RACING } from '../../../pages/Sports/SportsConstants';
import { SET_VISIBLE_SPORTS_IDS, SET_VISIBLE_SPORTS_WITH_NAME, SET_SPORT_WITH_SPORT_ID } from '../../application/applicationActionTypes';

/**
 * Fetches sports, with extra data down to a given competition.
 *
 * @param competition_id
 * @returns {Function}
 */
const fetchSportsByCompetitionId = (competition_id) => (dispatch) => {
	return get('combined/sports/competition/events', {
		params: { competition_id },
	}).then((response) => {
		// dispatch(updateEntities(normalizeSports(response.data.data).entities));
		dispatch(replaceEntities(normalizeSports(response.data.data).entities));
	});
};

/**
 * Fetch a list of visible sports and dispatch to the store
 *
 * @returns {*}
 */
const fetchVisibleSports = () => (dispatch) => {
  //return [];
	return get('visible-sports').then((response) => {
		let visibleSports = [];
		const sports = response.data.data;

		Object.keys(sports).forEach((key) => {
			visibleSports.push(sports[key].id);
		});
		dispatch(setVisibleSports(visibleSports));

		dispatch(mergeVisibleSports(sports));
	});
};


const fetchVisibleSportsWithGroupBySport = () => async (dispatch) => {
  return get('sports/events/next-to-jump/markets/selections').then((response) => {
    const responseData = response.data.data;
    let data = [];
    let teams = [];

    // for(let i = 0; i < response.data.data.length; i++){
    //   data.push({
    //     event: responseData[i].events,
    //     sportId:responseData[i].base_competition.sport_id,
    //     Teams:responseData[i].events[0].teams.slice(0, 2),
    //     sport_selections:responseData[i].events[0].markets[0].selections.slice(0, 2),
    //     markets:responseData[i].events[0].markets[0],
    //     })
    // }
    dispatch(setSportWithSportId(data));
  });
};

const fetchVisibleSportsWithName = () => (dispatch) => {
  return get('visible-sports').then((response) => {
    const sports = response.data.data;

    dispatch(setVisibleSportsWithName(sports));
    return sports;
  });
};

/**
 * Fetch the current next to jump sports with events and their selections, markets etc
 */
const fetchNextToJumpSportsWithMarketsAndSelections = () => (dispatch) => {
	return get('sports/events/next-to-jump/markets/selections').then((response) => {
		//console.log(response);
		dispatch(mergeEntities(normalizeCompetitions(response.data.data).entities));
	});
};

/**
 * Fetches sports, with the base competitions of sport with given name.
 *
 * @param sportName
 * @returns {Function}
 */
const fetchSportsBySportName = (sportName) => (dispatch) => {
	return get('combined/sports/competitions', {
		params: { sport_name: slugify(sportName) },
	}).then((response) => {
		dispatch(mergeEntities(normalizeSports(response.data.data).entities));
		return response;
	});
};

/**
 * Fetches sports, with the base competitions of sport with given name.
 *
 * @returns {Function}
 */
const fetchAllSports = () => async (dispatch) => {
	return get('sports').then((response) => {
		// dispatch(mergeEntities(normalizeSports(response.data.data).entities));
	});
};

const fetchNextToJumpSports = () => (dispatch) => {
	return get('sports/events/next-to-jump').then((response) => {
		const normalizedData = normalizeNextToJumpSports(response.data.data);
		dispatch(mergeEntities(normalizedData.entities));
		return normalizedData.result;
	});
};



/**
 * Set the visible sports ids
 *
 * @param sports
 * @returns {Object}
 */
const setVisibleSports = (sports) => {
	return createAction(SET_VISIBLE_SPORTS_IDS, sports);
};

const setVisibleSportsWithName = (sports) => {
  return createAction(SET_VISIBLE_SPORTS_WITH_NAME, sports);
};

const setSportWithSportId = (sports) => {
  return createAction(SET_SPORT_WITH_SPORT_ID, sports);
};

/**
 * Function to merge sport entities fetched via 'visible-sports' endpoint,
 * which is still being accessed via Marionette code.
 *
 * @param {Array} sports
 */
const mergeVisibleSports = (sports) => {
	const normalizedSports = normalizeSports(sports);
	return mergeEntities(normalizedSports.entities);
};

/**
 * Return a list of featured races from the sports list
 *
 * @param sports
 * @returns {*[]}
 */
const getFeaturedRacesFromSports = (sports) => sports.filter((sport) => sport.name === SPORTS_FEATURE_RACING);

export {
	fetchAllSports,
	fetchSportsByCompetitionId,
	fetchVisibleSports,
	fetchSportsBySportName,
	fetchNextToJumpSportsWithMarketsAndSelections,
	fetchNextToJumpSports,
	mergeVisibleSports,
	getFeaturedRacesFromSports,
	setVisibleSports,
  fetchVisibleSportsWithName,
  setVisibleSportsWithName,
  fetchVisibleSportsWithGroupBySport,
};
