// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { typography,  Text } from '@tbh/ui-kit';

const stepAnimation = (step) => {
	switch (step) {
		case 1:
			return css`
				animation: text-fading 0.2s 1;
				animation-delay: 1.6s;
			`;
		case 2:
			return css`
				opacity: 0;
				animation: text-opacity 0.2s 1, text-fading 0.2s 1;
				animation-delay: 1.6s, 3.2s;
			`;
		case 3:
			return css`
				opacity: 0;
				animation: text-opacity 0.2s 1;
				animation-delay: 3.2s;
			`;
	}
};

const StyledRegistrationConfirmation__Step = styled(Text)(
	(props) => css`
		label: RegistrationConfirmation__Step;

		@keyframes ellipsis {
			from {
				opacity: 1;
			}
			50% {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}

		@keyframes text-opacity {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}

		@keyframes text-fading {
			from {
				color: ${typography(props).base_color};
			}
			to {
				color: ${typography(props).color_2};
			}
		}

		margin-top: 0.5rem;
		${stepAnimation(props.step)};
		animation-fill-mode: forwards;
	`,
);

const StyledRegistrationConfirmation__Ellipsis = styled('span')(
	(props) => css`
		label: RegistrationConfirmation__Ellipsis;

		animation: ellipsis 0.8s 2;
		animation-delay: ${props.delay}s;
	`,
);

const RegistrationConfirmation = ({ className, t }) => {
	const componentClass = cx({
		[className]: className,
	});

	return (
		<div className={componentClass}>
			<Text size="2" centered>{t('RegistrationConfirmation__Heading')}</Text >

			<StyledRegistrationConfirmation__Step step={1} align="center" paragraph>
				{t('RegistrationConfirmation__Step1')}
				<StyledRegistrationConfirmation__Ellipsis delay={0}>.</StyledRegistrationConfirmation__Ellipsis>
				<StyledRegistrationConfirmation__Ellipsis delay={0.2}>.</StyledRegistrationConfirmation__Ellipsis>
				<StyledRegistrationConfirmation__Ellipsis delay={0.4}>.</StyledRegistrationConfirmation__Ellipsis>
			</StyledRegistrationConfirmation__Step>

			<StyledRegistrationConfirmation__Step step={2} align="center" paragraph>
				{t('RegistrationConfirmation__Step2')}
				<StyledRegistrationConfirmation__Ellipsis delay={1.6}>.</StyledRegistrationConfirmation__Ellipsis>
				<StyledRegistrationConfirmation__Ellipsis delay={1.8}>.</StyledRegistrationConfirmation__Ellipsis>
				<StyledRegistrationConfirmation__Ellipsis delay={2}>.</StyledRegistrationConfirmation__Ellipsis>
			</StyledRegistrationConfirmation__Step>

			<StyledRegistrationConfirmation__Step step={3} align="center">
				{t('RegistrationConfirmation__Step3')}
				<StyledRegistrationConfirmation__Ellipsis delay={3.2}>.</StyledRegistrationConfirmation__Ellipsis>
				<StyledRegistrationConfirmation__Ellipsis delay={3.4}>.</StyledRegistrationConfirmation__Ellipsis>
				<StyledRegistrationConfirmation__Ellipsis delay={3.6}>.</StyledRegistrationConfirmation__Ellipsis>
			</StyledRegistrationConfirmation__Step>
		</div>
	);
};

RegistrationConfirmation.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

RegistrationConfirmation.defaultProps = {
	className: null,
};

export default withNamespaces()(RegistrationConfirmation);
