// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces, Trans } from 'react-i18next';

import { spacings, ui, Button, Checkbox, Link, Text } from '@tbh/ui-kit';

// Components
import CreditCardForm from '../../../../ui-components/Forms/CreditCardForm/CreditCardForm';
import VerifyCardExplanation from '../../../Deposit/VerifyCardExplanation/VerifyCardExplanation';
import CurrencyInput from '../../../Application/Currency/CurrencyInput/CurrencyInput';
import BonusBetsList from './BonusBetsList/BonusBetsList';
import CountdownPanel from './CountdownPanel/CountdownPanel';
import PromoAlert from './PromoAlert/PromoAlert';
import YouQualifyAlert from './YouQualifyAlert/YouQualifyAlert';

// Functions
import { centsAsDollars } from '../../../../../legacy/core/format';

const StyledFirstDeposit__Alert = styled(CountdownPanel)(
	(props) => css`
		label: FirstDeposit__Alert;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const StyledFirstDeposit__CreditCardFormWrapper = styled('div')(
	(props) => css`
		label: FirstDeposit__CreditCardFormWrapper;

		background-color: ${ui(props).color_3};
		padding: ${spacings(props).cozy}px 0;
		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const StyledFirstDeposit__CustomBonusWrapper = styled('div')(
	(props) => css`
		label: FirstDeposit__CustomBonusWrapper;

		display: flex;
		justify-content: space-between;
		padding: 0 ${spacings(props).cozy}px;
	`,
);

class FirstDeposit extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Users' first name */
		first_name: PropTypes.string.isRequired,

		/** User's country */
		country: PropTypes.string.isRequired,

		/** Array of bonus' */
		bonus_bets: PropTypes.arrayOf(
			PropTypes.shape({
				/** The amount needed for the bonus bet */
				value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

				/** The bonus bet amount */
				bonus: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

				/** The bonus promotion code */
				promoCode: PropTypes.string,

				/** Text if the bonus was successfully applied */
				successText: PropTypes.string,

				/** Link if the bonus was successfully applied */
				successLink: PropTypes.string,
			}),
		).isRequired,

		/** Clears the error message */
		clearErrorMessage: PropTypes.func.isRequired,

		/** Skips the bonus offer and closes the page */
		skipBonus: PropTypes.func.isRequired,

		/** Launches Intercom chat */
		launchChat: PropTypes.func.isRequired,

		/** Action for handling the deposit */
		handleDeposit: PropTypes.func.isRequired,

		/** The container can handle GA tracking with this function when the user selects a bonus option */
		handleBonusSelection: PropTypes.func.isRequired,

		/** Action for handling deposit status' */
		setConfirmationProps: PropTypes.func.isRequired,

		/** Extra classes */
		className: PropTypes.string,

		/** Custom deposit amount */
		customValue: PropTypes.string,

		/** If the deposit action is loading */
		loading: PropTypes.bool,
		promotion: PropTypes.shape({
			/** The amount needed for the bonus bet */
			value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

			/** The bonus bet amount */
			bonus: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

			/** The bonus promotion code */
			promoCode: PropTypes.string,

			/** Text if the bonus was successfully applied */
			successText: PropTypes.string,

			/** Link if the bonus was successfully applied */
			successLink: PropTypes.string,
		}),

		/** Link for the terms and conditions text */
		termsAndConditionsLink: PropTypes.string,

		/** Brand name */
		brandName: PropTypes.string,
	};

	static defaultProps = {
		className: null,
		customValue: '',
		loading: false,
		promotion: null,
		termsAndConditionsLink: null,
		brandName: null,
	};

	state = {
		selected_bonus: null,
		creditCardName: '',
		creditCardNameValid: false,
		creditCardNumber: '',
		creditCardNumberValid: false,
		creditCardExpiry: '',
		creditCardExpiryValid: false,
		creditCardSecurityCode: '',
		creditCardSecurityCodeValid: false,
		customValue: '',
		customValueError: null,
		showVerifyCardInfo: false,
		depositAmount: '',
		accept_terms: false,
	};

	componentDidMount = () => {
		this.timer = window.setInterval(this.tick, 1000);
		this.tick();
	};

	componentWillUnmount = () => {
		window.clearInterval(this.timer);
	};

	/**
	 * update the timer by ticking down 1 second
	 */
	tick = () => {
		let time_left = new Date(this.state.elapsed) - Date.now();

		if (time_left >= 120000) {
			this.setState({
				elapsed: this.state.elapsed - 1,
				borderType: 'important',
			});
		} else if (time_left >= 0 && time_left < 120000) {
			this.setState({
				elapsed: this.state.elapsed - 1,
				borderType: 'danger',
			});
		} else {
			// restart the timer when it gets to 0
			this.setState({
				elapsed: Date.now() + 300000,
			});
		}
	};

	/**
	 * Set the bonus when clicking on a radio button
	 *
	 * @param event
	 */
	handleBonusSelection = (event) => {
		let value = event.target.value;

		this.setState({
			selected_bonus: value,
			customValue: '',
		});

		this.props.handleBonusSelection(value);

		if (value === 'Other Amount') {
			this.props.launchChat();
		}
	};

	/**
	 * Handle changes in the inputs
	 *
	 * @param name
	 * @param value
	 * @param valid
	 */
	handleFormChange = (name, value, valid) => {
		this.setState({
			[name]: value,
			[`${name}Valid`]: valid,
			customValueError: name === 'customValue' && value > 10000 ? this.props.t('FirstDeposit__AmountError') : null,
		});
	};

	/**
	 * Sends the credit card information and chosen bonus to make the deposit
	 */
	handleDeposit = () => {
		let [expiryMonth, expiryYear] = this.state.creditCardExpiry.split('/');
		let number = this.state.creditCardNumber.split('-');
		number = number[0] + number[1] + number[2] + number[3];

		let name = this.state.creditCardName.split(' ');
		let firstName = name[0];
		name.splice(0, 1);
		let lastName = name;
		lastName = lastName.join(' ');

		let current_bonus, amount, bonus, promoCode, successText, successLink;

		if (this.state.selected_bonus === 'promotion') {
			amount = this.state.depositAmount;
			promoCode = this.props.promotion.promoCode;
			successText = this.props.promotion.successText;
			successLink = this.props.promotion.successLink;
			bonus = this.props.promotion.bonus;
		} else {
			current_bonus = this.props.bonus_bets.findIndex((x) => x.value === this.state.selected_bonus);
			current_bonus = this.props.bonus_bets[current_bonus];
			amount = current_bonus.value;
			bonus = current_bonus.bonus;
			promoCode = current_bonus.promoCode;
			successText = current_bonus.successText;
			successLink = current_bonus.successLink;
		}

		this.props.handleDeposit({
			firstName: firstName,
			lastName: lastName,
			number: number,
			expiryMonth: expiryMonth,
			expiryYear: expiryYear,
			cvv: this.state.creditCardSecurityCode,
			promoCode: promoCode ? promoCode : 'FIRSTDEPOSIT',
			amount:
				this.state.selected_bonus === 'Custom Amount' ? parseFloat(this.state.customValue) : parseFloat(amount) * 100,
			bonus: bonus,
		});

		this.props.setConfirmationProps(successText, successLink);
	};

	handleCheckbox = () => {
		this.setState({
			accept_terms: !this.state.accept_terms,
		});
	};

	goBackToBonuses = () => {
		this.setState({
			selected_bonus: null,
			customValueError: null,
		});
		this.props.clearErrorMessage();
	};

	onToggleVerifyCardInfo = () => {
		this.setState({
			showVerifyCardInfo: !this.state.showVerifyCardInfo,
		});
	};

	renderFootNote = () => {
		if (this.props.country === 'United Kingdom') {
			return (
				<Text size="-1" align="center">
					<Checkbox
						name="accept_terms"
						value="accept_terms"
						action={this.handleCheckbox}
						checked={this.state.accept_terms}
						pushRight="1"
						inline
					/>
					<Trans i18nKey="FirstDeposit__TermsAndConditions">
						Accept{' '}
						<Link href={this.props.termsAndConditionsLink} type="secondary" size="-1" underline>
							terms and conditions
						</Link>
					</Trans>
				</Text>
			);
		} else {
			return (
				<Text size="-2" align="center">
					{this.props.t('FirstDeposit__BillingStatements', { brandName: this.props.brandName })}
				</Text>
			);
		}
	};

	renderPanel = () => {
		if (this.props.promotion) {
			if (this.state.selected_bonus && this.state.selected_bonus !== 'promotion') {
				return <StyledFirstDeposit__Alert />;
			} else {
				if (
					this.state.selected_bonus === 'promotion' &&
					(this.state.creditCardName ||
						this.state.creditCardNumber ||
						this.state.creditCardExpiry ||
						this.state.creditCardSecurityCode ||
						this.state.depositAmount)
				) {
					return <YouQualifyAlert />;
				} else {
					return (
						<PromoAlert
							handleBonusSelection={this.handleBonusSelection}
							selected_bonus={this.state.selected_bonus}
							promotion={this.props.promotion}
						/>
					);
				}
			}
		} else {
			return <StyledFirstDeposit__Alert />;
		}
	};

	render() {
		const { className, bonus_bets, first_name, loading, promotion, skipBonus, t } = this.props;

		const {
			accept_terms,
			creditCardExpiry,
			creditCardExpiryValid,
			creditCardName,
			creditCardNameValid,
			creditCardNumber,
			creditCardNumberValid,
			creditCardSecurityCode,
			creditCardSecurityCodeValid,
			customValue,
			customValueError,
			depositAmount,
			selected_bonus,
			showVerifyCardInfo,
			brandName,
		} = this.state;

		let current_bonus = bonus_bets.findIndex((x) => x.value === selected_bonus);
		current_bonus = bonus_bets[current_bonus];

		let bonus_text, button_text, formIsValid;

		if (!selected_bonus || selected_bonus === 'Other Amount') {
			bonus_text = t('FirstDeposit__Spend');
		} else {
			if (selected_bonus !== 'promotion') {
				bonus_text =
					selected_bonus === 'Custom Amount'
						? t('FirstDeposit__DepositingCustom')
						: t('FirstDeposit__Depositing') +
						  ' ' +
						  (parseInt(current_bonus.value.charAt(0)) ? '$' : '') +
						  current_bonus.value;
				button_text =
					selected_bonus === 'Custom Amount'
						? t('Deposit')
						: `${t('Deposit')} $${current_bonus.value} + ${t('Get')} ` +
						  (parseInt(current_bonus.bonus.charAt(0)) ? '$' : '') +
						  current_bonus.bonus +
						  ' ' +
						  t('BonusBet');
				formIsValid =
					creditCardNameValid && creditCardNumberValid && creditCardExpiryValid && creditCardSecurityCodeValid;
			} else {
				button_text = t('FirstDeposit__MakeADeposit');
				formIsValid =
					creditCardNameValid &&
					creditCardNumberValid &&
					creditCardExpiryValid &&
					creditCardSecurityCodeValid &&
					depositAmount;
			}
		}
		// Deposit button is disabled if:
		// 1. form is not valid
		// 2. if country is UK and form is valid and terms and conditions is not checked
		let disabled = !formIsValid || (this.props.country === 'United Kingdom' && !accept_terms);

		if (selected_bonus === 'Custom Amount') {
			disabled = disabled || !customValue || customValue > 10000;
		}

		return (
			<div className={cx({ [className]: className })}>
				{showVerifyCardInfo ? (
					<VerifyCardExplanation closeVerifyCardExplanation={this.onToggleVerifyCardInfo} brandName={brandName} />
				) : (
					<div>
						<Text align="center" paragraph>
							<Link action={skipBonus} size="-2" underline>
								{t('FirstDeposit__Skip')}
							</Link>
						</Text>

						<Text size="1" paragraph strong>
							{t('FirstDeposit__Glad', { first_name })}
						</Text>

						{this.renderPanel()}

						{bonus_text && <Text strong>{bonus_text}</Text>}

						{selected_bonus !== 'promotion' && (
							<BonusBetsList
								bonus_bets={bonus_bets}
								current_bonus={current_bonus}
								handleBonusSelection={this.handleBonusSelection}
								selected_bonus={selected_bonus}
							/>
						)}

						{selected_bonus &&
							selected_bonus !== 'Other Amount' && (
								<div>
									<Text align="center" paragraph>
										<Link action={this.goBackToBonuses} size="-2" underline>
											{t('FirstDeposit__PreferAnother')}
										</Link>
									</Text>

									<StyledFirstDeposit__CreditCardFormWrapper>
										<CreditCardForm
											onChange={this.handleFormChange}
											creditCardName={creditCardName}
											creditCardNumber={creditCardNumber}
											creditCardExpiry={creditCardExpiry}
											promoCode={
												selected_bonus === 'promotion'
													? promotion.promoCode
													: current_bonus.promoCode
														? current_bonus.promoCode
														: 'FIRSTDEPOSIT'
											}
											creditCardSecurityCode={creditCardSecurityCode}
											depositAmount={selected_bonus === 'promotion' ? depositAmount : null}
											onOpenVerifyInfo={this.onToggleVerifyCardInfo}
										/>

										{selected_bonus === 'Custom Amount' && (
											<StyledFirstDeposit__CustomBonusWrapper>
												<CurrencyInput
													className={css`
														flex: 0 1 50%;
													`}
													value={customValue}
													name="customValue"
													label={t('CustomAmount')}
													onChange={this.handleFormChange}
													error={customValueError}
													margin="cozy"
													horizontalMargin="cozy"
												/>
												<Text
													className={css`
														align-self: center;
														flex: 0 1 50%;
													`}
													type="success"
													size="-1"
													align="right"
													strong
												>
													{customValue && customValue <= 50000
														? `+${centsAsDollars(customValue)} ${t('BonusBet')}`
														: ''}
												</Text>
											</StyledFirstDeposit__CustomBonusWrapper>
										)}

										{this.renderFootNote()}
									</StyledFirstDeposit__CreditCardFormWrapper>

									<Button
										className={css`
											text-overflow: ellipsis;
											overflow: hidden;
										`}
										type="primary"
										action={this.handleDeposit}
										disabled={disabled}
										loading={loading}
										bold
										block
									>
										{button_text}
									</Button>
								</div>
							)}
					</div>
				)}
			</div>
		);
	}
}

export default withNamespaces()(FirstDeposit);
