// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { spacings } from '@tbh/ui-kit';
import { withNamespaces } from 'react-i18next';

import Format from '../../../../legacy/core/format';

// Components
import { Button, ButtonGroup, Link, LoadingMask, Text, PlotElements } from '@tbh/ui-kit';
import ModalFooter from '../../Application/ModalFooter/ModalFooter';

// Context
import AclContext from '../../../../contexts/AclContext';

const StyledCancelWithdrawalConfirmation__Message = styled('div')(
	(props) => css`
		padding: ${spacings(props).comfortable}px ${spacings(props).comfortable}px 0 ${spacings(props).comfortable}px;
	`,
);

const StyledCancelWithdrawalConfirmation__ActionsGroup = styled(PlotElements)(
	(props) => css`
		padding: ${spacings(props).comfortable}px;
	`,
);

const CancelWithdrawalConfirmation = (props) => {
	let { t, amount, isError, isLoading, isConfirmed } = props;
	amount = Format.centsAsDollars(amount);

	const handleClickYes = (e) => {
		e.preventDefault();
		props.onPromptConfirm();
	};

	const handlePromptClose = (e) => {
		e.preventDefault();
		props.onPromptClose();
	};

	if (isError)
		return (
			<div>
				<StyledCancelWithdrawalConfirmation__Message>
					<Text align="center" paragraph>
						{t('CancelWithdrawalConfirmation__UnableToProcess')}
					</Text>
					<Text align="center" paragraph>
						{t('CancelWithdrawalConfirmation__TryAgain')}
					</Text>
					<AclContext.Consumer>
						{(acl) =>
							acl.brandDetails && acl.brandDetails.phone ? (
								<Text align="center" paragraph>
									Call{' '}
									<Link href={`tel:${acl.brandDetails.phone}`} strong>
										{acl.brandDetails.phone}
									</Link>
								</Text>
							) : null
						}
					</AclContext.Consumer>
				</StyledCancelWithdrawalConfirmation__Message>
				<StyledCancelWithdrawalConfirmation__ActionsGroup align="middle">
					<Button type="primary" action={handlePromptClose}>
						{t('ContinueBetting')}
					</Button>
				</StyledCancelWithdrawalConfirmation__ActionsGroup>
				<ModalFooter />
			</div>
		);

	return (
		<div>
			<div>
				<LoadingMask loading={isLoading} />

				<StyledCancelWithdrawalConfirmation__Message>
					<Text align="center" paragraph>
						{`${t('CancelWithdrawalConfirmation__ThisRequest')} `}
						<Text tag="span" strong>
							{amount}
						</Text>
						{isConfirmed ? ` ${t('CancelWithdrawalConfirmation__Success')}` : ` ${t('CancelWithdrawalConfirmation__CanBe')}`}
					</Text>
					<Text align="center" paragraph>
						{`${t('CancelWithdrawalConfirmation__FundsWillAppear')} `}
						<AclContext.Consumer>
							{(acl) => (acl.brandDetails && acl.brandDetails.name ? acl.brandDetails.name : '')}
						</AclContext.Consumer>{' '}
						{`${t('account')} `}
						<Text tag="span" strong lower>
							{t('Immediately')}
						</Text>.
					</Text>
				</StyledCancelWithdrawalConfirmation__Message>

				<StyledCancelWithdrawalConfirmation__ActionsGroup align="middle">
					{isConfirmed ? (
						<Button type={Button.types.PRIMARY} disabled={isLoading} action={handlePromptClose} block>
							{t('ContinueBetting')}
						</Button>
					) : (
						<ButtonGroup center>
							<Button type={Button.types.PRIMARY} action={handleClickYes} disabled={isLoading} flex>
								{t('YesPlease')}
							</Button>
							<Button type={Button.types.GHOST} action={handlePromptClose} flex>
								{t('No')}
							</Button>
						</ButtonGroup>
					)}
				</StyledCancelWithdrawalConfirmation__ActionsGroup>
			</div>
			<ModalFooter />
		</div>
	);
};

CancelWithdrawalConfirmation.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	amount: PropTypes.number.isRequired,
	onPromptClose: PropTypes.func.isRequired,
	onPromptConfirm: PropTypes.func,
	isLoading: PropTypes.bool,
	isConfirmed: PropTypes.bool,
	isError: PropTypes.bool,
};

CancelWithdrawalConfirmation.defaultProps = {
	isLoading: false,
	isConfirmed: false,
	onPromptConfirm: null,
	isError: false,
};

export default withNamespaces()(CancelWithdrawalConfirmation);
