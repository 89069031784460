import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SizeMe } from 'react-sizeme';
import GRSNextSportsList from '../../../components/features/Sports/GRSNextSportsList/GRSNextSportsList';
import { selectEvent } from '../../../store/sportsHome/sportsHomeActions';
import { trackGaEvent } from '../../../store/trackingPixels/trackingActions';
import { getGRSNextJumpSports } from '../../../pages/SportNextJump/SportNextJumpHome/SportNextJumpHomeSelectors';
import { Ticker } from '@tbh/ui-kit';
import { trackNextToJump } from '../../../store/grsSportsHome/grsSportsTrackingActions';


class GRSNextToJumpSportsContainer extends React.Component {
	static propTypes = {
		items: PropTypes.array.isRequired,
		onItemClick: PropTypes.func.isRequired,
		isGRSSportHome: PropTypes.bool,
		trackOnFilterClick: PropTypes.func,
		className: PropTypes.string,
		hideSportNextJumpFilter: PropTypes.bool,
	};
	static defaultProps = {
		isGRSSportHome: false,
		trackOnFilterClick: () => undefined,
		className: '',
		hideSportNextJumpFilter: false,
	};

	/**
	 * Tracking event used the carousel filter
	 * @param {string} sportIds - sport id
	 */
	trackOnFilterClick = (sportIds) => {
		this.props.trackOnFilterClick(sportIds, this.props.isGRSSportHome);
	};

	/**
	 * Group by sport id
	 * @param array
	 */
	groupFilterSportBySportId = (array) => {
	
		Array.prototype.groupBy = function(prop) {
			return this.reduce(function(groups, item) {
				const val = item[prop];
				groups[val] = groups[val] || [];
				const mappedItem = `${item.sport_id}`;
				groups[val].push({
					... item,
					mappedItem
				});
				return groups;
			}, []);
		};
	
		const sports = array.groupBy('sport_id');
		return sports;
	};

	/**
	 * get all sport Id
	 */
	getSportIds = () => {
		const sportsFilterGroup = this.groupFilterSportBySportId(this.props.items);

		const sportIds = sportsFilterGroup.map(
			(group, key) => {
				return key;
			}
		);

		return sportIds;

	};



	render(){
		const sportsFilterGroup = this.groupFilterSportBySportId(this.props.items);

		const sportIds =  this.getSportIds();
		
		return(
			<React.Fragment>
				<SizeMe noPlaceholder>
					{({ size }) => (
						<Ticker every={60000}>
							<GRSNextSportsList
								className={this.props.className}
								items={this.props.items}
								onItemClick={this.handleOnItemClick}
								trackOnFilterClick={this.trackOnFilterClick}
								hideSportNextJumpFilter={this.props.hideSportNextJumpFilter}
								size={size}
								sportsFilterGroup= {sportsFilterGroup}
								sportIds={sportIds}
							/>

						</Ticker>
					)}	
				</SizeMe>
			</React.Fragment>
		);
	};
}

const mapStateToProps = (state, ownProps) => ({
	className: ownProps.className,
	items: getGRSNextJumpSports(state),
});

const mapDispatchToProps = (dispatch) => ({
	onItemClick: (competitionId, eventId) => {
		dispatch(trackGaEvent('Sports', 'Filter'));
		return dispatch(selectEvent(competitionId, eventId));
	},
	trackOnFilterClick: (type, isGRSSportHome) => dispatch(trackNextToJump('Filter', type, isGRSSportHome)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(GRSNextToJumpSportsContainer);
