/**
 * Find the closest number in an array
 *
 * @param goal
 * @param list
 * @param roundDown
 * @returns {*}
 */
export const closestNumberInList = (goal, list = [], roundDown = false) => {
	return list.reduce((prev, curr) => {
		if (roundDown) {
			return Math.abs(curr - goal) < Math.abs(prev - goal) ? (curr <= goal ? curr : prev) : prev;
		} else {
			return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
		}
	});
};

/**
 * Delimit an array and optionally provide a different character for the last item
 *
 * @param list
 * @param delimiter
 * @param lastChar
 * @returns {string}
 */
export const delimitArray = (list = [], delimiter = ', ', lastChar = ' & ') => {
	return [
		list.slice(0, -1).join(delimiter), // Take all but the last element and joins them together
		list.slice(-1)[0], // The last element
	].join(list.length < 2 ? '' : lastChar); // Joins the string and the last element with the lastChar provided if there are at least two elements.
};
