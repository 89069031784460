import { post } from '../../common/Ajax';
import { mergeEntities } from '../../common/actions/actionHelpers';
import { normalizeUsers } from '../entities/schemas/UserSchema';

export const greenidValid = (number, validateType) => (dispatch, getState) => {
  const state = getState();
  const useremail = state.entities.users[state.application.authenticatedUser].email;
  const params = {
    L_type: validateType,
    L_number: number,
    user_email: useremail,
  };
  return post('postElctoFrom', params)
    .then((response) => {
     // dispatch(trackGaEvent('user', 'password:change'));
      dispatch(mergeEntities(normalizeUsers(response.data.data).entities));
      return response;
    })
    .catch((error) => {
      throw error.response.data.errors;
    });
};
global.greenidValid = greenidValid;
