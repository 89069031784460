import { denormalize, normalize, schema } from 'normalizr';

// Schemas
const withdrawalSchema = new schema.Entity('withdrawals');
const withdrawalsSchema = new schema.Array(withdrawalSchema);

/*
 * Denormalize withdrawal entities back to a structure similar to the API response.
 *
 * @param entities
 * @returns {Array}
 */
const denormalizeWithdrawals = (entities, keys = null) => {
	return denormalize(keys || Object.keys(entities.withdrawals), withdrawalsSchema, entities);
};

/**
 * Normalize the withdrawals structure to suit our application.
 *
 * @param response
 * @returns {Object}
 */
const normalizeWithdrawals = (response = []) => {
	return normalize(response, withdrawalsSchema);
};

export { withdrawalSchema, withdrawalsSchema, denormalizeWithdrawals, normalizeWithdrawals };
