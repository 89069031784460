import strategies from 'normalizr-strategies';
import { schema, normalize, denormalize } from 'normalizr';
import { sortKeys } from './Entities';

// Defines user schema
const user = new schema.Entity(
	'users',
	{},
	{
		processStrategy: strategies(sortKeys(['racing_rolls', 'sports_rolls'], (a, b) => a.price - b.price)),
	},
);
export const usersSchema = new schema.Array(user);

/**
 * Normalizes entities.
 *
 * @param response
 * @returns {Object}
 */
export const normalizeUsers = (response) => {
	return normalize(response, usersSchema);
};

/**
 * Denormalizes entities.
 *
 * @param entities
 * @param {Array} keys
 * @returns {Array}
 */
export const denormalizeUsers = (entities, keys) => {
	return denormalize(keys || Object.keys(entities.user), usersSchema, entities);
};
