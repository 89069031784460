
import styled, { css } from 'react-emotion';
import { application,  spacings,media } from '@tbh/ui-kit';
import {List } from '@tbh/ui-kit';


export const StyledUpRaceSelectionsList = styled('div')`
    lable: StyledGRSNtjRaceSelectionsList;
	clear: both; // this is to clear the floated 'all form' toggle button
	padding: 8px 4px 4px 4px;
	&:after {
		content: '';
		display: table;
		clear: both;
	}
`;

export const StyledUpRaceSelectionsList__RaceSelectionsListItems = styled(List)`
	${(props) =>
		css`
			label: UpRaceSelectionsList_RaceSelectionsListItems;

			margin-bottom: ${spacings(props).cozy}px;
		`};
`;

export const StyledUpRaceSelectionsList__HeaderFlucs = styled('div')`
	${(props) =>
		css`
			label: UpRaceSelectionsList__HeaderFlucs;

			display: none;
			text-align: center;
			text-transform: uppercase;
			flex: 0 0 ${application(props).racing.flucs_width}px;
			max-width: ${application(props).racing.flucs_width}px;
		`};

	${media(
		css`
			display: block;
		`,
		660,
	)};
`;