import {
	USER_ACCOUNT_V2_BASE_URL,
	USER_ACCOUNT_OVERVIEW_URL,
	USER_ACCOUNT_DEPOSIT_URL,
	USER_ACCOUNT_SETTINGS_URL,
	USER_ACCOUNT_WITHDRAW_URL,
	USER_ACCOUNT_TRANSACTIONS_URL,
	USER_ACCOUNT_BONUS_BET_TRANSACTION_URL,
	USER_ACCOUNT_MENU_OVERVIEW,
	USER_ACCOUNT_MENU_DEPOSIT,
	USER_ACCOUNT_MENU_TRANSACTIONS,
	USER_ACCOUNT_MENU_BONUS_BET,
	// USER_ACCOUNT_DEPOSIT_LIMIT_URL,
	// USER_ACCOUNT__DEFAULT_BET_AMOUNTS,
	// USER_ACCOUNT_TAKE_A_BREAK_URL,
	// USER_ACCOUNT_SELF_EXCLUSION_URL,
	// USER_ACCOUNT_LOSS_LIMITS_URL,
	// USER_ACCOUNT_SPEND_LIMITS_URL,
	// USER_ACCOUNT_REALITY_CHECK_URL,
	USER_ACCOUNT_RESPONSIBLE_GAMBLING_URL,
	USER_ACCOUNT_RESPONSIBLE_GAMBLING,
	USER_ACCOUNT_RNS_ACCOUNT_URL,
} from './UserAccountPageConstants';

export default [
	{
		id: USER_ACCOUNT_OVERVIEW_URL,
		title: USER_ACCOUNT_MENU_OVERVIEW,
		route: `/${USER_ACCOUNT_V2_BASE_URL}/${USER_ACCOUNT_OVERVIEW_URL}`,
		trackingTitle: USER_ACCOUNT_MENU_OVERVIEW,
	},
	{
		id: USER_ACCOUNT_SETTINGS_URL,
		title: 'Settings',
		route: `/${USER_ACCOUNT_V2_BASE_URL}/${USER_ACCOUNT_SETTINGS_URL}`,
		trackingTitle: 'Settings',
	},
	{
		id: USER_ACCOUNT_TRANSACTIONS_URL,
		title: USER_ACCOUNT_MENU_TRANSACTIONS,
		route: `/${USER_ACCOUNT_V2_BASE_URL}/${USER_ACCOUNT_TRANSACTIONS_URL}`,
		defaultUrlFragments: 'all',
		trackingTitle: USER_ACCOUNT_MENU_TRANSACTIONS,
	},
	{
		id: USER_ACCOUNT_BONUS_BET_TRANSACTION_URL,
		title: USER_ACCOUNT_MENU_BONUS_BET,
		route: `/${USER_ACCOUNT_V2_BASE_URL}/${USER_ACCOUNT_BONUS_BET_TRANSACTION_URL}`,
		defaultUrlFragments: 'all',
		trackingTitle: USER_ACCOUNT_MENU_BONUS_BET,
	},
	{
		id: USER_ACCOUNT_DEPOSIT_URL,
		title: USER_ACCOUNT_MENU_DEPOSIT,
		route: `/${USER_ACCOUNT_V2_BASE_URL}/${USER_ACCOUNT_DEPOSIT_URL}`,
		trackingTitle: USER_ACCOUNT_MENU_DEPOSIT,
	},
	// {
	// 	id: USER_ACCOUNT_VERIFICATION,
	// 	title: 'ID verification',
	// 	route: `/${USER_ACCOUNT_V2_BASE_URL}/${USER_ACCOUNT_VERIFICATION_URL}`,
	// 	trackingTitle: 'Verification',
	// 	// items: Object.keys(USER_ACCOUNT_VERIFICATION_MENU_OPTIONS).map((key) => {
	// 	// 	const { url } = USER_ACCOUNT_VERIFICATION_MENU_OPTIONS[key];
	// 	// 	return {
	// 	// 		id: key,
	// 	// 		route: `/${USER_ACCOUNT_V2_BASE_URL}/${USER_ACCOUNT_VERIFICATION_URL}/${url}`,
	// 	// 		trackingTitle: key,
	// 	// 		title: key,
	// 	// 	};
	// 	// }),
	// },
	{
		id: USER_ACCOUNT_WITHDRAW_URL,
		title: 'Withdraw',
		route: `/${USER_ACCOUNT_V2_BASE_URL}/${USER_ACCOUNT_WITHDRAW_URL}`,
		trackingTitle: 'Withdraw',
	},
	{
		id: USER_ACCOUNT_RESPONSIBLE_GAMBLING_URL,
		title: USER_ACCOUNT_RESPONSIBLE_GAMBLING,
		route: `/${USER_ACCOUNT_V2_BASE_URL}/${USER_ACCOUNT_RESPONSIBLE_GAMBLING_URL}`,
		trackingTitle: USER_ACCOUNT_RESPONSIBLE_GAMBLING,
	},
	{
		id: USER_ACCOUNT_RNS_ACCOUNT_URL,
		title: 'RS Account',
		route: `/${USER_ACCOUNT_V2_BASE_URL}/${USER_ACCOUNT_RNS_ACCOUNT_URL}`,
		trackingTitle: USER_ACCOUNT_RNS_ACCOUNT_URL,
	},
];
