import { post } from '../../common/Ajax';


/**
 * Poli Payment Verification
 * @HW make by Paulo
 */
export const poliPaymentVerification = (token) => (dispatch) => {

    return post(`/poli-deposit?token=${token} `,)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log('error',error);
            document.Sentry && document.Sentry.captureException(error);
            throw error;
        });
};
