import React from 'react';
import { Button, ButtonGroup, CONSTRAINT_EMAIL, Icon, Input, InputGroup, Text } from '@tbh/ui-kit';
import { withNamespaces } from 'react-i18next';
import { css } from 'emotion';
import { EVENT_KEY_ENTER } from '../../../../../common/constants/Application';

class EmailAuthentication extends React.Component {
	state = {
		code_1: '',
		code_2: '',
		code_3: '',
		code_4: '',
		code_1_valid: true,
		code_2_valid: true,
		code_3_valid: true,
		code_4_valid: true,
		componentInFocus: 'code_1',
		checked: false,
		local_loading: false,
		error: null,
	};

	componentDidMount() {
		if (this.props.emailWasSent) {
			this.inputs.code_1.focus();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.componentInFocus !== this.state.componentInFocus) {
			this.inputs[this.state.componentInFocus].focus();
		}
	}

	/**
	 * Verifies the mobile number
	 */
	verifyEmail = () => {
		this.props.verifyEmail(this.state.code_1 + this.state.code_2 + this.state.code_3 + this.state.code_4).then((response) => { 
			this.local_loading = false;
		});
	};

	inputs = {};
	local_loading = false;

	/**
	 * Handle changes in the inputs
	 *
	 * @param name
	 * @param value
	 */
	handleChange = (name, value) => {
		let codes = ['code_1', 'code_2', 'code_3', 'code_4'];

		if (value.length < 1) {
			this.setState({
				[name]: '',
				[`${name}_valid`]: false,
				error: this.props.t('MobileVerificationForm__CodeError'),
			});

			if (name !== 'code_1') {
				let index = codes.indexOf(name);

				this.setState({
					componentInFocus: codes[index - 1],
				});
				// erase the next inputs;

				for (let i = index; i < codes.length; i++) {
					this.setState({
						[codes[i]]: '',
						[`${codes[i]}_valid`]: false,
					});
				}
			}
			return;
		}

		if (value == ' ' || isNaN(value)) {
			this.setState({
				[name]: '',
				[`${name}_valid`]: false,
				error: this.props.t('MobileVerificationForm__CodeError'),
				componentInFocus: name,
			});
			// Update input so that it doesn't show the space
			this.inputs[name].blur();

			setTimeout(
				function() {
					this.inputs[name].focus();
				}.bind(this),
				200,
			);

			return;
		}

		let c = codes.filter((code) => code != name);
		let error =
			c.filter((code) => {
				return !this.state[`${code}_valid`];
			}).length > 0;

		this.setState({
			[name]: value,
			[`${name}_valid`]: true,
			error: error ? this.props.t('MobileVerificationForm__CodeError') : null,
		});

		if (name !== 'code_4') {
			// Gets the last character of the input's name
			let lastChar = name.substr(name.length - 1);
			lastChar++;
			let nextInput = 'code_' + lastChar;

			// Explicitly focus the next input using the raw DOM API

			this.setState({
				componentInFocus: nextInput,
			});
		}
	};
	/**
	 * Verifies the mobile number when pressing enter
	 * @param event
	 */
	onKeyDown = (event) => {
		if (event.key === EVENT_KEY_ENTER && event.target.name === 'code_4') {
			// verify if the verification code is a 4 digit number
			let code = parseInt(this.state.code_1 + this.state.code_2 + this.state.code_3 + this.state.code_4);
			let valid = code && code.toString().length === 4;

			if (valid && !this.props.verified && !this.local_loading) {
				this.local_loading = true;
				this.verifyEmail();
			}
		}
	};
	render = () => {
		const {
			code_1,
			code_2,
			code_3,
			code_4,
			code_1_valid,
			code_2_valid,
			code_3_valid,
			code_4_valid,
			checked,
			error,
			componentInFocus,
		} = this.state;

		const {
			email,
			emailCode,
			emailWasSent,
			emailError,
			emailCodeError,
			verified,
			loading,
			sendEmail,
			// goBack,
			goNext,
			t,
		} = this.props;

		const { handleChange, verifyEmail } = this;
		// verify if the verification code is a 4 digit number
		const code = parseInt(code_1 + code_2 + code_3 + code_4);
		const valid = code && code.toString().length === 4;

		// Should only go to next input if the input is valid

		let availableInputs = {
			code_1: true,
			code_2: code_1.length === 1,
			code_3: code_2.length === 1,
			code_4: code_3.length === 1,
		};

		if (emailWasSent) {
			return (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
					<Text paragraph strong>
						{!verified ? t('Verify your email address') : t('Email verified')}
					</Text>
					<Text size="-1" strong>
						{t('We have sent you an email with a verification code. Please enter it below.')}
					</Text>

					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
						<div>
							<InputGroup>
								<Input
									name="code_1"
									type="tel"
									value={code_1}
									onChange={handleChange}
									onKeyDown={this.onKeyDown}
									inputRef={(input) => {
										this.inputs['code_1'] = input;
									}}
									maxLength={1}
									valid={code_1_valid ? null : false}
									disabled={verified || !availableInputs.code_1}
									align="center"
									margin="cozy"
									horizontalMargin="compact"
								/>
								<Input
									name="code_2"
									type="tel"
									value={code_2}
									onChange={handleChange}
									onKeyDown={this.onKeyDown}
									inputRef={(input) => {
										this.inputs['code_2'] = input;
									}}
									maxLength={1}
									valid={code_2_valid ? null : false}
									disabled={verified || !availableInputs.code_2}
									align="center"
									margin="cozy"
									horizontalMargin="compact"
								/>
								<Input
									name="code_3"
									type="tel"
									value={code_3}
									onChange={handleChange}
									onKeyDown={this.onKeyDown}
									inputRef={(input) => {
										this.inputs['code_3'] = input;
									}}
									maxLength={1}
									valid={code_3_valid ? null : false}
									disabled={verified || !availableInputs.code_3}
									align="center"
									margin="cozy"
									horizontalMargin="compact"
								/>
								<Input
									name="code_4"
									type="tel"
									value={code_4}
									onChange={handleChange}
									onKeyDown={this.onKeyDown}
									inputRef={(input) => {
										this.inputs['code_4'] = input;
									}}
									maxLength={1}
									valid={code_4_valid ? (verified ? true : null) : false}
									disabled={verified || !availableInputs.code_4}
									align="center"
									margin="cozy"
								/>
							</InputGroup>

							{error && (
								<Text type="danger" size="-2" paragraph>
									{error}
								</Text>
							)}

							{verified ? (
								<Text size="-1" paragraph>
									{t('Congratulations! Your email has been verified.')}
									{t('You can now proceed to the next step.')}
								</Text>
							) : (
								<Text size="-2" paragraph>
									Please enter the four digit verification code that has been sent to <strong>{email}</strong> so that
									we can validate your email.
								</Text>
							)}
						</div>

						<Icon
							className={css`
								font-size: 90px;
								line-height: 90px; // for IE
								align-self: flex-start;
							`}
							icon="email"
						/>
					</div>

					<ButtonGroup center>
						<Button type="ghost" action={() => this.props.handleChange('email_was_sent', false)} bold flex>
							{t('Go back')}
						</Button>
						{verified ? (
							<Button action={goNext} type="primary" disabled={emailCodeError} loading={loading} bold flex>
								{t('Next')}
							</Button>
						) : (
							<Button action={verifyEmail} disabled={!valid} type="primary" loading={loading} bold flex>
								{t('Verify Email')}
							</Button>
						)}
					</ButtonGroup>

					{verified && (
						<Text size="-1" paragraph>
							{t('Congratulations! Your email has been verified.')}
							{t('You can now proceed to the next step.')}
						</Text>
					)}
				</div>
			);
		}

		return (
			<div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
				<Text paragraph strong>
					{t('Verify your email address')}
				</Text>

				<Text size="-2" strong>
					{t('Please enter your email address below.')}
				</Text>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
					<Input
						key="email"
						name="email"
						type="email"
						value={email}
						onChange={this.props.handleChange}
						disabled={loading}
						error={emailError != 'Email is invalid' ? emailError : null}
						constraints={CONSTRAINT_EMAIL}
						runValidation={true}
						align="start"
						margin="cozy"
						horizontalMargin="compact"
						label={t('UserDetailsForm__Email')}
						className={css`
							width: 100%;
							margin-bottom: 20px;
						`}
					/>
					<Icon
						className={css`
							font-size: 90px;
							line-height: 90px; // for IE
							align-self: flex-start;
						`}
						icon="email"
					/>
				</div>

				<ButtonGroup center>
					{/* <Button type="ghost" action={goBack} bold flex>
						{t('Go back')}
					</Button> */}

					<Button action={sendEmail} type="primary" disabled={emailError} loading={loading} bold flex>
						{t('Send code')}
					</Button>
				</ButtonGroup>
				{/* <p> A code has been sent to your email address. Please enter it below.</p> */}
			</div>
		);
	};
}
export default withNamespaces()(EmailAuthentication);
