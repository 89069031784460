import styled, { css } from 'react-emotion';
import { spacings, media, brand , Text} from '@tbh/ui-kit';

import {  Button, typography} from '@tbh/ui-kit';
import { application } from '@tbh/ui-kit';

import BetBuilderRaceListContainer from '../../../containers/Racing/BetBuilderRaceListContainer/BetBuilderRaceListContainer'; // @HW 04July2020

export const StyledBetBuilderHomePage = styled('div')(
	(props) =>
		css`
			label: BetBuilderHomePage;

			margin: ${spacings(props).compact}px;
		`,
	media(
		css`
			display: flex;
			flex-wrap: wrap;
		`,
		768,
	),
);

export const StyledBetBuilderHomePage_body = styled('div')`
  ${(props) =>
      css`
        label: BetBuilderHomePage_body;

        display: flex;
		flex-direction: column;
	  `};
	  
	  ${media(
		css`
		display: flex;
		flex-direction: row;
		`,
		660,
	  )};
 `;


 /**
 * bet builder section
 */
export const StyledBetBuilderHomePage__BetBuilder_Content = styled('div')(
	(props) =>
	css`
		label: BetBuilderHomePage__BetBuilder_Content;

		//margin-top: ${spacings(props).compact}px;
		background-color: #fff;
	`,
	media(
	css`
		display: block;
			//margin-top:25px;
			//margin-bottom:25px;
		// display: flex;
		// flex-wrap: wrap;
	`,
	768,
	),
);

export const StyledBetBuilderHomePage___Card__Body = styled('div')`
	${(props) =>
  css`
			label: BetBuilderHomePage__Card_Body;
		`};
`;

export const StyledBetBuilderHomePage__Header_section = styled('div')`
	${(props) =>
		css`
			label: BetBuilderHomePage__Header_section;
			// padding: ${spacings(props).compact}px;
			position: relative;
			z-index: 0; // This stops the loading mask from overlapping the header bar
			
		`};
`;

export const StyledBetBuilderHomePage__maijor_header = styled('div')`
	${(props) =>
		css`
			label: BetBuilderHomePage__maijor_header;
			
			// border-bottom: 3px solid ${brand(props).rch_4};
			clear: both;
			display: flex;
			justify-content: space-between;
			flex-flow: row wrap;
			width: 100%;
		`};
`;

export const StyledBetBuilderHomePage__Major_header_h2 = styled('h2')`
	${(props) =>
		css`
			label: BetBuilderHomePage__Major_header_h2;

		
			display: flex;  
			// text-transform: uppercase;
			font-weight: 600;
			// background-color: ${brand(props).rch_4};
			// border-color: ${brand(props).rch_4};
			color: black;
			margin: 0;
			// padding: 13px 10px 10px 10px;
			height: auto;
			position: relative;
			font-size: 20px;
			letter-spacing: 0.32px;
			text-align: center;

			flex-wrap: wrap;
			max-height: 45px;
		`};
		${media(
			(props) => css`
				display: flex;  
				font-size: 20px;
				`,
			767,
			)};
`;

export const StyledBetBuilderHomePage__h2 = styled('h2')`
	${(props) =>
		css`
			label: BetBuilderHomePage__h2;

			display: flex; 
			flex-wrap: wrap;
			// text-transform: uppercase;
			font-weight: bold;
			// border-color: ${brand(props).rch_4};
			color: black;
			// padding-right:  ${spacings(props).compact}px;
			height: auto;
			position: relative;
			font-size: 18px;
			letter-spacing: 0.32px;
			text-align: center;
			
		`};
	
		${media(
			(props) => css`
			 
			font-size: 18px;

				  `,
			767,
			{ sizeKey: 'mediaSize' },
		  )};
`;


export const StyledBetBuilderHomePage__Title = styled(Text)`
	label: BetBuilderHomePage__Title;

	display: block;
	font-size: 15px;

	${media(
  		css`
      		margin-top:10px;
      		margin-bottom:10px;
			display: flex;
			flex: 1 1 auto;
			font-size: 15px;
		`,
  500,
  { sizeKey: 'mediaSize' },
)};
`;

export const StyledBetBuilderHomePage__BetBuilderRacesListsContainer = styled(BetBuilderRaceListContainer)`
			label: BetBuilderHomePage__BetBuilderRacesListsContainer;


	${media(
		(props) => css`
				overflow: hidden; // This is required for the races list to stay within its container
			`,
		767,
		)};
`;

export const StyledBetbuiderHomePage__Title = styled(Text)`
	label: BetbuiderHomePage__Title ;

	display: none;

	${media(
  css`
			display: flex;
			flex: 1 1 auto;
		`,
  500,
  { sizeKey: 'mediaSize' },
)};
`;


export const StyledRaceCountrySelection__All_Button = styled(Button)(
	(props) => css`
		label: All_Button;

        margin-top: ${spacings(props).compact}px;
        font-size: 11.237311385459533px;
        font-weight: 800;
        line-height: 1.5;
        //padding: 4px;
		text-align: center;
		// This is to keep all the buttons at the top of the race card the same width
		//min-width: ${application(props).racing.button_width}px;
		max-width: 30px;
		height: 30px;

		&:active {
			background: #2E2E2E;
			color: #ffff;
		}
        
		&:hover {
			// This is to override the specificity of the Text component
			${Text} {
				color: ${typography(props).color_1_alternate};
			}
        }
        
        ${props.type === 'secondary' &&
        css`
            && {
                background: ${brand(props).rch_4};
                border: 1px solid ${brand(props).rch_4};
            }
        `};


		&:first-child {
			margin-left: 5px; // This is to override the specificity of the button group
		}
	`,

	media(
		(props) => css`
			max-width: 30px;
			height: 30px;

			&:active {
				background: #2E2E2E;
			}
		`,
		780,
		{ sizeKey: 'mediaSize' },
	),
);

