import { denormalizeRaces } from '../../store/entities/schemas/RaceSchema';
import { denormalizeMarkets } from '../../store/entities/schemas/MarketSchema';
import { denormalizeEvents } from '../../store/entities/schemas/EventSchema';
import { addMultiSelection, reset } from '../../store/betPlacement/multiBetPlacementActions';
import {
	buildRacingSelection,
	buildNJRacingSelection,
	buildExpertTipsSelection,
	validateExotics,
} from '../../store/betPrompt/betPromptActionHelpers';
import { denormalizeNextToJumpRaces } from '../../store/entities/schemas/NextToJumpRaceSchema';
import { denormalizeFeatureRaces } from '../../store/entities/schemas/FeatureRaceSchema';
import { denormalizeUpcomingRaces } from '../../store/entities/schemas/UpcomingAllRaceSchema';

import {
	BET_TYPE_WIN,
	BET_TYPE_RACE,
	BET_TYPE_SPORT,
	PRODUCT_TYPE_STANDARD,
	BET_TYPE_EXOTIC,
} from '../../store/entities/constants/BetConstants';
import {
	addExotics,
	addQuaddies,
	addRace,
	addSRM,
	handleBetslipTab,
	setBetLoading,
	validateSameRaceMulti,
} from '../../store/betPlacement/betPlacementActions';
import { PLACE_MULTI_BET } from '../../store/betPlacement/betPlacementReducerNames';
import { openNotification, toggleMultiBetSlip } from '../../store/application/applicationActions';
import { CleanQuaddieSelections } from '../../store/GRSracingHome/GRSracingHomeActions';
import { formatExoticSelection } from '../../store/betPrompt/betPromptActions';
import { calculateCombinations } from '../ExoticBets';

/**
 * Add entire selection as is to the multi slip
 *
 * @param betSelection
 * @param validate
 * @param eventContext
 */
export const addSelectionToMultiBetSlip = (betSelection, validate = true, eventContext = '') => (dispatch) => {
	if (betSelection.bet_type === BET_TYPE_SPORT) {
		return dispatch(
			addSportSelectionToBetSlip(
				betSelection.selection_id,
				betSelection.market_id,
				betSelection.odds,
				betSelection.product_id,
				betSelection.product_type || PRODUCT_TYPE_STANDARD,
				validate,
			),
		);
	} else {
		return dispatch(addMultiSelection(betSelection, BET_TYPE_RACE, validate, eventContext));
	}
};

/**
 * Format sport multi bet data and call function to add to multi slip
 *
 * @param id
 * @param market_id
 * @param odds
 * @param product_id
 * @param product_type
 * @param validateMulti
 * @param eventContext
 * @returns {Function}
 */
export const addSportSelectionToBetSlip = (
	id,
	market_id,
	odds,
	product_id = null,
	product_type = PRODUCT_TYPE_STANDARD,
	validateMulti = true,
	eventContext = '',
) => (dispatch, getState) => {
	const marketEntity = denormalizeMarkets(getState().entities, [market_id]);
	if (marketEntity.length) {
		const market = marketEntity[0];
		const eventEntity = denormalizeEvents(getState().entities, [market.event_id]);
		if (eventEntity.length) {
			const event = eventEntity[0];
			const selection = {
				id,
				market_id,
				odds,
				product_id,
				product_type,
				event,
				market,
				type: BET_TYPE_SPORT,
			};

			return dispatch(addMultiSelection(selection, BET_TYPE_SPORT, validateMulti, eventContext));
		}
	}
};
export const BET_FROM_RACE_PAGE = 'race-page';
/**
 * Format racing multi bet data and call function to add to multi slip
 *
 * @param id
 * @param race_id
 * @param meeting_id
 * @param product_id
 * @param bet_type
 * @returns {Function}
 */
export const formatAndAddRacingMultiBet = (
	id,
	race_id,
	meeting_id,
	product_id,
	bet_type,
	user_amount,
	cash_out_enabled,
) => (dispatch, getState) => {
	const request_at_bet_slip = Date.now();
	const raceEntity = denormalizeRaces(getState().entities, [race_id]);
	dispatch(handleBetslipTab());

	if (raceEntity.length) {
		const race = raceEntity[0];

		const builtSelection = dispatch(buildRacingSelection(race, id, product_id, bet_type));
		const selection = {
			id,
			race,
			race_id,
			race_status: race.status,
			meeting_id,
			product_id,
			bet_type,
			user_amount, // newly add
			request_at_bet_slip, //newly add
			cash_out_enabled,
			type: BET_TYPE_RACE,
			...builtSelection,
			bet_from: BET_FROM_RACE_PAGE,
		};
		dispatch(addRace(PLACE_MULTI_BET, { id: race_id, status: race.status }));
		return dispatch(addMultiSelection(selection, BET_TYPE_RACE, true));
	}
};

/**
 * Format racing multi bet data and call function to add to multi slip
 *
 * @param id
 * @param race_id
 * @param meeting_id
 * @param product_id
 * @param bet_type
 * @returns {Function}
 */
export const formatAndUpdateRacingMultiBet = (
	id,
	race_id,
	meeting_id,
	product_id,
	bet_type,
	stake,
	cash_out_enabled,
	bet_from,
) => (dispatch, getState) => {
	const request_at_bet_slip = Date.now();
	let race = null;
	let builtSelection = null;
	if (bet_from == BET_FROM_RACE_PAGE) {
		const raceEntity = denormalizeRaces(getState().entities, [race_id]);
		if (!raceEntity.length) return;
		race = raceEntity[0];
		builtSelection = dispatch(buildRacingSelection(race, id, product_id, bet_type));
	} else if (bet_from == BET_FROM_UPCOMING || bet_from == BET_FROM_EXPERT_TIPS) {
		const raceUpComingEntity = denormalizeUpcomingRaces(getState().entities, [race_id]);
		if (!raceUpComingEntity.length) return;
		race = raceUpComingEntity[0];
		const raceType = race.favorite_selection.length > 0 ? 'favorite_selection' : 'top_selections';
		builtSelection = dispatch(buildExpertTipsSelection(race, id, product_id, bet_type, raceType));
	} else if (bet_from == BET_FROM_FEATURE) {
		const raceFeatureEntity = denormalizeFeatureRaces(getState().entities, [race_id]);
		if (!raceFeatureEntity.length) return;
		race = raceFeatureEntity[0];
		builtSelection = dispatch(buildExpertTipsSelection(race, id, product_id, bet_type));
	} else {
		return;
	}

	const selection = {
		id,
		race,
		race_id,
		race_status: race.status,
		meeting_id,
		product_id,
		bet_type,
		stake, // newly add
		amount: stake,
		user_amount: stake,
		request_at_bet_slip, //newly add
		cash_out_enabled,
		type: BET_TYPE_RACE,
		bet_from,
		...builtSelection,
	};
	return selection;
};

/**
 * Format racing multi bet data and call function to add to multi slip
 * for Next to jump races
 * @HW 10Feb2020
 * @param id
 * @param race_id
 * @param meeting_id
 * @param product_id
 * @param bet_type
 * @returns {Function}
 */
export const formatAndAddRacingMultiBetNJ = (id, race_id, meeting_id, product_id, bet_type) => (dispatch, getState) => {
	// add denormalizeNextToJumpRaces
	dispatch(handleBetslipTab());
	
	const raceEntity = denormalizeNextToJumpRaces(getState().entities, [race_id]);
	if (raceEntity.length) {
		const race = raceEntity[0];
		const builtSelection = dispatch(buildNJRacingSelection(race, id, product_id, bet_type));

		const selection = {
			id,
			race,
			race_id,
			race_status: race.status,
			meeting_id,
			product_id,
			bet_type,
			type: BET_TYPE_RACE,
			...builtSelection,
		};
		dispatch(addRace(PLACE_MULTI_BET, { id: race_id, status: race.status }));
		return dispatch(addMultiSelection(selection, BET_TYPE_RACE, true));
	}
};
export const BET_FROM_UPCOMING = 'upcoming';
/**
 * Format racing multi bet data and call function to add to multi slip
 * for Upcoming races
 * @HW 03Feb2021
 * @param id
 * @param race_id
 * @param meeting_id
 * @param product_id
 * @param bet_type
 * @returns {Function}
 */
export const formatAndAddRacingMultiBetUpcoming = (id, race_id, meeting_id, product_id, bet_type, selectionType) => (
	dispatch,
	getState,
) => {
	dispatch(handleBetslipTab());

	// add denormalizeUpcomingRaces
	const raceEntity = denormalizeUpcomingRaces(getState().entities, [race_id]);
	if (raceEntity.length) {
		const race = raceEntity[0];
		const builtSelection = dispatch(buildExpertTipsSelection(race, id, product_id, bet_type, selectionType));
		const selection = {
			id,
			race,
			race_id,
			race_status: race.status,
			meeting_id,
			product_id,
			bet_type,
			type: BET_TYPE_RACE,
			cash_out_enabled: race.cash_out_enabled,
			...builtSelection,
			bet_from: BET_FROM_UPCOMING,
		};
		dispatch(addRace(PLACE_MULTI_BET, { id: race_id, status: race.status }));
		return dispatch(addMultiSelection(selection, BET_TYPE_RACE, true));
	}
};
export const BET_FROM_FEATURE = 'feature';
/**
 * Format racing multi bet data and call function to add to multi slip
 * for Feature races
 * @HW 15Oct2020
 * @param id
 * @param name // selection name
 * @param race_id
 * @param meeting_id
 * @param product_id
 * @param bet_type
 * @returns {Function}
 */
export const formatAndAddRacingMultiBetFeature = (id, race_id, meeting_id, product_id, bet_type, selectionType) => (
	dispatch,
	getState,
) => {
	dispatch(handleBetslipTab());

	// add denormalizeFeatureRaces
	const raceEntity = denormalizeFeatureRaces(getState().entities, [race_id]);
	if (raceEntity.length) {
		const race = raceEntity[0];
		const builtSelection = dispatch(buildExpertTipsSelection(race, id, product_id, bet_type, selectionType));

		const selection = {
			id,
			race,
			race_id,
			race_status: race.status,
			meeting_id,
			product_id,
			bet_type,
			type: BET_TYPE_RACE,
			...builtSelection,
			bet_from: BET_FROM_FEATURE,
		};
		dispatch(addRace(PLACE_MULTI_BET, { id: race_id, status: race.status }));
		return dispatch(addMultiSelection(selection, BET_TYPE_RACE, true));
	}
};

/**
 * Add a NTJ racing selection to the multi slip
 *
 * @param race
 * @param price
 * @returns {Function}
 */
export const addNTJRacingMultiBet = (race, price, selectionId) => (dispatch) => {
	dispatch(formatAndAddRacingMultiBet(selectionId, race.id, race.meeting_id, price.product_id, BET_TYPE_WIN));
};

/**
 * Format Same Race Multi  bet data and call function to add to same race multi slip
 * for Same Race Multi
 * @HW 06Dec2022
 * @param race_id
 * @param meeting_id
 * @param selectedSelections
 * @returns {Function}
 */
export const formatAndAddSameRaceMultiBet = (race_id, meeting_id, selectedSelections) => async (dispatch, getState) => {
	// add denormalizeFeatureRaces
	const raceEntity = denormalizeRaces(getState().entities, [race_id]);
	dispatch(handleBetslipTab());

	if (raceEntity.length) {
		const race = raceEntity[0];

		const builtSelection = selectedSelections.map((selection) => {
			const data = dispatch(buildRacingSelection(race, selection.id, selection.product_id, selection.bet_type));
			return {
				id: selection.id,
				name: selection.name,
				type_name: selection.type_name,
				...data,
			};
		});

		// Sort the selections by bet number Win - Top 2  - Top 3 - Top 4
		builtSelection.sort((a, b) => {
			if (Number(a.betNumber) > Number(b.betNumber)) {
				return 1;
			}
			if (Number(a.betNumber) < Number(b.betNumber)) {
				return -1;
			}
			return 0;
		});

		const selectionsForValidation = builtSelection.map((selection) => {
			const type_bet = selection.type_name == 'Win' ? 'win' : 'place';
			const key_dividend = `${type_bet}_dividend`;
			const key_product = `${type_bet}_product`;
			//
			return {
				id: selection.id,
				bet_type: selection[type_bet].code.toLowerCase(),
				[key_dividend]: selection[type_bet].odds,
				[key_product]: selection[type_bet].productId,
				accept_previous_bet: false,
			};
		});

		const { bets, SRMs } = getState()[PLACE_MULTI_BET];
		const srmId = SRMs.length;

		// Set Loading

		dispatch(setBetLoading(PLACE_MULTI_BET, true));

		try {
			const response = await validateSameRaceMulti(selectionsForValidation);
			const srm = {
				id: srmId,
				race,
				race_id,
				race_status: race.status,
				meeting_id,
				type: BET_TYPE_RACE,
				selections: builtSelection,
				price: Number(response[0].price) * 100,
			};

			if (bets.length) {
				dispatch(reset(PLACE_MULTI_BET));
			}
			dispatch(addSRM(PLACE_MULTI_BET, srm));
			dispatch(addRace(PLACE_MULTI_BET, { id: race_id, status: race.status }));
		} catch (err) {
			dispatch(
				openNotification('Some error happened in your bet!', 'danger', {
					autoClose: 5000,
				}),
			);
		}

		dispatch(setBetLoading(PLACE_MULTI_BET, false));
	}
};

export const formatAndAddQuaddiesMultiBet = (races_ids, selectedSelections, bet_type, product_id) => (
	dispatch,
	getState,
) => {
	const raceEntity = denormalizeRaces(getState().entities, races_ids);
	dispatch(handleBetslipTab());

	if (raceEntity.length) {
		const races = raceEntity.map((r, i) => {
			const selections = selectedSelections[r.id].map((se) => {
				const s = r.selections.find((s) => s.id === se.selection_id);
				return {
					race_id: r.id,
					race_status: r.status,
					race_number: r.number,
					...s,
				};
			});

			return {
				...r,
				selections,
			};
		});
		const { bets, quaddies } = getState()[PLACE_MULTI_BET];

		const quaddieId = quaddies.length;
		const selections = [];

		races.forEach((r, i) => {
			r.selections.forEach((s) => {
				selections.push({
					...s,
					position: i + 1,
					bet_type,
					product_id,
				});
			});
		});

		let combinations = calculateCombinations(bet_type, selections, false);

		const bet = {
			id: quaddieId,
			eventName: races[0].meeting_name,
			race_type: races[0].type,
			races,
			bet_type,
			product_id,
			stake: 0,
			amount: 0,
			selections,
			combinations: combinations,
		};

		if (bets.length) {
			dispatch(reset(PLACE_MULTI_BET));
		}
		dispatch(addQuaddies(PLACE_MULTI_BET, bet));
		dispatch(CleanQuaddieSelections());
		dispatch(toggleMultiBetSlip(false));

		for (let race of races) {
			dispatch(addRace(PLACE_MULTI_BET, { id: race.id, status: race.status }));
		}
	}
};
export const BET_FROM_EXPERT_TIPS = 'expert_tips';

export const formatAndAddExpertTipsSelectionBet = (race, id, race_id, meeting_id, product_id, bet_type) => (
	dispatch,
	getState,
) => {
	const builtSelection = dispatch(buildExpertTipsSelection(race, id, product_id, bet_type, 'top_selections'));
	dispatch(handleBetslipTab());

	const selection = {
		id,
		race,
		race_id,
		race_status: race.status,
		meeting_id,
		product_id,
		bet_type,
		user_amount: null, // newly add
		request_at_bet_slip: null, //newly add
		cash_out_enabled: null,
		type: BET_TYPE_RACE,
		...builtSelection,
		bet_from: BET_FROM_EXPERT_TIPS,
	};
	dispatch(addRace(PLACE_MULTI_BET, { id: race_id, status: race.status }));
	return dispatch(addMultiSelection(selection, BET_TYPE_RACE, true));
};

export const formatAndAddExoticSelectionBetslip = (raceId, meetingId, betType, boxed, selectedCheckboxes) => (
	dispatch,
	getState,
) => {
	const raceEntity = denormalizeRaces(getState().entities, [raceId]);
	dispatch(handleBetslipTab());
	if (raceEntity.length) {
		const race = raceEntity[0];

		const { betSelections, exoticDetails } = formatExoticSelection(race, meetingId, betType, boxed, selectedCheckboxes);
		if (validateExotics(betSelections, betType, exoticDetails.isBoxed)) {
			const { bets, exotics } = getState()[PLACE_MULTI_BET];

			if (bets.length) {
				dispatch(reset(PLACE_MULTI_BET));
			}

			const combinations = calculateCombinations(betType, betSelections, exoticDetails.isBoxed);

			dispatch(
				addExotics(PLACE_MULTI_BET, {
					id: exotics.length,
					exoticDetails,
					selectionType: BET_TYPE_EXOTIC,
					selections: betSelections,
					combinations,
					race_id: raceId,
					race_status: race.status,
				}),
			);
			dispatch(addRace(PLACE_MULTI_BET, { id: raceId, status: race.status }));
			dispatch(toggleMultiBetSlip(false));
			return Promise.resolve();
		}

		return Promise.reject('Could not validate exotic.');
	}

	return Promise.reject(`Race [${raceId}] not found.`);
};
