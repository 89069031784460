/**
 * A function for building an array of race_ids based on the `first_leg` property.
 * Currently used for exotic bet types containing multi leg functionality, quaddie and dailydouble.
 *
 * @param {example} 5
 * @param {example} 2
 * @param {example} 'quaddie'
 */
export const buildRaceNumberSequence = (raceNumber, legPosition, betType) => {
	// sanity check
	if (!legPosition) {
		return [];
	}

	if (betType === 'quaddie') {
		/* number of legs to generate */
		const quaddieLegs = 4;

		/* define first and last ids to build array loop */
		let firstRaceId = raceNumber + 1 - legPosition;
		let lastRaceId = firstRaceId + quaddieLegs;

		/* build array loop */
		const races = Array(lastRaceId - firstRaceId)
			.fill()
			.map(() => firstRaceId++);

		return races;
	}

	if (betType === 'dailydouble') {
		/* number of legs to generate */
		const dailyDoubleLegs = 2;

		if (legPosition === 1) {
			const firstRaceId = raceNumber + 1 - legPosition;
			const lastRaceId = firstRaceId + dailyDoubleLegs;
			return [firstRaceId, lastRaceId];
		}

		if (legPosition === 2) {
			const firstRaceId = raceNumber - legPosition;
			const lastRaceId = firstRaceId + dailyDoubleLegs;
			return [firstRaceId, lastRaceId];
		}
	}
};
