import { Text, brand, spacings } from '@tbh/ui-kit';
import React from 'react';

import styled, { css } from 'react-emotion';
import { WEIGHTS, MEDIA_QUERIES, BREAKPOINTS } from '../../../constants/themes';

export const StyledSectionInput = styled('input')`
	${(props) => css`
		label: SectionInput;
		width: 100%;
		color: #747474;
		font-size: 16px;
		font-weight: normal;
		padding: 8px;
		border: none;
		border-bottom: 1px solid #dddddd;
		outline: none;
		${props.error && 'border-color: red;'}

		&:focus {
			outline: none;
			border-bottom: 1px solid #666666;
			box-shadow: none;
		}
	`}
`;

export const StyledSection = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
    gap: 24px;

	&:not(:last-of-type) {
		padding-bottom: 10px;
	}

	@media ${MEDIA_QUERIES.tabletAndUp} {
		padding-right: 15px;
	}
`;
export const StyledSectionTitle = styled(Text)`
	font-size: 20px;
	// font-weight: bolder;
	margin: 0px;
`;
export const StyledSectionButton = styled('button')`
	${(props) => css`
		label: SectionButton;
		
		width: 100%;
		height: 40px;
		background-color: ${props.color ? props.color : brand(props).color_3_dark};
		color: ${props.fontColor ? props.fontColor : 'white'};
		border: 1px solid #e5e5e5;
		padding: 4px 8px;
		border: 1px;
		border-radius: 4px;

		font-weight: ${WEIGHTS.medium};
		&:disabled {
			background-color: #737581;
			color: #e1e1e1;
			cursor: not-allowed;
		}
	`}
`;
export const StyledSectionInputGroup = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 10px 20px;
	gap: 12px;
	@media (max-width: ${BREAKPOINTS.laptopMin}px) {
		padding: 8px;
	}
`;
export const Row = styled.div`
	${(props) => css`
		display: flex;
		flex-direction: row;
		justify-content: ${props.justifyContent ? props.justifyContent : 'space-between'};
		align-items: ${props.alignItems ? props.alignItems : 'center'};
	`}
`;
export const Column = styled.div`
	${(props) => css`
		display: flex;
		flex-direction: column;
		justify-content: ${props.justifyContent ? props.justifyContent : 'space-between'};
		align-items: ${props.alignItems ? props.alignItems : 'center'};
	`}
`;


export const Input = ({ placeholder, onChange, error, type, value, style }) => {
	return (
		<Column
			justifyContent="flex-start"
			alignItems="flex-start"
			style={{ ...style, width: '100%', marginBottom: '12px' }}
		>
			<Text size="-2" type="light">
				{placeholder}
			</Text>

			<StyledSectionInput type={type} value={value} onChange={onChange} error={error} />

			{error && (
				<Text size="-2" type="danger">
					{error}
				</Text>
			)}
		</Column>
	);
};

export const StyledExtraContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${(props) => spacings(props).compact}px;
	margin: ${(props) => spacings(props).compact}px 0;
	margin-bottom: ${(props) => spacings(props).comfortable}px;
	border-radius: 4px;
	border: 1px solid #666666;
`;
