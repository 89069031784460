import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';


import { connect } from 'react-redux';

import RaceBetBuilder from '../../../components/features/Racing/RaceBetBuilder/RaceBetBuilder';
import { trackbetBulderType } from '../../../store/betBuilder/betBuilderHomeTrackingActions';
import {navigateToNextToJumpRaceWithType } from '../../../store/GRSracingHome/GRSracingHomeActions';

import { trackNextToJump } from '../../../store/GRSracingHome/GRSracingTrackingActions';
//import { getSelectedMeeting } from '../../../pages/BetBuilder/BetBuilderHome/BetBuilderSelectors';

class BetBuilderRaceListContainer extends React.Component {
    static propTypes = {
        /** Translation func provided by withNamespaces HOC */
        t: PropTypes.func.isRequired,
        
        /** The scrollbar width for the browser */
		scrollbarWidth: PropTypes.number.isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
        }),
        
        // Extra class(es) to pass through
        className: PropTypes.string,
        
        // selected meeting.
        activeMeetingItem: PropTypes.number,

        /** Entity for selected meeting */
		meeting: PropTypes.shape({
                id: PropTypes.number.isRequired,
                  name: PropTypes.string.isRequired,
                  state: PropTypes.string,
                  country: PropTypes.string,
                  type: PropTypes.string.isRequired,
                  start_date: PropTypes.string.isRequired,
                  races: PropTypes.array.isRequired,
       }),

       selectedBetBuilderMeeting: PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                state: PropTypes.string,
                country: PropTypes.string,
                type: PropTypes.string.isRequired,
                start_date: PropTypes.string.isRequired,
                races: PropTypes.array.isRequired,
        }).isRequired,

       meetingId: PropTypes.number,
       

       trackOnFilterClick: PropTypes.func,


       isbetBuilderHome: PropTypes.bool,
       /** Navigates to the selected race page */
	   navigateToRaceWithType: PropTypes.func.isRequired,
        
    };

    static defaultProps = {
        className: null,
        activeMeetingItem: null,
        size: null,
        trackOnFilterClick: () => undefined,
        isbetBuilderHome: false,
        meeting: undefined,
        meetingId: null,
    };

    /**
	 * Tracking event used the carousel filter
	 * @param {string} type -  type
	 */
	trackOnFilterClick = (type) => {
		this.props.trackOnFilterClick(type, this.props.isbetBuilderHome);
    };
    
   /**
	 * Navigates to the selected race
     * @param type
     * @param date
	 * @param raceId
	 * @param meetingId
	 */
	handleGoToRace = (type,date,raceId, meetingId) => {
		this.props.navigateToRaceWithType(type,date,raceId, meetingId);
	};

   
    
    render(){
        const { className, meeting, size, meetingId, selectedBetBuilderMeeting} = this.props;

		const containerClasses = cx({
			[className]: className,
        });
        
        return (

            <div className={containerClasses}>
                <RaceBetBuilder
                    className={this.props.className}
                    betBuilderMeeting={meeting}
                    selectedBetBuilderMeeting={selectedBetBuilderMeeting}
                    meetingId={meetingId}
                    size={size}
                    onGoToRace={this.handleGoToRace}
                    trackOnFilterClick={this.trackOnFilterClick}
                />
            </div>                        
        );
    }
}

const mapStateToProps = (state) => {
	return {
		
        selectedPage:state.application.selectedPage,
        activeMeetingItem: state.betBuilderHome.selectedMeeting,
	    scrollbarWidth: state.application.scrollbarWidth,
	};
};


const mapDispatchToProps = (dispatch) => ({
    navigateToRaceWithType: (type,date,raceId, meetingId, isbetBuilderHome) => {
		dispatch(trackNextToJump('Click', null, isbetBuilderHome));
		dispatch(navigateToNextToJumpRaceWithType(type,date,raceId, meetingId));
		App.startSubApp('RacingNewPage');
	},
    trackOnFilterClick: (type, isbetBuilderHome) => dispatch(trackbetBulderType('Filter', type, isbetBuilderHome)),	
});

export default withNamespaces()(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(BetBuilderRaceListContainer)
);