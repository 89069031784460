/**
 * Libraries
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css } from 'react-emotion';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

/**
 * Functions
 */
import { createErrorBoundary } from '../../../containers/ErrorBoundary/ErrorBoundaryActions';
import { getAuthenticatedUser } from '../../../store/application/applicationSelectors';

/**
 * Constants
 */
import { PLAYER_BETS_BASE_URL } from '../../../common/constants/PlayerBetsHome';

/**
 * Components
 */
import Route from '../../../components/controllers/Route/Route';
import DocumentTitle from '../../../components/controllers/Meta/DocumentTitle';
import DescriptionMeta from '../../../components/controllers/Meta/DescriptionMeta';

import Modal from '../../../components/controllers/Modal/Modal';
import LoginContainer from '../../../containers/Application/Login/LoginContainer';
import { Iframe, Panel } from '@tbh/ui-kit';
import { show404 } from '../../../store/application/applicationActions';

const cssFullHeight = css`
	height: 100%;
`;

class PlayerBetsHomePage extends PureComponent {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Function to mark that the container has stopped loading - NOTE: automatically passed in by a wrapper */
		setContainerHasLoaded: PropTypes.func.isRequired,

		/** The base src URL to load into the iframe */
		baseSrc: PropTypes.string.isRequired,

		/** The affiliate ID for accessing the API */
		id: PropTypes.string.isRequired,

		/** Loads the 404 error page if there is no baseSrc supplied */
		show404: PropTypes.func.isRequired,

		/** Based off state. Used for routing. */
		currentRoute: PropTypes.string,

		/** The resource to load from the base URL */
		src: PropTypes.string,

		/** The currently authenticated user */
		user: PropTypes.shape({
			/** Their username */
			username: PropTypes.string.isRequired,

			/** Their laravel session ID */
			user_token: PropTypes.string.isRequired,
		}),

		/** Brand name */
		brandName: PropTypes.string,
	};

	static defaultProps = {
		currentRoute: PLAYER_BETS_BASE_URL,
		src: '/?sb=',
		user: null,
		brandName: '',
	};

	constructor(props) {
		super(props);

		this.state = {
			showLoginPrompt: false,

			/** Height of the iFrame */
			dstHeight: 824,

			/** Message to post to the embedded iframe */
			dstMessage: '',
		};
	}

	componentDidMount() {
		// Show the 404 error page if there is no baseSrc supplied
		if (!this.props.baseSrc) {
			this.props.show404();
		}
	}

	/**
	 * Login to the DST service, or login and place a bet
	 *
	 * @param loginOnly
	 * @param showLoginPrompt
	 */
	dstLogin = (loginOnly = false, showLoginPrompt = false) => {
		const { user } = this.props;

		// Set the player authorisation message to login
		this.setState({
			showLoginPrompt: showLoginPrompt && !user,

			// ['response','ds::login','authorization',{username:'tomasswood',password:'MY_TOKEN',requester:'quickBet'}]
			dstMessage: user
				? [
						'response',
						`ds::${loginOnly ? 'login' : 'loginAndPlace'}`,
						'authorization',
						{ username: user.username, password: user.user_token, requester: 'quickBet' },
				  ]
				: '',
		});
	};

	/**
	 * Handle the DST embedded iframe posting messages to us
	 *
	 * @param event
	 */
	handlePlayerPropsMessage = (event) => {
		if (Array.isArray(event.data)) {
			if (event.data.length === 4) {
				if (event.data[1] === 'ds::login') {
					this.dstLogin(false, true);
				} else if (event.data[1] === 'ds::ready') {
					this.props.setContainerHasLoaded();
					this.dstLogin(true, false);
				}
			}
		}
	};

	/**
	 * Handle when the login modal was closed
	 */
	handleLoginModalClose = () => {
		// If the user has logged in, try and place their bet
		if (this.props.user) {
			this.dstLogin(false, false);
		} else {
			this.setState({
				showLoginPrompt: false,
			});
		}
	};

	/**
	 * Get the login modal
	 *
	 * @returns {boolean|XML}
	 */
	buildLoginModal = () => {
		if (this.props.user) {
			return null;
		}

		// Setup the modal configuration for the bet prompt
		const MODAL_CONFIG = {
			mobileWidth: true,
			noBorder: true,
			preventBackdropClose: true,
			showEncryptionMessage: false,
			hideClose: true,
		};

		return (
			this.state.showLoginPrompt && (
				<Modal
					open={this.state.showLoginPrompt}
					componentKey="event-controller__login-prompt"
					store={App.store}
					config={MODAL_CONFIG}
				>
					<LoginContainer handleClose={this.handleLoginModalClose} />
				</Modal>
			)
		);
	};

	render() {
		const { brandName, currentRoute, baseSrc, src, id, t } = this.props;
		const { dstMessage, dstHeight } = this.state;

		// Don't render anything if there is no baseSrc supplied
		if (!baseSrc) {
			return null;
		}

		return (
			<Panel padding={Panel.padding.SPACING_COZY} className={cssFullHeight}>
				<Route route={currentRoute} />
				<DocumentTitle>{`${t('PlayerBetsHomePage__Title')}${
					brandName ? ` ${t('at')} ${brandName}` : ''
				}`}</DocumentTitle>
				<DescriptionMeta>{`${t('PlayerBetsHomePage__Description')}${
					brandName ? ` ${t('with')} ${brandName}` : ''
				}.`}</DescriptionMeta>

				<div className={cssFullHeight}>
					{this.buildLoginModal()}
					<Iframe
						src={`${baseSrc}${src}${id}`}
						height={dstHeight}
						message={dstMessage}
						onMessageReceived={this.handlePlayerPropsMessage}
						targetOrigin={baseSrc}
					/>
				</div>
			</Panel>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: getAuthenticatedUser(state),
		id: state.acl.playerBets ? state.acl.playerBets.id : state.acl.playerBetsID,
		baseSrc: state.acl.playerBets ? state.acl.playerBets.homeEndpoint : state.acl.playerBetsHomeEndpoint,
		brandName: state.acl.brandDetails ? state.acl.brandDetails.name : null,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		show404: () => {
			dispatch(show404());
		},
	};
};

export default withNamespaces()(
	createErrorBoundary(
		connect(
			mapStateToProps,
			mapDispatchToProps,
		)(PlayerBetsHomePage),
	),
);
