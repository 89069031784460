import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { spacings, typography, media } from '@tbh/ui-kit';

// get the default silk image
import { GENERIC_SILK, SELECTION_NOT_SCRATCHED_STATUS } from '../../../../../common/constants/Racing';
import { Image } from '@tbh/ui-kit';
import { Text } from '@tbh/ui-kit';

const StyledGRSNtjRaceSelectionDetails = styled('div')`
	${(props) =>
		css`
			label: GRSNtjRaceSelectionDetails;

			width: 100%;
			display: flex;
			padding: ${spacings(props).tight}px 0;
		`};
`;

const StyledGRSNtjRaceSelectionDetails__Silk = styled('div')`
	${(props) =>
		css`
			label: GRSNtjRaceSelectionDetails__Silk;

			float: left;
			width: 30px;
			min-width: 30px;
			max-height: 32px;
			margin-right: ${spacings(props).constrictive}px;
			text-align: center;
			align-self: center;

			${props.scratched &&
			css`
					opacity: 0.2;
					filter: grayscale(100%);
				`};
		`};

	${media(
				(props) =>
					css`
				margin-right: ${spacings(props).tight}px;
			`,
				500,
			)};

	${media(
				css`
			width: 30px;
			min-width: 30px;
			max-height: 32px;
		`,
				660,
			)};
`;

const StyledGRSNtjRaceSelectionDetails__SilkImage = styled(Image)`
	height: 27px;
	border-radius: 3px !important;

	${media(
	css`
			height: 27px;
		`,
	660,
)};
`;

const StyledGRSNtjRaceSelectionDetails__DetailsColumn = styled('div')`
	${(props) =>
		css`
			label: GRSNtjRaceSelectionDetails__DetailsColumn;

			padding-top: ${spacings(props).tight}px;
			padding-left: ${spacings(props).tight}px;
			text-overflow: ellipsis;
			overflow: hidden;

			${props.scratched &&
			css`
					opacity: 0.2;
					filter: grayscale(100%);
				`};
		`};

	${media(
				css`
			padding-top: 0;
		`,
				660,
			)};
`;

const StyledGRSNtjGreyhoundsRaceSelectionDetails__DetailsColumn = styled('div')`
	${(props) =>
		css`
			label: GRSNtjGreyhoundsRaceSelectionDetails__DetailsColumn;

			padding-top: 20px;
			padding-left: ${spacings(props).tight}px;
			text-overflow: ellipsis;
			overflow: hidden;

			${props.scratched &&
			css`
					opacity: 0.2;
					filter: grayscale(100%);
				`};
		`};

	${media(
				css`
			padding-top: 0;
		`,
				660,
			)};
`;

const StyledGRSNtjRaceSelectionDetails__Name = styled(Text)`
	${(props) =>
		css`
			label: GRSNtjRaceSelectionDetails__Name;

			margin-right: ${spacings(props).tight}px;
			margin-bottom: 3px;
			&:after {
				content: ' ';
			}

			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		`};

	${media(
			(props) =>
				css`
				font-size: ${typography(props)['size_-1']}px;
			`,
			660,
			{ sizeKey: 'mediaSize' },
		)};
`;

const StyledGRSNtjRaceSelectionDetails__RunnerDetails = styled(Text)`
	${(props) =>
		css`
			label: GRSNtjRaceSelectionDetails__jockeyDetails;

			margin-right: ${spacings(props).tight}px;
			margin-bottom: 0;
			&:after {
				content: ' ';
			}
		`};

	${media(
			(props) =>
				css`
				font-size: ${typography(props)['size_-1']}px;
			`,
			660,
			{ sizeKey: 'mediaSize' },
		)};
`;

const StyledGRSNtjRaceSelectionDetails__Subtitle = styled(Text)`
	${(props) =>
		css`
			label: RaceSelectionsDetails__Subtitle;

			margin: ${spacings(props).constrictive}px 0;
			margin: 5px 0;
		`};
`;

const GRSNtjRaceSelectionDetails = (props) => {
	const {
		t,
		className,
		hideSilk,
		number,
		name,
		silk,
		jockey,
		trainer,
		last_starts_string,
		selection_status,
		subtitle,
		type_code,
		size,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});
	/**
	 * Create the jockey or driver label dependant on the race type code
	 * NOTE: no need to cater for greyhounds as it is not rendered
	 * last_starts_string.substring(last_starts_string.length , 5); This is the get last _starts_string in jocky.
	 * @type {string}
	 */
	let jockeyLabel = type_code === 'H' ? t('AcronymDriver') : t('AcronymJockey');

	let lastFiveStarts;
	if (last_starts_string) {
		lastFiveStarts = last_starts_string.substring(0, 5);
	}

	const scratched = selection_status !== SELECTION_NOT_SCRATCHED_STATUS;
	const trainerLabel = trainer;

	return (
		<StyledGRSNtjRaceSelectionDetails className={componentClasses}>
			{!hideSilk && (
				<StyledGRSNtjRaceSelectionDetails__Silk scratched={scratched} size={size}>
					<StyledGRSNtjRaceSelectionDetails__SilkImage
						size={size}
						src={silk ? silk : GENERIC_SILK}
						alt={name + 'racing silk'}
						substituteImageSrc={GENERIC_SILK}
					/>
				</StyledGRSNtjRaceSelectionDetails__Silk>
			)}

			{type_code && type_code !== 'G' ? (
				<StyledGRSNtjGreyhoundsRaceSelectionDetails__DetailsColumn scratched={scratched} size={size}>
					<StyledGRSNtjRaceSelectionDetails__Name
						lineThrough={scratched}
						mediaSize={size}
						size="-3"
						transform="uppercase"
						strong
					>
						{number && `${number}.`} {name}
					</StyledGRSNtjRaceSelectionDetails__Name>
					{subtitle && (
						<StyledGRSNtjRaceSelectionDetails__Subtitle size="-3">
							{subtitle}
						</StyledGRSNtjRaceSelectionDetails__Subtitle>
					)}
					{type_code && type_code !== 'G' && (
						<StyledGRSNtjRaceSelectionDetails__RunnerDetails
							mediaSize={size}
							size="-4"
							whiteSpace="nowrap"
							textOverflow="ellipsis"
						>
							{jockeyLabel}: {jockey}{' '}
						</StyledGRSNtjRaceSelectionDetails__RunnerDetails>
					)}

					{trainerLabel && (
						<StyledGRSNtjRaceSelectionDetails__RunnerDetails mediaSize={size} size="-4">
							{t('AcronymTrainer')}: {trainerLabel}
						</StyledGRSNtjRaceSelectionDetails__RunnerDetails>
					)}
				</StyledGRSNtjGreyhoundsRaceSelectionDetails__DetailsColumn>
			) : (
				/* Thoroughbred and Harness*/

				<StyledGRSNtjRaceSelectionDetails__DetailsColumn scratched={scratched} size={size}>
					<StyledGRSNtjRaceSelectionDetails__Name
						lineThrough={scratched}
						mediaSize={size}
						size="-3"
						transform="uppercase"
						strong
					>
						{number && `${number}.`} {name}
					</StyledGRSNtjRaceSelectionDetails__Name>
					{subtitle && (
						<StyledGRSNtjRaceSelectionDetails__Subtitle size="-3">
							{subtitle}
						</StyledGRSNtjRaceSelectionDetails__Subtitle>
					)}
					{type_code && type_code !== 'G' && (
						<StyledGRSNtjRaceSelectionDetails__RunnerDetails
							mediaSize={size}
							size="-4"
							whiteSpace="nowrap"
							textOverflow="ellipsis"
						>
							{jockeyLabel}: {jockey}{' '}
						</StyledGRSNtjRaceSelectionDetails__RunnerDetails>
					)}

					{trainerLabel && (
						<StyledGRSNtjRaceSelectionDetails__RunnerDetails mediaSize={size} size="-4">
							{t('AcronymTrainer')}: {trainerLabel}
						</StyledGRSNtjRaceSelectionDetails__RunnerDetails>
					)}
				</StyledGRSNtjRaceSelectionDetails__DetailsColumn>
			)}
		</StyledGRSNtjRaceSelectionDetails>
	);
};

GRSNtjRaceSelectionDetails.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}),

	/** The selection name */
	name: PropTypes.string.isRequired,

	/** The selection number */
	number: PropTypes.number,

	/** The race selection type */
	type_code: PropTypes.string,

	/** The jockey weight */
	weight: PropTypes.number,

	/** The jockey silk */
	silk: PropTypes.string,

	/** The jockey name */
	jockey: PropTypes.string,

	/** The trainer name */
	trainer: PropTypes.string,

	/** The selection barrier */
	barrier: PropTypes.number,

	/** The last starts for the selection */
	last_starts_string: PropTypes.string,

	/** The selection status */
	selection_status: PropTypes.oneOf(['not scratched', 'scratched', 'late scratching']),

	/** The fixed price changes/flucs */
	fluctuations: PropTypes.string,

	/**
	 * If the silk should be hidden or not.
	 * In the race card we need to show a generic silk for the runners that don't have a silk,
	 * but hide the image for selections that don't have a silk (E.g.: in the derivative selections).
	 */
	hideSilk: PropTypes.bool,

	/** Extra text to display under the runner */
	subtitle: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,
};

GRSNtjRaceSelectionDetails.defaultProps = {
	className: null,
	weight: null,
	silk: null,
	jockey: null,
	trainer: null,
	barrier: null,
	last_starts_string: null,
	selection_status: null,
	fluctuations: null,
	number: null,
	subtitle: null,
	type_code: null,
	hideSilk: false,
	size: {},
};
export default withNamespaces()(GRSNtjRaceSelectionDetails);
