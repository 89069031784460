import { get } from '../../../common/Ajax';
import { resetEntities, mergeEntities, updateEntities } from '../../../common/actions/actionHelpers';
import { normalizeSelections } from '../schemas/SelectionSchema';
import { normalizeUpcomingRaces } from '../schemas/UpcomingAllRaceSchema';

/**
 * Reset the Feature races with the data from the pusher
 *
 * @param data
 */
export const resetUpcomingRaces = (data) => {
	return resetEntities(normalizeUpcomingRaces(data).entities);
};

/**
 * Normalizes and dispatch merge action for array of Feature Races.
 * @param {Array} data
 */
export const updateMeetings = (data) => {
	return mergeEntities(normalizeUpcomingRaces(data).entities);
};

/**
 * Merge new next upcoming into the existing entities
 *
 * @param nextTickets
 * @returns {*}
 */
export const updateNextUpcomingRaces = (data) => {
	return updateEntities(normalizeUpcomingRaces(data).entities);
};

/**
 * Normalizes and dispatch merge action for array of upcoming Races.
 * @param {Array} races
 */
export const mergeUpcomingRaces = (races) => {
	return mergeEntities(normalizeUpcomingRaces(races).entities);
};

/**
 * Normalizes and dispatch merge action for a feature Race
 * @param race
 */
export const mergeUpcomingRace = (race) => {
	return mergeUpcomingRaces([race]);
};

export const mergeUpComingRaceSelections = (races, race) => {
	const r = races.find((r) => r.id === race.id);

	if (r && r.favorite_selection) {
		race.favorite_selection = {
			...r.favorite_selection,
			...race.favorite_selection,
		};
	}

	return [mergeEntities(normalizeSelections(race.favorite_selection).entities), mergeUpcomingRace(race)];
};

export const mergeSelections = (selections) => {
	return mergeEntities(normalizeSelections(selections).entities);
};

export const mergeSelection = (selection) => {
	return mergeSelections([selection]);
};

// export const mergeUpComingRaceSelections = (races, race) => {
// 	let newRaces = races.map((item, i) => {
// 		let r = { ...item };
// 		if (r.id === race.id) {
// 			r.favorite_selection = {
// 				...r.favorite_selection,
// 				...race.favorite_selection,
// 			};
// 		}
// 		return r;
// 	});

// 	let fs = newRaces.reduce((acc, item) => {
// 		return [...acc, ...item.favorite_selection];
// 	}, []);

// 	return [
// 		updateEntities(normalizeSelections(fs).entities), 
// 		updateEntities(normalizeUpcomingRaces(newRaces).entities)
// 	];
// };
/**
 * show  upcoming all races without date for welcome page
 *
 * @param {*} race_type
 * @param {*} limit
 */
export const fetchGRSAllUpcomingRaces = (race_type, limit) => (dispatch) => {
	return get('racing/upcoming_races', {
		params: { race_type, limit },
	})
		.then((response) => {
			let upcomingRaces = response.data.data.map((upcomingRace) => ({
				...upcomingRace,
				requested_at: null,
			}));
			let fs = upcomingRaces.reduce((acc, item) => {
				return [...acc, ...item.favorite_selection];
			}, []);
			dispatch(updateEntities(normalizeSelections(fs).entities));
			let normalizedEntities = normalizeUpcomingRaces(upcomingRaces);
			// merge
			// dispatch(mergeEntities(normalizedEntities.entities));
			// reset
			dispatch(resetEntities(normalizedEntities.entities));
			response.upcomingRaceIds = normalizedEntities.result;
			return response;
		})
		.catch((error) => {
			//console.log(error);
			//console.error('Upcoming races request failed');
			return error;
		});
};
