// Loading Masks
export const BETBUILDER_HOME_SET_CONTAINER_LOADING_MASK = 'BETBUILDER_HOME_SET_CONTAINER_LOADING_MASK';

// Named Batch Actions
export const BETBUILDER_HOME_LOADING__BATCH = 'BETBUILDER_HOME_LOADING__BATCH';
export const BETBUILDER_HOME_DONE__BATCH = 'BETBUILDER_HOME_DONE__BATCH';
export const BETBUILDER_HOME_SET_RACE_LOADING_MASK = 'BETBUILDER_HOME_SET_RACE_LOADING_MASK';


export const GRS_SHOWING_NTJ_RACES = 'GRS_SHOWING_NTJ_RACES';
export const BETBUILDER_HOME_SET_SHOWING_NTJ_RACES = 'BETBUILDER_HOME_SET_SHOWING_NTJ_RACES';

export const BETBUILDER_HOME_SET_SELECTED_MEETING = 'BETBUILDER_HOME_SET_SELECTED_MEETING';

export const BETBUILDER_HOME_SET_FILTERS_BATCH = 'BETBUILDER_HOME_SET_FILTERS_BATCH';

export const BETBUILDER_HOME_SET_SHOWING_MEETINGS = 'BETBUILDER_HOME_SET_SHOWING_MEETINGS';

export const BETBUILDER_HOME_RESET_FILTERS = 'BETBUILDER_HOME_RESET_FILTERS';

export const BETBUILDER_HOM_SET_SELECTED_DATE = 'BETBUILDER_HOM_SET_SELECTED_DATE';