import React from 'react';
import cx from 'classnames/bind';
// get the default silk image

import {
	StyledGRSNtjRaceSelectionDetails__Silk,
	StyledGRSNtjRaceSelectionDetails__SilkLoading,
	StyledGRSNtjGreyhoundsRaceSelectionDetails__DetailsColumnLoading,
	StyledUpRaceSelectionDetails,
	// Styled__BlockPrice,
} from './UpRaceSelectionDetails.styled';

const UpRaceSelectionDetailsLoading = (props) => {
	const { className, size, width } = props;

	const componentClasses = cx({
		[className]: className,
	});
	/**
	 * Create the jockey or driver label dependant on the race type code
	 * NOTE: no need to cater for greyhounds as it is not rendered
	 * last_starts_string.substring(last_starts_string.length , 5); This is the get last _starts_string in jocky.
	 * @type {string}
	 */

	return (
		<StyledUpRaceSelectionDetails className={componentClasses} >
			<StyledGRSNtjRaceSelectionDetails__Silk size={size}>
				<StyledGRSNtjRaceSelectionDetails__SilkLoading />
			</StyledGRSNtjRaceSelectionDetails__Silk>
			<StyledGRSNtjGreyhoundsRaceSelectionDetails__DetailsColumnLoading size={size} width={width} style={{marginLeft:'5px'} }/>
			
		{/* <Styled__BlockPrice/>
		<Styled__BlockPrice/> */}
		</StyledUpRaceSelectionDetails>
	);
};

export default UpRaceSelectionDetailsLoading;
