/**
 * Created by Josef Frank on 2017/03/03.
 */
'use strict';

// Functions
const {
	denormalize,
	normalize,
	schema,
} = require('normalizr');

// Schemas
const commentSchema = new schema.Entity('comments');
const commentsSchema = new schema.Array(commentSchema);

/**
 * Denormalize comment entities back to a structure similar to the API response.
 *
 * @param entities
 * @returns {Array}
 */
const denormalizeComments = (entities) => {
	let keys = Object.keys(entities.comments);
	return denormalize(keys, commentsSchema, entities);
};

/**
 * Normalize the comment structure to suit our application.
 *
 * @param rawComments
 * @returns {Object}
 */
const normalizeComments = (rawComments) => {
	return normalize(rawComments, commentsSchema);
};

module.exports = {
	commentSchema,
	commentsSchema,
	denormalizeComments,
	normalizeComments,
};