import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';

import env from '../../../../common/EnvironmentVariables';
import { Link } from '@tbh/ui-kit';

import iosAppBadge from '../../../../images/download_on_the_app_store_badge.svg';
import androidAppBadge from '../../../../images/download_from_play_store_badge.svg';

const StyledDownloadAppButton = styled(Link)(
	(props) => css`
		label: DownloadAppButton;

		display: block;
		width: 135px;
		max-width: 135px; // IE
		height: 40px;
		background-size: contain;
		margin: auto;

		${props.platform === 'ios'
			? css`
					background: url(${iosAppBadge}) 0 0 no-repeat;
			  `
			: css`
					background: url(${androidAppBadge}) 0 0 no-repeat;
			  `};
	`,
);

const DownloadAppButton = (props) => {
	const { className, platform } = props;

	const componentClasses = cx({
		[className]: className,
	});

	const appDownloadEndpoint = env('appDownloadEndpoints')[platform];

	return <StyledDownloadAppButton className={componentClasses} href={appDownloadEndpoint} platform={platform} />;
};

DownloadAppButton.propTypes = {
	/** The platform version of the download button that we want to render */
	platform: PropTypes.oneOf(['ios', 'android']),

	/** Extra classes */
	className: PropTypes.string,
};

DownloadAppButton.defaultProps = {
	platform: 'android',
	className: '',
};

export default DownloadAppButton;
