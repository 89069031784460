// Check for offline mode
import env from './common/EnvironmentVariables';
import Cookies from 'universal-cookie';
const offlineConfig = env('offline', undefined);
const cookies = new Cookies();

let appIsOffline = false;
let backgroundColour = null;
let textColour = null;
let message = null;

if (offlineConfig) {
	appIsOffline = offlineConfig.active;
	backgroundColour = offlineConfig.backgroundColour;
	textColour = offlineConfig.textColour;
	message = offlineConfig.message;
}

import React from 'react';
import { hot } from 'react-hot-loader';

import RootApplication from './RootApplication';
import Offline from './components/features/Application/Offline/Offline';
let CookiesValue = cookies.get('GRSAuthLogin');


const App = () => {
	if (appIsOffline) {
		return <Offline backgroundColour={backgroundColour} textColour={textColour} message={message} />;
	}

	return <RootApplication />;
};

export default hot(module)(App);
