// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

// Components
import { spacings, ButtonGroup, Input, Link, SelectCountry, InputGroup } from '@tbh/ui-kit';
import SelectState from './SelectState';
import Select from '@tbh/ui-kit/src/elements/Forms/Select/Select';
import SelectOption from '@tbh/ui-kit/src/elements/Forms/Select/SelectOption';

const StyledAdditionalAddressFields__ManualAddress = styled(Link)(
	(props) => css`
		label: AdditionalAddressFields__ManualAddress;
		text-decoration: underline !important;
		flex: 0 1 calc(50% - ${spacings(props).compact}px);
	`,
);

const InputRow = styled.div`
	display: flex;
	width: 100%;
	gap: 10px;

	@media (max-width: 768px) {
		flex-direction: column;
		gap: 0;
	}
	`;

const AdditionalAddressFields = (props) => {
	const {
		t,
		handleChange,
		street,
		street_error,
		street_number,
		street_number_error,
		street_type,
		street_type_error,
		unit_type,
		unit_type_error,
		unit_number,
		unit_number_error,
		city,
		address,
		city_error,
		country,
		state,
		postcode,
		postcode_error,
		closeAdditionalAddressFields,
		addressVerification,
		fullstreet,
	} = props;

	const unitTypes = [
		{ label: 'None', value: '' },
		{ label: 'House', value: 'H' },
		{ label: 'Apartment', value: 'A' },
		{ label: 'Flat', value: 'F' },
		{ label: 'Unit', value: 'U' },
		{ label: 'Other', value: 'O' },
	];
	const streetTypes = [
		{ label: 'Street', value: 'ST' },
		{ label: 'Road', value: 'RD' },
		{ label: 'Alley', value: 'AL' },
		{ label: 'Approach', value: 'APP' },
		{ label: 'Arcade', value: 'ARC' },
		{ label: 'Avenue', value: 'AVE' },
		{ label: 'Boulevard', value: 'BVD' },
		{ label: 'Bypass', value: 'BYP' },
		{ label: 'Causeway', value: 'CSWY' },
		{ label: 'Circuit', value: 'CCT' },
		{ label: 'Circus', value: 'CIRC' },
		{ label: 'Close', value: 'CL' },
		{ label: 'Corner', value: 'CNR' },
		{ label: 'Court', value: 'CT' },
		{ label: 'Cove', value: 'CV' },
		{ label: 'Crescent', value: 'CRES' },
		{ label: 'Drive', value: 'DR' },
		{ label: 'End', value: 'END' },
		{ label: 'Esplanade', value: 'ESP' },
		{ label: 'Freeway', value: 'FWY' },
		{ label: 'Frontage', value: 'FTG' },
		{ label: 'Gardens', value: 'GDNS' },
		{ label: 'Glade', value: 'GLD' },
		{ label: 'Glen', value: 'GLN' },
		{ label: 'Grove', value: 'GR' },
		{ label: 'Heights', value: 'HTS' },
		{ label: 'Highway', value: 'HWY' },
		{ label: 'Lane', value: 'LN' },
		{ label: 'Link', value: 'LINK' },
		{ label: 'Loop', value: 'LOOP' },
		{ label: 'Mall', value: 'MALL' },
		{ label: 'Mews', value: 'MEWS' },
		{ label: 'Parade', value: 'PDE' },
		{ label: 'Park', value: 'PK' },
		{ label: 'Parkway', value: 'PKWY' },
		{ label: 'Place', value: 'PL' },
		{ label: 'Promenade', value: 'PROM' },
		{ label: 'Reserve', value: 'RES' },
		{ label: 'Ridge', value: 'RDG' },
		{ label: 'Rise', value: 'RISE' },
		{ label: 'Row', value: 'ROW' },
		{ label: 'Square', value: 'SQ' },
		{ label: 'Strip', value: 'STP' },
		{ label: 'Terrace', value: 'TCE' },
		{ label: 'Thoroughfare', value: 'THF' },
		{ label: 'Track', value: 'TRK' },
		{ label: 'View', value: 'VIEW' },
		{ label: 'Vista', value: 'VISTA' },
		{ label: 'Walk', value: 'WALK' },
		{ label: 'Walkway', value: 'WKY' },
		{ label: 'Way', value: 'WAY' },
		{ label: 'Yard', value: 'YD' },
		{ label: 'Other', value: 'OTHER' }
	];
	

	return (
		<div>
			<InputRow>
				<Select
					name="unit_type"
					onChange={handleChange}
					value={unit_type}
					label={t('Unit Type')}
					error={unit_type_error}
					margin="cozy"
					className={css`width:100%`}
				>
					{unitTypes.map((unitType) => (
						<SelectOption key={unitType.value} value={unitType.value}>
							{unitType.label}
						</SelectOption>
					))}
				</Select>
				<Input
					name="unit_number"
					onChange={handleChange}
					value={unit_number}
					margin="cozy"
					label={t('Unit Number')}
					error={unit_number_error}
					className={css`width:100%`}
				/>
				<Input
					name="street_number"
					onChange={handleChange}
					value={street_number}
					label={t('Street Number *')}
					error={street_number_error}
					margin="cozy"
					className={css`width:100%`}
				/>
			</InputRow>
			<InputRow>
				<Input
					name="street"
					onChange={handleChange}
					value={street}
					label={t('Street Name *')}
					error={street_error}
					margin="cozy"
					className={css`width:100%`}
				/>

				<Select
					name="street_type"
					onChange={handleChange}
					value={street_type}
					label={t('Street Type *')}
					error={street_type_error}
					margin="cozy"
			
				>
					{streetTypes.map((streetType) => (
						<SelectOption key={streetType.value} value={streetType.value}>
							{streetType.label}
						</SelectOption>
					))}
				</Select>
			</InputRow>

			<Input
				name="city"
				onChange={handleChange}
				value={city}
				label={t('AdditionalAddressFields__TownCity')}
				error={city_error}
				margin="cozy"
				placeholder={t('MustMatchID')}
			/>

			<Select 
				name="country"
				onChange={handleChange}
				value={country}
				label={t('Country *')}
				margin="cozy"
			>
				<SelectOption value="Australia">Australia</SelectOption>
			</Select>

			<SelectState onChange={handleChange} value={state} />

			<ButtonGroup flex>
				<Input
					className={css`
						flex: 1 1 0;
					`}
					name="postcode"
					type="text"
					onChange={handleChange}
					value={postcode}
					label={t('Profile__Postcode')}
					error={postcode_error}
					horizontalMargin="cozy"
					maxLength={8}
					placeholder={t('MustMatchID')}
					constraints={{
						post_code: {
							presence: true,
							length: {
								minimum: 4,
								message: t('AdditionalAddressFields__PostcodeConstraint'),
							},
						},
					}}
				/>

				{!addressVerification && (
					<StyledAdditionalAddressFields__ManualAddress
						action={closeAdditionalAddressFields}
						size="-2"
						align="center"
						className={css`
							text-decoration: underline !important;
						`}
					>
						{t('AdditionalAddressFields__AutoComplete')}
					</StyledAdditionalAddressFields__ManualAddress>
				)}
			</ButtonGroup>
		</div>
	);
};

AdditionalAddressFields.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Handle input change */
	handleChange: PropTypes.func.isRequired,

	/** Return to the auto complete address form */
	closeAdditionalAddressFields: PropTypes.func.isRequired,

	/** The entered street */
	street: PropTypes.string,

	/** Validation errors for the entered street */
	street_error: PropTypes.string,

	/** The entered city */
	city: PropTypes.string,

	/** Validation errors for the entered city */
	city_error: PropTypes.string,

	/** The entered country */
	country: PropTypes.string,

	/** The entered state */
	state: PropTypes.string,

	/** The entered postcode */
	postcode: PropTypes.string,

	/** Validation errors for the entered postcode */
	postcode_error: PropTypes.string,

	/** Is address verification being used */
	addressVerification: PropTypes.bool,

	fullstreet: PropTypes.string,
};

AdditionalAddressFields.defaultProps = {
	street: '',
	street_error: null,
	city: '',
	city_error: null,
	country: '',
	state: null,
	postcode: '',
	fullstreet: '',
	postcode_error: null,
	addressVerification: false,
};

export default withNamespaces()(AdditionalAddressFields);
