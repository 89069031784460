/**
 * Maps the keys in a webpack require.context to allow for them to be easily mapped pre and post optimisation/bundling
 *
 * Example: const currencyIconModules = requireAll(require.context('../../../../images/currency-icons', false));
 * @param requireContext
 * @returns {*}
 */
export const requireAll = (requireContext) =>
	requireContext.keys().reduce((acc, key) => {
		acc[key] = requireContext(key);

		return acc;
	}, {});
