import { fetchMeetingsAndRacesWithSelectionsForRace } from './MeetingActions';
import { fetchSportsByCompetitionId } from './SportsActions';
import { fetchCombinedMarketsGroupsWithSelections } from './MarketActions';
import { addRacingSelectionToBetPrompt, formatAndAddSportSelectionToBetPrompt } from '../../betPrompt/betPromptActions';
import { trackGaTransaction } from '../../trackingPixels/trackingActions';
import { getAuthenticatedUser } from '../../application/applicationSelectors';
import { centsAsDollars } from '../../../legacy/core/format';

import { get } from '../../../common/Ajax';

import { normalizeBets } from '../schemas/BetSchema';
import { normalizeTournamentBets } from '../schemas/TournamentBetSchema';

import { mergeEntities } from '../../../common/actions/actionHelpers';

import { BET_TYPE_GROUPS_ALL_OBJECT, BET_TYPE_SPORT } from '../constants/BetConstants';
/**
 * Shows a bet prompt given the trending bet data.
 *
 * @param bet
 */
const showBetPromptForTrendingBet = (bet) => (dispatch) => {
	if (bet.bet_type === BET_TYPE_SPORT) {
		dispatch(
			formatAndAddSportSelectionToBetPrompt(
				bet.selection_id,
				bet.market_id,
				bet.odds,
				bet.product_id,
				bet.product_type,
			),
		);
	} else {
		dispatch(addRacingSelectionToBetPrompt(bet));
	}
};

/**
 * Fetch active bets for the currently authenticated user
 */
const fetchActiveBets = () => async (dispatch) => {
	try {
		const response = await get('active-bets');
		dispatch(normalizeAndMergeBets(response.data.data));
	} catch (error) {
		return Promise.reject(error);
	}
};

const updatePendingBetStatus = (id, status, response, entities) => (dispatch, getState) => {
	// state.entities
	const bet = entities.bets[id];
	if (bet && response && response[0]) {
		bet.response = response;
		bet.response[0].status = status;
		entities.bets[id] = bet;
	}
	return dispatch(mergeEntities(entities));
};

const updateBetStatus = (id, status) => (dispatch, getState) => {
	const entities = getState().entities;
	const bet = entities.bets[id];

	if (bet) {
		bet.status = status;
		entities.bets[id] = bet;
	}

	return dispatch(mergeEntities(entities));
};

/**
 * Dispatch an action to normalize and merge a bet entity
 * @param data
 */
const normalizeAndMergeBet = (data) => normalizeAndMergeBets([data]);

/**
 * Dispatch an action to normalize and merge bet entities
 * @param data
 */
const normalizeAndMergeBets = (data) => mergeEntities(normalizeBets(data).entities);

/**
 * Dispatch an action to normalize and merge a tournament bet entity
 * @param data
 */
const normalizeAndMergeTournamentBet = (data) => (dispatch) => {
	dispatch(normalizeAndMergeTournamentBets([data]));
};

/**
 * Dispatch an action to normalize and merge tournament bet entities
 * @param data
 */
const normalizeAndMergeTournamentBets = (data) => (dispatch) => {
	dispatch(mergeEntities(normalizeTournamentBets(data).entities));
};

/**
 * Received array of bets
 *
 * @param bets
 * @returns {{type: *, bets: *}}
 */
export const receivedBets = (bets = []) => {
	let result = normalizeBets(bets);
	return mergeEntities(result.entities);
};

/**
 * Fetch user bets
 *
 * @returns {function(*)}
 *
 * @example
 *  store.dispatch(fetchUserBets())
 */
export const fetchUserBets = () => (dispatch) => {
	return get('bets').then((response) => {
		return dispatch(receivedBets(response.data));
	});
};

/**
 * Validate client side, if the exotic bet is valid
 * @param betType
 * @param selections
 * @param boxed
 * @returns {Promise}
 */
const exoticValidation = (betType, selections, boxed) => {
	// No selections provided
	if (!Array.isArray(selections) || selections.length === 0) {
		return Promise.reject('No selections.');
	}

	// No betType or invalid betType is provided
	if (!betType || !BET_TYPE_GROUPS_ALL_OBJECT[betType]) {
		return Promise.reject('Invalid bet type provided.');
	}

	const { minSelections, numberOfBoxes } = BET_TYPE_GROUPS_ALL_OBJECT[betType];

	// Create a unique selections list
	const uniqueRunners = selections.reduce((acc, selection) => acc.add(selection.id), new Set());

	// Check that the unique selections list is long enough to satisfy the min number of selections
	if (uniqueRunners.size < minSelections) {
		return Promise.reject(
			`Not enough selections for ${betType} bet. ${uniqueRunners.size} provided when ${minSelections} required.`,
		);
	}

	// Clone our selections
	let selectionsClone = [...selections];

	// Loop through the min number of boxes
	for (let i = 1; i <= numberOfBoxes; i++) {
		// Check that a selection has a valid position and that all positions are filled
		// NOTE: selection.position appears to be a string in the API
		const selectionsAtPosition = selectionsClone.filter((selection) => i == selection.position);
		if (selectionsAtPosition.length === 0) {
			return Promise.reject(`Not enough boxes for ${betType} bet.`);
		}

		// If it is not boxed, then the positions must be unique
		if (!boxed) {
			selectionsClone = selectionsClone.filter((selection) => {
				// Find a selection in the clone list that was found at a position
				const selectionMatch = selectionsAtPosition.find(
					(selPos) => selection.id === selPos.id && selection.position === selPos.position,
				);

				// If we have found a match, filter it out of the clone list
				return !selectionMatch;
			});
		}
	}

	// There should be no duplicate selections left if it was not boxed
	if (!boxed && selectionsClone.length > 0) {
		return Promise.reject(`Invalid selections for ${betType} bet.`);
	}

	return Promise.resolve();
};

/**
 * Place bet if the response status is successful
 * @param response
 * @returns {Promise}
 */
const betPlacement = (response) => (dispatch, getState) => {
	if (response.statusText === 'OK') {
		for (const bet of response.data.data) {
			const name = bet.bet_type === 'multi' ? 'BetSlipMultis' : 'BetSlip';
			const user = getAuthenticatedUser(getState());

			const id = `${bet.id}-${user.id}`;
			const transactionData = {
				revenue: centsAsDollars(bet.amount),
			};
			const itemsData = [
				{
					name: name,
					sku: `${name}-${bet.id}`,
					category: bet.bet_type === 'sport' ? 'Single' : bet.bet_type === 'multi' ? 'Multi' : bet.bet_type,
					price: centsAsDollars(bet.amount),
				},
			];

			dispatch(trackGaTransaction(id, transactionData, itemsData));
		}

		dispatch(normalizeAndMergeBets(response.data.data));
		return Promise.resolve();
	} else {
		if (!response.data.data) {
			return Promise.reject('Unknown Error has occurred');
		}

		// Return bet response
		return Promise.reject(response.data.data);
	}
};

/**
 * Check if a certain bet with a matching type or product, etc, has been placed
 * @param key
 * @param value
 * @param bets
 * @param products
 * @returns {Number|boolean}
 */
const isBetPlacedForProduct = (key, value, bets = [], products = []) => {
	return (
		bets.length &&
		bets.some((bet) => {
			const product =
				products &&
				products.find((product) => product.product_id === bet.product_id && product.bet_type === bet.bet_type);
			return product && product[key] === value;
		})
	);
};

/**
 * Load the data needed to handle a list of selections
 *
 * @param selectionsList
 * @returns {Function}
 */
const loadDataForSelections = (selectionsList = []) => (dispatch) => {
	const requestedRaces = new Set();
	const requestedEvents = new Set();
	const requestedCompetitions = new Set();
	selectionsList.forEach((selection) => {
		if (selection.bet_type === BET_TYPE_SPORT || selection.type === BET_TYPE_SPORT) {
			const competitionId = selection.event ? selection.event.competition_id : selection.competition_id;
			if (competitionId) {
				if (!requestedCompetitions.has(competitionId)) {
					dispatch(fetchSportsByCompetitionId(competitionId));
					requestedCompetitions.add(competitionId);
				}
			}

			const eventId = selection.event ? selection.event.id : selection.event_id;
			if (eventId) {
				if (!requestedEvents.has(eventId)) {
					dispatch(fetchCombinedMarketsGroupsWithSelections(eventId));
					requestedEvents.add(eventId);
				}
			}
		} else {
			const raceId = selection.race ? selection.race.id : selection.race_id;
			const meetingId = selection.meeting ? selection.meeting.id : selection.meeting_id;
			if (raceId && meetingId) {
				if (!requestedRaces.has(raceId)) {
					dispatch(fetchMeetingsAndRacesWithSelectionsForRace(meetingId, raceId));
					requestedRaces.add(raceId);
				}
			}
		}
	});
};

export {
	betPlacement,
	exoticValidation,
	fetchActiveBets,
	showBetPromptForTrendingBet,
	normalizeAndMergeBet,
	normalizeAndMergeBets,
	normalizeAndMergeTournamentBet,
	normalizeAndMergeTournamentBets,
	isBetPlacedForProduct,
	loadDataForSelections,
	updateBetStatus,
	updatePendingBetStatus,
};
