// Loading Masks
export const FEATURE_HOME_SET_CONTAINER_LOADING_MASK = 'FEATURE_HOME_SET_CONTAINER_LOADING_MASK';
export const FEATURE_HOME_SET_MEETING_LOADING_MASK = 'FEATURE_HOME_SET_MEETING_LOADING_MASK';
export const FEATURE_HOME_SET_RACE_LOADING_MASK = 'FEATURE_HOME_SET_RACE_LOADING_MASK';



export const GRS_SHOWING_FEATUES_RACES = 'GRS_SHOWING_FEATUES_RACES';


// Named Batch Actions
export const FEATURE_HOME_NAVIGATE_TO_MEETING__BATCH = 'FEATURE_HOME_NAVIGATE_TO_MEETING__BATCH';
export const FEATURE_HOME_NAVIGATE_TO_RACE__BATCH = 'FEATURE_HOME_NAVIGATE_TO_RACE__BATCH';
export const FEATURE_HOME_NAVIGATE_TO_HOME__BATCH = 'FEATURE_HOME_NAVIGATE_TO_HOME__BATCH';
export const FEATURE_HOME_LOADING__BATCH = 'FEATURE_HOME_LOADING__BATCH';
export const FEATURE_HOME_DONE__BATCH = 'FEATURE_HOME_DONE__BATCH';
export const FEATURE_RACING_HOME_NAVIGATE_TO_HOME__BATCH = 'FEATURE_RACING_HOME_NAVIGATE_TO_HOME__BATCH';

// Data
export const FEATURE_HOME_SET_SELECTED_BET_TYPE = 'FEATURE_HOME_SET_SELECTED_BET_TYPE';
export const FEATURE_HOME_SET_SELECTED_DATE = 'FEATURE_HOME_SET_SELECTED_DATE';
export const FEATURE_HOME_RESET_FILTERS = 'FEATURE_HOME_RESET_FILTERS';
export const FEATURE_HOME_SET_EXOTIC_POOLS = 'FEATURE_HOME_SET_EXOTIC_POOLS';
export const FEATURE_HOME_SET_SHOWING_NTJ_RACES = 'FEATURE_HOME_SET_SHOWING_NTJ_RACES';

// Exotic Pool Filters
export const RACING_EXOTIC_QUADDIE_TOGGLE = 'RACING_EXOTIC_QUADDIE_TOGGLE';
export const RACING_EXOTIC_DAILYDOUBLE_TOGGLE = 'RACING_EXOTIC_DAILYDOUBLE_TOGGLE';
export const RACING_EXOTIC_SELECTIONS_TOGGLE = 'RACING_EXOTIC_SELECTIONS_TOGGLE';