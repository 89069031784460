import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { ui, spacings } from '@tbh/ui-kit';

import { Circle, FlexText, Text, PlotElements } from '@tbh/ui-kit';

const StyledSpeedmapHeader__Titles = styled(FlexText)`
	${(props) => {
		return css`
			label: SpeedmapHeader__Titles;

			margin-bottom: ${spacings(props).cozy}px;
		`;
	}};
`;

const StyledSpeedmapHeader__X_Axis = styled(PlotElements)`
	${(props) => {
		return css`
			label: SpeedmapHeader__X_Axis;

			margin-bottom: ${spacings(props).cozy}px;
		`;
	}};
`;

const StyledSpeedmapHeader__Barrier = styled(Text)`
	${(props) => {
		return css`
			label: SpeedmapHeader__Barrier;

			width: ${spacings(props).cozy}px;
			margin-right: ${spacings(props).compact}px;
		`;
	}};
`;

const StyledSpeedmapHeader__Line = styled('div')`
	${(props) => {
		return css`
			label: SpeedmapHeader__Line;

			width: 100%;
			text-align: center;
			position: relative;

			&::before {
				content: '';
				position: absolute;
				z-index: -1;
				top: 50%;
				left: 0;
				border-top: 1px solid ${ui(props).color_black};
				background: ${ui(props).color_black};
				width: 100%;
				transform: translateY(-50%);
			}
		`;
	}};
`;

const StyledSpeedmapHeader__Circle = styled(Circle)`
	${(props) => {
		return css`
			label: SpeedmapHeader__Circle;

			border: 2px solid ${ui(props).color_black};
			background-color: ${ui(props).color_2};
		`;
	}};
`;

const StyledSpeedmapHeader__Triangle = styled('div')`
	${(props) => {
		return css`
			label: SpeedmapHeader__Triangle;

			width: 0;
			height: 0;
			margin-top: -1px;
			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent;
			border-left: 15px solid ${ui(props).color_black};
		`;
	}};
`;

const SpeedmapHeader = (props) => {
	const { t, className, totalStages } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<div className={componentClasses}>
			<StyledSpeedmapHeader__Titles>
				<Text size="-1" strong>
					{t('SpeedmapHeader__Barrier')}
				</Text>
				<Text size="-1" strong>
					{t('SpeedmapHeader__Lead')}
				</Text>
			</StyledSpeedmapHeader__Titles>

			{totalStages > 1 && (
				<StyledSpeedmapHeader__X_Axis align="start">
					<StyledSpeedmapHeader__Barrier align="center" strong>
						#
					</StyledSpeedmapHeader__Barrier>
					<StyledSpeedmapHeader__Line>
						<FlexText flexGrow={false}>
							{[...Array(totalStages - 1).keys()].map((num) => (
								<StyledSpeedmapHeader__Circle key={num} size={18} />
							))}
							<StyledSpeedmapHeader__Triangle />
						</FlexText>
					</StyledSpeedmapHeader__Line>
				</StyledSpeedmapHeader__X_Axis>
			)}
		</div>
	);
};

SpeedmapHeader.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,

	/** The total number of stages. That will be used to calculate the number of circles to display in the line */
	totalStages: PropTypes.number,
};

SpeedmapHeader.defaultProps = {
	className: '',
	totalStages: 10,
};

export default withNamespaces()(SpeedmapHeader);
