'use strict';

import { denormalize, normalize } from 'normalizr';
import { navigationItemsSchema } from './Entities';
import {buildEntitySchema} from './relationships/relationshipSelectorHelpers';

export const getNavigationItemsEntities = buildEntitySchema(navigationItemsSchema);


/**
 * Normalize an array of navigation items
 *
 * @param navigationItems
 */
export const normalizeNavigationItems = (navigationItems = []) => {
	return normalize(navigationItems, navigationItemsSchema);
};

/**
 * Denormalize Navigation Items
 *
 * @param entities
 * @param keys
 */
export const denormalizeNavigationItems = (entities, keys) => {
	return denormalize(keys || Object.keys(entities.navigationItems), navigationItemsSchema, entities).filter(
		(item) => !!item,
	);
};
