// Loading Masks
export const SET_DEPOSIT_LOADING_MASK = 'SET_DEPOSIT_LOADING_MASK';
export const SET_VERIFICATION_LOADING_MASK = 'SET_VERIFICATION_LOADING_MASK';
export const SET_LOADING_CARDS = 'SET_LOADING_CARDS';

export const SET_LAST_DEPOSIT_ID = 'SET_LAST_DEPOSIT_ID';

export const SET_DEPOSIT_ERROR_MESSAGES = 'SET_DEPOSIT_ERROR_MESSAGES';
export const SET_START_VERIFICATION = 'SET_START_VERIFICATION';
export const SET_CARD_VERIFICATION_ATTEMPTS_LEFT = 'SET_CARD_VERIFICATION_ATTEMPTS_LEFT';
export const SET_CARD_VERIFICATION_SUCCESS = 'SET_CARD_VERIFICATION_SUCCESS';
export const CLEAR_CARD_VERIFICATION_STATE = 'CLEAR_CARD_VERIFICATION_STATE';

export const DEPOSIT_DONE_BATCH = 'DEPOSIT_DONE_BATCH';

export const SET_PROMO_CODE = 'SET_PROMO_CODE';
export const CLEAR_DEPOSITS_DATA = 'CLEAR_DEPOSITS_DATA';

export const SET_BPAY_DATA = 'SET_BPAY_DATA';
export const CLEAR_BPAY_DATA = 'CLEAR_BPAY_DATA';

export const SET_BANK_EFT_DATA = 'SET_BANK_EFT_DATA';
export const CLEAR_BANK_EFT_DATA = 'CLEAR_BANK_EFT_DATA';


export const SET_DEPOSIT_CC_TOKEN = 'SET_DEPOSIT_CC_TOKEN';