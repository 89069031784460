import React from 'react';
import Profile from '../../../../../components/features/UserAccount/Profile/Profile';
import { SERVER_DATE_FORMAT } from '../../../../../common/constants/DateFormats';
import moment from 'moment';
import { Text } from '@tbh/ui-kit';


const PersonalDetailsSection = ({
	address,
	country,
	email,
	dob,
	first_name,
	last_name,
	postcode,
	state,
	street,
	suburb,
	username,
	city,
}) => {
	const [form, setForm] = React.useState({
		email: email,
		first_name: first_name,
		last_name: last_name,
		dob: dob,
		address: address,
		suburb: suburb,
		state: state,
		postcode: postcode,
		country: country,
	});

	return (
		<div>
			<Text size="1">Personal Details</Text>

			<Profile
				size={{}}
				onCompleteVerification={() => {}}
				city={suburb}
				country={country}
				dob={moment(dob, SERVER_DATE_FORMAT).format(SERVER_DATE_FORMAT)}
				email={email}
				first_name={first_name}
				last_name={last_name}
				msisdn={false}
				postcode={postcode}
				state={state}
				street={address}
				username={username}
				userIsVerified={false}
				formDisabled
			/>
		</div>
	);
};

export default PersonalDetailsSection;
