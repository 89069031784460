import React from 'react';
import PropTypes from 'prop-types';

import AclContext from '../../../../../contexts/AclContext';

import { DEFAULT_CURRENCY_CONFIGURATION } from '../../../../../containers/Application/Currency/CurrencyConstants';

const CurrencyNameDisplay = ({ type }) => {
	return (
		<AclContext.Consumer>
			{(acl) => {
				const currencyConfiguration = acl.currencyConfiguration || DEFAULT_CURRENCY_CONFIGURATION;
				return currencyConfiguration[`${type}Name`] || DEFAULT_CURRENCY_CONFIGURATION[`${type}Name`];
			}}
		</AclContext.Consumer>
	);
};

CurrencyNameDisplay.propTypes = {
	/** Type of currency to display */
	type: PropTypes.oneOf(['currency', 'bonusCurrency', 'tournamentCurrency']),
};

CurrencyNameDisplay.defaultProps = {
	type: 'currency',
};

export default CurrencyNameDisplay;
