export const SPORTS_SUB_APP = 'Sports';
//export const SPORTS_BASE_URL = 'sports-v3';
export const SPORTS_BASE_URL = 'sports';
export const SPORTS_UPCOMING_SUB_APP = 'SportUpcoming';
export const SPORTS_FEATURE_RACING = 'Feature Racing';
export const SPORT_UPCOMING_BASE_URL = 'sports/Upcoming';
export const SPORT_NEW_UI = 'Cricket';
export const SPORT_NEW_UI_BASE_URL = 'cricket';
export const SPORT_CRICKET_SUB_APP = 'SportCricket';

