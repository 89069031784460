// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';

import { media, spacings, FlexText, Image, Text, PlotElements } from '@tbh/ui-kit';

// Components

// Constants
import { SHORT_DATE_FORMAT } from '../../../../../common/constants/DateFormats';
import {
	GENERIC_SILK,
	RACING_BET_TYPE_MARGIN,
	RACING_BET_TYPE_PLACE,
	RACING_BET_TYPE_WIN,
} from '../../../../../common/constants/Racing';
import { DST_EVENT_NAME, DST_MARKET_NAME } from '../../../../../common/constants/DSTConstants';

const cssBetSummaryBetItem__Text = css`
	label: BetSummaryBetItem__Text;
	flex: 1 0 50%;
`;

const cssLegStatusWon__Text = css`
	color: #32be60;
	flex: 1 0 50%;
`;

const cssLegStatusLost__Text = css`
	color: #c72128;
	flex: 1 0 50%;
`;

const StyledBetSummaryBetItem___SilkImage = styled(Image)(
	(props) => css`
		label: BetSummaryBetItem___SilkImage;

		padding-right: ${spacings(props).tight}px;
	`,
);

const StyledBetSummaryBetItem___Date = styled(Text)(
	css`
		label: BetSummaryBetItem___Date;

		white-space: nowrap;
		display: block;
	`,
	(props) =>
		media(
			css`
				margin-left: ${spacings(props).cozy}px;
				display: inline;
			`,
			480,
			{ sizeKey: 'mediaSize' },
		),
);

const BetSummaryBetItem = (props) => {
	const {
		t,
		bet_type,
		className,
		date,
		event_id,
		eventName,
		competition_id,
		leg_status,
		marginButt,
		market_name,
		onBetClick,
		resulted,
		selection_name,
		selectionOdds,
		silk,
		winnings,
		size,
		event_type,
		selectedTab,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	const handleBetClick = () => {
		//onBetClick(bet_type, competition_id, event_id);
		// onBetClick(bet_type, event_type, date, competition_id, event_id); // edit by 18May2020
	};

	let renderLegStusByColor;
	if (leg_status === 'Winning') {
		renderLegStusByColor = (
			<Text className={cssLegStatusWon__Text} size="-2" type="light" capitalize>
				Won
			</Text>
		);
	} else if (leg_status === 'Losing') {
		renderLegStusByColor = (
			<Text className={cssLegStatusLost__Text} size="-2" type="light" capitalize>
				{t(leg_status)}
			</Text>
		);
	} else if (leg_status === 'Pending') {
		renderLegStusByColor = (
			<Text className={cssBetSummaryBetItem__Text} size="-2" type="light" capitalize>
				Accepted
			</Text>
		);
	} else {
		renderLegStusByColor = (
			<Text className={cssBetSummaryBetItem__Text} size="-2" type="light" capitalize>
				{t(leg_status)}
			</Text>
		);
	}
	return (
		<div className={componentClasses} onClick={handleBetClick}>
			<FlexText>
				<Text className={cssBetSummaryBetItem__Text} size="-1" strong>
					{silk &&
						(bet_type === RACING_BET_TYPE_WIN ||
							bet_type === RACING_BET_TYPE_PLACE ||
							bet_type === RACING_BET_TYPE_MARGIN) && (
							<StyledBetSummaryBetItem___SilkImage src={silk} substituteImageSrc={GENERIC_SILK} width={20} />
						)}
					{selection_name}
				</Text>
				<Text className={cssBetSummaryBetItem__Text} size="-1" strong>
					{selectionOdds}
				</Text>
			</FlexText>

			<FlexText>
				<Text className={cssBetSummaryBetItem__Text} type="light" size="-2" strong capitalize>
					{market_name === DST_MARKET_NAME ? '' : market_name}
				</Text>
				{selectedTab && selectedTab === 'Active' ? (
					<React.Fragment>
						{winnings && (
							<Text className={cssBetSummaryBetItem__Text} size="-2" strong>
								<PlotElements align="end">
									<span>
										{resulted ? t('Won') : t('WinPotential')}
										&nbsp;
									</span>
									<Text size="-2" type="success" tag="span" strong>
										{winnings}
									</Text>
								</PlotElements>
							</Text>
						)}
					</React.Fragment>
				) : (
					renderLegStusByColor
				)}
			</FlexText>

			{marginButt && (
				<Text type="light" size="-2" strong>
					{t('BetSummaryBetItem__MarginMessage', { marginButt })}
				</Text>
			)}

			<FlexText>
				<Text className={cssBetSummaryBetItem__Text} size="-2">
					{eventName !== DST_EVENT_NAME && (
						<Text size="-2" tag="span">
							{eventName}
						</Text>
					)}
					<StyledBetSummaryBetItem___Date mediaSize={size} size="-2" type="light" tag="span">
						{moment(date).format(SHORT_DATE_FORMAT)}
					</StyledBetSummaryBetItem___Date>
				</Text>

				{selectedTab && selectedTab === 'Active' ? renderLegStusByColor : ''}
			</FlexText>
		</div>
	);
};

BetSummaryBetItem.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Competition id */
	competition_id: PropTypes.number.isRequired,

	/** Action for when clicking the node */
	onBetClick: PropTypes.func.isRequired,

	/** Selection name */
	selection_name: PropTypes.string.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** Bet type */
	bet_type: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,

	/** Event date */
	date: PropTypes.string,

	/** Event id */
	event_id: PropTypes.number,

	/** Event name */
	eventName: PropTypes.string,

	event_type: PropTypes.string,

	/** Leg status */
	leg_status: PropTypes.string,

	/** Margin Butt length */
	marginButt: PropTypes.number,

	/** Market name */
	market_name: PropTypes.string,

	/** If event is resulted or not */
	resulted: PropTypes.bool,

	/** Bet selection odds */
	selectionOdds: PropTypes.node,

	/** Bet selection silk */
	silk: PropTypes.string,

	/** Bet winnings */
	winnings: PropTypes.node,
};

BetSummaryBetItem.defaultProps = {
	bet_type: null,
	className: '',
	date: null,
	event_id: null,
	eventName: null,
	leg_status: null,
	marginButt: null,
	market_name: null,
	resulted: false,
	selectionOdds: null,
	silk: null,
	winnings: null,
	event_type: null,
};

export default withNamespaces()(BetSummaryBetItem);
