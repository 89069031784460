import { brand, Text, ui } from '@tbh/ui-kit';
import React from 'react';
// import countdown from 'countdown';
import styled, { css } from 'react-emotion';
import {
	RACE_ABANDONED_STATUS,
	RACE_AT_THE_PARADING_STATUS,
	RACE_AT_THE_POST_STATUS,
	RACE_CLOSED_STATUS,
	RACE_DELETED_STATUS,
	RACE_FALSE_START_STATUS,
	RACE_FINISHED_STATUS,
	RACE_GOING_BEHIND_STATUS,
	RACE_GOING_DOWN_STATUS,
	RACE_INTERIM_STATUS,
	RACE_PAID_STATUS,
	RACE_PAYING_STATUS,
	RACE_PROTEST_DISMISSED,
	RACE_PROTEST_STATUS,
	RACE_PROTEST_UPHELD,
	RACE_SELLING_STATUS,
	RACE_SUSPENDED_STATUS,
} from '../../../../../common/constants/Racing';
import PusherSubscriberGRSNjRaceChannel from '../../PusherSubscribers/PusherSubscriberGRSNjRaceChannel';
import PusherSubscriberNjUpcomingRaceChannel from '../../PusherSubscribers/PusherSubscriberNjUpcomingRaceChannel';
import { countdown, countdownLessThan2Min } from '../../../../../legacy/core/format';
import MyBetsIcon from '../../../../../containers/Application/MyBets/MyBetsIcon/MyBetsIcon';
import { buildWithDateGRSRouteToRace } from '../../../../../routes/RacingNew';
import { STATUS_COLORS } from '../../../../../constants/themes';

export const RaceItemContainer = styled('a')(
	(props) => css`
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		width: 100%;
		height: 100%;
		cursor: pointer;
		border-radius: 4px;
		background-color: #ced4da;

		&:hover {
			text-decoration:none;
			background-color: #a9b0be;
		}
		@keyframes slide {
			0% {
				background: #f27207;
			}
			25% {
				background: #fa971c;
			}
			50% {
				background: #f77f00;
			}
			75% {
				background: #f98f13;
			}
			100% {
				background: #f48c36;
			}
		}
		${props.isNav &&
			css`
				border: 1px solid #dddddd;
			`}
		${props.lessThanTwoMinutes &&
			css`
				color: black;
				animation: slide 1s infinite;
			`}

		${props.fixed_odds_enabled &&
			css`
				&:before {
					content: '';
					display: block;
					position: absolute;
					border-bottom: 10px solid ${ui(props).color_transparent};
					border-left: 10px solid ${brand(props).color_2};
					top: 0;
					left: 0;
					display: none;
				}
			`};

		${props.global_tote_available &&
			css`
				&:after {
					content: '';
					display: block;
					position: absolute;
					border-bottom: 6px solid ${ui(props).color_transparent};
					border-left: 6px solid ${brand(props).color_1};
					top: 0;
					left: 0;
				}
			`};
		${props.status === 'paying' &&
			css`
				color: ${brand(props).paying_text_color ? brand(props).paying_text_color : STATUS_COLORS.paying_text_color};
				background-color: ${brand(props).paying_bg_color
					? brand(props).paying_bg_color
					: STATUS_COLORS.paying_bg_color};
			`};
		${props.status === 'interim' &&
			css`
				background-color: ${brand(props).interim_bg_color
					? brand(props).interim_bg_color
					: STATUS_COLORS.interim_bg_color};
			`};

		${props.status === 'closed' &&
			css`
				color: ${brand(props).closed_text_color ? brand(props).closed_text_color : STATUS_COLORS.closed_text_color};
				background-color: ${brand(props).closed_bg_color
					? brand(props).closed_bg_color
					: STATUS_COLORS.closed_bg_color};
			`};
		${props.status === 'abandoned' &&
			css`
				color: ${brand(props).abandoned_text_color
					? brand(props).abandoned_text_color
					: STATUS_COLORS.abandoned_text_color};
				background-color: ${brand(props).abandoned_bg_color
					? brand(props).abandoned_bg_color
					: STATUS_COLORS.abandoned_bg_color};
			`};
		${props.status === 'deleted' &&
			css`
				color: ${brand(props).deleted_text_color ? brand(props).deleted_text_color : STATUS_COLORS.deleted_text_color};
				background-color: ${brand(props).deleted_bg_color
					? brand(props).deleted_bg_color
					: STATUS_COLORS.deleted_bg_color};
			`};
			${props.status === 'paid' &&
				css`
					color: ${brand(props).paid_text_color ? brand(props).paid_text_color : STATUS_COLORS.paid_text_color};
					background-color: ${brand(props).paid_bg_color ? brand(props).paid_bg_color : STATUS_COLORS.paid_bg_color};

					&:hover {
						/* Inherit the default hover styles */
						background-color: #8ba5d2;
						text-decoration: none;
					}
				`};


		${props.status === 'selling' &&
			css`
				color: ${brand(props).selling_text_color ? brand(props).selling_text_color : STATUS_COLORS.selling_text_color};
				background-color: ${brand(props).selling_bg_color
					? brand(props).selling_bg_color
					: STATUS_COLORS.selling_bg_color};
			`};
		${props.status === 'parading' &&
			css`
				color: black;
				animation: slide 1s infinite;
				color: ${brand(props).parading_text_color
					? brand(props).parading_text_color
					: STATUS_COLORS.parading_text_color};
				background-color: ${brand(props).parading_bg_color
					? brand(props).parading_bg_color
					: STATUS_COLORS.parading_bg_color};
			`};
		${props.status === 'at_the_post' &&
			css`
				color: ${brand(props).at_the_post_text_color
					? brand(props).at_the_post_text_color
					: STATUS_COLORS.at_the_post_text_color};
				animation: slide 1s infinite;
			`};
		${props.status === 'going_behind' &&
			css`
				color: ${brand(props).going_behind_text_color
					? brand(props).going_behind_text_color
					: STATUS_COLORS.going_behind_text_color};
				animation: slide 1s infinite;
			`};
		${props.isExoticRace &&
			css`
				background-color: #e5e4e2;
			`}		
	`,
);

const StyledRaceItem__MyBetsIcon = styled(MyBetsIcon)(
	(props) => css`
		label: RaceItem__MyBetsIcon;

		position: absolute;
		left: 2px;
		${props.status === 'selling'
			? `
    `
			: `
        bottom: 8px;
    `}

		display: inline-block;
		// height: 12px;
		text-align: center;
		margin: auto;
	`,
);

export const RaceStatusColors = {
	[RACE_SELLING_STATUS]: 'selling',
	[RACE_AT_THE_PARADING_STATUS]: 'parading',
	[RACE_GOING_BEHIND_STATUS]: 'going_behind',
	[RACE_GOING_DOWN_STATUS]: 'going_behind',
	[RACE_AT_THE_POST_STATUS]: 'at_the_post',
	[RACE_CLOSED_STATUS]: 'closed',
	[RACE_FINISHED_STATUS]: 'closed',
	[RACE_FALSE_START_STATUS]: 'closed',
	[RACE_INTERIM_STATUS]: 'paying',
	[RACE_PROTEST_STATUS]: 'paying',
	[RACE_PROTEST_UPHELD]: 'paying',
	[RACE_PROTEST_DISMISSED]: 'paying',
	[RACE_PAYING_STATUS]: 'paying',
	[RACE_PAID_STATUS]: 'paid',
	[RACE_ABANDONED_STATUS]: 'abandoned',
	[RACE_SUSPENDED_STATUS]: 'abandoned',
	[RACE_DELETED_STATUS]: 'abandoned',
};

export const StatusPattern = {
	[RACE_SELLING_STATUS]: 'selling',
	[RACE_AT_THE_PARADING_STATUS]: 'selling',
	[RACE_GOING_BEHIND_STATUS]: 'selling',
	[RACE_GOING_DOWN_STATUS]: 'selling',
	[RACE_AT_THE_POST_STATUS]: 'selling',
	[RACE_CLOSED_STATUS]: 'closed',
	[RACE_FINISHED_STATUS]: 'closed',
	[RACE_FALSE_START_STATUS]: 'closed',
	[RACE_INTERIM_STATUS]: 'paying',
	[RACE_PROTEST_STATUS]: 'paying',
	[RACE_PROTEST_UPHELD]: 'paying',
	[RACE_PROTEST_DISMISSED]: 'paying',
	[RACE_PAYING_STATUS]: 'paying',
	[RACE_PAID_STATUS]: 'paid',
	[RACE_ABANDONED_STATUS]: 'abandoned',
	[RACE_SUSPENDED_STATUS]: 'abandoned',
	[RACE_DELETED_STATUS]: 'abandoned',
};

const statusNameMap = {
	PARADING: { T: 'Parading', G: 'Parading', H: 'Parading' },
	GOING_DOWN: { T: '', G: '', H: '' },
	AT_THE_POST: { T: 'Barriers', G: 'Boxes', H: '' },
	GOING_BEHIND: { T: 'Loading', G: 'Loading', H: 'Moving Up' },
	LONG_DELAY: { T: 'Long Delay', G: 'Long Delay', H: 'Long Delay' },
	SHORT_DELAY: { T: 'Short Delay', G: 'Short Delay', H: 'Short Delay' },
	CLOSED: { T: 'Closed', G: 'Closed', H: 'Closed' },
	ABANDONED: { T: 'ABND', G: 'ABND', H: 'ABND' },
	PROTEST: { T: 'Protest', G: '', H: 'Protest' },
	PROTEST_UPHELD: { T: 'Upheld', G: '', H: 'Upheld' },
	PROTEST_DISMISSED: { T: 'Dismissed', G: '', H: 'Dismissed' },
	FALSE_START: { T: 'False Start', G: 'False Start', H: 'False Start' },
	FINISHED: { T: 'Finished', G: 'Finished', H: 'Finished' },
	INTERIM: { T: 'Interim', G: 'Interim', H: 'Interim' },
	PAYING: { T: 'Paying', G: 'Paying', H: 'Paying' },
	PAID: { T: 'Paid', G: 'Paid', H: 'Paid' },
};

// Function to get the status name based on race type
const getStatusName = (status, raceType) => {

	if(!status) return;

	if (!statusNameMap[status.toUpperCase()]) {
		return status.replaceAll('_', ' ').toUpperCase();
	}
	// Fallback to original status if mapping is not found
	return statusNameMap[status.toUpperCase()][raceType].toUpperCase();
};

export const validadeSellingStates = {
	[RACE_AT_THE_PARADING_STATUS]: true,
	[RACE_GOING_BEHIND_STATUS]: true,
	[RACE_GOING_DOWN_STATUS]: true,
	[RACE_AT_THE_POST_STATUS]: true,
};

const NewRaceItem = ({
	t,
	className,
	id,
	number,
	result_string,
	eventStart,
	race_site_link,
	fixed_odds_enabled,
	global_tote_available,
	hasBets,
	status,
	onClickItem = () => {},
	showRaceLabel,
	isNav,
	isCompact,
	size,
	prize_money,
	distance,
	name,
	type,
	date,
	isExoticRace,
	status_display,
	meetingId,
	meetingName,
}) => {
	const lowerCaseStatusDisplay = status_display ? status_display.toLowerCase() : '';
	const lowerCaseStatus = status ? status.toLowerCase() : '';

	const statusIsSelling = lowerCaseStatus === RACE_SELLING_STATUS;
	// Checks if the status is one of the principals
	// Paid - Selling - Closed - Paying
	const isNormalStatus = StatusPattern[lowerCaseStatusDisplay] === lowerCaseStatus;
	const statusColor = RaceStatusColors[isNormalStatus ? lowerCaseStatusDisplay : lowerCaseStatus];

	const handleClickItem = (e) => {
		e.preventDefault();
		onClickItem(type, date, id);
	};

	return (
		<RaceItemContainer
			href={buildWithDateGRSRouteToRace(type, date, { id: meetingId, name: meetingName, type, date }, { id, number })}
			role="link"
			aria-disabled="true"
			status={statusColor}
			lessThanTwoMinutes={countdownLessThan2Min(eventStart) && statusIsSelling}
			fixed_odds_enabled={fixed_odds_enabled}
			global_tote_available={global_tote_available}
			size={size}
			onClick={handleClickItem}
			isNav={isNav}
			isExoticRace={isExoticRace}
		>
			{hasBets && <StyledRaceItem__MyBetsIcon status={status} />}
			<PusherSubscriberNjUpcomingRaceChannel raceId={id} />
			<PusherSubscriberGRSNjRaceChannel raceId={id} />

			{statusIsSelling ? (
				<Text
					size="-3"
					align="center"
					className={css`
						display: flex;
						flex-direction: column;
						overflow: hidden;
					`}
				>
					<span style={{ display: 'block', fontWeight: '600' }}>
						{showRaceLabel && `R${number}`} {isNav && `${countdown(eventStart)}`}
					</span>
					<span>{!isNav && `${countdown(eventStart)}`}</span>
					<span style={{ fontSize: '9px' }}>
						{validadeSellingStates[lowerCaseStatusDisplay] && getStatusName(status_display, type)}
					</span>
				</Text>
			) : (
				<Text size="-3" align="center">
					{result_string && status != RACE_CLOSED_STATUS && (
						<span style={{ display: 'block', fontWeight: '600' }}>
							{showRaceLabel && `R${number} `} {result_string}
						</span>
					)}
					<span>{getStatusName(isNormalStatus ? status_display : status, type)}</span>
				</Text>
			)}
		</RaceItemContainer>
	);
};

export default NewRaceItem;
