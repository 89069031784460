import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { css } from 'react-emotion';

// Components
import {
	StyledCarouselItemV3_header_content,
	StyledCarouselItemV4,
	StyledCarouselItemV3_hedar_left_content,
	StyledCarouselItemV3_right_hedear_content,
	StyledCarouselItemV3_race_closed_fristCircle,
	StyledCarouselItemV3_race_closed_secondCircle,
	StyledCarouselItemV3_race_post,
	StyledCarouselItemV4_Race_Number,
	StyledCarouselItemV4_Race_name,
} from './CarouselItemV4.styled';
import {
	Text,
	//  Icon,
} from '@tbh/ui-kit';
import { FONT_SIZE_10 } from '../../../../store/application/applicationConstants';
import CountryImage from '../../Racing/CountryImage/CountryImage';
import RacingIcon from '../../Racing/RacingIcon/RacingIcon';

const CarouselItemV4 = (props) => {
	const {
		className,
		children,
		id,
		label,
		tooltipitem,
		country,
		startTime,
		text,
		meta,
		icon,
		image,
		type,
		action,
		meetingName,
		raceNumber,
		meeting_id,
		OpenNext2JumpItem,
		showSelections,
		size,
		lessThan2Min,
		status,
		distance,
		...rest
	} = props;
	const { center } = rest;

	const carouselItemClass = cx({
		[className]: className,
	});

	// <Icon icon={icon} size="-2" push="1" color="white" />;
	const carouselV4RaceDistance = distance && ' - ' + distance + 'm';
	const carouselV4RaceName = text && <StyledCarouselItemV4_Race_name>{text} </StyledCarouselItemV4_Race_name>;

	// @HW add new race number
	const carouselV4RaceNumber = label && (
		<StyledCarouselItemV4_Race_Number>
			{label}
			{carouselV4RaceDistance}
		</StyledCarouselItemV4_Race_Number>
	);

	const handleClick = (e) => {
		e.preventDefault();
		action(id);
	};

	if (children) {
		return (
			<StyledCarouselItemV4 {...rest} className={carouselItemClass}>
				{children}
			</StyledCarouselItemV4>
		);
	}

	return (
		<StyledCarouselItemV4 size={'0'} {...rest} className={carouselItemClass} action={handleClick}>
			<StyledCarouselItemV3_header_content>
				<StyledCarouselItemV3_hedar_left_content>
					<div
						className={css`
							display: flex;
							flex-direction: column; /* Make sure it's in a row for alignment */
							align-items: center; /* This will align items vertically */
							justify-content: space-around;
						`}
					>
						<div
							className={css`
								margin-top: -8px;
								/* padding-right: 5px; */
							`}
						>
							<RacingIcon type={type} />
						</div>
						<CountryImage
							country={country}
							className={css`
								width: 24px;
								border-radius: 4px;
							`}
						/>
					</div>

					<div
						className={css`
							flex: 0 0 80%;
							max-width: 80%;
							
							font-size: ${FONT_SIZE_10};
							display: flex;
							flex-direction: column;
							justify-content: space-around;
						`}
					>
						<div>{carouselV4RaceNumber}</div>
						<div>{carouselV4RaceName}</div>
					</div>
				</StyledCarouselItemV3_hedar_left_content>

				<StyledCarouselItemV3_right_hedear_content>
					<StyledCarouselItemV3_race_closed_fristCircle status={status} lessThan2Min={lessThan2Min}>
						<StyledCarouselItemV3_race_closed_secondCircle>
							<Text
								className={css`
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									margin: 0;
									font-size: ${FONT_SIZE_10};
									text-align: center;
								`}
							>
								{meta}
							</Text>
						</StyledCarouselItemV3_race_closed_secondCircle>
					</StyledCarouselItemV3_race_closed_fristCircle>

					<StyledCarouselItemV3_race_post>.</StyledCarouselItemV3_race_post>
				</StyledCarouselItemV3_right_hedear_content>
			</StyledCarouselItemV3_header_content>
		</StyledCarouselItemV4>
	);
};

CarouselItemV4.propTypes = {
	/** ID of the carousel item */
	id: PropTypes.any.isRequired,

	/** Extra classes */
	className: PropTypes.string,

	/** Label for the carousel item */
	label: PropTypes.string,

	loading: PropTypes.bool,

	meeting_id: PropTypes.number,

	meetingName: PropTypes.string,
	/** Text for the carousel item */
	text: PropTypes.string,

	/** Extra information for the carousel item */
	meta: PropTypes.string,

	/** Icon to display in the carousel item */
	icon: PropTypes.string,

	startTime: PropTypes.string,

	raceNumber: PropTypes.number,

	/** Image to display in the carousel item */
	image: PropTypes.string,

	/** How many slides to display */
	slides: PropTypes.string,

	/** If the carousel item should be centered */
	center: PropTypes.bool,

	/** If the carousel item should have padding */
	padding: PropTypes.bool,

	type: PropTypes.string,

	/** If the carousel item should have a margin */
	margin: PropTypes.bool,

	/** If the carousel item should have a border */
	border: PropTypes.bool,

	/** Action when the carousel item is clicked on */
	action: PropTypes.func,

	/** Children to display under the carousel item */
	children: PropTypes.node,

	/** Action to call when the forgot password link is to be opened */
	OpenNext2JumpItem: PropTypes.func,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}),

	/** Whether to use a small amount of flex to fit more buttons */
	small: PropTypes.bool,

	country: PropTypes.string,

	lessThan2Min: PropTypes.string,
};

CarouselItemV4.defaultProps = {
	className: '',
	label: undefined,
	text: undefined,
	meta: undefined,
	icon: undefined,
	image: undefined,
	slides: undefined,
	center: undefined,
	padding: undefined,
	margin: undefined,
	border: undefined,
	action: undefined,
	children: undefined,
	country: undefined,
	small: null,
	raceNumber: null,
	meeting_id: null,
	size: undefined,
	meetingName: null,
	OpenNext2JumpItem: undefined,
	type: undefined,
	startTime: null,
	lessThan2Min: null,
	loading: false,
};

export default CarouselItemV4;
