// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, ui } from '@tbh/ui-kit';
import { SizeMe } from 'react-sizeme';
import { withNamespaces } from 'react-i18next';

import Format from '../../../legacy/core/format';

import {
	RACING_AU,
	RACING_BET_TYPE_MARGIN,
	RACING_BET_TYPE_EACHWAY,
	RACING_BET_TYPE_PLACE,
	RACING_BET_TYPE_WIN,
	RACING_BET_TYPE_QUADDIE,
	RACING_BET_TYPE_DAILY_DOUBLE,
	RACING_ODDS_GIDS,
} from '../../../common/constants/Racing';
import {
	BET_TYPE_DERIVATIVE,
	BET_TYPE_EXOTIC,
	BET_TYPE_RACE,
	BET_TYPE_SPORT,
} from '../../../store/entities/constants/BetConstants';
import { GENERIC_LOGIN_MESSAGE } from '../../../common/constants/Notifications';
import { calculateCombinations, validateMultiLegSelections } from '../../../common/ExoticBets';
import { isBetPlacedForProduct } from '../../../store/entities/actions/BetActions';
import { GOAT_PRODUCT_TYPE_BOOST } from '../../../common/constants/GoatProducts';

/**
 * Components
 */
import { Button, ButtonGroup, Text, PlotElements } from '@tbh/ui-kit';
import BetSelection from '../../../components/features/Betting/BetSelection/BetSelection';
import BettaBucksAmount from '../../../components/features/BettaBucksAmount/BettaBucksAmount';
import SwapOddsButton from '../../../components/features/Betting/SwapOddsButton/SwapOddsButton';
import BetAmountAndPayout from '../../../components/features/Betting/BetAmountAndPayout/BetAmountAndPayout';
import CurrencyEntryKeypad from '../../../components/features/Application/Currency/CurrencyEntryKeypad/CurrencyEntryKeypad';
import LoginContainer from '../../Application/Login/LoginContainer';
import GoatButtBumpTabs from '../../../components/features/Racing/GoatButtBumpTabs/GoatButtBumpTabs';
import CurrencyDisplay from '../../../components/features/Application/Currency/CurrencyDisplay/CurrencyDisplay';
import CurrencyNameDisplay from '../../../components/features/Application/Currency/CurrencyNameDisplay/CurrencyNameDisplay';
import RaceProductLegend from '../../../components/features/Racing/RaceProductLegend/RaceProductLegend';

import FeatureContext from '../../../contexts/featureContext';

/**
 * Styling
 */
const cssActionButtons = css`
	align-items: inherit;
`;

const StyledBetPlacementContainer__BetAmountAndPayout = styled(BetAmountAndPayout)(
	(props) => css`
		label: BetPlacementContainer__BetAmountAndPayout;

		padding: ${spacings(props).cozy}px;
	`,
);

const StyledBetPlacementContainer__BetSelection = styled(BetSelection)(
	(props) => css`
		label: BetPlacementContainer__BetSelection;

		padding: ${spacings(props).cozy}px;
	`,
);

const StyledBetPlacementContainer__BetOptions = styled('div')(
	(props) => css`
		label: BetPlacementContainer__BetOptions;

		padding: 0 ${spacings(props).cozy}px ${spacings(props).cozy}px;
		text-align: right;
	`,
);

const StyledBetPlacementContainer__CurrencyEntryKeypad = styled(CurrencyEntryKeypad)(
	(props) => css`
		label: BetPlacementContainer__CurrencyEntryKeypad;

		padding: ${spacings(props).cozy}px ${spacings(props).cozy}px 0;
	`,
);

const StyledBetPlacementContainer__BetPlacementOptions = styled('div')(
	(props) => css`
		label: BetPlacementContainer__BetPlacementOptions;

		padding: ${spacings(props).cozy}px ${spacings(props).cozy}px 0;
	`,
);

const StyledBetPlacementContainer__BetEntry = styled('div')(
	(props) => css`
		label: BetPlacementContainer__BetEntry;

		background-color: ${ui(props).color_3};
	`,
);

const StyledBetPlacementContainer__StruckWarning = styled(Text)(
	(props) => css`
		label: BetPlacementContainer__StruckWarning;

		margin: ${spacings(props).compact}px 0;
	`,
);

const StyledBetPlacementContainer__ExtraButtonGroup = styled(ButtonGroup)(
	(props) => css`
		label: BetPlacementContainer__ExtraButtonGroup;

		margin: ${spacings(props).cozy}px 0;
		justify-content: space-between;

		&:empty {
			margin-bottom: 0;
		}
	`,
);

const StyledBetPlacementContainer__BetMessage = styled('div')(
	(props) => css`
		label: BetPlacementContainer__BetMessage;
		padding: 15px;
		position: relative;
		display: flex;
		width: 100%;
		-webkit-align-items: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		overflow: hidden;
		text-transform: none;
		background: #e60000;
		padding: 0 15px;
		font-weight: 700;
		height: 50px;
		text-transform: capitalize;
		color: #ffffff;
		text-align: center;
		font-size: 12px;
	`,
);

const StyledBetPlacementContainer__ExtraButton = styled(Button)(
	(props) => css`
		label: BetPlacementContainer__ExtraButton;

		flex: 1;

		&:not(:first-child) {
			margin-left: ${spacings(props).cozy}px;
		}
	`,
);
// End Styling

class BetPlacementContainer extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Whether the bet selection is a Racing selection, and if it is an Exotic Racing selection */
		isRacing: PropTypes.bool.isRequired,
		isExotic: PropTypes.bool.isRequired,

		/** The keypad buttons used for the Unit Stake */
		amountButtons: PropTypes.array.isRequired,

		/** Add to Multi button clicked */
		handleAddToMulti: PropTypes.func.isRequired,

		/** Undo Bet button clicked */
		handleCancel: PropTypes.func.isRequired,

		/** Place Bet button clicked */
		handleConfirm: PropTypes.func.isRequired,

		/** Quick Deposit button clicked */
		handleQuickDeposit: PropTypes.func.isRequired,

		/** Bonus Bet button clicked/toggled */
		handleUseBonusBetsChange: PropTypes.func.isRequired,

		/** Object containing the odds change structure */
		oddsChangeText: PropTypes.object.isRequired,

		/** The list of bet selections (should only contain one item) */
		selections: PropTypes.array.isRequired,

		/** Function to set an Error Message */
		setErrorMessage: PropTypes.func.isRequired,

		/** Function to clear an Error message */
		clearErrorMessage: PropTypes.func.isRequired,

		/** Function to set odds change message */
		setOddsChangeText: PropTypes.func.isRequired,

		/** List of props that must be built for the BetSelection component */
		betSelectionProps: PropTypes.object.isRequired,

		/** Update the bet prompt selection from entities */
		updateSelection: PropTypes.func.isRequired,

		/** Handler to change the GOAT product */
		onSelectGoatProduct: PropTypes.func.isRequired,

		/** Handler for setting the stage of the bet placement process */
		setStage: PropTypes.func.isRequired,

		/** The type of selection that is present in the bet prompt */
		selectionType: PropTypes.oneOf([BET_TYPE_RACE, BET_TYPE_EXOTIC, BET_TYPE_DERIVATIVE, BET_TYPE_SPORT]).isRequired,

		/** Whether the application is running in single wallet mode (and this isn't a tournament bet) or not */
		isSingleWallet: PropTypes.bool.isRequired,

		/**	Limit the user bet amount to their balance */
		limitUserBetAmount: PropTypes.bool.isRequired,

		/** Allow for navigation actions */
		useRouting: PropTypes.bool,

		/** Disable bonus bets completely */
		bonusBets: PropTypes.bool,

		/** The stage of the bet placement process */
		stage: PropTypes.string,

		/** A list of bets related to the current race or sport selection */
		relatedBets: PropTypes.array,

		/** Whether fixed odds will be auto-accepted (should always be false as the control has been removed) */
		autoAccept: PropTypes.bool,

		/** The user's available balance */
		availableBalance: PropTypes.number,

		/** Component class name */
		className: PropTypes.string,

		/** A structure containing all the different types of odds */
		allOdds: PropTypes.object,

		/** Fantasy dollars for tournaments */
		bettaBucks: PropTypes.number,

		/** Whether or not we are using tournament dollars */
		useBettaBucks: PropTypes.bool,

		/** Cost of entry for the tournament */
		entryFee: PropTypes.number,

		/** Hides the add to multi button */
		disableAddToMulti: PropTypes.bool,

		/** Extra details if the bet selection is an exotic selection */
		exoticDetails: PropTypes.object,

		/** Handler for swapping to and from global total fixed odds */
		handleSwapOdds: PropTypes.func,

		/** If the user is a vip user */
		isVIP: PropTypes.bool,

		/** If the odds are tote odds */
		isToteOdds: PropTypes.bool,

		/** The odds for the selection if it is W/P */
		odds: PropTypes.object,

		/** If the price has been bumped up */
		isPriceBumped: PropTypes.bool,

		/** The selected GOAT product */
		selectedGoatProduct: PropTypes.oneOf([null, GOAT_PRODUCT_TYPE_BOOST, RACING_BET_TYPE_MARGIN]),

		/** The race details, if it is a race selection */
		race: PropTypes.object,

		/** The current meeting if it is a racing bet */
		meeting: PropTypes.shape({
			country: PropTypes.string,
		}),

		/** The racing bet type ('win', 'place', 'eachway') */
		racingBetType: PropTypes.oneOf([
			'',
			RACING_BET_TYPE_WIN,
			RACING_BET_TYPE_PLACE,
			RACING_BET_TYPE_MARGIN,
			RACING_BET_TYPE_EACHWAY,
		]),

		/** Whether or not to show the Quick Deposit button */
		/** Criteria: User is logged in and has credit cards */
		showQuickDeposit: PropTypes.bool,

		/** The Unit Stake amount */
		stake: PropTypes.number,

		/** If swap odds are available for the selection */
		swapOddsAvailable: PropTypes.bool,

		/** GA tracking function */
		track: PropTypes.func,

		/** Whether or not to show the Bonus Bet button
		 * Criteria: Non Exotic, User is logged in and has free_credit_balance
		 */
		useBonusBets: PropTypes.bool,

		/** The currently logged in User */
		user: PropTypes.shape({
			/** The bonus bet balance for the user */
			free_credit_balance: PropTypes.number,

			/** The number of sports boosts available to the user */
			sports_boost_available: PropTypes.number,

			/** The number of racing boosts available to the user */
			racing_boost_available: PropTypes.number,

			/** The number of butt bets available to the user */
			racing_butt_available: PropTypes.number,

			message: PropTypes.string,
		}),
	};

	static defaultProps = {
		autoAccept: false,
		availableBalance: null,
		bettaBucks: null,
		bonusBets: false,
		useBettaBucks: false,
		entryFee: null,
		className: '',
		disableAddToMulti: false,
		exoticDetails: {},
		isVIP: false,
		isToteOdds: false,
		allOdds: {},
		relatedBets: [],
		odds: null,
		race: null,
		meeting: null,
		racingBetType: '',
		showQuickDeposit: false,
		stake: 0,
		stage: null,
		handleSwapOdds: () => { },
		swapOddsAvailable: false,
		isPriceBumped: false,
		selectedGoatProduct: null,
		track: () => { },
		useBonusBets: false,
		useRouting: true,
		user: null,
		message: '',
	};

	constructor(props) {
		super(props);

		this.state = {
			activeButton: null,
			stake: props.stake,
			flexiAmount: this.calculateFlexiAmount(props.stake),
			useBonusBets: props.useBonusBets && this.allowBonusBets(props),
			newamount: null,
			disabled: false,
			PreValue: null,
			clickFlex: false,
			betText: null,
		};
	}

	/**
	 * Performed when the component receives new props
	 *
	 * @param newProps
	 */
	UNSAFE_componentWillReceiveProps(newProps) {
		const { isRacing, isExotic, racingBetType, selections, selectionType, race } = newProps;

		const selection = selections[0];
		const lastSelection = this.props.selections[0];
		const lastOdds = this.props.odds || {};
		let oddsChanged = false;

		if (selection.id === lastSelection.id && selection.product_id === lastSelection.product_id) {
			const newOdds = newProps.odds;

			// If a racing selection is_fixed enabled and has a selected bet type marked as fixed, then it is fixed
			let isFixed = false;
			if (isRacing) {
				if (!isExotic) {
					if (race.fixed_odds_enabled) {
						if (selectionType === BET_TYPE_DERIVATIVE) {
							isFixed = true;
						} else if (racingBetType === RACING_BET_TYPE_EACHWAY) {
							isFixed = selection[RACING_BET_TYPE_WIN].fixed || selection[RACING_BET_TYPE_PLACE].fixed;
						} else {
							isFixed = racingBetType === RACING_ODDS_GIDS ? selection['win_fixed'] : selection[racingBetType].fixed;
						}
					}
				}
			} else {
				// otherwise all sports are fixed
				isFixed = true;
			}

			// Check if odds have decreased if the selection is fixed
			if (isFixed) {
				if (
					newOdds &&
					newProps.isPriceBumped === this.props.isPriceBumped &&
					(newOdds.win_fixed !== lastOdds.win_fixed ||
						newOdds.win !== lastOdds.win ||
						newOdds.place_fixed !== lastOdds.place_fixed ||
						newOdds.place !== lastOdds.place)
				) {
					oddsChanged =
						newOdds.win_fixed < lastOdds.win_fixed ||
						newOdds.place_fixed < lastOdds.place_fixed ||
						newOdds.win < lastOdds.win ||
						newOdds.place < lastOdds.place;
					this.props.updateSelection();
				}
			}
		}

		// The maximum available balance may have been changed, so refresh stake
		let stateObject = {};
		if (
			newProps.availableBalance !== this.props.availableBalance ||
			newProps.useBettaBucks !== this.props.useBettaBucks ||
			newProps.useBonusBets !== this.props.useBonusBets
		) {
			stateObject.useBonusBets = newProps.useBonusBets && this.allowBonusBets(newProps);
			stateObject.stake = this.getLimitedStake(this.state.stake, newProps);
		}

		if (
			(newProps.selectedGoatProduct === GOAT_PRODUCT_TYPE_BOOST &&
				(this.isBumpBetPlaced() || (newProps.user && newProps.user.racing_boost_available <= 0))) ||
			(newProps.selectedGoatProduct === RACING_BET_TYPE_MARGIN &&
				(this.isMarginBetPlaced() || (newProps.user && newProps.user.racing_butt_available <= 0)))
		) {
			this.props.onSelectGoatProduct();
		}

		this.setState(stateObject, () => {
			// Display a message if the odds have changed and we aren't auto-accepting the new odds
			if (oddsChanged && !this.props.autoAccept) {
				const oddsChangeText = this.getOddsChangeText(lastOdds);
				if (oddsChangeText) {
					this.props.setErrorMessage(oddsChangeText);
				}
			}
		});
	}

	/**
	 * Allow bonus bets if the user has bonus bets remaining and it is not an exotic or international race selection
	 *
	 * @param props
	 * @returns boolean
	 */
	allowBonusBets = (props = this.props) => {
		const {
			isExotic,
			user,
			isRacing,
			meeting,
			isPriceBumped,
			racingBetType,
			selectionType,
			useBettaBucks,
			bonusBets,
		} = props;

		return (
			bonusBets &&
			!useBettaBucks &&
			!isExotic &&
			user &&
			user.free_credit_balance &&
			!isPriceBumped &&
			racingBetType !== RACING_BET_TYPE_MARGIN &&
			(!isRacing || (isRacing && meeting && meeting.country === RACING_AU))
		);
	};

	/**
	 * Calculate the flexi amount for an exotic bet
	 *
	 * @param stake
	 * @returns {String}
	 */
	calculateFlexiAmount = (stake) => {
		const { isExotic, exoticDetails, selections } = this.props;
		// We don't need to calculate anything if it isn't an exotic bet
		if (!isExotic || !exoticDetails) {
			return stake && stake.toFixed(2);
		}

		const combinations = calculateCombinations(exoticDetails.id, selections, exoticDetails.isBoxed);
		const flexiAmount = stake / combinations;

		// if the flexi amount is less than one, display it like that
		return flexiAmount < 1 ? '< 1' : Math.trunc(flexiAmount * 100) / 100;
	};

	/**
	 * Handles clearing the stake value
	 */
	clearStake = () => {
		this.props.track('Click', 'Clear Stake');
		this.setState(
			{
				stake: 0,
				flexiAmount: '0.00',
				activeButton: null,
			},
			() => this.props.clearErrorMessage(),
		);
	};

	/**
	 * Performed when the 'Cancel Bet' button is pressed
	 * NOTE: Currently there is no cancel button, but I want to leave this in case one gets added back in
	 */
	handleCancel = () => {
		this.props.track('Click', 'Cancel Bet');
		this.props.handleCancel();
	};

	/**
	 * Performed when the 'Clear Selections' button is pressed
	 */
	onClear = () => {
		this.props.track('Click', 'Clear Selections');
		this.props.handleClear();
	};

	/**
	 * Determines the text for odds changes.
	 *
	 * @param lastOdds
	 * @return {String}
	 */
	getOddsChangeText = (lastOdds) => {
		const { odds, oddsChangeText, selections, t } = this.props;
		const placeOddsChanged = lastOdds.place !== odds.place;
		const winOddsChanged = lastOdds.win !== odds.win;

		// Quickly end function no odds change or not a single bet
		if (selections.length !== 1 || !(placeOddsChanged || winOddsChanged)) {
			return '';
		}

		// Determine formatted odds
		const current = {
			place: Format.formatAsOdds(odds.place),
			win: Format.formatAsOdds(odds.win),
		};
		const last = {
			place: Format.formatAsOdds(lastOdds.place),
			win: Format.formatAsOdds(lastOdds.win),
		};

		const selection = selections[0];
		if (selection.type === 'sport') {
			// Sports only work with the 'win' attribute
			return t('BetPlacementContainer__SportOddsChange', { last: last.win, current: current.win });
		}

		const racingBetType = this.props.racingBetType;

		let changes = [];
		let oddsChanges = {};

		if (racingBetType !== RACING_BET_TYPE_PLACE) {
			// Win odds have changed
			if (winOddsChanged) {
				const name = racingBetType === RACING_BET_TYPE_EACHWAY ? 'Win odds' : t('Odds');
				const text = name + t('BetPlacementContainer__RacingOddsChange', { last: last.win, current: current.win });
				oddsChanges.win = text;
				changes.push(text);
			} else if (oddsChangeText.win) {
				changes.push(oddsChangeText.win);
			}
		}

		if (racingBetType !== RACING_BET_TYPE_WIN && racingBetType !== RACING_BET_TYPE_MARGIN) {
			// Place odds have changed
			if (placeOddsChanged) {
				const name = racingBetType === RACING_BET_TYPE_EACHWAY ? 'Place odds' : t('Odds');
				const text = name + t('BetPlacementContainer__RacingOddsChange', { last: last.win, current: current.win });
				oddsChanges.place = text;
				changes.push(text);
			} else if (oddsChangeText.place) {
				changes.push(oddsChangeText.place);
			}
		}

		// Update odds text
		this.props.setOddsChangeText(oddsChanges.place, oddsChanges.win);

		return changes.length ? changes : null;
	};

	/**
	 * Returns the stake, limited by available balance.
	 *
	 * @param value
	 * @param props
	 * @returns {*}
	 */
	getLimitedStake = (value, props = this.props) => {
		// Set to either parameter or prop, depending on whether data is passed
		const availableBalance = props.availableBalance;
		const useBonusBets = props.useBonusBets;
		const useBettaBucks = props.useBettaBucks;

		// Limit the user's stake if they are logged in or on a tournament
		const limitStake = props.useBettaBucks || (props.limitUserBetAmount && !!props.user);

		// Limit the user's stake if they are logged in
		if (limitStake && value > availableBalance && !props.isSingleWallet) {
			value = availableBalance;

			props.setErrorMessage(props.t('BetPlacementContainer__ExceededBalance'));
		}

		// Final validation on the amount
		let amount = parseInt(value) || 0;
		if (amount < 0) {
			amount = 0;
		}

		return amount;
	};

	/**
	 * Performed when the 'Quick Deposit' button is clicked
	 */
	handleQuickDeposit = () => {
		this.props.track('Click', 'Quick Deposit');
		this.props.handleQuickDeposit();
	};

	/**
	 * Handles setting stake when an amount button is clicked
	 *
	 * @param event
	 * @param value
	 */
	onAmountButtonClick = (event, value) => {
		const amount = parseInt(value);

		// Check if the amount is standalone, which means it should overwrite the current stake amount
		const isStandalone = this.props.amountButtons.some((button) => button.amount === value && button.standalone);
		//const newAmount = isStandalone ? amount : this.state.stake + amount;
		const newAmount = amount;
		const stake = this.getLimitedStake(newAmount);

		// Track amount button being pressed
		this.props.track('Click', `${this.props.isVIP ? 'VIP' : ''}Keypad - ${Format.centsAsDollars(stake)}`);

		// Report that the stake should be changed
		this.props.setStage();
		this.setState({
			stake: stake,
			flexiAmount: this.calculateFlexiAmount(stake),
			activeButton: value,
		});
	};

	/**
	 * When the confirm button is clicked on
	 */
	onConfirm = () => {
		this.props.handleConfirm(this.state.stake);
	};

	/**
	 * Handle when the user wants to go back to the default stage
	 */
	/*	onChange = () => {
	  this.props.track('Click', 'Change Bet');
	  this.props.setStage();
	};*/

	/**
	 * Check this.state.stake / 100 < selections[0].total_liability then pass message
	 */

	onChange = () => {
		const { selections } = this.props;
		let betText = null;
		if (!(this.state.stake / 100 <= selections[0].total_liability)) {
			betText = 'Bet Message';
		}
		this.setState({
			betText,
		});

		this.setStake(0);
		// this.props.handleConfirm(this.state.stake);
	};

	/**
	 * Sets the stake, limited by the available balance
	 *
	 * @param value
	 */
	setStake = (value) => {
		const stake = this.getLimitedStake(value);
		this.props.setStage();
		this.setState({
			stake,
			flexiAmount: this.calculateFlexiAmount(stake),
		});
	};

	/**
	 * Sets the stake directly from an event
	 *
	 * @param name
	 * @param value
	 */
	setStakeFromHandler = (name, value) => {
		const amount = parseInt(value) || 0;
		this.setStake(amount);
	};

	/**
	 * Sets the stake directly from an event
	 *
	 * @param name
	 * @param value
	 */
	setFlexiFromHandler = (name, value) => {
		const amount = parseInt(value) || 0;
		const combinations = calculateCombinations(
			this.props.exoticDetails.id,
			this.props.selections,
			this.props.exoticDetails.isBoxed,
		);
		this.setStake(combinations * amount);
	};

	disbledFlxAmount = (event) => {
		const combinations = calculateCombinations(
			this.props.exoticDetails.id,
			this.props.selections,
			this.props.exoticDetails.isBoxed,
		);
		const clickValue = this.state.disabled + 1;
		if (clickValue % 2 === 0) {
			const newamountwithoutflx = this.state.PreValue;
			this.setStakeFromHandler('betAmount', newamountwithoutflx);
			this.setState({ newamountwithoutflx, disabled: clickValue, clickFlex: false });
		} else {
			const newamountwithoutflx = combinations * this.state.stake;
			this.setStakeFromHandler('betAmount', newamountwithoutflx);
			this.setState({ newamountwithoutflx, disabled: clickValue, PreValue: this.state.stake, clickFlex: true });
		}
	};

	/**
	 * Sets whether or not bonus bets are being used and triggers callback
	 *
	 * @param event
	 * @param value
	 */
	setUseBonusBets = (event, value) => {
		this.setState(
			{
				useBonusBets: value,
			},
			() => {
				this.props.track('Select', 'Bonus Bets');
				this.props.handleUseBonusBetsChange(value);
			},
		);
	};

	/**
	 * Handle when the Add to Multi button is clicked
	 */
	handleAddToMulti = () => {
		const { isRacing, selections, racingBetType, handleAddToMulti } = this.props;
		const selection = selections[0];
		racingBetType === 'oddsgrid'
			? handleAddToMulti(
				isRacing,
				selection,
				racingBetType,
				this.state.stake,
				selections[0].win.liability,
				selections[0].back2_liability,
				selections[0].back3_liability,
				selections[0].back4_liability,
				selections[0].total_liability,
			)
			: handleAddToMulti(isRacing, selection, racingBetType, this.state.stake, 0, 0, 0);
	};

	/**
	 * Returns whether or not multi bet button is available for the current selection
	 */
	multiBetAvailable = () => {
		const { disableAddToMulti, isRacing, race, racingBetType, selectionType, isPriceBumped } = this.props;

		return (
			!disableAddToMulti &&
			!isPriceBumped &&
			(selectionType === BET_TYPE_SPORT ||
				(isRacing &&
					race &&
					race.products &&
					racingBetType !== RACING_BET_TYPE_MARGIN &&
					race.products.find((product) => product.multi_available && product.bet_type === racingBetType)))
		);
	};

	/**
	 * Return whether or not the Swap Odds button should display
	 *
	 * @returns {boolean}
	 */
	displaySwapOdds = () => {
		const { betSelectionProps, swapOddsAvailable, racingBetType, allOdds, isToteOdds } = this.props;

		return !!(
			swapOddsAvailable &&
			betSelectionProps.isRacing &&
			racingBetType !== RACING_BET_TYPE_EACHWAY &&
			allOdds &&
			allOdds[`${racingBetType}_tote`].length &&
			racingBetType === allOdds[`${racingBetType}_fixed`].length &&
			// Check that the product we will be swapping to is available
			((isToteOdds && allOdds[`${racingBetType}_fixed`][0].available) ||
				(!isToteOdds && allOdds[`${racingBetType}_tote`][0].available))
		);
	};

	/**
	 * Check if a certain bet with a matching type or product, etc, has been placed
	 *
	 * @param key
	 * @param value
	 * @returns {*}
	 */
	isBetPlaced = (key, value) => {
		const { isRacing, relatedBets, race, selections } = this.props;

		if (isRacing) {
			return isBetPlacedForProduct(key, value, relatedBets, race.products);
		}

		const selection = selections[0];
		const selectedEvent = selection.event;
		if (selectedEvent) {
			// Filter so that it is only bets placed for this specific event
			const betsForEvent = relatedBets.filter((bet) => bet.bet_selections[0].event_id === selectedEvent.id);

			return isBetPlacedForProduct(key, value, betsForEvent, selectedEvent.products);
		}
	};

	/**
	 * Return whether or not a Margin bet has been placed
	 *
	 * @returns {*}
	 */
	isMarginBetPlaced = () => {
		return this.isBetPlaced('bet_type', RACING_BET_TYPE_MARGIN);
	};

	/**
	 * Return whether or not a Bump bet has been placed
	 *
	 * @returns {*}
	 */
	isBumpBetPlaced = () => {
		return this.isBetPlaced('product_type', GOAT_PRODUCT_TYPE_BOOST);
	};

	OddsGridValidate = () => {
		const { selections } = this.props;
		if (selections.length > 0) {
			if (selections[0].win.productId === 19) {
				return this.state.stake / 100 > selections[0].total_liability;
			}
			if (selections[0].win.productId === 20) {
				return this.state.stake / 100 > selections[0].total_liability;
				//return selections[0].back1_liability === 0 ? this.state.stake / 100 > selections[0].total_liability : this.state.stake / 100 > selections[0].back2_liability + selections[0].back3_liability;
			}
			if (selections[0].win.productId === 21) {
				return this.state.stake / 100 > selections[0].total_liability;
				//return selections[0].back1_liability === 0 || selections[0].back2_liability === 0 ? this.state.stake / 100 > selections[0].total_liability :  this.state.stake / 100 > selections[0].back3_liability;
			}
			if (selections[0].win.productId === 22) {
				return this.state.stake / 100 > selections[0].total_liability;
			}
			return this.state.stake !== 0;
		}
	};

	/**
	 * Check if a certain bet with a matching type or product, etc, has been placed
	 *
	 * @param product_id
	 * @param selections
	 * @returns {*}
	 */
	BetMessage = (product_id, selections) => {
		let message = null;
		if (product_id === 19) {
			message =
				this.state.stake / 100 > selections[0].total_liability
					? 'Maximum stake/ liability amount is ' + selections[0].total_liability
					: null;
		}
		if (product_id === 20) {
			message =
				this.state.stake / 100 > selections[0].total_liability
					? 'Maximum stake/ liability amount is ' + selections[0].total_liability
					: null;
		}
		if (product_id === 21) {
			message =
				this.state.stake / 100 > selections[0].total_liability
					? 'Maximum stake/ liability amount is ' + selections[0].total_liability
					: null;
		}
		if (product_id === 22) {
			message =
				this.state.stake / 100 > selections[0].total_liability
					? 'Maximum stake/ liability amount is ' + selections[0].total_liability
					: null;
		}
		return message;
	};
	render() {
		const {
			t,
			amountButtons,
			betSelectionProps,
			isExotic,
			isRacing,
			isToteOdds,
			className,
			handleSwapOdds,
			handleClear,
			selectedGoatProduct,
			onSelectGoatProduct,
			showQuickDeposit,
			racingBetType,
			race,
			user,
			bettaBucks,
			entryFee,
			selections,
			exoticDetails,
			stage,
			useBettaBucks,
			useRouting,
		} = this.props;
		const { stake } = this.state;

		const componentClasses = cx({
			[className]: className,
		});

		const selection = selections[0];

		// Double the stake if an eachway bet
		const amount = racingBetType === RACING_BET_TYPE_EACHWAY ? stake * 2 : stake;

		const isSwapOddsDisplaying = this.displaySwapOdds();

		// Price bump is only for fixed odds
		const showPriceBump =
			!useBettaBucks &&
			((!isRacing &&
				(!user || user.sports_boost_available > 0) &&
				selection &&
				selection.market &&
				selection.market.boost_available) ||
				(isRacing &&
					!isExotic &&
					!isToteOdds &&
					racingBetType !== RACING_BET_TYPE_PLACE &&
					(!user || user.racing_boost_available > 0) &&
					race &&
					race.products.some((product) => product.product_type === GOAT_PRODUCT_TYPE_BOOST))) &&
			!this.isBumpBetPlaced();

		// Need to ensure the fixed/tote criteria is met as well
		const showMarginButt =
			isRacing &&
			!isExotic &&
			!useBettaBucks &&
			(!user || user.racing_butt_available > 0) &&
			(racingBetType === RACING_BET_TYPE_WIN || racingBetType === RACING_BET_TYPE_MARGIN) &&
			race &&
			race.products.some((product) => product.bet_type === RACING_BET_TYPE_MARGIN && product.fixed === !isToteOdds) &&
			!this.isMarginBetPlaced();

		let betBtnMsg = '';
		let betSubMsg = '';
		let smallerButtonText = false;
		if (useBettaBucks) {
			// If we have an entry fee, we have to buy into a tournament
			if (entryFee !== null) {
				// Use different language depending on if entry is free or not
				if (entryFee === 0) {
					betBtnMsg = t('BetPlacementContainer__FreeEntryButton');
					betSubMsg = t('BetPlacementContainer__FreeEntryMessage');
				} else {
					const cost = Format.centsAsDollars(entryFee);
					betBtnMsg = t('BetPlacementContainer__ChargeEntryButton');
					betSubMsg = t('BetPlacementContainer__ChargeEntryMessage', { cost });
				}

				// Decrease the confirm button text if Swap Odds is also displaying
				smallerButtonText = isSwapOddsDisplaying;
			}
		}

		const currencyType = this.props.useBettaBucks
			? 'tournamentCurrency'
			: this.props.useBonusBets
				? 'bonusCurrency'
				: 'currency';

		const isMultiLegBetType =
			exoticDetails.id === RACING_BET_TYPE_QUADDIE || exoticDetails.id === RACING_BET_TYPE_DAILY_DOUBLE;
		const isMultiLegValid = validateMultiLegSelections(selections, exoticDetails.id);
		const disableNoStake = parseInt(stake) <= 0;
		const disablePlaceBetButton = isMultiLegBetType ? disableNoStake || !isMultiLegValid : disableNoStake;
		const disableCurrencySelection = isMultiLegValid ? this.onAmountButtonClick : () => { };
		const amountAction = isMultiLegBetType ? disableCurrencySelection : this.onAmountButtonClick;

		return (
			<SizeMe>
				{({ size }) => (
					<div className={componentClasses}>
						{useBettaBucks && this.props.user && <BettaBucksAmount bettaBucks={bettaBucks} />}
						<StyledBetPlacementContainer__BetSelection {...betSelectionProps} />
						{(showPriceBump || showMarginButt) && (
							<StyledBetPlacementContainer__BetOptions>
								<GoatButtBumpTabs
									onSelectGoatProduct={onSelectGoatProduct}
									selectedGoatProduct={selectedGoatProduct}
									showPriceBump={showPriceBump}
									showMarginButt={showMarginButt}
									priceBumps={user ? (isRacing ? user.racing_boost_available : user.sports_boost_available) : null}
									marginButts={user ? user.racing_butt_available : null}
									size={size}
								/>
							</StyledBetPlacementContainer__BetOptions>
						)}
						<StyledBetPlacementContainer__BetEntry>
							<StyledBetPlacementContainer__CurrencyEntryKeypad
								buttonValues={amountButtons}
								buttonAction={amountAction}
								currencyType={currencyType}
								customButton={{
									action: this.clearStake,
									label: t('Clear'),
								}}
							/>
							<StyledBetPlacementContainer__BetAmountAndPayout
								onInputChange={this.setStakeFromHandler}
								onFlexiChange={this.setFlexiFromHandler}
								price={betSelectionProps.price}
								placePrice={betSelectionProps.placePrice}
								racingBetType={betSelectionProps.racingBetType}
								stake={this.state.stake}
								flexiAmount={this.state.flexiAmount}
								newamountWflexi={this.state.newamount}
								exoticType={exoticDetails.id}
								showFlexiBox={isExotic}
								isMultiLegValid={isMultiLegValid}
								isBonusBet={this.state.useBonusBets}
								currencyType={currencyType}
								disableFlx={this.disbledFlxAmount}
								ClickFlx={this.state.clickFlex}
								oddsbettype={racingBetType}
								button={
									this.allowBonusBets()
										? {
											label: t('BonusBet'),
											type: this.state.useBonusBets ? 'primary' : 'ghost',
											action: this.setUseBonusBets,
											data: !this.state.useBonusBets,
										}
										: null
								}
							/>
						</StyledBetPlacementContainer__BetEntry>
						<StyledBetPlacementContainer__BetPlacementOptions>
							{racingBetType === 'oddsgrid' ? (
								<div>
									<StyledBetPlacementContainer__ExtraButtonGroup flex>
										<FeatureContext.Consumer>
											{(featureToggles) => {
												return showQuickDeposit && featureToggles.features.depositsAndWithdrawals.enabled ? (
													<StyledBetPlacementContainer__ExtraButton type="secondary" action={this.handleQuickDeposit}>
														{t('BetPromptContainer__QuickDepositTitle')}
													</StyledBetPlacementContainer__ExtraButton>
												) : (
													<StyledBetPlacementContainer__ExtraButton type="secondary" action={this.handleCancel}>
														{t('Cancel')}
													</StyledBetPlacementContainer__ExtraButton>
												);
											}}
										</FeatureContext.Consumer>
										{handleClear && (
											<StyledBetPlacementContainer__ExtraButton type="secondary" action={this.onClear}>
												{t('BetPlacementContainer__ClearSelection')}
											</StyledBetPlacementContainer__ExtraButton>
										)}
										<StyledBetPlacementContainer__ExtraButton
											type="secondary"
											action={this.handleAddToMulti}
											disabled={this.state.stake === 0 || this.OddsGridValidate()}
										>
											{t('BetPlacementContainer__AddToBetSlip')}
										</StyledBetPlacementContainer__ExtraButton>
										{/*{disabled={this.state.stake === 0 || this.OddsGridValidate()}}*/}
									</StyledBetPlacementContainer__ExtraButtonGroup>
									{/*{  selections[0].back1_liability === 0 || selections[0].back2_liability === 0 || selections[0].back3_liability === 0 ? (this.state.stake / 100 > selections[0].total_liability ? <StyledBetPlacementContainer__BetMessage>{'Maximum liability for this bet is : ' + selections[0].back1_liability + ' + ' + selections[0].back2_liability + ' + ' + selections[0].back3_liability + '+' + selections[0].back4_liability + ' = ' + selections[0].total_liability}</StyledBetPlacementContainer__BetMessage>: "") : (this.state.stake / 100 > selections[0].total_liability ? <StyledBetPlacementContainer__BetMessage>{'Maximum liability for this bet is : ' + selections[0].win.liability + ' + ' + selections[0].back2_liability + ' + ' + selections[0].back3_liability + ' = ' + selections[0].total_liability}</StyledBetPlacementContainer__BetMessage>: "")}*/}
									{/*{selections[0].back1_liability === 0 || selections[0].back2_liability === 0 || selections[0].back3_liability === 0 ? (this.state.stake / 100 > selections[0].total_liability ? <StyledBetPlacementContainer__BetMessage>{'Maximum stake/ liability amount is : ' + selections[0].total_liability}</StyledBetPlacementContainer__BetMessage>: "") : (this.state.stake / 100 > selections[0].total_liability ? <StyledBetPlacementContainer__BetMessage>{'Maximum stake/ liability amount is : ' + selections[0].total_liability}</StyledBetPlacementContainer__BetMessage>: "")}*/}
									{this.BetMessage(betSelectionProps.selectedProductIds[0], selections) !== null ? (
										<StyledBetPlacementContainer__BetMessage>
											{this.BetMessage(betSelectionProps.selectedProductIds[0], selections)}
										</StyledBetPlacementContainer__BetMessage>
									) : null}
								</div>
							) : // Place bet Quinella, Exacta, Trifecta And First 4
								user ? (
									<div>
										<ButtonGroup className={cssActionButtons} spacing={ButtonGroup.spacings.SPACING_NONE} center>
											{stage === 'confirm' ? (
												<Button action={this.onChange} type={Button.types.GHOST} autoFlex>
													{t('Change')}
												</Button>
											) : isSwapOddsDisplaying ? (
												<SwapOddsButton
													action={handleSwapOdds}
													isToteOdds={isToteOdds}
													fixedOdds={this.props.allOdds[`${racingBetType}_fixed`][0].odds}
													toteOdds={this.props.allOdds[`${racingBetType}_tote`][0].odds}
													toteCode={this.props.allOdds[`${racingBetType}_tote`][0].code}
												/>
											) : null}
											<Button
												className={
													smallerButtonText
														? css`
															font-size: smaller;
													  `
														: null
												}
												type={Button.types.CALLTOACTION}
												disabled={disablePlaceBetButton}
												key="confirm"
												action={this.onConfirm}
												autoFlex
											>
												<span
													key="bet-button-message"
													className={
														betBtnMsg && isSwapOddsDisplaying
															? css`
																display: block;
														  `
															: null
													}
												>
													{betBtnMsg}
												</span>
												<PlotElements key="place-bet-text">
													<span>{stage === 'confirm' ? t('Confirm') : t('Place')}</span>
													{amount > 0 ? <span>&nbsp;</span> : ''}
													{amount > 0 ? <CurrencyDisplay amount={amount} type={currencyType} /> : ''}
													{this.state.useBonusBets &&
														this.state.useBonusBets && (
															<span>
																&nbsp;
																{this.state.useBonusBets ? (
																	<CurrencyNameDisplay type="bonusCurrency" />
																) : this.props.useBettaBucks ? (
																	<CurrencyNameDisplay type="tournamentCurrency" />
																) : (
																	''
																)}
															</span>
														)}
													<span>
														&nbsp;
														{t('Bet')}
													</span>
												</PlotElements>
											</Button>
										</ButtonGroup>
										{isMultiLegBetType &&
											!isMultiLegValid && (
												<StyledBetPlacementContainer__StruckWarning size="-2" align="center">
													Incomplete selection, complete all race legs
												</StyledBetPlacementContainer__StruckWarning>
											)}
										<StyledBetPlacementContainer__StruckWarning size="-2" align="right">
											{betSubMsg}
										</StyledBetPlacementContainer__StruckWarning>
									</div>
								) : (
									<div>
										<FeatureContext.Consumer>
											{(featureToggles) => {
												return (
													featureToggles.features.loginInModals.enabled && (
														<LoginContainer
															simpleLogin
															handleClose={() => { }}
															notificationToDisplay={t(GENERIC_LOGIN_MESSAGE)}
															useRouting={useRouting}
														/>
													)
												);
											}}
										</FeatureContext.Consumer>
									</div>
								)}

							{/*	{user ? (
								<div>
									<ButtonGroup className={cssActionButtons} spacing={ButtonGroup.spacings.SPACING_NONE} center>
										{stage === 'confirm' ? (
											<Button action={this.onChange} type={Button.types.GHOST} autoFlex>
												{t('Change')}
											</Button>
										) : isSwapOddsDisplaying ? (
											<SwapOddsButton
												action={handleSwapOdds}
												isToteOdds={isToteOdds}
												fixedOdds={this.props.allOdds[`${racingBetType}_fixed`][0].odds}
												toteOdds={this.props.allOdds[`${racingBetType}_tote`][0].odds}
												toteCode={this.props.allOdds[`${racingBetType}_tote`][0].code}
											/>
										) : null}
										<Button
											className={
												smallerButtonText
													? css`
															font-size: smaller;
													  `
													: null
											}
											type={Button.types.CALLTOACTION}
											disabled={disablePlaceBetButton}
											key="confirm"
											action={this.onConfirm}
											autoFlex
										>
											<span
												key="bet-button-message"
												className={
													betBtnMsg && isSwapOddsDisplaying
														? css`
																display: block;
														  `
														: null
												}
											>
												{betBtnMsg}
											</span>
											<PlotElements key="place-bet-text">
												<span>{stage === 'confirm' ? t('Confirm') : t('Place')}</span>
												{amount > 0 ? <span>&nbsp;</span> : ''}
												{amount > 0 ? <CurrencyDisplay amount={amount} type={currencyType} /> : ''}
												{this.state.useBonusBets &&
													this.state.useBonusBets && (
														<span>
															&nbsp;
															{this.state.useBonusBets ? (
																<CurrencyNameDisplay type="bonusCurrency" />
															) : this.props.useBettaBucks ? (
																<CurrencyNameDisplay type="tournamentCurrency" />
															) : (
																''
															)}
														</span>
													)}
												<span>
													&nbsp;
													{t('Bet')}
												</span>
											</PlotElements>
										</Button>
									</ButtonGroup>
									{isMultiLegBetType &&
										!isMultiLegValid && (
											<StyledBetPlacementContainer__StruckWarning size="-2" align="center">
												Incomplete selection, complete all race legs
											</StyledBetPlacementContainer__StruckWarning>
										)}
									<StyledBetPlacementContainer__StruckWarning size="-2" align="right">
										{betSubMsg}
									</StyledBetPlacementContainer__StruckWarning>
								</div>
							) : (
								<div>
									<FeatureContext.Consumer>
										{(featureToggles) => {
											return (
												featureToggles.features.loginInModals.enabled && (
													<LoginContainer
														simpleLogin
														handleClose={() => {}}
														notificationToDisplay={t(GENERIC_LOGIN_MESSAGE)}
														useRouting={useRouting}
													/>
												)
											);
										}}
									</FeatureContext.Consumer>
								</div>
							)}*/}

							{/*              {this.state.stake / 100 > selections[0].total_liability ? <StyledBetPlacementContainer__BetMessage>{'Maximum liability for this bet is : ' + selections[0].win.liability + ' + ' + selections[0].back2_liability + ' + ' + selections[0].back3_liability + ' = ' + selections[0].total_liability}</StyledBetPlacementContainer__BetMessage>: ""}


              <StyledBetPlacementContainer__ExtraButtonGroup flex>
                <FeatureContext.Consumer>
                  {(featureToggles) => {
                    return showQuickDeposit && featureToggles.features.depositsAndWithdrawals.enabled ? (
                      <StyledBetPlacementContainer__ExtraButton type="secondary" action={this.handleQuickDeposit}>
                        {t('BetPromptContainer__QuickDepositTitle')}
                      </StyledBetPlacementContainer__ExtraButton>
                    ) : (
                      <StyledBetPlacementContainer__ExtraButton type="secondary" action={this.handleCancel}>
                        {t('Cancel')}
                      </StyledBetPlacementContainer__ExtraButton>
                    );
                  }}
                </FeatureContext.Consumer>
                {handleClear && (
                  <StyledBetPlacementContainer__ExtraButton type="secondary" action={this.onClear}>
                    {t('BetPlacementContainer__ClearSelection')}
                  </StyledBetPlacementContainer__ExtraButton>
                )}


                  <StyledBetPlacementContainer__ExtraButton type="secondary" action={this.handleAddToMulti} disabled={this.state.stake / 100 > selections[0].total_liability}>
                    {t('BetPlacementContainer__AddToBetSlip')}
                  </StyledBetPlacementContainer__ExtraButton>


              </StyledBetPlacementContainer__ExtraButtonGroup>*/}

							{isRacing &&
								betSelectionProps.selectedProductIds &&
								betSelectionProps.selectedProductIds.length && (
									<RaceProductLegend
										products={race.products}
										displayedProducts={betSelectionProps.selectedProductIds}
										hideExotics={!isExotic}
									/>
								)}
						</StyledBetPlacementContainer__BetPlacementOptions>
					</div>
				)}
			</SizeMe>
		);
	}
}

export default withNamespaces()(BetPlacementContainer);
