export const BRAND_NAME = 'RAS';
// export const DEFAULT_MAIN_LOGO = 'https://grsresource.s3.ap-southeast-2.amazonaws.com/site-resources/images/wagering360-logo.png';
export const DEFAULT_MAIN_LOGO = 'https://grsresource.s3.ap-southeast-2.amazonaws.com/site-resources/images/GRSTLogo250.50.png';
export const DEFAULT_SECONDARY_LOGO = 'https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/GRSTLogo.png';
// export const DEFAULT_LOGO_MAX_WIDTH = '200px';


export const DEFAULT_BRAND_LOGOS = {
	// main: DEFAULT_MAIN_LOGO,
	main: DEFAULT_SECONDARY_LOGO,
	secondary: DEFAULT_SECONDARY_LOGO,
	// maxWidth: DEFAULT_LOGO_MAX_WIDTH,
};
