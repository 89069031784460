import {Sport_Cricket_PAGE_SET_LOADING_MASK} from './sport_cricketPageActionTypes';

const initialState = {
  loadingContainer: undefined,
};
export default function SportCricketHome(state = initialState, action){
  switch (action.type) {
    case Sport_Cricket_PAGE_SET_LOADING_MASK:
      return {
        ...state,
        loadingContainer: action.payload,
      };
    default:
      return state;
  }

}
