import {
	RACING_GREYHOUNDS_CODE,
	RACING_HARNESS_CODE,
	RACING_NON_EXOTIC_BET_TYPE,
	RACING_THOROUGHBRED_CODE,
} from '../../common/constants/Racing';
import {
	RACING_HOME_RESET_FILTERS,
	GRS_RACING_HOME_RESET_FILTERS,
	RACING_HOME_SET_SELECTED_DATE,
	RACING_HOME_SET_SELECTED_BET_TYPE,
	RACING_HOME_SET_SHOWING_MEETINGS,
	RACING_HOME_SET_SHOWING_NTJ_RACES,
	RACING_HOME_SET_CONTAINER_LOADING_MASK,
	RACING_HOME_SET_MEETING_LOADING_MASK,
	RACING_HOME_SET_RACE_LOADING_MASK,
	RACING_EXOTIC_QUADDIE_TOGGLE,
	RACING_EXOTIC_DAILYDOUBLE_TOGGLE,
	RACING_HOME_SET_EXOTIC_POOLS,
	RACING_EXOTIC_SELECTIONS_TOGGLE,
	GRS_RACING_HOME_SET_SELECTED_TYPE,
	RACING_HOME_SET_BET_BUILDER_MEETING,
	BET_QUADDIES_RESET_LEG_SELECTIONS,
	BET_QUADDIES_LEG_SELECTIONS,
	SET_SELECTED_QUADDIES_RACES,
	GRS_RACING_HOME_SET_RACE_TYPE_FILTERS,
	SET_REQUEST_ID,
} from './GRSracingHomeActionType';

const initialState = {
	selectedRace: undefined,
	selectedMeeting: undefined,
	selectedDate: undefined,
	selectedBetType: undefined,
	betTypeBoxed: undefined,
	showingMeetings: [],
	showingNTJRaces: [],
	loadingMeeting: undefined,
	loadingRace: undefined,
	loadingContainer: undefined,
	showQuaddieRaces: false,
	showDailyDoubleRaces: false,
	exoticPools: [],
	selectedType: undefined,
	raceTypeFilters: [RACING_THOROUGHBRED_CODE, RACING_HARNESS_CODE, RACING_GREYHOUNDS_CODE],
	selectedBuilderMeeting: undefined,
	quaddiesLegsSelections: {},
	selectedQuaddiesRaces: [],
	requestId: null,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case RACING_HOME_RESET_FILTERS:
			return {
				...state,
				selectedRace: action.raceId || null,
				selectedMeeting: action.meetingId || null,
				selectedType: null,
				selectedDate: null,
				raceTypeFilters: [RACING_THOROUGHBRED_CODE, RACING_HARNESS_CODE, RACING_GREYHOUNDS_CODE],
				selectedBetType: RACING_NON_EXOTIC_BET_TYPE,
				betTypeBoxed: false,
				quaddiesLegsSelections: action.resetQuaddies ? {} : state.quaddiesLegsSelections,
			};
		case GRS_RACING_HOME_RESET_FILTERS:
			return {
				...state,
				selectedRace: action.raceId || null,
				selectedMeeting: action.meetingId || null,
				selectedType: action.type || null,
				selectedDate: action.date || null,
				raceTypeFilters: [RACING_THOROUGHBRED_CODE, RACING_HARNESS_CODE, RACING_GREYHOUNDS_CODE],
				selectedBetType: RACING_NON_EXOTIC_BET_TYPE,
				betTypeBoxed: false,
				quaddiesLegsSelections: action.resetQuaddies ? {} : state.quaddiesLegsSelections,
			};
		case GRS_RACING_HOME_SET_RACE_TYPE_FILTERS:
			return {
				...state,
				raceTypeFilters:
					action.payload.length === 0
						? [RACING_THOROUGHBRED_CODE, RACING_HARNESS_CODE, RACING_GREYHOUNDS_CODE]
						: action.payload,
			};
		case RACING_HOME_SET_SELECTED_DATE:
			return {
				...state,
				selectedDate: action.payload,
			};

		case GRS_RACING_HOME_SET_SELECTED_TYPE:
			return {
				...state,
				selectedType: action.payload,
			};
		case RACING_HOME_SET_SELECTED_BET_TYPE:
			/**
			 * If any parameter is not passed to the action
			 * related to this case, the state will keep it's value
			 * for the regarding field.
			 */
			return {
				...state,
				selectedBetType: typeof action.selectedBetType === 'undefined' ? state.selectedBetType : action.selectedBetType,
				betTypeBoxed: typeof action.betTypeBoxed === 'undefined' ? state.betTypeBoxed : action.betTypeBoxed,
			};

		case RACING_HOME_SET_SHOWING_MEETINGS:
			return {
				...state,
				showingMeetings: action.payload,
			};

		case RACING_HOME_SET_EXOTIC_POOLS:
			return {
				...state,
				exoticPools: action.payload,
			};
		// bet builder
		case RACING_HOME_SET_BET_BUILDER_MEETING:
			return {
				...state,
				selectedBuilderMeeting: action.payload,
			};
		case RACING_HOME_SET_SHOWING_NTJ_RACES:
			return {
				...state,
				showingNTJRaces: action.payload,
			};

		case RACING_HOME_SET_MEETING_LOADING_MASK:
			return {
				...state,
				loadingMeeting: action.payload,
			};
		case RACING_HOME_SET_RACE_LOADING_MASK:
			return {
				...state,
				loadingRace: action.payload,
			};
		case RACING_HOME_SET_CONTAINER_LOADING_MASK:
			return {
				...state,
				loadingContainer: action.payload,
			};

		case RACING_EXOTIC_QUADDIE_TOGGLE:
			return {
				...state,
				showQuaddieRaces: !state.showQuaddieRaces,
				showDailyDoubleRaces: false,
			};

		case RACING_EXOTIC_DAILYDOUBLE_TOGGLE:
			return {
				...state,
				showDailyDoubleRaces: !state.showDailyDoubleRaces,
				showQuaddieRaces: false,
			};

		case RACING_EXOTIC_SELECTIONS_TOGGLE:
			return {
				...state,
				clearSelections: !state.clearSelections,
			};

		case BET_QUADDIES_RESET_LEG_SELECTIONS:
			return {
				...state,
				quaddiesLegsSelections: [],
			};
		case BET_QUADDIES_LEG_SELECTIONS:
			return {
				...state,
				quaddiesLegsSelections: action.payload,
			};

		case SET_SELECTED_QUADDIES_RACES:
			return {
				...state,
				selectedQuaddiesRaces: action.payload,
			};
		case SET_REQUEST_ID:
			return {
				...state,
				requestId: action.payload,
			};
		default:
			return state;
	}
}
