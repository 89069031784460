/**
 * List of available locales can be found here:
 *  https://github.com/adamwdraper/Numeral-js/tree/master/locales
 *
 * If a locale can not be found you MUST define it as unlike moment
 * numeral will not revert back to the default if a locale cannot be set.
 *
 * List of the ISO-639 2 letter country codes can be found here
 *  https://www.loc.gov/standards/iso639-2/php/English_list.php
 */
import 'numeral/locales/en-au';
import 'numeral/locales/pl';
import 'numeral/locales/tr';
import 'numeral/locales/ja';
import 'numeral/locales/ru';
