import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';
import styled, { css } from 'react-emotion';

import { typography, Table } from '@tbh/ui-kit';
import { formatOdds } from '../../../../../common/BetPlacement';

const StyledRaceResultsWinPlace__Header = styled(Table.TR)(
	(props) => css`
		label: RaceResultsWinPlace__Header;

		font-size: ${typography(props)['size_-3']};
	`,
);

const RaceResultsWinPlace = (props) => {
	const { t, className, results } = props;

	const componentClasses = cx({
		[className]: className,
	});


	return (
		<Table density="cozy" size="-2" className={componentClasses}>
			<Table.TG thead compact size="-3">
				<StyledRaceResultsWinPlace__Header bottomBorder>
					<Table.TD strong center>
						{t('Position')}
					</Table.TD>
					<Table.TD strong center>
						{t('No.')}
					</Table.TD>
					<Table.TD strong colSpan="4">
						{t('Runner')}
					</Table.TD>
					<Table.TD strong right>
						{t('Win')}
					</Table.TD>
					<Table.TD strong right>
						{t('Place')}
					</Table.TD>
				</StyledRaceResultsWinPlace__Header>
			</Table.TG>
			{results.map((result) => (
				<Table.TR key={result.selection_id} bottomBorder>
					<Table.TD center>{result.position}</Table.TD>
					<Table.TD center>{result.number}</Table.TD>
					<Table.TD colSpan="4">{result.name}</Table.TD>
					<Table.TD right>{result.win_dividend}</Table.TD>
					<Table.TD right>{result.place_dividend > 0 && formatOdds(result.place_dividend)}</Table.TD>
				</Table.TR>
			))}
		</Table>
	);
};

RaceResultsWinPlace.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** List of standard race results */
	results: PropTypes.arrayOf(
		PropTypes.shape({
			/** The runner position */
			position: PropTypes.number.isRequired,

			/** The runner number */
			number: PropTypes.number.isRequired,

			/** The runner name */
			name: PropTypes.string.isRequired,

			/** The runner win amount */
			winDividend: PropTypes.number,

			/** The runner place amount */
			placeDividend: PropTypes.number,
		}),
	).isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

RaceResultsWinPlace.defaultProps = {
	winDividend: null,
	placeDividend: null,
	className: null,
};

export default withNamespaces()(RaceResultsWinPlace);
