// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { Trans, withNamespaces } from 'react-i18next';

import { spacings, Button, Notification, Text, Panel, Tabs, TabsItem } from '@tbh/ui-kit';

const StyledSetTimer__Notification = styled(Notification)(
	(props) => css`
		label: SetTimer__Notification;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const StyledSetTimer__Tabs = styled(Tabs)(
	(props) => css`
		label: SetTimer__Tabs;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const StyledSetTimerPanel = styled(Panel)(
	(props) => css`
	label: SetTimer__panel;

	
`,

);

class SetTimer extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** User's first name */
		first_name: PropTypes.string.isRequired,

		/** Hook for setting/updating the Reality Check */
		setRealityCheck: PropTypes.func.isRequired,

		/** Clears the message */
		clearMessage: PropTypes.func.isRequired,

		/** Extra classes */
		className: PropTypes.string,

		/** Error returned from the function setRealityCheck */
		error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

		/** Show or not the confirmation message */
		confirmation: PropTypes.bool,

		/** User's current reality check */
		reality_check: PropTypes.number,

		/** If the update request is loading or not */
		updateIsLoading: PropTypes.bool,

		/** Brand name */
		brandName: PropTypes.string,
	};

	constructor(props) {
        super(props);

        this.state = {
            option: props.reality_check > 0 ? props.reality_check.toString() : '0',
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reality_check !== this.props.reality_check) {
            this.setState({ option: this.props.reality_check > 0 ? this.props.reality_check.toString() : '0' });
        }
    }

    handleChangeSelection = (data) => {
        this.setState({ option: data });
    };

    handleSetRealityCheck = () => {
        const newRealityCheck = parseInt(this.state.option, 10);

        this.props.clearMessage();
        this.props.setRealityCheck(newRealityCheck)
            .then(() => {
                // console.log('setRealityCheck successful:', newRealityCheck);
                this.setState({ option: newRealityCheck.toString() });
            })
            .catch(error => {
                console.error('Error setting reality check:', error);
            });
    };

    render() {
        const {
            t,
            confirmation,
            first_name,
            error,
            clearMessage,
            className,
            updateIsLoading,
            brandName,
        } = this.props;

        const { option } = this.state;

        const realityCheckSettingsClass = cx({
            [className]: className,
        });

        return (
            <StyledSetTimerPanel className={realityCheckSettingsClass} padding={Panel.padding.SPACING_COZY}>
                {error || confirmation ? (
                    <StyledSetTimer__Notification
                        strong={Boolean(error)}
                        transparent={Boolean(!error)}
                        type={confirmation ? Notification.types.COLOUR_SUCCESS : Notification.types.COLOUR_DANGER}
                        message={confirmation ? t('SetTimer__Updated') : error}
                        buttonAction={clearMessage}
                    />
                ) : null}

                <Text size="-1" align="center" paragraph>
                    <Trans i18nKey="SetTimer__RealityCheck" values={{ first_name }}>
                        {{ first_name }}, your current <strong>Reality Check</strong> is:
                    </Trans>
                </Text>
                <Text type="success" size="3" align="center" paragraph strong>
                    {!parseInt(option, 10) ? t('None') : t('SetTimer__Hour', { count: parseInt(option, 10) })}
                </Text>
                <Text size="-1" align="center" paragraph>
                    {t('SetTimer__Check')}
                </Text>

                <StyledSetTimer__Tabs wrappingBorder justify padding={Tabs.paddings.SPACING_COMPACT}>
                    <TabsItem action={this.handleChangeSelection} data="0" active={option === '0'}>
                        {t('None')}
                    </TabsItem>
                    <TabsItem action={this.handleChangeSelection} data="1" active={option === '1'}>
                        {t('SetTimer__Hour', { count: 1 })}
                    </TabsItem>
                    <TabsItem action={this.handleChangeSelection} data="2" active={option === '2'}>
                        {t('SetTimer__Hour', { count: 2 })}
                    </TabsItem>
                    <TabsItem action={this.handleChangeSelection} data="4" active={option === '4'}>
                        {t('SetTimer__Hour', { count: 4 })}
                    </TabsItem>
                    <TabsItem action={this.handleChangeSelection} data="6" active={option === '6'}>
                        {t('SetTimer__Hour', { count: 6 })}
                    </TabsItem>
                </StyledSetTimer__Tabs>

                <Text size="-1" paragraph>
                    {t('SetTimer__Alert')}
                </Text>
                <Text size="-1" paragraph strong>
                    {`${brandName ? brandName + ' ' : ''}${t('SetTimer__SupportTeam')}`}
                </Text>

                <Button type="primary" action={this.handleSetRealityCheck} loading={updateIsLoading} block>
                    {t('SetTimer__Update')}
                </Button>
            </StyledSetTimerPanel>
        );
    }
}

export default withNamespaces()(SetTimer);