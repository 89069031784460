import moment from 'moment';
/**
 * Sort array of NTJ Races
 * @param ntjRaces
 * @return {Array.<*>}
 */
export const sortRacesToJumpByStartTime = (ntjRaces = []) => {
	return ntjRaces.sort((a, b) => moment(a.start_datetime) - moment(b.start_datetime));
};
/**
 * Sort the NTJ Races by start date first and filter the first 4 races
 * that have TBF prices and are not in the past
 *
 * @param ntjRaces
 * @returns {Array.<*>}
 */
export const filterFourNextRacesToJump = (ntjRaces = []) => {
	// Returns the TBF price from a race that is not in the past
	const races = ntjRaces.filter(
		(race) =>
			race.to_go !== 'past' &&
			race.selections
	).sort((a, b) => moment(a.start_datetime) - moment(b.start_datetime));
	return races.slice(0, 4);
};

{/*
			race.selection &&
			race.selection.prices &&
			race.selection.prices.some((price) => price.product_code === 'TBF'),
console.log(race.selection.some((selection) => selection.prices)
console.log(race.selection.some(selection) => selection.name) &&*/}
{/*		let exoticPools = response.data.data.map((meeting) => ({
			meetingId: meeting.id,
			exoticPools: meeting.exotic_pools,
		}));*/}

/**
 * Filter next to jump races by the given types
 *
 * @param {Array} ntjRaces Array with ntjRaces
 * @param {Array} types    Array with constants
 */
export const filterNextToJumpRaces = (ntjRaces = [], types = []) => {
	if (!types) {
		return ntjRaces;
	}

	if (!Array.isArray(types)) {
		types = [types];
	}
	let items = ntjRaces.filter((race) => types.includes(race.type));
	return items.length ? items : ntjRaces;
};

/**
 * Filter next to jump races by the given countrys
 *
 * @param {Array} ntjRaces Array with ntjRaces
 * @param {Array} countrys    Array with constants
 */
export const filterNextToJumpRacesbyCountry = (ntjRaces = [], countrys = []) => {
	if (!countrys) {
		return ntjRaces;
	}

	if (!Array.isArray(countrys)) {
		countrys = [countrys];
	}
	let items = ntjRaces.filter((race) => countrys.includes(race.country));
	return items;
};
