import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { css } from 'react-emotion';

import { Button } from '@tbh/ui-kit';


import * as BBS from './BetBuilderMeetingCardStyled';

import {
	RACING_THOROUGHBRED_CODE,
	RACING_GREYHOUNDS_CODE,
	RACING_HARNESS_CODE,
} from '../../../../common/constants/Racing';

import BetBuilderRaceCardFilter from '../RaceBetBuilder/BetBuilderRaceCardFilter/BetBuilderRaceCardFilter';
import {betBuilderMeetingSelectionsCount} from '../../../../store/entities/selectors/MeetingSelectors';

class BetBuilderMeetingCard extends React.Component{
  static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
			height: PropTypes.number,
    }).isRequired,

    /** Extra classes */
		className: PropTypes.string,

		/** Passed via ownProps from parent */
		meetingId: PropTypes.number.isRequired,

	

    betBuilderMeeting: PropTypes.shape({
      /** The id of the meeting */
      id: PropTypes.number.isRequired,

      /** The name of the meeting */
      name: PropTypes.string.isRequired,

      /** The type of race ie. Thoroughbred, Harness, Greyhounds */
      type: PropTypes.oneOf([RACING_THOROUGHBRED_CODE, RACING_HARNESS_CODE, RACING_GREYHOUNDS_CODE]).isRequired,

      /** The starting time of the meeting */
      start_date: PropTypes.string.isRequired,

      state: PropTypes.string.isRequired,

              country: PropTypes.string.isRequired,
              
              races: PropTypes.arrayOf(
                  PropTypes.shape({
                      /** The id of the race */
                      id: PropTypes.number.isRequired,

                      /** The name of the race */
                      name: PropTypes.string.isRequired,

                      /** The race number */
                      number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

                      /** Meeting name */
                      meeting_name: PropTypes.string.isRequired,

                      /** Meeting id of this race */
                      meeting_id: PropTypes.number.isRequired,

                      /** bet builder race products object */
                      products: PropTypes.array.isRequired,
                      
                      /** Array of objects that contain all the selection details */
                      selections: PropTypes.arrayOf(
                          PropTypes.shape({
                              /**
                               * Selections will contain all the selection details but besides 'id' they are not needed to be defined here.
                               * They are defined in the RaceSelectionsListItem
                               */
                              id: PropTypes.number.isRequired,

                              name: PropTypes.string.isRequired,

                              /** The list of prices for the selection */
                              prices: PropTypes.arrayOf(
                                  PropTypes.shape({
                                      id: PropTypes.number, //@HW
                                      /** The current price for a win bet with this product */
                                      win_odds: PropTypes.number,

                                      /** The current price for a place bet */
                                      place_odds: PropTypes.number,

                                      /** The current price for a margin bet */
                                      margin_odds: PropTypes.number,

                                      /** The code for this product with this product */
                                      product_code: PropTypes.string.isRequired,

                                      /** The product id (NOTE: this is not a unique id between products) */
                                      product_id: PropTypes.number.isRequired,
                                  })
                              ),
                          }),
                      ).isRequired,
             }),
              ),

    }).isRequired,

    onGoToBetBulder: PropTypes.func,

    /** Action to track when a filter is applied */
		trackOnFilterClick: PropTypes.func,
     
  };

  static defaultProps = {
    className: '',
    onGoToBetBulder: null,
    trackOnFilterClick: () => undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      // initially set all the type filters active
      typeFilter: new Set([]),
    };
  }
  BetBuilder_Button = () =>{
    const { meetingId } = this.props;
      this.props.onGoToBetBulder(meetingId);

      localStorage.setItem('FilterItems', JSON.stringify(Array.from(this.state.typeFilter)));
  };

  /**
	 * Handles the clicking/selecting of the type filters
	 * @param type
	 */
	handleOnFilterClick = (type) => {
		this.saveTypeToFilters(type);
		this.props.trackOnFilterClick(type);
	};

	/**
	 * Saves the type filters to state
	 * @param type
	 */
	saveTypeToFilters = (type) => {
		let types = new Set(this.state.typeFilter);

		if (!type) {
			return;
		}

		if (types.has(type)) {
			types.delete(type);
		} else {
			types.add(type);
		}

		this.setState({
			typeFilter: types,
		});
  };
  

  render() {

    const {
			      className,
            size ,
            betBuilderMeeting,
            meetingId,
        } = this.props;
        const componentClassesV3 = cx({
          [className]: className,
        });

    const modeChangeOverSize = 751;

    const betBuilderSelectionCount = betBuilderMeetingSelectionsCount(meetingId,betBuilderMeeting,Array.from(this.state.typeFilter));
   
    return (
      <BBS.StyledRaceCard__BetBuilder__Content>
        <BBS.StyledBetbuilder_MeetingCard__Filters_section>
          <BetBuilderRaceCardFilter
             mode={size.width > modeChangeOverSize ? 'vertical' : 'horizontal'}
             onFilterItemClick={this.handleOnFilterClick}
             types={Array.from(this.state.typeFilter)}
             size={size}
           />

        </BBS.StyledBetbuilder_MeetingCard__Filters_section>


        <BBS.StyledRaceCard__BetBuilder__Meeter>
          <BBS.StyledRaceCard__BetBuilder__Card__Content__Marks__Lable>
            <span className={css`color:#000;`}>{betBuilderSelectionCount}</span>
          </BBS.StyledRaceCard__BetBuilder__Card__Content__Marks__Lable>
          <BBS.StyledRaceCard__BetBuilder__Card__Content__Lable>
            <span className={css`color:#000;`}>{'matching results'}</span>
          </BBS.StyledRaceCard__BetBuilder__Card__Content__Lable>
          <BBS.StyledRaceCard__BetBuilder__Card__Content__Button>
              <Button
                key={''}
                action={() => this.BetBuilder_Button()}
                className={css`width:120px;font-size:10px;box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);background-color: #E6E6E6;border: 2px solid #f44336;padding: 10px;text-align: center;text-decoration: none;border-radius: 8px;`}
              >
                <span>{'SHOW SELECTIONS'}</span>
              </Button>
          </BBS.StyledRaceCard__BetBuilder__Card__Content__Button>
        </BBS.StyledRaceCard__BetBuilder__Meeter>
      </BBS.StyledRaceCard__BetBuilder__Content>
    );
  }
}

export default BetBuilderMeetingCard;
