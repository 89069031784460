// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames/bind';

// Actions
import { excludeUser } from '../../../../../store/entities/actions/UserActions';
// Components
import SelfExclusion from '../../../../../components/features/ResponsibleGambling/SelfExclusion/SelfExclusion';
import { Container, StyledExtraContentContainer, StyledSectionTitle } from '../../Components';
import { logoutUser } from '../../../../../store/authentication/authenticationActions';
import { Text } from '@tbh/ui-kit';

class SelfExclusionContainer extends Component {
	static propTypes = {
		/** User's email */
		email: PropTypes.string.isRequired,

		/** User's id */
		userId: PropTypes.number.isRequired,

		/** Excludes the user permanently */
		excludeUser: PropTypes.func.isRequired,

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		className: '',
	};

	state = {
		error: null,
		loading: false,
	};

	/**
	 * Calls the action to permanently block the user
	 * Set an error message if the action fails
	 *
	 * @param email
	 * @param reason
	 */
	handleExcludeUser = (email, reason) => {
		this.setLoadingMask(true);

		return this.props
			.excludeUser(email, reason)
			.catch((error) => {
				if (error.response.status === 400) {
					this.setState({
						error: error.response.data.errors,
					});
				} else {
					this.setState({
						error: error.message,
					});
				}
			})
			.finally(() => {
				this.setLoadingMask(false);
			});
	};

	/**
	 * Set a loading mask
	 *
	 * @param loading
	 */
	setLoadingMask(loading = false) {
		this.setState({
			loading: loading,
		});
	}

	/**
	 * Remove the error message
	 */
	handleClearError = () => {
		this.setState({
			error: null,
		});
	};

	render() {
		const { className, email } = this.props;
		const { error, loading } = this.state;

		const containerClasses = cx({
			[className]: className,
		});

		return (
			<div>
				<Text size="1">Self Exclusion</Text>
				<Container>
					<StyledExtraContentContainer>
						<SelfExclusion
							className={containerClasses}
							email={email}
							excludeUser={this.handleExcludeUser}
							clearError={this.handleClearError}
							error={error}
							loading={loading}
						/>
					</StyledExtraContentContainer>
				</Container>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		email: state.entities.users[state.application.authenticatedUser].email,
		userId: state.application.authenticatedUser,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		excludeUser: (email, reason) => {
			return dispatch(excludeUser(email, reason)).then(() => dispatch(logoutUser(true, true, true)));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SelfExclusionContainer);
