import moment from 'moment';

/**
 * Sort array of NTJ Sports
 * @param ntjSports
 * @return {Array.<*>}
 */
export const sortSportsToJumpByStartTime = (ntjSports = []) => {
	return ntjSports.sort((a, b) => moment(a.start_time) - moment(b.start_time));
};

/**
 * Filter next to jump sports by the given types
 *
 * @param {Array} ntjSports Array with ntjSports
 * @param {Array} sportIds    Array with constants
 */
export const filterNextToJumpSports = (ntjSports = [], sportIds = []) => {
	if (!sportIds) {
		return ntjSports;
	}

	if (!Array.isArray(sportIds)) {
		sportIds = [sportIds];
	}

	let items = ntjSports.filter((sport) => sportIds.includes(sport.sport_id));
	return items.length ? items : ntjSports;
};



export const groupBetSportBySportId = (array) => {
	
	Array.prototype.groupBy = function(prop) {
		return this.reduce(function(groups, item) {
			const val = item[prop];
			groups[val] = groups[val] || [];
			const mappedItem = `${item.sport_id}`;
			groups[val].push(mappedItem);
			return groups;
		}, {});
	};

	const sports = array.groupBy('sport_id');
	return sports;
};
