import React from 'react';
import { Button, ButtonGroup, Notification, Panel, Text } from '@tbh/ui-kit';
import ModalHeader from '../../../components/features/Application/ModalHeader/ModalHeader';
import { connect } from 'react-redux';
import { closeModal } from '../../../store/modal/modalActions';
import { logoutUser, openLoginForm } from '../../../store/authentication/authenticationActions';

const InactivityDialogContainer = ({ handleLogout, handleContinue }) => {
	return (
		<div>
			<ModalHeader title="Inactivity" />
			<Panel padding={Panel.padding.SPACING_COZY}>
				<Text size="-1" align="center" strong>
					You have been inactive for too long
				</Text>

				<Notification type="info" transparent message="You will be logged out automatically." />


				<ButtonGroup center>
					<Button type="ghost" action={handleLogout} flex>
						Sign out
					</Button>

					<Button type="primary" action={handleContinue} flex>
						Sign in
					</Button>
				</ButtonGroup>

			</Panel>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleContinue: () => {
			dispatch(closeModal('InactivityDialogContainer'));
			dispatch(openLoginForm());
		},
		handleLogout: () => {
			dispatch(closeModal('InactivityDialogContainer'));
			return dispatch(logoutUser());
		},
	};
};

export default connect(null, mapDispatchToProps)(InactivityDialogContainer);
