// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';

// Components
import { StyledBannerImage } from './BannersCarouselItems.style';

const BannersCarouselItems = (props) => {
	const { t, image, className, description, size } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<StyledBannerImage src={image} size={size} />
	);
};
BannersCarouselItems.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,

	image: PropTypes.string,

	description: PropTypes.string,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,
};

BannersCarouselItems.defaultProps = {
	className: '',
	image: null,
	description: null,
};

export default withNamespaces()(BannersCarouselItems);
