const initialState = {
    sessionCount: 0,
    timerOn: false
};

const sessionTimeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'START_SESSION_TIME':
            return {
                ...state,
                timerOn: false,
                sessionCount: interval
            };
        default:
            return state;
    }
};

export default sessionTimeReducer;