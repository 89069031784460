import { denormalize, normalize } from 'normalizr';
import { versionsSchema } from './Entities';

/**
 * Normalizes versions.
 *
 * @param response
 * @returns {Object}
 */

const normalizeVersions = (response) => {
	return normalize(response, versionsSchema);
};

/**
 * Denormalize versions.
 *
 * @param {object} entities
 * @param {Array} keys
 * @returns {Array}
 */
const denormalizeVersions = (entities, keys = null) => {
	return denormalize(keys || Object.keys(entities.versions), versionsSchema, entities);
};

export { normalizeVersions, denormalizeVersions };
