import { get } from '../../../common/Ajax';
import { resetEntities , mergeEntities} from '../../../common/actions/actionHelpers';
import { normalizeFeatureRaces } from '../schemas/FeatureRaceSchema';

/**
 * Reset the Feature races with the data from the pusher
 *
 * @param data
 */
export const resetFeatureRaces = (data) => {
	return resetEntities(normalizeFeatureRaces(data).entities);
};

/**
 * Normalizes and dispatch merge action for array of Feature Races.
 * @param {Array} data
 */
export const updateMeetings = (data) => {
	return mergeEntities(normalizeFeatureRaces(data).entities);
};

/**
 * Normalizes and dispatch merge action for array of feature Races.
 * @param {Array} races
 */
export const mergeFeatureRaces = (races) => {
	return mergeEntities(normalizeFeatureRaces(races).entities);
};

/**
 * Normalizes and dispatch merge action for a feature Race
 * @param race
 */
export const mergeFeatureRace = (race) => {
	return mergeFeatureRaces([race]);
};


/**
 * Fetch feature Races.
 * @HW 06Oct2020
 */
export const fetchGRSFeatureRaces = (date, race_type, limit ) => (dispatch) => {
	return get('racing/upcoming_races',{
		params: { date , race_type, limit },
	})
		.then((response) => {
			let featureRaces = response.data.data.map((featureRace) => ({ ...featureRace, requested_at: null }));
			let normalizedEntities = normalizeFeatureRaces(featureRaces);
			dispatch(mergeEntities(normalizedEntities.entities));
			response.featureRaceIds = normalizedEntities.result;
			return response;
		})
		.catch((error) => {
			console.error('Feature races request failed');
			return error;
		});
};
/**
 * show all feature races without date for welcome page
 * 
 * @param {*} race_type 
 * @param {*} limit 
 */
export const fetchGRSFeatureRacesWithoutDate = ( race_type, limit ) => (dispatch) => {
	return get('racing/upcoming_races',{
		params: { race_type, limit },
	})
		.then((response) => {
			let featureRaces = response.data.data.map((featureRace) => ({ ...featureRace, requested_at: null }));
			let normalizedEntities = normalizeFeatureRaces(featureRaces);
			dispatch(mergeEntities(normalizedEntities.entities));
			response.featureRaceIds = normalizedEntities.result;
			return response;
		})
		.catch((error) => {
			console.error('Feature races request failed');
			return error;
		});
};


