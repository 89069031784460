// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';

// Components
import { Icon, Link, Text, Panel } from '@tbh/ui-kit';

// Context
import AclContext from '../../../../../contexts/AclContext';

const ContactUsPanel = (props) => {
	const { className, t } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<AclContext.Consumer>
			{(acl) =>
				acl.brandDetails && (acl.brandDetails.phone || acl.brandDetails.email || acl.brandDetails.hours) ? (
					<Panel borderType="default" padding={Panel.padding.SPACING_COZY} className={componentClasses}>
						<Text paragraph size="-1" strong>
							{t('ContactUsPanel__Title')}
						</Text>

           
            				{acl.brandDetails.phone ? (
							<Text paragraph size="-2">
								{/* <Icon icon="phone" push="2" /> */}
								
								{/* <Link href={`tel:${acl.brandDetails.phone}`} size="-2">
									{acl.brandDetails.phone}
								</Link> */}
								{/* <Link href={ROCKET_CHAT_LINK} size="-2" target="blank">
									{acl.brandDetails.phone}
								</Link> */}
							</Text>
						) : null}
           
						{acl.brandDetails.email ? (
							<Text paragraph size="-2">
								<Icon icon="email" push="2" />
								<Link href={`mailto:${acl.brandDetails.email}`} size="-2">
									{acl.brandDetails.email}
								</Link>
							</Text>
						) : null}

						{acl.brandDetails.hours ? (
							<Text size="-2">
								<Icon icon="clock" push="2" />
								{acl.brandDetails.hours}
							</Text>
						) : null}
					</Panel>
				) : null
			}
		</AclContext.Consumer>
	);
};

ContactUsPanel.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

ContactUsPanel.defaultProps = {
	className: '',
};

export default withNamespaces()(ContactUsPanel);
