import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { spacings, typography, Icon, Tabs, TabsItem } from '@tbh/ui-kit';

const StyledDepositTypeFilter__TabsItem = styled(TabsItem)(
	(props) => css`
		label: DepositTypeFilter__TabsItem;

		font-size: ${typography(props)['size_-2']};
		padding: ${spacings(props).compact}px 0;
	`,
);

const StyledDepositTypeFilter__Icon = styled(Icon)(
	(props) => css`
		label: DepositTypeFilter__Icon;

		display: flex;
		justify-content: center;
		margin-bottom: ${spacings(props).constrictive}px;
	`,
);

const DepositTypeFilter = (props) => {
	const { t, action, depositType, className, disableTabs } = props;

	const componentClasses = cx({
		[className]: className,
	});

	/**
	 * Handles tab change
	 *
	 * @param data
	 */
	const handleFilterClick = (data) => {
		action(data);
	};

	return (
		<Tabs className={componentClasses} justify>
			<StyledDepositTypeFilter__TabsItem
				action={handleFilterClick}
				active={depositType === 'card'}
				data="card"
				disabled={disableTabs}
			>
				<StyledDepositTypeFilter__Icon icon="wallet" />
				{t('Debit Cards')}
			</StyledDepositTypeFilter__TabsItem>
			{/* <StyledDepositTypeFilter__TabsItem
				action={handleFilterClick}
				active={depositType === 'poli'}
				data="poli"
				disabled={disableTabs}
			>
				<StyledDepositTypeFilter__Icon icon="wallet" />
				POLi
			</StyledDepositTypeFilter__TabsItem> */}
			<StyledDepositTypeFilter__TabsItem
				action={handleFilterClick}
				active={depositType === 'bpay'}
				data="bpay"
				disabled={disableTabs}
			>
				<StyledDepositTypeFilter__Icon icon="wallet" />
				BPay
			</StyledDepositTypeFilter__TabsItem>
			<StyledDepositTypeFilter__TabsItem
				action={handleFilterClick}
				active={depositType === 'bank'}
				data="bank"
				disabled={disableTabs}
			>
				<StyledDepositTypeFilter__Icon icon="wallet" />
				{t('Bank')}
			</StyledDepositTypeFilter__TabsItem>
			{/*



			<StyledDepositTypeFilter__TabsItem
        action={handleFilterClick}
        active={depositType === 'poli'}
        data="poli"
        disabled={disableTabs}
      >
				<StyledDepositTypeFilter__Icon icon="wallet" />
        eWAY
			</StyledDepositTypeFilter__TabsItem>

      <StyledDepositTypeFilter__TabsItem
        action={handleFilterClick}
        active={depositType === 'bpay'}
        data="bpay"
        disabled={disableTabs}
      >
        <StyledDepositTypeFilter__Icon icon="wallet" />
        BPay
      </StyledDepositTypeFilter__TabsItem>


      <StyledDepositTypeFilter__TabsItem
				action={handleFilterClick}
				active={depositType === 'poli'}
				data="poli"
				disabled={disableTabs}
			>
				<StyledDepositTypeFilter__Icon icon="wallet" />
				POLi
			</StyledDepositTypeFilter__TabsItem>
			<StyledDepositTypeFilter__TabsItem
				action={handleFilterClick}
				active={depositType === 'bpay'}
				data="bpay"
				disabled={disableTabs}
			>
				<StyledDepositTypeFilter__Icon icon="wallet" />
				BPay
			</StyledDepositTypeFilter__TabsItem>
			<StyledDepositTypeFilter__TabsItem
				action={handleFilterClick}
				active={depositType === 'bank'}
				data="bank"
				disabled={disableTabs}
			>
				<StyledDepositTypeFilter__Icon icon="wallet" />
				{t('Bank')}
			</StyledDepositTypeFilter__TabsItem>

      */}
		</Tabs>
	);
};

DepositTypeFilter.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Action to fire when a tab is clicked */
	action: PropTypes.func.isRequired,

	/** The currently selected deposit type */
	depositType: PropTypes.oneOf(['card', 'poli', 'bpay', 'bank']),

	/** Extra class names to pass through */
	className: PropTypes.string,

	/** Whether the tabs are disabled or not */
	disableTabs: PropTypes.bool,
};

DepositTypeFilter.defaultProps = {
	depositType: 'card',
	className: '',
	disableTabs: false,
};

export default withNamespaces()(DepositTypeFilter);
