import {SPORT_NEW_UI_BASE_URL} from '../pages/Sports/SportsConstants';

export const buildRouteToSport_Cricket = () => {
  return `/${SPORT_NEW_UI_BASE_URL}`;
};

export const buildSportCricketRouteFormState = (state) => {
  const { entitis } = state;

  return `/${SPORT_NEW_UI_BASE_URL}`;
};
