import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, ui } from '@tbh/ui-kit';

import BetSelectionDetails from '../BetSelectionDetails/BetSelectionDetails';
import { Checkbox } from '@tbh/ui-kit';
import { Icon } from '@tbh/ui-kit';

/**
 * Styling
 */
const cssDisplayFlex = css`
	display: flex;
`;

const StyledBetSelectionMulti__Checkbox = styled('div')(
	(props) => css`
		label: BetSelectionMulti__Checkbox;

		flex: 0 0 16px;
		padding-right: ${spacings(props).compact}px;
	`,
);

const StyledBetSelectionMulti__Clear = styled('div')(
	(props) => css`
		label: BetSelectionMulti__Clear;

		margin-left: ${spacings(props).compact}px;
		text-align: right;
		cursor: pointer;
	`,
);

const StyledBetSelectionMulti__ClearIcon = styled(Icon)(
	(props) => css`
		label: BetSelectionMulti__ClearIcon;

		text-align: center;
		color: ${ui(props).color_danger};
		width: 20px;
		height: 20px;
		line-height: 16px;
	`,
);

export const StyleBetCupomPreview = styled('button')(`
	width: fit-content;
	height: fit-content;
	padding: 4px;
	border: 1px solid #ccc;
	border-radius: 4px;
	text-align: center;
	cursor: pointer;
	font-size: 12px;
	font-weight: 600;
`);

const BetSelectionMulti = (props) => {
	const {
		className,
		racingBetType,
		selectionName,
		betTypeLabel,
		eventName,
		price,
		placePrice,
		singleOnly,
		enableRemoval,
		multiBetId,
		onClickSelection,
		onMultiToggle,
		onMultiRemove,
		product_name,
		handleSelectedCupomBet,
		stake,
	} = props;

	const componentClasses = cx(cssDisplayFlex, {
		[className]: className,
	});

	/**
	 * Handle multi checkbox toggle
	 */
	const handleMultiToggle = () => {
		onMultiToggle(multiBetId);
	};

	/**
	 * Handle click on market
	 */
	const handleClickSelection = () => {
		onClickSelection(multiBetId);
	};

	/**
	 * Handle multi checkbox toggle
	 */
	const handleOnRemove = () => {
		onMultiRemove(multiBetId);
	};

	return (
		<div className={componentClasses}>
			<StyledBetSelectionMulti__Checkbox>
				<Checkbox
					name="multi"
					positive
					stacked
					disabled={racingBetType == 'EW'}
					checked={!singleOnly}
					//checked={true}
					//checked={racingBetType !== 'EW' && !singleOnly}
					action={handleMultiToggle}
					labelSize="-3"
				/>
			</StyledBetSelectionMulti__Checkbox>


			<BetSelectionDetails
				className={css`
					flex: 1;
				`}
				selectionName={selectionName}
				betTypeLabel={racingBetType ? betTypeLabel : null}
				marketDescription={!racingBetType ? betTypeLabel : null}
				eventName={eventName}
				product_name={product_name}
				//price={price}
				//placePrice={placePrice}
				racingBetType={racingBetType}
				onClickSelection={handleClickSelection}
			/>
			{/* {stake ? (
				<StyleBetCupomPreview onClick={() => handleSelectedCupomBet(multiBetId, 'single')}>Preview</StyleBetCupomPreview>
			) : null} */}

			{enableRemoval && (
				<StyledBetSelectionMulti__Clear>
					<StyledBetSelectionMulti__ClearIcon size="-2" icon="close" action={handleOnRemove} />
				</StyledBetSelectionMulti__Clear>
			)}
		</div>
	);
};

BetSelectionMulti.propTypes = {
	/** The id of the multi bet leg */
	multiBetId: PropTypes.number.isRequired,

	/** The name of the selection being bet on */
	selectionName: PropTypes.string.isRequired,

	/** The bet type being bet on */
	betTypeLabel: PropTypes.string.isRequired,

	/** Name of the event or competition */
	eventName: PropTypes.string.isRequired,

	/** Is the event being bet racing or sports */
	isRacing: PropTypes.bool.isRequired,

	/** The price or odds of the selection being bet on */
	price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

	/** If eachway then the place price or odds of the selection being bet on */
	placePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

	/** Function to call when the multi checkbox id toggled */
	onMultiToggle: PropTypes.func.isRequired,

	/** Function to call when the bet is to be removed from the multi bet slip */
	onMultiRemove: PropTypes.func.isRequired,

	/** Function to call when the bet selection name is clicked on */
	onClickSelection: PropTypes.func,

	/** The non-exotic bet type to use with the filters */
	racingBetType: PropTypes.string,

	/** Enable the bet to be removed from the multi bet slip */
	enableRemoval: PropTypes.bool,

	/** If the selection item is not enabled for multi */
	singleOnly: PropTypes.bool,

	/** Extra style class(es) to pass through */
	className: PropTypes.string,
};

BetSelectionMulti.defaultProps = {
	singleOnly: false,
	racingBetType: null,
	enableRemoval: true,
	placePrice: null,
	className: null,
	onClickSelection: () => { },
};

export default BetSelectionMulti;
