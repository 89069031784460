import { createSelector } from 'reselect';

/**
 * Memoized selector to get bets with one of the given id's
 */
import { getBetsWithIds } from '../../store/entities/selectors/BetSelectors';
import { getAllOddsForSelection } from '../../common/BetPlacement';
import { betPromptRaceId, getSelectedRace } from '../../pages/Racing/RacingHome/racingSelectorsGRS';
import { denormalizeSelections } from '../../store/entities/schemas/SelectionSchema';
import { denormalizeTournamentBets } from '../../store/entities/schemas/TournamentBetSchema';
import { denormalizeSportSelections } from '../../store/entities/schemas/SportSelectionSchema';
import { denormalizeDerivativeMarkets } from '../../store/entities/schemas/DerivativeMarketSchema';
import { denormalizeDerivativeSelections } from '../../store/entities/schemas/DerivativeSelectionSchema';
import {
	getBettingEntities,
	getTournamentBettingEntities,
} from '../../store/entities/schemas/relationships/BettingRelationships';
import { getRacingSelectionsEntities } from '../../store/entities/schemas/relationships/RacingRelationships';
import { getSportSelectionsEntities } from '../../store/entities/schemas/relationships/SportsRelationships';
import { getDerivativeSelectionsEntities } from '../../store/entities/schemas/relationships/DerivativeRelationships';

import { BET_PENDING_STATUS } from '../../common/constants/Bets';
import { PLACE_MULTI_BET } from '../../store/betPlacement/betPlacementReducerNames';
import { BET_TYPE_RACE, BET_TYPE_SPORT } from '../../store/entities/constants/BetConstants';

/**
 * Return whether or not there is a bet referred on the bet prompt
 */
export const isBetReferred = createSelector(
	(state) => getBetsWithId(state),
	(betsWithId) => betsWithId.some((bet) => bet.status === BET_PENDING_STATUS),
);

export const getBetsWithId = createSelector(
	(state) => getBettingEntities(state.entities),
	(state) => state.betPrompt.betsPlaced,
	(entities, betsPlaced) => getBetsWithIds(entities, betsPlaced),
);

export const getTournamentBetsWithId = createSelector(
	(state) => getTournamentBettingEntities(state.entities),
	(state) => state.betPrompt.betsPlaced,
	(entities, betsPlaced) => denormalizeTournamentBets(entities, betsPlaced),
);

export const getOddsForSelection = createSelector(
	(state, racingIdSlice = betPromptRaceId) => getSelectedRace(state, racingIdSlice),
	(state) => state.betPrompt.selections,
	(race, selections) => getAllOddsForSelection(race, selections),
);

/**
 * Reduce a list of selections to just the selection_id
 *
 * @param selections
 * @returns {*|Array}
 */
const filterSelections = (selections = []) =>
	selections.reduce((acc, selection) => {
		if (selection) {
			if (Number.isInteger(selection)) {
				acc.push(selection);
			} else if (typeof selection === 'object' && selection.id) {
				acc.push(selection.id);
			}
		}

		return acc;
	}, []);

/**
 * Get the selections ids added to the prompt.
 */
export const getSelectionIdsInBetPrompt = createSelector(
	(state) => state.betPrompt.selections,
	(promptSelections) => filterSelections(promptSelections),
);

/**
 * Get the selections ids added to the slip.
 */
export const getSelectionIdsInBetSlip = createSelector(
	(state) => state[PLACE_MULTI_BET].selections,
	(slipSelections) => filterSelections(slipSelections),
);

/**
 * Gets racing selections added to the bet prompt.
 */
export const getRacingSelectionsInPrompt = createSelector(
	(state) => getSelectionIdsInBetPrompt(state),
	(state) => getRacingSelectionsEntities(state.entities),
	(betPromptSelectionIds, entities) => denormalizeSelections(entities, betPromptSelectionIds),
);

/**
 * Gets racing selections added to the bet slip.
 */
export const getRacingSelectionsInSlip = createSelector(
	getSelectionIdsInBetSlip,
	(state) => getRacingSelectionsEntities(state.entities),
	(betSlipSelectionIds, entities) => denormalizeSelections(entities, betSlipSelectionIds),
);

/**
 * Gets sport selections added to the bet prompt.
 */
export const getSportSelectionsInPrompt = createSelector(
	getSelectionIdsInBetPrompt,
	(state) => getSportSelectionsEntities(state.entities),
	(betPromptSelectionIds, entities) => denormalizeSportSelections(entities, betPromptSelectionIds),
);

/**
 * Gets sport selections added to the bet slip.
 */
export const getSportSelectionsInSlip = createSelector(
	getSelectionIdsInBetSlip,
	(state) => getSportSelectionsEntities(state.entities),
	(betSlipSelectionIds, entities) => denormalizeSportSelections(entities, betSlipSelectionIds),
);

/**
 * Format the bet selection with the entity selection and the latest prices, for racing
 *
 * @param entitySelections
 * @param betSelections
 * @returns {*}
 */
const formatRacingSelections = (entitySelections, betSelections) =>

	betSelections.reduce((acc, betSelection) => {
		if (betSelection.type === BET_TYPE_RACE) {
			let newBetSelection = betSelection;
			// Resetting these in the selector ensures that we can capture any prices changes
			// without resetting the betPromptSelection on bet placement
			const selection = entitySelections.find((selection) => selection.id === betSelection.id);
			if (selection) {
				newBetSelection = { ...selection, ...betSelection };
				if (selection.prices) {
					if (newBetSelection.win) {
						const price = selection.prices.find(
							(price) =>
								price.product_id === newBetSelection.win.productId && price.product_code === newBetSelection.win.code,
						);
						if (price) {
							newBetSelection.win = {
								odds: price.win_odds,
								code: price.product_code,
								productId: price.product_id,
								fixed: newBetSelection.win.fixed,
                				liability:price.liability,
							};
						}
					}

					if (newBetSelection.place) {
						const price = selection.prices.find(
							(price) =>
								price.product_id === newBetSelection.place.productId &&
								price.product_code === newBetSelection.place.code,
						);
						if (price) {
							newBetSelection.place = {
								odds: price.place_odds,
								code: price.product_code,
								productId: price.product_id,
								fixed: newBetSelection.place.fixed,
                liability:price.liability,
							};
						}
					}
				}
			}
			acc.push(newBetSelection);
		}
		return acc;
	}, []);

/**
 * Format selections added to the bet prompt with latest prices.
 */
export const getSingleRaceSelectionsForPrompt = createSelector(
	getRacingSelectionsInPrompt,
	(state) => state.betPrompt.selections,
	(selections, betPromptSelections) => formatRacingSelections(selections, betPromptSelections),
);

/**
 * Format selections added to the bet slip with latest prices.
 */
export const getSingleRaceSelectionsForSlip = createSelector(
	getRacingSelectionsInSlip,
	(state) => state[PLACE_MULTI_BET].selections,
	(selections, betPromptSelections) => formatRacingSelections(selections, betPromptSelections),
);

/**
 * Format the bet selection with the entity selection, for sports
 *
 * @param entitySelections
 * @param betSelections
 * @returns {*}
 */
const formatSportSelections = (entitySelections, betSelections) =>
	betSelections.reduce((acc, betSelection) => {
		if (betSelection.type === BET_TYPE_SPORT) {
			let newBetSelection = betSelection;

			// Setting the base_odds in the selector ensures that we can capture any prices changes
			// without resetting the betPromptSelection on bet placement
			const selection = entitySelections.find((selection) => selection.id === betSelection.id);
			if (selection) {
				newBetSelection = { ...selection, ...betSelection };
				newBetSelection.base_odds = selection.odds || selection.price;
				newBetSelection.market =
					newBetSelection.market_id && selection.event && selection.event.markets
						? selection.event.markets.find((market) => market.id === newBetSelection.market_id)
						: null;
			}

			acc.push(newBetSelection);
		}

		return acc;
	}, []);

/**
 * Format selections added to the bet prompt with latest prices.
 */
export const getSportSelectionsForPrompt = createSelector(
	getSportSelectionsInPrompt,
	(state) => state.betPrompt.selections,
	(selections, betPromptSelections) => formatSportSelections(selections, betPromptSelections),
);

/**
 * Format selections added to the bet slip with latest prices.
 */
export const getSportSelectionsForSlip = createSelector(
	getSportSelectionsInSlip,
	(state) => state[PLACE_MULTI_BET].selections,
	(selections, betSlipSelections) => formatSportSelections(selections, betSlipSelections),
);

/**
 * Combine the sport and racing selections in the multi slip
 */
export const getSelectionsForBetSlip = createSelector(
	getSportSelectionsForSlip,
	getSingleRaceSelectionsForSlip,
	(sportSelections, racingSelections) => sportSelections.concat(racingSelections),
);

/**
 * Gets derivative selections added to the bet prompt.
 */
export const getDerivativeSelectionsInPrompt = createSelector(
	getSelectionIdsInBetPrompt,
	(state) => getDerivativeSelectionsEntities(state.entities),
	(betPromptSelectionIds, entities) =>
		denormalizeDerivativeSelections(getDerivativeSelectionsEntities(entities), betPromptSelectionIds),
);

/**
 * Gets the derivative market added to the bet prompt
 */
export const getDerivativeMarketInPrompt = createSelector(
	(state) => state,
	(state) => state.betPrompt.derivativeMarketId,
	(state, derivativeMarketId) => denormalizeDerivativeMarkets(state.entities, [derivativeMarketId]),
);
