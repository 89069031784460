import CurrencyEntryKeypad from '../../Application/Currency/CurrencyEntryKeypad/CurrencyEntryKeypad';
import styled, { css } from 'react-emotion';
import { spacings, ui, Button, typography, application } from '@tbh/ui-kit';

import { ButtonGroup, Checkbox, Notification } from '@tbh/ui-kit';
import CreditCardSelectForm from '../../../ui-components/Forms/CreditCardSelectForm/CreditCardSelectForm';
import CreditCardForm from '../../../ui-components/Forms/CreditCardForm/CreditCardForm';

export const StyledDepositForm__CurrencyKeypad = styled(CurrencyEntryKeypad)(
	(props) => css`
		label: DepositReceipt__CurrencyKeypad;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

export const StyledClearButton = styled(Button)`
	${(props) =>
  css`
			label: CurrencyButton;

			flex: 1;
			margin: ${spacings(props).tight}px;
			font-size: ${typography(props)['size_-3']};

			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		`};
`;


export const StyledDepositForm__NewCard = styled(CreditCardForm)(
	(props) => css`
		label: DepositReceipt__NewCard;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

export const StyledDepositForm__Section = styled('div')(
	(props) => css`
		label: DepositReceipt__Section;

		padding: ${spacings(props).cozy}px;
	`,
);

export const StyledDepositForm__SelectArea = styled(CreditCardSelectForm)(
	(props) => css`
		label: DepositReceipt__SelectArea;

		background-color: ${ui(props).color_3};
		padding: ${spacings(props).cozy}px;
	`,
);

export const StyledDepositForm__Notification = styled(Notification)(
	(props) => css`
		label: DepositReceipt__Notification;

		margin-top: ${spacings(props).cozy}px;
	`,
);

export const StyledDepositForm__TermsAcceptance = styled('div')(
	(props) => css`
		label: DepositReceipt__TermsAcceptance;

		margin: ${spacings(props).cozy}px 0 0 ${spacings(props).cozy}px;
	`,
);

export const StyledDepositForm__Actions = styled(ButtonGroup)(
	(props) => css`
		label: DepositReceipt__Actions;

		display: flex;
		flex-direction: column;
		gap: ${spacings(props).cozy}px;
		padding: ${spacings(props).cozy}px;
	`,
);

export const StyledDepositForm__TermsCheckbox = styled(Checkbox)(
	(props) => css`
		label: DepositReceipt__TermsCheckbox;

		margin-right: ${spacings(props).compact}px;
	`,
);

export const StyledDepositButton = styled(Button)(
	(props) => css`
		label: DepositReceipt__Button;
		width: 100%;
		margin: 0px!important;
		border-radius: ${application(props).border_radius_2}px
	`,
);