import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NextSportsList from '../../../components/features/Sports/NextSportsList/NextSportsList';
import { selectEvent } from '../../../store/sportsHome/sportsHomeActions';
import { trackGaEvent } from '../../../store/trackingPixels/trackingActions';
import { getNextToJumpSports } from '../../../store/entities/selectors/NextToJumpSportsSelectors';

const mapStateToProps = (state, ownProps) => ({
	className: ownProps.className,
	items: getNextToJumpSports(state),
});

const mapDispatchToProps = (dispatch) => ({
	onItemClick: (competitionId, eventId) => {
		dispatch(trackGaEvent('Sports', 'Filter'));
		return dispatch(selectEvent(competitionId, eventId));
	},
});

const Container = connect(mapStateToProps, mapDispatchToProps)(NextSportsList);

Container.propTypes = {
	className: PropTypes.string,
};

Container.defaultProps = {
	className: null,
};

export default Container;
