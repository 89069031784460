import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, Text, CountDown, PlotElements } from '@tbh/ui-kit';

const StyledBetPendingReview__Section = styled('div')(
	(props) => css`
		label: BetPendingReview__Section;

		margin-top: ${spacings(props).cozy}px;
		max-width: 100%;
	`,
);

const BetPendingReview = (props) => {
	const { className, infoMessage, statusMessage, t } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<PlotElements className={componentClasses} align="space-between" vertical>
			<StyledBetPendingReview__Section>
				<Text align="center" strong paragraph>
					{t('BetPendingReview__Title')}
				</Text>
			</StyledBetPendingReview__Section>
			<StyledBetPendingReview__Section>
				<CountDown startNumber="PENDING" countdown={false} pulsatingText={true} fontSize={72} />
				<Text align="center" strong paragraph>
					{infoMessage}
				</Text>
				<Text align="center" italic paragraph>
					{statusMessage}
				</Text>
			</StyledBetPendingReview__Section>
		</PlotElements>
	);
};

BetPendingReview.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Message to display in the info section */
	infoMessage: PropTypes.string,

	/** Message to display in the status section */
	statusMessage: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,
};

BetPendingReview.defaultProps = {
	infoMessage: 'Your bet has been referred to a trader for review.',
	statusMessage: 'Please standby...',
	className: '',
};

export default withNamespaces()(BetPendingReview);
