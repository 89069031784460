import React from 'react';
import styled, { css } from 'react-emotion';


const SportsStyles__SelectionRow = styled('div')(
  (props) => css`
		label: sportsStyles__SelectionRow;
    display: flex;
    width: 70%;
    gap: 4px;
    align-self: stretch;
    padding:1px;
	`,
);

const SportsStyles__SingleBet = styled('div')(
  (props) => css`
		label: sportsStyles__SingleBet;
    display: flex;
    border: 2px solid rgb(236, 236, 235);
    padding: 4px;
    flex: 1 1 0%;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    align-self: stretch;
    background: rgb(255, 255, 255);
    position: relative;
    height: 100%;
	`,
);

const SportsStyles__BetText = styled('div')(
  (props) => css`
		label: sportsStyles__BetText;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    -webkit-box-align: center;
    align-items: center;
    min-width: 0px;
    flex: 1 1 auto;
    overflow: hidden;
    font-size: 13px;
    font-weight:800;
	`,
);

const SportBets = ({selection}) => {
  return (
    <div>
      {selection.map((bets) => (
        <SportsStyles__SelectionRow key={bets.id}>
          <SportsStyles__SingleBet>
            <SportsStyles__BetText>{bets.price}</SportsStyles__BetText>
          </SportsStyles__SingleBet>
        </SportsStyles__SelectionRow>
      ))}
    </div>
  );
};

export default SportBets;
