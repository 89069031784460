// Manual translation strings for Turkish (TR)
export default {
	// Common & general definitions
	'1st Up': '1. up',
	'2nd Up': '2. up',
	'Non Wet': 'Islak değil',
	'Track Distance': 'Track süresi',
	Age: 'Yaş',
	BetSlip: 'Bahis kuponu',
	Career: 'Kariyer',
	Dam: 'Dam',
	Date: 'Tarih',
	Distance: 'Mesafe',
	DOB: 'DOB',
	Firm: 'Sert',
	fixed: 'fix',
	Fixed: 'Fix',
	Thoroughbred: 'Gallop',
	THOROUGHBRED: 'GALLOP',
	Good: 'İyi',
	Greyhounds: 'Greyhound',
	GREYHOUNDS: 'GREYHOUND',
	Harness: 'Harness',
	HARNESS: 'HARNESS',
	Heavy: 'Ağır',
	Night: 'Gece',
	Owners: 'Sahipleri',
	place: 'yer',
	Place: 'yer',
	race: 'yarış',
	Race: 'Yarış',
	Results: 'Sonuçlar',
	Season: 'Sezon',
	Sex: 'Cinsiyet',
	Sire: 'Sire',
	Soft: 'Yumuşak',
	Synthetic: 'Sentetik',
	Track: 'Track',
	Wet: 'Islak',
	win: 'Kazanan',
	Win: 'Kazanan',

	// Component definitions
	'SpeedmapLegend__Value--Backmarker': 'Backmarker',
	'SpeedmapLegend__Value--Leader': 'Lider',
	'SpeedmapLegend__Value--Midfield': 'Orta',
	'SpeedmapLegend__Value--Off-pace': 'Off-pace',
	MeetingList__NextRace: 'Bir sonraki yarış',
	RaceCard__EarlySpeed: 'Erken hız',
	RaceCard__Form: 'Form',
	RaceCard__Speedmaps: 'Hız haritası',
	RaceSelectionsList__Flucs: 'Fluc',
	RacingDateSelection__Tomorrow: 'Yarın',
	RacingDateSelection__Yesterday: 'Dün',
	RacingHomePage__Next: 'Birsonraki',
	RacingHomePage__Racing: 'Yarış',
	SpeedmapHeader__Barrier: 'Bariyer',
	SpeedmapHeader__Lead: 'Lead',
};
