import { get } from '../../../common/Ajax';
import { mergeEntities } from '../../../common/actions/actionHelpers';
import { normalizeNextToJumpSports } from '../schemas/NextToJumpSportSchema';


/**
 * Fetch next to jump sports.
 * @HW 26Feb2020
 */
const fetchNextToJumpSports_GRS = () => (dispatch) => {
	
    return get('sports/events/next-to-jump-grs')
		.then((response) => {
			let normalizedData = normalizeNextToJumpSports(response.data.data);
			dispatch(mergeEntities(normalizedData.entities));
		    return normalizedData.result;
		})
		.catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			return error;
		});
};

const fetchNextToJumpSports = () => (dispatch) => {
	return get('sports/events/next-to-jump').then((response) => {
		const normalizedData = normalizeNextToJumpSports(response.data.data);
		dispatch(mergeEntities(normalizedData.entities));
		return normalizedData.result;
	});
};

export {
    fetchNextToJumpSports_GRS,
    fetchNextToJumpSports,
};