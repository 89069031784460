import React from 'react';
import styled, { css } from 'react-emotion';

const SportsStyles__OuterTitleContainer = styled('div')(
  (props) => css`
		label: SportsStyles__OuterTitleContainer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-basis: 50%;
    overflow: hidden;
    align-self: stretch;
	`,
);
const SportsStyles__TeamIconContainer = styled('div')(
  (props) => css`
		label: SportsStyles__TeamIconContainer;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    align-items: flex-start;
    width: 32px;
    height: 32px;
    margin-right: 14px;
    flex: 0 0 32px;
	`,
);
const SportsStyles__SelectionTitleText = styled('div')(
  (props) => css`
		label: SportsStyles__SelectionTitleText;
    padding: 0px;
    line-height: 1.3;
    max-width: 100%;
    color: rgb(34, 34, 34);
    font-size: 13.4444px;
    text-transform: none;
    text-decoration: none;
    text-align: center;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    flex: 1 1 0%;
    width: 51em;
	`,
);
const SportsTeams = ({Teams}) => {
  return (
  <div>
    {Teams.map((teams) => (
        <SportsStyles__OuterTitleContainer key={teams.id}>
          <SportsStyles__TeamIconContainer/>
          <SportsStyles__SelectionTitleText>{teams.name}</SportsStyles__SelectionTitleText>
        </SportsStyles__OuterTitleContainer>
    ))}
  </div>
  );
};

export default SportsTeams;
