const URI = require('urijs');

import {
	APPLICATION_DEEP_LINK_COMPLETE,
	CLEAR_AUTHENTICATED_USER,
	SET_AUTHENTICATED_USER,
	SET_DEFAULT_ROLLS,
	SET_RUNNING_SUB_APP,
	STOP_RUNNING_SUB_APP,
	HIDE_MASTHEAD_FIELDS,
	SHOW_NAVIGATION,
	HIDE_NAVIGATION,
	UPDATE_SIDEBAR_NTJ_SETTINGS,
	UPDATE_SIDEBAR_NTJ_DATA,
	SET_IS_PHONE_DEVICE,
	SET_CURRENT_ROUTE,
	OPEN_MULTI_BET_SLIP,
	CLOSE_MULTI_BET_SLIP,
	SET_VISIBLE_SPORTS_IDS,
	SET_VISIBLE_SPORTS_WITH_NAME,
	SET_SPORT_WITH_SPORT_ID,
	UPDATE_INTERCOM_BOOTED,
	SET_BODY_REGION_LOADING,
	SET_APPLICATION_LOADING,
	SET_VERSION_LOADED,
	SET_VERSION_INFORMATION,
	SET_MASTHEAD_IFRAME_HEIGHT,
	SET_MASTHEAD_HEIGHT,
	APPLICATION_SET_SELECTED_PAGE,
	SHOW_SIDEBAR,
	HIDE_SIDEBAR,
	SHOW_LOCKED_SCREEN,
	HIDE_LOCKED_SCREEN,
	SET_SCROLLBAR_WIDTH,
	CLOSE_SIDE_BET_SLIP,
	OPEN_SIDE_BET_SLIP,
} from './applicationActionTypes';

const initialState = {
	selectedPage: '',
	authenticatedUser: null,
	isApplicationLoading: false,
	isBodyRegionLoading: true,
	deepLinkComplete: false,
	hideMastheadFields: false,
	racing_rolls: [],
	runningContainerSubApps: {},
	showLockedScreen: false,
	showMultiBetSlip: false, // deafult closed
	showSideBetSlip: false, // deafult closed
	showNavigation: true,
	showSidebar: false,
	sidebar: {
		nextToJumpSettings: 'racing',
		updateNextToJumpData: false,
	},
	intercomBooted: false,
	isCordova: !!process.env.IS_CORDOVA,
	isPhoneDevice: false,
	currentRoute: URI().path(),
	sports_rolls: [],
	visibleSports: [],
	versionLoaded: process.env.PACKAGE_VERSION,
	loadedVersionRequiresUpdate: false,
	nonMandatoryUpdateAvailable: false,
	loadedPlatform: null,
	mastheadIframeHeight: null,
	mastheadHeight: null,
	scrollbarWidth: null,
	lastTimeBetSlipOpened: null,
};

function checkLastTimeBetSlipOpened(lastTimeBetSlipOpened) {
	if (lastTimeBetSlipOpened) {
		if (new Date().getTime() - lastTimeBetSlipOpened > 900) {
			return true;
		}

		return false;
	}
	return true;
}

const applicationReducers = (state = initialState, action) => {
	switch (action.type) {
		case APPLICATION_DEEP_LINK_COMPLETE:
			return {
				...state,
				deepLinkComplete: true,
			};

		case SET_AUTHENTICATED_USER:
			return {
				...state,
				authenticatedUser: action.userId,
			};

		case HIDE_MASTHEAD_FIELDS:
			return {
				...state,
				hideMastheadFields: action.payload,
			};

		case APPLICATION_SET_SELECTED_PAGE:
			return {
				...state,
				selectedPage: action.payload,
			};

		case CLEAR_AUTHENTICATED_USER:
			return Object.assign({}, state, { authenticatedUser: initialState.authenticatedUser });

		case SET_RUNNING_SUB_APP:
			var runningSubApps = Object.assign({}, state.runningContainerSubApps);
			runningSubApps[action.regionName] = action.registeredName;
			return Object.assign({}, state, { runningContainerSubApps: runningSubApps });

		case STOP_RUNNING_SUB_APP:
			var runningContainerSubApps = Object.assign({}, state.runningContainerSubApps);
			delete runningContainerSubApps[action.regionName];
			return Object.assign({}, state, { runningContainerSubApps });

		case SET_DEFAULT_ROLLS:
			return {
				...state,
				...action.payload,
			};

		case SHOW_NAVIGATION:
			return {
				...state,
				showNavigation: true,
			};

		case HIDE_NAVIGATION:
			return {
				...state,
				showNavigation: false,
			};

		case SHOW_SIDEBAR:
			return {
				...state,
				showSidebar: true,
			};

		case HIDE_SIDEBAR:
			return {
				...state,
				showSidebar: false,
			};

		case SHOW_LOCKED_SCREEN:
			return {
				...state,
				showLockedScreen: true,
			};

		case HIDE_LOCKED_SCREEN:
			return {
				...state,
				showLockedScreen: false,
			};

		case UPDATE_SIDEBAR_NTJ_SETTINGS:
			return {
				...state,
				sidebar: {
					...state.sidebar,
					nextToJumpSettings: action.payload,
				},
			};

		case UPDATE_SIDEBAR_NTJ_DATA:
			return {
				...state,
				sidebar: {
					...state.sidebar,
					updateNextToJumpData: action.payload,
				},
			};

		case SET_IS_PHONE_DEVICE:
			return {
				...state,
				isPhoneDevice: action.payload,
			};

		case SET_CURRENT_ROUTE:
			return {
				...state,
				currentRoute: action.payload,
			};

		case OPEN_MULTI_BET_SLIP:
			return {
				...state,
				showSideBetSlip: checkLastTimeBetSlipOpened(state.lastTimeBetSlipOpened) ? false : state.showSideBetSlip,
				showMultiBetSlip: checkLastTimeBetSlipOpened(state.lastTimeBetSlipOpened) ? true : state.showMultiBetSlip,
				lastTimeBetSlipOpened: checkLastTimeBetSlipOpened(state.lastTimeBetSlipOpened)
					? new Date().getTime()
					: state.lastTimeBetSlipOpened,
			};

		case CLOSE_MULTI_BET_SLIP:
			return {
				...state,
				showMultiBetSlip: checkLastTimeBetSlipOpened(state.lastTimeBetSlipOpened) ? false : state.showMultiBetSlip,
			};

		case OPEN_SIDE_BET_SLIP:
			return {
				...state,
				showMultiBetSlip: false,
				showSideBetSlip: true,
			};

		case CLOSE_SIDE_BET_SLIP:
			return {
				...state,
				showSideBetSlip: false,
			};

		case SET_VISIBLE_SPORTS_IDS:
			return {
				...state,
				visibleSports: action.payload,
			};

		case SET_VISIBLE_SPORTS_WITH_NAME:
			return {
				...state,
				visibleSportWithName: action.payload,
			};
		case SET_SPORT_WITH_SPORT_ID:
			return {
				...state,
				visibleSportWithSportId: action.payload,
			};
		case UPDATE_INTERCOM_BOOTED:
			return {
				...state,
				intercomBooted: action.payload,
			};

		case SET_BODY_REGION_LOADING:
			return {
				...state,
				isBodyRegionLoading: action.payload,
			};

		case SET_APPLICATION_LOADING:
			return {
				...state,
				isApplicationLoading: action.payload,
			};

		case SET_VERSION_LOADED:
			return {
				...state,
				versionLoaded: action.payload,
			};

		case SET_VERSION_INFORMATION:
			return {
				...state,
				loadedVersionRequiresUpdate: action.payload.loadedVersionRequiresUpdate,
				nonMandatoryUpdateAvailable: action.payload.nonMandatoryUpdateAvailable,
				loadedPlatform: action.payload.loadedPlatform,
			};

		case SET_MASTHEAD_IFRAME_HEIGHT:
			return {
				...state,
				mastheadIframeHeight: action.payload,
			};

		case SET_MASTHEAD_HEIGHT:
			return {
				...state,
				mastheadHeight: action.payload,
			};

		case SET_SCROLLBAR_WIDTH:
			return {
				...state,
				scrollbarWidth: action.payload,
			};
	}

	return state;
};

module.exports = applicationReducers;
