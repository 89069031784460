// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

// Components
import { spacings, BankAccountSelect, Button, FlexText, Link, Text, PlotElements } from '@tbh/ui-kit';
import BankAccountForm from '../../Forms/BankAccount/BankAccountForm/BankAccountForm';
import CurrencyEntryKeypad from '../../Application/Currency/CurrencyEntryKeypad/CurrencyEntryKeypad';
import CurrencyInput from '../../Application/Currency/CurrencyInput/CurrencyInput';
import CurrencyDisplay from '../../Application/Currency/CurrencyDisplay/CurrencyDisplay';
import CurrencyNameDisplay from '../../Application/Currency/CurrencyNameDisplay/CurrencyNameDisplay';

// Functions
import Format from '../../../../legacy/core/format';

// Styling
export const StyledWithdrawalForm__CurrencyKeypad = styled(CurrencyEntryKeypad)(
	(props) => css`
		label: WithdrawalForm__CurrencyKeypad;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

export const StyledWithdrawalForm__SelectBank = styled(BankAccountSelect)(
	(props) => css`
		label: WithdrawalForm__SelectBank;

		margin-bottom: ${spacings(props).comfortable}px;
	`,
);

const WithdrawalForm = (props) => {
	const {
		t,

		// BankAccountForm
		account_name,
		account_number,
		bank_details_id,
		bsb_number,
		other_bank_name,
		onSelectBankName,
		withdrawable_balance,

		// BankAccountSelect
		bankAccounts,
		onSelectBankAccount,

		buttonValues,
		className,
		customButton,
		inputError,
		isPrimaryActionEnabled,
		newBankAccount,
		onAddBankAccount,
		onChange,
		onDeleteBankAccount,
		onRequestWithdrawal,
		onSubmitNewBankAccount,
		onShowExistingBankAccount,
		onWithdrawalAmountChange,
		setWithdrawalAmountError,
		withdrawalAmount,
		selectedAccount,
	} = props;

	// The minimum withdrawal amount is set as the first button value ($20)
	const minWithdrawalAmount = buttonValues[0];
	const minWithdrawlMessage = `${t('Minimum')} ${Format.centsAsFormat(minWithdrawalAmount, '0,0')} ${t('withdrawal')}`;

	const handleButtonClick = (event, value) => {
		onWithdrawalAmountChange(value.toString());
	};

	const handleInputChange = (name, value) => {
		onWithdrawalAmountChange(value);
	};

	const handleInputBlur = () => {
		if (withdrawalAmount && withdrawalAmount < minWithdrawalAmount) {
			setWithdrawalAmountError(minWithdrawlMessage);
		} else if (inputError === minWithdrawlMessage) {
			setWithdrawalAmountError();
		}
	};

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<div className={componentClasses}>
			{/* <Text paragraph strong>
				{t('WithdrawalPromptContainer__Amount')}
			</Text> */}
			<Text size="-1" strong className={css`font-size:14px!important;font-weight:bold!important;`}>Withdrawable balance: ${withdrawable_balance ? withdrawable_balance / 100 : 0}</Text>
			<StyledWithdrawalForm__CurrencyKeypad
				buttonAction={handleButtonClick}
				buttonValues={buttonValues}
				customButton={customButton}
			/>
			<CurrencyInput
				label={t('WithdrawalPromptContainer__WantTo')}
				value={String(withdrawalAmount)}
				name="withdrawalAmount"
				onChange={handleInputChange}
				onBlur={handleInputBlur}
				placeholder={minWithdrawlMessage}
				horizontalMargin="cozy"
				margin="cozy"
				error={inputError}
			/>
			<FlexText>
				<Text paragraph strong>
					{t('WithdrawalPromptContainer__Credit')}
				</Text>
				{newBankAccount ? (
					Object.keys(bankAccounts).length > 0 && (
						<Link action={onShowExistingBankAccount} size="-1" underline>
							{t('Existing')}
						</Link>
					)
				) : (
					<Text>
						<Link action={onAddBankAccount} size="-1" underline>
							{t('Add')}
						</Link>{' '}
						|&nbsp;
						<Link action={onDeleteBankAccount} size="-1" underline>
							{t('Delete')}
						</Link>
					</Text>
				)}
			</FlexText>
			{newBankAccount ? (
				<BankAccountForm
					account_name={account_name}
					account_number={account_number}
					bank_details_id={bank_details_id}
					bsb_number={bsb_number}
					other_bank_name={other_bank_name}
					onChange={onChange}
					onSelectBankName={onSelectBankName}
				/>
			) : (
				[
					<StyledWithdrawalForm__SelectBank
						key={1}
						bankAccounts={bankAccounts}
						onSelect={onSelectBankAccount}
						bankAccountId={selectedAccount}
					/>,
					<Text key={2} size="-1" paragraph>
						<strong>{t('Note')}:</strong>
						&nbsp;
						{t('WithdrawalPromptContainer__ProcessedIn')}
						&nbsp;
						<CurrencyNameDisplay />.
					</Text>,
				]
			)}
			<Button
				action={newBankAccount ? onSubmitNewBankAccount : onRequestWithdrawal}
				disabled={!isPrimaryActionEnabled}
				type="primary"
				block
			>
				{newBankAccount ? (
					t('WithdrawalPromptContainer__Add')
				) : (
					<PlotElements>
						<span>
							{t('Withdraw')}
							&nbsp;
						</span>
						<CurrencyDisplay amount={withdrawalAmount || buttonValues[0]} />
					</PlotElements>
				)}
			</Button>
		</div>
	);
};

WithdrawalForm.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	onWithdrawalAmountChange: PropTypes.func.isRequired,
	onRequestWithdrawal: PropTypes.func.isRequired,

	/** Selects and existing bank account */
	onSelectBankAccount: PropTypes.func.isRequired,

	/**
	 * BankAccountForm props
	 */
	account_name: PropTypes.string,
	account_number: PropTypes.string,
	bank_details_id: PropTypes.string,
	bsb_number: PropTypes.string,
	other_bank_name: PropTypes.string,

	/** List of bank accounts for the user */
	bankAccounts: PropTypes.objectOf(
		PropTypes.shape({
			account_number: PropTypes.number.isRequired,
			bsb_number: PropTypes.string.isRequired,
		}),
	),

	/** Currently selected account */
	selectedAccount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

	/** Withdrawal values for the buttons */
	buttonValues: PropTypes.array,

	/** Extra classes */
	className: PropTypes.string,

	/**
	 * An object containing the properties for the Currency Entry Keypad custom button.
	 * The object item contains it's action, type & label
	 */
	customButton: PropTypes.shape({
		action: PropTypes.func,
		type: PropTypes.string,
		label: PropTypes.string,
	}),

	/** Error message to display for the input */
	inputError: PropTypes.string,

	isPrimaryActionEnabled: PropTypes.bool,

	/** If we are showing the newBankAccount form or the existing account form */
	newBankAccount: PropTypes.bool,

	/** Opens the Bank Account Form to add a new bank account */
	onAddBankAccount: PropTypes.func,

	/** Handles changes in the Bank Account Form's inputs */
	onChange: PropTypes.func,

	/** Deletes the bank account selected */
	onDeleteBankAccount: PropTypes.func,

	/** Selects a bank option in the Bank Account Form */
	onSelectBankName: PropTypes.func,

	/** Shows the list of existing bank accounts */
	onShowExistingBankAccount: PropTypes.func,

	/** Saves the new bank account details to the user account */
	onSubmitNewBankAccount: PropTypes.func,

	/** Function to set the amount error for the input */
	setWithdrawalAmountError: PropTypes.func,

	/** The withdrawal amount in cents */
	withdrawalAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

WithdrawalForm.defaultProps = {
	account_name: '',
	account_number: '',
	bank_details_id: '1',
	bsb_number: '',
	other_bank_name: '',
	bankAccounts: null,
	selectedAccount: undefined,
	buttonValues: [2000, 5000, 7500, 10000, 25000],
	className: '',
	customButton: {},
	inputError: null,
	isPrimaryActionEnabled: false,
	newBankAccount: false,
	onAddBankAccount: undefined,
	onChange: undefined,
	onDeleteBankAccount: undefined,
	onSelectBankName: undefined,
	onShowExistingBankAccount: undefined,
	onSubmitNewBankAccount: undefined,
	setWithdrawalAmountError: () => { },
	withdrawalAmount: '2000',
};

export default withNamespaces()(WithdrawalForm);
