import { connect } from 'react-redux';

import SetLimit from '../SetLimit';
import React, { useState } from 'react';
import DepositContainer from '../../../../../containers/Deposit/DepositContainer/DepositContainer';
import styled from 'react-emotion';
import { Text } from '@tbh/ui-kit';

const StyledTitle = styled(Text)`
	display: flex;
	flex-direction: row;
	align-items: center;

	margin-top: 10px;
	margin-bottom: 10px;
	gap: 6px;
	color: black !important;

	span {
		font-bold: bold;
		color: black;
		padding: 2px 10px;
		background-color: #cdc8c8;
		border-radius: 999px;
	}
`;

export const LastStep = ({ first_name, onClose }) => {
	const [step, setStep] = useState(0);

	return (
		<div>
			<Text size="0" strong align="center">
				Congratulations, {first_name}!
			</Text>
			<Text size="0" className="mt-3" align="center">
				Your account has been created and you are ready to start playing.
			</Text>

			<StyledTitle size="-1" strong>
				<span>1</span>
				Select Deposit Limit
			</StyledTitle>

			<SetLimit isRegistration={true} nextStep={() => setStep(1)} onClose={onClose} />

			<StyledTitle size="-1" strong>
				<span>2</span>
				Add funds
			</StyledTitle>

			{step === 0 && <Text size="-2"> Choose a Deposit Limit option to deposit funds.</Text>}
			{step === 1 && <DepositContainer isQuickDeposit trackingCategory="Quick Deposit" handleClose={onClose} />}
		</div>
	);
};
const mapStateToProps = (state, ownProps) => {

	const user =  state.entities.users[state.application.authenticatedUser];
	let first_name = '';
	if (user) {
		first_name = user.first_name;
	}

	return {
        first_name,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LastStep);
