// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { uniqueId } from 'lodash-es';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, media, Icon } from '@tbh/ui-kit';

// Selectors
import { getBetSelectionDerivativeTitle } from '../../../../../store/entities/selectors/BetSelectors';
import { getBetSelectionDerivativeLabel } from '../../../../../store/entities/selectors/BetSelectors';

// Components
import { CollapsiblePanel, Text } from '@tbh/ui-kit';
import DerivativeSelection from '../DerivativeSelection/DerivativeSelection';

const StyledDerivativeItem__Versus = styled(Text)(
	css`
		label: DerivativeItem__Versus;

		display: none;
	`,
);

const StyledDerivativeItem__Selection = styled(DerivativeSelection)(
	(props) => css`
		label: DerivativeItem__Selection;

		margin-top: ${spacings(props).compact}px;
	`,
	media(
		(props) => css`
			margin-right: ${spacings(props).compact}px;

			&:nth-of-type(1) {
				order: 1;
			}
			&:nth-of-type(2) {
				order: 3;
			}
			&:nth-of-type(3) {
				order: 4;
			}
			&:last-of-type {
				margin-right: 0;
			}
		`,
		620,
	),
);

const StyledDerivativeItem__Selections = styled('div')(
	(props) => css`
		label: DerivativeItem__Selections;

		display: flex;
		flex-flow: row wrap;
	`,
	media(
		(props) =>
			css`
				flex-flow: row nowrap;

				${StyledDerivativeItem__Versus} {
					display: flex;
					align-items: center;
					order: 2;
					min-width: 20px;
					margin: 0 ${spacings(props).cozy}px 0 ${spacings(props).compact}px;
				}
			`,
		620,
	),
);

const DerivativeItem = (props) => {
	const {
		t,
		className,
		marketId,
		marketType,
		marketTypeCode,
		bettingAvailable,
		derivativeIcons,
		handleSelectionClick,
		selections,
		showDerivativeContent,
		title,
		toggleContent,
		size,
	} = props;

	const componentIcons = derivativeIcons.length ? (
		<span style={{ marginRight: '8px' }}>
			{derivativeIcons.map((icon) => (
				<Icon key={uniqueId(icon)} icon={icon} />
			))}
		</span>
	) : null;

	const componentClasses = cx({
		[className]: className,
	});

	/**
	 * When the derivative selection is toggled, fire an action with the market ID
	 */
	const onContentToggle = () => {
		toggleContent(marketId);
	};

	/**
	 * When a derivative selection is clicked on, fire an action and pass through the market type as well
	 *
	 * @param rest
	 */
	const onDerivativeSelectionClick = (...rest) => {
		handleSelectionClick(...rest, marketId, marketType);
	};

	return (
		<CollapsiblePanel
			className={componentClasses}
			label={title}
			headerBarOptions={{ nodeLeft: componentIcons }}
			isCollapsed={!showDerivativeContent}
			toggleCollapsibleContent={onContentToggle}
			type={CollapsiblePanel.types.SUPPORT}
		>
			<StyledDerivativeItem__Selections size={size}>
				{selections.map((selection) => {
					const baseSelection = selection.base_selections.length === 1 ? selection.base_selections[0] : {};
					const title = getBetSelectionDerivativeTitle(marketTypeCode, selection, true, t);
					const subtitle = getBetSelectionDerivativeLabel(
						{
							derivative_selections: selections,
							market_type_code: marketTypeCode,
						},
						selection,
						t,
					);

					return (
						<StyledDerivativeItem__Selection
							key={selection.id}
							action={onDerivativeSelectionClick}
							selectionId={selection.id}
							disabled={!bettingAvailable}
							name={title}
							odds={selection.price}
							subtitle={subtitle}
							number={baseSelection.number}
							type_code={baseSelection.type_code}
							barrier={baseSelection.barrier}
							jockey={baseSelection.jockey}
							silk={baseSelection.silk ? baseSelection.silk : null}
							trainer={baseSelection.runner && baseSelection.runner.trainer}
							weight={baseSelection.weight}
							size={size}
						/>
					);
				})}
				{selections.length === 2 && (
					<StyledDerivativeItem__Versus tag="span" mediaSize={size}>
						{t('v/s')}
					</StyledDerivativeItem__Versus>
				)}
			</StyledDerivativeItem__Selections>
		</CollapsiblePanel>
	);
};

DerivativeItem.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Handle when a selection is clicked on */
	handleSelectionClick: PropTypes.func.isRequired,

	/** The derivative market ID */
	marketId: PropTypes.number.isRequired,

	/** The derivative market type */
	marketType: PropTypes.string.isRequired,

	/** The derivative market code */
	marketTypeCode: PropTypes.string.isRequired,

	/** List of selections for the derivative item */
	selections: PropTypes.arrayOf(
		PropTypes.shape({
			/** The selection name */
			name: PropTypes.string.isRequired,

			/** The race selection type */
			type_code: PropTypes.string,

			/** The selection barrier */
			barrier: PropTypes.number,

			/** The jockey name */
			jockey: PropTypes.string,

			/** The selection number */
			number: PropTypes.number,

			/** The selection odds */
			odds: PropTypes.number,

			/** The jockey silk */
			silk: PropTypes.string,

			/** The trainer name */
			trainer: PropTypes.string,

			/** The jockey weight */
			weight: PropTypes.number,
		}),
	).isRequired,

	/** Derivative's title */
	title: PropTypes.string.isRequired,

	/** Function to use for toggling the content */
	toggleContent: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** Boolean indicating if betting is available ie. race isn't closed */
	bettingAvailable: PropTypes.bool,

	/** Icons for the header */
	derivativeIcons: PropTypes.array,

	/** Is the content collapsed or showing */
	showDerivativeContent: PropTypes.bool,

	/** Extra classes */
	className: PropTypes.string,
};

DerivativeItem.defaultProps = {
	className: '',
	derivativeIcons: [],
	bettingAvailable: true,
	showDerivativeContent: true,
	type_code: null,
};

export default withNamespaces()(DerivativeItem);
