import { get } from '../../common/Ajax';
import { createAction } from '../../common/actions/actionHelpers';
import { SELECT_RULE, SET_ERROR, SET_LOADING, SET_RULES } from './legalRulesActionTypes';


export const getAllLegalRules = async () => {
	try {
		const response = await get('/content');
		return response.data.data;
	} catch (error) {
		document.Sentry && document.Sentry.captureException(error);
		return 'Internal Server Error';
	}
};

export const getLegalRule = async (alias) => {
	try {
		const response = await get(`/content/${alias}`);
		return response.data.data[0];
	} catch (error) {
		document.Sentry && document.Sentry.captureException(error);
		return 'Internal Server Error';
	}
};

export const setRules = (rules) => (dispatch) => {
	dispatch(createAction(SET_RULES, rules));
};

export const getRules = () => async (dispatch) => {
	dispatch(setLoading(true));
	const rules = await getAllLegalRules();
	if (typeof rules === 'string') {
		dispatch(setError(rules));

	} else {
		dispatch(setRules(rules));
	}

    dispatch(setLoading(false));
	return rules;
};

export const getRule = (alias) => async (dispatch) => {
	dispatch(setLoading(true));
	const rule = await getLegalRule(alias);

	if (typeof rule === 'string') {
		dispatch(setError(rule));
	} else {
		dispatch(setRule(rule));
	}

	dispatch(setLoading(false));
    return rule;
};

export const setRule = (rule) => (dispatch) => {
	dispatch(createAction(SELECT_RULE, rule));
};

export const setError = (error) => (dispatch) => {
	dispatch(createAction(SET_ERROR, error));
};

export const setLoading = (loading) => (dispatch) => {
	dispatch(createAction(SET_LOADING, loading));
};
