/**
 * Based on the first_leg and race number returned from race/selections
 * Work out where the current race fits in the 4 quaddie legs
 *
 * @param race
 */
export const quaddiePosition = (race) => {
	const products = race.products;

	/* filter products result if product_name of "Quaddie" exists */
	const quaddieProduct =
		products &&
		products.filter(function(product) {
			return product.product_name === 'Quaddie' || product.bet_type === 'quaddie';
		});

	/* improve performance by not running function unless a daily double product exists */
	if (!quaddieProduct) {
		return false;
	}

	/* get the first leg number */
	const firstLegObject = { ...quaddieProduct }[0];
	const firstLegNumber = firstLegObject && +firstLegObject.first_leg;

	/* find the race number */
	const raceNumber = race && +race.number;

	/* determine position of quaddie leg relative to the race number */
	const legs = raceNumber - firstLegNumber + 1;

	/* define min max number of race legs in a quaddie (4: 0,1,2,3) */
	/* return true if it matches leg validation (4 = 0, 1, 2, 3) */
	const minLegs = 1;
	const maxLegs = 4;
	const validate = legs >= minLegs && legs <= maxLegs;

	return validate ? legs : false;
};
