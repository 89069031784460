// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, ui, Checkbox } from '@tbh/ui-kit';

// Components
import { FlexText, Link, Text } from '@tbh/ui-kit';

import FeatureContext from '../../../../../contexts/featureContext';

import CurrencyDisplay from '../../Currency/CurrencyDisplay/CurrencyDisplay';
import CurrencyNameDisplay from '../../Currency/CurrencyNameDisplay/CurrencyNameDisplay';

const StyledAccountSummaryBalanceStatistics__LineThrough = styled('hr')`
	label: AccountSummaryBalanceStatistics__LineThrough;

	${(props) => css`
		margin: ${spacings(props).compact}px 0;
		height: 1px;
		border: none;
		//background-color: ${ui(props).color_3};
		background-color: #a7a7a7;
		width: 111%;

		@media (width < 620px) {
			width: 100%;
		}

	`};
`;

const StyledAmount_box = styled('div')`
	label: Amount_box;

	border: 1px solid #fff;
	padding: 3px;
	width: 100%;
	color: #fff;
`;

const StyledRedColor_box = styled('div')`
	label: RedColor_box;

	background: #c72128;
	border: 1px solid #c72128;
	padding: 3px;
	width: 100%;
	color: #fff;
	text-align: end;
`;

const StyledBonusCheck = styled('div')`
	label: BonusCheck;
	position: relative;
	margin-left: 5px;
`;

const StyledSuccessAmount_box = styled('div')`
	label: SuccessAmount_box;

	background: #28a745;
	border: 1px solid #28a745;
	padding: 3px;
	width: 100%;
	color: #fff;
`;
const AccountSummaryBalanceStatistics = (props) => {
	const {
		t,
		account_balance,
		free_credit_balance,
		cash_balance,
		className,
		goToAccountTransactions,
		goToAccountTransactionsBonus,
		checkBonusbet,
		isChecked,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	const handleMultiToggle = () => {
		checkBonusbet();
	};

	return (
		<div className={componentClasses}>
			<div
				className={css`
					background-color: #212429;
					padding: 4px;
				`}
			>
				<div
					className={css`
						display: flex;
						width: 100%;
						justify-content: space-between;
					`}
				>
					<Text
						size="-1"
						className={css`
							color: #fff;
							flex: 0 0 50%;
						`}
					>
						{t('Bonus')} {t('Used')}
					</Text>
					<div
						className={css`
							color: #fff;
							flex: 0 0 50%;
							justify-content: flex-start;
						`}
					>
						<Checkbox
							name="multi"
							positive
							stacked
							checked={isChecked}
							action={handleMultiToggle}
							labelSize="-3"
							className={css`
								align-items: end;
							`}
						/>
					</div>
				</div>

				<div
					className={css`
						display: flex;
						width: 100%;
						justify-content: space-between;
						padding-bottom: 8px;
					`}
				>
					<Text
						size="-1"
						className={css`
							color: #fff;
							flex: 0 0 50%;
						`}
					>
						{t('Bonus')} {t('Expiry')}
					</Text>
					<Text
						size="-1"
						align="right"
						className={css`
							color: #fff;
							flex: 0 0 50%;
						`}
					>
						-
					</Text>
				</div>

				<div
					className={css`
						display: flex;
						width: 100%;
						justify-content: space-between;
					`}
				>
					<Link
						action={goToAccountTransactions}
						underline
						size="-1"
						className={css`
							flex: 0 0 50%;
						`}
					>
						<CurrencyNameDisplay /> {t('Balance')}
					</Link>
					<Text
						size="-1"
						align="right"
						className={css`
							flex: 0 0 50%;
						`}
					>
						<StyledAmount_box>
							<CurrencyDisplay amount={cash_balance} />
						</StyledAmount_box>
					</Text>
				</div>
				<FeatureContext.Consumer>
					{(featureToggles) => {
						return featureToggles.features.bonusBets && featureToggles.features.bonusBets.enabled ? (
							<FlexText
								className={css`
									margin-top: 8px;
									color: #fff;
									display: flex;
									width: 100%;
									justify-content: space-between;
								`}
							>
								<Link
									action={goToAccountTransactionsBonus}
									underline
									size="-1"
									className={css`
										flex: 0 0 50%;
									`}
								>
									<CurrencyNameDisplay type="bonusCurrency" /> {t('Balance')}
								</Link>
								<Text
									size="-1"
									align="right"
									className={css`
										flex: 0 0 50%;
									`}
								>
									<StyledRedColor_box>
										<CurrencyDisplay amount={free_credit_balance} type="bonusCurrency" />
									</StyledRedColor_box>
								</Text>
								{/* <StyledBonusCheck>
                  <Text size="-5" align="right" className={css`color:#fff;display:flex;width:100%;justify-content: space-between;`}>
                    <Checkbox
                      name="multi"
                      positive
                      stacked
                      checked={isChecked}
                      action={handleMultiToggle}
                      labelSize="-3"
                    />
                  </Text>
                </StyledBonusCheck> */}
							</FlexText>
						) : null;
					}}
				</FeatureContext.Consumer>
				<StyledAccountSummaryBalanceStatistics__LineThrough />
				<div
					className={css`
						display: flex;
						width: 100%;
						justify-content: space-between;
					`}
				>
					<Text
						size="-1"
						className={css`
							color: #fff;
							flex: 0 0 50%;
						`}
					>
						{t('Total')} {t('Balance')}
					</Text>
					<Text
						size="-1"
						align="right"
						className={css`
							flex: 0 0 50%;
						`}
					>
						<StyledSuccessAmount_box>
							<CurrencyDisplay amount={account_balance} />
						</StyledSuccessAmount_box>
					</Text>
				</div>

				<div
					className={css`
						display: flex;
						width: 100%;
						justify-content: space-between;
					`}
				>
					<Text
						size="-1"
						className={css`
							color: #fff;
							flex: 0 0 70%;
						`}
					>
						{t('Withdrawable')} {t('Balance')}
					</Text>
					<Text
						size="-1"
						align="right"
						className={css`
							color: #fff;
							flex: 0 0 30%;
						`}
					>
						<CurrencyDisplay amount={'0'} />
					</Text>
				</div>
			</div>
		</div>
	);
};

AccountSummaryBalanceStatistics.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The current users' account balance */
	account_balance: PropTypes.number,

	/** The current users' balance for their bonus bets */
	free_credit_balance: PropTypes.number,

	/** The current users' cash balance */
	cash_balance: PropTypes.number,

	/** Extra classes */
	className: PropTypes.string,

	/** Navigates to the account transactions page */
	goToAccountTransactions: PropTypes.func,

	/** Navigates to the account transactions bonus page */
	goToAccountTransactionsBonus: PropTypes.func,
};

AccountSummaryBalanceStatistics.defaultProps = {
	account_balance: 0,
	free_credit_balance: 0,
	cash_balance: 0,
	className: '',
	goToAccountTransactions: undefined,
	goToAccountTransactionsBonus: undefined,
};

AccountSummaryBalanceStatistics.StyledAccountSummaryBalanceStatistics__LineThrough = StyledAccountSummaryBalanceStatistics__LineThrough;

export default withNamespaces()(AccountSummaryBalanceStatistics);
