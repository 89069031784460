import { get, post } from '../../../common/Ajax';
import { MERGE_ENTITIES, REMOVE_ENTITIES } from '../EntityActionTypes';
import { normalizeWithdrawals } from '../schemas/WithdrawalSchema';
import { mergeEntities } from '../../../common/actions/actionHelpers';
import { trackGaTransaction } from '../../trackingPixels/trackingActions';
import { fetchAuthenticatedUser } from '../../authentication/authenticationActions';
import { centsAsDollars, allWordsWithCapitals } from '../../../legacy/core/format';

/**
 * Fetch all pending withdrawal from server.
 */
export const fetchPendingWithdrawals = () => (dispatch) => {
	return get('users/withdrawal/pending').then((response) => {
		dispatch({
			type: MERGE_ENTITIES,
			entities: normalizeWithdrawals(response.data.data).entities,
		});
	});
};

/**
 * Send Post request to cancel pending withdrawal.
 *
 * @param withdrawalId
 */
export const cancelWithdrawal = (withdrawalId) => (dispatch) => {
	return post('users/withdrawal/cancel', { withdrawal_id: withdrawalId }).then((response) => {
		dispatch({
			type: REMOVE_ENTITIES,
			entities: normalizeWithdrawals([{ ...response.data.data, canceled: true }]).entities,
		});

		// Update the user account balance
		dispatch(fetchAuthenticatedUser());

		const withdrawal = response.data.data;

		const id = `${withdrawal.id}-${withdrawal.bank_details.user_id}`;
		const transactionData = {
			revenue: centsAsDollars(withdrawal.amount),
		};
		const items = [
			{
				name: 'Cancel Withdraw',
				sku: allWordsWithCapitals(withdrawal.withdrawal_type),
				category: allWordsWithCapitals(withdrawal.withdrawal_type),
				price: centsAsDollars(withdrawal.amount),
			},
		];

		dispatch(trackGaTransaction(id, transactionData, items));

		return response;
	});
};

/**
 * Send new pending withdrawal created by Marionette to Redux store.
 *
 * @param newPendingWithdrawal
 */
export const storeNewPendingWithdrawal = (newPendingWithdrawal) => {
	return mergeEntities(normalizeWithdrawals([{ ...newPendingWithdrawal }]).entities);
};

/**
 *
 * @param amount
 * @param bankAccountId
 */
export const createBankWithdrawal = (amount, bankAccountId) => (dispatch) => {
	return post('users/withdrawal/bank', { amount, bank_details_id: bankAccountId }).then((response) => {
		dispatch(storeNewPendingWithdrawal(response.data.data));

		// Update the user account balance
		dispatch(fetchAuthenticatedUser());

		const withdrawal = response.data.data;

		const id = `${withdrawal.id}-${withdrawal.bank_details.user_id}`;
		const transactionData = {
			revenue: centsAsDollars(withdrawal.amount),
		};
		const items = [
			{
				name: 'Withdraw',
				sku: allWordsWithCapitals(withdrawal.withdrawal_type),
				category: allWordsWithCapitals(withdrawal.withdrawal_type),
				price: centsAsDollars(withdrawal.amount),
			},
		];

		dispatch(trackGaTransaction(id, transactionData, items));

		return response.data.data.id;
	});
};
