import { COLOR_TEXT_ONE, COLOR_TEXT_ONE_ALTERNATE, COLOR_TEXT_TWO, COLOR_TEXT_THREE } from './colours';

const ms = require('modularscale-js');
const modularscaleSettings = {
	base: [16],
	ratio: 1.125,
};
// const fontUnit = 'px';

export const PROXIMANOVA = 'Segoe UI Regular';//'"proximanova-regular", "Helvetica", "Arial", sans-serif';
export const FONT_BASE = PROXIMANOVA;
export const FONT_BASE_SIZE = modularscaleSettings.base;
export const FONT_BASE_UNIT = 'px';
export const LINE_HEIGHT = 1.3;

/**
 * Build our font value string based on the modular scale and the font unit
 *
 * @param scale
 * @returns {string}
 */
const fontScale = (scale = 0) => `${ms(scale, modularscaleSettings)}${FONT_BASE_UNIT}`;

export const FONT_SIZE_0 = fontScale(0);
export const FONT_SIZE__1 = fontScale(-1);
export const FONT_SIZE__2 = fontScale(-2);
export const FONT_SIZE__3 = fontScale(-3);
export const FONT_SIZE__4 = fontScale(-4);
export const FONT_SIZE__5 = fontScale(-5);

export const FONT_SIZE_1 = fontScale(1);
export const FONT_SIZE_2 = fontScale(2);
export const FONT_SIZE_3 = fontScale(3);
export const FONT_SIZE_4 = fontScale(4);
export const FONT_SIZE_5 = fontScale(5);
export const FONT_SIZE_6 = fontScale(6);

// newly added font size
export const FONT_SIZE_9 = '9px';
export const FONT_SIZE_10 = '10px';
export const FONT_SIZE_12 = '12px';
export const FONT_SIZE_15 = '15px';
export const FONT_SIZE_18 = '18px';
export const FONT_SIZE_20 = '20px';

export const FONT_SIZES = {
	'size_-5': FONT_SIZE__5,
	'size_-4': FONT_SIZE__4,
	'size_-3': FONT_SIZE__3,
	'size_-2': FONT_SIZE__2,
	'size_-1': FONT_SIZE__1,
	size_0: FONT_SIZE_0,
	size_1: FONT_SIZE_1,
	size_2: FONT_SIZE_2,
	size_3: FONT_SIZE_3,
	size_4: FONT_SIZE_4,
	size_5: FONT_SIZE_5,
	size_6: FONT_SIZE_6,

	size_9: FONT_SIZE_9,
	size_10: FONT_SIZE_10,
	size_12: FONT_SIZE_12,
	size_15: FONT_SIZE_15,
	size_18: FONT_SIZE_18,
	size_20: FONT_SIZE_20,
};

export const FONT_WEIGHT_LIGHT = 100;
export const FONT_WEIGHT_REGULAR = 400;
export const FONT_WEIGHT_BOLD = 700;

export const FONT_BASE_COLOR = COLOR_TEXT_ONE;
export const FONT_BASE_COLOR_LIGHT = COLOR_TEXT_TWO;
export const FONT_BASE_COLOR_ALT = COLOR_TEXT_ONE_ALTERNATE;
export const FONT_BASE_COLOR_ALT_LIGHT = COLOR_TEXT_THREE;

export const FONT_WEIGHTS = {
	light: FONT_WEIGHT_LIGHT,
	regular: FONT_WEIGHT_REGULAR,
	bold: FONT_WEIGHT_BOLD,
};
