const DEFAULT_MARKET_GROUP = 'DEFAULT_MARKET_GROUP';
const LEADERBOARD_DETAIL_TAB = 'leaderboard';
const PRIZE_TABLE_TAB = 'prizetable';
const SLEDGE_BOX_TAB = 'sledgebox';
const DETAILS_TAB = 'details';

module.exports = {
	DEFAULT_MARKET_GROUP,
	LEADERBOARD_DETAIL_TAB,
	PRIZE_TABLE_TAB,
	SLEDGE_BOX_TAB,
	DETAILS_TAB
};