import { CLEAR_RULES, SELECT_RULE, SET_ERROR, SET_LOADING, SET_RULES } from './legalRulesActionTypes';

const initialState = {
	rules: [],
	selectedRule: null,
	loading: false,
	error: null,
};

const legalRules = (state = initialState, action) => {
	switch (action.type) {
		case SET_RULES:
			return {
				...state,
				rules: action.payload,
			};
		case CLEAR_RULES:
			return {
				...initialState,
			};
		case SELECT_RULE:
			return {
				...state,
				selectedRule: action.payload,
			};
		case SET_ERROR:
			return {
				...state,
				error: action.payload,
			};
		case SET_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
};

export default legalRules;
