// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames/bind';

// Actions
import { scrollToTop } from '../../../store/application/applicationActions';
import { setRealityCheck } from '../../../store/entities/actions/UserActions';

// Components
import SetTimer from '../../../components/features/ResponsibleGambling/RealityCheckSettings/SetTimer/SetTimer';

class RealityCheckContainer extends Component {
	static propTypes = {
		first_name: PropTypes.string.isRequired,
		setRealityCheck: PropTypes.func.isRequired,

		/** Extra classes */
		className: PropTypes.string,

		reality_check: PropTypes.number,

		/** Brand name */
		brandName: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		reality_check: 0,
		brandName: null,
	};

	state = {
		confirmation: false,
		error: null,
		loading: false,
	};

	/**
	 * Calls the action to update the Reality Check
	 *
	 * @param time
	 */
	setRealityCheck = (time) => {
		this.setLoadingMask(true);

		return this.props
			.setRealityCheck(time)
			.then(() => {
				this.setState({
					error: null,
					confirmation: true,
				});
			})
			.catch((error) => {
				if (error.response.status === 400) {
					this.setState({
						error: error.response.data.errors,
					});
				} else {
					this.setState({
						error: error.message,
					});
				}
			})
			.finally(() => {
				this.setLoadingMask(false);
				scrollToTop();
			});
	};

	/**
	 * Set a loading mask
	 *
	 * @param loading
	 */
	setLoadingMask(loading = false) {
		this.setState({
			loading: loading,
		});
	}

	/**
	 * Remove the error message
	 */
	handleClearMessage = () => {
		this.setState({
			error: null,
			confirmation: false,
		});
	};

	render() {
		const { className, first_name, reality_check, brandName } = this.props;


		const { confirmation, error, loading } = this.state;

		const { setRealityCheck, handleClearMessage } = this;

		const containerClasses = cx({
			[className]: className,
		});

		return (
			<SetTimer
				className={containerClasses}
				confirmation={confirmation}
				clearMessage={handleClearMessage}
				error={error}
				first_name={first_name}
				updateIsLoading={loading}
				reality_check={reality_check}
				setRealityCheck={setRealityCheck}
				brandName={brandName}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	const user = state.entities.users[state.application.authenticatedUser];


	return {
		first_name: user ? user.first_name : null,
		reality_check: user ? user.reality_check_hours : null,
		brandName: state.acl.brandDetails && state.acl.brandDetails.name,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setRealityCheck: (period) => {
			return dispatch(setRealityCheck(period));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RealityCheckContainer);
