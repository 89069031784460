import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';
// get the default silk image
import { GENERIC_SILK, SELECTION_NOT_SCRATCHED_STATUS } from '../../../../../common/constants/Racing';

import {
	StyledGRSNtjGreyhoundsRaceSelectionDetails__DetailsColumn,
	StyledGRSNtjRaceSelectionDetails__DetailsColumn,
	StyledGRSNtjRaceSelectionDetails__Name,
	StyledGRSNtjRaceSelectionDetails__RunnerDetails,
	StyledGRSNtjRaceSelectionDetails__Silk,
	StyledGRSNtjRaceSelectionDetails__SilkImage,
	StyledGRSNtjRaceSelectionDetails__Subtitle,
	StyledUpRaceSelectionDetails,
} from './UpRaceSelectionDetails.styled';

const UpRaceSelectionDetails = (props) => {
	const {
		t,
		className,
		hideSilk,
		number,
		name,
		silk,
		jockey,
		trainer,
		last_starts_string,
		selection_status,
		subtitle,
		type_code,
		size,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});
	/**
	 * Create the jockey or driver label dependant on the race type code
	 * NOTE: no need to cater for greyhounds as it is not rendered
	 * last_starts_string.substring(last_starts_string.length , 5); This is the get last _starts_string in jocky.
	 * @type {string}
	 */
	let jockeyLabel = type_code === 'H' ? t('AcronymDriver') : t('AcronymJockey');

	let lastFiveStarts;
	if (last_starts_string) {
		lastFiveStarts = last_starts_string.substring(0, 5);
	}

	const scratched = selection_status !== SELECTION_NOT_SCRATCHED_STATUS;
	const trainerLabel = trainer;

	return (
		<StyledUpRaceSelectionDetails className={componentClasses}>
			{!hideSilk && (
				<StyledGRSNtjRaceSelectionDetails__Silk scratched={scratched} size={size}>
					<StyledGRSNtjRaceSelectionDetails__SilkImage
						size={size}
						src={silk ? silk : GENERIC_SILK}
						alt={name + 'racing silk'}
						substituteImageSrc={GENERIC_SILK}
					/>
				</StyledGRSNtjRaceSelectionDetails__Silk>
			)}

			{type_code && type_code !== 'G' ? (
				<StyledGRSNtjGreyhoundsRaceSelectionDetails__DetailsColumn scratched={scratched} size={size}>
					<StyledGRSNtjRaceSelectionDetails__Name
						lineThrough={scratched}
						mediaSize={size}
						size="-3"
						transform="uppercase"
						strong
					>
						{number && `${number}.`} {name}
					</StyledGRSNtjRaceSelectionDetails__Name>
					{subtitle && (
						<StyledGRSNtjRaceSelectionDetails__Subtitle size="-3">
							{subtitle}
						</StyledGRSNtjRaceSelectionDetails__Subtitle>
					)}
					{type_code &&
						type_code !== 'G' && (
							<StyledGRSNtjRaceSelectionDetails__RunnerDetails
								mediaSize={size}
								size="-4"
								whiteSpace="nowrap"
								textOverflow="ellipsis"
							>
								{jockeyLabel}: {jockey}{' '}
							</StyledGRSNtjRaceSelectionDetails__RunnerDetails>
						)}

					{trainerLabel && (
						<StyledGRSNtjRaceSelectionDetails__RunnerDetails mediaSize={size} size="-4">
							{t('AcronymTrainer')}: {trainerLabel}
						</StyledGRSNtjRaceSelectionDetails__RunnerDetails>
					)}
				</StyledGRSNtjGreyhoundsRaceSelectionDetails__DetailsColumn>
			) : (
				/* Thoroughbred and Harness*/

				<StyledGRSNtjRaceSelectionDetails__DetailsColumn scratched={scratched} size={size}>
					<StyledGRSNtjRaceSelectionDetails__Name
						lineThrough={scratched}
						mediaSize={size}
						size="-3"
						transform="uppercase"
						strong
					>
						{number && `${number}.`} {name}
					</StyledGRSNtjRaceSelectionDetails__Name>
					{subtitle && (
						<StyledGRSNtjRaceSelectionDetails__Subtitle size="-3">
							{subtitle}
						</StyledGRSNtjRaceSelectionDetails__Subtitle>
					)}
					{type_code &&
						type_code !== 'G' && (
							<StyledGRSNtjRaceSelectionDetails__RunnerDetails
								mediaSize={size}
								size="-4"
								whiteSpace="nowrap"
								textOverflow="ellipsis"
							>
								{jockeyLabel}: {jockey}{' '}
							</StyledGRSNtjRaceSelectionDetails__RunnerDetails>
						)}

					{trainerLabel && (
						<StyledGRSNtjRaceSelectionDetails__RunnerDetails mediaSize={size} size="-4">
							{t('AcronymTrainer')}: {trainerLabel}
						</StyledGRSNtjRaceSelectionDetails__RunnerDetails>
					)}
				</StyledGRSNtjRaceSelectionDetails__DetailsColumn>
			)}
		</StyledUpRaceSelectionDetails>
	);
};

UpRaceSelectionDetails.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}),

	/** The selection name */
	name: PropTypes.string.isRequired,

	/** The selection number */
	number: PropTypes.number,

	/** The race selection type */
	type_code: PropTypes.string,

	/** The jockey weight */
	weight: PropTypes.number,

	/** The jockey silk */
	silk: PropTypes.string,

	/** The jockey name */
	jockey: PropTypes.string,

	/** The trainer name */
	trainer: PropTypes.string,

	/** The selection barrier */
	barrier: PropTypes.string,

	/** The last starts for the selection */
	last_starts_string: PropTypes.string,

	/** The selection status */
	selection_status: PropTypes.oneOf(['not scratched', 'scratched', 'late scratching']),

	/** The fixed price changes/flucs */
	fluctuations: PropTypes.string,

	/**
	 * If the silk should be hidden or not.
	 * In the race card we need to show a generic silk for the runners that don't have a silk,
	 * but hide the image for selections that don't have a silk (E.g.: in the derivative selections).
	 */
	hideSilk: PropTypes.bool,

	/** Extra text to display under the runner */
	subtitle: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,
};

UpRaceSelectionDetails.defaultProps = {
	className: null,
	weight: null,
	silk: null,
	jockey: null,
	trainer: null,
	barrier: null,
	last_starts_string: null,
	selection_status: null,
	fluctuations: null,
	number: null,
	subtitle: null,
	type_code: null,
	hideSilk: false,
	size: {},
};
export default withNamespaces()(UpRaceSelectionDetails);
