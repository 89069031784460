import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';

import PusherSubscribe from './PusherSubscribe';
import { normalizeAndMergeBet } from '../../../store/entities/actions/BetActions';
import { normalizeAndMergeUser } from '../../../store/entities/actions/UserActions';
import { connect } from 'react-redux';

const PusherSubscribeAuthenticateUser = ({ user_id }) => {
	if (!user_id) return null;

	const PrivateUserchannel = `private-user_${user_id}`;
	const PrivateBetchannel = `private-bet_${user_id}`;
	const channel = 'presence-updates';
	const event = 'update';

	const PrivateUserOnUpdate = () => {};

	const PrivateBetOnUpdate = () => {};

	return (
		<Fragment>
			<PusherSubscribe
				channel={channel}
				event={'UpdateCreated'}
				onUpdate={(data) => {
					// console.log(data)
				}}
			/>
			<PusherSubscribe channel={PrivateUserchannel} event={event} onUpdate={PrivateUserOnUpdate} />
			<PusherSubscribe channel={PrivateBetchannel} event={event} onUpdate={PrivateBetOnUpdate} />
		</Fragment>
	);
};

PusherSubscribeAuthenticateUser.propTypes = {
	user_id: PropTypes.number.isRequired,
};
const mapStateToProps = (state) => {
	return {
		user_id: state.application.authenticatedUser,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		mergeBet: (bet) => {
			return dispatch(normalizeAndMergeBet(bet));
		},
		mergeUser: (user) => {
			return dispatch(normalizeAndMergeUser(user));
		},
	};
};
export default memo(connect(mapStateToProps, mapDispatchToProps)(PusherSubscribeAuthenticateUser));
