import {slugify} from '../legacy/core/format';
import {RACING_BASE_URL, SELECTED_DATE_FORMAT} from '../pages/Racing/RacingConstants';
import {RACING_TYPES_LOOKUP} from '../common/constants/Racing';
import {SERVER_DATE_FORMAT} from '../common/constants/DateFormats';
import moment from 'moment';


/**
 * Build route for date. This function will handle cases where we pass 'YYYY-MM-DDZ' format, e.g. '2010-10-10+11',
 * and should respect given timezone.
 *
 * @param date
 * @return {string}
 */
export const buildRacingRouteToDate = (date) => {
	const datetime = moment.parseZone(date, SELECTED_DATE_FORMAT);
	let route = `/${RACING_BASE_URL}`;

	if (datetime.isValid()) {
		route += `/all/${datetime.format(SERVER_DATE_FORMAT)}`;
	}

	return route;
};




/**
 *
 * @param type
 * @param meetingName
 * @param raceNumber
 * @param raceId
 * @param meetingId
 * @return {string}
 */
export const buildRouteToRace = (
	{id: meetingId, name: meetingName, type} = {},
    {number: raceNumber, id: raceId} = {}
) => {

	let route = `/${RACING_BASE_URL}`;

	// If no Id route to home
	if (!meetingId || !raceId) {
		return route;
	}

	if (type) {
		// If 'type' holds type code
		if (type.length === 1) {
			route += `/${slugify(RACING_TYPES_LOOKUP[type.toUpperCase()])}`;
		}
		else if (type.length > 1) {
			route += `/${slugify(type)}`;
		}
	} else {
		route += '/unknown';
	}

	if (meetingName) {
		route += `/${slugify(meetingName)}`;
	} else {
		route += '/unknown';
	}

	route += `/race-${(raceNumber || 0)}-${raceId}-${meetingId}`;

	return route;
};

export const buildRacingRouteFromState = (state) => {
	const {racingHome, entities} = state;
	const {selectedRace, selectedMeeting, selectedDate } = racingHome;
	// 'Or' includes alternative cases covered by called function
	if (selectedRace || selectedMeeting) {
		const {meetings, races} = entities;
		return buildRouteToRace(meetings[selectedMeeting], races[selectedRace]);

	} else if (selectedDate) {
		// If no meeting or race but there is a date, route to date
		return buildRacingRouteToDate(selectedDate);
	}

	// default case
	return `/${RACING_BASE_URL}`;
};
