import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';
import styled, { css } from 'react-emotion';

import { typography, Table } from '@tbh/ui-kit';
import { formatOdds } from '../../../../../common/BetPlacement';

const StyledRaceResultsExotic__Header = styled(Table.TR)(
	(props) => css`
		label: RaceResultsExotic__Header;

		font-size: ${typography(props)['size_-3']};
	`,
);

const StyledRaceResultsExotic__BetType = styled(Table.TD)`
	label: RaceResultsExotic__BetType;

	text-transform: uppercase;
`;

const RaceResultsExotic = (props) => {
	const { t, className, results } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<Table density="cozy" size="-2" className={componentClasses}>
			<Table.TG thead compact size="-3">
				<StyledRaceResultsExotic__Header bottomBorder>
					<Table.TD strong>{t('Exotic')}</Table.TD>
					<Table.TD strong center colSpan="2">
						{t('Selections')}
					</Table.TD>
					<Table.TD strong right>
						{t('Paid')}
					</Table.TD>
				</StyledRaceResultsExotic__Header>
			</Table.TG>
			{results.map((result) => (
				<Table.TR key={`${result.bet_type}_${result.selections}_${result.dividend}`} bottomBorder>
					<StyledRaceResultsExotic__BetType>{result.bet_type}</StyledRaceResultsExotic__BetType>
					<Table.TD center colSpan="2">
						{result.selections}
					</Table.TD>
					<Table.TD right>{formatOdds(result.dividend)}</Table.TD>
				</Table.TR>
			))}
		</Table>
	);
};

RaceResultsExotic.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** List of exotic race results */
	results: PropTypes.arrayOf(
		PropTypes.shape({
			/** Exotic bet type */
			bet_type: PropTypes.string.isRequired,

			/** The list of selections in the result */
			selections: PropTypes.string.isRequired,

			/** The dividend amount */
			dividend: PropTypes.number.isRequired,
		}),
	).isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

RaceResultsExotic.defaultProps = {
	className: null,
};

export default withNamespaces()(RaceResultsExotic);
