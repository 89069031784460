import {sortStateSliceByName, sortStateSliceByOrderThenName} from '../../../common/SelectorHelpers';

/**
 * Receives normalized markets and returns array sorted by name
 *
 * @param markets - normalized markets
 * @return {Array}
 */
export const getMarketsSortedByName = (markets) => {
	return sortStateSliceByName(markets);
};



/**
 * Receives normalized markets and returns array sorted by order then name
 *   - allows for order to be undefined
 *   - allows for order to be a string of numbers or a numeral ... ie. '100' or 100
 *
 * @param markets - normalized markets
 * @returns {Array}
 */
export const getMarketsSortedByOrderThenName = (markets) => {
	return sortStateSliceByOrderThenName(markets);
};
