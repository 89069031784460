import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, brand, ui } from '@tbh/ui-kit';

import {
	GOAT_PRODUCT_TYPE_BOOST,
	GOAT_PRODUCT_TYPE_STANDARD,
	GOAT_SPORTS_BUMP_ICON_SIZE,
} from '../../../../../common/constants/GoatProducts';
import { trackGaEvent } from '../../../../../store/trackingPixels/trackingActions';

import { HeaderBar } from '@tbh/ui-kit';
import SelectionItem from '../SelectionItem/SelectionItem';
import Svg from '../../../../ui-components/Svg/Svg';

const StyledMarketItem = styled('div')`
	label: MarketItem;

	${(props) =>
		css`
			margin-bottom: ${spacings(props).compact}px;
		`};
`;

const StyledMarketItem__Icon = styled(Svg)`
	label: MarketItem__Icon;

	${(props) => css`
		background: ${ui(props).color_2};
		border-radius: 50%;
		cursor: pointer;

		${props.priceBumpOn &&
			css`
				background: ${brand(props).color_1};
			`};
	`};
`;

class MarketItem extends Component {
	static propTypes = {
		/** The market name for the market item */
		market_name: PropTypes.string.isRequired,

		/** The list of available selections in the market */
		selectionItems: PropTypes.arrayOf(
			PropTypes.shape({
				/** The selection ID */
				id: PropTypes.number.isRequired,

				/** The selection name */
				name: PropTypes.string.isRequired,

				/** The selection price */
				price: PropTypes.number.isRequired,

				/** The selection line */
				line: PropTypes.any,
			}),
		).isRequired,

		/** Action to add the selection to the bet prompt */
		addToSingle: PropTypes.func.isRequired,

		/** The products available for the event */
		products: PropTypes.arrayOf(
			PropTypes.shape({
				product_id: PropTypes.number.isRequired,
				product_type: PropTypes.string.isRequired,
			}),
		).isRequired,

		/** Action to fetch the rolled up price of a boosted fixed price */
		fetchFixedPriceRollup: PropTypes.func.isRequired,

		/** Action to fetch the amount of rolls for a price */
		fetchRollTablePrice: PropTypes.func.isRequired,

		/** Action to add the selection to the multi bet slip */
		addToMulti: PropTypes.func,

		/** Indicate whether bump is available */
		bumpAvailable: PropTypes.bool,

		/** Disable the selection from being bet on */
		disabled: PropTypes.bool,

		/** Price bump product */
		priceBump: PropTypes.bool,

		/** Action for clicking on the price bump icon */
		onPriceBumpClick: PropTypes.func,

		/** If the price bump is selected or not */
		priceBumpIsSelected: PropTypes.bool,

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		className: null,
		selection_line: null,
		addToMulti: null,
		disabled: false,
		priceBump: false,
		bumpAvailable: false,
		onPriceBumpClick: undefined,
		priceBumpIsSelected: false,
	};

	state = {
		priceBumpOn: null,
		bumpAvailable: null,
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		// Clear the bump state if it is no longer available
		if (nextProps.bumpAvailable !== prevState.bumpAvailable) {
			return {
				priceBumpOn: false,
				bumpAvailable: nextProps.bumpAvailable,
			};
		}

		return null;
	}

	/**
	 * Toggle the price bump active state
	 */
	handlePriceBumpClick = () => {
		this.setState((prevState) => {
			if (!prevState.priceBumpOn) {
				trackGaEvent('SportMarketSelector', 'Select', 'GOAT Sport Bump');
			}

			return {
				priceBumpOn: this.props.bumpAvailable ? !prevState.priceBumpOn : false,
			};
		});
	};

	/**
	 * Get the selected productId
	 */
	getProductId = (products, priceBumpIsSelected) => {
		if (!products) {
			// TODO: this needs to be removed once data feed has products which is required
			return null;

			// throw new Error('No products could not be found.');
		}

		const productType = priceBumpIsSelected ? GOAT_PRODUCT_TYPE_BOOST : GOAT_PRODUCT_TYPE_STANDARD;

		const selectedProduct = products.find(function(product) {
			return product.product_type === productType;
		});

		return selectedProduct.product_id;
	};

	render() {
		const {
			className,
			market_name,
			selectionItems,
			products,
			addToSingle,
			addToMulti,
			disabled,
			priceBump,
			fetchFixedPriceRollup,
			fetchRollTablePrice,
		} = this.props;

		const priceBumpIsSelected = this.state.priceBumpOn;

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<StyledMarketItem className={componentClasses}>
				<HeaderBar
					margin
					type="support"
					strong
					aside={
						priceBump && (
							<StyledMarketItem__Icon
								onClick={this.handlePriceBumpClick}
								name="goat-bump"
								width={GOAT_SPORTS_BUMP_ICON_SIZE}
								height={GOAT_SPORTS_BUMP_ICON_SIZE}
								priceBumpOn={priceBumpIsSelected}
							/>
						)
					}
				>
					{market_name}
				</HeaderBar>
				{selectionItems &&
					selectionItems.map((selection) => (
						<SelectionItem
							disabled={disabled}
							key={selection.id}
							selection_id={selection.id}
							selection_name={selection.name}
							selection_price={selection.price}
							selection_line={selection.line}
							addToSingle={addToSingle}
							addToMulti={priceBumpIsSelected ? null : addToMulti}
							fetchFixedPriceRollup={fetchFixedPriceRollup}
							fetchRollTablePrice={fetchRollTablePrice}
							priceBumpIsSelected={priceBumpIsSelected}
							productId={this.getProductId(products, priceBumpIsSelected)}
						/>
					))}
			</StyledMarketItem>
		);
	}
}

export default MarketItem;
