import {
	loadSportsHomeData,
	loadSportData,
	loadCompetitionData,
	setSportsHomeFilters,
	setFiltersForCompetitionAndEvent,
} from '../store/sportsHome/sportsHomeActions';
import { slugify } from '../legacy/core/format';
import { fetchCompetition } from '../store/entities/actions/CompetitionActions';
import { fetchEvent } from '../store/entities/actions/EventActions';
import { fetchSportsByCompetitionId } from '../store/entities/actions/SportsActions';
import { fetchCombinedMarketsGroupsWithSelections } from '../store/entities/actions/MarketActions';
import { setBodyLoading } from '../store/application/applicationActions';
import { show404, scrollToTop, closeSideBetSlip } from '../store/application/applicationActions';

const { dispatch, getState } = App.store;

// Constants
const { SPORTS_BASE_URL, SPORTS_SUB_APP, SPORTS_UPCOMING_SUB_APP } = require('../pages/Sports/SportsConstants');

/**
 * Sports Router
 * -------------
 * Provides routing functionality for the sports sub app.
 *
 * @module routers/SportsRouter
 */
const SportsRouter = Backbone.Router.extend({
	routes: {
		[SPORTS_BASE_URL]: 'showSportsHome',
    [`${SPORTS_BASE_URL}/Upcoming`]:'showUpcoming',
    // [`${SPORTS_BASE_URL}/:sportName`]: 'showUpcoming',
		[`${SPORTS_BASE_URL}/:sportName`]: 'showSport',
		[`${SPORTS_BASE_URL}/:sportName/:competitionName-:competitionId`]: 'showCompetition',
		[`${SPORTS_BASE_URL}/:sportName/:competitionName-:competitionId/:eventName-:eventId`]: 'showEvent',
	},

	showSportsHome: function() {
		this.resetSportsStore(null);
		scrollToTop();
		dispatch(closeSideBetSlip(true)); // add betslip open  22Jan2021
		return dispatch(loadSportsHomeData())
			.then(() => {
				dispatch(setBodyLoading(false));
				return App.startSubApp(SPORTS_SUB_APP);
			})
			.catch((e) => {
				//console.log(e);
				dispatch(show404());
			});
	},

  showUpcoming: function (){
    scrollToTop();
    dispatch(closeSideBetSlip(true));
    dispatch(setBodyLoading(false));
    return dispatch(loadSportsHomeData())
      .then(() => {
        dispatch(setBodyLoading(false));
        return App.startSubApp(SPORTS_UPCOMING_SUB_APP);
      })
      .catch((e) => {
        //console.log(e);
        dispatch(show404());
      });
  },
	/**
	 * Receives wildcard and call Sports container to mount based on sport name.
	 *
	 * @param sportName
	 */
	showSport: function(sportName) {
		this.resetSportsStore(sportName);
		scrollToTop();
		dispatch(closeSideBetSlip(true)); // add betslip open  22Jan2021

		/**
		 * It's better fetching all data prior to render
		 */
		return dispatch(loadSportData(sportName))
			.then(() => {
				dispatch(setBodyLoading(false));
				return App.startSubApp(SPORTS_SUB_APP);
			})
			.catch((e) => {
				//console.log(e);
				dispatch(show404());
			});
	},

	/**
	 * Receives wildcards and call Sports container to mount based on competition.
	 *
	 * @param sportName
	 * @param competitionName
	 * @param competitionId
	 */
	showCompetition: function(sportName, competitionName, competitionId) {
		this.resetSportsStore(sportName, competitionName, competitionId);
		scrollToTop();
		dispatch(closeSideBetSlip(true)); // add betslip open  22Jan2021
		return dispatch(loadCompetitionData(parseInt(competitionId), sportName))
			.then(() => {
				dispatch(setBodyLoading(false));
				return App.startSubApp(SPORTS_SUB_APP);
			})
			.catch((e) => {
				//console.log(e);
				dispatch(show404());
			});
	},

	/**
	 * Receives wildcards and call Sports container to mount based on event.
	 * Deep linking to event was isolated in this function.
	 * @param sportName
	 * @param competitionName
	 * @param competitionId
	 * @param eventName
	 * @param eventId
	 */
	showEvent: function(sportName, competitionName, competitionId, eventName, eventId) {
		this.resetSportsStore(sportName, competitionName, competitionId, eventId);
		scrollToTop();
		dispatch(closeSideBetSlip(true)); // add betslip open  22Jan2021
		/**
		 * In order the fetch minimal data for a resulted event, we are fetching
		 * data for the single competition and event mentioned in the route.
		 * Todo: move this logic to an action.
		 */
		competitionId = parseInt(competitionId);
		eventId = parseInt(eventId);
		return Promise.all([
			dispatch(fetchCompetition(competitionId)),
			dispatch(fetchEvent(eventId)),
			dispatch(fetchSportsByCompetitionId(competitionId)),
			dispatch(fetchCombinedMarketsGroupsWithSelections(eventId)),
		]).then(() => {
			dispatch(setFiltersForCompetitionAndEvent(competitionId, eventId, sportName));
			dispatch(setBodyLoading(false));return App.startSubApp(SPORTS_SUB_APP);
		}).catch((e) => {
			//console.log(e);
			dispatch(show404());
		});
	},

	resetSportsStore: function(sportName, competitionName, competitionId, eventId) {
		let state = getState();
		let selectedSport = Object.values(state.entities.sports).find((sport) => slugify(sport.name) === sportName) || {};
		let selectedCompetition = state.entities.competitions[competitionId] || {};
		let selectedEvent = state.entities.events[eventId] || {};
		let selectedBaseCompetitionId = selectedCompetition.base_competition_id;
		dispatch(setBodyLoading(true));
		dispatch(
			setSportsHomeFilters(
				selectedSport.id,
				selectedSport.name || sportName,
				selectedBaseCompetitionId,
				selectedCompetition.id,
				selectedEvent.id,
			),
		);
	},
});

module.exports = new SportsRouter();
