import { PROMOTIONS_BASE_URL, PROMOTIONS_SUB_APP } from './PromotionsRouterConstants';
import { fetchCategoryArticles } from '../store/entities/actions/ArticleActions';
import {
	setSelectedPromotionWithSlug,
	setLoadingPromotionsCompleted,
} from '../store/promotionsPage/promotionsPageActions';
import { setBodyLoading, scrollToTop } from '../store/application/applicationActions';

import { GENERIC_ERROR_MESSAGE } from '../common/constants/Notifications';

const PromotionsRouter = Backbone.Router.extend({
	routes: {
		[PROMOTIONS_BASE_URL]: 'showPromotions',
		[PROMOTIONS_BASE_URL + '/:slug']: 'showPromotions',
	},

	showPromotions: function(slug) {
		App.store.dispatch(setBodyLoading(true));
		scrollToTop();

		App.store
			.dispatch(fetchCategoryArticles())
			.then(() => {
				App.store.dispatch(setSelectedPromotionWithSlug(slug));

				App.store.dispatch(setLoadingPromotionsCompleted());

				App.startSubApp(PROMOTIONS_SUB_APP);
			})
			.catch((exception) => {
				// Set the default error message
				let errorMessage = GENERIC_ERROR_MESSAGE;

				// Use the error message if supplied
				if (exception && exception.message) {
					errorMessage = exception.message;
				}

				App.store.dispatch(setLoadingPromotionsCompleted(errorMessage));

				App.startSubApp(PROMOTIONS_SUB_APP);
			});
	},
});

export default new PromotionsRouter();
