import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSportsSortedByName } from '../../store/entities/selectors/SportSelectors';
import { getPlayerBetItem } from '../../store/entities/selectors/NavigationItemsSelectors';
import { onNavigationItemSelect } from '../../store/entities/actions/NavigationItemsActions';
import { navigate } from '../../store/application/applicationActions';
import SportsList from '../../components/features/Sports/SportsList/SportsList';
import { buildRouteToSport } from '../../routes/Sports';

const mapStateToProps = (state, ownProps) => ({
	playerBets: getPlayerBetItem(state.entities),
	sports: getSportsSortedByName(state.entities.sports),
	className: ownProps.className,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	onItemClick: (name) => dispatch(navigate(buildRouteToSport(name), { trigger: true, keepAdvert: true })),
	onPlayerBetsClick: (item) => dispatch(onNavigationItemSelect(item)),
});

const Container = connect(
	mapStateToProps,
	mapDispatchToProps,
)(SportsList);

Container.propTypes = {
	/** Extra classes */
	className: PropTypes.string,
};

export default Container;
