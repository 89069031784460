// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import moment from 'moment';
import styled, { css } from 'react-emotion';
import { typography, ui } from '@tbh/ui-kit';

// Components
import { Button, ButtonGroup, Input, Notification, Panel, Text } from '@tbh/ui-kit';

// Constants
import { SHORT_DATE_FORMAT } from '../../../../common/constants/DateFormats';

// Functions
import { centsAsDollars } from '../../../../legacy/core/format';

const StyledUKLimitConfirmation__ConfirmationInput = styled(Input)(
	(props) => css`
		label: UKLimitConfirmation__ConfirmationInput;

		color: ${ui(props).color_success};
		font-weight: ${typography(props).weight.bold};
	`,
);

const UKLimitConfirmation = (props) => {
	const {
		limitType,
		bet_limit,
		requested_date,
		requested_bet_limit,
		onUndo,
		onConfirmLimit,
		error,
		clearError,
		period,
		className,
		brandName,
	} = props;

	// date that the request was made
	let date = moment(requested_date).format(SHORT_DATE_FORMAT);

	// Just incase the requested date does not exist. However, this should not happen, but its better than showing the current date
	if (!requested_date) {
		date = '-';
	}

	let text;
	if (period === 'daily') {
		text = ' - 24 hours';
	} else if (period === 'weekly') {
		text = ' - 7 days';
	} else if (period === 'monthly') {
		text = ' - 30 days';
	}

	let old_limit = centsAsDollars(bet_limit) + text;
	let new_limit = centsAsDollars(requested_bet_limit) + text;

	/**
	 * hook for set/update/removing the limit
	 */
	let handleConfirmLimit = () => {
		onConfirmLimit(requested_bet_limit);
	};

	const limitConfirmationClass = cx({
		[className]: className,
	});

	return (
		<div className={limitConfirmationClass}>
			{error && (
				<Notification strong type={Notification.types.COLOUR_DANGER} message={error} buttonAction={clearError} />
			)}

			<Panel padding={Panel.padding.SPACING_COZY}>
				<Text paragraph strong>
					You requested a {limitType} limit {requested_bet_limit > 0 ? 'increase' : 'removal'}
				</Text>

				<StyledUKLimitConfirmation__ConfirmationInput label="Request Made" value={date} margin="cozy" disabled />

				<StyledUKLimitConfirmation__ConfirmationInput label="Old Limit" value={old_limit} margin="cozy" disabled />

				{requested_bet_limit > 0 ? (
					<StyledUKLimitConfirmation__ConfirmationInput label="New Limit" value={new_limit} disabled margin="cozy" />
				) : null}

				<Text size="-1" paragraph>
					Please confirm that you wish this change to take place. If you confirm that you want this change it will apply
					from now. If you Undo the change the old limit will apply.
				</Text>
				<Text size="-1" paragraph>
					If you wish to make another change - you will have to make another request.
				</Text>
				<Text size="-1" paragraph strong>
					{`${brandName ? brandName + ' ' : ''}Support Team`}
				</Text>

				<ButtonGroup center>
					<Button type="ghost" action={onUndo} flex>
						Undo
					</Button>

					<Button action={handleConfirmLimit} type="danger" flex>
						Confirm
					</Button>
				</ButtonGroup>
			</Panel>
		</div>
	);
};

UKLimitConfirmation.propTypes = {
	/** Type of limit that will appear in the text */
	limitType: PropTypes.oneOf(['deposit', 'loss', 'spend']).isRequired,

	/** If the new limit is daily, weekly or monthly limit */
	period: PropTypes.oneOf(['daily', 'weekly', 'monthly']).isRequired,

	/** Cancel and return to daily selection screen */
	onUndo: PropTypes.func.isRequired,

	/** Hook for confirming the new limit */
	onConfirmLimit: PropTypes.func.isRequired,

	/** User's old bet limit */
	bet_limit: PropTypes.number.isRequired,

	/** User's new bet limit */
	requested_bet_limit: PropTypes.number.isRequired,

	/** Date the limit was requested */
	requested_date: PropTypes.string.isRequired,

	/** Function to clear the error message */
	clearError: PropTypes.func.isRequired,

	/** Error returned from the function setBetLimit */
	error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

	/** Extra classes */
	className: PropTypes.string,

	/** Brand name */
	brandName: PropTypes.string,
};

UKLimitConfirmation.defaultProps = {
	error: null,
	className: null,
	brandName: null,
};

export default UKLimitConfirmation;
