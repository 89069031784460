import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled, { css } from 'react-emotion';
import { brand, typography } from '@tbh/ui-kit';
import { FAMILYS, MEDIA_QUERIES, DEFAULT_COLORS } from '../../constants/themes';
import EventContext from '../../contexts/eventContext';
import { serverDateTime } from '../../legacy/core/format';
import { StyledPageComponentContainer } from '../../components/features/Application/PageComponentContainer/PageComponentContainer.styled';
import Spinner from '../../components/UI/Spinner/Spinner';
import ExpertTipsList from '../../components/features/ExpertTipsList/ExpertTipsList';
import { withNamespaces } from 'react-i18next';
import { createErrorBoundary } from '../../containers/ErrorBoundary/ErrorBoundaryActions';
import { connect } from 'react-redux';
import { getAllExpertTipsByQuery } from '../../store/entities/actions/ExpertRacesActions';
import { getExpertRaces } from './RSTipsSelectors';

const TipsCard = styled('div')`
	display: flex;
	flex-direction: column;
	width: 100%;   
`;

const TipsCardHeader = styled('div')(
    (props) => css`
        background-color: ${brand(props).tertiary_color ? brand(props).tertiary_color : DEFAULT_COLORS.df_tertiary_color};
        color: #ffffff;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        padding: 12px 12px;
        display: flex;
        flex-direction: column;
        align-items: inherit;
        gap: 6px;

        @media ${MEDIA_QUERIES.laptopAndUp} {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 12px 24px;
        }
    `
);

const TipsCardHeaderLeft = styled('div')`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	@media ${MEDIA_QUERIES.laptopAndUp} {
		display: block;
	}
`;

const TipsCardHeaderRight = styled('div')`
	background-color: #1e1e1e;
	color: #ffffff;
	border-radius: 4px;
	padding: 12px;
`;


const TipsCardTitle = styled('h4')(
    (props) => css`
        color: #ffffff;
        font-weight: 600;
        font-size: 24px;
        text-transform: uppercase;
        margin-bottom: 4px;
        margin-right: auto;
        font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};

        @media ${MEDIA_QUERIES.laptopAndUp} {
            margin-bottom: 4px;
        }
    `
);


const TipsCardBody = styled('div')`
	border: 1px solid #f0eeed;
	padding: 12px 24px;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    
`;

const TipsFilterLabel = styled('span')`
	display: inline-block;
	font-weight: 400;
	font-size: 16px;
	margin-right: 6px;
`;

const RaceTypeIcon = styled('img')`
	width: 24px;
	height: 24px;
	object-fit: cover;
`;

const TipsButton = styled('button')(
    (props) => css`
        background: none;
        color: #111111;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        text-decoration: none;
        border: 0;
        padding: 0.375rem 0.75rem;
        font-weight: 500;
        font-size: 15px;
        line-height: 1.5;
        font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};
        cursor: pointer;
    `
);


const TipsButtonPrimary = styled(TipsButton)`
	color: ${(props) => (props.isActive ? '#ffffff' : '#1e1e1e')};
	background-color: ${(props) => (props.isActive ? '#660809' : '#ffffff')};
	border-radius: 3px;
	transition: background-color 0.2s linear, color 0.2s linear;

	&:hover {
		background-color: #660809;
		color: #ffffff;
	}

	&:focus {
		background-color: #660809;
		color: #ffffff;
		outline: none;
		box-shadow: none;
	}

	&:active {
		background-color: #660809;
		color: #ffffff;
		outline: none;
		box-shadow: none;
	}

	&:not(:last-of-type) {
		margin-right: 4px;
	}
`;

const TipsButtonGroup = styled(TipsButton)`
	color: #1e1e1e;
	background-color: ${(props) => (props.isActive ? '#eda0a0' : '#ffffff')};
	position: relative;
	display: inline-flex;
	border-radius: 3px;
	transition: background-color 0.2s linear, color 0.2s linear;

	&:not(:last-child) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	&:nth-child(n + 3) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	&:hover {
		background-color: #eda0a0;
		color: #ffffff;
		outline: none;
		box-shadow: none;
	}

	&:active {
		background-color: #eda0a0;
		color: #ffffff;
		outline: none;
		box-shadow: none;
	}
`;

const SpinnerWrapper = styled('div')`
	display: flex;
	justify-content: center;
	margin: 32px auto;
`;

const RSTips = ({ getAllExpertTips, tips }) => {
    const [loading, setLoading] = useState(true);
    const [limit, setLimit] = useState(10);
    const [raceType, setRaceType] = useState('T');
    const [selectDate, setSelectDate] = useState('');

    useEffect(() => {
        setLoading(true);
        const fetch = async () => {
            try {
                await getAllExpertTips(limit, selectDate, raceType);
            } catch (error) {
                //console.log(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetch();
    }, [limit, selectDate, raceType]);

    const handleSelectedToday = () => {
        const today = serverDateTime(moment());
        setSelectDate(today.format('YYYY-MM-DD'));
    };

    const handleSelectedTomorrow = () => {
        const tomorrow = serverDateTime(moment().add(1, 'day'));
        setSelectDate(tomorrow.format('YYYY-MM-DD'));
    };

    const handleSelectedRaceType = (type) => {
        setRaceType(type);
    };
    const isToday = serverDateTime(moment());
    const isTomorrow = serverDateTime(moment().add(1, 'day'));
    //const filterMeetings = tips.filter((meetingItem, index) => tips.findIndex((item) => item.id === meetingItem.id) === index);

    const renderExpertTips = loading ? (
        <SpinnerWrapper>
            <Spinner />
        </SpinnerWrapper>
    ) : (
        <EventContext.Consumer>
            {(eventContext) => <ExpertTipsList eventContext={eventContext} tipsList={tips} raceType={raceType} />}
        </EventContext.Consumer>
    );
    return (
        <StyledPageComponentContainer>
            <div>
                <TipsCard>
                    <TipsCardHeader>
                        <TipsCardHeaderLeft>
                            <TipsCardTitle>RS Tips</TipsCardTitle>
                            <TipsButtonPrimary isActive={selectDate === isToday.format('YYYY-MM-DD')} onClick={handleSelectedToday}>
                                Today
                            </TipsButtonPrimary>
                            <TipsButtonPrimary
                                isActive={selectDate === isTomorrow.format('YYYY-MM-DD')}
                                onClick={handleSelectedTomorrow}
                            >
                                Tomorrow
                            </TipsButtonPrimary>
                        </TipsCardHeaderLeft>
                        <TipsCardHeaderRight>
                            <TipsFilterLabel>Filter</TipsFilterLabel>
                            <TipsButtonGroup isActive={raceType === 'T'} onClick={() => handleSelectedRaceType('T')}>
                                <RaceTypeIcon src={require('../../assets/img/Thoroughbred.png')} alt="T" />
                            </TipsButtonGroup>
                            <TipsButtonGroup isActive={raceType === 'G'} onClick={() => handleSelectedRaceType('G')}>
                                <RaceTypeIcon src={require('../../assets/img/Greyhounds.png')} alt="G" />
                            </TipsButtonGroup>
                            <TipsButtonGroup isActive={raceType === 'H'} onClick={() => handleSelectedRaceType('H')}>
                                <RaceTypeIcon src={require('../../assets/img/Harness.png')} alt="R" />
                            </TipsButtonGroup>
                        </TipsCardHeaderRight>
                    </TipsCardHeader>
                    <TipsCardBody>{renderExpertTips}</TipsCardBody>
                </TipsCard>
            </div>
        </StyledPageComponentContainer>
    );
};
const mapStateToProps = (state, ownProps) => {
    return {
        tips: getExpertRaces(state),
    };
};
const mapDispatchToProps = (dispatch) => ({
    getAllExpertTips: async (limit, selectDate, raceType) =>
        await dispatch(getAllExpertTipsByQuery(limit, selectDate, raceType)),
});

export default withNamespaces()(createErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(RSTips)));
