import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, media } from '@tbh/ui-kit';

import { LineText, Text } from '@tbh/ui-kit';
import SportBettingAdd from '../../SportBettingAdd/SportBettingAdd';

import { formatOdds } from '../../../../../common/BetPlacement';

const StyledMarketRow = styled('div')(
	(props) => css`
		label: MarketRow;

		align-items: center;
		padding: ${spacings(props).tight}px;
	`,
	media(
		css`
			display: flex;
		`,
		470,
	),
);

const StyledMarketRow__BettingAddItem = styled(LineText)(
	(props) => css`
		label: MarketRow__BettingAddItem;

		flex: 1 1 auto;
		max-width: 40px;
		width: 40px;
		padding: ${spacings(props).tight}px;
	`,
);

const cssMarketRow__BettingAddContainer = css`
	label: MarketRow__BettingAddContainer;

	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const cssMarketRow__SelectionContainer = css`
	label: MarketRow__SelectionContainer;

	flex: 1 1 auto;
	display: flex;
	align-items: center;
	justify-content: space-around;
`;

const StyledMarketRow__Selection = styled('div')(
	(props) => css`
		label: MarketRow__Selection;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: 2 1 100%;
		align-self: flex-end;

		${props.empty &&
			css`
				flex-grow: 1;
			`};

		${props.alignTop &&
			css`
				align-self: flex-start;
			`};
	`,
);

const LINE_POSITION_LEFT = 'left';
const LINE_POSITION_RIGHT = 'right';
const LINE_POSITION_INSIDE = 'inside';

const MarketRow = ({
	className,
	bettingAvailable,
	marketName,
	selections,
	alignTop,
	addToMulti,
	addToSingle,
	productId,
	size,
}) => {
	const componentClasses = cx({
		[className]: className,
	});

	return (
		<StyledMarketRow className={componentClasses} size={size}>
			<Text
				align="center"
				size="-1"
				className={css`
					flex: 0 0 100px;
				`}
			>
				{marketName}
			</Text>
			<div className={cssMarketRow__SelectionContainer}>
				{selections.map((selection) => {
					if (selection.display === false) {
						return (
							<StyledMarketRow__Selection key={selection.id} empty>
								&nbsp;
							</StyledMarketRow__Selection>
						);
					}

					return (
						<StyledMarketRow__Selection key={selection.id} alignTop={alignTop}>
							<Text align="center" size="-2">
								{selection.name} {!selection.linePosition && <LineText line={selection.line} />}
							</Text>
							<div className={cssMarketRow__BettingAddContainer}>
								{selection.linePosition === LINE_POSITION_LEFT ? (
									<StyledMarketRow__BettingAddItem alignRight line={selection.line} />
								) : (
									false
								)}
								<SportBettingAdd
									key={selection.id}
									size="2"
									metric={formatOdds(selection.price)}
									productId={productId}
									selectionId={selection.id}
									hasMulti={!!addToMulti}
									addToSingle={addToSingle}
									addToMulti={addToMulti}
									bettingAvailable={bettingAvailable}
								/>
								{selection.linePosition === LINE_POSITION_RIGHT ? (
									<StyledMarketRow__BettingAddItem line={selection.line} />
								) : (
									false
								)}
							</div>
						</StyledMarketRow__Selection>
					);
				})}
			</div>
		</StyledMarketRow>
	);
};

MarketRow.propTypes = {
	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** The name of the market */
	marketName: PropTypes.string.isRequired,

	/** Action to add the selection to the bet prompt */
	addToSingle: PropTypes.func.isRequired,

	/** The productId available for the event */
	productId: PropTypes.number,

	/** The list of selections in the market */
	selections: PropTypes.arrayOf(
		PropTypes.shape({
			/** The selection ID */
			id: PropTypes.any.isRequired,

			/** The selection price */
			price: PropTypes.number,

			/** The selection line */
			line: PropTypes.any,

			/** The selection name */
			name: PropTypes.string,

			/** The line position for the selection */
			linePosition: PropTypes.oneOf([LINE_POSITION_LEFT, LINE_POSITION_INSIDE, LINE_POSITION_RIGHT]),
		}),
	),

	/** Action to add the selection to the multi bet slip */
	addToMulti: PropTypes.func,

	/** Whether the selections should align to the top of the row or not */
	alignTop: PropTypes.bool,
	bettingAvailable: PropTypes.bool,

	/** Extra classes */
	className: PropTypes.string,
};

MarketRow.defaultProps = {
	selections: [],
	productId: null,
	addToMulti: null,
	alignTop: false,
	bettingAvailable: true,
	className: '',
};

export default MarketRow;
