import { post } from '../../common/Ajax';

export const RNSAddToBackBook = async (postData) => {
	const params = {
		username: postData.userName,
		id: postData.id,
		comment: postData.comment, // Keep it null untill finishing modal content//
		type: postData.type,
		discipline: postData.discipline,
		horse: postData.horseName,
		racecountry: postData.racecountry,
		comphorse: postData.horseName,
		horseid: postData.horseid,
		horsecountry: postData.horsecountry,
	};

	try {
		const response = await post('BlackBook/add-new', params);
		
		return {
			success: response.data.data,
			message: response.data.data,
		};
	} catch (e) {
		document.Sentry && document.Sentry.captureException(e);
		// console.log(e.message);
		//  check if e.response exists before accessing data
		const errorMessage = (e.response && e.response.data && e.response.data.errors) 
			? e.response.data.errors 
			: (e.message || 'An error occurred');
		return {
			success: false,
			message: errorMessage,
		};
	}
};
global.RNSAddToBackBook = RNSAddToBackBook;
