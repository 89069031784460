import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { spacings, Image, Text, HeaderBar, Panel, PlotElements } from '@tbh/ui-kit';
import { BET_RESULT_STATUSES } from '../../../../common/constants/Bets';
import BetItem from './BetItem/BetItem';
import AclContext from '../../../../contexts/AclContext';

const StyledBetListItem__ImageWrapper = styled('div')(
	(props) => css`
		label: BetListItem__ImageWrapper;

		padding-right: ${spacings(props).compact}px;
	`,
);

const StyledBetListItem__Info = styled('div')`
	label: BetListItem__Info;

	flex: auto;
	width: 100%; // IE
`;

const StyledBetListItem__BetItem = styled(BetItem)(
	(props) => css`
		label: BetListItem__BetItem;

		&:not(:first-of-type) {
			margin-top: ${spacings(props).cozy}px;
		}
	`,
);

const BetListItem = (props) => {
	const {
		t,
		betId,
		betStatus,
		betPaid,
		betType,
		className,
		bets,
		imageSrc,
		imageName,
		imageSrcSubstitute,
		showStatus,
		sportBet,
		title,
		currencyType,
		handlecacheOut,
		betItem,
		cashout_limit,
		dailyBailOutLimit,
		bailOutIsEnabled,
		freeCreditAmount,
	} = props;
	const componentClasses = cx({
		[className]: className,
	});
	return (
		<Panel className={componentClasses} borderType="default" background>
			{sportBet && (
				<HeaderBar type={HeaderBar.types.SECONDARY} horizontalPadding={HeaderBar.spacing.SPACING_COMPACT}>
					<PlotElements align="space-between">
						<Text>
							<Text size="-1" type="alternate">
								<PlotElements align="start">
									{currencyType === 'bonusCurrency' ? (
										<AclContext.Consumer>
											{(acl) =>
												acl.currencyConfiguration && acl.currencyConfiguration.bonusCurrencyName ? (
													<span>{acl.currencyConfiguration.bonusCurrencyName}</span>
												) : (
													<span>{t('Bonus')}</span>
												)
											}
										</AclContext.Consumer>
									) : bets.length === 1 ? (
										t('Single')
									) : (
										t('Multi')
									)}
									&nbsp;
									{title.priceAndOdds}
								</PlotElements>
							</Text>
							<Text size="-3" type="alternate" uppercase>
								{title.winningsMessage}
							</Text>
						</Text>
						<Text size="-5" type="alternate">
							{t('ID')}: {betId}
						</Text>
					</PlotElements>
				</HeaderBar>
			)}

			<Panel padding={Panel.padding.SPACING_COMPACT} flex>
				{imageSrc && (
					<StyledBetListItem__ImageWrapper>
						<Image
							className={css`
								max-height: 35px;
							`}
							src={imageSrc}
							alt={imageName}
							substituteImageSrc={imageSrcSubstitute}
						/>
					</StyledBetListItem__ImageWrapper>
				)}

				<StyledBetListItem__Info>
					{bets.length === 1 && sportBet ? (
						<StyledBetListItem__BetItem
							key={bets[0].id || betId}
							amountAndOdds={bets[0].betTypeDescription}
							betId={bets.length === 1 ? betId : null}
							betType={betType}
							date={bets[0].date}
							details={bets[0].details}
							paid={bets[0].paid || betPaid}
							showStatus={showStatus}
							status={bets[0].status || betStatus}
							title={bets[0].title}
							multiLegSelections={bets[0].multiLegSelections}
							sportBet={sportBet}
							raceStatus={bets[0].raceStatus}
							handlecacheOut={handlecacheOut}
							betItem={betItem}
							cashout_limit={cashout_limit}
							dailyBailOutLimit={dailyBailOutLimit}
							bailOutIsEnabled={bailOutIsEnabled}
							freeCreditAmount={freeCreditAmount}
						/>
					) : (
						bets.map((bet, i) => [
							<StyledBetListItem__BetItem
								key={bet.id || betId}
								amountAndOdds={
									sportBet ? (
										<PlotElements align="start">
											{bet.betTypeDescription}
											{bet.amountAndOdds}
										</PlotElements>
									) : (
										bet.amountAndOdds
									)
								}
								betId={bets.length === 1 ? betId : null}
								betType={betType}
								betTypeDescription={sportBet && bets.length > 1 ? null : bet.betTypeDescription}
								date={bet.date}
								derivativeTitle={bet.derivativeTitle}
								derivativeSubTitle={bet.derivativeSubTitle}
								details={bet.details}
								paid={bet.paid || betPaid}
								showStatus={showStatus}
								multiLegSelections={bet.multiLegSelections}
								status={bet.status || betStatus}
								title={bet.title}
								sportBet={sportBet}
								raceStatus={bets[0].raceStatus}
								handlecacheOut={handlecacheOut}
								betItem={betItem}
								cashout_limit={cashout_limit}
								dailyBailOutLimit={dailyBailOutLimit}
								bailOutIsEnabled={bailOutIsEnabled}
								freeCreditAmount={freeCreditAmount}
							/>,
						])
					)}
				</StyledBetListItem__Info>
			</Panel>
		</Panel>
	);
};

BetListItem.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The ID of the bet placed */
	betId: PropTypes.number.isRequired,

	/** Bet status as defined in our constants */
	betStatus: PropTypes.oneOf(BET_RESULT_STATUSES),

	/** Bets */
	bets: PropTypes.arrayOf(
		PropTypes.shape({
			/** E.g.: '$1.00 @ 2.10 (TBF)' */
			amountAndOdds: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,

			/** E.g.: 'DERIVATIVE (TO WIN $6.30)' */
			betTypeDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

			/** Selection id */
			id: PropTypes.number,

			/** How much the bet paid out */
			paid: PropTypes.number,

			/** Bet status as defined in our constants */
			status: PropTypes.oneOf(BET_RESULT_STATUSES),

			/** Bet's title. E.g.: '2. OUR YANGTZE' */
			title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

			/** Derivative bet's title. E.g.: 'EVENS' */
			derivativeTitle: PropTypes.string,

			/** Derivative bet's sub title. E.g.: 'To Beat 1, 3, 5 & 7' */
			derivativeSubTitle: PropTypes.string,

			/** Event/Race date */
			date: PropTypes.string,

			/** Bet selection details */
			details: PropTypes.shape({
				competitionName: PropTypes.string,
				eventName: PropTypes.string,
				sportName: PropTypes.string,
			}),
		}),
	).isRequired,

	/** Bet's currency type */
	currencyType: PropTypes.oneOf(['bonusCurrency', 'currency']),

	/** How much the bet paid out */
	betPaid: PropTypes.number,

	/** The type of bet that was placed ie. 'Win', 'Place', 'Trifecta' etc. */
	betType: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,

	/** Image (silk) */
	imageSrc: PropTypes.string,

	/** Image alt attribute */
	imageName: PropTypes.string,

	/** Default substitute for the image */
	imageSrcSubstitute: PropTypes.string,

	/** Should the bet status be shown */
	showStatus: PropTypes.bool,

	/** If it's a sport bet or not */
	sportBet: PropTypes.bool,

	/** Bet's title. E.g.: '2. OUR YANGTZE' */
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

	dailyBailOutLimit: PropTypes.number,
};

BetListItem.defaultProps = {
	className: '',
	currencyType: 'currency',
	imageSrc: null,
	imageName: null,
	imageSrcSubstitute: null,
	betPaid: 0,
	betStatus: null,
	betType: null,
	showStatus: true,
	sportBet: false,
	title: null,
	dailyBailOutLimit: 0,
};

export default withNamespaces()(BetListItem);
