import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';

import {
	Input,
	BANK_ACCOUNT_BSB_CONSTRAINTS,
	BANK_ACCOUNT_NAME_CONSTRAINTS,
	BANK_ACCOUNT_NUMBER_CONSTRAINTS,
	translateConstraints,
	MASK_BB_BANK_ACCOUNT_BSB,
} from '@tbh/ui-kit';

import { fetchBsbDetails } from '../../../../../store/entities/actions/BankAccountActions';

const BankAccountForm = (props) => {
	const {
		t,
		account_name,
		account_number,
		bsb_number,
		className,
		onChange,		
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	translateConstraints(t, [
		BANK_ACCOUNT_BSB_CONSTRAINTS,
		BANK_ACCOUNT_NAME_CONSTRAINTS,
		BANK_ACCOUNT_NUMBER_CONSTRAINTS,
	]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [bankDetails, setBankDetails] = useState(null);

	useEffect(() => {
		const fetchDetails = async () => {
			if (bsb_number.length === 7) {
				setLoading(true);
				setError(null);
				try {
					const data = await fetchBsbDetails(bsb_number);
					console.log('BSB Details:', data); // Debugging log
					if (data.length > 0) {
						setBankDetails(data[0]);
					} else {
						setError(t('No BSB details found.'));
					}
				} catch (error) {
					setError(t('Error fetching BSB details.'));
					console.error('Error fetching BSB details:', error);
				} finally {
					setLoading(false);
				}
			} else {
				setBankDetails(null);
				setError(null);
			}
		};

		fetchDetails();
	}, [bsb_number, t]);

	const getBankDetailsValue = () => {
		if (loading) {
			return t('Loading...');
		}
		if (error) {
			return error;
		}
		if (bankDetails) {
			return `${bankDetails.bank} / ${bankDetails.suburb} / ${bankDetails.state}`;
		}
		return ''; // Default empty value if no data
	};

	return (
		<div className={componentClasses}>
			<Input
				name="bsb_number"
				onChange={onChange}
				label={t('BankAccountForm__BankBSB')}
				placeholder={t('BankAccountForm__BankBSBFormat')}
				value={bsb_number}
				mask={MASK_BB_BANK_ACCOUNT_BSB}
				constraints={BANK_ACCOUNT_BSB_CONSTRAINTS}
				margin="cozy"
				type="text"
			/>
			
			<Input
				name="bank_details"
				label={t('BankAccountForm__BankBranch')}
				placeholder={t('BankAccountForm__BankBranch')}
				value={getBankDetailsValue()}
				constraints={{}} // No constraints needed for read-only field
				margin="cozy"
				type="text"
				readOnly
			/>


			
			<Input
				name="account_number"
				onChange={onChange}
				label={t('BankAccountForm__AccountNumber')}
				placeholder={t('BankAccountForm__AccountNumber')}
				value={account_number}
				constraints={BANK_ACCOUNT_NUMBER_CONSTRAINTS}
				margin="cozy"
				type="number"
			/>
			<Input
				name="account_name"
				onChange={onChange}
				label={t('BankAccountForm__AccountName')}
				placeholder={t('BankAccountForm__AccountHolderName')}
				value={account_name}
				constraints={BANK_ACCOUNT_NAME_CONSTRAINTS}
				margin="cozy"
			/>
		</div>
	);
};
BankAccountForm.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onSelectBankName: PropTypes.func.isRequired,
	account_name: PropTypes.string,
	account_number: PropTypes.string,
	bank_details_id: PropTypes.string,
	bsb_number: PropTypes.string,
	/** Extra classes */
	className: PropTypes.string,
	other_bank_name: PropTypes.string,
};

BankAccountForm.defaultProps = {
	account_name: '',
	account_number: '',
	bank_details_id: '1',
	bsb_number: '',
	className: '',
	other_bank_name: '',
};

export default withNamespaces()(BankAccountForm);
