import { post } from '../../common/Ajax';

export const CacheOut = (data) => (dispatch, getState) => {
  const params = {
    origin: 0,
    event: data.bet_selections[0].event_id,
    bet_id: data.id,
    amount:data.amount
  };
  return post('cashout-request', params)
    .then((response) => {
      // dispatch(trackGaEvent('user', 'password:change'));
      return response;
    })
    .catch((error) => {
      throw error.response.data.errors;
    });
};

global.CacheOut = CacheOut;
