import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { spacings, CollapsibleContent, CollapsibleIcon, Tabs, TabsItem } from '@tbh/ui-kit';

import ExoticBetTypeFilters from '../../ExoticBetTypeFilters/ExoticBetTypeFilters';

import {
  RACING_BET_TYPE_TITLES,
  RACING_NON_EXOTIC_BET_TYPE,
  RACING_EXOTIC_BET_TYPES,
  RACING_BET_TYPES,
} from '../../../../../common/constants/Racing';

const StyledBetTypeFiltersDropdown__CollapsibleIcon = styled(CollapsibleIcon)(
	(props) => css`
		label: BetTypeFiltersDropdown__CollapsibleIcon;

		padding-left: ${spacings(props).compact}px;
	`,
);

class BetTypeFiltersDropdown extends React.Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Function to handle the selecting of a bet type */
		selectBetType: PropTypes.func.isRequired,

		/** The list of products available for the race */
		raceProductBetTypes: PropTypes.array,

		/** Which bet type is currently active or selected */
		activeBetType: PropTypes.oneOf([RACING_NON_EXOTIC_BET_TYPE, ...RACING_EXOTIC_BET_TYPES]).isRequired,

		/** Whether the bet type selections are boxed or not */
		isBoxedSelected: PropTypes.bool,

		/** Are exotics available for the current race */
		exoticsAvailable: PropTypes.bool,

		/** An array of non exotic products to display on the dropdown version. ie. win & place products */
		productsDisplayed: PropTypes.arrayOf(
			PropTypes.shape({
				productLabel: PropTypes.string.isRequired,
				betType: PropTypes.string.isRequired,
			}),
		),

		/**
		 * The product code of the non exotic product to display as active on the narrow version
		 *  NOTE: Needs to match a 'productCode' from the 'productsDisplayed' prop or be set to null if exotic is active
		 */
		activeProduct: PropTypes.string,

		/** A collection of race numbers within this bet type */
		dailyDoubleRaceNumbers: PropTypes.string,

		/** A collection of race numbers within this bet type */
		quaddieRaceNumbers: PropTypes.string,

		/** Whether or not the exotic panel should be collapsed or open */
		openExoticPanel: PropTypes.bool,

		/** Extra style class(es) to pass through */
		className: PropTypes.string,
	};

	static defaultProps = {
		raceProductBetTypes: RACING_BET_TYPES,
		isBoxedSelected: false,
		exoticsAvailable: true,
		productsDisplayed: [],
		activeProduct: null,
		openExoticPanel: false,
    openMoreMarketPanel: false,
		className: null,
		quaddieRaceNumbers: '',
		dailyDoubleRaceNumbers: '',
	};

	state = {
		exoticPanelOpen: this.props.openExoticPanel || false,
    More_marketOpen: this.props.openMoreMarketPanel || false,

	};

	/**
	 * Toggles the exotic panel
	 */
	toggleExoticPanel = () => {
		this.setState({
			exoticPanelOpen: !this.state.exoticPanelOpen,
		});
	};

	/**
	 * Handle the selecting of a bet type
	 *
	 * @param betType
	 * @param boxed
	 */
	handleSelectBetType = (betType, boxed = false) => {
		// no need to change the bet type if it is already active
		if (this.props.activeBetType === betType && boxed === this.props.isBoxedSelected) {
			return;
		}

		// return the new bet type and checkbox state
		this.props.selectBetType(betType, boxed);

		// close the toggle
		this.setState({ exoticPanelOpen: false, More_marketOpen: false });
	};

	/**
	 * Handle the selecting of a bet product
	 *
	 * @param newActiveProduct
	 */
	handleSelectProduct = (newActiveProduct) => {
		// no need to change if product is already active
		if (newActiveProduct === this.props.activeProduct && this.props.activeBetType === RACING_NON_EXOTIC_BET_TYPE) {
			return;
		}

		// return the bet type, checkbox state & active product
		this.props.selectBetType(newActiveProduct,false);

		// close the exotic panel
		this.setState({ exoticPanelOpen: false, More_marketOpen: false });
	};

	// build up the products buttons
	buildProductButtons = () => {
		let productButtonsSet = [];

		this.props.productsDisplayed.forEach((product) => {
			productButtonsSet.push(
				<TabsItem
					active={this.props.activeProduct === product.betType}
					key={product.productLabel}
					action={this.handleSelectProduct}
					data={product.betType}
					pointer
				>
					{this.props.t(product.productLabel)}
				</TabsItem>,
			);
		});

		return productButtonsSet;
	};

	render() {
		const {
			className,
			activeBetType,
			isBoxedSelected,
			exoticsAvailable,
			raceProductBetTypes,
			quaddieRaceNumbers,
			dailyDoubleRaceNumbers,
			t,
		} = this.props;

		const componentClasses = cx({
			[className]: className,
		});

		// grab the exotic titles from the racing constants
		const betTypeTitles = RACING_BET_TYPE_TITLES;

		// setup the label for the active exotics and add boxed if needed
		const activeExoticLabel = isBoxedSelected ? 'Boxed ' + betTypeTitles[activeBetType] : betTypeTitles[activeBetType];

		return (
			<div className={componentClasses}>
				<Tabs alignment="right" padding={Tabs.paddings.SPACING_TIGHT} justifyAuto wrappingBorder>
					{exoticsAvailable && (
						<TabsItem
							active={activeBetType !== RACING_NON_EXOTIC_BET_TYPE}
							key="exotic"
							pointer
							action={this.toggleExoticPanel}
						>
							{activeBetType !== RACING_NON_EXOTIC_BET_TYPE ? t(activeExoticLabel) : t('Exotics')}
							<StyledBetTypeFiltersDropdown__CollapsibleIcon size="-2" isCollapsed={!this.state.exoticPanelOpen} />
              {activeBetType !== RACING_NON_EXOTIC_BET_TYPE && activeBetType === RACING_EXOTIC_BET_TYPES ? t(activeExoticLabel) : t('More Market')}
              <StyledBetTypeFiltersDropdown__CollapsibleIcon size="-2" isCollapsed={!this.state.More_marketOpen} />
						</TabsItem>
					)}
					{this.buildProductButtons()}
				</Tabs>

				{exoticsAvailable && (
					<CollapsibleContent isCollapsed={!this.state.exoticPanelOpen}>
            <div>
              <ExoticBetTypeFilters
                className={css`
								margin-top: -1px;
							`}
                activeBetType={activeBetType !== RACING_NON_EXOTIC_BET_TYPE ? activeBetType : null}
                isBoxedSelected={isBoxedSelected}
                selectBetType={this.handleSelectBetType}
                checkboxSize="medium"
                raceProductBetTypes={raceProductBetTypes}
                quaddieRaceNumbers={quaddieRaceNumbers}
                dailyDoubleRaceNumbers={dailyDoubleRaceNumbers}
                isDropdownContext
              />
            </div>
					</CollapsibleContent>
				)}

          <CollapsibleContent isCollapsed={!this.state.More_marketOpen}>
            <div>

            </div>
          </CollapsibleContent>

			</div>
		);
	}
}

export default withNamespaces()(BetTypeFiltersDropdown);
