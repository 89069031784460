export const cleanRacingPusherPayload = (data) => {
	return Object.keys(data).reduce((acc, key) => {

		/**
		 * Shallowly exclude blank fields. The following samples are considered blank values:
		 *     null, undefined, 0, '', {}, []
 		 */
		if (data[key] === null ||
			data[key] === undefined ||
			data[key] === 0 ||
			data[key] === '' ||
			(Object.keys(data[key]).length === 0 && data[key].constructor === Object) ||
			(Array.isArray(data[key]) && data[key].length === 0)
		) {
			return acc;
		}

		acc[key] = data[key];
		return acc;
	}, {});
};