// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

// Components
import {
	application,
	brand,
	spacings,
	Button,
	ButtonGroup,
	EmailInput,
	Input,
	Link,
	PasswordInput,
	Text,
	InputGroup,
	PIPE_AUTO_CORRECTED_DATE,
	DateInput,
} from '@tbh/ui-kit';

// Constants
import {
	CONSTRAINT_OVER_18,
	CONSTRAINT_USERNAME,
	PASSWORD_8_CHAR,
	CONSTRAINT_EMAIL,
	translateConstraints,
} from '@tbh/ui-kit';
import { USER_ADDRESS_FORM } from '../StepsContants';

// Context
import AclContext from '../../../../../contexts/AclContext';
import { StyledCheckBox } from '../../../../UI/Checkbox';
import DatePicker from '../../../../ui-elements/Forms/DatePicker/DatePicker';
// import policy_data from './policy_data';
const StyledUserDetailsForm__Actions = styled(ButtonGroup)(
	(props) => css`
		label: UserDetailsForm__Actions;

		margin: ${spacings(props).cozy}px 0;
	`,
);

const StyledUserDetailsForm__AgeAlert = styled('div')(
	(props) => css`
		label: UserDetailsForm__AgeAlert;

		flex: 0 1 32%; // it needs to be 32% to not break in IE
		display: inline-flex;
		align-self: flex-start;
		align-items: center;
		padding: ${spacings(props).tight}px;
		border: 2px solid ${brand(props).color_2_dark};
		border-radius: ${application(props).border_radius_2}px;
	`,
);

const StyledUserDetailsForm__AgeAlert18 = styled(Text)(
	(props) => css`
		label: UserDetailsForm__AgeAlert18;

		background-color: ${brand(props).color_2_dark};
		padding: ${spacings(props).tight}px;
		margin-right: ${spacings(props).tight}px;
		border-radius: ${application(props).border_radius_2}px;
	`,
);

const TermsAndConditions = styled('div')(
	(props) => css`
		label: UserDetailsForm__TermsAndConditions;
		border: 1px solid ${brand(props).color_2_dark};
		height: 140px;
		overflow-y: scroll;
		margin: ${spacings(props).cozy}px 0;
		width: 100%;
		padding: 12px;
	`,
);
const Row = styled('div')(
	(props) => css`
		label: UserDetailsForm__Row;

		display: flex;
		justify-content: start;
		align-items: start;
		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const ErrorText = styled(Text)(
	(props) => css`
		color: red !important;
	`,
);

const validadePasswordAndReturnError = (password) => {
	// Minimum At least 10 characters, at least 1 number, at least 1 upper-case letter and at least 1 lower-case letter.

	if (!password) {
		return null;
	}

	if(password.length < 10){
		return 'Must be at least 10 characters';
	}

	const atLeastOneNumber = /\d/;
	const atLeastOneUpperCase = /[A-Z]/;
	const atLeastOneLowerCase = /[a-z]/;

	if (!atLeastOneNumber.test(password)) {
		return 'Must contain at least one number';
	}

	if (!atLeastOneUpperCase.test(password)) {
		return 'Must contain at least one upper-case letter';
	}

	if (!atLeastOneLowerCase.test(password)) {
		return 'Must contain at least one lower-case letter';
	}

	return null;
};

class UserDetailsForm extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Action to sign thew user in */
		signIn: PropTypes.func.isRequired,

		/** Goes to the next step of the registration */
		goToStep: PropTypes.func.isRequired,

		/** Updates state when changes are made */
		onChange: PropTypes.func.isRequired,

		/** Bulk updates to state when changes are made */
		onBulkChange: PropTypes.func.isRequired,

		/** Email can be pre-populated from the masthead, Facebook or when coming back from next step */
		email: PropTypes.string,

		/** Users' password */
		password: PropTypes.string,

		/** Users' username */
		username: PropTypes.string,

		/** Is mobile verification being used */
		mobileVerification: PropTypes.bool,

		/** The mobile number */
		mobile_number: PropTypes.string,

		/** DOB string */
		date_of_birth: PropTypes.string,

		/** Formatted date for the date picker */
		datePickerDate: PropTypes.string,

		/** Errors from the back end validation */
		email_error: PropTypes.string,
		password_error: PropTypes.string,
		username_error: PropTypes.string,
		date_of_birth_error: PropTypes.string,

		/** If the email is valid or not */
		email_valid: PropTypes.bool,

		/** If all fields are valid */
		form_is_valid: PropTypes.bool,

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		email: '',
		password: '',
		username: '',
		date_of_birth: '',
		datePickerDate: null,
		email_error: null,
		email_valid: false,
		password_error: null,
		username_error: null,

		mobileVerification: null,
		country: null,
		mobile_number: null,
		mobile_is_valid: null,
		validateMobileNumber: null,
		mobile_number_error: null,

		date_of_birth_error: null,
		form_is_valid: false,
		className: null,
	};

	constructor(props) {
		super(props);

		this.state = {
			runDOBValidation: false,

			/**
			 * Runs the email validation if there is an email.
			 * This is needed because the email validation of the validate.js package
			 * is slightly different from the back end validation
			 */
			runEmailValidation: !!props.email,
			check_policy_privacy: false,
			check_promo_bet_bonus: true,
			error: {
				check_policy_privacy: false,
				check_promo_bet_bonus: false,
			},
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.form_is_valid && this.props.email_valid && !prevProps.email_valid) {
			this.setState({
				runEmailValidation: true,
			});
		}
	}

	/**
	 * Handles changes in the inputs
	 *
	 * @param name
	 * @param value
	 * @param valid
	 * @param day
	 * @param month
	 * @param year
	 */
	handleChange = (name, value, valid, day, month, year) => {
		if (name === 'date_of_birth') {
			this.props.onBulkChange({ date_of_birth: value, dob_day: day, dob_month: month, dob_year: year });
		} else {
			this.props.onChange(name, value, valid);
		}

		this.setState({
			runDOBValidation: name === 'date_of_birth',
		});
	};

	onSelectDate = (event, date) => {
		const day = date.date() < 10 ? '0' + date.date() : date.date();
		const month = date.month() < 9 ? '0' + (date.month() + 1) : date.month() + 1;
		const year = date.year();

		this.setState(
			{
				runDOBValidation: true,
			},
			() => {
				this.props.onBulkChange({
					date_of_birth: `${day}/${month}/${year}`,
					dob_day: day.toString(),
					dob_month: month.toString(),
					dob_year: year.toString(),
				});
			},
		);
	};

	/**
	 * Goes to the next step of the registration
	 */
	nextStep = () => {
		if (!this.state.check_policy_privacy) {
			this.setState({
				error: {
					check_policy_privacy: !this.state.check_policy_privacy,
				},
			});
			return;
		}

		if (this.props.password.length < 8) {
			this.setState({
				error: {},
			});
		}

		this.props.goToStep(USER_ADDRESS_FORM);
	};

	handlePolicyPrivacy = () => {
		this.setState({
			check_policy_privacy: !this.state.check_policy_privacy,
		});
	};

	handlePromoBetBonus = () => {
		this.setState({
			check_promo_bet_bonus: !this.state.check_promo_bet_bonus,
		});
	};
	render() {
		const {
			t,
			className,
			date_of_birth,
			date_of_birth_error,
			email,
			email_error,
			password,
			password_error,
			signIn,
			username,
			username_error,
			mobileVerification,
			mobile_number,
			datePickerDate,
			form_is_valid,
			marketing_opt_in_flag,
			termsAndConditions,
		} = this.props;
		const { runDOBValidation, runEmailValidation } = this.state;

		const get_Z_value = mobile_number.slice(2, 3);
		const countryCode = mobile_number.slice(0, 2);
		translateConstraints(t, [CONSTRAINT_OVER_18, CONSTRAINT_USERNAME, PASSWORD_8_CHAR, CONSTRAINT_EMAIL]);

		const passwordError = validadePasswordAndReturnError(password) ? validadePasswordAndReturnError(password) : password_error;

		return (
			<div className={cx({ [className]: className })}>
				<Text size="-1" paragraph>
					{t('UserDetailsForm__HaveAnAccount')}{' '}
					<Link action={signIn} type={Link.types.SECONDARY} underline>
						{t('SimpleRegistration__SignIn')}
					</Link>
				</Text>

				<AclContext.Consumer>
					{(acl) =>
						acl.brandDetails && acl.brandDetails.name ? (
							<Text size="1" strong paragraph>
								{t('UserDetailsForm__StartAccount', { brandName: acl.brandDetails.name })}
							</Text>
						) : null
					}
				</AclContext.Consumer>

				<EmailInput
					name="email"
					onChange={this.handleChange}
					value={email}
					label={t('UserDetailsForm__Email')}
					error={email_error}
					disabled={true}
					runValidation={runEmailValidation}
					constraints={CONSTRAINT_EMAIL}
					valid
					margin="cozy"
				/>

				<PasswordInput
					name="password"
					onChange={this.handleChange}
					value={password}
					label={t('UserDetailsForm__Password')}
					error={passwordError}
					margin="cozy"
				/>

				{!mobileVerification && (
					<Input
						name="mobile_number"
						type="tel"
						value={
							get_Z_value === '0'
								? `${(countryCode + mobile_number.slice(3, 15)).split(' ').join('')}`
								: `+${mobile_number.split(' ').join('')}`
						}
						label={t('Profile__MobileNumber')}
						placeholderChar="#"
						margin="cozy"
						disabled={true}
						onChange={this.handleChange}
					/>
				)}
				<InputGroup>
					<DateInput
						className={css`
							flex: 1 1 0; // for IE
						`}
						name="date_of_birth"
						type="tel"
						onChange={this.handleChange}
						value={date_of_birth}
						label={t('UserDetailsForm__DateOfBirth')}
						constraints={CONSTRAINT_OVER_18}
						pipe={PIPE_AUTO_CORRECTED_DATE}
						error={date_of_birth_error}
						horizontalMargin="cozy"
						runValidation={runDOBValidation}
						placeholder="DD/MM/YYYY"
						nodeRight={
							<DatePicker
								action={this.onSelectDate}
								calendarClassName={css`
									left: -43%;
								`}
								date={datePickerDate ? datePickerDate : new Date()}
								dropdownMode="select"
								showMonthDropdown
								showYearDropdown
								yearDropdownItemNumber={15}
								justIcon
							/>
						}
					/>

					<StyledUserDetailsForm__AgeAlert>
						<StyledUserDetailsForm__AgeAlert18 type="alternate" strong>
							{t('UserDetailsForm__18Plus')}
						</StyledUserDetailsForm__AgeAlert18>
						<Text
							className={css`
								line-height: 1;
							`}
							size="-1"
							transform="uppercase"
							strong
						>
							{t('UserDetailsForm__AdultsOnly')}
						</Text>
					</StyledUserDetailsForm__AgeAlert>
				</InputGroup>

				<TermsAndConditions disabled>
					<div dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
				</TermsAndConditions>
				<Row>
					<StyledCheckBox
						name="terms"
						value="terms"
						action={this.handlePolicyPrivacy}
						checked={this.state.check_policy_privacy}
						pushRight="1"
						inline
						error={date_of_birth_error}
					/>
					<Text size="-2" style={{ cursor: 'pointer' }} action={this.handlePolicyPrivacy}>
						{t('UserDetailsForm__Terms', { age: 18 })}
					</Text>
				</Row>
				{this.state.error.check_policy_privacy && <ErrorText size="-2">Policy and Privacy is required</ErrorText>}
				<Row>
					<StyledCheckBox
						name="marketing_opt_in_flag"
						action={(name, value) =>
							this.handleChange('marketing_opt_in_flag', marketing_opt_in_flag === 0 ? 1 : 0, true)
						}
						checked={marketing_opt_in_flag}
						pushRight="1"
						inline
					/>
					<Text
						size="-2"
						style={{ cursor: 'pointer' }}
						action={() => this.handleChange('marketing_opt_in_flag', marketing_opt_in_flag === 0 ? 1 : 0, true)}
					>
						{t('UserDetailsForm__Promo')}
					</Text>
				</Row>

				<StyledUserDetailsForm__Actions center>
					<Text
						className={css`
							flex: 0 1 50%;
						`}
						size="-1"
					>
						{mobileVerification ? t('UserDetailsForm__Step1of3') : t('UserDetailsForm__Step1of3')}
					</Text>

					<Button
						action={this.nextStep}
						type="primary"
						disabled={!this.state.check_policy_privacy || !form_is_valid}
						bold
						block
						flex
					>
						{t('NextStep')}
					</Button>
				</StyledUserDetailsForm__Actions>
			</div>
		);
	}
}

export default withNamespaces()(UserDetailsForm);
