// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings } from '@tbh/ui-kit';

// Components
import { FlexText, Text, UserIcon } from '@tbh/ui-kit';
import UserVerifiedText from '../UserVerifiedText/UserVerifiedText';
import FeatureContext from '../../../../../contexts/featureContext';

const cssAccountSummaryUser = css`
	label: AccountSummaryUser;
	display: flex;
	flex-direction: row;
`;

const StyledAccountSummaryUser__IconArea = styled('div')`
	label: AccountSummaryUser__IconArea;

	${(props) => css`
		padding-right: ${spacings(props).compact}px;
	`};
`;

const AccountSummaryUser = (props) => {
	const { id, className, name, verified, t } = props;

	const componentClasses = cx(cssAccountSummaryUser, {
		[className]: className,
	});

	return (
		<div className={componentClasses}>
			<StyledAccountSummaryUser__IconArea>
				<UserIcon id={id} name={name} />
			</StyledAccountSummaryUser__IconArea>
			<div
				className={css`
					flex-grow: 1;
				`}
			>
				<FeatureContext.Consumer>
					{(featureToggles) => {
						return featureToggles.features.userVerification.enabled ? (
							<React.Fragment>
								<FlexText>
									<Text size="-1">{name}</Text>
									<Text size="-1" align="right">
										{t('Verified')}:
									</Text>
								</FlexText>
								<FlexText>
									<Text size="-1" type="dark">
										{t('Welcome')}!
									</Text>
									<Text size="-1" align="right">
										<UserVerifiedText verified={verified} tag="span" />
									</Text>
								</FlexText>
								<FlexText>
									<Text size="-1">{t('AccountNumber')}</Text>
									<Text size="-1" align="right">
										#{id}
									</Text>
								</FlexText>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Text size="-1">{name}</Text>
								<Text size="-1">{t('Welcome')}!</Text>
								<FlexText>
									<Text size="-1">{t('AccountNumber')}</Text>
									<Text size="-1" align="right">
										#{id}
									</Text>
								</FlexText>
							</React.Fragment>
						);
					}}
				</FeatureContext.Consumer>
			</div>
		</div>
	);
};

AccountSummaryUser.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The current users' ID */
	id: PropTypes.number.isRequired,

	/** The current users' full name */
	name: PropTypes.string.isRequired,

	/** Whether the current user is verified or not */
	verified: PropTypes.bool.isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

AccountSummaryUser.defaultProps = {
	className: '',
};

export default withNamespaces()(AccountSummaryUser);
