import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, ui } from '@tbh/ui-kit';

// Components
import { Icon, Text } from '@tbh/ui-kit';

// Functions
import { centsAsDollars } from '../../../../legacy/core/format';

const StyledBalance = styled('div')(
	(props) => css`
		label: Balance;

		display: flex;
		align-items: center;
		padding-bottom: ${spacings(props).cozy}px;
		border-bottom: 1px solid ${ui(props).color_4};
	`,
);

const Balance = (props) => {
	const { type, balance, className } = props;

	const componentClasses = cx({
		[className]: className
	});

	return (
		<StyledBalance className={componentClasses}>
			<Icon size="5" icon="money" push="4" />

			<Text>
				{type} Balance
				<Text size="1" type="success" strong>
					{centsAsDollars(balance)}
				</Text>
			</Text>
		</StyledBalance>
	);
};

Balance.propTypes = {
	/** Type of receipt: Available Cash, Bonus Bet... */
	type: PropTypes.string.isRequired,

	/** User's balance */
	balance: PropTypes.number.isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

Balance.defaultProps = {
	className: null,
};

export default Balance;
