import React from 'react';
import { Icon, spacings, Text, ui } from '@tbh/ui-kit';
import styled, { css } from 'react-emotion';
import { RACING_TYPES_LOOKUP } from '../../../../common/constants/Racing';
import BetAmountAndPayout from '../BetAmountAndPayout/BetAmountAndPayout';
import { centsAsDollars } from '../../../../legacy/core/format';
import BetNotificationEventClosed from '../BetNotification/BetNotificationEventClosed';
// import { StyleBetCupomPreview } from '../BetSelection/BetSelectionMulti/BetSelectionMulti';

const StyledMultiSRMItem = styled('div')(
	(props) => css`
		label: MultiSRMItem;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		border: 2px solid ${ui(props).color_4};
		border-radius: 4px;
		${props.selectedBet && 'border: 1px solid #660809!important;'}
	`,
);

const SRMHeader = styled.div`
	${(props) => `
        label: SRMHeader;

        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 40px;
        padding-left: ${spacings(props).compact}px;
        border-bottom: 1px solid #dddddd;
    `}
`;

const SRMHeaderTitle = styled.div`
	label: SRMHeaderTitle;
	display: flex;
`;

const SRMBody = styled.div`
	${(props) => css`
		label: SRMBody;

		display: flex;
		flex-direction: column;
	`}
`;

const SRMSelections = styled.div`
	display: flex;
	flex-direction: column;
	padding: 8px;
	gap: 2px;
`;

export const StyledBetSelectionMulti__Clear = styled('div')(
	(props) => css`
		label: BetSelectionMulti__Clear;

		margin-left: ${spacings(props).compact}px;
		text-align: right;
		cursor: pointer;
	`,
);

export const StyledBetSelectionMulti__ClearIcon = styled(Icon)(
	(props) => css`
		label: BetSelectionMulti__ClearIcon;

		text-align: center;
		color: ${ui(props).color_danger};
		width: 20px;
		height: 20px;
		line-height: 16px;
	`,
);

const StyleSRMSelection = styled.div`
	${(props) => css`
		label: StyleSRMSelection;
		display: flex;
		flex-direction: row;
	`}
`;

export const SRMItem = ({
	id,
	srm,
	className,
	racingBetType,
	selectionName,
	betTypeLabel,
	eventName,
	price,
	placePrice,
	cash_out_enabled,
	handleBetTypeChange,
	bet_code,
	singleOnly,
	enableRemoval = true,
	multiBetId,
	onClickSelection,
	onMultiToggle,
	onRemove,

	product_id,
	bet,
	stake,
	winPrice,
	user_bet_amount,
	onStakeChange,
	handleSelectedCupomBet,

	bonus_bet_enabled,
	handleBonusBetChange,
	selectedBet,
	handleSelectedBet,
	isClosed,
}) => {
	const handleRemoveSRM = () => {
		onRemove(srm);
	};
	const handleInputChange = (name, value) => {
		onStakeChange(id, value, racingBetType, product_id);
	};

	const onBetClick = (s) => {
		let data = null;

		if (selectedBet && selectedBet.id == s.id && selectedBet.group == 'SRMs') return;

		if (!selectedBet || (selectedBet.id != s.id && selectedBet.group != 'SRMs')) {
			data = {
				...s,
				amount: stake,
				group: 'SRMs',
			};
		}

		handleSelectedBet(data);
	};
	return (
		<StyledMultiSRMItem
			onClick={() => onBetClick(srm)}
			selectedBet={selectedBet && selectedBet.id == id && selectedBet.group == 'SRMs'}
		>
			{isClosed && <BetNotificationEventClosed onClick={handleRemoveSRM} />}
			<div style={{ opacity: isClosed ? 0.3 : 1 }}>
				<SRMHeader>
					<SRMHeaderTitle>
						<Icon color={'black'} icon={RACING_TYPES_LOOKUP[srm.race.type]} size="-1" push="1" />
						<Text size="-4">
							<strong>
								#{srm.race.number} {srm.race.meeting_name}
							</strong>
						</Text>
					</SRMHeaderTitle>
					{/* {stake ? (
					<StyleBetCupomPreview onClick={() => handleSelectedCupomBet(id, 'sameracemulti')}>Preview</StyleBetCupomPreview>
				) : null} */}
					{enableRemoval && (
						<StyledBetSelectionMulti__Clear>
							<StyledBetSelectionMulti__ClearIcon size="-3" icon="close" action={handleRemoveSRM} />
						</StyledBetSelectionMulti__Clear>
					)}
				</SRMHeader>

				<SRMBody>
					<SRMSelections>
						{srm.selections.map((selection, i) => (
							<StyleSRMSelection key={selection.id}>
								<Text size="-3" style={{ width: '42px', fontWeight: '700' }}>
									{selection.type_name}:
								</Text>
								
								<Text size="-3" style={{ marginLeft: '5px' }}>
									{selection.selection_number}. {selection.name}
								</Text>
							</StyleSRMSelection>
						))}
					</SRMSelections>

					<BetAmountAndPayout
						className={css`
							padding: 8px;
						`}
						// pulse={this.state.pulse}
						//stake={stake ? stake : bet}
						free_credit_flag={srm.free_credit_flag}
						bonus_bet_enabled={bonus_bet_enabled}
						handleBonusBetChange={handleBonusBetChange}
						onInputChange={handleInputChange}
						stake={stake ? stake : user_bet_amount}
						winPrice={winPrice}
						price={price}
						placePrice={placePrice}
						racingBetType={racingBetType}
						placeHolder={centsAsDollars(bet, true)}
						betTypeLabel={betTypeLabel}
						displayFlexed
						cash_out_enabled={cash_out_enabled}
						onBetTypeChange={handleBetTypeChange}
						isLeg={false}
						hasTote={false}
						bet_code={bet_code}
						eachway={false}
					/>
				</SRMBody>
			</div>
		</StyledMultiSRMItem>
	);
};
