import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import {countdown, countdownLessThan2Min} from '../../../../legacy/core/format';
import UpCarouselItem from '../../Carousel/UpCarouselItem/UpCarouselItem'; // @HW newly added

// eslint-disable-next-line react/prop-types
const UpRacesCarouselItem = ({
	t, id, raceNumber, meetingName, startTime, type, action, className , raceName, meetingState , selection ,
	selectionCount,raceDistance,country, start_datetime , size}) => {

	return (
		<UpCarouselItem
			border
			id={id}
			label={`${t('Race')} ${raceNumber}`}
			text={meetingName}
			raceText={raceName} // @HW 15 Oct 2019
			meetingStateText={meetingState} // @HW 15 Oct 2019
			meta={`${countdown(startTime)}`}
			lessThan2Min={countdownLessThan2Min(startTime)}
			icon={type}
			action={action}
			className={className}
			topSelection={selection}
			selectionLength={selectionCount}
			distance={raceDistance}
			country={country}
			start_datetime={start_datetime}
			raceNumber={raceNumber}
			size={size}
		/>
	);
};

UpRacesCarouselItem.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}),
	/** The race ID */
	id: PropTypes.number.isRequired,

	/** The race number */
	raceNumber: PropTypes.number.isRequired,

	/** The name of the meeting that the race is part of */
	meetingName: PropTypes.string.isRequired,

	/** The start time of the race */
	startTime: PropTypes.string.isRequired,

	/** The race type */
	type: PropTypes.string.isRequired,

	/** Action to perform when the race item is clicked on */
	action: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,

	raceName: PropTypes.string.isRequired,

	meetingState: PropTypes.string.isRequired,

	raceDistance: PropTypes.string,

	country: PropTypes.string,

  	start_datetime: PropTypes.string,

	selection: PropTypes.arrayOf(
		PropTypes.shape({
			prices: PropTypes.arrayOf(
				PropTypes.shape({
					/** The ID of the product */
					product_id: PropTypes.number.isRequired,
					/** The code of the product */
					product_code: PropTypes.string.isRequired,
					/** The type of bet */
					bet_type: PropTypes.string,
					/** The odds/price for the bet */
					price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
					/** If a multi bet is available for this product */
					hasMulti: PropTypes.bool,
					/** If the product is available to be bet on */
					productAvailable: PropTypes.bool,
				}),
			).isRequired,
			//prices: PropTypes.array.isRequired,
		})
	).isRequired,

	selectionCount: PropTypes.number,

	meetingStateText: PropTypes.string,

	raceText: PropTypes.string,

};

UpRacesCarouselItem.defaultProps = {
	className: '',
	selection: [],
	selectionCount: 0,
	meetingStateText: '',
	raceText: '',
	size: null,
	raceDistance: 0,
	country:  null,
	start_datetime: null,
};
export default withNamespaces()(UpRacesCarouselItem);
