/**
 * Returns the environment variable.
 *
 * @param value
 * @param defaultValue
 * @returns {*}
 */
const env = function(value, defaultValue = undefined) {
	const state = global.App && global.App.store ? global.App.store.getState() : {};

	const config = {
		...(window.__bm_env || {}),
		...(state.acl || {}),
		...(window.__bm_envOverrides || {}),
	};

	if (!config.hasOwnProperty(value)) {
		return defaultValue;
	}

	return config[value];
};

export default env;
