import styled, { css } from 'react-emotion';
import { application, brand, spacings, typography, media, FlexText, HeaderBar } from '@tbh/ui-kit';

import { Image, List, Text } from '@tbh/ui-kit';
import { FONT_SIZE_12, FONT_SIZE_10 } from '../../../../store/application/applicationConstants';

export const StyledbetFeatureRaceSelectionList = styled('div')`
	lable: FeatureRaceSelectionList;
	&:after {
		content: '';
		display: table;
		clear: both;
	}
`;

export const StyledbetFeatureRaceSelectionList__RaceSelectionsListItems = styled(List)`
	${(props) =>
		css`
			label: FeatureRaceSelectionList__RaceSelectionsListItems;

			margin-bottom: ${spacings(props).cozy}px;
		`};
`;

export const StyledRaceSelectionsList__HeaderFlucs = styled('div')`
	${(props) =>
		css`
			label: RaceSelectionsList__HeaderFlucs;

			display: block;
			text-align: center;
			text-transform: uppercase;
			flex: 0 0 ${application(props).racing.flucs_width}px;
			max-width: ${application(props).racing.flucs_width}px;
			font-size: ${FONT_SIZE_10};
		`};

	${media(
			css`
			display: block;
			font-size: 10px;
			font-weight: 700;
			margin-top: 10px;
			text-align: right;
		`,
			660,
		)};
`;

export const modelPanel = css`
	${(props) =>
		css`
			label: modelPanel;

			width: 700px !important;
			background-color: #fff;
			margin-top: 50%;
			padding: 40px;
			margin-left: -170px;
		`};

	${media(
			(props) => css`
			width: ${application(props).size.mobile}px;
			background-color: #fff;
			margin-top: 50%;

			margin-left: -50px;
		`,
			660,
		)};
`;

export const StyledRaceSelectionsList__HeaderCell = styled('div')(
	(props) =>
		css`
			label: RaceSelectionsList__HeaderCell;

			flex: 0 0 ${application(props).racing.odds_button_width}px;
			max-width: ${application(props).racing.odds_button_width}px;
			margin: 0 ${spacings(props).constrictive}px;
			padding: ${spacings(props).tight}px;
			text-align: center;
			justify-content: flex-end;

			${props.emphasised &&
			css`
					color: ${typography(props).color_1_alternate};
					background-color: ${brand(props).color_1};
					text-shadow: ${application(props).shadow};
					margin: 0 1px;
				`};

			${props.small &&
			css`
					flex: 0 0 ${application(props).racing.odds_button_width_small}px;
					//max-width: ${application(props).racing.odds_button_width_small}px;
				`};
		`,

	media(
		(props) => css`
			${props.small &&
			css`
					flex: 0 0 ${application(props).racing.odds_button_width}px;
					max-width: ${application(props).racing.odds_button_width}px;
				`};
		`,
		660,
	),
);

export const StyledRaceSelectionsList__ProductIcon = styled(Image)`
	${(props) =>
		css`
			label: RaceSelectionsList__ProductIcon;

			max-width: ${application(props).racing.odds_button_width}px;
			max-height: 22px;
			padding: 0 ${spacings(props).constrictive}px;
			margin-bottom: ${spacings(props).constrictive}px;
		`};
`;

export const StyledFeatureRaceSelectionList_header__Wrapper = styled('div')(
	(props) =>
		css`
			label: FeatureRaceSelectionList_header__Wrapper;

			// Set a min-height so the trends don't overlap
			min-height: ${spacings(props).roomy}px;
			display: flex;
			align-items: center;
			//padding: ${spacings(props).tight}px;

			display: flex;
			justify-content: space-between;
		`,
);

export const StyledRaceSelections_SilkImage = styled(Image)`
	height: 37px;

	${media(
	css`
			height: 37px;
		`,
	660,
)};
`;

export const StyledFeatureRaceSelections__Name = styled(Text)`
	${(props) =>
		css`
			label: FeatureRaceSelections__Name;

			color: #fff;
			font-size: ${FONT_SIZE_12};
		`};

	${media(
			(props) =>
				css`
				font-size: ${FONT_SIZE_12};
			`,
			660,
			{ sizeKey: 'mediaSize' },
		)};
`;

export const StyledChart_header = styled('div')`
	${(props) =>
		css`
			label: Chart_header;


			display: flex;

			//padding: ${spacings(props).tight}px ${spacings(props).tight}px;

			width: auto;
			flex-flow: row wrap;
			justify-content: center;
			align-items: center;
        `};
	${media(
			css`
			padding-top: 0;
		`,
			660,
		)};
`;

export const StyledFeaRaceSelectionsDetails = styled('div')`
	${(props) =>
		css`
			label: FeaRaceSelectionsDetails;

			width: 100%;
			display: flex;
			padding: ${spacings(props).tight}px 0;
			height: 65px;
		`};
`;
export const StyledChart_header_silk = styled('div')`
	${(props) =>
		css`
			label: Chart_header_silk;

			flex: 0 0 7%;
		`};
	${media(
			css`
			padding-top: 0;
		`,
			660,
		)};
`;
export const StyledChart_header_details = styled('div')`
	${(props) =>
		css`
			label: Chart_header_details;

			display: contents;

			padding: ${spacings(props).tight}px ${spacings(props).tight}px;
		`};
	${media(
			css`
			padding-top: 0;
		`,
			660,
		)};
`;

export const StyledflucsModle__Header = styled('div')`
	${(props) =>
		css`
			label: flucsModle__Header;
			position: relative;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			height: inherit;
			width: 100%;
			-webkit-align-items: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			overflow: hidden;
			text-transform: none;
			background: #070b19;
			padding: 0 15px;
			padding-left: 0;
			padding-right: 0;
			min-height: 45px;
			background: #070b19;
			margin-bottom: 10px;
			margin-top: 10px;
		`};
`;

export const StyledFlusRaceHeaderBar = styled(HeaderBar)`
	${(props) => css`
		min-height: 45px; // To Match the height of a regular header bar
		background: ${brand(props).rch_5};
	`};
`;

export const StyledFlucsRaceHeaderBar__RaceNumber = styled('div')`
	${(props) => css`
		label: FlucsRaceHeaderBar__RaceNumber;

		background-color: ${brand(props).rch_4};
		display: inline-block;

		padding: ${spacings(props).compact}px;
		font-size: 30px;
		font-weight: 600;

		text-align: center;
	`};
`;

export const FlucsRaceHeaderBarModle = styled(FlexText)`
	${(props) => css`
		label: FlucsRaceHeaderBarModle__Heading;

		//padding-top: ${spacings(props).tight}px;
		//padding-bottom: ${spacings(props).tight}px;
	`};
`;

export const StyledRaceSelectionsDetails__Silk = styled('div')`
	${(props) =>
		css`
			label: RaceSelectionsDetails__Silk;

			float: left;
			width: 28px;
			min-width: 28px;
			max-height: 37px;
			margin-right: ${spacings(props).constrictive}px;
			text-align: center;
			align-self: center;

			${props.scratched &&
			css`
					opacity: 0.2;
					filter: grayscale(100%);
				`};
		`};

	${media(
				(props) =>
					css`
				margin-right: ${spacings(props).tight}px;
			`,
				500,
			)};

	${media(
				css`
			width: 45px;
			max-height: 55px;
		`,
				660,
			)};
`;
export const StyledRaceSelectionsDetails__SilkImage = styled(Image)`
	height: 26px;

	${media(
	css`
			height: 37px;
		`,
	660,
)};
`;
export const StyledFeRaceSelectionsDetails__LastStartsRow = styled(Text)`
	${(props) =>
		css`
			label: feRaceSelectionsDetails__LastStartsRow;

			${props.scratched &&
			css`
					opacity: 0.2;
					filter: grayscale(100%);
				`};
		`};

	${media(
				(props) =>
					css`
				font-size: ${FONT_SIZE_12};
			`,
				660,
				{ sizeKey: 'mediaSize' },
			)};
`;
export const StyledFeRaceSelectionsDetails__DetailsColumn = styled('div')`
	${(props) =>
		css`
			label: FeRaceSelectionsDetails__DetailsColumn;

			padding-top: ${spacings(props).tight}px;
			text-overflow: ellipsis;
			overflow: hidden;
			${props.scratched &&
			css`
					opacity: 0.2;
					filter: grayscale(100%);
				`};
		`};

	${media(
				css`
			padding-top: 0;
		`,
				660,
			)};
`;

export const StyledRaceSelectionsDetails__Name = styled(Text)`
	${(props) =>
		css`
			label: FeatureRaceSelectionsDetails__Name;
			margin-right: ${spacings(props).tight}px;
			color: #003a91;
			&:after {
				content: ' ';
			}
		`};

	${media(
			(props) =>
				css`
				${typography(props)['size_-2']}px;
			`,
			660,
			{ sizeKey: 'mediaSize' },
		)};
`;

export const StyledRaceSelectionsDetails__RunnerDetails = styled(Text)`
	${media(
	(props) =>
		css`
				font-size: ${FONT_SIZE_10};
			`,
	660,
	{ sizeKey: 'mediaSize' },
)};
`;
