import {
	denormalize,
	normalize,
} from 'normalizr';

import {
	featureRacesSchema
} from './Entities';

/**
 * Denormalizes entities.
 *
 * @param entities
 * @param keys
 * @returns {Array}
 */
const denormalizeFeatureRaces = (entities, keys = null) => {
	return denormalize(keys || Object.keys(entities.featureRaces), featureRacesSchema, entities);
};

/**
 * Normalizes entities.
 *
 * @param response
 * @returns {Object}
 */
const normalizeFeatureRaces = (response) => {
	return normalize(response, featureRacesSchema);
};

export {
	denormalizeFeatureRaces,
	normalizeFeatureRaces,
};