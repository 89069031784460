// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';

// Components
import { Text, Tabs, TabsItem, ArrowIcon } from '@tbh/ui-kit';
import Svg from '../../../ui-components/Svg/Svg';

// Constants
import { RACING_BET_TYPE_MARGIN } from '../../../../common/constants/Racing';
import { GOAT_PRODUCT_TYPE_BOOST } from '../../../../common/constants/GoatProducts';

const cssTabsItem = css`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const StyledTabsItem = styled(TabsItem)`
	${(props) => css`
		@keyframes BumpStripeAnimation {
			// 0% {
			// 	background: repeating-linear-gradient(135deg, white, white 3px, #bb181b 3px, #bb181b 7px);
			// }
			// 35% {
			// 	background: repeating-linear-gradient(135deg, white, white 4px, #bb181b 4px, #bb181b 8px);
			// }
			// 50% {
			// 	background: repeating-linear-gradient(135deg, white, white 3px, #bb181b 3px, #bb181b 7px);
			// }
			// 75% {
			// 	background: repeating-linear-gradient(135deg, white, white 4px, #bb181b 4px, #bb181b 8px);
			// }
			// 100% {
			// 	background: repeating-linear-gradient(135deg, white, white 3px, #bb181b 3px, #bb181b 7px);
			// }
			100% {
				background-position: 100% 100%;
			  }
		}

		${props.active &&
			css`

			animation: BumpStripeAnimation 10s linear infinite;
			background: repeating-linear-gradient(
				-45deg, 
				white, 
				white 3px, 
				#bb181b 2px, 
				#bb181b 6px
			   );

			   background-size: 200% 200%;
			color: #000000;
		`}
	`}
`;

const GoatButtBumpTabs = (props) => {
	const {
		className,
		disabled,
		onSelectGoatProduct,
		priceBumps,
		marginButts,
		selectedGoatProduct,
		showPriceBump,
		showMarginButt,
		selectedBetType,
		size,
	} = props;

	const cssGoatButtBumpTabs =
		size.width >= 500
			? css`
					flex: 0 1 auto;
			  `
			: '';

	const componentClasses = cx(cssGoatButtBumpTabs, {
		[className]: className,
	});

	const bumpActive = showPriceBump && selectedGoatProduct === GOAT_PRODUCT_TYPE_BOOST;
	const buttActive = showMarginButt && selectedGoatProduct === RACING_BET_TYPE_MARGIN;

	return (
		<Tabs
			className={componentClasses}
			emphasised
			padding={Tabs.paddings.SPACING_TIGHT}
			wrappingBorder
			alignment="left"
			justifyAuto
		>
			{showPriceBump && selectedBetType === 'winplace' && (
				<StyledTabsItem
					action={onSelectGoatProduct}
					active={bumpActive}
					data={GOAT_PRODUCT_TYPE_BOOST}
					disabled={disabled}
					badge={priceBumps && priceBumps}
					pointer
					logo={<ArrowIcon color={'white'} name="goat-bump" direction="up" height="24" width="24" />}
					className={cssTabsItem}
				>
					<Text
						size="-2"
						align="center"
						type={bumpActive ? 'alternate' : 'default'}
						transform="uppercase"
						strong
						whiteSpace="nowrap"
					>
						ROLL{' '}
						<Text tag="span" size="-2" type={bumpActive ? 'alternate' : 'default'} transform="uppercase" strong>
							UP
						</Text>
					</Text>
				</StyledTabsItem>
			)}
			{showMarginButt && (
				<TabsItem
					action={onSelectGoatProduct}
					active={buttActive}
					data={RACING_BET_TYPE_MARGIN}
					disabled={disabled}
					badge={marginButts && marginButts}
					pointer
					logo={<Svg name="goat-butt" height="24" width="24" />}
					className={cssTabsItem}
				>
					<Text
						size="-2"
						align="center"
						type={buttActive ? 'alternate' : 'default'}
						transform="uppercase"
						strong
						whiteSpace="nowrap"
					>
						MARGIN{' '}
						<Text tag="span" size="-2" type={buttActive ? 'alternate' : 'default'} transform="uppercase" strong italic>
							BUTT
						</Text>
					</Text>
				</TabsItem>
			)}
		</Tabs>
	);
};

GoatButtBumpTabs.propTypes = {
	/** Action when selecting a tab */
	onSelectGoatProduct: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** Extra classes */
	className: PropTypes.string,

	/** Whether to disable all of the GOAT tabs or not */
	disabled: PropTypes.bool,

	/** User's remaining GOAT Margin Butt */
	marginButts: PropTypes.number,

	/** User's remaining GOAT Price Bump */
	priceBumps: PropTypes.number,

	/** Current selected goat product */
	selectedGoatProduct: PropTypes.oneOf([GOAT_PRODUCT_TYPE_BOOST, RACING_BET_TYPE_MARGIN]),

	/** If the Price Bump tab should be rendered */
	showPriceBump: PropTypes.bool,

	/** If the Margin Butt tab should be rendered */
	showMarginButt: PropTypes.bool,
};

GoatButtBumpTabs.defaultProps = {
	className: '',
	disabled: false,
	marginButts: null,
	priceBumps: null,
	selectedGoatProduct: null,
	showPriceBump: false,
	showMarginButt: false,
};

export default GoatButtBumpTabs;
