import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import { PUSHER_GRS_UPCOMING_EVENT_ODDS_UPDATE } from '../../../../common/constants/Racing';
import { buildPriceUpdates, updatePrices } from '../../../../store/entities/actions/PriceActions';
import PusherSubscribe from '../../../controllers/Pusher/PusherSubscribe';

import { mergeSelections } from '../../../../store/entities/actions/UpcomingAllRaceActions';
import {
	getFeatureRaces,
	getUpcomingRaces,
} from '../../../../pages/FeatureRacing/FeatureRacingHome/featureRacingSelectors';
import { mergeUpcomingRace } from './../../../../store/entities/actions/UpcomingAllRaceActions';
import {
	PUSHER_GRS_EVENT_UPDATE,
	PUSHER_GRS_UPCOMING_EVENT_ODDS_UPDATE,
	PUSHER_N2J_UPCOMING_EVENT_ODDS_UPDATE,
} from '../../../../common/constants/Racing';
import { getNJRace } from '../../../../store/entities/actions/NextToJumpRaceActions';
import { mergeRace } from './../../../../store/entities/actions/RaceActions';
import { mergeNJRace } from './../../../../store/entities/actions/NextToJumpRaceActions';
import { getNextToJumpRaces } from '../../../../pages/Racing/RacingHome/racingSelectorsGRS';
import { mergeFeatureRace } from '../../../../store/entities/actions/FeatureRaceActions';

/**
 * Pusher Subscriber Container for Race.
 * Channel: n2j_race_<raceId>
 */

class PusherSubscriberNjUpcomingRaceChannel extends Component {
	static propTypes = {
		/** The race ID to update */
		raceId: PropTypes.number.isRequired,

		/** Action to update the selected race */
		//updateRace: PropTypes.func,

		/** Action to update the prices in the race */
		updatePrices: PropTypes.func,

		/** Action to update the prices for a derivative selection */
		updateDerivativeSelection: PropTypes.func,
	};

	static defaultProps = {
		//updateRace: () => {},
		updatePrices: () => {},
		updateDerivativeSelection: () => {},
	};

	/**
	 * Handles pushed data to update race
	 * @param data
	 */
	handlePusherRaceUpdate = (data) => {
		this.props.updateRace(data);
	};

	/**
	 * Handles pushed data to update odds.
	 * @param data
	 */
	handlePusherOddsUpdate = (data) => {
		/**
		 * Separate Price entities to be updated,
		 * otherwise Race can lose selections and selections can lose price.
		 */

		const prices = buildPriceUpdates(data.favorite_selection);
		this.props.updatePrices(prices);
		/// Merge the new favorite selection in the old state of UpComingRaces
		this.props.mergeUpComingRaceSelections(this.props.races, data);
	};

	handlePusherStatusUpdate = (data) => {

		// Get the race selected
		const selectedUpComingRace = this.props.races.find((race) => race.id === data.data.id);

		if (selectedUpComingRace) {
			selectedUpComingRace.status = data.data.race_original_status;
			this.props.mergeUpcomingRace(selectedUpComingRace);
		}

		const selectedNjRace = this.props.ujRaces.find((race) => race.id === data.data.id);

		if (selectedNjRace) {
			selectedNjRace.event_status_id = data.data.event_status_id;
			this.props.updateNJ(selectedNjRace);
		}
		const selectedFrRace = this.props.frRaces.find((race) => race.id === data.data.id);

		if (selectedFrRace) {
			selectedFrRace.status = data.data.race_original_status;
			this.props.updateFR(selectedFrRace);
		}
		// const updateRace = this.props.getRaceById(this.props.entities, data.data.id);
		// const status = RaceStatusEventById(data.data.event_status_id);

		// updateRace.status = data.data.race_original_status;
		// updateRace.race_status_display = status;
		// this.props.updateRace(updateRace);
	};

	render() {
		const { raceId, isWelcomePage=false } = this.props;

		// Determine channel for all subscriptions
		const channel = `n2j_race_${raceId}`;

		return (
			<Fragment>
				<PusherSubscribe
					key={PUSHER_GRS_EVENT_UPDATE}
					channel={channel}
					event={PUSHER_GRS_EVENT_UPDATE}
					onUpdate={this.handlePusherRaceUpdate}
				/>
				{isWelcomePage && (
					<PusherSubscribe
						key={PUSHER_GRS_UPCOMING_EVENT_ODDS_UPDATE}
						channel={channel}
						event={PUSHER_GRS_UPCOMING_EVENT_ODDS_UPDATE}
						onUpdate={this.handlePusherOddsUpdate}
					/>
				)}
				<PusherSubscribe
					key={PUSHER_N2J_UPCOMING_EVENT_ODDS_UPDATE}
					channel={channel}
					event={PUSHER_N2J_UPCOMING_EVENT_ODDS_UPDATE}
					onUpdate={this.handlePusherStatusUpdate}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	races: getUpcomingRaces(state),
	ujRaces: getNextToJumpRaces(state),
	frRaces: getFeatureRaces(state),
	entities: state.entities,
});

const mapDispatchToProps = (dispatch) => ({
	//updateRace: (race) => dispatch(mergeUpcomingRace(race)),
	getRaceById: (entities, raceId) => dispatch(getNJRace(entities, raceId)),
	updateFR: (race) => dispatch(mergeFeatureRace(race)),
	updateNJ: (race) => dispatch(mergeNJRace(race)),
	updateRace: (race) => dispatch(mergeRace(race)),
	updatePrices: (prices) => dispatch(updatePrices(prices)),
	mergeUpComingRaceSelections: (races, race) => {
		const r = races.find((r) => r.id == race.id);

		if (!r || !r.favorite_selection) {
			return;
		}

		let favorite_selection = {
			...r.favorite_selection,
			...race.favorite_selection,
		};

		race = { ...r, favorite_selection };

		dispatch(mergeSelections(race.favorite_selection));
		dispatch(mergeUpcomingRace(r));
	},
	mergeUpcomingRace: (race) => dispatch(mergeUpcomingRace(race)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PusherSubscriberNjUpcomingRaceChannel);
