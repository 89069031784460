import { createAction } from '../../common/actions/actionHelpers';


export const CLOSE_BET_EXPORT = 'CLOSE_BET_EXPORT';

export const closeBetExport = () => {
	return createAction(CLOSE_BET_EXPORT, null);
};

export const OPEN_BET_EXPORT = 'OPEN_BET_EXPORT';

export const openBetExport = (bet = {}) => {
    return createAction(OPEN_BET_EXPORT, bet);
};