import styled, { css } from 'react-emotion';
import { media, spacings, brand, typography, Text } from '@tbh/ui-kit';
import FeatureRaceCardContainer from '../../../../containers/Racing/FeatureRaceCardContainer/FeatureRaceCardContainer';
//import { FONT_SIZE_12, FONT_SIZE_18, COLORS } from '../../../../store/application/applicationConstants';
import { DEFAULT_COLORS, TYPE_SCALE, MEDIA_QUERIES } from '../../../../constants/themes';

export const StyledFeaturesMain = styled('div')`
	label: FeaturesMain;
	background: white;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	border-top-left-radius: 8px;
    border-top-right-radius: 8px;
	border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`;
export const StyledfeatureRaces_list_content = styled('div')`
	${(props) =>
		css`
			label: featureRaces_list_content;

			display: flex;
			box-sizing: border-box;
			flex-direction: column;
			flex: 1 0 0;

			margin: 12px ${spacings(props).compact}px 12px ${spacings(props).compact}px;
			color: ${typography(props).color_2};
			text-aligin: center;
			background: ${typography(props).color_1_alternate};
		`};

	${media(
			(props) =>
				css`
				padding: ${spacings(props).compact}px;
				margin-top: -10px;
			`,
			500,
		)};
`;
export const StyledRacingFeatures = styled('div')`
	${(props) =>
		css`
			label: RacingFeatures;
			
			box-sizing: border-box;
			-webkit-tap-highlight-color: transparent;
			display: flex;
			flex-direction:row;
			// @media (min-width: 1024px) {
			// 	flex-direction: column;
			// 	height:250px
			// };
			flex-wrap: wrap;
			width: 100%;
			
			margin: ${spacings(props).compact}px;
			// background-color: ${brand(props).color_2_dark};
			color: ${typography(props).color_1_alternate};
		`};

	${media(
			(props) =>
				css`
				display: flex;
				flex-wrap: wrap;
				padding: 6px ${spacings(props).compact}px 6px 6px;
				width: 100%;
			`,
			400,
		)};
`;

export const StyledLargeDiviceRacingFeatures = styled('div')`
	${(props) =>
		css`
			label: LargeDiviceRacingFeatures;

			box-sizing: border-box;
			-webkit-tap-highlight-color: transparent;
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			margin: ${spacings(props).compact}px;
			background-color: ${brand(props).color_2_dark};
			color: ${typography(props).color_1_alternate};
		`};

	${media(
			(props) =>
				css`
                display: flex;
                flex-wrap: wrap;
				//padding: 12px ${spacings(props).compact}px 0px 0px;
				width: 100%;
			`,
			400,
		)};
`;

export const StyledRacingFeaturesMainSection = styled('div')`
	${(props) =>
		css`
			label: RacingFeaturesMainSection;

			display: flex;
			flex-wrap: wrap;
			width: 100%;
			padding: ${spacings(props).compact}px;
			border-left: 5px solid #ffffff;
		`};

	${(media(
			(props) =>
				css`
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				padding: ${spacings(props).compact}px;
			`,
			424,
		),
		media(
			(props) =>
				css`
				display: flex;
				width: 100%;
				padding: ${spacings(props).compact}px;
			`,
			1024,
		),
		media(
			(props) =>
				css`
				display: flex;
				width: 50%;
				padding: ${spacings(props).compact}px;
			`,
			1440,
		))};
`;

export const StyledLargeScreenRacingFeaturesHeaderSection = styled('div')`
	${(props) =>
		css`
			label: LargeScreenRacingFeaturesHeaderSection;

			-webkit-box-flex: 0;
			-ms-flex: 0 0 30%;
			flex: 0 0 30%;
			padding: ${spacings(props).compact}px;
			border-radius: 4px;
			background-color: #ECE9E9;

			@media only screen and (max-width: 1024px) {
				-ms-flex: 0 0 100%;
				flex: 0 0 100%;
			}
		`};
`;

export const StyledLargeScreenRacingFeaturesTopSelectionBodySection = styled('div')`
	${(props) =>
		css`
			label: LargeScreenRacingFeaturesTopSelectionBodySection;

			-webkit-box-flex: 0;
			-ms-flex: 0 0 70%;
			flex: 0 0 70%;
			padding: ${spacings(props).compact}px;
			border: 1px solid #ECE9E9;
			border-radius: 4px;
			@media only screen and (max-width: 1024px) {
				-ms-flex: 0 0 100%;
				flex: 0 0 100%;
			}
		`};
`;

export const StyledRacingFeaturesHeaderSection = styled('div')`
	${(props) =>
		css`
			label: RacingFeatures_header_section;
			// background-color: #070B19;
			border-radius: 4px 4px 0px 0px;
			// background: linear-gradient(-45deg,rgb(102 42 42),rgb(26,27,35));
			// background-color: rgb(54 54 55);
			// background-size: 400% 400%;
			// animation: gradient 1s ease infinite;

			background-color: ${brand(props).color_2_dark};
			-webkit-box-flex: 0;
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
			padding: 16px ${spacings(props).compact}px;

			@keyframes gradient {
				0% {
					background-position: 0% 50%;
				}
				50% {
					background-position: 100% 50%;
				}
				100% {
					background-position: 0% 50%;
				}
			}
		`};

	${(media(
			(props) =>
				css`
				-webkit-box-flex: 0;
				-ms-flex: 0 0 33.333333%;
				flex: 0 0 33.333333%;
				max-width: 33.333333%;
				padding: ${spacings(props).compact}px;
			`,
			400,
		),
		media(
			(props) =>
				css`
				-webkit-box-flex: 0;
				-ms-flex: 0 0 100%;
				flex: 0 0 100%;
				max-width: 100%;
				padding: ${spacings(props).compact}px;
			`,
			424,
		),
		media(
			(props) =>
				css`
				-webkit-box-flex: 0;
				-ms-flex: 0 0 33.333333%;
				flex: 0 0 33.333333%;
				max-width: 33.333333%;
				padding: ${spacings(props).compact}px;
			`,
			700,
		),
		media(
			(props) =>
				css`
				-webkit-box-flex: 0;
				-ms-flex: 0 0 50%;
				flex: 0 0 50%;
				max-width: 50%;
				padding: ${spacings(props).compact}px;
			`,
			1024,
		))};
`;

export const StyledRacingFeaturesTopSelectionBodySection = styled('div')`
	${(props) =>
		css`
			label: Racing_features_topSelection_bodySection;
			// box-shadow: 1px 0px 7px 1px rgb(0 0 0 / 20%);
			border: 1px solid rgba(0, 0, 0, 0.5);

			// @media (min-width: 1024px) {
			// 	border-bottom:1px solid rgba(0,0,0,0.5);
			// 	border-radius: 0px 4px 4px 0px;
			// };
			border-radius: 0px 0px 4px 4px;
			-webkit-box-flex: 0;
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
			padding: ${spacings(props).compact}px;
		`};

	${(media(
			(props) =>
				css`
				-webkit-box-flex: 0;
				-ms-flex: 0 0 66.666667%;
				flex: 0 0 66.666667%;
				max-width: 66.666667%;
				padding: ${spacings(props).compact}px;
			`,
			400,
		),
		media(
			(props) =>
				css`
				-webkit-box-flex: 0;
				-ms-flex: 0 0 100%;
				flex: 0 0 100%;
				max-width: 100%;
				padding: ${spacings(props).compact}px;
			`,
			424,
		),
		media(
			(props) =>
				css`
				-webkit-box-flex: 0;
				-ms-flex: 0 0 66.666667%;
				flex: 0 0 66.666667%;
				max-width: 66.666667%;
				padding: ${spacings(props).compact}px;
			`,
			700,
		),
		media(
			(props) =>
				css`
				-webkit-box-flex: 0;
				-ms-flex: 0 0 50%;
				flex: 0 0 50%;
				max-width: 50%;
				padding: ${spacings(props).compact}px;
				margin-top: -8px;
			`,
			1024,
		))};
`;

export const StyledRacingFeatureHeader = styled('div')`
	${(props) =>
		css`
			label: RacingFeatureHeader;

			display: flex;
			box-sizing: border-box;
			justify-content: center;
			margin: ${spacings(props).compact}px;
			color: ${typography(props).color_1_alternate};
			text-aligin: center;
			height: 100%;
		`};

	${media(
			(props) =>
				css`
				padding: ${spacings(props).compact}px;
			`,
			500,
		)};
`;

export const StyledRacingFeature__MainLabel = styled(Text)(
	(props) => css`
		label: RacingFeature__Label;

        display: block;
        padding: ${spacings(props).compact}px;
        margin-bottom: ${spacings(props).compact}px;
        color: ${typography(props).color_1_alternate};
        text-align: center;
        text-transform: uppercase;
        font-size: ${TYPE_SCALE.header6};
        //line-height: ${typography(props)['size_-2']};
        line-height: 0px;
        font-weight: 800;
        color: ${brand(props).rch_4};
	`,
);

export const StyledRacingFeature__TopHeaderMeetingLabel = styled(Text)(
	(props) => css`
		label: RacingFeature__TopHeaderMeetingLabel;

        display: block;
        padding: 0;
       // margin-bottom: ${spacings(props).compact}px;
        text-align: center;
        text-transform: uppercase;
        font-size: ${TYPE_SCALE.helpText};
        line-height: ${typography(props)['size_-2']};
        font-weight: 600;
        color: ${brand(props).rch_4};

		@media ${MEDIA_QUERIES.tabletAndUp} {
			padding: ${spacings(props).compact}px;
		}
	`,
);

export const StyledLargeScreenRacingFeature__Heading_More = styled('div')`
	${(props) => css`
		label: LargeScreenRacingFeature__Heading_More;

		display: inline-block;

		flex: 0 0 100%;
		width: 100%;
	`};
`;

export const StyledRacingFeature__Heading_More = styled('div')`
	${(props) => css`
		label: RacingFeature__Heading_More;

		display: inline-block;

		flex: 0 0 90%;
	`};
`;

export const StyledRacingFeature_topSelection_content = styled('div')`
	${(props) =>
		css`
            label: RacingFeature_topSelection_content;

            display: flex;
            box-sizing: border-box;
            flex-direction: column;
            flex: 1 0 0;

           // margin: ${spacings(props).compact}px;
            color: ${typography(props).color_2};
            text-aligin: center;
            background: ${typography(props).color_1_alternate};
        `};

	${media(
			(props) =>
				css`
				padding: 0px ${spacings(props).compact}px ${spacings(props).compact}px ${spacings(props).compact}px;
			`,
			500,
		)};
`;

export const StyledMajor_header_h2 = styled('h2')`
	${(props) =>
		css`
			label: StyledWelcomeLandingHomePage_Promotion_maijor_header;

			text-transform: uppercase;
			font-weight: bold;
			// background: #c72128;
			// border-color: #c72128;
			color: white;
			text-transform: uppercase;
			display: inline-block;
			margin: 0;
			padding: 7px;
			height: auto;
			position: relative;
			font-size: 15px;
			letter-spacing: 0.32px;
			text-align: center;
			margin-top: 25px;
			line-height: 15px;
		`};
`;

export const StyledMajor_Mainheader_h2 = styled('h2')`
	${(props) =>
		css`
			label: Major_Mainheader_h2;
			border-radius: 4px 4px 0px 0px;
			text-transform: uppercase;
			font-weight: 600;
			// background: #c72128;
			// border-color: #c72128;
			color: white;
			display: inline-block;
			margin: 0;
			height: auto;
			position: relative;
			font-size: 1.25rem;
			letter-spacing: 0.32px;
			text-align: center;
			margin-top: 10px;
			font-family: ${typography(props).base_font} !important;
		`};
`;

export const StyledRacingFeature_topSelectionBody = styled('div')`
	${(props) =>
		css`
			label: RacingFeature_topSelectionBody;

			display: flex;
			flex-direction: column;
			flex: 1 0 0;

			color: ${typography(props).color_2};
			text-aligin: center;
			background: ${typography(props).color_1_alternate};
		`};

	${media(
			(props) =>
				css`
               // padding: ${spacings(props).compact}px;
            `,
			500,
		)};
`;

export const StyledRacingFeature_topSelectionItemDetails = styled('div')`
	${(props) =>
		css`
			label: RacingFeature_topSelectionItemDetails;

			display: flex;
			box-sizing: border-box;
			width: 100%;
			height: auto;
			// border-bottom: 3px solid ${brand(props).rch_4};
		`};

	${media(
			(props) =>
				css`
				padding: ${spacings(props).compact}px;
				height: auto;
			`,
			500,
		)};
`;

export const StyledRacingFeature_list_header_racedeails = styled('div')`
	${(props) => css`
		label: RacingFeature_list_header_racedeails;

		display: inline-block;
		width: 100%;
		height: auto;
		padding: 16px;
		background: ${DEFAULT_COLORS.df_tertiary_color};
		border-radius: 8px 8px 0 0 !important;
		// flex: 0 0 85%;
	`};
`;

export const StyledRacingFeature_list_header_moredeails = styled('div')`
	${(props) => css`
		label: RacingFeature_list_header_moredeails;

		display: flex;
		align-items: center;
		justify-content: end;
		flex: 0 0 15%;
		padding-right: 12px;
	`};
`;

export const StyledFeatureRaceCardContainer = styled(FeatureRaceCardContainer)`
	${(props) =>
		css`
			label: FeatureRaceCardContainer;

            display: flex;
			flex-direction:colunm;
            width: 100%;
            // margin: ${spacings(props).compact}px;
		`};

	${media(
			(props) =>
				css`
                display: flex;
                width: 100%;
                // margin: ${spacings(props).compact}px;
			`,
			300,
		)};
`;
