import { denormalizeBets } from '../schemas/BetSchema';
import { delimitArray } from '../../../common/ArrayHelpers';
import moment from 'moment';
import { createSelector } from 'reselect';

import { ACTIVE, NUM_SELECTIONS_FOR_EXOTIC, PRODUCT_TYPE_BOOST } from '../constants/BetConstants';
import {
	RACING_BET_TYPE_EACHWAY,
	DERIVATIVE_FAV_VS_FIELD_CODE,
	DERIVATIVE_LUCKY_ROUGHIES_CODE,
	DERIVATIVE_INSIDE_VS_OUTSIDE_CODE,
	DERIVATIVE_HALF_VS_HALF_CODE,
	RACING_EACHWAY_LABEL,
	RACING_BUMPED_LABEL,
} from '../../../common/constants/Racing';

export const getFilteredBets = (bets, betSelections = {}, filter) => {
	bets = denormalizeBets({ bets, betSelections });

	if (filter === ACTIVE) {
		return bets.filter((bet) => bet.status === 'unresulted');
	}
	return bets;
};

/**
 * Get bets with given ids and denormalize
 *
 * @param entities
 * @param ids
 */
export const getBetsWithIds = (entities, ids = []) => {
	let bets = {};
	ids.forEach((id) => {
		let entity = entities.bets[id];
		if (entity) {
			bets[id] = entity;
		}
	});

	let newEntities = {
		...entities,
		...{ bets },
	};

	return denormalizeBets(newEntities);
};

/**
 * Filter bets that are unresulted
 *
 * @param bets
 */
export const filterUnresultedBets = (bets) => {
	return bets.filter((bet) => {
		return bet.status === 'unresulted';
	});
};

/**
 * Filter bets by type
 *
 * @param bets
 * @param types
 */
export const filterBetsByType = (bets, types = []) => {
	return bets.filter((bet) => {
		if (types.indexOf(bet.bet_type) > -1) {
			/*
			If index is found return true.
			 */
			return true;
		} else if (bet.bet_type === 'multi') {
			/*
			If 'multi', iterate through bet selections. If length > 0 return true.
			 */
			return bet.bet_selections.filter((betSelection) => types.indexOf(betSelection.bet_type) > -1).length;
		} else {
			return false;
		}
	});
};

/**
 * Return whether the exotic selection is a regular selection or not (standout)
 *
 * @param betPrompt
 * @returns {boolean}
 */
export const isExoticSelectionRegular = (betPrompt) => {
	const { exoticDetails, selections } = betPrompt;

	// Detect if the bet is regular
	return selections.length === NUM_SELECTIONS_FOR_EXOTIC[exoticDetails.id];
};

/**
 * Return the exotic selection type based if it is boxed, or a regular exotic selection
 *
 * @param betPrompt
 * @returns {string}
 */
export const getExoticSelectionType = (betPrompt) => {
	const { exoticDetails } = betPrompt;

	const isRegular = isExoticSelectionRegular(betPrompt);
	// Detect if the bet is regular
	return exoticDetails.isBoxed ? 'boxed' : isRegular ? 'standard' : 'standout';
};

/**
 * When the selections are regular or not boxed they can be return as an array of selections
 * NOTE: The data is reshaped for a specific purpose
 * TODO: Work out of the receiving component needs to reshape or not
 *
 * @param selections
 * @returns {Array}
 */
export const getSingleExoticSelections = (selections) => {
	return selections
		.sort((a, b) => a.position - b.position)
		.map((selection) => {
			return {
				id: selection.id,
				label: selection.position,
				runnerNumber: selection.number,
			};
		});
};

/**
 * When the selections are of the type standout or boxed they must be returned as a flattened list, by position
 *
 * @param selections
 * @returns {Array}
 */
export const getStandoutExoticSelections = (selections) => {
	let standoutSelections = [];
	let selectionsByPosition = {};

	// Loop through each selection and group them by position
	selections.forEach((selection) => {
		if (!selectionsByPosition[selection.position]) {
			selectionsByPosition[selection.position] = [];
		}

		selectionsByPosition[selection.position].push(selection.number);
	});

	// Loop through our positions, ensure they are in correct order, and shape the selection data
	Object.keys(selectionsByPosition)
		.sort((a, b) => a - b)
		.map((key) => {
			// Sort the selection array for the position
			const selectionArray = selectionsByPosition[key].sort((a, b) => a - b);

			standoutSelections.push({
				label: key,

				// comma separated string of runner numbers
				selection: selectionArray.join(', '),
			});
		});

	return standoutSelections;
};

/**
 * This builds up the exotic selections item shape based on what is in betPrompt
 *
 * @param betPrompt
 * @returns {Array}
 */
export const getExoticSelectionsFromBetPrompt = (betPrompt) => {
	const { exoticDetails, selections } = betPrompt;

	// const isRegular = isExoticSelectionRegular(betPrompt);

	// if (isRegular && !exoticDetails.isBoxed) {
	// 	// sort the selections by final position and change the shape
	// 	return getSingleExoticSelections(selections);
	// }

	return getStandoutExoticSelections(selections);
};

/**
 * Get the label for a racing selection
 *
 * @param racingBetType
 * @param selection
 * @param t
 * @returns {*}
 */
export const getRacingBetTypeLabel = (racingBetType, selection, t) => {
	let betTypeLabel;
	if (racingBetType === RACING_BET_TYPE_EACHWAY) {
		let codeSet = new Set();
		if (selection.win && selection.win.code) {
			if (selection.win.type === PRODUCT_TYPE_BOOST) {
				codeSet.add(t(RACING_BUMPED_LABEL));
			} else {
				codeSet.add(selection.win.code);
			}
		}
		if (selection.place && selection.place.code) {
			codeSet.add(selection.place.code);
		}

		const codeList = [...codeSet];
		const codes = codeList.length ? ` (${codeList.join('/')})` : '';

		betTypeLabel = `${t(RACING_EACHWAY_LABEL)}`;
	} else {
		if (selection[racingBetType] && selection[racingBetType].code) {
			if (selection[racingBetType].type === PRODUCT_TYPE_BOOST) {
				betTypeLabel = t(RACING_BUMPED_LABEL);
			} else {
				betTypeLabel = `${t(racingBetType.toUpperCase())} (${selection[racingBetType].code})`;
			}
		} else {
			betTypeLabel = racingBetType.toUpperCase();
		}
	}

	return betTypeLabel;
};

/**
 * Filter out the current selection from the list of derivative selections
 *
 * @param derivativeSelections
 * @param selectionId
 */
export const filterDerivativeSelections = (derivativeSelections = [], selectionId = null) => {
	return derivativeSelections.filter((sel) => sel.id !== selectionId);
};

/**
 * Format the display string for a list of selections
 *
 * @param selections
 * @param numberOnly
 */
export const formatSelectionsWithBaseSelections = (selections = [], numberOnly = false) => {
	return selections.map((sel) => {
		return sel.number ? (numberOnly ? sel.number : `${sel.number}. ${sel.name}`) : sel.name;
	});
};

/**
 * Determine the correct title to display for the derivative market bet selection
 *
 * @param marketTypeCode
 * @param selection
 * @param nameOnly
 * @param t
 * @returns {string}
 */
export const getBetSelectionDerivativeTitle = (marketTypeCode, selection = {}, nameOnly = false, t) => {
	const selectionName = selection.derivative_selection_name || selection.name;

	let derivativeTitle = '';

	switch (marketTypeCode) {
		// Inside vs Outside
		case DERIVATIVE_INSIDE_VS_OUTSIDE_CODE: {
			if (selection.base_selections.length) {
				const sortedBaseSelections = selection.base_selections.sort((a, b) => a.barrier - b.barrier);
				derivativeTitle = `${selectionName} (${t('Box')} ${sortedBaseSelections[0].barrier} ${t('to')} ${
					sortedBaseSelections[sortedBaseSelections.length - 1].barrier
				})`;
			} else {
				derivativeTitle = selectionName;
			}
			break;
		}

		// Half vs Half
		case DERIVATIVE_HALF_VS_HALF_CODE: {
			derivativeTitle = `${t('Runner_plural')} ${delimitArray(selectionName.split(','))} ${t('towin')}`;
			break;
		}

		default: {
			if (nameOnly) {
				derivativeTitle = selectionName;
			} else {
				const baseSelection = selection.base_selections.length === 1 ? selection.base_selections[0] : {};
				derivativeTitle = baseSelection.number ? `${baseSelection.number}. ${selectionName}` : selectionName;
			}
			break;
		}
	}

	return derivativeTitle;
};

/**
 * Determine the correct label to display for the derivative market bet selection
 *
 * @param derivativeMarket
 * @param selection
 * @param t
 * @returns {string}
 */
export const getBetSelectionDerivativeLabel = (derivativeMarket = {}, selection = {}, t) => {
	// Filter out the current selection
	const marketsToBeat = filterDerivativeSelections(derivativeMarket.derivative_selections, selection.id);
	const selectionsToBeat = marketsToBeat.reduce((acc, market) => {
		return [...acc, ...market.base_selections];
	}, []);

	return buildDerivativeLabel(derivativeMarket.market_type_code, selection.base_selections, selectionsToBeat, t);
};

/**
 * Determine the correct label to display for the derivative market
 *
 * @returns {string}
 * @param derivativeMarketCode
 * @param baseSelections
 * @param selectionsToBeat
 * @param t
 */
export const buildDerivativeLabel = (derivativeMarketCode, baseSelections = [], selectionsToBeat = [], t) => {
	let derivativeMarketTypeLabel = '';

	switch (derivativeMarketCode) {
		case DERIVATIVE_HALF_VS_HALF_CODE:
		case DERIVATIVE_LUCKY_ROUGHIES_CODE:
		case DERIVATIVE_INSIDE_VS_OUTSIDE_CODE: {
			break;
		}

		// Favourite vs Field
		case DERIVATIVE_FAV_VS_FIELD_CODE: {
			// Field selected
			if (baseSelections.length > 1) {
				derivativeMarketTypeLabel = `${t('Without')} ${formatSelectionsWithBaseSelections(
					selectionsToBeat,
					selectionsToBeat.length > 3,
				)}`;
			} else {
				// Favourite selected
				derivativeMarketTypeLabel = t('To Beat the Field');
			}
			break;
		}

		default: {
			derivativeMarketTypeLabel = `${t('To Beat')} ${delimitArray(
				formatSelectionsWithBaseSelections(selectionsToBeat, selectionsToBeat.length > 3),
			)}`;
			break;
		}
	}

	return derivativeMarketTypeLabel;
};

/**
 * Get all bets and split them into active and recent bets
 *
 * @param entities
 * @returns {{activeBets: Array, recentBets: Array}}
 */
export const getActiveBets = (entities) => {
	let denormalizedBets = denormalizeBets(entities);

	let bets = [].concat(denormalizedBets).sort(sortBetsByDate);

	let activeBetsArray = [];
	let recentBetsArray = [];

	const notResulted = ['unresulted', 'pending', 'processing', 'partially-refunded'];
	// Remove the resulted bets from the bets list
	for (let i = 0; i < bets.length; i++) {
		let bet = bets[i];
		if (bet.hasOwnProperty('status')) {
			if (notResulted.indexOf(bet.status) === -1) {
				recentBetsArray = [...recentBetsArray, ...[bet]];
			} else {
				activeBetsArray = [...activeBetsArray, ...[bet]];
			}
		}
	}
	return {
		activeBets: activeBetsArray,
		recentBets: recentBetsArray,
	};
};

/**
 * Get all bets and split them into active and recent bets
 *
 * @param entities
 * @returns {{activeBets: Array, recentBets: Array}}
 */
export const getBets = (entities) => {
	let denormalizedBets = denormalizeBets(entities);

	let bets = [].concat(denormalizedBets).sort(sortBetsByDate);

	return bets;
};

/**
 * Memoized selector to get sorted active bets
 * @params state
 */
export const getSortedActiveBets = createSelector(
	(state) => getActiveBets(state.entities),
	(betsList) => {
		const { activeBets, recentBets } = betsList;
		return { activeBets: activeBets.sort(sortBetsById), recentBets: recentBets.sort(sortBetsById) };
	},
);

export const getPendingBets = createSelector(
	(state) => getActiveBets(state.entities),
	(betsList) => {
		const { activeBets, recentBets } = betsList;

		const bets = [];

		if (activeBets.length > 0) {
			for (const bet of activeBets) {
				if (bet.status === 'pending' || bet.status == 'processing') {
					bets.push(bet);
				}
			}
		}
		bets.sort(sortBetsById);
		return bets;
	},
);
/**
 * Sort bets by selection date - closest to now first
 */
export const sortBetsByDate = (betA, betB) => {
	/**
	 * Get smallest time difference from now of both bets,
	 * considering pending legs only when unresulted.
	 */
	const aN2J = getClosestTimeDiffFromNow(betA);
	const bN2J = getClosestTimeDiffFromNow(betB);

	// If same time to jump, latest bet first
	return aN2J - bN2J || betB.id - betA.id;
};

export const sortBetsById = (betA, betB) => {
	return betB.id - betA.id;
};

export const getClosestTimeDiffFromNow = (bet) => {
	const usePendingOnly = bet.status === 'unresulted';

	const now = moment();
	if (!bet.bet_selections) {
		return Number.POSITIVE_INFINITY;
	}
	return bet.bet_selections.reduce((closesTimeToJump, selection) => {
		// If unresulted, use pending legs only
		if (usePendingOnly && selection.leg_status !== 'pending') {
			return closesTimeToJump;
		}

		// Math.abs is used here to force future races to not appear before current races
		const selectionDate = moment(selection.date, 'YYYY-MM-DD HH:mm:ss');
		const diffFromNow = Math.abs(now.diff(selectionDate, 'seconds'));

		if (diffFromNow < closesTimeToJump) {
			closesTimeToJump = diffFromNow;
			return closesTimeToJump;
		}

		return closesTimeToJump;
	}, Number.POSITIVE_INFINITY);
};

/**
 * Get the label for a sports selection
 *
 * @param selection
 * @returns {*}
 */
export const getSportBetLabel = (selection) => {
	const line = selection.selection_line || selection.line;
	const market = selection.market_name || (selection.market ? selection.market.name : '');

	if (line) {
		const selectionLine = line > 0 ? `(+${line})` : `(${line})`;
		return `${market} ${selectionLine}`;
	}

	return market;
};
