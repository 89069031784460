// Libraries
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, media } from '@tbh/ui-kit';

// Custom date formatting function
const formatDate = (dateString) => {
	const date = new Date(dateString);
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const year = date.getFullYear();
	return `${day}/${month}/${year}`;
  };

// Components
import { EmailInput, Input, InputGroup, Notification } from '@tbh/ui-kit';
import ContactUsPanel from './ContactUsPanel/ContactUsPanel';
import VerificationStatus from './VerificationStatus/VerificationStatus';

import { TABLET_MAX_WIDTH } from '../../../../common/constants/Breakpoints';

// Context

const StyledProfile__Wrapper = styled('div')(
	(props) => css`
		label: Profile__Wrapper;

		display: flex;
		align-items: flex-start;
		flex-flow: row wrap;
		padding: ${spacings(props).cozy}px;
	`,
);

const StyledProfile__VerificationStatus = styled(VerificationStatus)(
	(props) => css`
		label: Profile__VerificationStatus;

		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: ${spacings(props).comfortable}px;
	`,
);

const StyledProfile__FormDisabledMessage = styled(Notification)(
	(props) => css`
		label: Profile__FormDisabledMessage;

		margin-top: ${spacings(props).compact}px;
		font-size: 12px;
	`,
);

const StyledProfile__Form = styled('div')(
	css`
		label: Profile__Form;

		order: 2;
		//flex: 1 1 100%;
		width:100%;
	`,
	media(
		css`
			order: 1;
			width:100%;
		`,
		TABLET_MAX_WIDTH,
	),
);

const StyledProfile__Contact = styled(ContactUsPanel)(
	css`
		label: Profile__Contact;

		display: none;
	`,
	(props) =>
		media(
			css`
				display: inline;
				margin-bottom: ${spacings(props).cozy}px;
				font-size: 15px;
				border:none !important;

			`,
			TABLET_MAX_WIDTH,
		),
);

const StyledProfile__VerificationAndContact = styled('div')(
	css`
		label: Profile__VerificationAndContact;

		order: 1;
		width:60%;
		//flex: 1 1 100%;
	`,
	(props) =>
		media(
			css`
				order: 2;
				width:20%;
				//flex: 0 0 220px;
				//max-width: 220px;
				margin-left: ${spacings(props).comfortable}px;
			`,
			TABLET_MAX_WIDTH,
		),
);

const Profile = (props) => {
	const {
		t,
		email,
		size,
		first_name,
		msisdn,
		username,
		street,
		className,
		country,
		dob,
		formDisabled,
		last_name,
		postcode,
		state,
		city,
		userIsVerified,
		onCompleteVerification,
	} = props;

	// useEffect(() => {
	// 	if (dob) {
	// 	  console.log(formatDate(dob));
	// 	}
	//   }, [dob]);

	const profileClass = cx({
		[className]: className,
	});

	return (
		<div className={profileClass}>
			<StyledProfile__Wrapper>
				{/* <StyledProfile__VerificationAndContact size={size}>
					<FeatureContext.Consumer>
						{(featureToggles) => {
							// onCompleteVerification open Intercom so Intercom needs to be enabled before rendering VerificationStatus
							return !userIsVerified &&
								featureToggles.features.userVerification.enabled &&
								featureToggles.features.intercom.enabled ? (
									<StyledProfile__VerificationStatus onCompleteVerification={onCompleteVerification} />
							) : null;
						}}
					</FeatureContext.Consumer>
					<StyledProfile__Contact size={size} />
				</StyledProfile__VerificationAndContact> */}
				<StyledProfile__Form size={size}>


					{/* <Input
						value={username}
						name="username"
						type="text"
						label={t('Username')}
						disabled={formDisabled}
						margin="cozy"
					/> */}
					<EmailInput value={email} name="email" label={t('Email')} disabled={formDisabled} margin="cozy" />
					<InputGroup>
						<Input
							value={first_name}
							name="first_name"
							type="text"
							label={t('Profile__FirstName')}
							disabled={formDisabled}
							margin="cozy"
							horizontalMargin="cozy"
						/>
						<Input
							value={last_name}
							name="last_name"
							type="text"
							label={t('Profile__LastName')}
							disabled={formDisabled}
						/>
					</InputGroup>
{/*
          						<Input
						value={msisdn}
						name="mobile"
						type="text"
						label={t('Profile__MobileNumber')}
						disabled={formDisabled}
						margin="cozy"
						horizontalMargin="cozy"
					/>
					 */}
					<InputGroup>
						<Input value={formatDate(dob)} name="dob" type="text" label={t('Profile__Birthday')} disabled={formDisabled} margin="cozy"
							horizontalMargin="cozy" />

					</InputGroup>

					<Input
						value={street}
						name="address"
						type="text"
						label={t('Profile__Address')}
						disabled={formDisabled}
						margin="cozy"
					/>
					<InputGroup>
						<Input
							value={city}
							name="suburb"
							type="text"
							label={t('Profile__Suburb')}
							disabled={formDisabled}
							margin="cozy"
							horizontalMargin="cozy"
						/>
						{/* {country !== 'Australia'  && state=='Outside of Australia' ? (
							<Input value={state} name="state" type="text" label={t('Profile__State')} disabled={formDisabled} />
						) :( null
							// <Input value={state} name="state" type="text" label={t('Profile__State')} disabled={formDisabled} />
						)} */}

					</InputGroup>
					<InputGroup>
						<Input
							value={country}
							name="country"
							type="text"
							label={t('Profile__Country')}
							disabled={formDisabled}
							margin="cozy"
							horizontalMargin="cozy"
						/>
						<Input
							value={postcode}
							name="postcode"
							type="text"
							label={t('Profile__Postcode')}
							disabled={formDisabled}
						/>
					</InputGroup>

					{formDisabled && (
						<StyledProfile__FormDisabledMessage type={Notification.types.COLOUR_INFO} transparent>
							<strong>{t('Note')}:</strong> {t('Profile__FormDisabledMessage')}
						</StyledProfile__FormDisabledMessage>
					)}
				</StyledProfile__Form>
			</StyledProfile__Wrapper>
		</div>
	);
};

Profile.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** Opens the intercom so the user can complete the verification */
	onCompleteVerification: PropTypes.func.isRequired,

	/** The current user's email */
	email: PropTypes.string,

	/** The current user's first name */
	first_name: PropTypes.string,

	/** The current user's mobile */
	msisdn: PropTypes.string,

	/** The current user's username */
	username: PropTypes.string,

	/** The current user's address */
	street: PropTypes.string,

	/** The current user's country */
	country: PropTypes.string,

	/** The current user's dob */
	dob: PropTypes.string,

	/** The current user's last name */
	last_name: PropTypes.string,

	/** The current user's postcode */
	postcode: PropTypes.string,

	/** The current user's state */
	state: PropTypes.string,

	/** The current user's suburb */
	city: PropTypes.string,

	/** Whether the form/inputs are disabled or not */
	formDisabled: PropTypes.bool,

	/** If it's a verified user or not */
	userIsVerified: PropTypes.bool,

	/** Extra classes */
	className: PropTypes.string,
};

Profile.defaultProps = {
	email: '',
	first_name: '',
	username: '',
	street: '',
	className: null,
	country: '',
	msisdn: '',
	dob: '',
	formDisabled: true,
	last_name: '',
	postcode: '',
	state: '',
	city: '',
	userIsVerified: false,
};

export default withNamespaces()(Profile);
