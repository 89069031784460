import React from 'react';
import styled from 'react-emotion';
import { MEDIA_QUERIES } from '../../../../constants/themes';

const FlagFeature = styled('img')`
	width: 44px;
	height: 44px;
	border-radius: 4px;

	@media ${MEDIA_QUERIES.tabletAndUp} {
		width: 64px;
		height: 64px;
	}
`;

const CountryImage = ({ country, className }) => {
	switch (country) {
		case 'AU':
			return (
				<img
					src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/AUS.svg"
					className={className}
					alt="Australia"
				/>
			);
		case 'NZ':
			return (
				<img
					src={
						'https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Silver_fern_flag.svg/1200px-Silver_fern_flag.svg.png'
					}
					// src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/NZ.svg"
					className={className}
					alt="New Zealand"
				/>
			);
		case 'UK':
			return (
				<img src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/GB.svg" className={className} alt="UK" />
			);
		case 'HK':
			return (
				<img src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/HK.svg" className={className} alt="USA" />
			);
		case 'FR':
			return (
				<img src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/FR.svg" className={className} alt="USA" />
			);
		case 'SGP':
			return (
				<img
					src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/SGP.svg"
					className={className}
					alt="Singapore"
				/>
			);
		case 'JPN':
			return (
				<img
					src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/JPN.svg"
					className={className}
					alt="Japan"
				/>
			);
		case 'JP':
			return (
				<img
					src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/JPN.svg"
					className={className}
					alt="Japan"
				/>
			);
		case 'US':
			return (
				<img
					src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/USA.svg"
					className={className}
					alt="USA"
				/>
			);
		case 'SAF':
			return (
				<img
					src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/SAF.svg"
					className={className}
					alt="SAF"
				/>
			);
		case 'ZA':
			return (
				<img
					src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/SAF.svg"
					className={className}
					alt="SAF"
				/>
			);
		default:
			return null;
	}
};

export default CountryImage;

export const coutryImageObject = {
	AU: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/AUS.svg',
	NZ: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/NZ.svg',
	UK: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/GB.svg',
	HK: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/HK.svg',
	FR: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/FR.svg',
	SGP: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/SGP.svg',
	JPN: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/JPN.svg',
	US: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/USA.svg',
	SAF: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/SAF.svg',
	ZA: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/SAF.svg',
};
