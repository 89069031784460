import styled, { css } from 'react-emotion';
import { application, typography, spacings, Text, Button, media, ButtonGroup, brand } from '@tbh/ui-kit';

export const StyledCounryFilter = styled('div')(
	(props) => css`
		label: CountryFilter;

		color: ${typography(props).color_3};
		white-space: nowrap;
		padding-right: 5px;
		width: fit-content!important;
	`,
	media(
		(props) => css`
				flex-direction: column;
			`,
		767,
	)
);

export const StyledCountryCodeFilterSelection__Button = styled(Button)(
	(props) => css`
		label: CountryCodeFilterSelection__Button;

        margin-top: 15px;
        font-weight: 800;
        line-height: 1.5;
        padding: ${spacings(props).compact}px;
		text-align: center;
		// This is to keep all the buttons at the top of the race card the same width
		//min-width: ${application(props).racing.button_width}px;
		max-width:30px;
		padding: 2px;

		&:hover {
			// This is to override the specificity of the Text component
			${Text} {
				color: ${typography(props).color_1_alternate};
			}
		}

		${props.type === 'secondary' &&
		css`
				&& {
					background: ${brand(props).rch_4};
					border: 1px solid ${brand(props).rch_4};
				}
			`};

       
		&:first-child {
			margin-left: 0; // This is to override the specificity of the button group
		}
	`,

	media(
		(props) => css`
			//min-width: ${application(props).racing.button_width_expanded}px;
			//min-width: ${application(props).racing.button_width}px;
			max-width:30px;
			padding: 2px;



			
		`,
		780,
	),
);

export const StyledCounryFilterSelection = styled(ButtonGroup)(
	(props) => css`
		label: CounryFilterSelection;

		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 12px;
		border-left: 1px solid #ddd;
		gap: 12px;

		&:not(:last-of-type) {
			border-right: 1px solid #ddd;
		}
	`,
	media(
		(props) => css`
			justify-content: space-between;

			${StyledCountryCodeFilterSelection__Button} {
				min-width: ${application(props).racing.button_width_expanded}px;
			}
		`,
		780,
	),
);

export const CheckBoxLabel = styled('label')(
	(props) => css`
		label: CheckBoxLabel;
		
			font-size: 10px;
			font-weight: 600;
			line-height: 1.5;
			margin-left: 5px;
			color: #000000;
			cursor: pointer;
		`
);

