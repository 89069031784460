import { useState, useRef, useEffect } from 'react';

const useLoginSessionTime = (key, defaultValue = 0) => {
    //const [sessionTime, setSessionTime] = useState(defaultValue);

    const [sessionTime, setSessionTime] = useState(() => {
        const valueInLoginSessionTime = sessionStorage.getItem(key);
        if (valueInLoginSessionTime) {
            return JSON.parse(valueInLoginSessionTime);
        }
        return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
    });

    const pevSessionKey = useRef(key);

    useEffect(() => {
        const prevKey = pevSessionKey.current;
        if (prevKey !== key) {
            sessionStorage.removeItem(prevKey);
        }
        pevSessionKey.current = key;
        sessionStorage.setItem(key, JSON.stringify(sessionTime));
    }, [key, sessionTime]);

    return [sessionTime, setSessionTime];
};

export default useLoginSessionTime;