import { brand, Button, spacings } from '@tbh/ui-kit';
import React from 'react';
import styled, { css } from 'react-emotion';
import { connect } from 'react-redux';
import { scrollToTop, showDeposits } from '../../../../../store/application/applicationActions';
import { getDepositLimit, setDepositLimit } from '../../../../../store/depositLimits/depositLimitsActions';
import { trackGaEvent } from '../../../../../store/trackingPixels/trackingActions';
import BetLimits from '../../../ResponsibleGambling/BetLimits/BetLimits';
import { SET_DAILY_LIMIT, SET_MONTHLY_LIMIT, SET_WEEKLY_LIMIT } from '../../../ResponsibleGambling/BetLimits/Constants';


const StyledSetLimit = styled(BetLimits)(
    (props) => css`
        label: SetLimit;

        border: 1px solid ${brand(props).color_2_dark};
        padding: ${spacings(props).cozy};
    `

);

class SetLimit extends React.Component {

    state = {
        confirmation: null,
        flow: SET_DAILY_LIMIT,
        error: null,
        componentIsLoading: false,
        updateIsLoading: false,
        removeLimitLoading: false,
    };

    componentDidMount() {
        /**
         * Load the deposit limits
         * Set a loading mask while loading the limits
         * Remove when done
         */
        this.setLoadingMask(true, 'componentIsLoading');

        this.props.getDepositLimit('weekly');
        this.props.getDepositLimit('monthly');
        return this.props.getDepositLimit('daily').finally(() => {
            this.setLoadingMask(false, 'componentIsLoading');
        });
    }

    /**
     * Set a loading mask
     *
     * @param loading
     * @param region
     */
    setLoadingMask(loading = false, region) {
        this.setState({
            [region]: loading,
        });
    }

    /**
     * handle content change when clicking on a tab item
     * and send GA tracking
     *
     * @param screen
     */
    handleScreenChange = (screen) => {
        let track;

        if (screen === SET_DAILY_LIMIT) {
            track = 'Daily';
        } else if (screen === SET_WEEKLY_LIMIT) {
            track = 'Weekly';
        } else if (screen === SET_MONTHLY_LIMIT) {
            track = 'Monthly';
        }

        this.props.onTabClick(track);

        this.handleClearMessage();

        this.setState({
            flow: screen,
        });
    };

    /**
     * Calls the action to set the deposit limit
     * Set an error message if the action fails
     *
     * @param amount
     * @param type
     */
    handleSetDepositLimit = (amount, type) => {
        this.setLoadingMask(true, 'updateIsLoading');

        const period = type === 'daily' ? 1 : type === 'weekly' ? 7 : 30;

        this.props
            .setDepositLimit(this.props.userId, amount, period)
            .then((response) => {
                this.props
                    .getDepositLimit(type)
                    .then(() => {
                        this.setState({
                            error: null,
                            confirmation: response.data.data,
                        });
                    })
                    .finally(() => {
                        this.setLoadingMask(false, 'updateIsLoading');
                        scrollToTop();
                        this.props.nextStep();
                    });
            })
            .catch((error) => {
                this.setState({
                    error: error.response && error.response.data ? error.response.data.errors : error.message,
                    confirmation: null,
                });
                this.setLoadingMask(false, 'updateIsLoading');
                scrollToTop();
            });
    };

    /**
     * Calls the action to remove the deposit limit
     * Set an error message if the action fails
     *
     * @param type
     */
    handleRemoveDepositLimit = (type) => {
        this.setLoadingMask(true, 'removeLimitLoading');

        const period = type === 'daily' ? 1 : type === 'weekly' ? 7 : 30;

        this.props
            .setDepositLimit(this.props.userId, -1, period)
            .then((response) => {
                this.props
                    .getDepositLimit(type)
                    .then(() => {
                        this.setState({
                            error: null,
                            confirmation: response.data.data,
                        });
                    })
                    .finally(() => {
                        this.setLoadingMask(false, 'removeLimitLoading');
                        scrollToTop();

                    });
            })
            .catch((error) => {
                this.setState({
                    error: error.response && error.response.data ? error.response.data.errors : error.message,
                    confirmation: null,
                });
                this.setLoadingMask(false, 'removeLimitLoading');
                scrollToTop();
            });
    };

    /**
     * Remove the error message
     */
    handleClearMessage = () => {
        this.setState({
            error: null,
            confirmation: null,
        });
    };

    /**
     * Goes to the next step of the registration
     */
    nextStep = () => {
        // this.props.openDeposits();

        this.props.nextStep();
    };
    render() {
		const {
			className,
			first_name,
			daily_deposit_limit,
			weekly_deposit_limit,
			monthly_deposit_limit,
			brandName,
            isRegistration,
            nextStep
		} = this.props;



        const { confirmation, componentIsLoading, updateIsLoading, removeLimitLoading, flow, error } = this.state;
        return (
            <div>
               
                <Button action={() => this.props.onClose('')} type="secondary" bold block flex>
                    Skip, Deposit Later
                </Button>
                <StyledSetLimit
                    first_name={first_name}
                    flow={flow}
                    limitType="Deposit"
                    daily_bet_limit={daily_deposit_limit}
                    weekly_bet_limit={weekly_deposit_limit}
                    monthly_bet_limit={monthly_deposit_limit}
                    onScreenChange={this.handleScreenChange}
                    setBetLimit={this.handleSetDepositLimit}
                    removeBetLimit={this.handleRemoveDepositLimit}
                    clearMessage={this.handleClearMessage}
                    error={error}
                    confirmation={confirmation}
                    componentIsLoading={componentIsLoading}
                    updateIsLoading={updateIsLoading}
                    removeLimitLoading={removeLimitLoading}
                    color="success"
                    brandName={brandName}

                    isRegistration={isRegistration}
                    handleNoLimit={nextStep}
                />

                {/* <Button action={this.nextStep} type="primary" bold block flex>
                    Deposit
                </Button> */}
            </div>

        );
    }
};

const mapStateToProps = (state) => {

	const user =  state.entities.users[state.application.authenticatedUser];
	let first_name = '';
	if (user) {
		first_name = user.first_name;
	}
    return {
        first_name,
        userId: state.application.authenticatedUser,
        daily_deposit_limit: state.depositLimits.daily_deposit_limit,
        weekly_deposit_limit: state.depositLimits.weekly_deposit_limit,
        monthly_deposit_limit: state.depositLimits.monthly_deposit_limit,
        brandName: state.acl.brandDetails && state.acl.brandDetails.name,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDepositLimit: (period) => {
            return dispatch(getDepositLimit(period));
        },
        setDepositLimit: (userId, amount, period) => {
            return dispatch(setDepositLimit(userId, amount, period));
        },
        onTabClick: (type) => {
            return dispatch(trackGaEvent('DepositLimits', 'Select', type));
        },
        openDeposits: () => {
            return dispatch(showDeposits());
          },

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SetLimit);