// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import {
	ui,
	spacings,
	typography,
	COLOUR_INFO,
	COLOUR_SUCCESS,
	COLOUR_WARNING,
	COLOUR_DANGER,
	Icon,
	Text,
	CollapsibleContent,
	CollapsibleIcon,
	CollapsiblePanel,
	DetailsBlock,
	HeaderBar,
	PlotElements,
	StatCell,
	Button,
} from '@tbh/ui-kit';

// Constants
import { BET_RESULT_STATUSES, BET_UNRESULTED_STATUS, BET_PAID_STATUS } from '../../../../../common/constants/Bets';
import { DATE_TIME_FORMAT } from '../../../../../common/constants/DateFormats';
import { DST_EVENT_NAME } from '../../../../../common/constants/DSTConstants';
import { BET_TYPE_DERIVATIVE } from '../../../../../store/entities/constants/BetConstants';

// Functions
import { slugify, formatResult } from '../../../../../legacy/core/format';

// Components
import BetPositionDetail from '../../BetSelection/BetPositionDetail/BetPositionDetail';

// Styles
const StyledBetItem__Details = styled(HeaderBar)(
	(props) => css`
		label: BetItem__Details;

		margin-top: ${spacings(props).tight}px;
	`,
);

const StyledBetOut__Button = styled('div')(
	(props) => css`
		label: BetOut__Button;

		margin-top: ${spacings(props).tight}px;
	`,
);

const StyledBetItem__DetailsHeader = styled(Text)(
	(props) => css`
		label: BetItem__DetailsHeader;

		padding: ${spacings(props).compact}px 0;
	`,
);

const StyledBetItem__DetailsContent = styled(CollapsibleContent)(
	(props) => css`
		label: BetItem__DetailsContent;

		background-color: ${ui(props).color_1};
	`,
);

const StyledBetItem__StatusAndId = styled('div')(
	(props) => css`
		label: BetItem__StatusAndId;

		display: flex;
		flex-direction: column;
		justify-content: space-around;
		width: 110px;
		margin-top: ${spacings(props).tight}px;
	`,
);

const StyledBetItem__Status = styled(StatCell)(
	(props) => css`
		label: BetItem__Status;

		align-self: center;
		width: 110px;
		font-size: ${typography(props)['size_-5']};
		font-weight: bold;
		text-transform: uppercase;

		${props.sportBet &&
		css`
				width: auto;
				margin-top: ${spacings(props).tight}px;
			`};
	`,
);

/**
 * Return the bet status color label needed
 *
 * @param status
 * @param paid
 * @returns {string}
 */
const getStatusColor = (status, paid) => {
	// If bet has been paid - show win or loss
	if (status === BET_PAID_STATUS) {
		if (paid && paid > 0) {
			return COLOUR_SUCCESS;
		}
		return COLOUR_DANGER;
	}

	// If bet has been accepted
	if (status === BET_UNRESULTED_STATUS) {
		return COLOUR_INFO;
	}

	return COLOUR_WARNING;
};

class BetItem extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Bet Item title. E.g.: '2. SPIRIT FORCE' */
		title: PropTypes.string.isRequired,

		/** Array of race selections ordered by race_number */
		multiLegSelections: PropTypes.object.isRequired,

		/** E.g.: '$1.00 @ 2.10 (TBF)' */
		amountAndOdds: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

		/** E.g.: 'DERIVATIVE (TO WIN $6.30)' */
		betTypeDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

		/** Bet's id */
		betId: PropTypes.number,

		/** The type of bet that was placed ie. 'Win', 'Place', 'Trifecta' etc. */
		betType: PropTypes.string,

		/** Extra classes */
		className: PropTypes.string,

		/** Event/Race date */
		date: PropTypes.string,

		/** Derivative bet's title. E.g.: 'EVENS' */
		derivativeTitle: PropTypes.string,

		/** Derivative bet's sub title. E.g.: 'To Beat 1, 3, 5 & 7' */
		derivativeSubTitle: PropTypes.string,

		/** Bet details */
		details: PropTypes.shape({
			competitionName: PropTypes.string,
			eventName: PropTypes.string,
			sportName: PropTypes.string,
		}),

		/** How much the bet paid out */
		paid: PropTypes.number,

		/** Should the bet status be shown */
		showStatus: PropTypes.bool,

		/** If it's a sport bet or not */
		sportBet: PropTypes.bool,

		/** Bet status as defined in our constants */
		status: PropTypes.oneOf(BET_RESULT_STATUSES),

		dailyBailOutLimit: PropTypes.number,
	};

	static defaultProps = {
		amountAndOdds: null,
		betTypeDescription: null,
		betId: null,
		betType: null,
		className: '',
		date: '',
		details: null,
		derivativeTitle: null,
		derivativeSubTitle: null,
		paid: 0,
		multiLegSelections: [],
		showStatus: true,
		sportBet: false,
		status: null,
		title: null,
		dailyBailOutLimit: 0,
		bailOutIsEnabled: false,
	};

	state = {
		detailsAreCollapsed: true,
	};

	toggleDetails = () => {
		this.setState((prevState) => {
			return { detailsAreCollapsed: !prevState.detailsAreCollapsed };
		});
	};

	render() {
		const {
			t,
			amountAndOdds,
			betId,
			betType,
			betTypeDescription,
			multiLegSelections,
			className,
			date,
			details,
			derivativeTitle,
			derivativeSubTitle,
			paid,
			showStatus,
			sportBet,
			status,
			title,
			raceStatus,
			handlecacheOut,
			betItem,
			cashout_limit,
			dailyBailOutLimit,
			bailOutIsEnabled,
			freeCreditAmount,
		} = this.props;

		const componentClasses = cx({
			[className]: className,
		});
		const statusComponent = (
			<StyledBetItem__Status
				sportBet={sportBet}
				label={t('Status')}
				value={t(formatResult(status, paid))}
				statusColor={getStatusColor(status, paid)}
				spacing={StatCell.spacings.SPACING_CONSTRICTIVE}
			/>
		);

		const betDetails = details ? (
			<React.Fragment>
				<StyledBetItem__Details
					action={this.toggleDetails}
					aside={<CollapsibleIcon isCollapsed={this.state.detailsAreCollapsed} size="-3" />}
					type={HeaderBar.types.SUPPORT}
					horizontalPadding={HeaderBar.spacing.SPACING_COMPACT}
					fluid
				>
					<StyledBetItem__DetailsHeader size="-2">{t('Details')}</StyledBetItem__DetailsHeader>
				</StyledBetItem__Details>
				<StyledBetItem__DetailsContent
					isCollapsed={this.state.detailsAreCollapsed}
					padding={CollapsiblePanel.spacings.SPACING_COMPACT}
					useBorder
				>
					<DetailsBlock
						size={DetailsBlock.sizes.COMPACT}
						type={DetailsBlock.types.SUBTLE}
						headingText={
							<React.Fragment>
								<Icon size="-1" icon={slugify(details.sportName)} push="1" />
								{details.sportName}
							</React.Fragment>
						}
						headingTextUppercase
						subHeadingText={details.eventName !== DST_EVENT_NAME ? details.eventName : null}
						supportText={details.competitionName}
						align="center"
					/>
					{statusComponent}
				</StyledBetItem__DetailsContent>
			</React.Fragment>
		) : null;

		return (
			<React.Fragment>
				<PlotElements className={componentClasses} align="space-between">
					{betType && betType === BET_TYPE_DERIVATIVE ? (
						<div
							className={css`
								flex: 1;
							`}
						>
							<DetailsBlock
								size={DetailsBlock.sizes.COMPACT}
								headingText={derivativeTitle}
								headingTextUppercase
								subHeadingText={derivativeSubTitle}
							/>
							<DetailsBlock
								size={DetailsBlock.sizes.COMPACT}
								subHeadingText={amountAndOdds}
								supportText={betTypeDescription}
								supportTextUppercase
							/>
						</div>
					) : (
						<DetailsBlock
							className={css`
								flex: 1;
							`}
							size={DetailsBlock.sizes.COMPACT}
							headingText={title}
							headingTextUppercase
							subHeadingText={amountAndOdds}
							supportText={betTypeDescription}
							supportTextUppercase
							detailText={sportBet && date ? moment(date).format(DATE_TIME_FORMAT) : null}
						/>
					)}

					{status && showStatus && !sportBet ? (
						<StyledBetItem__StatusAndId>
							{betId && (
								<Text type="light" size="-5" align="center">
									{t('ID')}: {betId}
								</Text>
							)}
							{statusComponent}
							{bailOutIsEnabled &&
								raceStatus === 'selling' &&
								status !== 'cashout' &&
								cashout_limit !== 0 &&
								freeCreditAmount === null &&
								dailyBailOutLimit > 0 ? (
								<StyledBetOut__Button>
									<Button type="secondary" action={handlecacheOut} data={betItem}>
										{t('Bail Out')}
									</Button>
								</StyledBetOut__Button>
							) : (
								''
							)}
							{cashout_limit === 0 ? (
								<Text type="danger" size="-5" align="center">
									{t('Daily Bail out limit existing')}
								</Text>
							) : (
								''
							)}
						</StyledBetItem__StatusAndId>
					) : null}
				</PlotElements>

				{multiLegSelections &&
					Object.keys(multiLegSelections).map((key, index) => (
						<BetPositionDetail
							key={key}
							selection={multiLegSelections[key].join(', ')}
							label={`Leg ${index + 1}`}
							isStandout
							isLegs
						/>
					))}

				{betDetails}
			</React.Fragment>
		);
	}
}
//featureToggles.features.
export default withNamespaces()(BetItem);
