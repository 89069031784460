import { batchActions } from 'redux-batched-actions';
import { get, post } from '../../common/Ajax';
import { createAction, updateEntities } from '../../common/actions/actionHelpers';
import env from '../../common/EnvironmentVariables';
import { getAuthenticatedUser } from '../application/applicationSelectors';
import { normalizeDeposits } from '../entities/schemas/DepositSchema';
import {
	deleteCreditCardToken,
	fetchCreditCards,
	setLastUsedCardOnLocalStorage,
} from '../entities/actions/CreditCardActions';
import { normalizeCreditCards } from '../entities/schemas/CreditCardSchema';
import {
	CLEAR_DEPOSITS_DATA,
	CLEAR_CARD_VERIFICATION_STATE,
	DEPOSIT_DONE_BATCH,
	SET_CARD_VERIFICATION_ATTEMPTS_LEFT,
	SET_CARD_VERIFICATION_SUCCESS,
	SET_DEPOSIT_ERROR_MESSAGES,
	SET_DEPOSIT_LOADING_MASK,
	SET_LAST_DEPOSIT_ID,
	SET_LOADING_CARDS,
	SET_PROMO_CODE,
	SET_VERIFICATION_LOADING_MASK,
	SET_BPAY_DATA,
	CLEAR_BPAY_DATA,
	CLEAR_BANK_EFT_DATA,
	SET_BANK_EFT_DATA,
	SET_START_VERIFICATION,
	SET_DEPOSIT_CC_TOKEN,
} from './depositActionTypes';
import { DEFAULT_DEPOSIT_RECEIPT_TITLE } from '../../containers/Deposit/DepositConstants';
import { DEPOSIT_DEFAULT } from './depositsReducerNames';
import { fetchAuthenticatedUser, logoutUser } from '../authentication/authenticationActions';
import { errorString } from '../../legacy/core/format';
import { trackGaEvent, trackGtmEvent } from '../trackingPixels/trackingActions';
import { updateBetPromptDetails } from '../betPrompt/betPromptActions';
import { trackGaTransaction } from '../trackingPixels/trackingActions';
import { centsAsDollars } from '../../legacy/core/format';
/**
 * This is used to post the Webdosh payment using an XMLHttpRequest
 *  NOTE: couldn't use our normal post/axios method as there is an issue with the extra headers it adds & the Access-Control-Allow-Headers
 *
 * @param endpoint
 * @param createTokenData
 */
const postWebdosh = (endpoint, createTokenData) => {
	return new Promise(function(resolve, reject) {
		// Format the data
		const data = Object.keys(createTokenData)
			.map((key) => key + '=' + createTokenData[key])
			.join('&');

		let request = new XMLHttpRequest();
		request.open('POST', endpoint);

		request.setRequestHeader('Accept', 'application/json, text/javascript, */*; q=0.01');
		request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
		request.responseType = 'json';

		request.onreadystatechange = function() {
			if (request.readyState === XMLHttpRequest.DONE) {
				if (request.status === 200) {
					resolve(request.response.temporary_token);
				} else {
					// Format the errors from webdosh
					let errorMessages = 'An error occurred processing the card.';
					if (request.response && request.response.errors) {
						const errorObj = request.response.errors;
						errorMessages = Object.keys(errorObj).map((errorKey) => {
							return errorObj[errorKey][0].message;
						});
					}

					reject(errorMessages);
				}
			}
		};
		request.onerror = function() {
			reject(Error('Network Error'));
		};
		request.send(data);
	});
};

const makeWebDoshDeposit = ({ reducerName, amount, name, number, expiryDate, cvv, trackingCategory, user }) => (
	dispatch,
) => {
	return new Promise(async (resolve, reject) => {
		const webdoshApiUrl = env('webdoshApiUrl');

		// 1. Make a request to our api to get a webdosh token
		let access_token;
		try {
			const response = await post('/payment/webdosh/access-token', 1);
			access_token = response.data.data.access_token;
		} catch (e) {
			return reject(e);
		}

		// 2. Make request to webdosh with our token and credit card details
		let [expiryMonth, expiryYear] = expiryDate.split('/');
		expiryYear = '20' + expiryYear; // Webdosh needs year as 4 digits

		// Set card type
		const majorIndustryIdentifier = number.charAt(0);
		let paymentMethod;
		switch (majorIndustryIdentifier) {
			case '4': // Visa card
				paymentMethod = 'VISA';
				break;
			case '5': // Mastercard
				paymentMethod = 'MasterCard';
				break;
		}

		const createTokenEndpoint = webdoshApiUrl + 'temporary_token/create';
		const createTokenData = {
			temporary_access: access_token,
			card_holder: name,
			card_number: number,
			card_expiry_month: expiryMonth,
			card_expiry_year: expiryYear,
			card_verification_code: cvv,
			payment_method: paymentMethod,
		};

		let temporary_token;
		try {
			temporary_token = await postWebdosh(createTokenEndpoint, createTokenData);
		} catch (e) {
			return reject(e);
		}

		// 3. Make a request to our API with the payment token, along with the amount
		let transaction;
		try {
			const processPayment = await post('/payment/webdosh/deposit', {
				amount: amount,
				cc_token: temporary_token,
			});
			transaction = processPayment.data.data;

			const depositType = transaction.first_deposit ? 'FD' : 'RD';
			const id = `${transaction.id}-${user.id}`;
			const transactionData = {
				revenue: centsAsDollars(transaction.amount),
			};
			const items = [
				{
					name: 'Deposit',
					sku: `${depositType}-${transaction.type}`,
					category: `${depositType}-${transaction.type}`,
					price: centsAsDollars(transaction.amount),
				},
			];

			// Add tracking events and set the last deposit
			dispatch(trackGaTransaction(id, transactionData, items));
			dispatch(trackGaEvent(trackingCategory, transaction.type, user.username, transaction.amount));
			dispatch(trackGtmEvent('depositMade'));

			dispatch(
				batchActions(
					[
						updateBetPromptDetails({ title: DEFAULT_DEPOSIT_RECEIPT_TITLE }),
						updateEntities(normalizeDeposits(transaction).entities),
						setLastDeposit(reducerName, transaction.id),
					],
					DEPOSIT_DONE_BATCH,
				),
			);

			// Update the user account balance
			dispatch(fetchAuthenticatedUser());
		} catch (e) {
			reject(e);
		}

		// Payment Success
		resolve(transaction);
	});
};

/**
 * Requests new deposit with new card details
 *
 * @param reducerName
 * @param amount {number} - In dollars
 * @param name
 * @param number {string}
 * @param expiryDate
 * @param cvv {string}
 * @param trackingCategory
 * @param user
 * @param issavecard
 */
export const makeDepositWithNewCard = (
	reducerName = DEPOSIT_DEFAULT,
	amount,
	name,
	number,
	expiryDate,
	cvv,
	trackingCategory = 'Deposit',
	user = {},
	isSaveCard,
) => (dispatch, getState) => {
	// Deposits with new card must be done with integer values (except for webdosh)
	if (!Number.isInteger(amount / 100) && !env('webdosh')) {
		dispatch(
			setDepositErrors(
				reducerName,
				'This amount will be used in verifying your card, please enter whole $ amounts. Restriction only applies to first deposit on this card.',
			),
		);
		return;
	}

	const state = getState();

	const [firstName, lastName] = getFirstAndLastNames(name);
	const [expiryMonth, expiryYear] = expiryDate.split('/');

	const data = {
		amount,
		number: number,
		cvv: eCrypt.encryptValue(String(cvv), env('ewayMerchantKey')),
		firstName,
		lastName,
		expiryMonth,
		expiryYear,
		billingCountry: 'AU',
		promo_code: state.deposits.validatePromoCode || '',
		source: 'topbetta',
		isSaveCard,
	};
	dispatch(setDepositLoadingMask(reducerName, true));

	// If WebDosh payment gateway being used for payments
	if (env('webdosh')) {
		return dispatch(
			makeWebDoshDeposit({
				reducerName,
				amount,
				name,
				number,
				expiryDate,
				cvv,
				trackingCategory,
				user,
			}),
		)
			.catch((errorData) => {
				document.Sentry && document.Sentry.captureException(errorData);
				if (errorData && errorData.response && errorData.response.data && errorData.response.data.errors) {
					return dispatch(setDepositErrors(reducerName, errorData.response.data.errors));
				}

				let errorMessage = 'Unknown error occurred.';
				if (errorData) {
					errorMessage = errorData;
				} else if (errorData && errorData.response && errorData.response.data && errorData.response.data.errors) {
					errorMessage = errorData.response.data.errors;
				}
				dispatch(setDepositErrors(reducerName, errorMessage));
			})
			.finally(() => {
				dispatch(setDepositLoadingMask(reducerName, false));
			});
	}
	return post('deposits', data)
		.then((response) => {
			const transaction = response.data.data;
			const depositType = transaction.first_deposit ? 'FD' : 'RD';
			const id = `${transaction.id}-${user.id}`;
			const transactionData = {
				revenue: centsAsDollars(transaction.amount),
			};
			const items = [
				{
					name: 'Deposit',
					sku: `${depositType}-${transaction.type}`,
					category: `${depositType}-${transaction.type}`,
					price: centsAsDollars(transaction.amount),
				},
			];

			dispatch(trackGaTransaction(id, transactionData, items));
			dispatch(trackGaEvent(trackingCategory, transaction.type, user.username, transaction.amount));
			dispatch(trackGtmEvent('depositMade'));
			dispatch(
				batchActions(
					[
						updateBetPromptDetails({ title: DEFAULT_DEPOSIT_RECEIPT_TITLE }),
						updateEntities(normalizeDeposits(transaction).entities),
						setLastDeposit(reducerName, transaction.id),
					],
					DEPOSIT_DONE_BATCH,
				),
			);

			// // if we are at the registration page redirect to home page
			// // state.application.selectedPage === 'Registration'
			// if (state.application.selectedPage === 'Registration') {
			// 	dispatch(navigate('/'));
			// }
			// Update the user account balance
			dispatch(fetchAuthenticatedUser());

			// Save cc_token to deposit state
			dispatch(setDepositCcToken(reducerName, response.data.data.cc_token));

			return dispatch(fetchCreditCards());
		})
		.catch((errorData) => {
			document.Sentry && document.Sentry.captureException(errorData);
			dispatch(setDepositErrors(reducerName, errorData.response.data.errors));
		})
		.finally(() => {
			setLastUsedCardOnLocalStorage(`${firstName} ${lastName}`, number.toString().slice(-4));
			dispatch(setDepositLoadingMask(reducerName, false));
		});
};

/**
 * Sends post request to make deposit with existing card
 *
 * @param reducerName
 * @param cardId
 * @param amount
 * @param cvv
 * @param trackingCategory
 * @param user
 * @param isQuickDeposit
 */
export const makeDepositViaToken = (
	reducerName = DEPOSIT_DEFAULT,
	cardId,
	amount,
	cvv,
	trackingCategory = 'Deposit',
	user = {},
	isQuickDeposit,
) => (dispatch, getState) => {
	const state = getState();

	const data = {
		amount,
		cc_token: String(cardId),
		cvv: eCrypt.encryptValue(String(cvv), env('ewayMerchantKey')),
		// cvv,
		promo_code: state.deposits.validPromoCode || '',
		source: 'topbetta',
	};

	dispatch(setDepositLoadingMask(reducerName, true));
	return post('deposits', data)
		.then((response) => {
			const { FirstName: firstName, LastName: lastName, Number: number } = state.entities.creditCards[cardId];
			setLastUsedCardOnLocalStorage(`${firstName} ${lastName}`, number.slice(-4));

			const transaction = response.data.data;
			const depositType = transaction.first_deposit ? 'FD' : 'RD';
			const id = `${transaction.id}-${user.id}`;
			let transactionData, items;

			if (isQuickDeposit) {
				transactionData = {
					revenue: centsAsDollars(transaction.amount),
					shipping: 0,
				};
				items = [
					{
						category: 'Credit Card',
						name: 'Quick Deposit',
						sku: 'Credit Card',
						price: centsAsDollars(transaction.amount),
					},
				];
			} else {
				transactionData = {
					revenue: centsAsDollars(transaction.amount),
				};
				items = [
					{
						category: `${depositType}-${transaction.type}`,
						name: 'Deposit',
						sku: `${depositType}-${transaction.type}`,
						price: centsAsDollars(transaction.amount),
					},
				];
			}

			dispatch(trackGaTransaction(id, transactionData, items));
			dispatch(trackGaEvent(trackingCategory, transaction.type, user.username, transaction.amount));
			dispatch(trackGtmEvent('depositMade'));
			dispatch(
				batchActions(
					[
						updateBetPromptDetails({ title: DEFAULT_DEPOSIT_RECEIPT_TITLE }),
						updateEntities(normalizeDeposits(transaction).entities),
						setLastDeposit(reducerName, transaction.id),
					],
					DEPOSIT_DONE_BATCH,
				),
			);

			// Update the user account balance
			dispatch(fetchAuthenticatedUser());
		})
		.catch((errorData) => {
			document.Sentry && document.Sentry.captureException(errorData);
			dispatch(setDepositErrors(reducerName, errorData.response.data.errors));
		})
		.finally(() => {
			dispatch(setDepositLoadingMask(reducerName, false));
		});
};

/**
 * Sends verification code for new credit card.
 *
 * @param reducerName
 * @param cardId
 * @param verifyCode
 */
export const verifyCreditCard = (reducerName = DEPOSIT_DEFAULT, cardId, verifyCode) => (dispatch, getState) => {
	const user = getAuthenticatedUser(getState());

	const params = {
		user_id: user.id,
		cc_token: cardId,
		verify_code: verifyCode,
	};

	dispatch(setVerificationLoadingMask(reducerName, true));
	return post('credit-card/verify', params)
		.then(() => {
			// TODO: When endpoint is up, confirm best type for 'verified'
			dispatch(
				batchActions(
					[
						updateEntities(normalizeCreditCards([{ id: cardId, verified: 1 }]).entities),
						setVerificationSuccessful(reducerName),
					],
					'CARD_VERIFY_SUCCESSFUL__BATCH',
				),
			);
		})
		.catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			dispatch(
				setDepositErrors(
					reducerName,
					errorString(error.response && error.response.data ? error.response.data.errors : error.message),
				),
			);
			if (error.response) {
				switch (error.response.status) {
					// When running out of attempts.
					case 550: {
						dispatch(setCardVerificationAttemptsLeft(reducerName, 0));
						dispatch(logoutUser());
						break;
					}

					default:
						dispatch(setCardVerificationAttemptsLeft(reducerName, error.response.data.attempts_left));
				}
			}
		})
		.finally(() => {
			dispatch(setVerificationLoadingMask(reducerName, false));
		});
};

export const setDepositSuccessful = (reducerName = DEPOSIT_DEFAULT) =>
	createAction(SET_CARD_VERIFICATION_SUCCESS, null, { reducerName });

/**
 * POST request to validate promo code. Set store with valid one only
 *
 * @param reducerName
 * @param promoCode
 */
export const validatePromoCode = (reducerName = DEPOSIT_DEFAULT, promoCode) => (dispatch) => {
	return post('promos/validate', { promo_code: promoCode })
		.then(() => {
			dispatch(setPromoCode(reducerName, promoCode, true));
		})
		.catch((errorData) => {
			document.Sentry && document.Sentry.captureException(errorData);
			dispatch(setPromoCode(reducerName, null, false));
		});
};

/**
 * Set last deposit amount to browser local storage
 * @param depositAmount
 */
export const setLastDepositAmountOnLocalStorage = (depositAmount) => {
	const lastDeposit = JSON.stringify({
		depositAmount,
	});

	// Save to localStorage -- try/catch for iOS compatibility
	try {
		localStorage.setItem('last_deposit_amount', lastDeposit);
	} catch (e) {
		console.warn('Local storage is disabled on this device');
	}
};

/**
 * Get last deposit amount from browser local storage
 * @return {*}
 */
export const getLastDepositAmountOnLocalStorage = () => {
	// Try to load previously deposited amount if possible
	const lastDeposit = JSON.parse(localStorage.getItem('last_deposit_amount'));

	if (lastDeposit) {
		return parseInt(lastDeposit.depositAmount);
	}

	return 0;
};

/**
 * Trigger reducers to clear Deposit entities as well as the 'deposits' slice of store.
 */
export const clearDepositsStore = (reducerName = DEPOSIT_DEFAULT) => {
	return createAction(CLEAR_DEPOSITS_DATA, null, { reducerName });
};

/**
 * GET request to get credit cards controlling deposit prompt state
 *
 * @param reducerName
 */
export const loadCreditCards = (reducerName = DEPOSIT_DEFAULT) => (dispatch) => {
	dispatch(setLoadingCards(reducerName, true));
	dispatch(fetchCreditCards())
		.catch((error) => {

			if (error.response && error.response.data.http_status_code == 429) return null;

			document.Sentry && document.Sentry.captureException(error);
			
			dispatch(setDepositErrors(reducerName, errorString(error.response.data.errors)));
		})
		.finally(() => {
			dispatch(setLoadingCards(reducerName, false));
		});
};

/**
 * Set errors from deposit process
 *
 * @param reducerName
 * @param {Array|string} errors
 */
export const setDepositErrors = (reducerName = DEPOSIT_DEFAULT, errors = null) =>
	createAction(SET_DEPOSIT_ERROR_MESSAGES, errors, { reducerName });

/**
 * Clear fields related to card verification
 *
 * @param reducerName
 */
export const clearCardVerificationState = (reducerName = DEPOSIT_DEFAULT) =>
	createAction(CLEAR_CARD_VERIFICATION_STATE, null, { reducerName });

/**
 * Uses entity delete action and control prompt state changes
 *
 * @param reducerName
 * @param cardId
 */
export const deleteCreditCard = (reducerName = DEPOSIT_DEFAULT, cardId) => (dispatch) => {
	dispatch(setDepositLoadingMask(reducerName, true));
	return dispatch(deleteCreditCardToken(cardId))
		.catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			dispatch(setDepositErrors(reducerName, errorString(error.response.data.errors)));
		})
		.then(() => {
			dispatch(setDepositLoadingMask(reducerName, false));
		});
};

/**
 * Fetch the details to be present for bpay and bank EFT
 *
 * @param reducerName
 * @param type ['bpay'|'bankdeposit']
 */
export const fetchDepositDetails = (reducerName = DEPOSIT_DEFAULT, type) => (dispatch) => {
	const params = { type };
	return get('users/get/deposit', { baseURL: env('apiUrl'), params }).then((response) => {
		switch (type) {
			case 'bpay': {
				dispatch(createAction(SET_BPAY_DATA, response.data.result, { reducerName }));
				return response;
			}
			case 'bankdeposit': {
				dispatch(createAction(SET_BANK_EFT_DATA, response.data.result, { reducerName }));
				return response;
			}
		}
	});
};

/**
 * Clear fields related to bpay
 *
 * @param reducerName
 */
export const clearBpayData = (reducerName = DEPOSIT_DEFAULT) => createAction(CLEAR_BPAY_DATA, null, { reducerName });

/**
 * Clear fields related to bank eft
 *
 * @param reducerName
 */
export const clearBankEFTData = (reducerName = DEPOSIT_DEFAULT) =>
	createAction(CLEAR_BANK_EFT_DATA, null, { reducerName });

/**
 * Split string in two considering first space character found.
 *
 * @param {string} cardName
 * @return {[string,string]}
 */

const getFirstAndLastNames = (cardName) => {
	const name = cardName.trim();
	const index = cardName.indexOf(' ') !== -1 ? cardName.indexOf(' ') : name.length;

	const firstName = name.substr(0, index);
	const lastName = name.substr(index + 1);

	return [firstName, lastName];
};

/**
 * Handle a Poli deposit
 *
 * @param reducerName
 * @param amount
 */
export const handlePoliDeposit = (reducerName = DEPOSIT_DEFAULT, amount) => (dispatch, getState) => {
	const { validPromoCode } = getState().deposits;
	const params = {
		Amount: amount,
		promo_code: validPromoCode,
	};
	return get('poli-deposit', { params })
		.then((response) => {
			if (response.data.data.ErrorMessage) {
				dispatch(setDepositErrors(reducerName, response.data.data.ErrorMessage));
			} else {
				window.location.assign(response.data.data.NavigateURL);
			}
		})
		.catch((errorData) => {
			dispatch(setDepositErrors(reducerName, errorData.response.data.errors));
			document.Sentry && document.Sentry.captureException(errorData);

		});
};

/**
 * Flag to use when loading credit cards in repeat deposit.
 */
export const setLoadingCards = (reducerName = DEPOSIT_DEFAULT, bool) =>
	createAction(SET_LOADING_CARDS, bool, { reducerName });

/**
 * Loading mask for card verification
 *
 * @param reducerName
 * @param bool
 */
const setVerificationLoadingMask = (reducerName = DEPOSIT_DEFAULT, bool) =>
	createAction(SET_VERIFICATION_LOADING_MASK, bool, { reducerName });

/**
 * Set deposit id to render receipt
 *
 * @param reducerName
 * @param id
 */
const setLastDeposit = (reducerName = DEPOSIT_DEFAULT, id) => createAction(SET_LAST_DEPOSIT_ID, id, { reducerName });

/**
 * Loading mask for deposit
 *
 * @param reducerName
 * @param bool
 */
const setDepositLoadingMask = (reducerName = DEPOSIT_DEFAULT, bool) =>
	createAction(SET_DEPOSIT_LOADING_MASK, bool, { reducerName });

/**
 * Set number of attempts left to verify card
 *
 * @param reducerName
 * @param attemptsLeft
 */
export const setCardVerificationAttemptsLeft = (reducerName = DEPOSIT_DEFAULT, attemptsLeft) =>
	createAction(SET_CARD_VERIFICATION_ATTEMPTS_LEFT, attemptsLeft, { reducerName });

/**
 * This state
 *
 * @param reducerName
 * @param validPromoCode
 * @param isPromoCodeValid
 */
export const setPromoCode = (reducerName = DEPOSIT_DEFAULT, validPromoCode, isPromoCodeValid) =>
	createAction(SET_PROMO_CODE, { validPromoCode, isPromoCodeValid }, { reducerName });

/**
 * Set verification successful
 *
 * @param reducerName
 */
const setVerificationSuccessful = (reducerName = DEPOSIT_DEFAULT) =>
	createAction(SET_CARD_VERIFICATION_SUCCESS, null, { reducerName });

export const setStartVerification = (reducerName = DEPOSIT_DEFAULT) =>
	createAction(SET_START_VERIFICATION, null, { reducerName });

export const setDepositCcToken = (reducerName = DEPOSIT_DEFAULT, token) =>
	createAction(SET_DEPOSIT_CC_TOKEN, token, { reducerName });
