import {
	CLEAR_BANK_EFT_DATA,
	CLEAR_BPAY_DATA,
	CLEAR_CARD_VERIFICATION_STATE,
	CLEAR_DEPOSITS_DATA,
	SET_BANK_EFT_DATA,
	SET_BPAY_DATA,
	SET_CARD_VERIFICATION_ATTEMPTS_LEFT,
	SET_CARD_VERIFICATION_SUCCESS,
	SET_DEPOSIT_CC_TOKEN,
	SET_DEPOSIT_ERROR_MESSAGES,
	SET_DEPOSIT_LOADING_MASK,
	SET_LAST_DEPOSIT_ID,
	SET_LOADING_CARDS,
	SET_PROMO_CODE,
	SET_START_VERIFICATION,
	SET_VERIFICATION_LOADING_MASK,
} from './depositActionTypes';

const bankEftInitialState = {
	eftBankName: null,
	eftBsb: null,
	eftAccountNumber: null,
	eftBankLogo: null,
};

const bpayInitialState = {
	bpayBillerCode: null,
	bpayRef: null,
	bpayLogo: null,
};

const verificationInitialState = {
	verificationLoading: null,
	verificationAttemptsLeft: 3,
	verificationFailed: null,
	inVerificationCard: null,
};

const initialState = {
	...bpayInitialState,
	...bankEftInitialState,
	depositLoading: null,
	loadingCards: null,
	depositErrors: null,
	lastDepositId: null,
	isPromoCodeValid: null,
	validPromoCode: null,
	depositCcToken: null,
	...verificationInitialState,
};

const deposits = (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_DEPOSITS_DATA:
			return initialState;

		case SET_DEPOSIT_LOADING_MASK:
			return {
				...state,
				depositLoading: action.payload,
			};

		case SET_VERIFICATION_LOADING_MASK:
			return {
				...state,
				verificationLoading: action.payload,
			};

		case SET_LOADING_CARDS:
			return {
				...state,
				loadingCards: action.payload,
			};

		case SET_DEPOSIT_ERROR_MESSAGES:
			return {
				...state,
				depositErrors: action.payload,
			};

		case SET_CARD_VERIFICATION_ATTEMPTS_LEFT:
			return {
				...state,
				verificationAttemptsLeft: action.payload,
				verificationFailed: true,
			};

		case SET_CARD_VERIFICATION_SUCCESS:
			return {
				...state,
				verificationAttemptsLeft: initialState.verificationAttemptsLeft,
				verificationFailed: false,
			};

		case CLEAR_CARD_VERIFICATION_STATE:
			return {
				...state,
				...verificationInitialState,
			};

		case SET_LAST_DEPOSIT_ID:
			return {
				...state,
				lastDepositId: action.payload,
			};

		case SET_PROMO_CODE:
			return {
				...state,
				validPromoCode: action.payload.validPromoCode,
				isPromoCodeValid: action.payload.isPromoCodeValid,
			};

		case SET_BPAY_DATA:
			return {
				...state,
				bpayBillerCode: action.payload.biller_code,
				bpayRef: action.payload.ref,
				bpayLogo: action.payload.logo,
			};

		case CLEAR_BPAY_DATA:
			return {
				...state,
				...bpayInitialState,
			};

		case SET_BANK_EFT_DATA:
			return {
				...state,
				eftBankName: action.payload.bank,
				eftBsb: action.payload.BSB,
				eftAccountNumber: action.payload.account,
				eftBankLogo: action.payload.logo,
			};

		case CLEAR_BANK_EFT_DATA:
			return {
				...state,
				...bankEftInitialState,
			};

		case SET_START_VERIFICATION:
			return {
				...state,
				inVerificationCard: true,
			};

		case SET_DEPOSIT_CC_TOKEN: {
			return {
				...state,
				depositCcToken: action.payload,
			};
		}
		default:
			return state;
	}
};

export default deposits;
