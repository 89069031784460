// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled from 'react-emotion';
import { spacings, Notification, Text } from '@tbh/ui-kit';

// Functions
import { minutesCountdown } from '../../../../../../legacy/core/format';

const StyledCountdownAlert = styled(Notification)`
	label: CountdownAlert;

	min-width: 115px;
	max-width: 130px;
	margin-left: ${(props) => `${spacings(props).cozy}px`};
`;

class CountdownAlert extends Component {
	static propTypes = {
		/** The time the countdown was started */
		startTime: PropTypes.number.isRequired,

		/** The time to finish the countdown */
		alertTime: PropTypes.number.isRequired,

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		className: '',
	};

	constructor(props) {
		super(props);

		this.state = {
			type: 'success',
			elapsed: Date.now() + props.startTime,
		};
	}

	componentDidMount = () => {
		this.timer = window.setInterval(this.tick, 1000);
		this.tick();
	};

	componentWillUnmount = () => {
		window.clearInterval(this.timer);
	};

	/**
	 * update the timer by ticking down 1 second
	 */
	tick = () => {
		let time_left = new Date(this.state.elapsed) - Date.now();

		if (time_left >= this.props.alertTime) {
			this.setState({
				elapsed: this.state.elapsed - 1,
				type: 'success',
			});
		} else if (time_left >= 0 && time_left < this.props.alertTime) {
			this.setState({
				elapsed: this.state.elapsed - 1,
				type: 'danger',
			});
		}
		// restart the timer when it gets to 0
		else {
			this.setState({
				elapsed: Date.now() + this.props.startTime,
				type: 'success',
			});
		}
	};

	render() {
		const { className } = this.props;
		const { type, elapsed } = this.state;

		return (
			<StyledCountdownAlert className={cx({ [className]: className })} type={type} strong>
				<Text size="1" align="center" strong>
					{minutesCountdown(elapsed)}
				</Text>
			</StyledCountdownAlert>
		);
	}
}

export default CountdownAlert;
