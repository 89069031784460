import { get } from '../../../common/Ajax';
import { mergeEntities } from '../../../common/actions/actionHelpers';
import { normalizeArticles } from '../schemas/ArticleSchema';

import env from '../../../common/EnvironmentVariables';

/**
 * Fetch an article based on an article slug id and merge into the store
 *
 * @param slug
 * @returns {function(*)}
 */
export const fetchArticleBySlug = (slug) => {
	return (dispatch) => {
		return get(env('blogApiEndpoint') + 'get_post/', {
			params: {
				slug: slug,
			},
		})
			.then((response) => {
				if (!response.data.post) {
					throw Error('Article was not found.');
				}

				dispatch(mergeEntities(normalizeArticles([response.data.post]).entities));
				return response;
			})
			.catch((exception) => {
				throw exception;
			});
	};
};

/**
 * Fetch up to (count) articles in a category based on a category slug and merge into the store
 *
 * @param slug
 * @param count
 * @returns {function(*)}
 */
export const fetchCategoryArticles = (slug, count = 500) => {
	if (!slug) {
		slug = env('blogApiPromotionsCategorySlug') ? env('blogApiPromotionsCategorySlug') : 'promos';
	}

	return (dispatch) => {
		return get(env('blogApiEndpoint') + 'get_category_posts/', {
			params: {
				slug: slug,
				count: count,
			},
		})
			.then((response) => {
				if (response.data.count === 0) {
					throw Error('Article category is empty.');
				}

				if (response.data.status === 'error') {
					throw Error(response.data.error);
				}

				dispatch(mergeEntities(normalizeArticles(response.data.posts).entities));
				return response;
			})
			.catch((exception) => {
				throw exception;
			});
	};
};
