// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SizeMe } from 'react-sizeme';

// Components
import BaseCompetitionCarousel from '../../components/features/Sports/BaseCompetitionCarousel/BaseCompetitionCarousel';

// Functions
import { selectBaseCompetition } from '../../store/sportsHome/sportsHomeActions';
import { trackGaEvent } from '../../store/trackingPixels/trackingActions';
import { getBaseCompetitions } from '../../pages/Sports/SportsHome/sportsHomeSelectors';

const BaseCompetitionCarouselContainer = (props) => (
	<SizeMe>
		{({ size }) => (
			<BaseCompetitionCarousel
				className={props.className}
				items={props.items}
				selectedItem={props.selectedBaseCompetition}
				size={size}
				onItemClick={props.onItemClick}
			/>
		)}
	</SizeMe>
);

const mapStateToProps = (state, ownProps) => {
	return {
		className: ownProps.className,
		items: getBaseCompetitions(state),
		selectedBaseCompetition: state.sportsHome.selectedBaseCompetition,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onItemClick: (baseComp) => {
			dispatch((dispatch, getState) => {
				const state = getState();
				const sport = state.entities.sports[baseComp.sport_id];
				dispatch(trackGaEvent('Sports', 'Click', `${sport.name} - ${baseComp.name}`));
			});
			return dispatch(selectBaseCompetition(baseComp));
		},
	};
};

BaseCompetitionCarouselContainer.propTypes = {
	/** Action to fire when a competition is clicked on in the carousel */
	onItemClick: PropTypes.func.isRequired,

	/** List of base competitions */
	items: PropTypes.arrayOf(
		PropTypes.shape({
			/** The competition ID */
			id: PropTypes.number.isRequired,

			/** The competition name */
			name: PropTypes.string.isRequired,

			/** The competition icon */
			icon: PropTypes.string.isRequired,
		}),
	).isRequired,

	/** The currently selected base competition */
	selectedBaseCompetition: PropTypes.number,

	className: PropTypes.string,
};

BaseCompetitionCarouselContainer.defaultProps = {
	className: null,
	selectedBaseCompetition: null,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(BaseCompetitionCarouselContainer);
