import { MASTHEAD_DEFAULT, MASTHEAD_TYPES } from '../../common/constants/Application';

export const aclSchema = {
	type: 'object',
	properties: {
		acl_description: { type: 'string', title: 'ACL Description' },
		affiliate_description: { type: 'string', title: 'Affiliate Description' },
		affiliate_name: { type: 'string', title: 'Affiliate Name' },
		brandDetails: {
			type: 'object',
			title: 'Brand Details',
			properties: {
				phone: { type: 'string', title: 'Phone' },
				email: { type: 'string', title: 'Email' },
				hours: { type: 'string', title: 'Hours' },
				name: { type: 'string', title: 'Name' },
				copyright: { type: 'string', title: 'Copyright' },
			},
		},
		brandLogos: {
			type: 'object',
			title: 'Brand Logos',
			properties: {
				main: { type: 'string', title: 'Main' },
				secondary: { type: 'string', title: 'Secondary' },
			},
		},
		currencyConfiguration: {
			type: 'object',
			title: 'Currency Configuration',
			properties: {
				currencyName: { type: 'string', title: 'Currency Name' },
				currencySymbol: { type: 'string', title: 'Currency Symbol' },
				bonusCurrencyName: { type: 'string', title: 'Bonus Currency Name' },
				bonusCurrencySymbol: { type: 'string', title: 'Bonus Currency Symbol' },
				tournamentCurrencyName: { type: 'string', title: 'Tournament Currency Name' },
				tournamentCurrencySymbol: { type: 'string', title: 'Tournament Currency Symbol' },
			},
		},
		defaultCountry: { type: 'string', title: 'Default Country' },
		default_route: { type: 'string', title: 'Default Route' },
		disablePinAuth: { type: 'boolean', title: 'Disable Pin Authentication' },
		features: {
			type: 'object',
			title: 'Features',
			properties: {
				accountTransactions: {
					type: 'object',
					title: 'Account Transactions',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
						value: {
							type: 'object',
							title: 'Value',
							properties: {
								hideStatistics: { type: 'boolean', title: 'Hide Statistics', default: false },
							},
						},
					},
				},
				addressVerification: {
					type: 'object',
					title: 'Address Verification',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
					},
				},
				adverts: {
					type: 'object',
					title: 'Adverts',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
					},
				},
				bonusBets: {
					type: 'object',
					title: 'Bonus Bets',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
					},
				},
				ceoComment: {
					type: 'object',
					title: 'CEO Comment',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
					},
				},
				depositsAndWithdrawals: {
					type: 'object',
					title: 'Deposits And Withdrawals',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
					},
				},
				enableSpeedmaps: {
					type: 'object',
					title: 'Enable Speedmaps',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
					},
				},
				firstDepositOnSignup: {
					type: 'object',
					title: 'First Deposit On Signup',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: false },
					},
				},
				groupMeetingsByCountry: {
					type: 'object',
					title: 'Group Meetings By Country',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
					},
				},
				intercom: {
					type: 'object',
					title: 'Intercom',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
					},
				},
				jsonLd: {
					type: 'object',
					title: 'JSON Ld',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
					},
				},
				loginInModals: {
					type: 'object',
					title: 'Login In Modals',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
					},
				},
				mainNavigation: {
					type: 'object',
					title: 'Main Navigation',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
					},
				},
				masthead: {
					type: 'object',
					title: 'Masthead',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
						value: {
							type: 'string',
							title: 'Value',
							enum: MASTHEAD_TYPES,
							enumNames: MASTHEAD_TYPES,
							default: MASTHEAD_DEFAULT,
						},
					},
				},
				mobileVerification: {
					type: 'object',
					title: 'Mobile Verification',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
					},
				},
				ntjRaces: {
					type: 'object',
					title: 'Next To Jump Races',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
					},
				},
				otherLevels: {
					type: 'object',
					title: 'Other Levels',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
					},
				},
				playerBetsWidgets: {
					type: 'object',
					title: 'Player Bets Widgets',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: false },
					},
				},
				racingDerivatives: {
					type: 'object',
					title: 'Racing Derivatives',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
					},
				},
				racingMultiButton: {
					type: 'object',
					title: 'Racing Multi Button',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: false },
					},
				},
				resizeEvent: {
					type: 'object',
					title: 'Resize Event',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: false },
					},
				},
				responsibleGambling: {
					type: 'object',
					title: 'Responsible Gambling',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
					},
				},
				simpleRegistration: {
					type: 'object',
					title: 'Simple Registration',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: false },
					},
				},
				showFooter: {
					type: 'object',
					title: 'Show Footer',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
					},
				},
				tournaments: {
					type: 'object',
					title: 'Tournaments',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
					},
				},
				urlRouting: {
					type: 'object',
					title: 'Url Routing',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
					},
				},
				userProfile: {
					type: 'object',
					title: 'User Profile',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: true },
					},
				},
				versionUpdateSupport: {
					type: 'object',
					title: 'Version Update Support',
					properties: {
						enabled: { type: 'boolean', title: 'Enabled', default: false },
					},
				},
			},
		},
		firstDepositBonuses: {
			type: 'array',
			title: 'First Deposit Bonuses',
			items: {
				type: 'object',
				properties: {
					value: { type: 'string', title: 'Value' },
					bonus: { type: 'string', title: 'Bonus' },
					promoCode: { type: 'string', title: 'Promo Code' },
					successText: { type: 'string', title: 'Success Text' },
					successLink: { type: 'string', title: 'Success Link' },
				},
			},
		},
		footerGroups: {
			type: 'array',
			title: 'Footer Groups',
			items: {
				type: 'object',
				properties: {
					title: { type: 'string', title: 'Title' },
					className: { type: 'string', title: 'ClassName' },
					style: { type: 'string', title: 'Style' },
					itemsStyle: { type: 'string', title: 'Items Style' },
					links: {
						type: 'array',
						title: 'Links',
						items: {
							type: 'object',
							properties: {
								href: { type: 'string', title: 'Href' },
								text: { type: 'string', title: 'Text' },
								linkText: { type: 'string', title: 'Link Text' },
								target: { type: 'string', title: 'Target' },
								icon: { type: 'string', title: 'Icon' },
								imageSrc: { type: 'string', title: 'Image Src' },
								imageProps: {
									type: 'object',
									title: 'Image Props',
									properties: {
										width: { type: 'number', title: 'Width' },
									},
								},
								style: { type: 'string', title: 'Style' },
							},
						},
					},
				},
			},
		},
		gamblingMessages: { type: 'string', title: 'Gambling Messages' },
		navigation_items: {
			type: 'array',
			title: 'Navigation Items',
			items: {
				type: 'object',
				properties: {
					title: { type: 'string', title: 'Title' },
					id: { type: 'string', title: 'Id' },
					icon: { type: 'string', title: 'Icon' },
					route: { type: 'string', title: 'Route' },
					order: { type: 'number', title: 'Order' },
					sidebarOrder: { type: 'number', title: 'Sidebar Order' },
					show: { type: 'boolean', title: 'Show', default: true },
					showForGuest: { type: 'boolean', title: 'Show For Guest', default: true },
					showForUser: { type: 'boolean', title: 'Show For User', default: true },
					showOnSidebar: { type: 'boolean', title: 'Show On Sidebar', default: false },
					mobile: { type: 'boolean', title: 'Mobile', default: false },
					primary: { type: 'boolean', title: 'Primary', default: true },
					secondary: { type: 'boolean', title: 'Secondary', default: false },
					meta: { type: 'boolean', title: 'Meta', default: true },
					routeOptions: {
						type: 'object',
						title: 'Route Options',
						properties: {
							trigger: { type: 'boolean', title: 'Trigger', default: true },
							keepAdvert: { type: 'boolean', title: 'Keep Advert', default: true },
						},
					},
				},
			},
		},
		seoContent: {
			type: 'object',
			title: 'SEO Content',
			properties: {
				racing: { type: 'string', title: 'Racing' },
				sports: { type: 'string', title: 'Sports' },
				tournaments: { type: 'string', title: 'Tournaments' },
				footer: {
					type: 'object',
					title: 'Footer',
					properties: {
						extraContent: { type: 'string', title: 'Extra Content' },
						footerNote: { type: 'string', title: 'Footer Note' },
					},
				},
			},
		},
		termsAndConditionsLink: { type: 'string', title: 'Terms & Conditions Link' },
	},
};

export const aclUiSchema = {
	seoContent: {
		racing: {
			'ui:widget': 'textarea',
			'ui:options': {
				rows: 3,
			},
		},
		sports: {
			'ui:widget': 'textarea',
			'ui:options': {
				rows: 3,
			},
		},
		tournaments: {
			'ui:widget': 'textarea',
			'ui:options': {
				rows: 3,
			},
		},
		footer: {
			extraContent: {
				'ui:widget': 'textarea',
				'ui:options': {
					rows: 3,
				},
			},
			footerNote: {
				'ui:widget': 'textarea',
				'ui:options': {
					rows: 3,
				},
			},
		},
	},
};
