const { batchActions } = require('redux-batched-actions');
import { createNamedAction, createNamedActions } from '../../common/actions/createNamedActions';
import * as betPlacementActions from './betPlacementActions';
import { PLACE_MULTI_BET } from './betPlacementReducerNames';
import { buildSelectionsForRequest } from '../../common/BetPlacement';
import { toggleMultiBetSlip } from '../../store/application/applicationActions';

/**
 * Validate the selections for the multi and handle the response
 *
 * @param reducerName
 * @param validateSingles
 * @param selections
 * @private
 */
const _validate = (reducerName = PLACE_MULTI_BET, validateSingles = true, selections) => (dispatch, getState) => {
	if (!selections) {
		const state = getState()[reducerName];
		selections = state.selections;
	}
	if (selections && selections.length) {
		const selectionsForValidation = buildSelectionsForRequest(selections, validateSingles);
		if (selectionsForValidation.length) {
			betPlacementActions
				.validateMulti(selectionsForValidation)
				.then((response) => {
					dispatch(betPlacementActions.handleMultiBetResponse(reducerName, response));
				})
				.catch((error) => {
					// dispatch(betPlacementActions.handleMultiBetResponse(reducerName, error.response));
				});
		}
	}
};

/**
 * Adds a formatted selection to the collection and recalculate legs
 *
 * @param reducerName
 * @param betSelection
 * @param validate
 */
const _addMultiBetSelection = (reducerName = PLACE_MULTI_BET, betSelection, validate = true) => (
	dispatch,
	getState,
) => {
	const { bets, selections, maximumSelections } = getState()[reducerName];
	let error;
	if (
		selections.find(
			(element) =>
				element.id === betSelection.id &&
				(element.product_id === betSelection.product_id || element.price_id === betSelection.product_id) &&
				element.bet_type === betSelection.bet_type,
		)
	) {
		error = 'Selection already exists in bet slip';
		dispatch(betPlacementActions.setError(reducerName, error));
	} else if (selections.length === maximumSelections) {
		error = 'Maximum number of selections reached';
		dispatch(betPlacementActions.setError(reducerName, error));
	} else {
		if (bets.length) {
			// Clean up the bet slip
			// error = 'Please clear the bet slip before adding more selections';
			// dispatch(openNotification(error, 'danger'));
			// dispatch(betPlacementActions.setError(reducerName, error));
			dispatch(betPlacementActions.reset(reducerName));
		}

		dispatch(
			batchActions([
				betPlacementActions.setError(reducerName),
				betPlacementActions.addSelection(reducerName, betSelection, null),
			]),
		);

		if (validate) {
			//dispatch(_validate(reducerName));
		}

		return Promise.resolve();
	}

	return Promise.reject(error);
};

/**
 * Decide which bet type selection we are processing
 *
 * @param reducerName
 * @param multiSelection
 * @param type
 * @param validate
 */
//add harsha
const _addMultiSelection = (reducerName = PLACE_MULTI_BET, multiSelection, type, validate = true) => (dispatch) => {
	const betSelection = {
		...multiSelection,
		type,
	};
	return dispatch(_addMultiBetSelection(reducerName, betSelection, validate), dispatch(toggleMultiBetSlip(false)));
};

/**
 * Remove a single selections from the multi slip
 *
 * @param reducerName
 * @param selectionId
 * @param productId
 * @private
 */
const _removeSingleSelection = (reducerName = PLACE_MULTI_BET, selection) => (dispatch, getState) => {
	dispatch(
		batchActions([
			betPlacementActions.setError(reducerName),
			betPlacementActions.removeSelection(reducerName, selection),
		]),
	);
	dispatch(betPlacementActions.rebuildLegs(reducerName));
	dispatch(_validate(reducerName, true, getState()[reducerName].selections));
};

/**
 * Remove a update a single selections from the multi slip
 *
 * @param reducerName
 * @param selectionId
 * @private
 */
const _updateSingleSelection = (reducerName = PLACE_MULTI_BET, selection, validate = true) => (dispatch, getState) => {
	const betSelection = {
		...selection,
	};

	dispatch(
		batchActions([
			betPlacementActions.setError(reducerName),
			betPlacementActions.replaceSelection(reducerName, betSelection),
		]),
	);
	dispatch(betPlacementActions.rebuildLegs(reducerName));
	dispatch(_validate(reducerName, true, getState()[reducerName].selections));
};

/**
 * Update the stake and amount for a leg selection
 *
 * @param reducerName
 * @param legId
 * @param stake
 * @private
 */
const _updateLegStake = (reducerName = PLACE_MULTI_BET, legId, stake = 0) => (dispatch, getState) => {
	const legs = getState()[reducerName].legs;
	const leg = legs.find((l) => l.id === legId);
	if (leg) {
		const newLeg = {
			...leg,
			stake,
			amount: stake * leg.numberOfBets,
		};
		return dispatch(betPlacementActions.replaceLeg(reducerName, newLeg));
	}
};

/**
 * Create the named actions for all of the default bet placement actions
 *
 * @type {function(...[*]): *}
 */
const namedActions = createNamedActions(PLACE_MULTI_BET, betPlacementActions);

export const addMultiSelection = createNamedAction(PLACE_MULTI_BET, _addMultiSelection);
export const addMultiBetSelection = createNamedAction(PLACE_MULTI_BET, _addMultiBetSelection);
export const removeSingleSelection = createNamedAction(PLACE_MULTI_BET, _removeSingleSelection);
export const updateSingleSelection = createNamedAction(PLACE_MULTI_BET, _updateSingleSelection);
export const updateLegStake = createNamedAction(PLACE_MULTI_BET, _updateLegStake);
export const validate = createNamedAction(PLACE_MULTI_BET, _validate);
export const {
	clearBets,
	addBet,
	setBets,
	addExoticBet,
	addMultiBet,
	addSportBet,
	addSingleBet,
	setLegs,
	addLeg,
	removeLeg,
	replaceLeg,
	setSelections,
	addSelection,
	addExoticSelection,
	addFixedSelection,
	addSportSelection,
	addToteSelection,
	removeSelection,
	replaceSelection,
	changeSelection,
	update,
	setBoxedFlag,
	setOrigin,
	useBonusBet,
	reset,
	setError,
	validateSelections,
	validateExotic,
	placeBet,
	placeSportBet,
	placeRacingBet,
	placeMultiBet,
	handleMultiBetsPlaced,
	rebuildLegs,
} = namedActions;
