import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PusherSubscribe from '../../../controllers/Pusher/PusherSubscribe';
import { PUSHER_EVENT_UPDATE } from '../../../../common/constants/Racing';
import { mergeRaces } from '../../../../store/entities/actions/RaceActions';
import { cleanRacingPusherPayload } from '../../../../containers/Racing/RacingHelpers';
import { updateMeetingsDeepCompare } from '../../../../store/entities/actions/MeetingActions';

/**
 * Pusher Subscriber Container for Race.
 * Channel: meeting_<meetingId>
 */

class PusherSubscriberForMeeting extends React.Component {
	static propTypes = {
		/** The meeting ID to subscribe to */
		meetingId: PropTypes.number.isRequired,

		/** Action to update the meeting */
		updateMeeting: PropTypes.func,

		/** Action to update the races in the meeting */
		updateRaces: PropTypes.func,
	};

	static defaultProps = {
		updateMeeting: () => {},
		updateRaces: () => {},
	};

	/**
	 * Handles pushed data to update meeting. Possible payloads:
	 *     1) Updates to its races:
	 *          {id: number,
	 *            races: []
	 *          }
	 *
	 *     2) Updates to it own data:
	 *          {id, ,name, state, track, [...] }
	 *
	 *
	 * @param data
	 */
	onPusherUpdate = (data) => {
		let meeting = cleanRacingPusherPayload(data);

		// Update data for races separately because the data comes fragmented
		if (Array.isArray(meeting.races)) {
			let races = meeting.races;
			delete meeting.races;

			races = races.map((race) => {
				return cleanRacingPusherPayload(race);
			});
			this.props.updateRaces(races);
		}

		// Handle cases where there is data for the meeting to update
		if (Object.keys(meeting).length > 2) {
			this.props.updateMeeting(meeting);
		}
	};

	render() {
		let { meetingId } = this.props;

		// Determine channel for all subscriptions
		let channel = `meeting_${meetingId}`;

		return <PusherSubscribe channel={channel} event={PUSHER_EVENT_UPDATE} onUpdate={this.onPusherUpdate} />;
	}
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
	updateRaces: (races) => dispatch(mergeRaces(races)),
	updateMeeting: (meeting) => dispatch(updateMeetingsDeepCompare(meeting)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PusherSubscriberForMeeting);
