/**
 * Created by Josef Frank on 2017/04/06.
 */
'use strict';
// Functions
import {get} from '../../../common/Ajax';
import {normalizeTrendingBets} from '../schemas/TrendingBetSchema';
import {replaceEntities} from '../../../common/actions/actionHelpers';

/**
 * Fetches trending bets.
 *
 * @returns {Function}
 */
export const fetchTrendingBets = () => (dispatch) => {
	return get('trending-bets')
		.then((response) => {
			let massagedData = response.data.data.map((trendingBet, id) => {
				return {
					...trendingBet,
					id,
				};
			});

			dispatch(replaceEntities(normalizeTrendingBets(massagedData).entities));
		});
};
