// Constants
const {
	DATA_PAGINATOR_SET_INITIAL_DATA,
	DATA_PAGINATOR_SET_DATA,
	DATA_PAGINATOR_SET_HEADERS,
	DATA_PAGINATOR_SET_LOADING,
	DATA_PAGINATOR_SET_SIZE,
	DATA_PAGINATOR_SET_PAGE,
	DATA_PAGINATOR_FILTERED_DATA,
	DATA_PAGINATOR_SET_TOTAL,
	DATA_PAGINATOR_SET_TOTAL_PAGES,
	DATA_PAGINATTOR_PAGE_NAME,
} = require('./dataPaginatorActionTypes');

/**
 * Initial state
 */
const initialState = {
	loading: false,
	size: 15,
	page: 1,
	totalPages: null,
	total: null,
	data: [],
	filteredData: [],
	headers: [],
	pageName: null,
};

/**
 * Data paginator reducer
 *
 * @param state
 * @param action
 * @returns {*}
 */
const dataPaginator = (state = initialState, action) => {
	switch (action.type) {
		case DATA_PAGINATOR_SET_INITIAL_DATA:
			return {
				...state,
				loading: action.payload.loading,
				size: action.payload.size,
				page: action.payload.page,
				data: action.payload.data,
				total: action.payload.total,
				totalPages: action.payload.totalPages,
				filteredData: action.payload.filteredData,
				headers: action.payload.headers,
			};

		case DATA_PAGINATOR_SET_DATA:
			return {
				...state,
				data: action.payload,
			};

		case DATA_PAGINATOR_SET_HEADERS:
			return {
				...state,
				headers: action.payload,
			};

		case DATA_PAGINATOR_SET_LOADING:
			return {
				...state,
				loading: action.payload,
			};

		case DATA_PAGINATOR_SET_SIZE:
			return {
				...state,
				size: action.payload,
			};

		case DATA_PAGINATOR_SET_PAGE:
			return {
				...state,
				page: action.payload,
			};

		case DATA_PAGINATOR_SET_TOTAL:
			return {
				...state,
				total: action.payload,
			};

		case DATA_PAGINATOR_SET_TOTAL_PAGES:
			return {
				...state,
				totalPages: action.payload,
			};

		case DATA_PAGINATOR_FILTERED_DATA:
			return {
				...state,
				filteredData: action.payload,
			};

		case DATA_PAGINATTOR_PAGE_NAME:
			return {
				...state,
				pageName: action.payload,
			};
		default:
			return state;
	}
};

export default dataPaginator;
