import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { Checkbox } from '@tbh/ui-kit';

const StyledComputerTipsHeaderFilter = styled('div')(
	(props) => css`
		label: StyledComputerTipsHeaderFilter;
		width: 100%;
		display: flex;
	`,
);

const StyledCheckbox = styled(Checkbox)(`
    border-radius: 4px;
`);

const ComputerTipsHeader = (props) => {
	const { t, className, onFilterItemClick, computerTips, raceFilter, size, tip } = props;

	const componentClasses = cx({
		[className]: className,
	});

	const isActive = (computerTips = [], type) => {
		return computerTips.includes(type);
	};

	const handleTipsClick = () => {
		const { tip } = props;
		onFilterItemClick(tip);
	};

	return (
		<StyledComputerTipsHeaderFilter className={componentClasses}>
			<div
				className={css`
					display: flex;
					padding: 0 4px;
					gap: 4px;
					align-items: center;
					width: fit-content;
				`}
				onClick={handleTipsClick}
			>
				<StyledCheckbox
					name={tip}
					id={tip}
					value={tip}
					//action={this.showAllRaces}
					action={handleTipsClick}
					checked={isActive(computerTips, tip)}
					disabled={false}
				/>
				<label
					htmlFor={tip}
					onClick={handleTipsClick}
					className={css`
						font-size: 10px;
						margin: 0;
						cursor: pointer;
						font-weight: bold;
					`}
				>
					{tip}
				</label>
			</div>
		</StyledComputerTipsHeaderFilter>
	);
};

ComputerTipsHeader.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func,

	/** Action to handle when a filter is clicked on */
	onFilterItemClick: PropTypes.func.isRequired,

	/** List of different  types */
	computerTips: PropTypes.array,

	/** Extra classes */
	className: PropTypes.string,

	raceFilter: PropTypes.array.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}),

	tip: PropTypes.string.isRequired,
};

ComputerTipsHeader.defaultProps = {
	types: [],
	className: undefined,
	size: undefined,
	t: undefined,
};

export default withNamespaces()(ComputerTipsHeader);
