// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { connect } from 'react-redux';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { ui, spacings, brand, Image, Link, OverlayMessage } from '@tbh/ui-kit';

// Actions
import {

	routeTo,
	hideSidebar,
} from '../../../store/application/applicationActions';
import { navigateToNextToJumpRaceWithType, } from '../../../store/GRSracingHome/GRSracingHomeActions';
import FooterNote from '../../../components/features/Application/Footer/FooterNote/FooterNote';

// Constants
import {
	NEXT_TO_JUMP_RACING,
	NEXT_TO_JUMP_SPORTS,
	NEXT_TO_JUMP_TOURNAMENTS,
	SIDEBAR_STATE_NAVIGATION,
	SIDEBAR_STATE_SETTINGS,
} from '../../../common/constants/SidebarConstants';

import { navigateToMeetingsWithTypeAndDate } from './../../../store/GRSracingHome/GRSracingHomeActions';
import GRSSidebarNavigation from './../../../components/features/Application/Sidebar/GRSSidebarNavigation/GRSSidebarNavigation';

const StyledSidebarContainer = styled('div')(
	(props) => css`
		label: SidebarContainer;
		display: none;
		transition: all 0.25s linear;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
		position: fixed;
		z-index: 2022;
		${props.isOpen &&
		css`
				display: block;
			`};
	`,
);

const StyledSidebarNavigation = styled(GRSSidebarNavigation)(
	(props) => css`
		label: SidebarNavigation;
		@keyframes slideIn {
				from {
					transform: translateX(-100%);
				}
				to {
					transform: translateX(0);
				}
			}
		transition: 0.25s;
		${props.isOpen && 'animation: slideIn 0.25s linear;'}

`);


const cssSidebarContainer__Logo = css`
	label: SidebarContainer__Logo;

	flex: 0 0 135px;
	min-width: 135px;
	max-height: 70px;
`;

class SidebarContainer extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** extra classes */
		className: PropTypes.string,

		/** Get the Next Races To Jump */
		fetchNextToJumpRaces: PropTypes.func.isRequired,

		/** Fetch the entire race for a Next To Jump selection */
		//fetchRaceForNextToJump: PropTypes.func.isRequired, // tempary comment by @HW04Nov2020

		/** Get the Next Sports To Jump */
		fetchNextToJumpSports: PropTypes.func.isRequired,

		/** Get the Next Tournaments To Jump */
		fetchNextToJumpTournaments: PropTypes.func.isRequired,

		/** The business brand name */
		brandName: PropTypes.string.isRequired,

		/** Handles navigation to the page selected in the sidebar */
		navigateToPage: PropTypes.func.isRequired,

		/** Navigates to the selected race page with date and type*/
		navigateToRaceWithType: PropTypes.func.isRequired,

		/** Navigates to the selected sport event page */
		navigateToSport: PropTypes.func.isRequired,

		/** Navigates to the selected tournament page */
		navigateToTournament: PropTypes.func.isRequired,

		/** Navigation items */
		navigationItems: PropTypes.array.isRequired,

		/** Add the selected race price to the multi bet slip */
		onAddRacingBetToMulti: PropTypes.func.isRequired,

		/** Add the sport selection to the multi bet slip */
		onAddSportBetToMulti: PropTypes.func.isRequired,

		/** Close the sidebar */
		onCloseSidebar: PropTypes.func.isRequired,

		/** Remove the bet selection from the multi bet slip */
		onRemoveMultiBetSelection: PropTypes.func.isRequired,

		/** Set/Update the settings for showing the Next to Jump race, sport or tournament on the sidebar navigation */
		onSetSettingsNTJ: PropTypes.func.isRequired,

		/** Set the updateNextToJumpData to false */
		onStopSidebarUpdate: PropTypes.func.isRequired,

		/** GA tracking */
		trackGaEvent: PropTypes.func.isRequired,

		/** List of available icons to use */
		brandLogos: PropTypes.object.isRequired,

		/** If it's the cordova app or not */
		isCordova: PropTypes.bool,

		/** Next to jump racing items */
		//nextToJumpRacingItems: PropTypes.array, // tempary comment by @HW04Nov2020

		/** Next to jump racing items */
		nextToJumpSportItems: PropTypes.array,

		/** Next to jump racing items */
		nextToJumpTournamentItems: PropTypes.array,

		/** Show the Next to Jump Card for racing, sport or tournament */
		showNextToJump: PropTypes.oneOf([NEXT_TO_JUMP_RACING, NEXT_TO_JUMP_SPORTS, NEXT_TO_JUMP_TOURNAMENTS]),

		/**
		 * If the Sidebar is visible or not
		 */
		showSidebar: PropTypes.bool,

		/**
		 * If the NTJ data needs to be fetched again.
		 * This is used when the component is already mounted and needs to update.
		 */
		updateNextToJumpData: PropTypes.bool,

		/** Gambling message from the acl */
		gamblingMessage: PropTypes.string,

		// eslint-disable-next-line react/require-default-props
		activeItem: PropTypes.func, //'account',

		gallopsMetingGroups: PropTypes.array.isRequired,

		harnessMetingGroups: PropTypes.array.isRequired,

		greyhoundsMeetingGroups: PropTypes.array.isRequired,

		currentRoute: PropTypes.string.isRequired,

		/** Next to jump race object */
		nextNtjRace: PropTypes.shape({
			id: PropTypes.number,
			meeting_id: PropTypes.number,
		}),
		/** Undefined for today's races. Type*/

		selectedType: PropTypes.string,
	};

	static defaultProps = {
		className: '',

		//nextToJumpRacingItems: [], // tempary comment by @HW04Nov2020
		nextToJumpSportItems: [],
		nextToJumpTournamentItems: [],
		showNextToJump: NEXT_TO_JUMP_RACING,
		showSidebar: false,
		updateNextToJumpData: false,
		gamblingMessage: null,
		isCordova: false,
		nextNtjRace: undefined,
		selectedType: null,
		sideBarData: {
			id: '',
			children: [],
		},
	};

	constructor(props) {
		super(props);

		this.state = {
			// loading mask for the Next to Jump on the settings view
			loadingNTJSettings: false,
			// loading mask for the Next to Jump on the navigation view
			loadingNTJNavigation: false,
			showNavigationOrSettings: SIDEBAR_STATE_NAVIGATION,
			touchStart: null,
			touchEnd: null
		};

		this.onTouchMoveSideBar = this.onTouchMoveSideBar.bind(this);
		this.onTouchStartSideBar = this.onTouchStartSideBar.bind(this);
		this.onTouchEndSideBar = this.onTouchEndSideBar.bind(this);
	}


	/**
	 * Navigates to the selected race
	 *
	 * @param raceId
	 * @param meetingId
	 */
	handleGoToRace = (type, date, raceId, meetingId) => {
		raceId && meetingId
			? this.props.navigateToRaceWithType(type, date, raceId, meetingId)
			: type
				? this.props.navigateToMeetingWithType(date, type)
				: this.props.navigateToMeetingsWitDate(date);
	};

	/**
	 * Navigates to the selected sport
	 *
	 * @param eventId
	 * @param competitionId
	 */
	handleGoToSport = (eventId, competitionId) => {
		this.props.navigateToSport(eventId, competitionId);
	};

	/**
	 * Navigates to the selected tournament
	 *
	 * @param tournamentId
	 */
	handleGoToTournament = (tournamentId) => {
		this.props.navigateToTournament(tournamentId);
	};

	/**
	 * When a settings option is selected request and show the Next to Jump items for the selected option
	 *
	 * @param nextToJumpType
	 */
	handleSettingsOptionSelect = (nextToJumpType) => {
		this.fetchNextToJumpData(nextToJumpType, SIDEBAR_STATE_SETTINGS);
	};

	/**
	 * Update the settings for showing the Next to Jump race, sport or tournament on the sidebar navigation
	 *
	 * @param nextToJumpType
	 */
	handleSaveSettings = (nextToJumpType) => {
		this.props.onSetSettingsNTJ(nextToJumpType);
		this.showNavigation();
	};

	/**
	 * Shows the settings view
	 */
	showSettings = () => {
		this.setState({
			showNavigationOrSettings: SIDEBAR_STATE_SETTINGS,
		});
		this.props.trackGaEvent('Settings');
	};

	/**
	 * Shows the navigation view
	 */
	showNavigation = () => {
		this.setState({
			showNavigationOrSettings: SIDEBAR_STATE_NAVIGATION,
		});
	};
	handleGoToRace = (type, date, raceId, meetingId) => {
		raceId && meetingId
			? this.props.navigateToRaceWithType(type, date, raceId, meetingId)
			: this.props.navigateToMeetingWithType(date, type);
		this.props.onCloseSidebar();
	};

	handleCloseSidebar = (e) => {
		e.preventDefault();
		this.props.onCloseSidebar();
	};

	sidebarTitle = () => {
		let title = '';
		// }
		if (this.props.brandLogos.main) {
			title = (
				<Link action={this.handleCloseSidebar} className={cx(cssSidebarContainer__Logo)}>
					<Image width={135} src={this.props.brandLogos.main} alt={this.props.t('Logo')} />
				</Link>
			);
		}
		return title;
	};

	handleItemClick = (e, { name }) => this.setState({ activeItem: name });

	onTouchMoveSideBar(e) {
		this.setState({ touchEnd: e.targetTouches[0].clientX });
	}

	onTouchStartSideBar(e) {
		this.setState({ touchEnd: null });
		this.setState({ touchStart: e.targetTouches[0].clientX });
	}

	onTouchEndSideBar() {
		const minSwipeDistance = 50;
		if (!this.state.touchStart || !this.state.touchEnd) return;
		const distance = this.state.touchStart - this.state.touchEnd;
		const isLeftSwipe = distance > minSwipeDistance;
		//const isRightSwipe = distance < -minSwipeDistance
		if (isLeftSwipe) this.props.onCloseSidebar();
		//if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')
	}

	render() {
		const { showSidebar, sideBarData } = this.props;
		return (
			<StyledSidebarContainer
				isOpen={showSidebar}
				onTouchMove={this.onTouchMoveSideBar}
				onTouchStart={this.onTouchStartSideBar}
				onTouchEnd={this.onTouchEndSideBar}
			>
				<StyledSidebarNavigation
					isOpen={showSidebar}
					navigateToPage={this.props.navigateToPage}
					onGoToRace={this.handleGoToRace}
					sideBarData={sideBarData}
					footerLists={this.props.footerLists}
				/>

				<OverlayMessage
					className={css`
						cursor: default;
					`}
					action={this.handleCloseSidebar}
					open
				/>
			</StyledSidebarContainer>
		);
	}
}

const mapStateToProps = (state) => {

	let today_race = state.sideBarData.today;
	today_race['link'] = '/racing-v3';

	let tomorrow = state.sideBarData.tomorrow;
	tomorrow['link'] = '#';

	let dayAtomorrow = state.sideBarData.dayAfterTomorrow;
	dayAtomorrow['link'] = '#';

	let previousResults = state.sideBarData.previousResults;
	previousResults['link'] = '#';


	let sideBarData = { today: today_race, tomorrow: tomorrow, dayAfterTomorrow: dayAtomorrow , previousResults: previousResults};

	return {
		showSidebar: state.application.showSidebar,
		sideBarData,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		navigateToPage: (route, label) => {
			dispatch(routeTo(route));
			dispatch(hideSidebar());
		},
		navigateToRaceWithType: (type, date, raceId, meetingId) => {
			dispatch(navigateToNextToJumpRaceWithType(type, date, raceId, meetingId));
			App.startSubApp('RacingNewPage');
			dispatch(hideSidebar());
		},

		navigateToMeetingWithType: (date, type) => {
			dispatch(navigateToMeetingsWithTypeAndDate(date, type));
			App.startSubApp('RacingNewPage');
			dispatch(hideSidebar());
		},
		onCloseSidebar: () => {
			dispatch(hideSidebar());
		},
	};
};
export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(SidebarContainer));
