import { createSelector } from 'reselect';
import { getAuthenticatedUser } from '../../store/application/applicationSelectors';
import { denormalizeBankAccounts } from '../../store/entities/schemas/BankAccountSchema';
import { denormalizeWithdrawals } from '../../store/entities/schemas/WithdrawalSchema';

/**
 * Get data from user entity and return available balance to withdraw.
 */
export const getWithdrawableBalance = createSelector(getAuthenticatedUser, (user) => {
	const {
		account_balance: accountBalance,
		// balance_to_turnover comes from the api as balance_to_turnover + free_credit_wins_to_turnover
		balance_to_turnover: balanceToTurnover,
	} = user;

	return Math.max(0, accountBalance - balanceToTurnover);
});

/**
 * Get bank accounts
 */
export const getBankAccounts = createSelector(
	(state) => state.entities,
	(entities) => {
		return denormalizeBankAccounts(entities).sort((accA, accB) => accA.id - accB.id);
	},
);

/**
 * Get withdrawal entity created in withdrawal process
 */
export const getCreatedWithdrawal = createSelector(
	(state) => state.withdrawalPrompt.withdrawalReceiptId,
	(state) => state.entities,
	(createdWithdrawal, entities) => {
		return denormalizeWithdrawals(entities, createdWithdrawal);
	},
);
