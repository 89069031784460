import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import ReactModal from 'react-modal';
import styled, { css } from 'react-emotion';

import { typography, ui } from '@tbh/ui-kit';
import ModalHeader from '../../features/Application/ModalHeader/ModalHeader';
import ModalFooter from '../../features/Application/ModalFooter/ModalFooter';
import BrandContactPhone from '../../ui-components/BrandContactPhone/BrandContactPhone';
import { BREAKPOINTS } from '../../../constants/themes';


const StyledModal__Inner = styled('div')(
	(props) => css`
		label: Modal__Inner;

		position: relative;
		// height: 100%;
		width: 100%;
		font-family: ${typography(props).base_font};
		font-size: ${typography(props)['size_-1']};
		font-feature-settings: 'kern', 'liga', 'pnum';
		-webkit-font-smoothing: antialiased;
		line-height: ${typography(props).base_line_height};
		color: ${typography(props).base_color};
		background-color: ${ui(props).color_2};
		border-radius: 4px;
		ul,
		ol {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}

		${props.fullHeight &&
		css`
				display: flex;
				flex-direction: column;
				height: 100%;
			`};
	`,
);

const cssModalClosed = css`
	label: Modal;

	display: none;
`;

const cssModalOpen = css`
	display: none;

	&:last-child {
		display: block;
	}
`;

const cssModal__Overlay = css`
	label: Modal__Overlay;

	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	position: fixed;
	z-index: 99999;
	background-color: rgba(0, 0, 0, 0.85);
	overscroll-behavior: contain;
	-webkit-overflow-scrolling: touch !important;
	// LEAVE THIS HERE
	overflow-y: auto !important;
	display: flex;
	justify-content: center;
	align-items: start;
`;

const cssModal__Content = css`
	label: Modal__Content;

	width: 100%;
	margin: 0 auto;
	outline: none;
	padding: 20px 8px;
	display: flex;

	@media (max-width: ${BREAKPOINTS.laptopMin}px) {
		margin-top: env(safe-area-inset-top);
	}

	@media (min-width: 480px) {
		max-width: 480px;
	}
`;

const cssModal__Content_Wide = css``;

/**
 * Our Modals will be appended to the bottom of this element
 *
 * @returns {HTMLElement}
 */
const rootSelector = (elementId = 'root') => document.getElementById(elementId);

class Modal extends React.Component {
	static setAppElement(element) {
		if (element) {
			ReactModal.setAppElement(element);
		}
	}
	static propTypes = {
		/** Unique key for the modal */
		componentKey: PropTypes.string.isRequired,

		/** http://reactcommunity.org/react-modal/accessibility/ - Only set to false for storybook/jest */
		ariaHideApp: PropTypes.bool,

		/** Whether or not the modal is open */
		open: PropTypes.bool,

		/** The children to display in the modal */
		children: PropTypes.node,

		/** Config options for the modal */
		config: PropTypes.shape({
			/** Display the modal larger than mobile size */
			wide: PropTypes.bool,

			/** Expand the modal to be 100% of screen height */
			fullHeight: PropTypes.bool,

			/** Title to display in the modal header (will render ModalHeader) */
			title: PropTypes.any,

			/** Show the encryption footer */
			showEncryptionMessage: PropTypes.bool,
		}),

		/** Handler for closing the modal */
		onClose: PropTypes.func,

		/** Selector for where to render the modal into */
		parentSelector: PropTypes.func,

		/** The app element to bind the modal too */
		appElement: PropTypes.string,
	};

	static defaultProps = {
		ariaHideApp: true,
		children: null,
		config: {},
		open: false,
		onClose: () => { },
		parentSelector: rootSelector,
		appElement: '#root',
	};



	constructor(props) {
		super(props);

		// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
		// This won't work in widget context
		let { ariaHideApp, appElement, parentSelector } = props;
		if (ariaHideApp) {
			const AppElement = parentSelector();
			if (AppElement) {
				Modal.setAppElement(appElement);
			} else {
				ariaHideApp = false;
			}
		}

		this.state = {
			ariaHideApp,
		};

	}

	/**
	 * Close the modal
	 */
	closeModel = () => {
		this.props.onClose(this.props.componentKey);
	};

	componentDidMount() {
		if (open) {
			document.body.style.overflowY = 'hidden';
		}
	}

	componentWillUnmount() {
		document.body.style.overflowY = 'auto';
	}

	render() {
		const { ariaHideApp } = this.state;

		/**
		 * If ariaHideApp is set to false then it means there is no #root element.
		 * This means that we are in widget context and we don't want modals to display.
		 */
		if (!ariaHideApp) {
			return null;
		}

		const { componentKey, open, children, config, parentSelector } = this.props;

		// The internal modal wrapper
		const contentComponentClasses = cx(cssModal__Content, config.wide ? cssModal__Content_Wide : null);


		return (
			<ReactModal
				key={componentKey}
				parentSelector={parentSelector}
				ariaHideApp={ariaHideApp}
				bodyOpenClassName={css`
					// Needed to prevent underscroll in Cordova
					-webkit-overflow-scrolling: auto !important;
				`}
				portalClassName={open ? cssModalOpen : cssModalClosed}
				className={contentComponentClasses}
				overlayClassName={cssModal__Overlay}
				isOpen={open}
				onRequestClose={this.closeModel}
				shouldCloseOnOverlayClick={false}
			>
				<StyledModal__Inner fullHeight={config.fullHeight}>
					{config.title && (
						<ModalHeader
							onClose={config.hideClose ? null : this.closeModel}
							title={config.title}
							aside={<BrandContactPhone />}
						/>
					)}
					{children}
					{config.showEncryptionMessage && <ModalFooter />}
				</StyledModal__Inner>
			</ReactModal>
		);
	}
}

export default Modal;
