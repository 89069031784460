import React from 'react';
import cx from 'classnames/bind';
import { SizeMe } from 'react-sizeme';
import RaceHeaderBarLoading from '../../../components/features/Racing/RaceHeaderBar/RaceHeaderBarLoading';
import RaceNavLoading from './../../../components/features/Racing/RaceNav/RaceNavLoading';
import RaceCardContainerLoading from './../RaceCardContainer/RaceCardContainerLoading';

class MeetingContainerLoading extends React.Component {
	render() {
		const { className } = this.props;

		const containerClasses = cx({
			[className]: className,
		});

		const config = {
			freeMode: true,
			freeModeMomentumBounce: false,
			freeModeMomentumRatio: 0.35,
			paginationClickable: true,
			slidesPerView: 'auto',
			spaceBetween: 0,
		};

		return (
			<SizeMe>
				{({ size }) => (
					<div className={containerClasses} style={{ marginTop: size.width <= 500 ? '12px' : '0' }}>
						<div>
							<RaceHeaderBarLoading size={size} />

							<RaceNavLoading
								displayCarousel
								carouselActivationLength={8}
								carouselConfig={config}
								size={size}
							/>

							<RaceCardContainerLoading size={size} />
						</div>
					</div>
				)}
			</SizeMe>
		);
	}
}

export default MeetingContainerLoading;
