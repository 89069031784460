import { createSelector } from 'reselect';
import { get, post } from '../../../common/Ajax';
import { resetEntities, mergeEntities } from '../../../common/actions/actionHelpers';
import { denormalizeCreditCards, normalizeCreditCards } from '../schemas/CreditCardSchema';
import moment from 'moment';
import { REMOVE_ENTITIES } from '../EntityActionTypes';

/**
 * Pure selector for retrieving user's credit cards
 */
export const getCreditCards = createSelector((entities) => entities, (entities) => denormalizeCreditCards(entities));

/**
 * Fetch user's credit cards, select the not expired ones and store.
 * @param params
 */
export const fetchCreditCards = (params) => (dispatch) => {
	return get('eway-tokens', { params }).then((response) => {
		const validCards = response.data.data.filter((card) => {
			/**
			 * Subtracting one month due to month indexation starting from 0.
			 */
			const expiryMonth = +card.ExpiryMonth - 1;
			const expiryYear = +('20' + card.ExpiryYear);
			/**
			 * Adding 1 month to validate current month.
			 */
			const expiryDate = moment([expiryYear, expiryMonth]).add(1, 'month');
			return expiryDate.isAfter();
		});

		/**
		 * If there are no credit cards found, clear all of the ones in the entities slice
		 */
		const creditCardEntities = normalizeCreditCards(validCards).entities;
		if (!creditCardEntities.creditCards) {
			creditCardEntities.creditCards = {};
			dispatch(resetEntities(creditCardEntities));
		} else {
			dispatch(mergeEntities(creditCardEntities));
		}

		return response.data.data;
	});
};

export const setLastUsedCardOnLocalStorage = (name, lastFour) => {
	const cardDetails = JSON.stringify({
		name: name,
		number: lastFour,
	});
	// Save to localStorage -- try/catch for iOS compatibility
	try {
		localStorage.setItem('last_used_card', cardDetails);
	} catch (e) {
		console.warn('Local storage is disabled on this device');
	}
};

export const getLastUsedCardFromLocalStorage = (creditCards = []) => {
	const cards = typeof creditCards === 'object' ? denormalizeCreditCards({ creditCards }) : creditCards;

	// Try to load previously saved card if possible
	const lastCard = JSON.parse(localStorage.getItem('last_used_card'));
	if (lastCard) {
		// Search for card in list of available ones
		const foundCard = cards.find((card) => {
			return card.Name === lastCard.name && card.Number.endsWith(lastCard.number);
		});

		// If found, set default card to that card
		if (foundCard) {
			return foundCard.id;
		}
	}

	// Default to the first card if no last used one found, or null
	const firstCard = cards[0];
	return firstCard ? firstCard.id : null;
};

/**
 * Sends request to delete credit card token.
 *
 * @param cardId
 * @return {Promise}
 */
export const deleteCreditCardToken = (cardId) => (dispatch) => {
	return post('eway-tokens-delete', { token: cardId }).then(() => {
		dispatch({
			type: REMOVE_ENTITIES,
			entities: normalizeCreditCards([{ id: cardId }]).entities,
		});
		return dispatch(fetchCreditCards());
	});
};
