import { application, Button, ButtonGroup, media, spacings, typography } from '@tbh/ui-kit';
import styled, { css } from 'react-emotion';
import { COLORS, FAMILYS, TYPE_SCALE, BREAKPOINTS } from '../../../../constants/themes';

export const StyledMenuButtonGroup = styled(ButtonGroup)(
	() => css`
		label: MenuButtonGroup;

		width: 100%;
		display: grid;
    	grid-auto-flow: column;
    	grid-gap: 8px;
		padding: 4px 0;

		@media (max-width: ${BREAKPOINTS.laptopMin}px) {
			display: none;
		}
	`,
);

export const StyledTopMenuButton = styled(Button)`
	${(props) =>
		css`
			label: TopMenuButton;


			${props.isActive ?
				`
			background: ${COLORS.red[500]};
			border-color: ${COLORS.red[500]};
			color: ${COLORS.white};
			`
				:
				`
			background: ${COLORS.gray[300]};
			border-color: ${COLORS.gray[300]};
			color: ${COLORS.white};
			`
			}


			min-width: ${application(props).racing.button_width}px;
			padding: ${spacings(props).compact}px;
			max-height: 36px;
			margin-top: ${spacings(props).compact}px;
			font-family: ${FAMILYS.primary};
			border-radius:4px;
			&:hover {
				${props.disable && props.disable != undefined ? `
    			background: ${COLORS.red[600]};
				border-color: ${COLORS.red[600]};
				` : `
				color: ${typography(props).color_1_alternate};
				`
			}
			}

			${props.disable && props.disable != undefined && `
				background: ${COLORS.tertiary};
				border-color: ${COLORS.tertiary};
			`}
			font-size: ${TYPE_SCALE.helpText};
		`};

	${media(
				(props) => css`
			max-height: 52px;
			min-width: ${application(props).racing.button_width_expanded}px;
		`,
				500,
				{ sizeKey: 'mediaSize' },
			)};
`;
