import {sortStateSliceByName, sortStateSliceByOrderThenName} from '../../../common/SelectorHelpers';

/**
 * Receives normalized market type groups and returns array sorted by name
 *
 * @param marketTypeGroups - normalized market type groups
 * @return {Array}
 */
export const getMarketTypeGroupsSortedByName = (marketTypeGroups) => {
	return sortStateSliceByName(marketTypeGroups);
};



/**
 * Receives normalized market type groups and returns array sorted by order then name
 *
 * @param marketTypeGroups - normalized markets
 * @returns {Array}
 */
export const getMarketTypeGroupsSortedByOrderThenName = (marketTypeGroups) => {
	return sortStateSliceByOrderThenName(marketTypeGroups);
};
