// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { Trans, withNamespaces } from 'react-i18next';

// Components
import { Select, SelectOption, Text } from '@tbh/ui-kit';

// Context

const TakeABreakShort = (props) => {
	const { onSelection, t } = props;

	let short_break = [];

	// Adding an 'id' to the structure built so we can use it as the `key` in the Selection Option
	for (let i = 1; i < 31; i++) {
		short_break.push({
			label: i + ' ' + t('Day', { count: i }),
			day: i,
		});
	}
	for (let i = 1; i < 7; i++) {
		short_break.push({
			label: i + ' ' + t('Week', { count: i }),
			day: i * 7,
		});
	}

	const breaks = short_break.map((item) => (
		<SelectOption key={item.label} value={item.day} label={item.label}>
			{item.label} {t('Break')}
		</SelectOption>
	));

	return (
		<div>
			<Trans i18nKey="TakeABreakShort__Content">
				<Text size="-1" paragraph strong>
					If you take a short break from gambling on this site, this is what happens next:
				</Text>
				<Text size="-1" paragraph>
					All pending bets will stand.
				</Text>
				<Text size="-1" paragraph>
					Account balance remains unchanged. You can request a withdrawal by contacting Customer Services.
				</Text>
				<Text size="-1" paragraph>
					You will cease to receive marketing communications within 24hours.
				</Text>
				<Text size="-1" paragraph>
					You will be able to login to your account after the period of break is over.
				</Text>

				<Text size="-1" paragraph strong>
					How long do you want a short break for?
				</Text>
			</Trans>

			<Select name="break" onChange={onSelection} margin="cozy">
				{breaks}
			</Select>
		</div>
	);
};

TakeABreakShort.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Hook for when the selected value changes */
	onSelection: PropTypes.func.isRequired,
};

export default withNamespaces()(TakeABreakShort);
