import { DEACTIVATE_ACCOUNT, DEACTIVATE_ACCOUNT_APP } from '../pages/DeactivatedAccount/DeactivatedAccountConstans';
import {setBodyLoading, scrollToTop, closeSideBetSlip} from '../store/application/applicationActions';


const {dispatch, getState} = App.store;

const DeactivateAccountRouter = Backbone.Router.extend({
  routes: {
    [DEACTIVATE_ACCOUNT]: 'show',
  },
  show: function (){
    try{
      scrollToTop();
      dispatch(closeSideBetSlip(true));
      dispatch(setBodyLoading(false));
      return App.startSubApp(DEACTIVATE_ACCOUNT_APP);
    } catch (e){
        //console.log(e);
    }

  }
});

module.exports = new DeactivateAccountRouter;
