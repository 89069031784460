import React from 'react';
import { StyledSliderComponentContainer } from './PageComponentContainer.styled';

const SliderComponentContainer = ({ children, ...props }) => {
    return (
        <StyledSliderComponentContainer {...props}>
            {children}
        </StyledSliderComponentContainer>
    );
};

export { SliderComponentContainer };