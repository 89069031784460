import React from 'react';
import { Button, ButtonGroup, Icon, Input, Text } from '@tbh/ui-kit';
import { cx } from 'emotion';
import { useRef, useState } from 'react';
import { Trans, withNamespaces } from 'react-i18next';
import styled, { css } from 'react-emotion';

const MobileOPTForm = ({
	t,
	className,
	first_name,
	loadingCreateAccount,
	loadingVerifyMobile,
	mobile_number,
	verified,
	...props
}) => {
	const length = 4;
	const [otp, setOTP] = useState(new Array(length).fill(''));
	const inputsRef = useRef([]);
	let valid = otp.every((data) => data !== '');

	const handleChange = (v, index) => {
		if (isNaN(v) || v === '') return;
		setOTP(prev => [...prev.map((data, idx) => (idx === index ? v : data))]);

		// Focus next input field
		if (index !== length - 1) inputsRef.current[index + 1].focus();
		else inputsRef.current[index].blur();
	};

	const handlePaste = (e) => {
		const pasteData = e.clipboardData.getData('text').split('');
		if (pasteData.length !== length) return;

		const newOTP = [...otp.map((_, idx) => pasteData[idx] || '')];
		setOTP(newOTP);

		// Focus the last filled input
		// inputsRef.current[length - 1].focus();
	};

	const handleKeyDown = (e, index) => {
		const value = e.key;
		if (value === 'Backspace') {
			e.preventDefault();
			setOTP(prev => [...prev.map((data, idx) => (idx === index ? '' : data))]);
			if (index !== 0) inputsRef.current[index - 1].focus();
			else {
				inputsRef.current[index].blur();
			}
		} else if (value === 'ArrowLeft' && index !== 0) {
			inputsRef.current[index - 1].focus();
		} else if (value === 'ArrowRight' && index !== length - 1) {
			inputsRef.current[index + 1].focus();
		} else if (e.target.value.length === 1) {
			e.preventDefault();
			handleChange(value, index);
		} else if (value === 'Enter' && valid) {
			verifyMobile();
		}
	};

	const verifyMobile = async () => {
		props.verifyMobile(otp.join(''));
	};

	const get_Z_value = mobile_number.slice(2, 3);

	const mobNumber =
		get_Z_value === '0'
			? `${('0' + mobile_number.slice(3, 15)).split(' ').join('')}`
			: `${('0' + mobile_number.slice(2, 15)).split(' ').join('')}`;

	return (
		<div className={cx({ [className]: className })}>
			<Text paragraph strong>
				{verified ? t('MobileVerificationForm__Verified', { first_name }) : t('MobileVerificationForm__Verify')}
			</Text>

			<Text size="-1" paragraph>
				{t('MobileVerificationForm__VerifyMobile')}
			</Text>

			<Text size="-2" strong>
				{t('MobileVerificationForm__VerificationCode')}
			</Text>
			<div>
				<div
					style={{
						display: 'flex',
						gap: '4px',
					}}
					onPaste={handlePaste}
				>
					{otp.map((data, index) => (
						<Input
							key={index}
							type="text"
							name={`code_${index + 1}`}
							maxLength={1}
							value={data}
							onChange={(name, value, error) => {
								handleChange(value, index);
							}}
							onKeyDown={(e) => handleKeyDown(e, index)}
							onFocus={(e) => e.target.select()}
							inputRef={(el) => (inputsRef.current[index] = el)}
							disabled={verified}
							align="center"
							margin="cozy"
							horizontalMargin="compact"
							pattern="[0-9]*"
						/>
					))}
				</div>

				{/* {error && (
					<Text type="danger" size="-2" paragraph>
						{error}
					</Text>
				)} */}

				{verified ? (
					<div>
						<Text size="-1" paragraph>
							{t('MobileVerificationForm__VerifiedOne')}
						</Text>
						<Text size="-1" paragraph strong>
							{t('MobileVerificationForm__VerifiedTwo')}
						</Text>
					</div>
				) : (
					<Text size="-2" paragraph>
						<Trans i18nKey="MobileVerificationForm__Message" values={{ mobNumber }}>
							Please enter the four digit verification code that has been sent to <strong>{{ mobNumber }}</strong> so
							that we can validate your mobile number.
						</Trans>
					</Text>
				)}
				<Icon
					className={css`
						font-size: 90px;
						line-height: 90px; // for IE
						align-self: flex-start;
					`}
					icon="phone-code"
				/>
			</div>

			{verified ? (
				<div>
					<ButtonGroup center>
						<Text
							className={css`
								flex: 0 1 50%;
							`}
							size="-1"
						>
							{t('MobileVerificationForm__Step3of3')}
						</Text>

						<Button action={props.goNext} type="primary" loading={loadingCreateAccount} bold flex>
							{t('MobileVerificationForm__NextStep')}
						</Button>
					</ButtonGroup>
				</div>
			) : (
				<ButtonGroup center>
					<Button type="ghost" action={props.goBack} bold flex>
						{t('MobileVerificationForm__UndoNumber')}
					</Button>

					<Button action={verifyMobile} type="primary" disabled={!valid} loading={loadingVerifyMobile} bold flex>
						{t('MobileVerificationForm__VerifyMobileButton')}
					</Button>
				</ButtonGroup>
			)}
		</div>
	);
};

export default withNamespaces()(MobileOPTForm);
