import { debounce } from 'lodash-es';

import { getAuthenticatedUser } from '../../store/application/applicationSelectors';

import {
	WIDGET_EVENT_PREFIX,
	WIDGET_RESIZE_DELAY,
	WIDGET_MAIN_BODY_ID,
	WIDGET_EVENT_TRIGGER,
} from '../constants/Widget';

/**
 * Attach the prefix separator to a message
 *
 * @param msg
 * @param prefix
 * @returns {string}
 */
export const prefixMessage = (msg, prefix = WIDGET_EVENT_PREFIX) => `${prefix}:${msg}`;

/**
 * Post a message from an iframe to the parent, with the prefix separator already in place
 *
 * @param msg
 * @param src
 */
export const postMessage = (msg, src = '*') => {
	// console.info(msg);
	window.parent.postMessage(msg, src);
};

/**
 * Post a BM Core event if it exists. Will also prefix the message
 *
 * @param msg
 * @param prefix
 */
export const postCoreMessage = (msg, prefix = WIDGET_EVENT_PREFIX) => {
	if (typeof window.bm === 'function') {
		// console.info(prefixMessage(msg, prefix));
		window.bm(WIDGET_EVENT_TRIGGER, prefixMessage(msg, prefix));
	}
};

/**
 * Post a message from an iframe to the parent
 *
 * @param msg
 * @param src
 */
export const postPrefixedMessage = (msg, src = '*') => {
	postCoreMessage(msg);
	postMessage(prefixMessage(msg), src);
};

/**
 * Add an event listener
 *
 * @param type
 * @param event
 */
export const addWidgetEvent = (type, event) => {
	window.addEventListener(type, event);
};

/**
 * Add a custom load event that posts a message to the parent
 */
export const addWidgetLoadEvent = () => {
	addWidgetEvent('load', postPrefixedMessage('load'));
};

const getHeightOfElementWithId = function(id, heightProp = 'clientHeight') {
	const element = document.getElementById(id);

	if (element) {
		return element[heightProp];
	}

	return 0;
};

/**
 * Debounce the height check for iframes
 */
const debounceHeight = debounce(() => {
	const footerHeight = getHeightOfElementWithId('js_footer-region');
	const headerHeight = getHeightOfElementWithId('js_header-region');
	const height = getHeightOfElementWithId(WIDGET_MAIN_BODY_ID, 'scrollHeight') + footerHeight + headerHeight + 150;
	postPrefixedMessage(`resize:${height}`);
}, 100);

/**
 * Add a custom resize event that posts a message to the parent
 *
 * @param wait
 */
export const addWidgetResizeEvent = (wait = WIDGET_RESIZE_DELAY) => {
	setInterval(() => {
		const el = document.getElementById(WIDGET_MAIN_BODY_ID);

		if (!el) {
			return;
		}

		const scrollHeight = el.scrollHeight;

		if (scrollHeight !== window.__tempScrollHeight) {
			window.__tempScrollHeight = scrollHeight;
			debounceHeight();
		}
	}, wait);

	// new ResizeSensor(
	// 	document.getElementById(WIDGET_MAIN_BODY_ID),
	// 	debounce(() => postPrefixedMessage(`resize:${document.getElementById(WIDGET_MAIN_BODY_ID).scrollHeight}`), wait),
	// );
};

/**
 * Send a login message to the parent if there is no currently authenticated user
 *
 * @returns {Function}
 */
export const sendWidgetLoginMessage = () => (dispatch, getState) => {
	if (!getAuthenticatedUser(getState())) {
		postPrefixedMessage('login');
	}
};

/**
 * Trigger an eventContext message. Optionally trigger a login event as well
 *
 * @param eventContext
 * @param msg
 * @param isLoginEvent
 * @param user
 */
export const triggerEventMessage = (eventContext, msg, isLoginEvent = false, user) => {
	if (eventContext && msg && typeof eventContext === 'function') {
		eventContext(msg);
		if (isLoginEvent && !user) {
			eventContext('login');
		}
	}
};
