// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, ui, media, brand, Text, typography } from '@tbh/ui-kit';

// Components
import { Notification, Link, Button, Input, Popover } from '@tbh/ui-kit';
import AclContext from '../../../../../contexts/AclContext';
import PasswordResetContainer from '../../../../../containers/UserAccount/PasswordReset/PasswordResetContainer';

import { EVENT_KEY_ENTER } from '../../../../../common/constants/Application';

const StyledJoinLoginElements = styled('div')(
	(props) => css`
		label: JoinLoginElements;

		display: flex;
		flex-wrap: wrap;
		justify-content: stretch;
		height: 100%;
		width: 100%;
		justify-content: center;
		max-width: 300px;
		${props.hideInputsWhenSmall &&
			css`
				flex-wrap: nowrap;
				justify-content: unset;
			`};
	`,

	media(
		css`
			flex-wrap: nowrap;
			justify-content: normal;
		`,
		900,
	),
);

const StyledJoinLoginElements__UsernameInput = styled(Input)(
	(props) => css`
		label: JoinLoginElements__UsernameInput;

		${Input.StyledInput__Input} {
			border-color: ${ui(props).color_2};
			&:focus {
				border: none;
			}
		}
		display: inline-flex;
		background-color: ${ui(props).color_2};
		flex: 1 0 100%;
		margin-bottom: ${spacings(props).tight}px;

		${props.hideInputsWhenSmall &&
			css`
				display: none;
			`};
	`,

	media(
		css`
			flex: 1 0 0px;
			/* -ms-flex: 1 0 auto; // Needed by IE & Edge */
			margin-bottom: 0;
		`,
		460,
		{ sizeKey: 'mediaSize' },
	),

	media(
		css`
			display: inline-flex;
			min-width: 180px;
		`,
		900,
		{ sizeKey: 'mediaSize' },
	),
);

const StyledJoinLoginElements__PasswordAndForgotButton = styled('div')(
	(props) => css`
		label: JoinLoginElements__PasswordAndForgotButton;

		display: flex;
		flex: 1 0 0px;
		-ms-flex: 1 0 auto; // Needed by IE & Edge
		background-color: ${ui(props).color_2};

		${props.hideInputsWhenSmall &&
			css`
				display: none;
			`};
	`,
	media(
		css`
			display: flex;
			min-width: 180px;
		`,
		900,
	),
);

const StyledJoinLoginElements__PasswordInput = styled(Input)(
	(props) => css`
		label: JoinLoginElements__PasswordInput;

		${Input.StyledInput__Input} {
			border-color: ${ui(props).color_2};
			&:focus {
				border: none;
			}
		}

		background-color: ${ui(props).color_2};
		flex: 1 1 auto;
	`,
);

const StyledJoinLoginElements__ForgotPassword = styled('div')(
	(props) => css`
		label: JoinLoginElements__ForgotPassword;

		display: inline-flex;
		position: relative;
		background-color: ${ui(props).color_2};
		flex: 1 1 auto;
		max-width: 50%;
	`,
);

const StyledJoinLoginElements__ForgotPasswordLink = styled(Link)(
	(props) =>
		css`
			label: JoinLoginElements__ForgotPasswordLink;

			align-self: center;
			padding: 0 ${spacings(props).tight}px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		`,
);

const StyledJoinLoginElements__LoginButton = styled(Button)(
	(props) => css`
		label: JoinLoginElements__LoginButton;

		display: block;
		height: 100%;
		align-items: baseline;
		margin-left: ${spacings(props).compact}px;

		${props.hideInputsWhenSmall &&
			css`
				display: none;
			`};
	`,
	media(
		css`
			display: block;
		`,
		900,
		{ sizeKey: 'mediaSize' },
	),
);

const StyledJoinLoginElements__JoinButton = styled('div')(
	(props) => css`
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		background-color: ${brand(props).color_4};
		border-radius: ${spacings(props).tight}px;
		padding: ${spacings(props).compact}px;

		&:hover {
			background-color: ${brand(props).color_1};
		}
	`,
);

// This is the button that renders on hideInputsWhenSmall
const StyledJoinLoginElements__JoinLoginButton = styled(Button)(
	(props) => css`
		label: JoinLoginElements__JoinLoginButton;

		font-family:  ${typography(props).base_font};
		height: 60px;

		background: rgb(118 116 116 / 48%);
		border-color: rgb(118 116 116 / 48%);
		font-weight: 100;

		${!props.hideInputsWhenSmall &&
			css`
				display: none;
			`};
	`,

	media(
		css`
			display: none;
		`,
		900,
		{ sizeKey: 'mediaSize' },
	),
);

const JoinLoginElements = (props) => {
	const {
		t,
		username,
		password,
		showForgotPassword,
		error,
		handleChange,
		openForgotPassword,
		closeForgotPassword,
		clearError,
		onLogin,
		goToRegistration,
		onJoinLogin,
		className,
		loadingLogin,
		showRegistration,
		hideInputsWhenSmall,
		size,
	} = props;

	let onKeyDown = (event) => {
		if (event.key === EVENT_KEY_ENTER) {
			onLogin();
		}
	};

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<StyledJoinLoginElements className={componentClasses} size={size}>
			<AclContext.Consumer>
				{(acl) => (
					<StyledJoinLoginElements__UsernameInput
						mediaSize={size}
						hideInputsWhenSmall={hideInputsWhenSmall}
						placeholder={t('JoinLoginElements__Username')}
						name="username"
						value={username}
						onChange={handleChange}
						horizontalMargin="compact"
						onKeyDown={onKeyDown}
					/>
				)}
			</AclContext.Consumer>

			<StyledJoinLoginElements__PasswordAndForgotButton size={size} hideInputsWhenSmall={hideInputsWhenSmall}>
				<StyledJoinLoginElements__PasswordInput
					mediaSize={size}
					hideInputsWhenSmall={hideInputsWhenSmall}
					placeholder={t('Password')}
					name="password"
					type="password"
					value={password}
					onChange={handleChange}
					onKeyDown={onKeyDown}
				/>

				<StyledJoinLoginElements__ForgotPassword size={size} hideInputsWhenSmall={hideInputsWhenSmall}>
					<StyledJoinLoginElements__ForgotPasswordLink action={openForgotPassword} size="-1">
						{t('Forgot')}?
					</StyledJoinLoginElements__ForgotPasswordLink>

					<Popover
						className={css`
							min-width: 360px;
						`}
						open={showForgotPassword}
						onOutsideClick={closeForgotPassword}
						padding
					>
						{showForgotPassword && <PasswordResetContainer />}
					</Popover>
				</StyledJoinLoginElements__ForgotPassword>
			</StyledJoinLoginElements__PasswordAndForgotButton>

			<div
				className={css`
					position: relative;
					text-align: right;
				`}
			>
				<StyledJoinLoginElements__LoginButton
					type="secondary"
					action={onLogin}
					loading={loadingLogin}
					icon="lock"
					bold
					mediaSize={size}
					hideInputsWhenSmall={hideInputsWhenSmall}
				>
					{t('Login')}
				</StyledJoinLoginElements__LoginButton>

				<Popover
					className={css`
						min-width: 250px;
					`}
					open={Boolean(error)}
					onOutsideClick={clearError}
					error
					padding
				>
					<Notification type="danger" message={error} strong />
				</Popover>
			</div>

			{/* {showRegistration && (
				<StyledJoinLoginElements__JoinButton
					icon="right-arrow-circle"
					type={Button.types.PRIMARY}
					action={goToRegistration}
					bold
					mediaSize={size}
					hideInputsWhenSmall={hideInputsWhenSmall}
				>
					{t('JoinLoginElements__GetStarted')}
				</StyledJoinLoginElements__JoinButton>
			)} */}
			<StyledJoinLoginElements__JoinButton onClick={onJoinLogin}>
			<Text size="-2" type="alternate-light">
				Login Or Register
				</Text>
			</StyledJoinLoginElements__JoinButton>
		</StyledJoinLoginElements>
	);
};

JoinLoginElements.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}),

	/** Action to call when an input is changed */
	handleChange: PropTypes.func.isRequired,

	/** Action to call when the forgot password link is to be opened */
	openForgotPassword: PropTypes.func.isRequired,

	/** Action to call when the forgot password link is to be closed */
	closeForgotPassword: PropTypes.func.isRequired,

	/** Action to clear any error message */
	clearError: PropTypes.func.isRequired,

	/** Action to reset a users' password */
	resetPassword: PropTypes.func.isRequired,

	/** Action to login the user */
	onLogin: PropTypes.func.isRequired,

	/** Action to go to the registration page */
	goToRegistration: PropTypes.func.isRequired,

	/** Action for the login/register button */
	onJoinLogin: PropTypes.func.isRequired,

	/** If true only show the login/register button and hide inputs when displaying smaller sizes */
	hideInputsWhenSmall: PropTypes.bool,

	/** Show the registration button */
	showRegistration: PropTypes.bool,

	/** Whether or not to show the forgot password section */
	showForgotPassword: PropTypes.bool,

	/** The users' username for login */
	username: PropTypes.string,

	/** The users' password for login */
	password: PropTypes.string,

	/** Any errors to display */
	error: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),

	/** Extra classes */
	className: PropTypes.string,

	/** If we are waiting for a login response */
	loadingLogin: PropTypes.bool,

	/** If we are waiting for a reset password response */
	loadingResetPassword: PropTypes.bool,
};

JoinLoginElements.defaultProps = {
	hideInputsWhenSmall: true,
	showRegistration: true,
	showForgotPassword: false,
	className: null,
	error: null,
	username: '',
	password: '',
	loadingLogin: false,
	loadingResetPassword: false,
	size: undefined,
};

export default withNamespaces()(JoinLoginElements);
