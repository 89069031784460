import React, { Fragment, useEffect, useState } from 'react';
import styled, { css } from 'react-emotion';
import { SizeMe } from 'react-sizeme';
import { PageComponentContainer } from '../../../components/features/Application/PageComponentContainer/PageComponentContainer';
import { media, spacings, Text } from '@tbh/ui-kit';
import RacingDateSelectionGRSV2 from '../../../components/features/Racing/RacingDateSelection/RacingDateSelectionGRSV2';
// import MeetingsListGRSContainerLoading from '../../../containers/Racing/MeetingsListContainer/MeetingListGRSContainerLoading';
import MeetingContainerLoading from '../../../containers/Racing/MeetingContainer/MeetingContainerLoading';
// import RacesListByTypeContainerLoading from '../../../containers/Racing/RacesListContainer/RacesListByTypeContainerLoading';
import MeetingContainer from '../../../containers/Racing/MeetingContainer/MeetingContainer';
// import MeetingsListGRSContainer from '../../../containers/Racing/MeetingsListContainer/MeetingsListGRSContainer';
// import RacesListByTypeContainer from '../../../containers/Racing/RacesListContainer/RacesListByTypeContainer';
import MeetingsAndRacesTable, {
	MeetingsAndRacesTableLoading,
} from '../../../components/features/Racing/MeetingsAndRacesTable/MeetingsAndRacesTable';

import DocumentTitle from '../../../components/controllers/Meta/DocumentTitle';
import Route from '../../../components/controllers/Route/Route';
import DescriptionMeta from '../../../components/controllers/Meta/DescriptionMeta';
import RaceNextJumpFilter from '../../../components/features/Racing/RaceNextJumpV3/RaceNextJumpFilter/RaceNextJumpFilter';
import RaceCountryFilter from '../../../components/features/Racing/RaceCountryFilter/RaceCountryFilter';
import { serverDateTime } from '../../../../src/legacy/core/format';
import { meetingsOfTypeCountByCountryCode } from '../../../store/entities/selectors/MeetingSelectorsGRS';
import {
	buildMeetingGroupFilters,
	meetingsCountByCountryCode,
} from '../../../store/entities/selectors/MeetingSelectors';
import { getMeetings } from '../RacingNewHome/racingSelectorsGRS';
import { buildRacingGRSRouteFromState } from '../../../routes/RacingNew';
import {
	getSelectedDate,
	getSelectedType,
	navigateToRacingHome,
	setRaceTypeFilter,
	setSelectedType,
} from '../../../store/GRSracingHome/GRSracingHomeActions';
import { withNamespaces } from 'react-i18next';
import { createErrorBoundary } from '../../../containers/ErrorBoundary/ErrorBoundaryActions';
import { connect } from 'react-redux';
import moment from 'moment';
import {
	QUADDIES_BET_TYPES,
	RACING_BET_TYPE_RUNNING_DOUBLE,
	RACING_BET_TYPE_TITLES,
	RACING_THOROUGHBRED_CODE,
	// RACING_THOROUGHBRED_CODE,
	// RACING_GREYHOUNDS_CODE,
	// RACING_HARNESS_CODE,
} from '../../../common/constants/Racing';
import MeetingListMobile from '../../../components/features/Racing/MeetingsListMobile/MeetingListMobile';
import { MeetingListMobileLoading } from '../../../components/features/Racing/MeetingsListMobile/MeetingListMobileLoading';
import { SliderComponentContainer } from '../../../components/features/Application/PageComponentContainer/SliderComponentContainer';
import BannersCarousel from '../../../components/features/Application/BannersCarousel/BannersCarousel';
import { LoadingMask } from '@tbh/ui-kit';
import { get } from '../../../common/Ajax';
import PendingBetNotifications from '../../../containers/Betting/PendingBetNotifications/PendingBetNotifications';

export const NewRacingHomeContainer = styled('div')(
	(props) => css`
		label: NewRacingHomeContainer;
		display: flex;
		position: relative;
		flex-direction: column;
		width: 100%;
		background-color: #fff;
		border-radius: 8px;
		padding: 12px;
	`,
);

export const NewRacingHomeHeader = styled(PageComponentContainer)(
	(props) => css`
		label: NewRacingHomeHeader;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: ${spacings(props).compact}px;
		@media (max-width: 767px) {
			grid-template-columns: 1fr;
		}
	`,
);
export const NewRacingHomeHeaderContent = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		@media (max-width: 767px) {
			flex-direction: column;
		}
	`,
);

export const NewRacingHomeContent = styled(PageComponentContainer)(
	(props) =>
		css`
			margin-top: 16px;
		`,
);
export const FilterSection = styled('div')`
	${(props) =>
		css`
			label: FilterSection;
			width: 100%;
			display: flex;
			flex-direction: row;
			box-sizing: border-box;
			display: flex;
			justify-content: space-between;
			background-color: #ffffff;
			border-radius: 4px;
			@media (max-width: 767px) {
				flex-direction: column;
				justify-content: center !important;
				align-items: center;
			}
		`};

	${media(
		(props) =>
			css`
				display: flex;
				width: 100%;
			`,
		500,
	)};
`;

export const modeChangeOverSize = 751;
const IPAD_SIZE = 760;

const ExoticList = styled('div')`
	display: flex;
	flex-direction: row;
	gap: 4px;

	@media (max-width: 767px) {
		display: none;
	}
`;

const ExoticItem = styled('div')`
	text-align: center;
	font-size: 12px;
	border: 1px solid #e6e6e6;
	padding: 8px;
	cursor: pointer;
	border: 1px solid;
	border-color: #cccccc;
	border-bottom: 4px solid #e6e6e6;

	color: #666666;

	&:hover {
		color: #000000;
		border-bottom-color: rgba(199, 2, 2, 0.4);
	}

	${(props) =>
		props.active &&
		`
        border-bottom-color: rgba(199,2,2,1);
        color: #000000
    `}
`;

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	border-radius: 4px;
	padding-top: 8px;
	gap: 8px;
`;

const Header = ({
	t,
	selectedDate,
	selectedMeeting,
	raceTypeFilters,
	setSelectedType,
	setSelectedDate,
	resetTypeFilterClick,
	setRaceTypeFilter,
	handleOnCountryFilterClick,
	countryFilter,
	meetingCountByCountryCode,
	resetCountryFilterClick,
	raceCount,
	selectedExotic,
	handleOnExoticClick,
	getHeaderText,
	size,
}) => {
	return (
		<Fragment>
			<FilterSection size={size}>
				<Text
					size={'1'}
					className={css`
						text-transform: uppercase;
						font-weight: 550;
						white-space: nowrap;
						margin-bottom: 16px;
					`}
				>
					{`${getHeaderText(selectedDate)} ${t('RacingHomePage__Races')}`}
				</Text>

				<RacingDateSelectionGRSV2
					className={css`
						flex-flow: row wrap;
						width: 100% !important;
						justify-content: end;
					`}
					date={selectedDate}
					handleFeaturedRacingClick={null}
					handleSelectDay={setSelectedDate}
					size={size}
				/>
			</FilterSection>
			{!selectedMeeting && (
				<FilterSection style={{ gap: '12px' }} size={size}>
					<div style={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center' }}>
						<RaceNextJumpFilter
							mode={size.width > modeChangeOverSize ? 'vertical' : 'horizontal'}
							onFilterItemClick={setRaceTypeFilter}
							types={raceTypeFilters}
							resetFilterClick={resetTypeFilterClick}
							showAllButton={false}
							size={size}
						/>

						{raceCount && (
							<RaceCountryFilter
								onFilterItemClick={handleOnCountryFilterClick}
								countryCodes={Array.from(countryFilter)}
								counryGroup={meetingCountByCountryCode}
								allActive={countryFilter.size === 0}
								resetFilterClick={resetCountryFilterClick}
								raceCount={raceCount}
								size={size}
							/>
						)}
					</div>

					{raceCount && (
						<ExoticList style={{ justifyContent: 'end' }} size={size}>
							{QUADDIES_BET_TYPES.filter((q) => q != RACING_BET_TYPE_RUNNING_DOUBLE).map((b) => (
								<ExoticItem key={b} active={selectedExotic == b} onClick={() => handleOnExoticClick(b)}>
									{RACING_BET_TYPE_TITLES[b]}
								</ExoticItem>
							))}
						</ExoticList>
					)}
				</FilterSection>
			)}
		</Fragment>
	);
};

const HeaderMobile = ({ t, selectedDate, setSelectedDate, getHeaderText, size }) => {
	return (
		<Fragment>
			<FilterSection size={size} style={{ gap: 12 }}>
			{/* hideHeader in the race header */}
				{/* <Text
					size={'1'}
					className={css`
						text-transform: uppercase;
						font-weight: 550;
						white-space: nowrap;
					`}
				>
					{`${getHeaderText(selectedDate)} ${t('RacingHomePage__Races')}`}
				</Text> */}
				<RacingDateSelectionGRSV2
					className={css`
						flex-flow: row wrap;
						width: 100% !important;
						justify-content: end;
					`}
					date={selectedDate}
					handleFeaturedRacingClick={null}
					handleSelectDay={setSelectedDate}
					size={size}
				/>
			</FilterSection>
		</Fragment>
	);
};

const NewRacingHomePage = ({
	t,
	useRouting,
	currentRoute,
	brandName,
	loadingMeeting,
	loadingContainer,
	selectedType,
	setSelectedType,
	selectedMeeting,
	selectedDate,
	meetings,
	navigateToRacingHome,
	groupMeetingsByCountry,
	meetingGroups,
	raceTypeFilters,
	setRaceTypeFilter,
	taglines,
	headerHeight,
	footerHeight,
}) => {
	const [loadingMask, setLoadingMask] = useState(false);
	const [banners, setBanners] = useState([]);

	// useEffect(() => {
	// 	const handleContextMenu = (event) => {
	// 	  event.preventDefault(); // Prevent the context menu from opening
	// 	};
	
	// 	// Add event listener to disable right-click
	// 	document.addEventListener('contextmenu', handleContextMenu);
	
	// 	// Cleanup event listener on component unmount
	// 	return () => {
	// 	  document.removeEventListener('contextmenu', handleContextMenu);
	// 	};
	//   }, []);



	useEffect(() => {
		const getBanners = async () => {
			setLoadingMask(true);
			try {
				const {
					data: { data },
				} = await get('/banners');
				setBanners([...data.map((banner) => ({ image: banner.imageurl, link: banner.clickurl }))]);
			} catch (error) {
				// handle error
			}
			setLoadingMask(false);
		};

		getBanners();
	}, []);

	const setSelectedDate = (newDate, trackingLabel) => {
		navigateToRacingHome(newDate, selectedType);
	};

	const aclMeetingGroupsEnabled = () => {
		return (groupMeetingsByCountry && groupMeetingsByCountry.enabled) || (meetingGroups && meetingGroups.enabled);
	};

	const [selectedExotic, setSelectedExotic] = useState(null);

	const handleOnExoticClick = (exotic) => {
		if (exotic === selectedExotic) {
			setSelectedExotic(null);
			return;
		}

		setSelectedExotic(exotic);
	};

	const showContent = (size) => {
		// Table race is loading
		if (loadingContainer) {
			if (size.width < modeChangeOverSize) {
				return (
					<Fragment>
						<Container>
							<RaceNextJumpFilter
								onFilterItemClick={setRaceTypeFilter}
								types={raceTypeFilters}
								setSelectedType={setSelectedType}
								selectedType={selectedType ? selectedType : RACING_THOROUGHBRED_CODE}
								resetFilterClick={resetTypeFilterClick}
								showAllButton={false}
								size={size}
							/>
							<MeetingListMobileLoading size={size} />
						</Container>
					</Fragment>
				);
			}

			return <MeetingsAndRacesTableLoading size={size} />;
		}

		// Rece selected but is loading
		if (loadingMeeting) {
			return <MeetingContainerLoading size={size} />;
		}

		// Race selected
		if (selectedMeeting) {
			return (
				<Fragment>
					<MeetingContainer size={size} />
				</Fragment>
			);
		}

		if (size.width < modeChangeOverSize) {
			return (
				<Fragment>
					<Container>
						<RaceNextJumpFilter
							onFilterItemClick={setRaceTypeFilter}
							types={raceTypeFilters}
							setSelectedType={setSelectedType}
							selectedType={selectedType ? selectedType : RACING_THOROUGHBRED_CODE}
							resetFilterClick={resetTypeFilterClick}
							showAllButton={false}
							size={size}
						/>
						<MeetingListMobile
							size={size}
							showOnlyMeetingGroup={aclMeetingGroupsEnabled()}
							filteredCountryList={Array.from(countryFilter)}
							filterRaceTypeList={selectedType ? [selectedType] : [RACING_THOROUGHBRED_CODE]}
						/>
					</Container>
				</Fragment>
			);
		}

		return (
			<MeetingsAndRacesTable
				size={size}
				selectedType={selectedType}
				selectedDate={selectedDate}
				showOnlyMeetingGroup={aclMeetingGroupsEnabled()}
				filteredCountryList={Array.from(countryFilter)}
				filterRaceTypeList={raceTypeFilters}
				selectedExotic={selectedExotic}
			/>
		);
	};

	const getHeaderText = (day) => {
		const diffFromToday = (day) =>
			serverDateTime(moment())
				.startOf('day')
				.diff(serverDateTime(day).startOf('day'), 'days');

		if (!day) {
			return t("RacingHomePage__Today's");
		}

		switch (diffFromToday(day)) {
			case 0:
				return t("RacingHomePage__Today's");
			case -1:
				return t("RacingHomePage__Tomorrow's");
			case 1:
				return t("RacingHomePage__Yesterday's");
			default:
				return serverDateTime(day).format('dddd MMMM Do YYYY');
		}
	};
	const resetTypeFilterClick = () => setRaceTypeFilter([]);

	const meetingCountByCountryCode = meetingsOfTypeCountByCountryCode(meetings);

	const [countryFilter, setCountryFilter] = useState(['AU', 'NZ', 'INT']);

	const handleOnCountryFilterClick = (types) => {
		let types_set = new Set(countryFilter);
		for (const type of types) {
			if (!type) {
				return;
			}

			if (types_set.has(type)) {
				types_set.delete(type);
			} else {
				types_set.add(type);
			}
		}

		setCountryFilter(types_set);
	};

	const resetCountryFilterClick = () => setCountryFilter(new Set());

	const raceCount = meetings.length > 0;

	return (
		<div>
			<SizeMe>
				{({ size }) => (
					<Fragment>
						{size.width < modeChangeOverSize && !selectedMeeting && !loadingMeeting && (
							<SliderComponentContainer
								style={{
									marginBottom: '16px',
								}}
							>
								<LoadingMask loading={loadingMask} />
								<BannersCarousel size={size} Items={banners} taglines={taglines} />
							</SliderComponentContainer>
						)}

						<NewRacingHomeContainer
							style={
								size.width < modeChangeOverSize
									? { minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px` }
									: {}
							}
						>
							{useRouting && (
								<React.Fragment>
									<Route route={currentRoute} />
									<DocumentTitle>
										{`${t('RacingHomePage__Title')}${brandName ? ` ${t('at')} ${brandName}` : ''}`}
									</DocumentTitle>
									<DescriptionMeta>
										{`${t('RacingHomePage__Description')}${brandName ? ` ${t('with')} ${brandName}` : ''}. ${t(
											'RacingHomePage__DescriptionBonus',
										)}`}
									</DescriptionMeta>
								</React.Fragment>
							)}

							{size.width > modeChangeOverSize ? (
								<Header
									t={t}
									selectedDate={selectedDate}
									setSelectedDate={setSelectedDate}
									selectedMeeting={selectedMeeting}
									raceTypeFilters={raceTypeFilters}
									setSelectedType={setSelectedType}
									resetTypeFilterClick={resetTypeFilterClick}
									setRaceTypeFilter={setRaceTypeFilter}
									handleOnCountryFilterClick={handleOnCountryFilterClick}
									countryFilter={countryFilter}
									meetingCountByCountryCode={meetingCountByCountryCode}
									resetCountryFilterClick={resetCountryFilterClick}
									raceCount={raceCount}
									selectedExotic={selectedExotic}
									handleOnExoticClick={handleOnExoticClick}
									getHeaderText={getHeaderText}
									size={size}
								/>
							) : (
								<HeaderMobile
									t={t}
									selectedDate={selectedDate}
									setSelectedDate={setSelectedDate}
									getHeaderText={getHeaderText}
									size={size}
								/>
							)}

							{showContent(size)}
						</NewRacingHomeContainer>
					</Fragment>
				)}
			</SizeMe>

		</div>
	);
};

const mapStateToProps = (state) => {
	const groupMeetingsByCountry = state.featureToggles.features.groupMeetingsByCountry;
	const meetingGroups = state.featureToggles.features.meetingGroups;
	const meetingGroupsCount = meetingsCountByCountryCode(state);
	const meetingGroupFilters = buildMeetingGroupFilters(groupMeetingsByCountry, meetingGroups, meetingGroupsCount);
	return {
		selectedMeeting: state.grsRacingHome.selectedMeeting,
		loadingMeeting: state.grsRacingHome.loadingMeeting,
		loadingContainer: state.grsRacingHome.loadingContainer,
		brandName: state.acl.brandDetails && state.acl.brandDetails.name,
		meetingGroupFilters: meetingGroupFilters,
		authenticatedUser: state.application.authenticatedUser,
		currentRoute: buildRacingGRSRouteFromState(state),
		selectedDate: getSelectedDate(state),
		selectedType: getSelectedType(state),
		isPhoneDevice: state.application.isPhoneDevice,
		selectedPageKey: state.application.selectedPage,
		meetings: getMeetings(state),
		useRouting: true,
		raceTypeFilters: state.grsRacingHome.raceTypeFilters,
		taglines: state.acl.taglines,
		headerHeight: document.getElementById('headerWrapper') ? document.getElementById('headerWrapper').offsetHeight : 0,
		footerHeight: document.getElementById('footerWrapper') ? document.getElementById('footerWrapper').offsetHeight : 0,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	navigateToRacingHome: (newDate, type) => {
		dispatch(navigateToRacingHome(newDate, type));
	},
	setRaceTypeFilter: (type, selecteOnly) => dispatch(setRaceTypeFilter(type, selecteOnly)),
	setSelectedType: (type) => dispatch(setSelectedType(type)),
});
export default withNamespaces()(createErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(NewRacingHomePage)));
