import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { navigateToRaceWithType } from '../../store/GRSracingHome/GRSracingHomeActions';

import RaceUpcoming from '../../components/features/Racing/RaceUpcoming/RaceUpcoming'; // create new @HW

import { Ticker } from '@tbh/ui-kit';
import { trackNextToJump } from '../../store/GRSracingHome/GRSracingTrackingActions';
import { buildSelectionBetButtons } from '../../pages/GRSNewRacing/RacingNewHome/racingSelectorsGRS';
import { getUpcomingRaces } from '../../pages/FeatureRacing/FeatureRacingHome/featureRacingSelectors';
import RaceUpcomingLoading from '../../components/features/Racing/RaceUpcoming/RaceUpcomingLoading';
import PusherSubscriberUpcomingRacesChannel from '../../components/features/Racing/PusherSubscribers/PusherSubscriberUpcomingRacesChannel';
import { fetchGRSAllUpcomingRaces } from '../../store/entities/actions/UpcomingAllRaceActions';

const UpcomingRacesContainer = (props) => {
	useEffect(() => {
		const id = setInterval(() => {
			props.fetchUpcomingRaces();
		}, 4 * 30000); // 2 min delays
		return () => {
			clearInterval(id);
		};
	}, []);

	const trackOnFilterClick = (type) => {
		props.trackOnFilterClick(type, props.isNJRacingHome);
	};

	const handleGoToRace = (type, date, raceId, meetingId) => {
		props.navigateToRaceWithType(type, date, raceId, meetingId);
	};

	const notSellingRaces = props.items.filter((race) => race.status == 'selling');

	return (
		<React.Fragment>
			<PusherSubscriberUpcomingRacesChannel />
			<Ticker every={90000}>
				{notSellingRaces.length != 0 ? (
					<RaceUpcoming
						className={props.className}
						items={notSellingRaces}
						onGoToRace={handleGoToRace}
						trackOnFilterClick={trackOnFilterClick}
						hideRaceNextJumpFilter={props.hideRaceNextJumpFilter}
						buildSelectionBetButtons={props.buildSelectionBetButtons}
						size={props.size}
					/>
				) : (
					<RaceUpcomingLoading className={props.className} size={props.size} />
				)}
			</Ticker>
		</React.Fragment>
	);
};

UpcomingRacesContainer.propTypes = {
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			products: PropTypes.array,
			meeting_name: PropTypes.string,
			race_name: PropTypes.string,
			state: PropTypes.string,
			start_datetime: PropTypes.string,
			selection: PropTypes.array,
			distance: PropTypes.string,
		}),
	).isRequired,
	isNJRacingHome: PropTypes.bool,
	trackOnFilterClick: PropTypes.func,
	className: PropTypes.string,
	hideRaceNextJumpFilter: PropTypes.bool,
	buildSelectionBetButtons: PropTypes.func.isRequired,
	navigateToRaceWithType: PropTypes.func.isRequired,
};

UpcomingRacesContainer.defaultProps = {
	isNJRacingHome: false,
	trackOnFilterClick: () => undefined,
	className: '',
	hideRaceNextJumpFilter: false,
};

const mapStateToProps = (state) => {
	return {
		items: getUpcomingRaces(state),
	};
};

const mapDispatchToProps = (dispatch) => ({
	navigateToRaceWithType: (type, date, raceId, meetingId, isNJRacingHome) => {
		dispatch(trackNextToJump('Click', null, isNJRacingHome));
		dispatch(navigateToRaceWithType(type, date, raceId, meetingId));
	},
	fetchUpcomingRaces: () => {
		dispatch(fetchGRSAllUpcomingRaces());
	},
	buildSelectionBetButtons: (prices, displayedBetProducts, betType) =>
		dispatch(buildSelectionBetButtons(prices, displayedBetProducts, betType)),
	trackOnFilterClick: (type, isNJRacingHome) => dispatch(trackNextToJump('Filter', type, isNJRacingHome)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingRacesContainer);
