// import rg4js from 'raygun4js';
import env from '../../common/EnvironmentVariables';

import { requireAll } from '../../common/ApplicationHelpers';

/**
 * Application Global Variables
 *
 * Will be put onto 'window' when bundled
 */
global.getEnv = env; // This is required for sitemap generation
global.Backbone = require('backbone');

global.currencyIconModules = requireAll(require.context('../../images/currency-icons', false));

global.onunhandledrejection = function(e) {
	try {
		throw new Error(e.reason);
	} catch (error) {
		// rg4js('send', error);
		//console.log('send',error)
	}
};
