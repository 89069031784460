import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { Button, Input, spacings, Notification, PasswordInput, Text, LoadingMask, ProgressBar } from '@tbh/ui-kit';
import { RNSUserLoginAction } from '../../../store/RNS/RNSUserLoginAction';
import { errorString } from '../../../legacy/core/format';
import { MEDIA_QUERIES } from '../../../constants/themes';

const Styled__Login__Header = styled('div')(
  (props) => css`
  	label: Login__Header;
  	background-color: #00357a;
  	color:#fff;
  	padding:15px;
  	text-align: center;
  	font-weight: bold;
  	-webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  `
);

const Styled__Login__Page__Wrapper = styled('div')(
  (props) => css`
  	label: Login__Page__Wrapper;
  `
);
const Styled__Login__Wrapper = styled('div')(
  (props) => css`
  	label: Login__Wrapper;
		margin: 0 auto;
		width: 100%;
		height: auto;
		padding: 0;

    @media ${MEDIA_QUERIES.tabletAndUp} {
      margin: 0 auto;
      width: 60%;
      height: auto;
      padding: 20px;
    }
  `
);
const Styled__Login__Container = styled('div')(
  (props) => css`
    label: Login__Container;
    padding:15px;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #f3f3f3;
  `
);
const Style__Login__Inner__Card = styled('div')(
  (props) => css`
  label: Login__Inner__Card;
  margin:20px;

  color:#000;
  `
);

const StyledRNS__Notification = styled(Notification)`
	label: UpdatePassword__Notification;

	${(props) =>
    css`
			margin: 0 0 ${spacings(props).cozy}px;
		`};
`;
const StyledRNS__SignUpText = styled('span')`
	label: SignUpText ;

	${(props) =>
    css`
			padding-left: 10px;
		`};
`;

class RNSContainer extends React.Component {

  static propTypes = {
    /** Translation func provided by withNamespaces HOC */
    t: PropTypes.func.isRequired,

    /** Extra classes */
    className: PropTypes.string,
  };
  static defaultProps = {
    className: '',
  };
  state = {
    RNS_UserName: '',
    RNS_Password: '',
    error: null,
    success: null,
    progress: 0,
    interval: null,
  };

  handleChange = (name, value, valid) => {
    this.setState({
      [name]: value,
      [`${name}_valid`]: valid,
    });
  }
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.RNS_Password !== '' && this.state.RNS_UserName !== '') {
      const inter = setInterval(() => {
        if (this.state.progress >= 60) {
          if (this.props.loading == false) {
            clearInterval(this.state.interval);
            this.setState({ progress: 100 });
          }
        } else {
          this.setState({ progress: this.state.progress + 10 });
        }
      }, 100);
      this.setState({ interval: inter });

      this.props.RNSUserLoginAction(this.state.RNS_UserName, this.state.RNS_Password, this.props.user_email);
      // .then((response) =>{

      //   if(response.status === 400){
      //     this.setState({error:response.data.message});
      //   }
      //   if(response.status === 200){
      //     this.setState({success:response.data.message});
      //   }
      // }).catch((err) => {
      //   console.log(err);
      // });
    }
  }
  /**
   * Removes the error message
   */
  clearErrorMessage = () => {
    this.setState({
      error: null,
      success: null,
    });
  };

  updateProgress = () => {

  }

  render() {
    const { isUserLog, className, t } = this.props;
    const {
      RNS_UserName,
      RNS_Password,
      error,
      success,
    } = this.state;
    const componentClasses = cx({
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {<LoadingMask loading={this.props.loading} />}
        {!isUserLog ?
          <Styled__Login__Page__Wrapper>
            <Styled__Login__Wrapper>
              <Styled__Login__Header>
                Sign In
              </Styled__Login__Header>
              <Styled__Login__Container>
                <Style__Login__Inner__Card>
                  <Text size="-1" className={css`black!important`}>
                    {'User Name'}
                  </Text>
                  <Input
                    value={RNS_UserName}
                    onChange={this.handleChange}
                    name="RNS_UserName"
                    type="text"
                  />
                </Style__Login__Inner__Card>
                <Style__Login__Inner__Card>
                  <Text size="-1" className={css`black!important`}>
                    {'Password'}
                  </Text>
                  <PasswordInput
                    value={RNS_Password}
                    onChange={this.handleChange}
                    name="RNS_Password"
                  />
                </Style__Login__Inner__Card>

                {this.state.progress != 0 && this.state.progress != 100 ? (<ProgressBar percentage={this.state.progress} className={css`padding:0;margin-top:12px;`} />) :

                  <Button block type="primary"
                    action={this.handleSubmit}
                  >
                    {'Sign in Racing and Sports'}
                  </Button>}
                <br /><br />
                <Text size="-2">{'Are you a New User?'} <StyledRNS__SignUpText><a href={'https://www.racingandsports.com/user/register'} target="_blank" rel="noopener noreferrer">{'Sign-up here'}</a></StyledRNS__SignUpText> </Text>
              </Styled__Login__Container>
            </Styled__Login__Wrapper>
          </Styled__Login__Page__Wrapper>
          :
          <div>
            <Styled__Login__Page__Wrapper>
              <Styled__Login__Wrapper>
                <Styled__Login__Header>
                  Racing and Sports Synchronization Successfully
                </Styled__Login__Header>
              </Styled__Login__Wrapper>
            </Styled__Login__Page__Wrapper>
          </div>
        }
        {error && (
          <StyledRNS__Notification
            type={Notification.types.COLOUR_DANGER}
            message={errorString(error)}
            buttonAction={this.clearErrorMessage}
          />
        )}
        {success && (
          <StyledRNS__Notification
            type={Notification.types.COLOUR_SUCCESS}
            message={errorString(success)}
            buttonAction={this.clearErrorMessage}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const authenticatedUser = state.application.authenticatedUser;
  const user = state.entities.users[authenticatedUser] || {};
  return {
    isUserLog: user.rns_user_name,
    user_email: user.email,
    loading: state.application.isBodyRegionLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  RNSUserLoginAction: (rns_user_name, rns_password, email) => {
    return dispatch(RNSUserLoginAction(rns_user_name, rns_password, email));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RNSContainer);
