import React from 'react'; // { Component }
import { StyledSection } from '../Components';
import styled, { css } from 'react-emotion';
import { Input, spacings, typography } from '@tbh/ui-kit';
import  VerificationFormV2  from './VerificationFormV2';
const StyledSelectDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
`;

export const StyledVeficaitionInput = styled(Input)`
	${(props) => css`
		width: 100%;
		height: 25px;
		font-size: ${typography(props).size_2}px;
		color: #333;
		margin-bottom: 10px;
		width: 280px;
		outline: none;
	`}
`;
export const StyledInputSelectVerification = styled.select`
	width: 100%;
	height: 25px;
	border: 1px solid black;
	border-top: none;
	border-left: none;
	border-right: none;
	padding: 0 10px;
	font-size: 14px;
	color: #333;
	margin-bottom: 10px;
	width: 280px;
	outline: none;
`;
export const StyledVerification = styled('div')`
	${(props) => css`
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;

		gap: ${spacings(props).cozy}px;
	`}
`;

export const StyledInputBox = styled('div')`
	${(props) => css`
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		padding: ${spacings(props).cozy}px;
		label {
			color: black;
			font-weight: bold;
			margin: 0px;
		}
	`}
`;
export const StyledSpan = styled('span')`
	font-size: 15px;
	margin-left: 5px;
	color: #ccc;
`;

export const StyledMenuSelect = styled.select`
	width: 100%;
	height: 25px;
	border: none;
	outline: none;
`;

export const StyledVerificationModalContent = styled('div')(
	(props) => css`
		label: Modal__Failed;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: ${spacings(props).cozy}px;
		padding: ${spacings(props).cozy}px;

	`
);

const VerificationsContainer = ({ selectedMenuItem, verificationItem, setSelectedMenuItem, user,toWelcome }) => {
	const { items } = verificationItem;

	// React.useEffect(() => {
	// 	setSelectedMenuItem(items[0]);
	// }, []);

	// const createMenu = () => {
	// 	return (
	// 		<StyledMenuSelect
	// 			value={selectedMenuItem.id}
	// 			onChange={(e) => setSelectedMenuItem(items.find((item) => item.id == e.target.value))}
	// 		>
	// 			{items.map((item, index) => (
	// 				<option key={'verify-' + item.id} value={item.id}>
	// 					{item.title}
	// 				</option>
	// 			))}
	// 		</StyledMenuSelect>
	// 	);
	// };


	return (
		<StyledSection style={{ alignItems: 'center' }}>
			<VerificationFormV2 
	
			/>
			{/* <StyledSelectDiv>{createMenu()}</StyledSelectDiv> */}

			{/* {selectedItem &&
				<VerificationFormContainer user={user}
					img={selectedItem.img}
					country={selectedItem.country}
					state={selectedItem.state}
					selectedItem={selectedItem}
					idType={selectedItem.idType}
					type={selectedItem.type}
					fields={selectedItem.fields} />} */}
		</StyledSection>
	);
};

export { VerificationsContainer };
