import { denormalize, normalize } from 'normalizr';

import { eventsSchema } from './Entities';

/**
 * As the Event entity uses processStrategy() to modify normalize() output,
 * this entity does not build the denormalized data exactly as the API response,
 * but renames 'market' field to 'default_market'.
 *
 * @param entities
 * @param keys
 */
export const denormalizeEvents = (entities, keys) => {
	return denormalize(keys || Object.keys(entities.events), eventsSchema, entities).filter((event) => !!event);
};

/**
 * Normalize the tournament structure to suit JS Applications
 *
 * @param response
 */
export const normalizeEvents = (response) => {
	return normalize(response, eventsSchema);
};
