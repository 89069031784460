import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from '../../../store/application/applicationActions';

class Route extends React.PureComponent {
	static propTypes = {
		/** The current route for the application */
		route: PropTypes.string.isRequired,

		/** Navigation configuration */
		config: PropTypes.object,

		/** Navigation meta data */
		meta: PropTypes.any,

		/** Whether the marionette routing should be triggered */
		trigger: PropTypes.bool,

		/** If the next route should be replaced */
		replaceThisRoute: PropTypes.bool,
	};

	static defaultProps = {
		config: {},
		meta: false,
		trigger: false,
		replaceThisRoute: false,
	};

	constructor(props) {
		super(props);
		this.state = {
			replace: true,
			replaceMeNextRoute: props.replaceThisRoute,
		};
	}

	componentDidMount() {
		this.route();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.state.replaceMeNextRoute && !nextProps.replaceThisRoute) {
			this.setState({
				replace: true,
				replaceMeNextRoute: false,
			});
		} else if (nextProps.replaceThisRoute) {
			this.setState({
				replace: false,
				replaceMeNextRoute: true,
			});
		} else if (this.state.replace || this.state.replaceMeNextRoute) {
			this.setState({
				replace: false,
				replaceMeNextRoute: false,
			});
		}
	}

	componentDidUpdate() {
		this.route();
	}

	/**
	 * Navigates to the route given on props.
	 */
	route() {
		const { config, route, trigger } = this.props;

		const backboneFragment = Backbone.history.getFragment();
		const fragment = (backboneFragment[0] === '/' ? '' : '/') + backboneFragment; // Ensure the first character is a slash

		// Don't navigate to the route if we are already on the route
		if (fragment !== route) {
			const _config = {
				...config,
			};

			if (trigger) {
				_config.trigger = trigger;
			}

			if (this.state.replace) {
				_config.replace = true;
			}

			App.store.dispatch(navigate(route, _config, this.props.meta));
		}
	}

	render() {
		return null;
	}
}

export default Route;
