import {UPDATE_DEFAULT_BET_AMOUNTS} from './defaultBetAmountsActionsTypes';
import {CLEAR_AUTHENTICATED_USER} from '../application/applicationActionTypes';


const initialState = {
	default_bet_amount: 0,
	default_tournament_bet_amount: 0,
	accepted_live_bet_warning: false
};

const defaultBetAmounts = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_DEFAULT_BET_AMOUNTS:
			return {
				...state,
				...action.payload
			};

		case CLEAR_AUTHENTICATED_USER:
			return {
				...initialState
			};

		default:
			return state;
	}
};

export default defaultBetAmounts;