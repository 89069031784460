import { throttle } from 'lodash-es';
import configureStore from '../store/index';
import env from '../common/EnvironmentVariables';
import ReactDOM from 'react-dom';
import ContextContainer from '../containers/Application/Context/ContextContainer';
import { Provider } from 'react-redux';
import App from '../App';
import { injectGlobal } from 'react-emotion';

injectGlobal`
	html, body, div {
		margin: 0;
		padding: 0;
		border: 0;
		vertical-align: baseline;
	}

	*,
	*:before,
	*:after {
		box-sizing: border-box;
	}

	body {
		
	}
`;


window.propDiff = global.propDiff = require('../common/PropDiff');
const { getReducerStoredLocally } = require('../common/reducers/LocalStorageReducer');
let preloadedState = getReducerStoredLocally();

const React = require('react');
global.App = require('./app');
global.Store = {};

const loadState = () => {
	try {
		const state = localStorage.getItem('tb_state');
		if (!state) {
			return undefined;
		}
		return JSON.parse(state);
	} catch (err) {
		return undefined;
	}
};

const saveState = (state) => {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem('tb_state', serializedState);
	} catch (err) {
		console.warn('Could not save state', err);
	}
};

const persist = env('persistStateInLocalStorage', false);

const store = (global.App.store = configureStore(persist ? loadState() : preloadedState));

if (persist) {
	store.subscribe(
		throttle(() => {
			saveState(store.getState());
		}, 1000),
	);
}

// The routes cannot be added until after the Application is started as we need to initialise manually
// as we are no longer extending a marionette class
require('../routers/index');
ReactDOM.render(
	<Provider store={store}>
		<ContextContainer contextList="FeatureContext">
			<ContextContainer contextList="AclContext">
				<ContextContainer contextList="ThemeContext">
					<App />
				</ContextContainer>
			</ContextContainer>
		</ContextContainer>
	</Provider>,
	document.getElementById('root'),
);
