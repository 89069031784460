// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';

// Components
import { Text } from '@tbh/ui-kit';

const UserVerifiedText = ({ className, tag, verified, t }) => {
	const componentClasses = cx({
		[className]: className,
	});

	return (
		<Text className={componentClasses} tag={tag} size="-1" type={verified ? 'success' : 'danger'} strong>
			{verified ? t('Yes') : t('No')}
		</Text>
	);
};

UserVerifiedText.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Whether or not the current user is verified or not */
	verified: PropTypes.bool.isRequired,

	/** The type of element to use for the Text component */
	tag: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,
};

UserVerifiedText.defaultProps = {
	tag: 'span',
	className: '',
};

export default withNamespaces()(UserVerifiedText);
