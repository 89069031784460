/**
 * Created by @HW on 24Jan2020
 */
'use strict';
// Functions

// constants
import { GRS_SPORTS_BASE_URL } from '../pages/GRSSports/GRSSportsConstants';

/**
 * Builds route to a sports-v3.
 *
 * @returns {String}
 */
export const buildRouteToGRSSports = () => {
    return `/${GRS_SPORTS_BASE_URL}`;
};

