export const MOBILE_AUTHENTICATION = 'MOBILE_AUTHENTICATION';
export const MOBILE_VERIFICATION = 'MOBILE_VERIFICATION';
export const EMAIL_AUTHENTICATION = 'EMAIL_AUTHENTICATION';
export const EMAIL_VERIFICATION = 'EMAIL_VERIFICATION';

export const USER_DETAILS_FORM = 'USER_DETAILS_FORM';
export const USER_ADDRESS_FORM = 'USER_ADDRESS_FORM';
export const REGISTRATION_CONFIRMATION = 'REGISTRATION_CONFIRMATION';
export const GREENID_VERIFICATION = 'GREENID_VERIFICATION';
export const FIRST_DEPOSIT = 'FIRST_DEPOSIT';
export const SET_LIMITS = 'SET_LIMITS';
