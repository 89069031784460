import { denormalize, normalize } from 'normalizr';
import { tournamentSchema, tournamentsSchema } from './Entities';

/**
 * Convert normalized tournament entity back to a structure similar to the API response
 *
 * @param entities
 * @param tournamentId
 */
const denormalizeTournament = (entities, tournamentId) => {
	let tournament = entities.tournaments[tournamentId];
	if (!tournament) {
		throw new Error(`Cannot find tournament entity with id of ${tournamentId}`);
	}
	return denormalize(tournamentId, tournamentSchema, entities);
};

/**
 * Convert normalized tournament entities back to a structure similar to the API response
 *
 * @param entities
 */
const denormalizeTournaments = (entities) => {
	return denormalize(Object.keys(entities.tournaments), tournamentsSchema, entities);
};

/**
 * Normalize the tournament structure to suit JS Applications
 *
 * @param tournamentsResponse
 */
const normalizeTournaments = (tournamentsResponse) => {
	return normalize(tournamentsResponse, tournamentsSchema);
};

module.exports = {
	denormalizeTournament,
	denormalizeTournaments,
	normalizeTournaments,
};
