/**
 * Libraries
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { SizeMe } from 'react-sizeme';
// import { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
/**
 * Functions
 */
import { createErrorBoundary } from '../../../containers/ErrorBoundary/ErrorBoundaryActions';

/**
 * Components
 */
import Route from '../../../components/controllers/Route/Route';
// import { PlotElements } from '@tbh/ui-kit';

import { LoadingSpanner } from '../../../components/features/Application/LoadingSpinner/LoadingSpinner';
import DocumentTitle from '../../../components/controllers/Meta/DocumentTitle';
import DescriptionMeta from '../../../components/controllers/Meta/DescriptionMeta';
import { serverDateTime } from '../../../../src/legacy/core/format';

import * as BBH from './BetBuilderHome.styled';

/**
 * Schemas
 */
import { buildBetBuilderRouteFromState } from '../../../routes/BetBuilder';

import {
	getSelectedDate,
	navigateToBetBuilderWithDate,
	navigateToBetBuilderWithMeetingId,
	resetRacingFilters,
	setSelectedBuilderMeeting,
} from '../../../store/betBuilder/betBuilderHomeAction';
import { getSelectedMeeting, getBetbuilderSelectedMeeting, getMeetingsWithOpenRaces } from './BetBuilderSelectors';

import { fetchActiveBets } from '../../../store/entities/actions/BetActions';
// import { buildCustomizedMeetingsList } from '../../../pages/BetBuilder/BetBuilderHome/BetBuilderSelectors';
// import MeetingNameSelect from '../../../components/features/Racing/MeetingNameSelect/MeetingNameSelect';
import { routeTo } from '../../../store/application/applicationActions';
import BetBuilderRaceListContainer from '../../../containers/Racing/BetBuilderRaceListContainer/BetBuilderRaceListContainer';

// import TopNavigation from '../../../components/features/Application/TopNavigation/TopNavigation';
import { FEATURE_RACING_BASE_URL, FEATURE_RACING_SUB_APP } from '../../../pages/FeatureRacing/FeatureConstants';
import { RACING_NEW_SUB_APP, RACING_NEW_BASE_URL } from '../../../pages/GRSNewRacing/RacingNewConstrants';
import { BETBUILDER_SUB_APP, BETBUILDER_BASE_URL } from '../../../pages/BetBuilder/BetBuilderConstants';
// import RaceMultiCountryFilter from '../../../components/features/Racing/RaceCountryFilter/RaceMultiCountryFilter';
import { trackDateSelection, trackCountry } from '../../../store/betBuilder/betBuilderHomeTrackingActions';
import {
	meetingsOfTypeCountByCountryCode,
	// filterCoutryWiseMeetings,
} from '../../../store/entities/selectors/MeetingSelectorsGRS';
import { SPORTS_SUB_APP, SPORTS_BASE_URL } from '../../../pages/Sports/SportsConstants';
import { PROMOTIONS_BASE_URL, PROMOTIONS_SUB_APP } from '../../../routers/PromotionsRouterConstants';
import { fetchBetBulderMeetingWithRaceSelections } from '../../../store/entities/actions/MeetingActions';
import { StyledPageComponentContainer } from '../../../components/features/Application/PageComponentContainer/PageComponentContainer.styled';
import { batchActions } from 'redux-batched-actions';

class BetBuilderHome extends React.Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,
		/** Function to mark that the container has stopped loading */
		setContainerHasLoaded: PropTypes.func.isRequired,
		/** Current registration step */
		currentRoute: PropTypes.string,
		/** Show routing and meta components from the page */
		useRouting: PropTypes.bool,

		/** Navigate to betbuilder home with a given meeting id or not. */
		navigateToBetbuilderHome: PropTypes.func.isRequired,

		/** Loading mask to cover both meetings and races lists. */
		loadingContainer: PropTypes.bool,

		/** Brand name */
		brandName: PropTypes.string,

		/** Watch for selected meeting to either render races list or meeting. */
		selectedMeeting: PropTypes.number,

		// meetingsLists .
		meetingsLists: PropTypes.array.isRequired,

		/** Entity for selected meeting */
		meeting: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			state: PropTypes.string,
			country: PropTypes.string,
			type: PropTypes.string.isRequired,
			start_date: PropTypes.string.isRequired,
			races: PropTypes.array,
		}),

		selectedBetBuilderMeeting: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			state: PropTypes.string,
			country: PropTypes.string,
			type: PropTypes.string.isRequired,
			start_date: PropTypes.string.isRequired,
			races: PropTypes.array,
		}),

		/** Watch for authenticatedUser to fetch their bets. */
		authenticatedUser: PropTypes.number,

		/** ac; data */
		acl: PropTypes.shape(),

		/** Is the app loaded on a phone type device */
		isPhoneDevice: PropTypes.bool,

		/** Attempt to fetch the logged in users active bets */
		fetchActiveBets: PropTypes.func.isRequired,

		navigateToBetBuilder: PropTypes.func.isRequired,

		/** REST call to API to fetch and set state of betbuilder */
		fetchBetBulder: PropTypes.func,

		navigateToGRSFeature: PropTypes.func,

		selectedPageKey: PropTypes.string.isRequired,

		/** Undefined for today's races. */
		selectedDate: PropTypes.instanceOf(moment),

		navigateToBetBuilderWithDate: PropTypes.func,

		navigateToSports: PropTypes.func,

		navigateToTodayRacing: PropTypes.func,

		navigateToPromos: PropTypes.func,
	};

	static defaultProps = {
		brandName: '',
		currentRoute: null,
		useRouting: true,
		loadingContainer: false,
		selectedMeeting: null,
		isPhoneDevice: false,
		acl: {},
		authenticatedUser: null,
		fetchBetBulder: () => { },
		selectedBetBuilderMeeting: undefined,
		navigateToGRSFeature: () => undefined,
		navigateToSports: () => undefined,
		navigateToTodayRacing: () => undefined,
		selectedDate: undefined,
		navigateToBetBuilderWithDate: undefined,
		meeting: undefined,
		navigateToPromos: () => undefined,
	};

	constructor(props) {
		super(props);
		this.state = {
			loadingMask: false,
			isMobile: props.isPhoneDevice,
			meetingId: this.props.selectedMeeting,
			typeFilter: new Set([]),
		};
	}

	componentDidMount() {
		if (this.props.authenticatedUser) {
			this.props.fetchActiveBets();
		}

		if (!this.props.selectedBetBuilderMeeting) {
			this.props.fetchBetBuilderMeeting(this.props.selectedMeeting);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.authenticatedUser && prevProps.authenticatedUser !== this.props.authenticatedUser) {
			this.props.fetchActiveBets();
		}

	}

	/**
	 * Updates selected meeting
	 * @param inputName
	 * @param index
	 *
	 */
	handleChangeSelectedMeeting = (inputName, index) => {
		const { selectedDate } = this.props;
		//console.log(index);
		this.setState({
			meetingId: index,
		});

		this.props.fetchBetBulder(selectedDate, index);
		this.props.navigateToBetBuilder(selectedDate, index);
	};

	/**
	 * Navigate to today racing
	 */
	setTodayRacing = () => {
		this.props.navigateToTodayRacing();
	};

	handleGoToFeature = () => {
		this.props.navigateToGRSFeature();
	};

	/**
	 * Navigates to the selected bet builder meetingId
	 *
	 * @param meetingId
	 */
	handleGoToBetBulder = (meetingId) => {
		this.props.navigateToBetBuilder(meetingId);
	};

	/**
	 * Navigate to sports
	 */
	handleToSports = () => {
		this.props.navigateToSports();
	};

	/**
	 * Navigate to promotions page
	 */
	handleToPromos = () => {
		this.props.navigateToPromos();
	};

	/**
	 * Re-builds racing home with races for given date
	 * @param {moment} newDate
	 * @param {string} trackingLabel - for tracking purpose
	 */
	setSelectedDate = (newDate) => {
		this.props.navigateToBetBuilderWithDate(newDate);
	};

	/**
	 * Handles the clicking/selecting of the  sportId filters
	 * @param filterCountryCode
	 */
	handleOnFilterClick = (filterCountryCode) => {
		this.saveTypeToFilters(filterCountryCode);
		this.props.trackOnFilterClick(filterCountryCode);
	};

	/**
	 * Saves the race type filters to state
	 * @param type
	 */
	saveTypeToFilters = (code) => {
		let codes = new Set(this.state.typeFilter);

		if (!code) {
			return;
		}

		if (codes.has(code)) {
			codes.delete(code);
		} else {
			codes.add(code);
		}

		this.setState({
			typeFilter: codes,
		});
	};

	resetFilterClick = () => {
		this.setState({ typeFilter: new Set([]) });
	};

	render() {
		const {
			t,
			currentRoute,
			useRouting,
			loadingContainer,
			brandName,
			meeting,
			meetingsLists,
			selectedMeeting,
			selectedBetBuilderMeeting,
			selectedPageKey,
			selectedDate,
		} = this.props;

		const { isMobile, meetingId } = this.state;

		const meetingCountByCountryCode =
			meetingsLists.length > 0 ? meetingsOfTypeCountByCountryCode(meetingsLists) : meetingsLists;

		const filteredCountryList = Array.from(this.state.typeFilter);
		const allActive = meetingCountByCountryCode.length == filteredCountryList.length;

		const buttonStyles = {
			all: {
				button: allActive === false ? 'primary' : 'secondary',
				text: 'alternate',
			},
		};

		const meetingDate = serverDateTime(selectedDate).format('YYYY-MM-DD');
		let currentDate = moment().format('YYYY-MM-DD');

		const diffFromToday = (day) =>
			serverDateTime(moment())
				.startOf('day')
				.diff(serverDateTime(day).startOf('day'), 'days');

		const getHeaderText = (day) => {
			if (!day) {
				return t("RacingHomePage__Today's");
			}

			switch (diffFromToday(day)) {
				case 0:
					return t("RacingHomePage__Today's");
				case -1:
					return t("RacingHomePage__Tomorrow's");
				case 1:
					return t("RacingHomePage__Yesterday's");
				default:
					return day.format('dddd MMMM Do YYYY');
			}
		};

		return (
			<SizeMe>
				{({ size }) => (
					<BBH.StyledBetBuilderHomePage>
						{/* set page title and meta data*/}
						{useRouting && (
							<React.Fragment>
								<Route route={currentRoute} />
								<DocumentTitle>{`${t('BetBuilderPage_Title')}${brandName ? ` ${t('at')} ${brandName}` : ''
									}`}</DocumentTitle>
								<DescriptionMeta>
									{`${t('BetBuilderPage_Description')}${brandName ? ` ${t('with')} ${brandName}` : ''}. `}
								</DescriptionMeta>
							</React.Fragment>
						)}

						<LoadingSpanner style={{ display: loadingContainer ? 'block' : 'none' }} />

						<StyledPageComponentContainer>
							<BBH.StyledBetBuilderHomePage__BetBuilder_Content size={size}>
								<BBH.StyledBetBuilderHomePage___Card__Body size={size}>
									{/* <PlotElements align="end" alignElements="baseline">
										<BBH.StyledBetbuiderHomePage__Title mediaSize={size} uppercase strong>
											{`${getHeaderText(selectedDate)} ${t('BetBuilderPage__Meeting')}`}
										</BBH.StyledBetbuiderHomePage__Title>
									</PlotElements> */}

									<BBH.StyledBetBuilderHomePage__Header_section>
										<BBH.StyledBetBuilderHomePage__maijor_header>
											<BBH.StyledBetBuilderHomePage__Major_header_h2>
												{t('BetBuilder_Header_Title')}
											</BBH.StyledBetBuilderHomePage__Major_header_h2>

											{/* {meetingsLists.length > 0 && (
											<div
												className={css`
													flex: 0 0 23%;
													max-width: 23%;
												`}
											>
												<MeetingNameSelect
													meetingLists={
														meetingsLists.length > 0
															? filterCoutryWiseMeetings(meetingsLists, Array.from(this.state.typeFilter))
															: meetingsLists
													}
													onSelectMeetingName={this.handleChangeSelectedMeeting}
													meeting_id={this.state.meetingId}
													meeting={meeting}
												/>
											</div>
										)} */}
											<BBH.StyledBetBuilderHomePage__h2>
												<BBH.StyledBetBuilderHomePage__Title
													mediaSize={size}
													uppercase
													strong
												></BBH.StyledBetBuilderHomePage__Title>
											</BBH.StyledBetBuilderHomePage__h2>
										</BBH.StyledBetBuilderHomePage__maijor_header>
									</BBH.StyledBetBuilderHomePage__Header_section>

									{/* load betbuilder racelist container           */}
									{
										<BetBuilderRaceListContainer
											size={size}
											meetingId={meetingId}
											meeting={meeting}
											selectedBetBuilderMeeting={selectedBetBuilderMeeting}
										/>
									}
								</BBH.StyledBetBuilderHomePage___Card__Body>
							</BBH.StyledBetBuilderHomePage__BetBuilder_Content>
						</StyledPageComponentContainer>

					</BBH.StyledBetBuilderHomePage>
				)}
			</SizeMe>
		);
	}
}

const mapStateToProps = (state, ownProps) => {

	const meetingsLists = getMeetingsWithOpenRaces(state);
	const selectedMeeting = meetingsLists.length > 0 ? meetingsLists[0].id : null;

	return {
		meetingsLists,
		selectedMeeting,
		meeting: state.betBuilderHome.selectedMeeting ? getSelectedMeeting(state) : null,
		selectedBetBuilderMeeting: state.betBuilderHome.selectedBuilderMeeting ? getBetbuilderSelectedMeeting(state) : null,
		currentRoute: buildBetBuilderRouteFromState(state),
		isFullWidth: state.featureToggles.features.fullWidth.enabled,
		brandName: state.acl.brandDetails && state.acl.brandDetails.name,
		showNtjRaces: state.featureToggles.features.ntjRaces.enabled,
		selectedPageKey: state.application.selectedPage,
		selectedDate: getSelectedDate(state),

		useRouting:
			typeof ownProps.useRouting === 'undefined'
				? state.featureToggles.features.urlRouting.enabled
				: ownProps.useRouting,
	};
};

/**
 * Map dispatch methods to props
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch,ownProps) => {
	return {
		navigateToBetbuilderHome: () =>
			dispatch(
				//navigateToBetbuilderHome()
				ownProps.useRouting
					? dispatch(routeTo('/betbuilder'))
					: dispatch(navigateToBetBuilderWithDate(serverDateTime(new Date()).format('YYYY-MM-DD'))),
			),
		fetchActiveBets: () => dispatch(fetchActiveBets()),

		navigateToBetBuilderWithDate: (newDate, label, isRacingHome) => {
			dispatch(trackDateSelection(label, isRacingHome));
			dispatch(navigateToBetBuilderWithDate(newDate));
		},

		fetchBetBulder: (newDate, meetingId) => {
			dispatch(navigateToBetBuilderWithDate(newDate));
			dispatch(navigateToBetBuilderWithMeetingId(newDate, meetingId));
		},

		// fetchBetBulder: (newDate, meetingId) => {
		//   dispatch(fetchBetBulderMeetingWithRaceSelections(meetingId)).then((response) => {
		//      console.log(response.data);
		//     dispatch(
		//       batchActions(
		//         [ setSelectedBuilderMeeting(response.data.data.id), setSelectedDate(serverDateTime(response.data.data.start_date).format('YYYY-MM-DD'))]
		//       ),
		//     );
		//   });

		// },

		// navigate to bet builder
		navigateToBetBuilder: (meetingId) => {
			dispatch(
				routeTo('/' + BETBUILDER_BASE_URL + '/' + serverDateTime(new Date()).format('YYYY-MM-DD') + '/' + meetingId),
			);
			App.startSubApp(BETBUILDER_SUB_APP);
		},

		navigateToTodayRacing: () => {
			dispatch(routeTo('/' + RACING_NEW_BASE_URL));
			App.startSubApp(RACING_NEW_SUB_APP);
		},

		navigateToGRSFeature: () => {
			dispatch(routeTo('/' + FEATURE_RACING_BASE_URL));
			App.startSubApp(FEATURE_RACING_SUB_APP);
		},

		navigateToSports: () => {
			dispatch(routeTo('/' + SPORTS_BASE_URL));
			App.startSubApp(SPORTS_SUB_APP);
		},

		navigateToPromos: () => {
			dispatch(routeTo('/' + PROMOTIONS_BASE_URL));
			App.startSubApp(PROMOTIONS_SUB_APP);
		},
		fetchBetBuilderMeeting: async (meetingId) => {
			const response = await dispatch(fetchBetBulderMeetingWithRaceSelections(meetingId));
			dispatch(batchActions([
				resetRacingFilters(response.data.data.id),
				setSelectedBuilderMeeting(response.data.data.id)]));
		},
		trackOnFilterClick: (type, isRacingHome) => dispatch(trackCountry('Filter', type, isRacingHome)),
	};
};

export default withNamespaces()(createErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(BetBuilderHome)));
