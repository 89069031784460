// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames/bind';

// Actions
import { getDefaultBetAmounts, setDefaultBetAmounts } from '../../../store/defaultBetAmounts/defaultBetAmountsActions';
import { scrollToTop } from '../../../store/application/applicationActions';

// Components
import DefaultBetAmounts from '../../../components/features/ResponsibleGambling/DefaultBetAmounts/DefaultBetAmounts';

class DefaultBetAmountsContainer extends Component {
	static propTypes = {
		/**
		 * user's first name
		 */
		first_name: PropTypes.string.isRequired,

		/**
		 * hook for getting the default bet amounts
		 */
		getDefaultBetAmounts: PropTypes.func.isRequired,

		/**
		 * hook for updating the default bet amounts
		 */
		setDefaultBetAmounts: PropTypes.func.isRequired,

		/** Extra classes */
		className: PropTypes.string,

		/**
		 * user's default bet amount
		 */
		default_bet_amount: PropTypes.number,

		/**
		 * user's default tournament bet amount
		 */
		default_tournament_bet_amount: PropTypes.number,

		/**
		 * user's default tournament bet amount
		 */
		accepted_live_bet_warning: PropTypes.bool,
	};

	static defaultProps = {
		className: '',
		default_bet_amount: 0,
		default_tournament_bet_amount: 0,
		accepted_live_bet_warning: false,
	};

	state = {
		confirmation: false,
		defaultAmountsLoading: false,
		error: '',
		amountsUpdateLoading: false,
	};

	componentDidMount() {
		/**
		 * Load the default bet amounts
		 * Set a loading mask while loading the default bet amounts
		 * Remove when done
		 */
		this.setLoadingMask(true, 'defaultAmountsLoading');
		return this.props.getDefaultBetAmounts().finally(() => {
			this.setLoadingMask(false, 'defaultAmountsLoading');
		});
	}

	/**
	 * Set a loading mask
	 *
	 * @param loading
	 * @param region
	 */
	setLoadingMask(loading = false, region) {
		this.setState({
			[region]: loading,
		});
	}

	/**
	 * Update the default bet amounts
	 *
	 * @param default_bet_amount
	 * @param default_tournament_bet_amount
	 * @param accepted_live_bet_warning
	 */
	handleUpdateAmounts = (default_bet_amount, default_tournament_bet_amount, accepted_live_bet_warning) => {
		this.setLoadingMask(true, 'amountsUpdateLoading');

		this.props
			.setDefaultBetAmounts(default_bet_amount, default_tournament_bet_amount, accepted_live_bet_warning)
			.then(() => {
				this.setState({
					error: null,
					confirmation: true,
				});
			})
			.catch((error) => {
				if (error.response.status === 400) {
					this.setState({
						error: error.response.data.errors,
					});
				} else {
					this.setState({
						error: error.message,
					});
				}
			})
			.finally(() => {
				this.setLoadingMask(false, 'amountsUpdateLoading');
				scrollToTop();
			});
	};

	/**
	 * Remove the error message
	 */
	handleClearMessage = () => {
		this.setState({
			error: null,
			confirmation: false,
		});
	};

	render() {
		const {
			className,
			first_name,
			default_bet_amount,
			default_tournament_bet_amount,
			accepted_live_bet_warning,
		} = this.props;

		const { confirmation, defaultAmountsLoading, amountsUpdateLoading, error } = this.state;

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<DefaultBetAmounts
				className={componentClasses}
				confirmation={confirmation}
				defaultAmountsLoading={defaultAmountsLoading}
				first_name={first_name}
				updateDefaultBetAmounts={this.handleUpdateAmounts}
				default_bet_amount={default_bet_amount}
				default_tournament_bet_amount={default_tournament_bet_amount}
				accepted_live_bet_warning={accepted_live_bet_warning}
				error={error}
				clearMessage={this.handleClearMessage}
				amountsUpdateLoading={amountsUpdateLoading}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		first_name: state.entities.users[state.application.authenticatedUser].first_name,
		default_bet_amount: state.defaultBetAmounts.default_bet_amount,
		default_tournament_bet_amount: state.defaultBetAmounts.default_tournament_bet_amount,
		accepted_live_bet_warning: state.defaultBetAmounts.accepted_live_bet_warning,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getDefaultBetAmounts: () => {
			return dispatch(getDefaultBetAmounts());
		},
		setDefaultBetAmounts: (default_bet_amount, default_tournament_bet_amount, accepted_live_bet_warning) => {
			return dispatch(
				setDefaultBetAmounts(default_bet_amount, default_tournament_bet_amount, accepted_live_bet_warning),
			);
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(DefaultBetAmountsContainer);
