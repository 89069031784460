/**
 * Find an article from an array of articles that matches the supplied article slug
 *
 * @param articles
 * @param slug
 */
export const findArticleBySlug = (articles, slug) => {
	return articles.find((article) => {
		return article.slug === slug;
	});
};
