import React from 'react';

import ErrorBoundaryContainer from './ErrorBoundaryContainer';

/**
 * This is a custom function that you can wrap your connect export with to build an error boundary around your container
 *
 * @param Component
 * @param errorBoundaryProps
 * @returns <HigherOrderComponent />
 */
export const createErrorBoundary = (Component, errorBoundaryProps = {}) => {
	return class extends React.Component {
		render() {
			return (
				<ErrorBoundaryContainer {...errorBoundaryProps}>
					<Component {...this.props} />
				</ErrorBoundaryContainer>
			);
		}
	};
};