import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames/bind';
import styled from 'react-emotion';

import { modifyDataTable } from '../../../store/dataPaginator/dataPaginatorActions';
import { DATA_PAGINATOR_DEFAULT } from '../../../store/dataPaginator/dataPaginatorReducerNames';

import { LoadingMask, Table, DataToTable, PlotElements } from '@tbh/ui-kit';


const OffLayLoading = styled(LoadingMask)`
	z-index: 0;
`;

class DataTableContainer extends Component {
	static propTypes = {
		/** Action to fire when the table is paginated */
		changeTablePage: PropTypes.func.isRequired,

		/** List of children to display in the table **/
		children: PropTypes.node,

		/** An action you may want to pass down as props to children elements */
		action: PropTypes.func,

		/** Extra classes */
		className: PropTypes.string,

		/** The data for the data table (can be used to hold all data) */
		data: PropTypes.array,

		/** A list of headers you may want to display */
		headers: PropTypes.array,

		/** The filtered data for the current view */
		filteredData: PropTypes.array,

		/** The current page */
		page: PropTypes.number,

		/** The total number of pages */
		totalPages: PropTypes.number,

		/** How many items to display on each page */
		size: PropTypes.number,

		/** If the data table is in a loading state */
		loading: PropTypes.bool,

		/** The name of the reducer slice you want to use to store data */
		reducerName: PropTypes.string,
	};

	static defaultProps = {
		action: () => { },
		children: <DataToTable />,
		className: null,
		data: [],
		filteredData: [],
		headers: [],
		page: 1,
		size: 10,
		loading: false,
		totalPages: null,
		reducerName: DATA_PAGINATOR_DEFAULT,
	};

	componentDidMount() {
		this.props.changeTablePage(this.props.page, this.props.size, this.props.data);
	}

	render() {
		const { className, changeTablePage, loading, page, data, size, totalPages } = this.props;

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<PlotElements className={componentClasses} alignElements="stretch" vertical>
				{loading && <OffLayLoading topOffset={150} displayAtTop loading={loading} />}
				{React.cloneElement(this.props.children, { ...this.props })}
				<Table.TP action={changeTablePage} page={page} data={data} size={size} totalPages={totalPages} />
			</PlotElements>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const reducerName = ownProps.reducerName || DATA_PAGINATOR_DEFAULT;

	return {
		page: ownProps.page || state[reducerName].page,
		totalPages: ownProps.totalPages || state[reducerName].totalPages,
		size: ownProps.size || state[reducerName].size,
		loading: ownProps.loading || state[reducerName].loading,
		data: ownProps.data && ownProps.data.length ? ownProps.data : state[reducerName].data,
		headers: ownProps.headers && ownProps.headers.length ? ownProps.headers : state[reducerName].headers,
		filteredData:
			ownProps.filteredData && ownProps.filteredData.length ? ownProps.filteredData : state[reducerName].filteredData,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	const reducerName = ownProps.reducerName || DATA_PAGINATOR_DEFAULT;

	return {
		changeTablePage: (page, size, data) =>
			typeof ownProps.changeTablePage === 'function'
				? ownProps.changeTablePage(page, size, data)
				: dispatch(modifyDataTable(reducerName, page, size, data)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(DataTableContainer);
