import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { css } from 'react-emotion';
import { Text } from '@tbh/ui-kit';
import moment from 'moment';
import { FONT_SIZE_12 } from '../../../../store/application/applicationConstants';
import {
	StyledUpCarouselItem,
	StyledNJRaceHeaderBar,
	StyledNJRaceHeaderBar__Heading,
	StyledRaceHeaderBar__Heading_raceNumber,
	StyledRaceHeaderBar__Heading_flagIcon,
	StyledRaceHeaderBar__Heading_More,
	StyledNJRaceHeaderBar__Metting__Name,
	StyledNJRaceHeaderBar__Flage__Details,
	StyledNextToJumpCardItem__TimeToGoBUtton,
} from './UpCarouselItem.styled';
import CountryImage from '../../Racing/CountryImage/CountryImage';
import RacingIcon from '../../Racing/RacingIcon/RacingIcon';

const UpCarouselItem = (props) => {
	const {
		className,
		raceNumber,
		children,
		id,
		label,
		text,
		meta,
		icon,
		image,
		action,
		OpenNext2JumpItem,
		meetingStateText,
		raceText,
		showSelections,
		distance,
		country,
		start_datetime,
		size,
		lessThan2Min,
		...rest
	} = props;
	const carouselItemClassv3 = cx({
		[className]: className,
	});

	const componentClassesv3 = cx({
		[className]: className,
	});

	const handleClick = (e) => {
		e.preventDefault();
		action(id);
	};

	if (children) {
		return (
			<StyledUpCarouselItem {...rest} className={carouselItemClassv3}>
				{children}
			</StyledUpCarouselItem>
		);
	}
	const countryImageCss = css`
		width: 100%;
	`;

	const newText = text.length > 20 ? text.substring(0, 20) + '...' : text;

	return (
		<StyledUpCarouselItem size={'0'} {...rest} className={carouselItemClassv3} action={handleClick}>
			<StyledNJRaceHeaderBar noPadding className={componentClassesv3} margin={false} country={country}>
				<StyledNJRaceHeaderBar__Heading>
					<StyledRaceHeaderBar__Heading_raceNumber>
						<div
							className={css`
								color: #ffffff;
								background: #666666;
								border: 1px solid #666666;
								border-bottom: 2px solid #5c5c5c;
								text-align: center;
								margin-top: 8px;
								border-radius: 3.5px;
								font-size: 16px;
								padding: 2px;
							`}
						>
							{raceNumber}
						</div>
					</StyledRaceHeaderBar__Heading_raceNumber>

					<StyledRaceHeaderBar__Heading_flagIcon>
						<div>
							<RacingIcon type={icon} />
						</div>
						<div>
							<CountryImage country={country} className={countryImageCss} />
						</div>
					</StyledRaceHeaderBar__Heading_flagIcon>

					<StyledRaceHeaderBar__Heading_More
						className={css`
							display: flex;
							flex-direction: column;
							align-items: center; // Center-align the content horizontally
							margin-left: 0;
							max-width: 100%;
						`}
					>
						<div
							className={css`
								display: flex;
								flex-direction: column;
								align-items: flex-start; // Align items to the start (left)
								width: 100%; // Ensures it takes full width of the parent
							`}
						>
							<StyledNJRaceHeaderBar__Metting__Name
								className={css`
									width: 100%;
									display: flex;
									justify-content: flex-start; // Align text to the left
									padding-left: 2px;
								`}
							>
								<Text
									className={css`
										color: #ffffff !important;
										text-align: left;
										overflow: hidden;
										font-size: ${FONT_SIZE_12};
										font-weight: 600;
										text-overflow: ellipsis;
										padding-left: 6px;
									`}
									size="-2"
								>
									{newText + ' - ' + distance + 'm'}
								</Text>
							</StyledNJRaceHeaderBar__Metting__Name>

							<StyledNJRaceHeaderBar__Flage__Details
								className={css`
									width: 100%; // Ensures it takes full width of the parent
									display: flex;
									align-items: center; // Align items vertically center
									margin-top: 8px; // Adds space between the name and the details
									justify-content: flex-start; // Aligns content to the start (left)
								`}
							>
								<div
									className={css`
										flex: 0 0 70%;
										max-width: 70%;
										display: flex;
										align-items: center; // Align the text vertically center
									`}
								>
									<Text
										className={css`
											color: #ffffff !important;
											text-align: left;
											padding-left: 8px;
											font-size: ${FONT_SIZE_12};
											align-items: center;
										`}
										size="-2"
									>
										{moment(start_datetime).calendar()}
									</Text>
								</div>
							</StyledNJRaceHeaderBar__Flage__Details>
						</div>

						<div
							className={css`
								margin-top: 2px; // Adds space between the details block and the button
								display: flex;
								justify-content: right; // Center the button horizontally
								width: 100%; // Ensures it takes full width of the parent
							`}
						>
							<StyledNextToJumpCardItem__TimeToGoBUtton lessThan2Min={lessThan2Min}>
								{meta}
							</StyledNextToJumpCardItem__TimeToGoBUtton>
						</div>
					</StyledRaceHeaderBar__Heading_More>
				</StyledNJRaceHeaderBar__Heading>
			</StyledNJRaceHeaderBar>
		</StyledUpCarouselItem>
	);
};
UpCarouselItem.propTypes = {
	/** ID of the carousel item */
	id: PropTypes.any.isRequired,

	/** Extra classes */
	className: PropTypes.string,

	/** Label for the carousel item */
	label: PropTypes.string,

	/** Text for the carousel item */
	text: PropTypes.string,

	/** Extra information for the carousel item */
	meta: PropTypes.string,

	/** Icon to display in the carousel item */
	icon: PropTypes.string,

	/** Image to display in the carousel item */
	image: PropTypes.string,

	/** How many slides to display */
	slides: PropTypes.string,

	/** If the carousel item should be centered */
	center: PropTypes.bool,

	/** If the carousel item should have padding */
	padding: PropTypes.bool,

	/** If the carousel item should have a margin */
	margin: PropTypes.bool,

	/** If the carousel item should have a border */
	border: PropTypes.bool,

	/** Action when the carousel item is clicked on */
	action: PropTypes.func,

	/** Children to display under the carousel item */
	children: PropTypes.node,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}),

	/** Whether to use a small amount of flex to fit more buttons */
	small: PropTypes.bool,

	meetingStateText: PropTypes.string,

	raceText: PropTypes.string,

	start_datetime: PropTypes.string,

	distance: PropTypes.string,

	raceNumber: PropTypes.number,

	lessThan2Min: PropTypes.string,
};

UpCarouselItem.defaultProps = {
	className: '',
	label: undefined,
	text: undefined,
	meta: undefined,
	icon: undefined,
	image: undefined,
	slides: undefined,
	center: undefined,
	padding: undefined,
	margin: undefined,
	border: undefined,
	action: undefined,
	children: undefined,
	small: null,
	size: undefined,
	meetingStateText: '',
	raceText: '',
	start_datetime: '',
	distance: 0,
	raceNumber: null,
	lessThan2Min: null,
};

export default UpCarouselItem;
