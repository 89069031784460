import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { spacings, typography, ui } from '@tbh/ui-kit';

import BetSelectionDetails from '../BetSelectionDetails/BetSelectionDetails';
import BetPositionDetail from '../BetPositionDetail/BetPositionDetail';

import numeral from 'numeral';

import { RACING_BET_TYPE_STRAIGHT_EIGHT, RACING_BET_TYPE_DAILY_DOUBLE } from '../../../../../common/constants/Racing';
import { BET_TYPE_GROUPS_EXOTIC_OBJECT, BET_TYPE_QUADDIE } from '../../../../../store/entities/constants/BetConstants';

/**
 * Styling
 */
const StyledBetSelectionExotic__Selections = styled('div')(
	(props) => css`
		label: BetSelectionExotic__Selections;

		flex: 1;
		display: flex;
		justify-content: flex-end;
		text-align: center;
		max-width: 100px;

		${props.selectionName === RACING_BET_TYPE_STRAIGHT_EIGHT &&
		css`
				max-width: 100%;
				justify-content: space-between;
			`};
	`,
);

const StyledBetSelectionExotic__SelectionType = styled('div')(
	(props) => css`
		label: BetSelectionExotic__SelectionType;

		text-transform: capitalize;
		font-size: ${typography(props)['size_-2']};
		color: ${ui(props).color_success};
	`,
);

const StyledBetSelectionExotic__BoxedSelections = styled('div')(
	(props) => css`
		label: BetSelectionExotic__BoxedSelections;

		margin-top: ${spacings(props).compact}px;
		// text-align: center;
		font-size: 10px;
		font-weight: bold;
	`,
);
// End Styling

const BetSelectionExotic = (props) => {
	const { className, exoticSelectionType, exoticSelections, selectionName, eventName, t } = props;

	const componentClasses = cx({
		[className]: className,
	});

	const exoticTypeLabel = selectionName === 'quinella' ? 'Any Order' : 'Exact Order';

	/**
	 * Display the correct race position label based on the bet type
	 *
	 * @param selection
	 */
	const positionLabel = (selection) => {
		switch (selectionName) {
			case 'quaddie':
				return `Leg ${selection.label}`;
			case 'dailydouble':
				return `Leg ${selection.label}`;
			case 'quinella':
				return 'Any';
			default:
				return numeral(selection.label).format('0o');
		}
	};

	return (
		<div className={componentClasses}>
			<div
				className={css`
					display: flex;
					flex-flow: wrap;
				`}
			>
				<BetSelectionDetails
					className={css`
						flex: ${selectionName === RACING_BET_TYPE_STRAIGHT_EIGHT && exoticSelectionType === 'standard'
							? '1 1 100%'
							: '1'};
					`}
					selectionName={BET_TYPE_GROUPS_EXOTIC_OBJECT[selectionName].title + ' ' + exoticSelectionType}
					betTypeLabel={exoticSelectionType === 'standard' ? exoticTypeLabel : null}
					eventName={eventName}
				/>
				{exoticSelectionType === 'standard' &&
					exoticSelections && (
						<StyledBetSelectionExotic__Selections selectionName={selectionName}>
							{exoticSelections.map((selection) => (
								<BetPositionDetail
									key={selection.runnerNumber}
									selection={positionLabel(selection)}
									label={selection.runnerNumber}
								/>
							))}
						</StyledBetSelectionExotic__Selections>
					)}
				{(exoticSelectionType === 'boxed' || exoticSelectionType === 'standout') && (
					<StyledBetSelectionExotic__SelectionType>{t(exoticSelectionType)}</StyledBetSelectionExotic__SelectionType>
				)}
			</div>

			{exoticSelectionType === 'boxed' && (
				<StyledBetSelectionExotic__BoxedSelections>
					{exoticSelections[0].selection}
				</StyledBetSelectionExotic__BoxedSelections>
			)}
			{exoticSelectionType === 'standout' &&
				exoticSelections.map((selection) => (
					<BetPositionDetail
						key={selection.label}
						selection={selection.selection}
						label={positionLabel(selection)}
						isLegs={selectionName === BET_TYPE_QUADDIE || selectionName === RACING_BET_TYPE_DAILY_DOUBLE}
						isStandout
					/>
				))}
		</div>
	);
};

BetSelectionExotic.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The name of the selection being bet on */
	selectionName: PropTypes.string.isRequired,

	/** Name of the event or competition */
	eventName: PropTypes.string.isRequired,

	/** The price or odds of the selection being bet on */
	price: PropTypes.number.isRequired,

	/** What type of exotic selection is it */
	exoticSelectionType: PropTypes.oneOf(['standard', 'boxed', 'standout']).isRequired,

	/** The bet type being bet on */
	betTypeLabel: PropTypes.string,

	/** Standard exotic selection with a single runner per position */
	exoticSelections: PropTypes.arrayOf(
		PropTypes.shape({
			/** Label for the position of the runner */
			label: PropTypes.string,

			/** Runner number ro selection string */
			selection: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		}),
	),

	/** Extra style class(es) to pass through */
	className: PropTypes.string,
};

BetSelectionExotic.defaultProps = {
	betTypeLabel: null,
	exoticSelections: null,
	className: null,
};

export default withNamespaces()(BetSelectionExotic);
