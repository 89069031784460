import moment from 'moment';
import { GRS_NEXJUMP_BASE_URL, GRS_NEXJUMP_SUB_APP } from '../pages/GRSNextJump/GRSNextJumpConstants';
import { NEXTJUMP_HOME_LOADING__BATCH, NEXTJUMP_HOME_DONE__BATCH} from '../store/nextJumpHome/NextJumpHomeActionTypes';

import {
  fetchMeetingsAndRaces,
} from '../store/entities/actions/MeetingActions';

import {
  resetRacingFilters, setContainerLoadingMask, setExoticPools,
  setSelectedDate,
  setShowingMeetings,
  setShowingNTJRaces,
} from '../store/GRSracingHome/GRSracingHomeActions';  
import { batchActions } from 'redux-batched-actions';

import { show404, scrollToTop, setBodyLoading , closeSideBetSlip } from '../store/application/applicationActions';
import { fetchNewNextToJumpRaces_GRS } from './../store/newNextToJumpRaces/newNextToJumpRacesActions';
const { dispatch } = App.store;

const NextJumpRouter = Backbone.Router.extend({
    routes: {
        [GRS_NEXJUMP_BASE_URL] : 'showNextJumpPage',
    },

     /**
     * make new router with loading nextjummp data
     * @HW12Oct2019
     */
    showNextJumpPage: async () =>{
      let userDate = moment(new Date());
      /**
       * Reset filters and render loading mask for the whole container
       */
      dispatch(
        batchActions(
          [setBodyLoading(true), resetRacingFilters(), setSelectedDate(userDate), closeSideBetSlip(true)],
          NEXTJUMP_HOME_LOADING__BATCH,
        ),
      );
      try {
        let [response, ntjResponse] = await Promise.all([
          dispatch(fetchMeetingsAndRaces()),
          dispatch(fetchNewNextToJumpRaces_GRS()), // GRS api route
        ]);
        scrollToTop();
        dispatch(
          batchActions(
            [
              setShowingMeetings(response.meetingIds),
              setShowingNTJRaces(ntjResponse.ntjRaceIds), // change 
              setExoticPools(response.exoticPools),
              setContainerLoadingMask(false),
              setBodyLoading(false),
            ],
            NEXTJUMP_HOME_DONE__BATCH,
          ),
        );
        App.startSubApp(GRS_NEXJUMP_SUB_APP);
      } catch (e) {
        //console.log(e);
        dispatch(show404());
      }
    },

  
});

module.exports = new NextJumpRouter();