/**
 * Created by Josef Frank on 2017/05/03.
 */

'use strict';
import { buildSportsSitemapFromAJAX } from './sports';
import { buildRacingSitemapFromAJAX } from './racing';

// Generators
const { buildTournamentSitemapFromAJAX } = require('./tournaments');

/**
 * Returns all the promises used to generate Redux sitemaps.
 *
 * @param store
 */
function getReduxSitemapPromises(store) {
	return [
		buildRacingSitemapFromAJAX(store),
		buildTournamentSitemapFromAJAX(store),
		buildSportsSitemapFromAJAX(store),
		// buildPromotionsSitemapFromAJAX(store),
	];
}

module.exports = {
	getReduxSitemapPromises,
};
