
// constants
import { GRS_SPORTS_BASE_URL, GRS_SPORTS_SUB_APP } from '../pages/GRSSports/GRSSportsConstants';




import { setBodyLoading } from '../store/application/applicationActions';
import { show404, scrollToTop } from '../store/application/applicationActions';

const { dispatch, getState } = App.store;

/**
 * GRSSports Router
 * -------------
 * Provides routing functionality for the GRSsports sub app.
 *
 * @module routers/GRSSportsRouter
 */

const GRSSportsRouter = Backbone.Router.extend({
    routes: {
		[GRS_SPORTS_BASE_URL]: 'showLandingPage',
    },
    
    showLandingPage: function () {
      /**
       * Reset filters and render loading mask for the whole container
       */
    //   dispatch(
    //     batchActions(
    //       [setBodyLoading(true)],
    //       GRS_SPORTS_HOME_LOADING__BATCH,
    //     ),
    //   );
      try {
        scrollToTop();
        dispatch(setBodyLoading(false));
        App.startSubApp(GRS_SPORTS_SUB_APP);
      } catch (e) {
        //console.log(e);
        dispatch(show404());
      }
       
    },
    
});

module.exports = new GRSSportsRouter();