
/**
 * Sub Apps
 */
export const ACTIVE_TOURNAMENT_SUB_APP = 'activeTournament';

/**
 * Navigation
 */
export const HIGHLIGHTS_NAVIGATION = 'HIGHLIGHTS_NAVIGATION';
export const MY_TOURNAMENTS_NAVIGATION = 'MY_TOURNAMENTS_NAVIGATION';
export const DAILY_TOURNAMENTS_NAVIGATION = 'DAILY_TOURNAMENTS_NAVIGATION';
export const HIGHLIGHTS_LABEL = 'Highlights';
export const MY_TOURNAMENTS_LABEL = 'Daily';
export const DAILY_TOURNAMENTS_LABEL = 'My Tournaments';
export const TAB_LIST = [
	{
		id: HIGHLIGHTS_NAVIGATION,
		content: HIGHLIGHTS_LABEL,
	},
	{
		id: DAILY_TOURNAMENTS_NAVIGATION,
		content: DAILY_TOURNAMENTS_LABEL,
	},
	{
		id: MY_TOURNAMENTS_NAVIGATION,
		content: MY_TOURNAMENTS_LABEL,
	},
];

/**
 * Advanced search filters
 */
export const ADVANCED_SEARCH_CURRENT = 'ADVANCED_SEARCH_CURRENT';
export const ADVANCED_SEARCH_CUSTOM = 'ADVANCED_SEARCH_CUSTOM';
export const ADVANCED_SEARCH_LAST_MONTH = 'ADVANCED_SEARCH_LAST_MONTH';
export const ADVANCED_SEARCH_LAST_SEVEN_DAYS = 'ADVANCED_SEARCH_LAST_SEVEN_DAYS';
export const ADVANCED_SEARCH_YESTERDAY = 'ADVANCED_SEARCH_YESTERDAY';

/**
 * My tournament search filters
 */
export const MY_TOURNAMENTS_SEARCH_CUSTOM = 'MY_TOURNAMENTS_SEARCH_CUSTOM';
export const MY_TOURNAMENTS_SEARCH_LAST_MONTH = 'MY_TOURNAMENTS_SEARCH_LAST_MONTH';
export const MY_TOURNAMENTS_SEARCH_LAST_SEVEN_DAYS = 'MY_TOURNAMENTS_SEARCH_LAST_SEVEN_DAYS';
export const MY_TOURNAMENTS_SEARCH_YESTERDAY = 'MY_TOURNAMENTS_SEARCH_YESTERDAY';

/**
 * Filters
 */
export const NO_CATEGORY = 'NO_CATEGORY';
export const RACING_FILTER = 'RACING_FILTER';
export const SPORTS_FILTER = 'SPORTS_FILTER';
export const ALL_FILTER = 'ALL_FILTER';
export const ACTIVE_FILTER = 'ACTIVE_FILTER';
export const CLOSED_FILTER = 'CLOSED_FILTER';
export const COMPLETED_FILTER = 'COMPLETED_FILTER';
export const IN_PLAY_FILTER = 'IN_PLAY_FILTER';
export const NOT_STARTED_FILTER = 'NOT_STARTED_FILTER';
export const ACTIVE_LABEL = 'Active';
export const CLOSED_LABEL = 'Closed';
export const RACING_LABEL = 'Racing';
export const SPORTS_LABEL = 'Sports';
export const TOURNAMENTS_HOME_FILTERS = [
	{
		id: ACTIVE_FILTER,
		title: ACTIVE_LABEL,
	},
	{
		id: CLOSED_FILTER,
		title: CLOSED_LABEL,
	},
	{
		id: RACING_FILTER,
		title: RACING_LABEL,
	},
	{
		id: SPORTS_FILTER,
		title: SPORTS_LABEL,
	},
];

/**
 * Market type filters
 */
export const MARKET_TYPE_HALF_TIME_RESULT = 513;
export const MARKET_TYPE_MATCH_RESULT = 98068;

/**
 * Routing
 */
export const TOURNAMENTS_BASE_URL = 'tournaments';

/**
 * Ordering
 */
export const PRIZE_POOL_ORDER = 'PRIZE_POOL_ORDER';
export const REBUYS_ORDER = 'REBUYS_ORDER';
export const BUY_IN_ORDER = 'BUY_IN_ORDER';
export const PRIZE_POOL_LABEL = 'Prize Pool';
export const REBUYS_LABEL = 'Re-buys';
export const BUY_IN_LABEL = 'Buy In';
export const START_DATE_ORDER = 'START_DATE_ORDER';
export const END_DATE_ORDER = 'END_DATE_ORDER';
export const DEFAULT_ORDER = END_DATE_ORDER;
export const DEFAULT_ORDER_KEY = 'end_date';
export const TOURNAMENTS_HOME_ORDERS = [
	{
		id: PRIZE_POOL_ORDER,
		title: PRIZE_POOL_LABEL,
	},
	{
		id: REBUYS_ORDER,
		title: REBUYS_LABEL,
	},
	{
		id: BUY_IN_ORDER,
		title: BUY_IN_LABEL,
	},
];

export const DEFAULT_ORDER_DIRECTION = 'ASC';

/**
 * Modals
 */
export const MODAL_BET_PLACEMENT = 'MODAL_BET_PLACEMENT';
export const MODAL_LEADERBOARD_OPEN = 'MODAL_LEADERBOARD_OPEN';
export const MODAL_REBUY = 'MODAL_REBUY';
export const MODAL_SLEDGE_BOX = 'MODAL_SLEDGE_BOX';
export const MODAL_TICKET_PURCHASE = 'MODAL_TICKET_PURCHASE';
