import Format from '../legacy/core/format';

// Constants
const { TOURNAMENTS_BASE_URL } = require('../pages/Tournaments/TournamentsConstants');

/**
 * Build route to racing tournament
 *
 * @param id
 * @param name
 * @param raceId
 * @param raceNumber
 * @returns {String}
 */
const buildRouteToRacingTournament = (id, name, raceId, raceNumber) => {
	return `${buildRouteToTournament(id, name)}/race-${Format.slugify(raceNumber)}-${raceId}`;
};

/**
 * Build route to sports tournament
 *
 * @param id
 * @param name
 * @param competitionId
 * @param competitionName
 * @param eventId
 * @param eventName
 * @returns {String}
 */
const buildRouteToSportsTournament = (id, name, competitionId, competitionName, eventId, eventName) => {
	let route = `${buildRouteToTournament(id, name)}`;

	if (competitionId) {
		route += `/${Format.slugify(competitionName)}-${competitionId}`;
	}

	if (eventId) {
		route += `/${Format.slugify(eventName)}-${eventId}`;
	}

	return route;
};

/**
 * Builds route to a tournament.
 *
 * @param id
 * @param name
 * @returns {String}
 */
const buildRouteToTournament = (id, name) => {
	return `/${TOURNAMENTS_BASE_URL}/${Format.slugify(name)}-${id}`;
};

module.exports = {
	buildRouteToRacingTournament,
	buildRouteToSportsTournament,
	buildRouteToTournament,
};
