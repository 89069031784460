/**
 * Created by Jason Kinnear on 29/06/15.
 */

'use strict';
require('./shims/globals');
require('./shims/device-details');
require('./shims/momentjs-setup');
require('./shims/numeral-setup');
require('promise.prototype.finally');
require('./shims/ecrypt');
