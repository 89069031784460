const {
	denormalize,
	normalize,
	schema,
} = require('normalizr');

// Schemas
const bankAccountSchema = new schema.Entity('bankAccounts');
const bankAccountsSchema = new schema.Array(bankAccountSchema);

/**
 * Denormalize entities back to a structure similar to the API response.
 *
 * @param {object} entities
 * @param {Array} keys
 * @returns {Array}
 */
export const denormalizeBankAccounts = (entities, keys = null) => {
	return denormalize(keys || Object.keys(entities.bankAccounts), bankAccountsSchema, entities);
};

/**
 * Normalize structure to suit our application.
 *
 * @returns {Object}
 * @param response
 */
export const normalizeBankAccounts = (response) => {
	return normalize(response, bankAccountsSchema);
};
