import styled, { css } from 'react-emotion';
import { spacings, media, brand, Button, typography } from '@tbh/ui-kit';
import { FONT_SIZE_12 } from '../../../../store/application/applicationConstants';
import { DEFAULT_COLORS, FAMILYS } from '../../../../constants/themes';

export const StyledRaceCard_goToRace = styled(Button)(
	(props) => css`
		label: StyledRaceCard_goToRace;

		cursor: pointer;
		width: -webkit-fill-available;
		background: ${brand(props).primary_color ? brand(props).primary_color : DEFAULT_COLORS.df_primary_color};
		border-style: solid;
		border-color: ${brand(props).primary_color ? brand(props).primary_color : DEFAULT_COLORS.df_primary_color};
		padding: ${spacings(props).tight}px;
		font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};
		font-size: ${FONT_SIZE_12};

		border-radius: 0 0 4px 4px !important;

		&:hover {
			background-color: ${brand(props).primary_hover_color ? brand(props).primary_hover_color : DEFAULT_COLORS.df_primary_hover_color};
			border-color: ${brand(props).primary_hover_color ? brand(props).primary_hover_color : DEFAULT_COLORS.df_primary_hover_color};
			border-bottom: 2px solid ${brand(props).primary_hover_color ? brand(props).primary_hover_color : DEFAULT_COLORS.df_primary_hover_color};
		}
	`,
	(props) =>
		media(
			css`
				padding: ${spacings(props).comfortable}px;
			`,
		),
);
