import { mergeEntities } from '../../common/actions/actionHelpers';
import { post } from '../../common/Ajax';
import { openNotification, setBodyLoading } from '../application/applicationActions';
import { normalizeUsers } from '../entities/schemas/UserSchema';

export const RNSUserLoginAction = (rns_user_name, rns_password, grs_email) => (dispatch, getState) => {
  const state = getState();
  const params = {
    userName: rns_user_name,
    Password: rns_password,
    grs_email: grs_email,
  };
  const headers = {
    'Content-Type': 'application/json',
  };
  dispatch(setBodyLoading(true));
  post('RNSAccount/sign-in-rns', params, { headers })
    .then((response) => {

      const user = state.entities.users[state.application.authenticatedUser];

      user.rns_user_name = rns_user_name;

      dispatch(mergeEntities(normalizeUsers(user).entities));
      return response;
    })
    .catch((error) => {
      document.Sentry && document.Sentry.captureException(error);

      dispatch(
        openNotification('Apologies, but your login attempt was not successful. For security reasons, you will be logged out of the site.'
          , 'danger', {
          autoClose: 1500,
        }),
      );
      return error.response;
    }).finally(() => dispatch(setBodyLoading(false)));

};
global.RNSUserLoginAction = RNSUserLoginAction;


