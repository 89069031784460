import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings } from '@tbh/ui-kit';
import { debounce } from 'lodash-es';
import { withNamespaces } from 'react-i18next';
import env from '../../../common/EnvironmentVariables';

import { getFilteredCreditCards } from '../../../store/deposits/depositMemoizedSelectors';
import { getAuthenticatedUser } from '../../../store/application/applicationSelectors';
import {
	// deleteCreditCardToken,
	getLastUsedCardFromLocalStorage,
	setLastUsedCardOnLocalStorage,
} from '../../../store/entities/actions/CreditCardActions';
import {
	clearDepositsStore,
	clearCardVerificationState,
	deleteCreditCard,
	makeDepositWithNewCard,
	makeDepositViaToken,
	setLastDepositAmountOnLocalStorage,
	getLastDepositAmountOnLocalStorage,
	setDepositErrors,
	loadCreditCards,
	validatePromoCode,
	verifyCreditCard,
} from '../../../store/deposits/depositActions';
import {
	DEPOSIT_DEFAULT,
	DEPOSIT_USER_ACCOUNT,
	DEPOSIT_VERIFY_CREDIT_CARD,
} from '../../../store/deposits/depositsReducerNames';

import { Button, ButtonGroup, LoadingMask, Notification, Text } from '@tbh/ui-kit';
import DepositForm from '../../../components/features/Deposit/DepositForm/DepositForm';
import DepositReceipt from '../../../components/features/Deposit/DepositReceipt/DepositReceipt';
import VerifyCardExplanation from '../../../components/features/Deposit/VerifyCardExplanation/VerifyCardExplanation';
import ModalFooter from '../../../components/features/Application/ModalFooter/ModalFooter';
import Modal from '../../../components/controllers/Modal/Modal';
import { trackGaEvent } from '../../../store/trackingPixels/trackingActions';
import CreditCardVerification from '../../../components/ui-components/Forms/CreditCardVerification/CreditCardVerification';

const cssDepositContainer = css`
	label: DepositContainer;

	position: relative;
`;

const StyledDepositContainer__Receipt = styled(DepositReceipt)(
	(props) => css`
		label: DepositContainer__ReceiptExplanation;

		padding: ${spacings(props).cozy}px;
	`,
);
const StyledDepositContainer__Explanation = StyledDepositContainer__Receipt.withComponent(VerifyCardExplanation);

/**
 * This component was meant to be used by our fully functional Deposit prompt
 * and by Quick Deposit in the bet prompt, the change/suppress the following functionality:
 *  - No need to fetch the on mount
 *  - Last amount button added to keypad;
 *  - Hide promo code field
 *  - Hide Add/Delete card
 *  - Should filter selected cards only
 */

export class DepositContainer extends Component {
	/**
	 * Internal function to count credit cards as we use normalized creditCards
	 *
	 * @param creditCards
	 * @return {Number}
	 */
	static getCardsCount = (creditCards) => {
		return Object.keys(creditCards).length;
	};

	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The credit cards, directly from state */
		creditCards: PropTypes.object.isRequired,

		/** The default deposit amount */
		defaultDepositAmount: PropTypes.number.isRequired,

		/** Func to clear the deposits slice */
		clearDepositsStore: PropTypes.func.isRequired,

		/** Action to handle deposit with a new card */
		makeDepositWithNewCard: PropTypes.func.isRequired,

		/** Action to handle deposit via an existing card */
		makeDepositViaToken: PropTypes.func.isRequired,

		/** Action to submit card verification */
		verifyCreditCard: PropTypes.func.isRequired,

		/** Used by skip verification and on card change */
		clearCardVerificationState: PropTypes.func.isRequired,

		/** Extra classes */
		className: PropTypes.string,

		/** Func to close the deposit form */
		handleClose: PropTypes.func,

		/** The currently logged in user */
		user: PropTypes.object,

		/** Whether or not we are depositing with a new card or an existing card */
		newCard: PropTypes.bool,

		/** The card/token ID of the last selected card */
		lastUsedCardId: PropTypes.number,

		/** Func to grab the last deposited amount from local storage */
		getLastDepositAmountOnLocalStorage: PropTypes.func,

		/** Func to set the last used card */
		setLastUsedCardOnLocalStorage: PropTypes.func,

		/** Function for event tracking */
		track: PropTypes.func,

		/** Func to fetch cards on mount */
		loadCreditCards: PropTypes.func,

		/** Validate promo code on blur */
		validatePromoCode: PropTypes.func,

		/** Delete currently selected card */
		deleteCreditCard: PropTypes.func,

		/** Event tracking category for successful deposit */
		trackingCategory: PropTypes.string,

		/** If the unverified cards should be displayed first */
		showUnverifiedCards: PropTypes.bool,

		/**
		 * Props for the deposit slice of state
		 */
		/** Dismiss error notification */
		clearDepositErrors: PropTypes.func.isRequired,

		/** loading masks */
		depositLoading: PropTypes.bool,

		/** For card verification */
		verificationLoading: PropTypes.bool,

		/** First card load */
		loadingCards: PropTypes.bool,

		/** Error messages */
		depositErrors: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
		cardVerificationErrors: PropTypes.string,

		/** successful deposit */
		lastDepositId: PropTypes.number,

		/** Holds valid code code */
		validPromoCode: PropTypes.string,

		/** Controls failure cases. Must be strictly false. */
		isPromoCodeValid: PropTypes.bool,

		/** Control the hide or show promo code input. */
		isQuickDeposit: PropTypes.bool,

		/** Number of card verifications left */
		verificationAttemptsLeft: PropTypes.number,

		/** Whether the verification attempt has failed. */
		verificationFailed: PropTypes.bool,

		/** If only the credit card verification should be displayed or not */
		verifyCreditCardsOnly: PropTypes.bool,

		/** Brand name */
		brandName: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		handleClose: undefined,
		user: {},
		newCard: false,
		lastUsedCardId: null,
		getLastDepositAmountOnLocalStorage: () => { },
		setLastUsedCardOnLocalStorage: () => { },
		track: () => { },
		loadCreditCards: () => { },
		trackingCategory: 'Deposit',
		isQuickDeposit: false,
		depositLoading: null,
		verificationLoading: null,
		depositErrors: null,
		cardVerificationErrors: null,
		lastDepositId: null,
		isPromoCodeValid: null,
		validPromoCode: null,
		validatePromoCode: () => { },
		loadingCards: null,
		deleteCreditCard: () => { },
		verificationAttemptsLeft: 3,
		verificationFailed: null,
		showUnverifiedCards: false,
		verifyCreditCardsOnly: false,
		brandName: null,
	};

	constructor(props) {
		super(props);
		const { isQuickDeposit } = props;
		this.state = {
			amount: props.defaultDepositAmount,
			activeKeypadButton: props.defaultDepositAmount,
			newCard: (props.newCard && !isQuickDeposit) || Object.keys(props.creditCards).length === 0,
			showDeleteCardConfirmation: false,
			lastUsedCardId: props.lastUsedCardId,
			loadingCards: props.loadingCards,
			depositTermsAccepted: props.user.country !== 'United Kingdom',

			shouldVerifyCard: false,
			isSaveCard: false,
			showVerifyCard: false,
			showVerifyExplanation: false,

			// Form field values and validation
			creditCardName: {
				value: props.user.name,
				valid: true,
			},
			creditCardNumber: {
				value: '',
				valid: false,
			},
			creditCardExpiry: {
				value: '',
				valid: false,
			},
			creditCardSecurityCode: {
				value: '',
				valid: false,
			},

			promoCode: {
				value: '',
				valid: null,
			},

			verifyAmount: {
				value: '',
				valid: null,
			},
		};

		if (!isQuickDeposit && !env('webdosh')) {
			props.loadCreditCards();
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let nextState = null;

		// Always show the new card form when Webdosh is active
		if (env('webdosh')) {
			nextState = {
				newCard: true,
			};
			// Controls first cards load, or card addition/removal
		} else if (
			((prevState.loadingCards && !nextProps.loadingCards) || nextProps.lastUsedCardId !== prevState.lastUsedCardId) &&
			!prevState.showVerifyCard
		) {
			nextState = {
				loadingCards: nextProps.loadingCards,
				lastUsedCardId: nextProps.lastUsedCardId,
				newCard: !DepositContainer.getCardsCount(nextProps.creditCards),
			};
		}

		// Controls promo code
		if (nextProps.validPromoCode) {
			nextState = {
				...nextState,
				isPromoCodeValid: true,
				promoCode: {
					value: nextProps.validPromoCode,
				},
			};
		} else if (nextProps.isPromoCodeValid === false) {
			nextState = {
				...nextState,
				isPromoCodeValid: false,
			};
		}

		// Control blocked step of verification
		if (nextProps.verificationAttemptsLeft === 0) {
			nextState = {
				...nextState,
				verificationStep: 'blocked',
			};
		}

		return nextState;
	}

	/**
	 * Reset the store on unmount
	 */
	componentWillUnmount() {
		this.props.clearDepositsStore();
	}

	/**
	 * Handle verify amount,
	 */
	handleVerifyCard = () => {
		const { depositCcToken } = this.props;

		const { verifyAmount } = this.state;
		this.props.verifyCreditCard(depositCcToken, verifyAmount.value);
		this.props.track('Click', 'Verify Card');
	};

	/**
	 * Control state to render modal with card verification explanation.
	 */
	handleShowVerifyExplanation = () => {
		this.setState({
			showVerifyExplanation: true,
		});
	};

	/**
	 * Control state to render modal with card verification explanation.
	 */
	handleHideVerifyExplanation = () => {
		this.setState({
			showVerifyExplanation: false,
		});
	};

	/**
	 * Configuration to open cc verification explanation
	 */
	explanationModalConfig = {
		mobileWidth: true,
		noBorder: true,
		preventBackdropClose: true,
		title: this.props.t('MakeDeposit'),
	};

	/**
	 * Dispatch actions to deposit
	 */
	handleDeposit = () => {
		// Ensure that everything is valid
		if (this.depositEnabled()) {
			if (this.state.newCard) {
				// Remove any card masking from the number
				const cardNumber = this.state.creditCardNumber.value.replace(/-/g, '');

				// (amount, name, number, expiryDate, cvv)
				this.props.makeDepositWithNewCard(
					parseInt(this.state.amount),
					this.state.creditCardName.value,
					cardNumber,
					this.state.creditCardExpiry.value,
					this.state.creditCardSecurityCode.value,
					this.props.trackingCategory,
					this.props.user,
					this.state.isSaveCard
				);
			} else {
				// (cardId, amount, cvv)
				this.props.makeDepositViaToken(
					this.state.lastUsedCardId,
					parseInt(this.state.amount),
					this.state.creditCardSecurityCode.value,
					this.props.trackingCategory,
					this.props.user,
					this.props.isQuickDeposit,
				);
			}
			this.handleDismissErrors();
			this.props.track('Click', 'Continue Deposit');
		}
	};

	/**
	 * Store last used card in localStorage.
	 *
	 * @param cardId
	 */
	setLastUsedCard = (cardId) => {
		const creditCard = this.props.creditCards[cardId];
		if (creditCard) {
			const { Name } = creditCard;
			this.props.setLastUsedCardOnLocalStorage(Name, this.getLastFour(cardId));
		}
	};

	/**
	 * Return the last 4 digits of the current Card Number
	 *
	 * @param cardId
	 * @returns {string|*}
	 */
	getLastFour = (cardId) => {
		const creditCard = this.props.creditCards[cardId];
		if (creditCard) {
			const { Number } = creditCard;
			return Number.substr(Number.length - 4);
		}

		return '';
	};

	/**
	 * Sets the amount to the last deposited amount, from local storage
	 */
	handleLastButton = () => {
		this.setState({ amount: this.props.getLastDepositAmountOnLocalStorage(), activeKeypadButton: 'last' });
	};

	/**
	 * Handle the deposit amount changing
	 *
	 * @param amount
	 */
	handleDepositAmountChange = (amount) => {
		this.setState({ amount: amount });
	};

	/**
	 * Handle the deposit keypad button click
	 *
	 * @param amount
	 */
	handleDepositKeypadChange = (amount) => {
		this.setState({ amount: Number(amount), activeKeypadButton: amount });
	};

	handleDepositClear = () => {
		this.setState({ amount: Number(this.state.amount) - Number(this.state.amount), activeKeypadButton: 0 });
	};

	/**
	 * Handle the form input changing, eg. Card Name, Security Code, etc
	 * Also mark the fields as valid or invalid depending on the valid param.
	 *
	 * @param name
	 * @param value
	 * @param valid
	 */
	handleFormChange = (name, value, valid) => {
		this.setState(
			{
				[name]: {
					value,
					valid,
				},
			},
			() => {
				if (name === 'promoCode' && value) {
					this.debounce();
				}
			},
		);
	};

	/**
	 * Clear deposits error messages in redux
	 */
	handleDismissErrors = () => {
		this.props.clearDepositErrors();
	};

	handleCancelDeposit = () => {
		this.props.track('Click', 'Close');
		this.props.handleClose();
	};

	/**
	 * Handle the card select changing
	 *
	 * @param name
	 * @param value
	 */
	handleCardChange = (name, value) => {
		const cardId = parseInt(value);
		this.setState({
			lastUsedCardId: cardId,
			showDeleteCardConfirmation: false,

			creditCardSecurityCode: {
				value: '',
				valid: false,
			},

			verifyAmount: {
				value: '',
				valid: false,
			},
		});
		this.handleDismissErrors();
		this.setLastUsedCard(cardId); // Store last used card in localStorage
		this.props.clearCardVerificationState();
	};

	/**
	 * Validates the promo code
	 */
	handleValidatePromoCode = () => {
		if (this.state.promoCode.value) {
			this.props.validatePromoCode(this.state.promoCode.value);
		}
	};

	/**
	 * Debounce for the promo code validation
	 * @type {Function}
	 */
	debounce = debounce(() => {
		this.handleValidatePromoCode();
	}, 1000);

	/**
	 * Handle Add new card or show existing ones.
	 */
	handleAddOrExistingCard = () => {
		if (this.state.newCard) {
			this.setState({
				newCard: !this.state.newCard,

				creditCardSecurityCode: {
					value: '',
					valid: false,
				},
				creditCardName: {
					value: '',
					valid: false,
				},
				creditCardNumber: {
					value: '',
					valid: false,
				},
				creditCardExpiry: {
					value: '',
					valid: false,
				},
			});
		} else {
			this.setState({
				newCard: !this.state.newCard,
				creditCardSecurityCode: {
					value: '',
					valid: false,
				},
			});
			this.props.clearCardVerificationState();
			this.props.track('Select', 'Add Credit Card');
		}
	};

	/**
	 * Set flag to render confirmation
	 */
	handleDeleteCardClick = () => {
		this.setState({
			showDeleteCardConfirmation: true,
		});
	};

	/**
	 * Clear notification for delete card confirmation.
	 * Also used when changing selected card.
	 */
	handleDismissDeleteCard = () => {
		this.setState({
			showDeleteCardConfirmation: false,
		});
	};

	/**
	 * Handle Delete current selected card
	 */
	handleDeleteCard = () => {
		this.handleDismissDeleteCard();
		this.props.deleteCreditCard(this.state.lastUsedCardId).then(() => {
			const creditCards = Object.values(this.props.creditCards);
			if (creditCards.length) {
				this.handleCardChange(null, creditCards[0].id);
			} else {
				this.setState({
					newCard: true,
					creditCardSecurityCode: {
						value: '',
						valid: false,
					},
				});
				this.handleSkipCardVerification();
			}
		});
	};

	/**
	 * Activate card verification state
	 */
	handleStartCardVerification = () => {
		this.setState({
			showVerifyCard: true,
			verificationStep: 'verify-amount',
		});
		this.props.track('Click', 'Verify Card');
	};

	/**
	 * Clear verification state
	 */
	handleSkipCardVerification = () => {
		this.setState({
			verificationStep: 'verify-card',
		});

		this.props.clearCardVerificationState();
	};

	/**
	 * Return whether or not the Deposit can be processed and all fields are valid
	 * depending on if we are depositing with a new card or an existing card.
	 *
	 * @returns boolean
	 */
	depositEnabled = () => {
		const isValid = this.state.newCard
			? this.state.amount >= 1000 &&
			this.state.creditCardName.valid &&
			this.state.creditCardNumber.valid &&
			this.state.creditCardExpiry.valid &&
			this.state.creditCardSecurityCode.valid &&
			this.state.depositTermsAccepted
			// this.state.shouldVerifyCard
			: this.state.lastUsedCardId &&
			this.state.amount >= 1000 &&
			this.state.creditCardSecurityCode.valid &&
			this.state.depositTermsAccepted;
		return !!isValid;
	};

	/**
	 * Builds confirmation buttons for delete card confirmation.
	 * TODO: <Notification> can be adapted to receive node.
	 */
	buildDeleteCardConfirmationButtons = () => (
		<ButtonGroup right>
			<Button action={this.handleDeleteCard} type="secondary" block>
				{this.props.t('Confirm')}
			</Button>
			<Button action={this.handleDismissDeleteCard} block>
				{this.props.t('Cancel')}
			</Button>
		</ButtonGroup>
	);

	/**
	 * Quick Deposit amount key pad settings.
	 */
	quickDepositValueButtons = [1000, 2500, 5000, 10000, 25000, 50000, 75000, 100000, 250000];
	lastValueButton = {
		action: this.handleLastButton,
		type: 'ghost',
		label: this.props.t('Last'),
	};

	/**
	 * Set the deposit terms accepted flag
	 *
	 * @param event
	 * @param value
	 */
	handleDepositTermsCheckbox = (event, value) => {
		this.setState({ depositTermsAccepted: !value });
	};

	/**
	 * Set that the verification should happen with a new card
	 * @param value
	 */
	handleShouldShowVerification = (value) => {
		this.setState({ shouldVerifyCard: value });
	};
	handleShouldShowSaveCard = (value) => {
		this.setState({ isSaveCard: value });
	};

	buildContent() {
		const {
			t,
			isQuickDeposit,
			creditCards,
			clearDepositsStore,
			depositLoading,
			handleClose,
			lastDepositId,
			user,
			loadingCards,
			verificationLoading,
			verificationAttemptsLeft,
			verificationFailed,
			verifyCreditCardsOnly,
			brandName,
		} = this.props;
		const {
			amount,
			activeKeypadButton,
			creditCardName,
			creditCardNumber,
			creditCardExpiry,
			creditCardSecurityCode,
			depositTermsAccepted,
			lastUsedCardId,
			newCard,
			promoCode,
			isPromoCodeValid,
			verificationStep,
			verifyAmount,
		} = this.state;

		// If we complete a deposit, show the confirmation screen
		// If we confirm the start of the verification process, show the verification screen

		if (this.state.showVerifyCard) {
			return (
				<CreditCardVerification
					t={t}
					className={'deposit-verification'}
					creditCardSecurityCode={creditCardSecurityCode}
					loadingCardVerification={verificationLoading}
					onChange={this.handleFormChange}
					onStartCardVerification={this.handleStartCardVerification}
					onVerifyCardSubmit={this.handleVerifyCard}
					verificationStep={verificationStep}
					verifyAmountIsValid={this.state.verifyAmount.valid}
					verifyAmount={this.state.verifyAmount.value}
					verificationFailed={verificationFailed}
					verificationAttemptsLeft={verificationAttemptsLeft}
					skipCardVerification={this.handleSkipCardVerification}
					verifyCreditCardsOnly={verifyCreditCardsOnly}
					handleClose={handleClose}
				/>
			);
		}

		if (lastDepositId && !depositLoading) {
			return (
				<StyledDepositContainer__Receipt
					account_balance={user.account_balance}
					backToBetting={handleClose ? handleClose : clearDepositsStore}
					receiptId={lastDepositId}
					receiptMethod={`${t('CreditCard')} ****${this.getLastFour(lastUsedCardId)}`}
					buttonText={isQuickDeposit ? t('BackToBetting') : t('AwesomeThanks')}
					extraInformation={{
						text: t('DepositContainer__AddBalance'),
						amount: amount,
					}}
					shouldVerifyCard={this.state.shouldVerifyCard}
					isSaveCard={this.state.isSaveCard}
					handleStartCardVerification={this.handleStartCardVerification}
				/>
			);
		}

		if (user.id) {
			return (
				<DepositForm
					user={user}
					depositTermsAccepted={depositTermsAccepted}
					handleDepositTermsCheckbox={this.handleDepositTermsCheckbox}
					activeKeypadButton={activeKeypadButton}
					loadingCards={loadingCards}
					isQuickDeposit={isQuickDeposit}
					creditCardId={lastUsedCardId}
					creditCards={creditCards}
					depositAmount={amount}
					handleCancelDeposit={handleClose ? this.handleCancelDeposit : null}
					handleDeposit={this.handleDeposit}
					handleFormChange={this.handleFormChange}
					isDepositEnabled={this.depositEnabled()}
					onAddCard={this.handleAddOrExistingCard}
					onDeleteCard={this.handleDeleteCardClick}
					onShowExistingCard={!!DepositContainer.getCardsCount(creditCards) && this.handleAddOrExistingCard}
					newCard={newCard}
					onDepositAmountChange={this.handleDepositAmountChange}
					onDepositKeypadChange={this.handleDepositKeypadChange}
					onClearDepositChange={this.handleDepositClear}
					onCardChange={this.handleCardChange}
					handleLastButtonClicked={this.handleLastButton}
					promoCode={promoCode.value}
					isPromoCodeValid={isPromoCodeValid}
					creditCardName={creditCardName.value}
					creditCardNumber={creditCardNumber.value}
					creditCardExpiry={creditCardExpiry.value}
					creditCardSecurityCode={creditCardSecurityCode.value}
					onOpenVerifyInfo={this.handleShowVerifyExplanation}
					showVerificationMessage={verificationFailed === false}
					skipCardVerification={this.handleSkipCardVerification}
					onStartCardVerification={this.handleStartCardVerification}
					onVerifyCardSubmit={this.handleVerifyCard}
					loadingCardVerification={verificationLoading}
					verificationAttemptsLeft={verificationAttemptsLeft}
					verificationFailed={verificationFailed}
					verificationStep={verificationStep}
					buttonValues={isQuickDeposit ? this.quickDepositValueButtons : undefined}
					customButton={isQuickDeposit ? this.lastValueButton : undefined}
					verifyAmount={verifyAmount.value}
					verifyAmountIsValid={verifyAmount.valid}
					verifyCreditCardsOnly={verifyCreditCardsOnly}
					brandName={brandName}
					handleShouldShowVerification={this.handleShouldShowVerification}
					handleShouldShowSaveCard={this.handleShouldShowSaveCard}
					shouldVerifyCard={this.state.shouldVerifyCard}
					isSaveCard={this.state.isSaveCard}

				/>
			);
		}
	}

	render() {
		const { t, className, depositErrors, depositLoading, loadingCards, brandName } = this.props;

		const { lastUsedCardId, showDeleteCardConfirmation } = this.state;

		const componentClasses = cx(cssDepositContainer, {
			[className]: className,
		});

		return (
			<div className={componentClasses}>
				{this.state.showVerifyExplanation && (
					<Modal
						open={this.state.showVerifyExplanation}
						componentKey="card-verification-explanation"
						config={this.explanationModalConfig}
						onClose={this.handleHideVerifyExplanation}
					>
						<StyledDepositContainer__Explanation
							closeVerifyCardExplanation={this.handleHideVerifyExplanation}
							brandName={brandName}
						/>
						<ModalFooter />
					</Modal>
				)}

				<LoadingMask loading={depositLoading || loadingCards} />

				{depositErrors && this.state.verificationStep !== 'blocked' && (
					<Notification
						buttonAction={this.handleDismissErrors}
						buttonText={t('Dismiss')}
						type={Notification.types.COLOUR_DANGER}
						message={depositErrors}
						strong
					/>
				)}

				{depositErrors && this.state.verificationStep === 'blocked' && (
					<Notification type={Notification.types.COLOUR_DANGER} strong>
						<div>
							<Text size="-2" strong>
								{t('AccountBlockedTemporarily')}
							</Text>
							<Text size="-2" paragraph>
								{t('DepositForm__ExceededVerification')}
							</Text>
						</div>
					</Notification>
				)}

				{showDeleteCardConfirmation && (
					<Notification
						type={Notification.types.COLOUR_DANGER}
						strong
						message={`${t('DepositContainer__RemoveCard')} ***-${this.getLastFour(lastUsedCardId)}.`}
						customActions={this.buildDeleteCardConfirmationButtons}
					/>
				)}

				<div>{this.buildContent()}</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const creditCards = getFilteredCreditCards(state, ownProps);

	const sliceOfState = ownProps.verifyCreditCardsOnly
		? state[DEPOSIT_VERIFY_CREDIT_CARD]
		: ownProps.renderOnPage
			? state[DEPOSIT_USER_ACCOUNT]
			: state[DEPOSIT_DEFAULT];

	return {
		...sliceOfState,
		...ownProps,

		/**
		 * We don't want to normalize the credit cards, as we want
		 * to be able to access a credit card directly by it's ID.
		 */
		creditCards: creditCards,
		user: ownProps.user || getAuthenticatedUser(state),
		lastUsedCardId: getLastUsedCardFromLocalStorage(creditCards),
		defaultDepositAmount: 1000,
		brandName: state.acl.brandDetails && state.acl.brandDetails.name,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	const reducerName = ownProps.verifyCreditCardsOnly
		? DEPOSIT_VERIFY_CREDIT_CARD
		: ownProps.renderOnPage
			? DEPOSIT_USER_ACCOUNT
			: DEPOSIT_DEFAULT;

	return {
		loadCreditCards: () => dispatch(loadCreditCards(reducerName)),
		getLastDepositAmountOnLocalStorage: getLastDepositAmountOnLocalStorage,
		setLastUsedCardOnLocalStorage: setLastUsedCardOnLocalStorage,
		makeDepositWithNewCard: (amount, name, number, expiryDate, cvv, trackingCategory, user, isSaveCard) => {
			setLastDepositAmountOnLocalStorage(amount);
			dispatch(makeDepositWithNewCard(reducerName, amount, name, number, expiryDate, cvv, trackingCategory, user, isSaveCard));
		},
		makeDepositViaToken: (cardId, amount, cvv, trackingCategory, user, isQuickDeposit) => {
			setLastDepositAmountOnLocalStorage(amount);
			dispatch(makeDepositViaToken(reducerName, cardId, amount, cvv, trackingCategory, user, isQuickDeposit));
		},
		clearDepositErrors: () => dispatch(setDepositErrors(reducerName, null)),
		clearDepositsStore: () => dispatch(clearDepositsStore(reducerName)),
		validatePromoCode: (promoCode) => dispatch(validatePromoCode(reducerName, promoCode)),
		clearCardVerificationState: () => dispatch(clearCardVerificationState(reducerName)),
		deleteCreditCard: (cardId) => dispatch(deleteCreditCard(reducerName, cardId)),
		verifyCreditCard: (cardId, verifyAmount) => dispatch(verifyCreditCard(reducerName, cardId, verifyAmount)),
		track: (action, label, value) => dispatch(trackGaEvent(ownProps.trackingCategory, action, label, value)),
	};
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(DepositContainer));
