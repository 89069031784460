import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getIosAppLink } from '../../../store/application/applicationActions';

import JsonLd from '../../../components/features/Meta/JsonLd/JsonLd';

const JsonLDContainer = (props) => {
	const { currentHREF } = props;

	/**
	 * Update the canonical iOS app href.
	 * TODO: Move this into root component when we have one
	 */
	const iosLd = {
		'@context': 'http://schema.org',
		'@type': 'WebPage',
		'@id': currentHREF,
		'@potentialAction': {
			'@type': 'ViewAction',
			'@target': getIosAppLink(),
		},
	};

	return (
		<JsonLd content={iosLd} />
	);
};

JsonLDContainer.propTypes = {
	/** The current URL route */
	currentHREF: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
	return {
		currentHREF: window.location.href,
	};
};

export default connect(
	mapStateToProps,
)(JsonLDContainer);
