import React, { Component } from 'react';
import styled from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { createErrorBoundary } from '../../containers/ErrorBoundary/ErrorBoundaryActions';
import { fetchAuthenticatedUser } from '../../store/authentication/authenticationActions';
import { poliPaymentVerification } from '../../store/poliPaymentVerifiy/poliPaymentVerifiyAction';
import { Text } from '@tbh/ui-kit';

const StyledPoliContainer = styled('div')`
    width:100%;
    padding:10px;
    border-radius:8px;
    background:white;
    height:100%;
    display:flex;
    justify-content:center;
`;

class PoliConfirmationPage extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loadingMask: !!props.user,
            userLogged: !!props.user,
            confirmedPayment: false,
            confirmationPaymentError: false,
        };
    };

    componentDidUpdate(prevProps, prevState) {

        if (!this.props.user === false && !this.state.userLogged) {
            this.verifyPoliToken();
            this.setState({
                userLogged: true,
            });
        }
    }

    // if location doesn't have token= redirect
    verifyPoliToken() {
        const params = new URLSearchParams(window.location.search);

        const token = params.get('token');

        this.props.verifyPoliPayment(token)
            .then(res => {
                this.setState({
                    confirmedPayment: true,
                });
            })
            .catch(err => {
                this.setState({
                    confirmationPaymentError: true,
                });
            });

        // Error
    }

    render() {

        // Call backEnd a confirm the Token
        if (!this.props.user) {
            return (
                <StyledPoliContainer>
                    <Text size="-1">Log in to confirm you token!</Text>
                </StyledPoliContainer>);
        }

        if (this.state.confirmationPaymentError) {
            return (
                <StyledPoliContainer>
                    <Text size="-1">Happened a error in your confirmation!</Text>
                </StyledPoliContainer>);
        }

        return (
            <StyledPoliContainer>
                {this.state.confirmedPayment ?
                    <Text size="-1">Payment was verified</Text>
                    : <Text size="-1">Please wait, we are confirming your payment</Text>
                }
            </StyledPoliContainer>
        );
    }
};
const mapStateToProps = (state, ownProps) => {
    return {
        user: state.application.authenticatedUser,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchUser: () => dispatch(fetchAuthenticatedUser()),
    verifyPoliPayment: (token) => dispatch(poliPaymentVerification(token))
});

export default withNamespaces()(
    createErrorBoundary(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(PoliConfirmationPage),
    ),
);



function soma(a, b) {

}

