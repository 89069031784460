import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import {countdown} from '../../../../legacy/core/format';
import CarouselSportsItem from '../../Carousel/CarouselSportsItem/CarouselSportsItem';


const NtjRacesCarouselItemV3 = ({
	t, id, eventName, competitionName, startTime, sportName, action, className , country, icon, size }) => {

	return (
		<CarouselSportsItem
			border
			id={id}
			eventName={eventName}
			competitionName={competitionName}
			meta={`${countdown(startTime)}`}
			icon={icon}
			action={action}
			className={className}
            country={country}
			sportName={sportName}
			size={size}
		/>
	);
};

NtjRacesCarouselItemV3.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}),
	/** The race ID */
	id: PropTypes.number.isRequired,

    /** The name of the sport  */
    sportName: PropTypes.string,

    /** The name of the event for the sport */
    eventName: PropTypes.string.isRequired,

	/** The name of the competition for the sport */
	competitionName: PropTypes.string.isRequired,

	/** Action to perform when the race item is clicked on */
	action: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,

    country: PropTypes.string,

    /** Icon for the sport/event */
    icon: PropTypes.node,

    /** The start time of the event starts */
	startTime: PropTypes.string.isRequired,

};

NtjRacesCarouselItemV3.defaultProps = {
	className: '',
	country:  null,
	icon: null,
	sportName: null,
  size: undefined,
};
export default withNamespaces()(NtjRacesCarouselItemV3);
