import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PusherSubscribe from '../../../controllers/Pusher/PusherSubscribe';
import { updatePendingBetStatus } from '../../../../store/entities/actions/BetActions';

class PusherSubscriberUserBet extends Component {
	static propTypes = {
		/** The user ID to update */
		userId: PropTypes.number.isRequired,

		/** The bet ID to update */
		betId: PropTypes.number.isRequired,
	};

	handleUpdateBet = (data) => {
		const { bet_id, status, response } = data;
		// const statuses = ['partial', 'accepted', 'rejected'];
		this.props.updateBetStatus(bet_id, status, response, this.props.entities);
	};

	render() {
		const { userId, betId } = this.props;

		// Determine channel for all subscriptions
		// const channel = `private-cache-bet-user_${betId}_${userId}`;
		const channel = `cache-bet-user_${betId}_${userId}`;
		return (
			<Fragment>
				<PusherSubscribe key={'update'} channel={channel} event={'update'} onUpdate={this.handleUpdateBet} />
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	entities: state.entities,
});

const mapDispatchToProps = (dispatch) => ({
	updateBetStatus: (id, status, response, entities) => dispatch(updatePendingBetStatus(id, status, response, entities)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PusherSubscriberUserBet);
