import {
	denormalize,
	normalize,
} from 'normalizr';

import {
	nextToJumpRacesSchema
} from './Entities';

/**
 * Denormalizes entities.
 *
 * @param entities
 * @param keys
 * @returns {Array}
 */
const denormalizeNextToJumpRaces = (entities, keys = null) => {
	return denormalize(keys || Object.keys(entities.nextToJumpRaces), nextToJumpRacesSchema, entities);
};

/**
 * Normalizes entities.
 *
 * @param response
 * @returns {Object}
 */
const normalizeNextToJumpRaces = (response) => {
	return normalize(response, nextToJumpRacesSchema);
};

export {
	denormalizeNextToJumpRaces,
	normalizeNextToJumpRaces,
};