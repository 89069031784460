import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { RACING_TYPES_LOOKUP } from '../../../../common/constants/Racing';
import { countdown, countdownLessThan2Min } from '../../../../legacy/core/format';

import CarouselItemV4 from '../../Carousel/CarouselItemV4/CarouselItemV4';


/**
 * This feature wraps the CarouselItem in order to compute the props every time
 * the Ticker triggers a new rendering.
 *
 */



const NtjRacesCarouselItemV4Header = ({ t, id, raceNumber, meetingName, startTime, type, action, className, meetingId, raceName, distance, weather,  country, size, loading,status  }) => {
	
	return (
		<CarouselItemV4
			border
      		lablewather={`${t(',')}${weather}`}
			id={id}
			label={`${t('R ')}${raceNumber}`}
			text={meetingName}
			meta={countdown(startTime)}
			lessThan2Min={countdownLessThan2Min(startTime)}
      		startTime={startTime}
      		raceNumber={raceNumber}
			icon={RACING_TYPES_LOOKUP[type]}
      		type={type}
			action={action}
      		country={country}
			className={className}
      		meeting_id={meetingId}
      		tooltipitem={`${raceName === null ? '' : (raceName)},  <br/> ${distance}m${weather === null ? '' : (',')}  ${weather === null ? '' : (weather)}`}
			size={size}
			loading={loading}
			distance={distance}
		/>
	);
};

NtjRacesCarouselItemV4Header.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}),

	/** The race ID */
	id: PropTypes.number.isRequired,

	/** The race number */
	raceNumber: PropTypes.number.isRequired,

	/** The name of the meeting that the race is part of */
	meetingName: PropTypes.string.isRequired,

	/** The start time of the race */
	startTime: PropTypes.string.isRequired,

	/** The race type */
	type: PropTypes.string.isRequired,

	/** Action to perform when the race item is clicked on */
	action: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,

	meetingId: PropTypes.number.isRequired,

	raceName:PropTypes.string,

	distance:PropTypes.string,

	weather:PropTypes.string,

  	lablewather:PropTypes.string,

  	country:PropTypes.string.isRequired,

	loading:PropTypes.bool
};

NtjRacesCarouselItemV4Header.defaultProps = {
	className: '',
	meetingId: null,
	weather:null,
	lablewather: null,
	size: null,
	raceName: '',
	distance: null,
	loading:false,
};
export default withNamespaces()(NtjRacesCarouselItemV4Header);
