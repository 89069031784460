// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css } from 'react-emotion';
import { SizeMe } from 'react-sizeme';
import { withNamespaces } from 'react-i18next';

// Actions
import { login, logoutUser, unlockScreen } from '../../../store/authentication/authenticationActions';
import { trackGaEvent } from '../../../store/trackingPixels/trackingActions';
import { routeTo } from '../../../store/application/applicationActions';

// Components
import LockedScreen from '../../../components/features/Authentication/LockedScreen/LockedScreen';

// Constants
import { DEFAULT_BRAND_LOGOS } from '../../../common/constants/TopBetta';

// Styling

class LockedScreenContainer extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** List of available icons to use */
		brandLogos: PropTypes.object.isRequired,

		/** Sends the password to login the user */
		login: PropTypes.func.isRequired,

		/** Logs the user out */
		logout: PropTypes.func.isRequired,

		/** GA tracking */
		trackGaEvent: PropTypes.func.isRequired,

		/** Unlocks the screen */
		unlockScreen: PropTypes.func.isRequired,

		/** User's id */
		userId: PropTypes.number,

		/** User's username */
		username: PropTypes.string,

		/** ACL's text for the footer */
		footerMessage: PropTypes.string,

		/** User's Email */
		email: PropTypes.string,
	};

	static defaultProps = {
		footerMessage: null,
		userId: null,
		username: null,
		email: null,
	};

	state = {
		error: null,
		loading: false,
		userId: this.props.userId,
		username: this.props.username,
		email: this.props.email,
	};

	/**
	 * Login the user
	 *
	 * @param password
	 */
	handleLogin = (password) => {
		this.setLoadingMask(true);

		if (!password) {
			this.setState({
				error: 'The password field is required.',
			});
		} else {
			this.props
				//.login(this.state.username, password) //original code
				.login(this.state.email, password) // edit by @HW22May2020
				.then(() => {
					this.clearError();
					this.props.unlockScreen();
					this.props.trackGaEvent('Lock Screen', 'Submit', 'Login');
				})
				.catch((error) => {
					let errors = error.response && error.response.data;

					if (errors && typeof errors.errors === 'string' && errors.errors.startsWith('Login failed')) {
						this.setState({
							error: this.props.t('LockedScreenContainer__Error'),
						});
					} else {
						this.setState({
							error: error.message,
						});
					}
				})
				.finally(() => {
					this.setLoadingMask(false);
				});
		}
	};

	/**
	 * Sets a loading mask
	 */
	setLoadingMask = (loading = false) => {
		this.setState({
			loading: loading,
		});
	};

	/**
	 * Clears the error message
	 */
	clearError = () => {
		this.setState({
			error: null,
		});
	};

	render() {
		const { footerMessage, logout, brandLogos } = this.props;

		const { error, loading, username, userId, email } = this.state;

		return (
			<SizeMe>
				{({ size }) => (
					<LockedScreen
						size={size}
						className={css`
							width: 100%;
							max-width: 500px;
						`}
						clearError={this.clearError}
						error={error}
						footerMessage={footerMessage}
						id={userId}
						login={this.handleLogin}
						logout={logout}
						username={email}
						loading={loading}
						brandLogos={brandLogos}
					/>
				)}
			</SizeMe>
		);
	}
}

const mapStateToProps = (state) => {
	const authUser = state.entities.users[state.application.authenticatedUser] || {};

	return {
		userId: authUser.id,
		username: authUser.username,
		email: authUser.email, // add @HW 22May2020
		footerMessage: state.acl.gamblingMessages,
		brandLogos: state.acl.brandLogos || DEFAULT_BRAND_LOGOS,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		login: (username, password) => {
			return dispatch(login(username, password));
		},
		logout: () => {
			dispatch(logoutUser()).then(() => {
				return dispatch(routeTo('/welcome'), { trigger: true }); // @HW 01/01/2020
				//return dispatch(routeTo('/racing'), { trigger: true }); // original code
			});
		},
		trackGaEvent: (category, action, label) => {
			return dispatch(trackGaEvent(category, action, label));
		},
		unlockScreen: () => dispatch(unlockScreen()),
	};
};

export default withNamespaces()(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(LockedScreenContainer),
);
