import { createAction } from '../../common/actions/actionHelpers';

import {
	ADD_FEATURES,
	SET_FEATURES,
	ENABLE_FEATURE_TOGGLING,
	UPDATE_BULK_FEATURE_TOGGLING,
} from './featureTogglesActionTypes';
import { get } from '../../common/Ajax';

export const fetchFeatures = () => (dispatch) =>
	get('features').then((response) => dispatch(setFeatures(response.data.data)));

/**
 * Adds features to the toggler
 *
 * @param features
 * @returns {Object}
 */
export const addFeatures = (features) => {
	return createAction(ADD_FEATURES, features);
};

/**
 * Sets features on the toggler
 *
 * @param features
 * @returns {Object}
 */
export const setFeatures = (features) => {
	return createAction(SET_FEATURES, features);
};

/**
 * Enables or disables feature toggling
 *
 * @param enabled
 * @returns {Object}
 */
export const enableFeatureToggling = (enabled = true) => {
	return createAction(ENABLE_FEATURE_TOGGLING, enabled);
};

/**
 * Set the status for bulk feature toggling
 *
 * @param status
 * @returns {Object}
 */
export const setBulkFeatureToggling = (status = 0) => {
	return createAction(UPDATE_BULK_FEATURE_TOGGLING, status);
};
