import React from 'react';
import PropTypes from 'prop-types';
import Meta from './Meta';

const DocumentTitle = ({ children }) => {
	return (
		<React.Fragment>
			<Meta id="title" tag="title" content={children} />
			<Meta id="og-title" property="og:title" content={children} />
		</React.Fragment>
	);
};

DocumentTitle.propTypes = {
	children: PropTypes.string.isRequired,
};

export default DocumentTitle;
