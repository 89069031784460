export const GRS_SPORTS_HOME_SET_SELECTED_SPORT = 'GRS_SPORTS_HOME_SET_SELECTED_SPORT';
export const GRS_SPORTS_HOME_SET_SELECTED_BASE_COMPETITION = 'GRS_SPORTS_HOME_SET_SELECTED_BASE_COMPETITION';
export const GRS_SPORTS_HOME_SET_SELECTED_COMPETITION = 'GRS_SPORTS_HOME_SET_SELECTED_COMPETITION';
export const GRS_SPORTS_HOME_SET_SELECTED_EVENT = 'GRS_SPORTS_HOME_SET_SELECTED_EVENT';
export const GRS_SPORTS_HOME_SET_SELECTED_MARKET = 'GRS_SPORTS_HOME_SET_SELECTED_MARKET';
export const GRS_SPORTS_HOME_SET_FILTERS_BATCH = 'GRS_SPORTS_HOME_SET_FILTERS_BATCH';
export const SET_VISIBLE_GRS_SPORTS = 'SET_VISIBLE_GRS_SPORTS'; // not actually apart of GRS sports home
export const GRS_SPORTS_HOME_SET_NTJ_IDS = 'GRS_SPORTS_HOME_SET_NTJ_IDS';
export const GRS_SPORTS_SET_LOADING_EVENTS = 'GRS_SPORTS_SET_LOADING_EVENTS';

export const GRS_SPORT_HOME_SET_CONTAINER_LOADING_MASK = 'GRS_SPORT_HOME_SET_CONTAINER_LOADING_MASK';

// Named Batch Actions
export const GRS_SPORTS_HOME_LOADING__BATCH = 'GRS_SPORTS_HOME_LOADING__BATCH';