import styled, { css } from 'react-emotion';

import { spacings, media } from '@tbh/ui-kit';

// get the default silk image
import { Image } from '@tbh/ui-kit';
import { Text } from '@tbh/ui-kit';
import { FONT_SIZE_12, FONT_SIZE_10, WEIGHTS } from '../../../../../store/application/applicationConstants';

export const StyledUpRaceSelectionDetails = styled('div')`
	${(props) =>
		css`
			label: UpRaceSelectionDetails;

			width: 100%;
			display: flex;
			padding: ${spacings(props).tight}px 0;
		`};
`;

export const StyledGRSNtjRaceSelectionDetails__Silk = styled('div')`
	${(props) =>
		css`
			label: GRSNtjRaceSelectionDetails__Silk;

			float: left;
			width: 30px;
			min-width: 30px;
			max-height: 32px;
			margin-right: ${spacings(props).constrictive}px;
			text-align: center;
			align-self: center;

			${props.scratched &&
			css`
					opacity: 0.2;
					filter: grayscale(100%);
				`};
		`};

	${media(
				(props) =>
					css`
				margin-right: ${spacings(props).tight}px;
			`,
				500,
			)};

	${media(
				css`
			width: 30px;
			min-width: 30px;
			max-height: 32px;
		`,
				660,
			)};
`;

export const StyledGRSNtjRaceSelectionDetails__SilkImage = styled(Image)`
	height: 27px;
	border-radius: 3px !important;

	${media(
	css`
			height: 27px;
		`,
	660,
)};
`;

export const StyledGRSNtjRaceSelectionDetails__SilkLoading = styled('div')`
	height: 27px;
	background-color: grey;
	border-radius: 4px;
	background-color: rgb(54, 69, 79);
	animation: changeColor 2s infinite;
	@keyframes changeColor {
		0% {
			background-color: rgba(54, 69, 79, 0.4);
		}
		25% {
			background-color: rgba(54, 69, 79, 0.6);
		}
		50% {
			background-color: rgba(54, 69, 79, 0.8);
		}
		75% {
			background-color: rgba(54, 69, 79, 0.6);
		}
		100% {
			background-color: rgba(54, 69, 79, 0.4);
		}
	}
	${media(
	css`
			height: 27px;
			background-color: rgb(54, 69, 79);
			border-radius: 4px;
			animation: changeColor 2s infinite;
		`,
	660,
)};
`;

export const StyledGRSNtjRaceSelectionDetails__DetailsColumn = styled('div')`
	${(props) =>
		css`
			label: GRSNtjRaceSelectionDetails__DetailsColumn;

			padding-top: ${spacings(props).tight}px;
			padding-left: ${spacings(props).tight}px;
			text-overflow: ellipsis;
			overflow: hidden;

			${props.scratched &&
			css`
					opacity: 0.2;
					filter: grayscale(100%);
				`};
		`};

	${media(
				css`
			padding-top: 0;
		`,
				660,
			)};
`;

export const StyledGRSNtjGreyhoundsRaceSelectionDetails__DetailsColumn = styled('div')`
	${(props) =>
		css`
			label: GRSNtjGreyhoundsRaceSelectionDetails__DetailsColumn;

			padding-top: 20px;
			padding-left: ${spacings(props).tight}px;
			text-overflow: ellipsis;
			overflow: hidden;

			${props.scratched &&
			css`
					opacity: 0.2;
					filter: grayscale(100%);
				`};
		`};

	${media(
				css`
			padding-top: 8px;
		`,
				660,
			)};
`;
export const StyledGRSNtjGreyhoundsRaceSelectionDetails__DetailsColumnLoading = styled('div')`
	${(props) =>
		css`
			label: GRSNtjGreyhoundsRaceSelectionDetails__DetailsColumnloading;

			padding-top: 20px;
			padding-left: ${spacings(props).tight}px;
			text-overflow: ellipsis;
			overflow: hidden;
			background-color: grey;
			border-radius: 4px;
			width: ${props.width ? props.width : '120px'};
			max-width: 320px;
			background-color: rgb(54, 69, 79);
			animation: changeColor 2s infinite;
			@keyframes changeColor {
				0% {
					background-color: rgba(54, 69, 79, 0.2);
				}
				25% {
					background-color: rgba(54, 69, 79, 0.6);
				}
				50% {
					background-color: rgba(54, 69, 79, 0.8);
				}
				75% {
					background-color: rgba(54, 69, 79, 0.6);
				}
				100% {
					background-color: rgba(54, 69, 79, 0.4);
				}
			}
			${props.scratched &&
			css`
					opacity: 0.2;
					filter: grayscale(100%);
				`};
		`};

	${media(
				css`
			padding-top: 8px;
		`,
				660,
			)};
`;

export const StyledGRSNtjRaceSelectionDetails__Name = styled(Text)`
	${(props) =>
		css`
			label: GRSNtjRaceSelectionDetails__Name;

			margin-right: ${spacings(props).tight}px;
			margin-bottom: 1px;
			&:after {
				content: ' ';
			}

			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			color: rgb(0, 58, 145);
		`};

	${media(
			(props) =>
				css`
				font-size: ${FONT_SIZE_12};
				color: rgb(0, 58, 145);
			`,
			660,
			{ sizeKey: 'mediaSize' },
		)};
`;

export const StyledGRSNtjRaceSelectionDetails__RunnerDetails = styled(Text)`
	${(props) =>
		css`
			label: GRSNtjRaceSelectionDetails__jockeyDetails;

			margin-right: ${spacings(props).tight}px;
			margin-bottom: 1px;
			&:after {
				content: ' ';
			}
		`};

	${media(
			(props) =>
				css`
				font-weight: ${WEIGHTS.exBold};
				font-size: ${FONT_SIZE_10};
			`,
			660,
			{ sizeKey: 'mediaSize' },
		)};
`;

export const StyledGRSNtjRaceSelectionDetails__Subtitle = styled(Text)`
	${(props) =>
		css`
			label: RaceSelectionsDetails__Subtitle;

			margin: ${spacings(props).constrictive}px 0;
			margin: 5px 0;
		`};
`;

export const Styled__BlockPrice = styled('div')`
	${(props) => css`
		position: relative;
		display: inline-flex;
		align-items: stretch;
		min-width: 50px;
		background: #e3e3e3;
		color: #070b19;
		border: 1px solid #f9f9f99c;
		cursor: pointer;
		transition: color 0.1s ease-in-out, border 0.1s ease-in-out;
		text-align: center;
		font-size: 12.641975308641975px;
		flex: 0 0 72px;
		max-width: 72px;
		margin: 0 1px;
		text-align: center;
		font-size: 12px;
		border-radius: 4px !important;
	`};
`;
