/**
 * Libraries
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

/**
 * Actions
 */
import { loginWithPin, saveDevicePin, openLoginForm } from '../../../store/authentication/authenticationActions';
import PINAuthentication from '../../../components/features/Authentication/PINAuthentication/PINAuthentication';
import { clearRedirectRoute } from '../../../store/registration/registrationActions';
import { routeTo } from '../../../store/application/applicationActions';
import { openNotification } from '../../../store/application/applicationActions';
import { closeAllModals } from '../../../store/modal/modalActions';

/**
 * Components
 */
import { Link, LoadingMask, Notification } from '@tbh/ui-kit';
import { css } from 'react-emotion';
const PIN_DEVICE_KEY = 'TB:PIN:DEVICEID';

class PINAuthenticationContainer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			pin: false,
			error: '',
			loading: false,
		};

		this.handlePINSuccess = this.handlePINSuccess.bind(this);
		this.handleSavePIN = this.handleSavePIN.bind(this);
		this.handleLoginWithPin = this.handleLoginWithPin.bind(this);
		this.handleClearError = this.handleClearError.bind(this);
		this.handleLoginWithUsername = this.handleLoginWithUsername.bind(this);
		this.handleReset = this.handleReset.bind(this);
	}

	/**
	 * Reset the state back to the default
	 */
	handleReset() {
		this.setState({
			pin: false,
			error: '',
		});
	}

	/**
	 * Helper to set the loading state
	 *
	 * @param loading
	 */
	isLoading(loading = true) {
		this.setState({ loading });
	}

	/**
	 * Handle logging in with a pin. Treat the prop as a promise
	 * @param pin
	 */
	handleLoginWithPin(pin) {
		this.handleClearError();
		this.isLoading();
		Promise.resolve(
			this.props.loginWithPin(this.setAndGetDeviceId(), this.props.username, pin, this.props.redirectRoute),
		)
			.catch((response) => {
				this.setState({
					error: response.response.data.errors,
					pin: false,
				});
			})
			.finally(() => {
				this.isLoading(false);
			});
	}

	/**
	 * Handle the enter of a successful pin
	 * @param pin
	 */
	handlePINSuccess(pin) {
		this.handleClearError();
		this.setState({ pin });
	}

	/**
	 * Clear all errors from the component
	 */
	handleClearError() {
		this.setState({
			error: '',
		});
	}

	/**
	 * Create a device ID
	 *
	 * @returns {number}
	 */
	setAndGetDeviceId() {
		let existingDeviceId = localStorage.getItem(PIN_DEVICE_KEY);

		if (existingDeviceId) {
			return existingDeviceId;
		}

		return Math.round(new Date().getTime() / 1000);
	}

	/**
	 * Handle saving a PIN
	 *
	 * @param pin
	 */
	handleSavePIN(pin) {
		this.handleClearError();
		this.isLoading();
		Promise.resolve(this.props.saveUserPin(this.setAndGetDeviceId(), pin));
	}

	/**
	 * Handle logging in with a username. Currently uses marionette methods to login
	 */
	handleLoginWithUsername() {
		localStorage.removeItem(PIN_DEVICE_KEY);
		this.props.closeAllModals();
		this.props.onJoinLogin();
	}

	render() {
		let authComponent = (
			<PINAuthentication
				key={this.state.pin}
				pin={this.state.pin || ''}
				defaultMessage={
					<span>
						{this.props.t('PINAuthenticationContainer__ForgotPin')}{' '}
						<Link action={this.handleLoginWithUsername} underline className={css`font-weight: inherit; text-decoration: underline !important; &:hover {color:#850000!important;text-decoration: underline;}`}>
							{this.props.t('PINAuthenticationContainer__Password')}
						</Link>
						<br />
						<span style={{
							fontSize: '10px',
							color: '#666666',
							fontWeight: 'normal',
						}}>
							If you have forgotten your PIN, you can reset it by logging in with your password.
						</span>
					</span>
				}
				onPINComplete={this.handleLoginWithPin}
				handleReset={this.handleReset}
				mustMatch={this.state.pin}
			/>
		);

		if (this.props.registration) {
			authComponent = (
				<PINAuthentication
					key={this.state.pin}
					defaultMessage={
						!this.state.pin
							? this.props.t('PINAuthenticationContainer__Message')
							: this.props.t('PINAuthenticationContainer__RepeatMessage')
					}
					onPINComplete={!this.state.pin ? this.handlePINSuccess : this.handleSavePIN}
					handleReset={this.handleReset}
					mustMatch={this.state.pin}
				/>
			);
		}

		return (
			<div>
				<LoadingMask loading={this.state.loading} />

				{!!this.state.error && (
					<Notification
						message={[this.state.error]}
						strong
						type={Notification.types.COLOUR_DANGER}
						buttonAction={this.handleClearError}
					/>
				)}

				{authComponent}
			</div>
		);
	}
}

PINAuthenticationContainer.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Save the pin */
	saveUserPin: PropTypes.func.isRequired,

	/** Login with the pin */
	loginWithPin: PropTypes.func.isRequired,

	/** Opens the Login/Join modal */
	onJoinLogin: PropTypes.func.isRequired,

	/** The username that is performing the action */
	username: PropTypes.string.isRequired,

	/** Close all currently open modals */
	closeAllModals: PropTypes.func.isRequired,

	/**
	 * Flag to indicate which mode the component is in.
	 * true: Setup components for registration
	 * false: Setup components for login
	 */
	registration: PropTypes.bool,
	redirectRoute: PropTypes.string,
};

PINAuthenticationContainer.defaultProps = {
	registration: false,
	redirectRoute: null,
};

const mapStateToProps = (state, ownProps) => {
	return {
		username: ownProps.username,
		registration: ownProps.registration,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		/**
		 * Dispatch action to save the users PIN against the current device
		 *
		 * @param deviceId
		 * @param pin
		 */
		saveUserPin: (deviceId, pin) =>
			dispatch(saveDevicePin(deviceId, pin)).then(() => {
				localStorage.setItem(PIN_DEVICE_KEY, deviceId);

				dispatch(
					openNotification(ownProps.t('PINAuthenticationContainer__NotificationSuccess'), 'success', {
						autoClose: 5000,
					}),
				);

				dispatch(closeAllModals());
			}),

		/**
		 * Dispatch an action to login with the provided PIN
		 *
		 * @param deviceId
		 * @param username
		 * @param pin
		 * @param redirectRoute
		 */
		loginWithPin: (deviceId, username, pin, redirectRoute) =>
			dispatch(loginWithPin(deviceId, username, pin, redirectRoute)).then(() => {
				if (redirectRoute) {
					dispatch(routeTo('/' + redirectRoute));
					dispatch(clearRedirectRoute());
				}
				dispatch(closeAllModals());
			}),

		/**
		 * Opens the Login/Join modal
		 */
		onJoinLogin: () => {
			return dispatch(openLoginForm(true));
		},

		/**
		 * Closes all currently open modals
		 */
		closeAllModals: () => dispatch(closeAllModals()),
	};
};

export default withNamespaces()(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(PINAuthenticationContainer),
);
