import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { ui, spacings, media } from '@tbh/ui-kit';

import { speedMapLegendGroups } from '../../../../../../common/constants/SpeedmapConstants';

import { Circle, Text, PlotElements } from '@tbh/ui-kit';

const StyledSpeedmapLegend__Circle = styled(Circle)(
	(props) => css`
		label: SpeedmapLegend__Circle;

		margin-right: ${spacings(props).tight}px;
		background-color: ${ui(props)[props.color] ? ui(props)[props.color] : 'rgba(0,0,0,0.3)'};
	`,
);

const StyledSpeedmapLegend__Item = styled(PlotElements)(
	(props) => css`
		label: SpeedmapLegend__Item;

		margin-right: ${spacings(props).tight}px;

		&:last-child {
			margin-right: 0;
		}
	`,
	media(
		(props) => css`
			margin-right: ${spacings(props).cozy}px;
		`,
		380,
	),
);

const SpeedmapLegend = (props) => {
	const { t, className, circleSize, textSize, size } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<PlotElements wrap alignElements="middle" className={componentClasses}>
			{Object.entries(speedMapLegendGroups).map(([key, value]) => {
				return (
					<StyledSpeedmapLegend__Item key={key} size={size}>
						<StyledSpeedmapLegend__Circle color={key} size={circleSize} />
						<Text size={textSize}>{t(`SpeedmapLegend__Value--${value}`)}</Text>
					</StyledSpeedmapLegend__Item>
				);
			})}
		</PlotElements>
	);
};

SpeedmapLegend.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** The size of the circle to use */
	circleSize: PropTypes.number,

	/** The text size to use */
	textSize: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,
};

SpeedmapLegend.defaultProps = {
	circleSize: 12,
	textSize: '-2',
	className: '',
};

export default withNamespaces()(SpeedmapLegend);
