import { Button } from '@tbh/ui-kit';
import React, { useEffect } from 'react';
import styled, { css } from 'react-emotion';

const Curtain = styled('div')`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.5);
  transition: all .3s ease-in-out;

  ${props => !props.open && css`display: none;`};
`;

const ModalContainer = styled('div')`
  position: fixed;
  z-index: 1001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #fff;
  ${props => props.size === 'small' && css`max-width: 300px;`};
  ${props => props.size === 'medium' && css`max-width: 500px;`};
  ${props => props.size === 'large' && css`max-width: 800px;`};
`;

const ModalHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;

  `;

const ModalFooter = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  gap: 12px;
`;

const ModalBody = styled('div')`
  padding: 12px;
  font-size: 12px;
  font-weight: 700;
`;


export default function ModalConfirm({ title, text, open, setOpen, size = 'medium', onConfirm, showConfirm = true }) {

  const curtain_id = `${title}_curtain`;

  useEffect(() => {
    // Check if the curtain is clicked
    window.addEventListener('click', (e) => {
      if (e.target.id == curtain_id) {
        setOpen(false);
      }
    });
  }, []);

  const closeHandler = () => {
    setOpen(false);
  };

  const confirmHandler = () => {
    onConfirm();
  };

  return (
    <Curtain id={curtain_id} open={open}>
      <ModalContainer size={size} id={'auto-modal'}>
        <ModalHeader>
          <h3>{title}</h3>
        </ModalHeader>
        <ModalBody>
          {text}
        </ModalBody>
        <ModalFooter>
          <Button action={closeHandler} css={'background-color:#BB181B;color:#fff;width:100%;border-radius:4px;'}>
            Close
          </Button>

          {showConfirm && (
            <Button action={confirmHandler} css={'background-color:#00A216;color:#fff;width:100%;border-radius:4px;'}>
              Confirm
            </Button>
          )}
        </ModalFooter>
      </ModalContainer>
    </Curtain>
    // <Modal
    //   closeButton
    //   aria-labelledby="modal-title"
    //   open={open}
    //   onClose={closeHandler}
    // >
    //   <Modal.Body>
    //     <Text>
    //   {text}
    // </Text>
    //   </Modal.Body>
    //   <Modal.Footer>
    //     <Button auto flat color="error" onPress={closeHandler}>
    //       Close
    //     </Button>
    //     <Button auto onPress={closeHandler}>
    //       Confirm
    //     </Button>
    //   </Modal.Footer>
    // </Modal>
  );
}
