import { connect } from 'react-redux';
import { trackGaEvent } from '../../../store/trackingPixels/trackingActions';
import { getUserAccountV2MenuItems } from '../../../store/userAccount/userAccountSelectors';
import UserAccountMenu from '../../../components/features/UserAccount/UserAccountMenu/UserAccountMenu';
import { setSelectedMenuItemV2 } from '../../../store/userAccount/userAccountActions';

const mapStateToProps = (state, ownProps) => {
    return {
        className: ownProps.className,
        showHeader: ownProps.showHeader,
        items: getUserAccountV2MenuItems(state),
        activeItem: state.userAccount.selectedMenuItemV2,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSelect: (item) => {
        dispatch(trackGaEvent('MyAccount', 'Click', item.trackingTitle));
        dispatch(setSelectedMenuItemV2(item, false));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAccountMenu);
