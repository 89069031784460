// Libraries
import styled, { css } from 'react-emotion';

export const LoadingSpanner = styled('div')`
	${(props) =>
		css`
			label: LoadingSpanner;
			position: absolute;
			width: 100%;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.1);
			z-index: 9999;
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 40%;
				margin-left: auto;
				margin-right: auto;
				left: 0;
				right: 0;
				text-align: center;
				width: 60px;
				height: 60px;
				border-style: solid;
				border-color: #c72128;
				border-top-color: transparent;
				border-width: 4px;
				border-radius: 50%;
				-webkit-animation: spin 0.8s linear infinite;
				animation: spin 0.8s linear infinite;

				@keyframes spin {
					0% {
						transform: rotate(0deg);
					}
					100% {
						transform: rotate(360deg);
					}
				}
			}
		`};
`;

export const LoadingWithoutSpanner = styled('div')`
	${(props) =>
		css`
            label: LoadingWithoutSpanner;
            position:absolute;
            width:100%;
            left:0;right:0;top:0;bottom:0;
            background-color: rgba(0,0,0,0.1);
            z-index:9999;
		&:after{
                  display:block;
                  position:absolute;
                         `};
`;

export const Spinner = styled('div')`
      width: 18px;
      height: 18px;
      border-radius:50%;
      border:4px solid white;
      border-top-color:#cacaca;
      animation: spin .8s linear infinite;
      -webkit-animation: spin .8s linear infinite;
      animation: spin .8s linear infinite;
      @keyframes spin {0% {transform: rotate(0deg);}00% {transform: rotate(360deg);}}}
`;
