import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@tbh/ui-kit';

const RunnerLastStartRow = (props) => {
	const {
		abr_venue,
		barrier,
		finish_position,
		handicap,
		in_running_400,
		in_running_800,
		jockey_initials,
		jockey_surname,
		margin_decimal,
		mgt_date,
		name_race_form,
		numeric_rating,
		other_runner_barrier,
		other_runner_name,
		other_runner_time,
		race_distance,
		race_starters,
		starting_win_price,
		track_condition,
		runner_time,
		comment,
		...rest
	} = props;

	return (
	  <div>
      <Table.TR wrapRowItems {...rest}>
        <Table.TD textWrap={false} strong>
          {finish_position}/{race_starters}
        </Table.TD>

        <Table.TD textWrap={false} colSpan="2">
          {runner_time}
        </Table.TD>

        <Table.TD textWrap={false} colSpan="2">
          {mgt_date}
        </Table.TD>

        <Table.TD textWrap={false} center colSpan="3">
          {abr_venue}
        </Table.TD>

        <Table.TD textWrap={false} right>
          {margin_decimal ? margin_decimal + 'L' : '-'}
        </Table.TD>



        <Table.TD textWrap={false} right colSpan="2">
          {race_distance}
        </Table.TD>

        <Table.TD textWrap={false} colSpan="2" center>
          {name_race_form}
        </Table.TD>



        <Table.TD textWrap={false} colSpan="2">
          {track_condition}
          {numeric_rating}
        </Table.TD>

        <Table.TD textWrap={false} strong colSpan="3">
          {jockey_initials} {jockey_surname} {barrier ? '(' + barrier + ')' : null}
        </Table.TD>

        {/* <Table.TD textWrap={false} right>
				{handicap}
			</Table.TD> */}

        <Table.TD textWrap={false} right>
          {barrier}
        </Table.TD>

        <Table.TD textWrap={false} right colSpan="2" >
          {starting_win_price ? '$' + starting_win_price : null}
        </Table.TD>

        <Table.TD textWrap={false} strong colSpan="4" center>
          {other_runner_name} {other_runner_barrier ? '(' + other_runner_barrier + ')' : null}
        </Table.TD>

        {/* <Table.TD textWrap={false} center>
				{!in_running_800 && !in_running_400 ? null : in_running_800 || 0 + '-' + in_running_400 || 0}
			</Table.TD>

			<Table.TD textWrap={false} right>
				{other_runner_time}
			</Table.TD> */}

      </Table.TR>
      <Table.TR wrapRowItems {...rest}>
        <Table.TD textWrap={false}  colSpan="8" left>
          {comment}
        </Table.TD>
      </Table.TR>
    </div>

	);
};

RunnerLastStartRow.propTypes = {
	finish_position: PropTypes.number.isRequired,
	race_starters: PropTypes.number.isRequired,
	abr_venue: PropTypes.string,
	race_distance: PropTypes.number,
	name_race_form: PropTypes.string,
	mgt_date: PropTypes.string,
	track_condition: PropTypes.string,
	numeric_rating: PropTypes.number,
	jockey_initials: PropTypes.string,
	jockey_surname: PropTypes.string,
	barrier: PropTypes.number,
	handicap: PropTypes.string,
	starting_win_price: PropTypes.string,
	other_runner_name: PropTypes.string,
	other_runner_barrier: PropTypes.number,
	in_running_800: PropTypes.number,
	in_running_400: PropTypes.number,
	other_runner_time: PropTypes.string,
	margin_decimal: PropTypes.string,
	runner_time: PropTypes.string,
	comment: PropTypes.string,
};

RunnerLastStartRow.defaultProps = {
	abr_venue: null,
	race_distance: null,
	name_race_form: null,
	mgt_date: null,
	track_condition: null,
	numeric_rating: null,
	jockey_initials: null,
	jockey_surname: null,
	barrier: null,
	handicap: null,
	starting_win_price: null,
	other_runner_name: null,
	other_runner_barrier: null,
	in_running_800: null,
	in_running_400: null,
	other_runner_time: null,
	margin_decimal: null,
	runner_time: null,
	comment: null,
};

export default RunnerLastStartRow;
