import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { NotFound, Table } from '@tbh/ui-kit';
import PendingWithdrawalRow from './PendingWithdrawalsRow/PendingWithdrawalRow';

const PendingWithdrawalsList = (props) => {
	const { t, isLoading, onWithdrawalCancel, pendingWithdrawals, size } = props;

	const columns = [
		{
			id: 'date',
			key: 'date',
			title: t('Date'),
			span: '2',
		},
		{
			id: 'description',
			key: 'description',
			title: t('Description'),
			span: '5',
		},
		{
			id: 'amount',
			key: 'amount',
			title: t('Amount'),
			span: '2',
		},
		{
			id: 'balance',
			key: 'balance',
			title: t('Balance'),
			span: '1',
		},
	];

	return (
		<div>
			{pendingWithdrawals.length ? (
				<Table>
					{size.width >= PendingWithdrawalRow.PENDING_WITHDRAWAL_ROW_BREAKPOINT && (
						<Table.TR size={size} bottomBorder>
							{columns.map((column) => (
								<Table.TD key={column.id} head colSpan={column.span}>
									{column.title}
								</Table.TD>
							))}
						</Table.TR>
					)}
					{pendingWithdrawals.map((row) => (
						<PendingWithdrawalRow key={row.id} item={row} onClick={onWithdrawalCancel} size={size} />
					))}
				</Table>
			) : (
				isLoading || <NotFound message={t('WithdrawalPromptContainer__PendingNotFound')} />
			)}
		</div>
	);
};

PendingWithdrawalsList.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** Action to handle cancelling a withdrawal */
	onWithdrawalCancel: PropTypes.func.isRequired,

	/** The list of Pending Withdrawals */
	pendingWithdrawals: PropTypes.array,

	/** If the withdrawals are loading or not */
	isLoading: PropTypes.bool,
};

PendingWithdrawalsList.defaultProps = {
	pendingWithdrawals: [],
	isLoading: false,
};

export default withNamespaces()(PendingWithdrawalsList);
