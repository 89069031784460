import React, { Component } from 'react';
import PropTypes from 'prop-types';
import countdown from 'countdown';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { brand, spacings, typography, ui, media } from '@tbh/ui-kit';
import ReactTooltip from 'react-tooltip';
import { formatToGoCountdown } from '../../../../../legacy/core/format';
import {
	RACE_STATUSES,
	RACE_SELLING_STATUS,
	RACE_CLOSED_STATUS,
	RACE_ABANDONED_STATUS,
	RACE_INTERIM_STATUS,
	RACE_PAID_STATUS,
	RACE_AT_THE_POST_STATUS,
	RACE_GOING_BEHIND_STATUS,
	RACE_FINISHED_STATUS,
	RACE_PROTEST_STATUS,
	RACE_PROTEST_UPHELD,
	RACE_PROTEST_DISMISSED,
	RACE_PAYING_STATUS,
	RACE_AT_THE_PARADING_STATUS,
	RACE_GOING_DOWN_STATUS,
	RACE_FALSE_START_STATUS,
	RACE_SUSPENDED_STATUS,
} from '../../../../../common/constants/Racing';

import { Text } from '@tbh/ui-kit';
import MyBetsIcon from '../../../../../containers/Application/MyBets/MyBetsIcon/MyBetsIcon';
import PusherSubscriberNjUpcomingRaceChannel from '../../PusherSubscribers/PusherSubscriberNjUpcomingRaceChannel';
import PusherSubscriberGRSNjRaceChannel from '../../PusherSubscribers/PusherSubscriberGRSNjRaceChannel';
import { debounce } from 'lodash-es';
import { RACE_DELETED_STATUS } from './../../../../../common/constants/Racing';

const StyledRaceItem__Status = styled(Text)(
	(props) => css`
		label: RaceItem__Status;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		${props.status === RACE_CLOSED_STATUS &&
		css`
				color: #fff;
			`};


	`,
);

const StyledRaceItem__Label = styled('div')`
	label: RaceItem__Label;
`;

const StyledHoverRaceItemInfo = styled('div')(
	(props) => css`
		border-radius: 3px;
		display: inline-block;
		font-size: 13px;

		left: ${!props.open ? '0' : '50%'};
    	/* ${props.open &&
		css`
					transform: translateX(-50%);
				`}; */
		top: ${!props.open ? '0' : '-35px'};
		opacity: ${!props.open ? '0' : '1'};
		visibility: ${!props.open ? 'hidden' : 'visible'};
		padding: 8px 21px;
		position: absolute;
		pointer-events: none;
		transition: opacity 0.3s ease-out;
		z-index: 15000;
		background-color: #8dc572;
	`,
);

const StyledRaceItem__MyBetsIcon = styled(MyBetsIcon)(
	(props) => css`
		label: RaceItem__MyBetsIcon;

		position: absolute;
		top: -${spacings(props).tight}px;
		left: 0;
		right: 0;
		display: inline-block;
		height: 12px;
		text-align: center;
		margin: auto;
	`,
);

const raceItemColor = (status) => {
	/*
	return type = default, danger, info , dark
	 */

	switch (status) {
		case RACE_SELLING_STATUS:
			return 'selling';
		case RACE_AT_THE_PARADING_STATUS:
			return 'parading';
		case RACE_GOING_BEHIND_STATUS:
			return 'going_behind';
		case RACE_GOING_DOWN_STATUS:
			return 'going_behind';
		case RACE_AT_THE_POST_STATUS:
			return 'at_the_post';
		case RACE_CLOSED_STATUS:
			return 'closed';
		case RACE_FINISHED_STATUS:
			return 'closed';
		case RACE_FALSE_START_STATUS:
			return 'closed';
		case RACE_INTERIM_STATUS:
			return 'paying';
		case RACE_PROTEST_STATUS:
			return 'paying';
		case RACE_PROTEST_UPHELD:
			return 'paying';
		case RACE_PROTEST_DISMISSED:
			return 'paying';
		case RACE_PAYING_STATUS:
			return 'paying';
		case RACE_PAID_STATUS:
			return 'paid';
		case RACE_ABANDONED_STATUS:
			return 'abandoned';
		case RACE_DELETED_STATUS:
			return 'abandoned';
		default:
			return 'selling';
	}
};
const StyledRaceItem = styled('div')(
	(props) => css`
		label: RaceItem;

		display: block;
		position: relative;
		font-size: ${typography(props)['size_-3']};
		text-decoration: none;
		padding: ${spacings(props).compact}px 0;
		height: 100%;
		p {
			margin: 0;
			font-size: 12px;
		}
		${props.fixed_odds_enabled &&
		css`
				&:before {
					content: '';
					display: block;
					position: absolute;
					border-bottom: 10px solid ${ui(props).color_transparent};
					border-left: 10px solid ${brand(props).color_2};
					top: 0;
					left: 0;
					display: none;
				}
			`};

		${props.global_tote_available &&
		css`
				&:after {
					content: '';
					display: block;
					position: absolute;
					border-bottom: 6px solid ${ui(props).color_transparent};
					border-left: 6px solid ${brand(props).color_1};
					top: 0;
					left: 0;
				}
			`};
		${props.status === 'paying' &&
		css`
				background-color: #32be60;
			`};
		${props.status === 'interim' &&
		css`
				background-color: #32be60;
			`};

		${props.status === 'closed' &&
		css`
				background-color: ${brand(props).rch_4};

			`};
		${props.status === 'abandoned' &&
		css`
				background-color: #c0c0c0;
			

			`};
		${props.status === 'deleted' &&
		css`
				background-color: #343a40;

			`};
		${props.status === 'paid' &&
		css`
				color: #ffff;
				background-color: #5d97fb;
			`};

		${props.status === 'selling' &&
		css`
				color: black;
				background-color: #ffff;
			`};
		${props.status === 'parading' &&
		css`
				color: black;
				animation: slide 1s infinite;
			`};
		${props.status === 'at_the_post' &&
		css`
				color: black;
				animation: slide 1s infinite;
			`};
		${props.status === 'going_behind' &&
		css`
				color: black;
				animation: slide 1s infinite;
			`};
	`,
	(props) =>
		media(
			css`
				padding: ${spacings(props).tight}px 0;
			`,
			767,
		),
);

class RaceItem extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The race ID */
		id: PropTypes.number.isRequired,

		/** The race number */
		number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

		/** The start date of the race */
		eventStart: PropTypes.string.isRequired,

		/** The HREF link for the race */
		race_site_link: PropTypes.string.isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
		}).isRequired,

		/** The result of the race */
		result_string: PropTypes.string,

		/** If there are fixed odds enabled for the race */
		fixed_odds_enabled: PropTypes.bool,

		/** If GT is enabled fore the race */
		global_tote_available: PropTypes.bool,

		/** If the current user has placed any bets on the race */
		hasBets: PropTypes.bool,

		/** The status of the race */
		status: PropTypes.oneOf(RACE_STATUSES),

		/** Handle a race item being clicked on */
		onClickItem: PropTypes.func,

		/** Whether or not to show the race label */
		showRaceLabel: PropTypes.bool,

		/** The name of the meeting for the race */
		meeting_name: PropTypes.string,

		/** Extra classes */
		className: PropTypes.string,

		/** Display the compacted version, used when there are lots of races */
		isCompact: PropTypes.bool,

		prize_money: PropTypes.string,

		distance: PropTypes.string,

		name: PropTypes.string,

		type: PropTypes.string,

		date: PropTypes.string,
	};

	static defaultProps = {
		className: null,
		result_string: null,
		fixed_odds_enabled: false,
		global_tote_available: false,
		hasBets: false,
		status: RACE_SELLING_STATUS,
		onClickItem: function () { },
		showRaceLabel: true,
		meeting_name: null,
		isCompact: false,
		prize_money: null,
		distance: null,
		name: null,
		type: null,
		date: null,
	};

	state = {
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
		tick: 0,
		status: this.props.status,
		eventStart: this.props.eventStart,
		openHoverInfo: false,
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		// If got new props or cannot tick. Should try to setup ticker.
		if (nextProps.status !== prevState.status || nextProps.eventStart !== prevState.eventStart) {
			return {
				status: nextProps.status,
				eventStart: nextProps.eventStart,
			};
		} else if (nextProps.eventStart) {
			// On minute cycles we should update the countdown values to change component state.
			return {
				...countdown(new Date(nextProps.eventStart)),
			};
		}

		return null;
	}

	componentDidMount() {
		this.setupTick(this.props.status, this.props.eventStart);
	}


	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.status !== this.props.status ||
			nextProps.status_display !== this.props.status_display ||
			nextState.openHoverInfo !== this.state.openHoverInfo ||
			nextState.tick != this.state.tick
		) {
			return true;
		}
		return false;
	}
	componentDidUpdate(prevProps) {
		if (prevProps.status !== this.props.status) {
			this.setupTick(this.props.status, this.props.eventStart);
		}
	}

	componentWillUnmount() {
		this.stopTick();
	}


	// 	this.setupTick(this.props.status, this.props.eventStart);
	// 	return false;
	// }
	/**
	 * Setup the ticker if the race is still selling and within 6 minutes
	 */
	setupTick(newStatus, newEventStart) {
		this.stopTick();
		let cd = countdown(new Date(newEventStart));
		let shouldTick =
			this.statusPattern(newStatus) === RACE_SELLING_STATUS;
		// && cd.minutes < 6 && cd.hours === 0 && cd.days === 0;
		this.setState({
			...cd,
			tick: 1,
			shouldTick,
		});

		if (shouldTick) {
			this.ticker = setInterval(() => {
				this.setState((prevState) => {
					return {
						...countdown(new Date(this.props.eventStart)),
						tick: prevState.tick + 1,
					};
				});
			}, 1000);
		}
	}

	/**
	 * Stop the interval
	 */
	stopTick() {
		clearInterval(this.ticker);
		this.ticker = undefined;
	}

	/**
	 * Build the countdown object. The `countdown` produces an object which has properties like minutes, hours, days etc
	 * It is being flatted into state so there isn't a change every time we produce the object
	 * React tool tip added this page
	 * How to use react tool tip
	 * Ex <p data-tip='' data-for='test'></p>   <ReactTooltip id='test'>{}</ReactTooltip>
	 */
	buildCountdown() {
		return {
			...this.state,
		};
	}

	statusPattern(status) {
		switch (status) {
			case RACE_SELLING_STATUS:
				return 'selling';
			case RACE_AT_THE_PARADING_STATUS:
				return 'selling';
			case RACE_GOING_BEHIND_STATUS:
				return 'selling';
			case RACE_GOING_DOWN_STATUS:
				return 'selling';
			case RACE_AT_THE_POST_STATUS:
				return 'selling';
			case RACE_CLOSED_STATUS:
				return 'closed';
			case RACE_FINISHED_STATUS:
				return 'closed';
			case RACE_FALSE_START_STATUS:
				return 'closed';
			case RACE_INTERIM_STATUS:
				return 'paying';
			case RACE_PROTEST_STATUS:
				return 'paying';
			case RACE_PROTEST_UPHELD:
				return 'paying';
			case RACE_PROTEST_DISMISSED:
				return 'paying';
			case RACE_PAYING_STATUS:
				return 'paying';
			case RACE_PAID_STATUS:
				return 'paid';
			case RACE_ABANDONED_STATUS:
				return 'abandoned';
			case RACE_DELETED_STATUS:
				return 'abandoned';
			case RACE_SUSPENDED_STATUS:
				return 'abandoned';
			default:
				return 'selling';
		}
	}

	isNormalStatus(status, status_display) {
		// Checks if the status is one of the principals
		// Paid - Selling - Closed - Paying
		return this.statusPattern(status_display) === status;
	}

	removeUnderLine(string) {
		// Remove under line from the status's string
		return string.replaceAll('_', ' ');
	}

	validadeSellingStates(status) {
		// Validade if the status is one of new Ones
		// Parading - At the post - Going Down - Going Behind
		return status === 'parading' ||
			status === 'at_the_post' ||
			status === 'going_down' ||
			status === 'going_behind';
	};
	render() {
		const {
			t,
			className,
			id,
			number,
			result_string,
			eventStart,
			race_site_link,
			fixed_odds_enabled,
			global_tote_available,
			hasBets,
			status,
			onClickItem,
			showRaceLabel,
			meeting_name,
			isCompact,
			size,
			status_display,
			prize_money,
			distance,
			name,
			type,
			date,
		} = this.props;

		// race_status
		// race_status_display_fild

		const raceItemClasses = cx({
			[className]: className,
		});

		/**
		 * Handles the click on each list item
		 *
		 * @param e
		 */
		const handleClickItem = (e) => {
			e.preventDefault();
			onClickItem(type, date, id);
		};

		const statusLabel = result_string ? result_string : '';
		// const statusLabel = t(`${status_display}`);
		const RaceItem__LabelTag = showRaceLabel || meeting_name ? StyledRaceItem__Label : 'div';

		// Styling used on the status cells
		const cssDisplay =
			size.width < 600
				? css`
						display: block;
				  `
				: css`
						display: block;
				  `;

		const debouncedHandleMouseEnter = debounce(() => this.setState({ openHoverInfo: true }), 500);

		const handleMouseLeave = () => {
			this.setState({ openHoverInfo: false });
			debouncedHandleMouseEnter.cancel();
		};

		const lowerCaseStatus = status ? status.toLowerCase() : status;
		const lowerCaseStatusDisplay = status_display ? status_display.toLowerCase() : status_display;

		return (
			<React.Fragment>
				<StyledRaceItem
					className={raceItemClasses}
					href={race_site_link}
					onClick={handleClickItem}
					status={raceItemColor(this.isNormalStatus(lowerCaseStatus, lowerCaseStatusDisplay) ? lowerCaseStatusDisplay : lowerCaseStatus)}
					fixed_odds_enabled={fixed_odds_enabled}
					global_tote_available={global_tote_available}
					size={size}
					id="sdasdasdas"
				>
					{hasBets && <StyledRaceItem__MyBetsIcon />}
					<PusherSubscriberNjUpcomingRaceChannel raceId={id} />
					<PusherSubscriberGRSNjRaceChannel raceId={id} />
					<RaceItem__LabelTag size={size}>
						{showRaceLabel && meeting_name ? (
							<Text size="-3" align="center" strong ellipsis whiteSpace="nowrap" transform="uppercase">
								{meeting_name}
							</Text>
						) : null}
					</RaceItem__LabelTag>

					{status === RACE_SELLING_STATUS ? (
						<StyledRaceItem__Status
							className={cssDisplay}
							size={isCompact ? '-5' : '-3'}
							align="center"
							type={raceItemColor(this.isNormalStatus(lowerCaseStatus, lowerCaseStatusDisplay) ? lowerCaseStatusDisplay : lowerCaseStatus)}
							whiteSpace="nowrap"
							status={raceItemColor(this.isNormalStatus(lowerCaseStatus, lowerCaseStatusDisplay) ? lowerCaseStatusDisplay : lowerCaseStatus)}
						>
							<StyledHoverRaceItemInfo
								open={this.state.openHoverInfo}
							>{`R${number} | ${name} | ${distance}m`}</StyledHoverRaceItemInfo>
							{showRaceLabel ? (
								<React.Fragment>
									<Text
										size="-3"
										tag="span"
										align="center"
										strong
										className={
											size.width < 767
												? css`
													display: none;
											  `
												: css`
													display: inline;
											  `
										}
										transform="uppercase"
									>
										{t('R')}
										&nbsp;
									</Text>
									<Text size="-3" tag="span" align="center" strong>
										{number}
									</Text>
								</React.Fragment>
							) : null}


							{!showRaceLabel && <p>{formatToGoCountdown(this.buildCountdown(), eventStart)}</p>
							}

							{
								this.validadeSellingStates(lowerCaseStatusDisplay)
								&& showRaceLabel
								&& <span style={{ marginLeft: '0px' }}>{formatToGoCountdown(this.buildCountdown(), eventStart)}</span>
							}
							{
								!this.validadeSellingStates(lowerCaseStatusDisplay)
								&& showRaceLabel
								&& <p>{formatToGoCountdown(this.buildCountdown(), eventStart)}</p>
							}

							{this.validadeSellingStates(lowerCaseStatusDisplay) &&
								<p>{t(`${lowerCaseStatusDisplay.replaceAll('_', ' ').toUpperCase()}`)}</p>
							}

							{/* <div>
								{!showRaceLabel && <p onMouseEnter={debouncedHandleMouseEnter} onMouseLeave={handleMouseLeave}>
									{formatToGoCountdown(this.buildCountdown(), eventStart)}
								</p>}
								{t(`${lowerCaseStatusDisplay.replaceAll('_', ' ').toUpperCase()}`)}
							</div>
						) : (
							<p onMouseEnter={debouncedHandleMouseEnter} onMouseLeave={handleMouseLeave} style={{ margin: '0px' }}>
								{formatToGoCountdown(this.buildCountdown(), eventStart)}
							</p>
						)} */}

							<ReactTooltip id="RacecardTooltip" type="success" />
						</StyledRaceItem__Status>
					) : (
						<StyledRaceItem__Status
							id="sdasdasdasd"
							className={cssDisplay}
							size={isCompact ? '-5' : lowerCaseStatus === RACE_ABANDONED_STATUS ? '-4' : '-3'}
							type={status === RACE_ABANDONED_STATUS ? 'light' : 'default'}
							align="center"
							italic={status === RACE_ABANDONED_STATUS}
							transform="uppercase"
							whiteSpace="nowrap"
							status={raceItemColor(this.isNormalStatus(lowerCaseStatus, lowerCaseStatusDisplay) ? lowerCaseStatusDisplay : lowerCaseStatus)}
						>

							{showRaceLabel ? (
								<React.Fragment>

									<Text
										size="-3"
										tag="span"
										align="center"
										strong
										className={
											size.width < 767
												? css`
													display: none;
											  `
												: css`
													display: inline;
											  `
										}
										transform="uppercase"
									>
										{t('R')}
										&nbsp;
									</Text>
									<Text size="-3" tag="span" align="center" strong>
										{number}
									</Text>
								</React.Fragment>
							) : null}
							{statusLabel && <span style={{ marginLeft: '8px' }}>{statusLabel}</span>}
							<p>
								{this.isNormalStatus(lowerCaseStatus, lowerCaseStatusDisplay)
									? t(`${this.removeUnderLine(lowerCaseStatusDisplay)}`).toUpperCase()
									: t(`${this.removeUnderLine(lowerCaseStatus)}`).toUpperCase()}
							</p>
						</StyledRaceItem__Status>
					)}
				</StyledRaceItem>
			</React.Fragment>
		);
	}
}

RaceItem.StyledRaceItem__Label = StyledRaceItem__Label;
RaceItem.StyledRaceItem__Status = StyledRaceItem__Status;

export default withNamespaces()(RaceItem);
