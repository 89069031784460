import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'react-emotion';
import { typography } from '@tbh/ui-kit';
import { FAMILYS } from '../../../constants/themes';
import { formatOdds } from '../../../common/BetPlacement';
import { formatAndAddExpertTipsSelectionBet } from '../../../common/actions/multiActions';
import { toggleSideBetSlip } from '../../../store/application/applicationActions';
import { allocationWeightCalculate } from '../../../common/allocationWeightCalc';

const SelectionListRow = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px;
    margin-bottom: 6px;
    border-radius: 2px;
    border: 1px solid #e1e1e1;
    background-color: #f3f3f3;
`;

const SelectionListLeftCol = styled('div')`
    display: flex;
    align-items: center;
`;

const SelectionAvatrWrapper = styled('div')`
    width: 36px;
    height: 36px;
    text-align: center;
    margin-right: 6px;
    border-radius: 50%;
    border: ${props => props.isNumberView ? 'none' : '1px solid #eb9c9c'};
    background-color: ${props => props.isNumberView ? 'none' : '#f7aaa2'};
`;

const SelectionAvatr = styled('img')`
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: ${props => props.isNumberView ? '50%' : '0'};
`;

const SelectionNameWrapper = styled('div')`
    text-align: left;
`;

const SelectionName = styled('h6')(
    (props) => css`
        color: #111111;
        font-weight: 700;
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 0;
        font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};
    `
);

const SelectionJockey = styled('h6')(
    (props) => css`
        color: #111111;
        font-weight: 700;
        font-size: 10px;
        text-transform: uppercase;
        margin-bottom: 0;
        font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};
    `
);

const TipsButton = styled('button')(
    (props) => css`
        background: none;
        color: #111111;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        text-decoration: none;
        border: 0;
        padding: 0.375rem 0.75rem;
        font-weight: 500;
        font-size: 15px;
        line-height: 1.5;
        font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};
        cursor: pointer;
    `
);

const OddButton = styled(TipsButton)`
    background-color: #ffffff;
    min-width: 60px;
    font-weight: 700;
    font-size: 12px;
    display: inline-block;
    text-align: center;
    padding: 0.375rem 0.75rem;
    border-radius: 3px;
    border: 1px solid #e1e1e1;
    transition: background-color 0.2s linear, color 0.2s linear;

    &:hover {
        background-color: #660809;
        color: #ffffff;
    }
`;

const AllocationWeightDisplay = styled('span')`
    text-transform: initial;
    font-size: 10px;
`;
/**
     * Handles click on plus sign. Build data from de-normalized entities and dispatch action.
     *
     * @param selectionId
     * @param productId
     * @param betType
     */


class TopSelectionListView extends React.Component {
    constructor(props) {
        super(props);
        this.handleAddWidOddToBet = this.handleAddWidOddToBet.bind(this);
    }

    handleAddWidOddToBet(selectionId, productId, betType = 'win') {
        const { race, meetingId, raceId, eventContext } = this.props;


        this.props.formatAndAddExpertTipsSelectionBet(race, selectionId, raceId, meetingId, productId, betType);
        this.props.onSideBetSlipClick(true);
    };

    render() {
        const { raceType } = this.props;
        const renderSelections = this.props.selections.map((item, index) => (
            <SelectionListRow key={index}>
                <SelectionListLeftCol>
                    <SelectionAvatrWrapper isNumberView={item.type_code === 'G'}>
                        <SelectionAvatr isNumberView={item.type_code === 'G'} src={item.silk} alt="Icon" />
                    </SelectionAvatrWrapper>
                    <SelectionNameWrapper>
                        {item.type_code === 'T' ? (
                            <SelectionName>{`${item.number}. ${item.name} (${item.barrier})`}</SelectionName>
                        ) : (
                            <SelectionName>{`${item.number}. ${item.name}`}</SelectionName>
                        )}
                        {item.type_code === 'H' && (
                            <React.Fragment>
                                <SelectionJockey>T: {item.trainer}</SelectionJockey>
                                <SelectionJockey>D: {item.jockey}</SelectionJockey>
                            </React.Fragment>
                        )}
                        {item.type_code === 'G' && (
                            <React.Fragment>
                                <SelectionJockey>T: {item.trainer}</SelectionJockey>
                            </React.Fragment>
                        )}
                        {item.type_code === 'T' && (
                            <React.Fragment>
                                <SelectionJockey>T: {item.trainer}</SelectionJockey>
                                <SelectionJockey>J: {item.jockey} <AllocationWeightDisplay>{allocationWeightCalculate(item.weight, item.allocation)}</AllocationWeightDisplay></SelectionJockey>
                                <SelectionJockey>W: {`${item.weight}kg`}</SelectionJockey>
                            </React.Fragment>
                        )}
                    </SelectionNameWrapper>
                </SelectionListLeftCol>
                <OddButton type="submit" onClick={() => this.handleAddWidOddToBet(item.id, 16)}>{formatOdds(item.prices[0].win_odds)}</OddButton>
            </SelectionListRow>
        ));
        return (
            <React.Fragment>{renderSelections}</React.Fragment>
        );
    }
}


const mapDispatchToProps = (dispatch) => ({
    formatAndAddExpertTipsSelectionBet: (race, selectionId, raceId, meetingId, productId, betType) =>
        dispatch(formatAndAddExpertTipsSelectionBet(race, selectionId, raceId, meetingId, productId, betType)),


    onSideBetSlipClick: (isSideBetSlipOpen) => {
        return dispatch(toggleSideBetSlip(isSideBetSlipOpen));
    },
});

export default connect(undefined, mapDispatchToProps)(TopSelectionListView);