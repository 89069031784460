import { createAction } from '../../common/actions/actionHelpers';
import { OPEN_MODAL, CLOSE_MODAL, CLOSE_ALL_MODALS } from './modalActionsTypes';

/**
 * Open a modal
 *
 * @param payload
 * @returns {Object}
 */
export const openModal = (payload) => {
	return createAction(OPEN_MODAL, payload);
};

/**
 * Close a specific modal
 *
 * @param modalId
 * @returns {Object}
 */
export const closeModal = (modalId) => {
	return createAction(CLOSE_MODAL, modalId);
};

/**
 * Close all modals
 *
 * @returns {Object}
 */
export const closeAllModals = () => {
	return createAction(CLOSE_ALL_MODALS);
};
