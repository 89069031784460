// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { application, ui, typography, spacings } from '../../../theme';

// Components
import Icon from '../../Icon/Icon';

const StyledSelect__Arrow = styled('span')(
	(props) => css`
		label: Select__Arrow;

		position: absolute;
		right: ${props.size === 'small' ? '1px' : '10px'};
		border: 5px solid ${ui(props).color_transparent};
		border-top-color: ${typography(props).base_color};
		z-index: 1;
		top: ${props.size === 'small' ? '10px' : !props.label ? '14px' : '26px'};
	`,
);

const StyledSelect__Label = styled('label')(
	(props) => css`
		label: Select__Label;

		font-size: ${typography(props)['size_-2']};
		line-height: ${typography(props)['size_-2']};
		color: ${typography(props).color_2};
		font-weight: ${typography(props).weight.regular};
		margin-bottom: 0;
	`,
);

const StyledSelect__Icon = styled(Icon)`
	label: Select__Icon;

	position: absolute;
	top: 12px;
	right: 30px;
`;

const StyledSelect__Select = styled('select')(
	(props) => css`
		label: Select__Select;

		appearance: none;
		height: ${props.size === 'small' ? `${application(props).form_height_small}px` : 'auto'};
		width: 100%;
		box-sizing: border-box;
		font-size: ${typography(props)['size_-1']};
		font-size: ${props.size === 'small' ? typography(props)['size_-2'] : typography(props)['size_-1']};
		line-height: initial;
		color: inherit;
		background-color: ${ui(props).color_transparent};
		padding: ${
	props.size === 'small'
		? `${spacings(props).constrictive}px ${spacings(props).compact}px 0 0`
		: `${spacings(props).compact}px 0`
};
		margin: 0;
		border: none;
		outline: none;
		cursor: pointer;
		z-index: 2;
		
		&::-ms-expand {
		  display: none;
		}

		> option {
			appearance: none;
			background: #ffffff; // This is to keep the selectbox dropdowns consistent between browsers
			color: #000000; // This is to keep the selectbox dropdowns consistent between browsers
		}

	`,
);

const StyledSelect = styled('div')(
	(props) => css`
		label: Select;

		position: relative;
		display: flex;
		flex-flow: row wrap;
		border: none;
		border-bottom: 1px solid ${ui(props).color_4};
		font-size: ${typography(props).size_0};
		background-color: ${ui(props).color_transparent};
		color: inherit;

		&:hover {
			border-bottom: 1px solid ${ui(props).color_5};
		}

		${props.size &&
			props.size === 'small' &&
			css`
				height: ${application(props).form_height_small}px;
			`};

		${props.margin &&
			css`
				margin-bottom: ${spacings(props)[props.margin]}px;
			`};
	`,
);

class Select extends React.Component {
	static propTypes = {
		/** Name attribute */
		name: PropTypes.string.isRequired,

		/**
		 * The SelectOption components should be wrapped by the Select component.
		 * There should be at least one child
		 */
		children: PropTypes.node.isRequired,

		/** The current selected option value */
		value: PropTypes.string,

		/** Label to display for the component */
		label: PropTypes.string,

		/** Hook for when the selected value changes */
		onChange: PropTypes.func,

		/** If the Select is disabled or not */
		disabled: PropTypes.bool,

		/** Different heights for the component */
		size: PropTypes.oneOf(['small', 'medium']),

		/** Bottom margin */
		margin: PropTypes.oneOf([null, 'compact', 'cozy']),

		/** Icon that will appear inside the component on the right side */
		icon: PropTypes.string,

		/** Extra classes */
		className: PropTypes.string,

		/** Class name for the Select element */
		selectClassName: PropTypes.string,
	};

	static defaultProps = {
		value: undefined,
		label: null,
		onChange: null,
		disabled: false,
		size: 'medium',
		margin: null,
		icon: null,
		className: '',
		selectClassName: '',
	};

	state = {
		selectOnFocus: false,
	};

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		const label = target[target.selectedIndex] ? target[target.selectedIndex].label : '';

		this.props.onChange(name, value, label);
	};

	handleFocus = () => {
		this.setState({
			selectOnFocus: !this.state.selectOnFocus,
		});
	};

	render() {
		const { className, selectClassName, children, name, label, size, disabled, margin, icon, value } = this.props;
		const { selectOnFocus } = this.state;

		const selectClasses = cx({
			[className]: className,
		});

		return (
			<StyledSelect className={selectClasses} focus={selectOnFocus} margin={margin} size={size}>
				<StyledSelect__Arrow size={size} label={label} />

				{label && size !== 'small' ? <StyledSelect__Label>{label}</StyledSelect__Label> : null}

				<StyledSelect__Select
					className={selectClassName}
					disabled={disabled}
					name={name}
					onBlur={this.handleFocus}
					onChange={this.handleChange}
					onFocus={this.handleFocus}
					size={size}
					value={value}
				>
					{children}
				</StyledSelect__Select>

				{icon && <StyledSelect__Icon icon={icon} size="2" />}
			</StyledSelect>
		);
	}
}

Select.StyledSelect__Arrow = StyledSelect__Arrow;
Select.StyledSelect__Select = StyledSelect__Select;

export default Select;
