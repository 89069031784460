import {
	WITHDRAWALS_CLEAR_STATE,
	WITHDRAWALS_SET_CREATING_WITHDRAWAL,
	WITHDRAWALS_SET_CREATING_BANK_ACCOUNT,
	WITHDRAWALS_SET_DATA_LOADED,
	WITHDRAWALS_SET_ERRORS,
	WITHDRAWALS_SET_RECEIPT_ID,
	WITHDRAWALS_SET_LAST_CREATED_ACCOUNT,
	WITHDRAWALS_SET_SHOULD_VERIFY,
} from './withdrawalPromptActionTypes';

const initialState = {
	hasDataLoaded: null,
	withdrawalReceiptId: null,
	createdBankAccountId: null,
	errors: null,
	creatingWithdrawal: null,
	creatingBankAccount: null,
	shouldVerifyCards: null,
};

export default function withdrawals(state = initialState, action) {
	switch (action.type) {
		case WITHDRAWALS_SET_DATA_LOADED:
			return {
				...state,
				hasDataLoaded: action.payload,
			};

		case WITHDRAWALS_SET_SHOULD_VERIFY:
			return {
				...state,
				shouldVerifyCards: action.payload,
			};

		case WITHDRAWALS_SET_RECEIPT_ID:
			return {
				...state,
				withdrawalReceiptId: action.payload,
			};

		case WITHDRAWALS_SET_LAST_CREATED_ACCOUNT:
			return {
				...state,
				createdBankAccountId: action.payload,
			};

		case WITHDRAWALS_SET_ERRORS:
			return {
				...state,
				errors: action.payload,
			};

		case WITHDRAWALS_SET_CREATING_WITHDRAWAL:
			return {
				...state,
				creatingWithdrawal: action.payload,
			};

		case WITHDRAWALS_SET_CREATING_BANK_ACCOUNT:
			return {
				...state,
				creatingBankAccount: action.payload,
			};

		case WITHDRAWALS_CLEAR_STATE:
			return {
				...initialState,
			};

		default:
			return state;
	}
}
