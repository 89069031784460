'use strict';
const moment = require('moment');

// Constants
const {
	SET_TOURNAMENTS_HOME_ACTIVE_MODAL,
	SET_TOURNAMENTS_HOME_ADVANCED_SEARCH_END_DATE,
	SET_TOURNAMENTS_HOME_ADVANCED_SEARCH_FILTER,
	SET_TOURNAMENTS_HOME_ADVANCED_SEARCH_START_DATE,
	SET_TOURNAMENTS_HOME_DAILY_RACES_COLLAPSED,
	SET_TOURNAMENTS_HOME_DECK_ITEMS,
	SET_TOURNAMENTS_HOME_IN_PLAY_COLLAPSED,
	SET_TOURNAMENTS_HOME_INITIAL_LOAD_OF_TICKETS,
	SET_TOURNAMENTS_HOME_LOADING,
	SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_FILTER,
	SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_COLLAPSED,
	SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_END_DATE,
	SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_FILTER,
	SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_IS_LOADING,
	SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_RESULTS,
	SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_START_DATE,
	SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SELECTED,
	SET_TOURNAMENTS_HOME_NAVIGATION,
	SET_TOURNAMENTS_HOME_NTP_COLLAPSED,
	SET_TOURNAMENTS_HOME_NTP_FILTER,
	SET_TOURNAMENTS_HOME_NTP_LOADING,
	SET_TOURNAMENTS_HOME_NTP_SEARCH_RESULTS,
	SET_TOURNAMENTS_HOME_NTP_SEARCH_TERM,
	SET_TOURNAMENTS_HOME_SELECTED_TOURNAMENT,
	SET_TOURNAMENTS_HOME_TRENDING_COLLAPSED,
	SET_TOURNAMENTS_HOME_TICKET_PURCHASE_ERROR_MESSAGE,
	SET_TOURNAMENTS_HOME_TICKET_PURCHASE_LOADING,
	SET_TOURNAMENTS_HOME_FILTER_AND_ORDER,
	SET_TOURNAMENTS_HOME_DATE_FILTER,
	SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_COLLAPSED,
	SET_TOURNAMENTS_HOME_DAILY_SCHEDULE_VISIBILITY,
	SET_TOURNAMENTS_HOME_ORDER_DIRECTION,
} = require('./tournamentsHomeActionTypes');

const { FORM_DATE_FORMAT } = require('../../common/constants/DateFormats.js');

/**
 * Tournament Home Constants
 */
const {
	ALL_FILTER,
	ACTIVE_FILTER,
	HIGHLIGHTS_NAVIGATION,
	DEFAULT_ORDER,
} = require('../../pages/Tournaments/TournamentsConstants');

/**
 * Initial state
 */
const initialState = {
	activeNavigation: HIGHLIGHTS_NAVIGATION,
	activeOrderField: DEFAULT_ORDER,
	orderDirection: 'ASC',
	activeFilter: ACTIVE_FILTER,
	activeModal: null,
	advancedSearchFilter: null,
	advancedSearchEndDate: moment()
		.add(7, 'day')
		.format(FORM_DATE_FORMAT),
	advancedSearchStartDate: moment().format(FORM_DATE_FORMAT),
	dailyScheduleVisible: true,
	dateFilter: null,
	dailyRacesCollapsed: false,
	deckItems: [],
	selectedTournament: null,
	inPlayCollapsed: false,
	initialLoadOfTickets: false,
	isLoading: false,
	isMyTournamentsSearchLoading: false,
	isNTPLoading: false,
	myTournamentsFilter: ALL_FILTER,
	myTournamentsSearchCollapsed: false,
	myTournamentsSearchEndDate: moment().format(FORM_DATE_FORMAT),
	myTournamentsSearchFilter: null,
	myTournamentsSearchResults: [],
	myTournamentsSearchStartDate: moment()
		.subtract(7, 'day')
		.format(FORM_DATE_FORMAT),
	myTournamentsSelectedTournament: null,
	nextToPlayCollapsed: false,
	nextToPlaySearchResults: [],
	nextToPlaySearchTerm: '',
	nextToPlayTimeFilter: 1,
	ticketPurchaseErrorMessage: null,
	ticketPurchaseIsLoading: false,
	trendingCollapsed: false,
	myTournamentsCollapsed: false,
};

/**
 * Tournaments home reducer
 *
 * @param state
 * @param action
 * @returns {*}
 */
const tournamentsHomeReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_TOURNAMENTS_HOME_ORDER_DIRECTION:
			return {
				...state,
				orderDirection: action.payload,
			};

		case SET_TOURNAMENTS_HOME_ACTIVE_MODAL:
			return {
				...state,
				activeModal: action.payload,
			};

		case SET_TOURNAMENTS_HOME_ADVANCED_SEARCH_END_DATE:
			return {
				...state,
				advancedSearchEndDate: action.payload,
			};

		case SET_TOURNAMENTS_HOME_ADVANCED_SEARCH_FILTER:
			return {
				...state,
				advancedSearchFilter: action.payload,
			};

		case SET_TOURNAMENTS_HOME_ADVANCED_SEARCH_START_DATE:
			return {
				...state,
				advancedSearchStartDate: action.payload,
			};

		case SET_TOURNAMENTS_HOME_DAILY_RACES_COLLAPSED:
			return {
				...state,
				dailyRacesCollapsed: action.payload,
			};

		case SET_TOURNAMENTS_HOME_DECK_ITEMS:
			return {
				...state,
				deckItems: action.payload,
			};

		case SET_TOURNAMENTS_HOME_IN_PLAY_COLLAPSED:
			return {
				...state,
				inPlayCollapsed: action.payload || initialState.inPlayCollapsed,
			};

		case SET_TOURNAMENTS_HOME_INITIAL_LOAD_OF_TICKETS:
			return {
				...state,
				initialLoadOfTickets: action.payload || initialState.initialLoadOfTickets,
			};

		case SET_TOURNAMENTS_HOME_LOADING:
			return {
				...state,
				isLoading: action.loading || initialState.isLoading,
			};

		case SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_FILTER:
			return {
				...state,
				myTournamentsFilter: action.payload,
			};

		case SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_COLLAPSED:
			return {
				...state,
				myTournamentsSearchCollapsed: action.payload,
			};

		case SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_END_DATE:
			return {
				...state,
				myTournamentsSearchEndDate: action.payload,
			};

		case SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_FILTER:
			return {
				...state,
				myTournamentsSearchFilter: action.payload,
			};

		case SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_IS_LOADING:
			return {
				...state,
				isMyTournamentsSearchLoading: action.payload,
			};

		case SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_RESULTS:
			return {
				...state,
				myTournamentsSearchResults: action.payload,
			};

		case SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_START_DATE:
			return {
				...state,
				myTournamentsSearchStartDate: action.payload,
			};

		case SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SELECTED:
			return {
				...state,
				myTournamentsSelectedTournament: action.payload,
			};

		case SET_TOURNAMENTS_HOME_NAVIGATION:
			return {
				...state,
				activeNavigation: action.payload,
			};

		case SET_TOURNAMENTS_HOME_NTP_COLLAPSED:
			return {
				...state,
				nextToPlayCollapsed: action.payload || initialState.nextToPlayCollapsed,
			};

		case SET_TOURNAMENTS_HOME_NTP_FILTER:
			return {
				...state,
				nextToPlayTimeFilter: action.payload || initialState.nextToPlayTimeFilter,
			};

		case SET_TOURNAMENTS_HOME_NTP_LOADING:
			return {
				...state,
				isNTPLoading: action.payload || initialState.isNTPLoading,
			};

		case SET_TOURNAMENTS_HOME_NTP_SEARCH_RESULTS:
			return {
				...state,
				nextToPlaySearchResults: action.results || initialState.nextToPlaySearchResults,
			};

		case SET_TOURNAMENTS_HOME_DAILY_SCHEDULE_VISIBILITY:
			return {
				...state,
				dailyScheduleVisible: action.payload,
			};

		case SET_TOURNAMENTS_HOME_NTP_SEARCH_TERM:
			return {
				...state,
				nextToPlaySearchTerm: action.searchTerm || initialState.nextToPlaySearchTerm,
			};

		case SET_TOURNAMENTS_HOME_SELECTED_TOURNAMENT:
			return {
				...state,
				selectedTournament: action.payload,
			};

		case SET_TOURNAMENTS_HOME_TICKET_PURCHASE_ERROR_MESSAGE:
			return {
				...state,
				ticketPurchaseErrorMessage: action.payload,
			};

		case SET_TOURNAMENTS_HOME_TICKET_PURCHASE_LOADING:
			return {
				...state,
				ticketPurchaseIsLoading: action.payload,
			};

		case SET_TOURNAMENTS_HOME_TRENDING_COLLAPSED:
			return {
				...state,
				trendingCollapsed: action.payload || initialState.trendingCollapsed,
			};

		case SET_TOURNAMENTS_HOME_DATE_FILTER:
			return {
				...state,
				dateFilter: action.payload,
			};

		case SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_COLLAPSED:
			return {
				...state,
				myTournamentsCollapsed: action.payload,
			};

		case SET_TOURNAMENTS_HOME_FILTER_AND_ORDER:
			return {
				...state,
				activeFilter: action.payload.filter || state.activeFilter,
				activeOrderField: action.payload.orderField || state.activeOrderField,
				// Check for undefined, because setting it to null is a valid value
				// TODO: Check if the key exists rather than undefined
				dateFilter: action.payload.dateFilter === null ? null : state.dateFilter,
			};
	}

	return state;
};

/**
 * Exports
 */
module.exports = tournamentsHomeReducer;
