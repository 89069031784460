import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, ui } from '@tbh/ui-kit';

// Components
import { Text, CollapsibleContent, CollapsibleIcon, HeaderBar } from '@tbh/ui-kit';
import BetSelectionAndAmount from '../BetSelection/BetSelectionAndAmount/BetSelectionAndAmount';
import { SRMItem } from './MultiSRMItem';
import { RACING_SAME_RACE_MULTI_TYPE } from '../../../../common/constants/Racing';
// import BetSelectionAndAmount from '../BetSelection/BetSelectionAndAmount/BetSelectionAndAmount';

const StyledMultiSRMList__CloseIcon = styled(CollapsibleIcon)(
	(props) => css`
		label: MultiSRMList__CloseIcon;

		min-width: ${spacings(props).comfortable}px;
	`,
);
const StyledMultiSRMList = styled('div')(
	(props) => css`
		label: MultiSRMList;

		padding: 4px;
		display: flex;
		flex-direction: column;
		gap: ${spacings(props).compact}px;
	`,
);

const StyledMultiSRMItem = styled(BetSelectionAndAmount)(
	(props) => css`
		label: MultiSRMItem;

		border: 2px solid ${ui(props).color_4};
		border-radius: 4px;
	`,
);

const MultiSRMList = (props) => {
	const { className, disabled, bet, SRMs, onStakeChange, onRemove, t, track,    handleSelectedCupomBet, 	bonus_bet_enabled,
		handleBonusBetChange,
		selectedBet,
		handleSelectedBet,
		races,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	const handleSRMItemToggle = () => {
		setIsCollapsed(!isCollapsed);
	};

	const onMultiToggle = (srm, isMulti) => {};

	const [isCollapsed, setIsCollapsed] = React.useState(false);




	return (
		<div className={componentClasses}>
			<HeaderBar
				type="primary"
				action={handleSRMItemToggle}
				className={css`
					cursor: pointer;
				`}
				aside={<StyledMultiSRMList__CloseIcon size="-3" isCollapsed={isCollapsed}  />}
			>
				<Text type="alternate" strong>
					Same Race Multi Bets
				</Text>
			</HeaderBar>
			<CollapsibleContent isCollapsed={isCollapsed}>
				<StyledMultiSRMList>
					{SRMs.map((srm, i) => (
						<SRMItem
							key={i}
							isClosed={races.find((r) => r.id == srm.race_id && r.status != 'selling')}
							srm={srm}
							{...srm}
							bonus_bet_enabled={bonus_bet_enabled}
							handleBonusBetChange={() => handleBonusBetChange(srm.id,RACING_SAME_RACE_MULTI_TYPE)}
							racingBetType={'sameracemulti'}
							isSRM={true}
							disabled={false}
							price={srm.price}
							onRemove={onRemove}
							onStakeChange={onStakeChange}
							onMultiToggle={onMultiToggle}
							track={track}
							bet={bet}
							handleSelectedCupomBet={    handleSelectedCupomBet}
							selectedBet={selectedBet}
							handleSelectedBet={handleSelectedBet}
							// cash_out_enabled={cash_out_enabled}
						/>
					))}
				</StyledMultiSRMList>
			</CollapsibleContent>
		</div>
	);
};

MultiSRMList.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Action to update the stake against the leg */
	onStakeChange: PropTypes.func.isRequired,

	/** Action to toggle the leg item open and closed */
	onLegToggle: PropTypes.func.isRequired,

	/** If the leg item is disabled or not */
	disabled: PropTypes.bool,

	/** If the leg item is open or closed */
	isCollapsed: PropTypes.bool,

	/** Extra classes */
	className: PropTypes.string,
};
MultiSRMList.defaultProps = {
	className: '',
	disabled: false,
	isCollapsed: false,
	defaultBetAmount: 0,
};

export default withNamespaces()(MultiSRMList);
