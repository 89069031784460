import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, mediaMax, media } from '@tbh/ui-kit';

import ReactTooltip from 'react-tooltip';

// Components
import { Button, Select, SelectOption, Notification, Text, InputGroup, Input } from '@tbh/ui-kit';

// Functions/Constants
import { errorString } from '../../../../legacy/core/format';
import {LoadingSpanner} from '../../Application/LoadingSpinner/LoadingSpinner';

const StyledUpdatePassword__Notification = styled(Notification)`
	label: UpdatePassword__Notification;

	${(props) =>
  css`
			margin: 0 0 ${spacings(props).cozy}px;
		`};
`;

const StyledProgressbar_section = styled('div')(
  (props) => css`
		label: Progressbar_section ;
		width: 100%;
		// margin-left:-81px;
	`,
  mediaMax(
    css`
			display: none;
		`,
    620,
  ),
  media(
    css`
			display: block;
					margin-left:-81px;
		`,
    1024,
  ),
);

class UploadGreenIdFile extends React.Component {
  static propTypes = {
    /** Translation func provided by withNamespaces HOC */
    t: PropTypes.func.isRequired,

    greenidValid:PropTypes.func.isRequired,

    /** Extra classes */
    className: PropTypes.string,

    first_name:PropTypes.string.isRequired,

    last_name:PropTypes.string.isRequired,

    street:PropTypes.string.isRequired,

    msisdn:PropTypes.string.isRequired,

    green_id_status:PropTypes.string.isRequired,

    value:PropTypes.string.isRequired,

    licncnumber:PropTypes.string.isRequired,

  };

  static defaultProps = {
    className: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      licncnumber: '',
      document_type:'',
      errors: false,
      showSuccess: false,
    };
  }

  /**
   * Changes to input fields and their validation statuses
   * @param name
   * @param value
   * @param valid
   */
  handleChange = (name, value, valid) => {
    this.setState({
      [name]: value,
      [`${name}_valid`]: valid,
    });
  };

  /**
   * Handle update password submission and set new state
   */
  handleSubmit = (event) => {
    event.preventDefault();
    let newState = {};
    this.props
      .greenidValid(this.state.licncnumber, this.state.document_type)
      .then((response) => {
        let error = response.data.error;

        if (response.data.success || (response.status === 200 && !error)) {
          newState = {
            errors: false,
            showSuccess: true,

            /** Reset Form data */
            licncnumber: '',
            document_type:'',
          };
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        newState.errors = error
          ? error.message
            ? error.message
            : error
          : this.props.t('UpdatePassword__GenericError');
      })
      .finally(() => {
        this.setState({
          ...newState,
          loadingMask: false,
        });
      });
    };
  render() {
    const { t, className, first_name, last_name, street, msisdn, green_id_status, value, licncnumber, user_green_id, send_email, email} = this.props;
    const {
      loadingMask,
      errors, showSuccess
    } = this.state;
    const componentClasses = cx({
      [className]: className,
    });
    return (
	<div className={css`padding: 10px;`}>
		<LoadingSpanner style={{display: loadingMask ? 'block' : 'none'}} />
		{errors && (
		<StyledUpdatePassword__Notification
        type={Notification.types.COLOUR_DANGER}
        message={errorString(errors)}
        buttonText={'Dismiss'}
        buttonAction={this.clearErrorMessage}
      />
    )}

		{!errors &&
    showSuccess && (
      <StyledUpdatePassword__Notification
        type={Notification.types.COLOUR_SUCCESS}
        message={t('UpdatePassword__PasswordUpdate')}
      />
    )}
		<br/>
		<Text size="1" strong>
			{'Verification'}
		</Text>
		<br/>
    {/*<div className="progress">*/}
    {/*  <div className="progress-bar bg-success" data-tip={'25%'} data-for="MobileSucess" role="progressbar" style={{width: '20%'}} aria-valuenow="15"*/}
    {/*       aria-valuemin="0" aria-valuemax="100"/>*/}
    {/*  {send_email != null ? <div className="progress-bar text-danger" data-tip={'50%'} data-for="EmailSucess" role="progressbar" style={{width: '40%'}}*/}
    {/*                             aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"/> : <div className="progress-bar bg-success" data-tip={'75%'} data-for="EmailSucess" role="progressbar" style={{width: '0%'}} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"/>}*/}
    {/*  {green_id_status === 'PENDING' ? <div className="progress-bar text-danger" role="progressbar" style={{width: '40%'}} aria-valuenow="40"*/}
    {/*                                        aria-valuemin="0" aria-valuemax="100"/> : green_id_status === 'IN_PROGRESS' ? <div className="progress-bar bg-success" role="progressbar" style={{width: '0%'}} aria-valuenow="20"*/}
    {/*                                                                                                                           aria-valuemin="0" aria-valuemax="100"/> : <div className="progress-bar bg-success" data-tip={'100%'} data-for="GreenSucess" role="progressbar" style={{width: '40%'}} aria-valuenow="20"*/}
    {/*                                                                                                                                                                         aria-valuemin="0" aria-valuemax="100"/>}*/}
    {/*</div>*/}

    <StyledProgressbar_section>

      {/*<FlexText>*/}
      {/*  <Text size="-1" className={css`color:#fff; text-align: center !important; margin-bottom: 10px;`}>*/}
      {/*    {green_id_status !== 'VERIFIED' ? 'A' : null}*/}
      {/*  </Text>*/}
      {/*</FlexText>*/}
      {/* progressbar section */}
      <ul className="progressbar_user_account">
        <li className={green_id_status === null  ? null: green_id_status !== 'VERIFIED'  ? 'active1' : 'active2'} >
          {green_id_status !== 'VERIFIED' ? <div className={css`color:red`}>Name</div> : 'Name'}
        </li>
        <li className={green_id_status === null  ? null: green_id_status !== 'VERIFIED'  ? 'active1' : 'active2'}>
          {green_id_status !== 'VERIFIED' ? <div className={css`color:red`}>Address</div> : 'Address'}
        </li>
        <li className={msisdn ? 'active2': 'active1'}>
          { !msisdn ?  <div className={css`color:red`}>Phone Number</div> : 'Phone Number' }
        </li>
        <li className={send_email !== null ? 'active2': 'active1'}>
          {send_email !== 'active1' ? 'Email' : <div className={css`color:red`}>Email</div>}
        </li>
        <li className={green_id_status === 'VERIFIED' ? 'active2': 'active1'}>
          {green_id_status !== 'VERIFIED' ? <div className={css`color:red`}>GreenId</div> : 'GreenId'}
        </li>
      </ul>
    </StyledProgressbar_section>


    <ReactTooltip id="MobileSucess" type="success" place="bottom" textColor="black" />
    <ReactTooltip id="EmailSucess" type="success" place="bottom" textColor="black" />
    <ReactTooltip id="GreenSucess" type="success" place="bottom" textColor="black" />
    <br/>
		<InputGroup>
			<label className={css`font-size:13px;`}>{'Customer Name'}</label>
		</InputGroup>
		<InputGroup>
			<Text className={css`font-size:15px;`}>{first_name + ' ' + last_name}</Text>
			{green_id_status == null ? <span className={css`padding-left:10px;color: #FF0000;`}><i className="fa fa-times-circle-o"
                                                                                        aria-hidden="true"/></span> : green_id_status === 'IN_PROGRES' ? <span className={css`padding-left:10px;color: #FF0000;`}><i className="fa fa-times-circle-o" aria-hidden="true"/></span> : <span className={css`padding-left:10px;color: green;`}><i className="fa fa-check-circle-o" aria-hidden="true"/></span>}
		</InputGroup>
		<br/>
		<InputGroup>
			<label className={css`font-size:13px;`}>{'Customer Address'}</label>
		</InputGroup>
		<InputGroup>
			<Text className={css`font-size:15px;`}>{street}</Text>
			{green_id_status == null ? <span className={css`padding-left:10px;color: #FF0000;`}><i className="fa fa-times-circle-o"
                                                                                             aria-hidden="true"/></span> : green_id_status === 'IN_PROGRES' ? <span className={css`padding-left:10px;color: #FF0000;`}><i className="fa fa-times-circle-o" aria-hidden="true"/></span> : <span className={css`padding-left:10px;color: green;`}><i className="fa fa-check-circle-o" aria-hidden="true"/></span>}
		</InputGroup>
		<br/>
		<InputGroup>
			<label className={css`font-size:13px;`}>{'Customer Phone Number'}</label>
		</InputGroup>
		<InputGroup>
			<Text className={css`font-size:15px;`}>{msisdn}</Text>
			<span className={css`padding-left:10px;color: green;`}><i className="fa fa-check-circle-o" aria-hidden="true"/></span>
		</InputGroup>
		<br/>
		<InputGroup>
			<label className={css`font-size:13px;`}>{'Customer Email'}</label>
		</InputGroup>
		<InputGroup>
			<Text className={css`font-size:15px;`}>{email}</Text>
			{send_email == null ? <span className={css`padding-left:10px;color: #FF0000;`}><i className="fa fa-times-circle-o"
  aria-hidden="true"/></span> : <span className={css`padding-left:10px;color: green;`}><i className="fa fa-check-circle-o" aria-hidden="true"/></span>}
		</InputGroup>
		<br/>
		<InputGroup>
			<label className={css`font-size:13px;`}>{'Verification Status'}</label>
		</InputGroup>
		<InputGroup>
			<Text className={css`font-size:15px;`}>{green_id_status}</Text>
		</InputGroup>
		<br/>
		<Text paragraph strong>
			{'Electronic Verification'}
		</Text>
		<Select name="document_type" label={'Select Type'}  onChange={this.handleChange} value={value}  margin="cozy">
			<SelectOption value="">Select Document Type</SelectOption>
			<SelectOption value="actregodvs">DVS ACT Drivers Licence</SelectOption>
			<SelectOption value="ntregodvs">DVS NT drivers licence</SelectOption>
			<SelectOption value="nswregodvs">DVS NSW drivers licence</SelectOption>
			<SelectOption value="qldregodvs">DVS Queensland drivers licence</SelectOption>
			<SelectOption value="saregodvs">DVS SA drivers licence</SelectOption>
			<SelectOption value="waregodvs">DVS WA drivers licence</SelectOption>
			<SelectOption value="vicregodvs">DVS Victorian drivers licence</SelectOption>
			<SelectOption value="passportdvs">DVS Australian Passport</SelectOption>
		</Select>
		<Input
      value={licncnumber}
      onChange={this.handleChange}
      name="licncnumber"
      type="number"
      label={'Please Enter Number'}
      margin="cozy"
      horizontalMargin="cozy"
    />
		{green_id_status === 'IN_PROGRES' ? <Button block type="primary" action={this.handleSubmit}>{'Submit'}</Button> : <Button block type="primary" disabled={true}  action={this.handleSubmit}>{'Submit'}</Button>}
	</div>
    );
  }
}

export default withNamespaces()(UploadGreenIdFile);
