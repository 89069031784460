// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';

// Components
import { Text, Panel } from '@tbh/ui-kit';
import CountdownAlert from '../CountdownAlert/CountdownAlert';

const CountdownPanel = (props) => {
	const { className, t } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<Panel className={componentClasses} borderType="default" flex padding={Panel.padding.SPACING_COZY}>
			<Text size="-1" strong>
				{t('CountdownPanel__Message')}
			</Text>
			<CountdownAlert startTime={300000} alertTime={120000} />
		</Panel>
	);
};

CountdownPanel.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

CountdownPanel.defaultProps = {
	className: '',
};

export default withNamespaces()(CountdownPanel);
