import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MODAL_COMPONENT_VIEWS from './ModalContainerViews';
import { closeModal, closeAllModals } from '../../../store/modal/modalActions';

import Modal from '../../../components/controllers/Modal/Modal';

class ModalContainer extends Component {
	static propTypes = {
		/** Modal slice in state */
		modal: PropTypes.shape({
			/** List of modals to display */
			modals: PropTypes.arrayOf(
				PropTypes.shape({
					/** Unique modal key */
					id: PropTypes.string.isRequired,

					/** Component name to render in the modal - NOTE: must exist in MODAL_COMPONENT_VIEWS list */
					Component: PropTypes.string.isRequired,

					/** Props for the Modal component */
					config: PropTypes.object,

					/** Props for the component to render in the modal */
					props: PropTypes.object,
				}),
			).isRequired,
		}).isRequired,

		/** Action to close a specific modal */
		closeModal: PropTypes.func.isRequired,

		/** Action to close all modals */
		closeAllModals: PropTypes.func.isRequired,
	};

	componentDidMount() {
		this.props.closeAllModals();
	}

	componentWillUnmount() {
		this.props.closeAllModals();
	}

	render() {
		const { modal, closeModal } = this.props;

		return modal.modals.map((componentModal) => {
			const { id, Component, config, props } = componentModal;

			// Modals require both an ID and the Component name, which can be different, but usually aren't
			if (!id || !Component) {
				return null;
			}

			// Grab the correct component to display from the list
			const ModalComponent = MODAL_COMPONENT_VIEWS[Component];
			if (!ModalComponent) {
				throw new Error(`You must add the component '${Component}' to the MODAL_COMPONENT_VIEWS list.`);
			}

			return (
				<Modal key={id} componentKey={id} onClose={closeModal} config={config} open>
					<ModalComponent {...props} />
				</Modal>
			);
		});
	}
}

const mapStateToProps = (state) => {
	return {
		modal: state.modal,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeModal: (modalId) => dispatch(closeModal(modalId)),
		closeAllModals: () => dispatch(closeAllModals()),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ModalContainer);
