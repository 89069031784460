/**
 * Created by Josef Frank on 2017/04/03.
 */
// Libraries
import {denormalize, normalize,} from 'normalizr';

import {baseCompetitionsSchema,} from './Entities';

/**
 * Denormalizes base competititons.
 *
 * @param entities
 * @param keys
 * @returns {Array}
 */
const denormalizeBaseCompetitions = (entities, keys = null) => {
	return denormalize(keys || Object.keys(entities.base_competitions), baseCompetitionsSchema, entities);
};

/**
 * Normalizes base competitions.
 *
 * @param response
 * @returns {Object}
 */
const normalizeBaseCompetitions = (response) => {
	return normalize(response, baseCompetitionsSchema);
};

export {
	denormalizeBaseCompetitions,
	normalizeBaseCompetitions,
};