import {
	REGISTRATION_BASE_URL,
	REGISTRATION_SUB_APP,
} from '../pages/Authentication/Registration/RegistrationPageConstants';
import { setRegistrationStep } from '../store/registration/registrationActions';
import { scrollToTop } from '../store/application/applicationActions';
import { getRule } from '../store/legalRules/legalRulesAction';

const { dispatch } = App.store;

const RegistrationRouter = Backbone.Router.extend({
	routes: {
		[REGISTRATION_BASE_URL + '/:step']: 'showRegistration',
	},

	showRegistration: async function(step) {
		scrollToTop();

		let steps = [
			'step-1',
			'step-2',
			'step-3',
			'step-4',
			'step-5',
			'step-6',
			'registration-confirmation',
			'set-limits',
			'first-deposit',
		];

		const currentStep = App.store.getState().registration.step;

		if (steps.indexOf(step) > -1) {
			// Check if the current is one step in front of the requested step
			// If so, we should not allow the user to navigate to the requested step
			// That is only valid for steps 6 and beyond
			if (steps.indexOf(step) > 5 && steps.indexOf(step) - steps.indexOf(currentStep) > 1) {
				dispatch(setRegistrationStep(currentStep));
				return;
			}

			dispatch(setRegistrationStep(step));
			await App.store.dispatch(getRule('terms-and-conditions'));

			App.startSubApp(REGISTRATION_SUB_APP);
			// dispatch(navigateToStep('registration-confirmation'));
		}
	},
});

module.exports = new RegistrationRouter();
