import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { spacings, Image } from '@tbh/ui-kit';


import { RACING_EXOTIC_BET_TYPES } from '../../../../common/constants/Racing';

const StyledRaceProductLegend__ProductImage = styled(Image)`
	${(props) => css`
		label: RaceProductLegend__ProductImage;

		max-height: 16px;
		margin-top: ${spacings(props).constrictive}px;
		margin-bottom: ${spacings(props).constrictive}px;
		vertical-align: middle;
		display: inline-block;
	`};
`;

const RaceProductLegend = (props) => {
	const { t, className, products, displayedProducts, hideExotics } = props;

	const componentClasses = cx({ [className]: className });

	/**
	 * If displayedProducts is defined then filter the products based on these id's
	 */
	const filteredProducts =
		displayedProducts && displayedProducts.length
			? products.filter((product) => displayedProducts.includes(product.product_id))
			: products;

	/**
	 * Create an array of the different exotic types
	 *
	 * @type {[string,string,string,string]}
	 */
	const exoticTypes = RACING_EXOTIC_BET_TYPES;

	/**
	 * Separate the exotic products from the others and remove duplicates from the remaining products
	 * Also check for any product notes while we are here and add them to the accumulated string
	 */
	let pleaseNoteDescription = '';
	let exoticProducts = [];

	const uniqueProducts = filteredProducts.reduce((accu, nextItem) => {
		// separate the exotic bet types from others
		if (exoticTypes.indexOf(nextItem.bet_type) !== -1) {
			exoticProducts.push(nextItem);
		} else if (!accu.find((item) => item.product_code === nextItem.product_code)) {
			//Check for product notes and build 'Please Note' string
			if (nextItem.product_note) {
				pleaseNoteDescription += nextItem.product_note + ' ';
			}

			accu.push(nextItem);
		}

		// return the unique non-exotic products
		return accu;
	}, []);

	/**
	 * Remove the duplicate exotic products
	 *
	 * @type {*}
	 */
	const uniqueExoticProducts = exoticProducts.reduce((accu, nextItem) => {
		if (!accu.find((item) => item.product_code === nextItem.product_code)) {
			accu.push(nextItem);
		}
		return accu;
	}, []);

	return (
		<div></div>
/*		<div className={componentClasses}>
			<Text paragraph>
				{uniqueProducts.map((product) => (
					<Text key={`${product.product_id}_${product.product_code}`} size="-4">
						{product.product_image ? (
							<StyledRaceProductLegend__ProductImage src={product.product_image} alt={product.product_description} />
						) : (
							product.product_code
						)}{' '}
						= {product.product_description}
					</Text>
				))}
				{!hideExotics &&
					uniqueExoticProducts.map((product) => (
						<Text key={product.product_id} size="-4">
							{uniqueExoticProducts.length === 1 ? t('RaceProductLegend__AllExotics') : product.product_code} ={' '}
							{product.product_description}
						</Text>
					))}
			</Text>
			{pleaseNoteDescription ? (
				<Text paragraph size="-4" italic>
					<Text size="-4" tag="span" transform="uppercase">
						{t('RaceProductLegend__PleaseNote')}
					</Text>
					: {pleaseNoteDescription}
				</Text>
			) : null}
		</div>*/
	);
};

RaceProductLegend.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The list of products/totes in the race */
	products: PropTypes.arrayOf(
		PropTypes.shape({
			/** The ID of the product */
			product_id: PropTypes.number.isRequired,

			/** The bet type that the product applies to */
			bet_type: PropTypes.string.isRequired,

			/** The product code */
			product_code: PropTypes.string.isRequired,

			/** The product description */
			product_description: PropTypes.string,

			/** The product name */
			product_name: PropTypes.string,

			/** Any extra notes for the product */
			product_note: PropTypes.string,

			/** This will be either the product image if it exists or the product code */
			product_image: PropTypes.string,
		}),
	).isRequired,

	/** An array of product id's that should be displayed - If empty then display all products */
	displayedProducts: PropTypes.arrayOf(PropTypes.number),

	/** Should the exotic products be displayed */
	hideExotics: PropTypes.bool,

	/** Extra classes */
	className: PropTypes.string,
};

RaceProductLegend.defaultProps = {
	products: [
		{
			product_name: null,
			product_note: null,
			product_image: null,
			product_description: null,
		},
	],
	displayedProducts: [],
	hideExotics: false,
	className: '',
};

export default withNamespaces()(RaceProductLegend);
