import {SPORT_NEXJUMP_BASE_URL} from '../pages/SportNextJump/SportNextJumpConstants';


/**
 * Builds route to a sport next jump page.
 * this is simple
 * @returns {String}
 */
export const buildRouteToSportNextJump = () => {
    return `/${SPORT_NEXJUMP_BASE_URL}`;
};