import { denormalize, normalize } from 'normalizr';
import { betsSchema } from './Entities';

/**
 * Normalize an array of bets
 *
 * @param bets
 */
export const normalizeBets = (bets = []) => {
	return normalize(bets, betsSchema);
};

/**
 * Denormalize Bets
 *
 * @param entities
 * @param keys
 */
export const denormalizeBets = (entities, keys) => {
	return denormalize(keys || Object.keys(entities.bets), betsSchema, entities);
};
