import { NEXT_TO_JUMP_APP, NEXT_TO_JUMP_URL } from '../pages/NextToJump/NextToJumpConstrants';
import { setBodyLoading, scrollToTop, show404 } from '../store/application/applicationActions';
import { fetchNextToJumpRaces_GRS } from '../store/entities/actions/NextToJumpRaceActions';

const { dispatch, getState } = App.store;

const NextToJump = Backbone.Router.extend({
	routes: {
		[NEXT_TO_JUMP_URL]: 'showN2J',
	},
	showN2J: async function() {
		App.startSubApp(NEXT_TO_JUMP_APP);
		try {
			scrollToTop();

			await Promise.all([dispatch(fetchNextToJumpRaces_GRS())]);
		} catch (e) {
			dispatch(show404());
		}
	},
});

module.exports = new NextToJump();
