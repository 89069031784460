// Constants needed for translations
import {
	SELECTION_PRICE_NOT_APPLICABLE,
	DEFAULT_BET_AMOUNT_PLACEHOLDER_TEXT,
} from '../../store/entities/constants/BetConstants';
import {
	DAILY_TOURNAMENTS_LABEL,
	HIGHLIGHTS_LABEL,
	MY_TOURNAMENTS_LABEL,
	ACTIVE_LABEL,
	CLOSED_LABEL,
	RACING_LABEL,
	SPORTS_LABEL,
	PRIZE_POOL_LABEL,
	REBUYS_LABEL,
	BUY_IN_LABEL,
} from '../../pages/Tournaments/TournamentsConstants';
import {
	USER_ACCOUNT_SUBMENU_USER_HOME,
	USER_ACCOUNT_SUBMENU_DEPOSIT,
	USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS,
	USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS,
	USER_ACCOUNT_SUBMENU_WITHDRAW_FUNDS,
	USER_ACCOUNT_SUBMENU_WITHDRAWALS,
	USER_ACCOUNT_SUBMENU_RESET_PASSWORD,
	USER_ACCOUNT_SUBMENU_DEFAULT_BET_AMOUNTS,
	USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING,
	USER_ACCOUNT_SUBMENU_TAKE_A_BREAK,
	USER_ACCOUNT_SUBMENU_SELF_EXCLUSION,
	USER_ACCOUNT_SUBMENU_DEPOSIT_LIMIT,
	USER_ACCOUNT_SUBMENU_LOSS_LIMITS,
	USER_ACCOUNT_SUBMENU_SPEND_LIMIT,
	USER_ACCOUNT_SUBMENU_REALITY_CHECK,
	USER_ACCOUNT_VERIFY_CREDIT_CARDS_URL,
	USER_ACCOUNT_SUBMENU_GREEN_ID_FORM_UPLOAD,
	USER_ACCOUNT_SUBMENU_RNS_ACCOUNT,
} from '../../pages/UserAccount/UserAccountPageConstants';
import { GENERIC_ERROR_MESSAGE, GENERIC_LOGIN_MESSAGE } from '../../common/constants/Notifications';
import {
	CURRENT,
	YESTERDAY,
	LAST_7_DAYS,
	LAST_MONTH,
	CUSTOM,
	FILTER_RESULTS,
} from '../../common/constants/Tournaments';
import { VERIFIED, NOT_VERIFIED } from '../../containers/Deposit/DepositConstants';
import {
	RACING_EACHWAY_LABEL,
	RACING_BUMPED_LABEL,
	EXOTIC_BOXED,
	EXOTIC_STANDARD,
	EXOTIC_STANDOUT,
} from '../../common/constants/Racing';

// Translation strings
export default {
	'^Adults Only': '^Adults Only',
	'^Invalid Credit Card Number': '^Invalid Debit Card Number',
	'^Invalid CVV': '^Invalid CVV',
	'^Invalid expiry date': '^Invalid expiry date',
	'Details used on this account are already in use by a previous user. Please contact Support on 1300 886 503 to resolve.':
		'Details used on this account are already in use by a previous user. Please contact Support on 1300 886 503 to resolve.',
	'Email is not a valid email': 'Email is not a valid email',
	'is not a valid email': 'is not a valid email',
	'is not valid': 'is not valid',
	'must be an integer': 'must be an integer',
	'must be at least 6 characters': 'must be at least 6 characters',
	'must have at least 1 number and 1 letter': 'must have at least 1 number and 1 letter',
	'Password must contain at least one number and one letter':
		'Password must contain at least one number and one letter',
	'The city field is required.': 'The city field is required.',
	'The country field is required.': 'The country field is required.',
	'The dob day field is required.': 'The dob day field is required.',
	'The dob month field is required.': 'The dob month field is required.',
	'The dob year field is required.': 'The dob year field is required.',
	'The email format is invalid.': 'The email format is invalid.',
	'The first name field is required.': 'The first name field is required.',
	'The first name must be at least 2 characters.': 'The first name must be at least 2 characters.',
	'The last name field is required.': 'The last name field is required.',
	'The last name must be at least 2 characters.': 'The last name must be at least 2 characters.',
	'The marketing opt in flag field is required.': 'The marketing opt in flag field is required.',
	'The password field is required.': 'The password field is required.',
	'The password must be at least 6 characters.': 'The password must be at least 6 characters.',
	'The postcode field is required.': 'The postcode field is required.',
	'The source field is required.': 'The source field is required.',
	'The state field is required.': 'The state field is required.',
	'The street field is required.': 'The street field is required.',
	'The title field is required.': 'The title field is required.',
	'The username field is required.': 'The username field is required.',
	'The username must be at least 6 characters.': 'The username must be at least 6 characters.',
	// The 'TopBetta' here comes from a API response and probably should be changed
	'You must be 18 or older to sign up to TopBetta': 'You must be 18 or older to sign up to this site',
	"can't be blank": "can't be blank",
	'1st Prize': '1st Prize',
	'1st Up': '1st Up',
	'2nd Up': '2nd Up',
	'Available until': 'Available until',
	'e/w': 'e/w',
	'First 4': 'First 4',
	'First Four': 'First Four',
	"Str8 '8'": "Str8 '8'",
	'FREE Tournament': 'FREE Tournament',
	'n/a': 'n/a',
	'NO RETURN': 'NO RETURN',
	'No.': 'No.',
	'Non Wet': 'Non Wet',
	'Not enough selections!': 'Not enough selections!',
	'out of': 'out of',
	'Play with': 'Play with',
	'Say something': 'Say something',
	'Terms&Conditions': 'Terms & Conditions',
	'To Beat the Field': 'To Beat the Field',
	'To Beat': 'To Beat',
	'Type something...': 'Type something...',
	'v/s': 'v/s',
	'Win/Place': 'Win/Place',
	[ACTIVE_LABEL]: ACTIVE_LABEL,
	[BUY_IN_LABEL]: BUY_IN_LABEL,
	[CLOSED_LABEL]: CLOSED_LABEL,
	[CURRENT]: CURRENT,
	[CUSTOM]: CUSTOM,
	[DAILY_TOURNAMENTS_LABEL]: DAILY_TOURNAMENTS_LABEL,
	[DEFAULT_BET_AMOUNT_PLACEHOLDER_TEXT]: DEFAULT_BET_AMOUNT_PLACEHOLDER_TEXT,
	[EXOTIC_BOXED]: EXOTIC_BOXED,
	[EXOTIC_STANDARD]: EXOTIC_STANDARD,
	[EXOTIC_STANDOUT]: EXOTIC_STANDOUT,
	[FILTER_RESULTS]: FILTER_RESULTS,
	[GENERIC_ERROR_MESSAGE]: GENERIC_ERROR_MESSAGE,
	[GENERIC_LOGIN_MESSAGE]: GENERIC_LOGIN_MESSAGE,
	[HIGHLIGHTS_LABEL]: HIGHLIGHTS_LABEL,
	[LAST_7_DAYS]: LAST_7_DAYS,
	[LAST_MONTH]: LAST_MONTH,
	[MY_TOURNAMENTS_LABEL]: MY_TOURNAMENTS_LABEL,
	[NOT_VERIFIED]: NOT_VERIFIED,
	[PRIZE_POOL_LABEL]: PRIZE_POOL_LABEL,
	[RACING_BUMPED_LABEL]: RACING_BUMPED_LABEL,
	[RACING_EACHWAY_LABEL]: RACING_EACHWAY_LABEL,
	[RACING_LABEL]: RACING_LABEL,
	[REBUYS_LABEL]: REBUYS_LABEL,
	[SELECTION_PRICE_NOT_APPLICABLE]: SELECTION_PRICE_NOT_APPLICABLE,
	[SPORTS_LABEL]: SPORTS_LABEL,
	[VERIFIED]: VERIFIED,
	[YESTERDAY]: YESTERDAY,
	Abandoned: 'Abandoned',
	Accept: 'Accept',
	accepted: 'accepted',
	Account: 'Account',
	account: 'account',
	AccountBlockedTemporarily: 'Account Blocked Temporarily',
	AccountNumber: 'Account Number',
	AcronymDriver: 'D',
	AcronymJockey: 'J',
	AcronymKilogram: 'kg',
	AcronymLoss: 'L',
	AcronymRace: 'R',
	AcronymRefunded: 'R',
	AcronymTrainer: 'T',
	AcronymUnresulted: 'U',
	AcronymWeather: 'Weather',
	AcronymWin: 'W',
	Active: 'Active',
	Add: 'Add',
	Added: 'Added',
	Adds: 'Adds',
	AddToBetSlip: 'Add to Bet Slip',
	Age: 'Age',
	All: 'All',
	Amount: 'Amount',
	Apply: 'Apply',
	Approved: 'Approved',
	at: 'at',
	Available: 'Available',
	AvailableBonusBet: 'Available Bonus Bet',
	AvailableCash: 'Available Cash',
	AwesomeThanks: 'Awesome, thanks!',
	BackToBetting: 'Back to Betting',
	BackToTop: 'Back To Top',
	Balance: 'Balance',
	Bank: 'Bank',
	BankDepositInformation: 'Bank Deposit Information',
	BankEFT: 'Bank EFT',
	BetLimit: 'Bet Limit',
	Bets: 'Bets',
	BetSlip: 'BetSlip',
	BillerCode: 'Biller code',
	Bonus: 'Bonus',
	Box: 'Box',
	Boxed: 'Boxed',
	Break: 'Break',
	BSB: 'BSB',
	Buy: 'Buy',
	BuyIn: 'Buy-In',
	Cancel: 'Cancel',
	CancelWithdrawal: 'Cancel Withdrawal',
	Career: 'Career',
	Cash: 'Cash',
	CashTournament: 'Cash Tournament',
	Change: 'Change',
	ChooseCreditCard: 'Choose Debit Cards',
	ClaimFreeTicket: 'Claim Free Ticket',
	Clear: 'Clear',
	Close: 'Close',
	Closed: 'Closed',
	Closing: 'Closing',
	Colour: 'Colour',
	Comment: 'Comment',
	Competitions: 'Competitions',
	Confirm: 'Confirm',
	Confirmed: 'Confirmed',
	ContinueBetting: 'Continue Betting',
	CreditCard: 'Debit Card',
	CreditCardNumber: 'Debit Card Number',
	CreditCardVerification__Success: 'Deposit from Debit Cards',
	Currency: 'Currency',
	CustomAmount: 'Custom Amount',
	daily: 'daily',
	Daily: 'Daily',
	'Daily Double': 'Daily Double',
	DailyTournamentSchedule: 'Daily Tournament Schedule',
	Dam: 'Dam',
	Date: 'Date',
	Delete: 'Delete',
	deposit: 'deposit',
	Deposit: 'Deposit',
	DepositAmount: 'Deposit Amount',
	DepositFromBankAccount: 'Deposit from Bank Account',

	DepositReceipt: 'Deposit Receipt',
	Deposits: 'Deposits',
	DepositSuccessful: 'Deposit Successful',

	Description: 'Description',
	Details: 'Details',
	Dismiss: 'Dismiss',
	Distance: 'Distance',
	Dividend: 'Dividend',
	DOB: 'DOB',
	EmailAddress: 'Email Address',
	EndDate: 'End Date',
	Ended: 'Ended',
	EnterCreditCardDetails: 'Enter Debit Card Details',
	EnterTournament: 'Enter Tournament',
	Entries: 'Entries',
	EntriesClose: 'Entries Close',
	EntryCost: 'Entry Cost',
	events: 'events',
	EW: 'EW',
	Email: 'Email',
	Exacta: 'Exacta',
	Existing: 'Existing',
	Exotic: 'Exotic',
	Exotics: 'Exotics',
	Expiry: 'Expiry',
	Fee: 'Fee',
	FilterBy: 'Filter By',
	Firm: 'Firm',
	Fixed: 'Fixed',
	fixed: 'fixed',
	FixedOdds: 'Fixed Odds',
	Flexi: 'Flexi',
	Forgot: 'Forgot',
	Free: 'Free',
	FullyRefunded: 'Fully refunded',
	THOROUGHBRED: 'THOROUGHBREDS',
	Thoroughbred: 'Thoroughbred',
	Get: 'Get',
	Good: 'Good',
	GotItThanks: 'Got it, thanks!',
	GREYHOUNDS: 'GREYHOUNDS',
	Greyhounds: 'Greyhounds',
	HARNESS: 'HARNESS',
	Harness: 'Harness',
	Heavy: 'Heavy',
	Hidden: 'Hidden',
	Hide: 'Hide',
	HideMarkets: 'Hide Markets',
	HowDoesThisWork: 'How does this work?',
	ID: 'ID',
	Immediately: 'immediately',
	including: 'including',
	International: 'International',
	INTERNATIONAL: 'INTERNATIONAL',
	Join: 'Join',
	Last: 'Last',
	length: 'length',
	Leg: 'Leg',
	limit: 'limit',
	Limit: 'Limit',
	Live: 'Live',
	LIVE: 'LIVE',
	Locked: 'Locked',
	Login: 'Login',
	Logo: 'Logo',
	Logout: 'Logout',
	Long: 'Long',
	lose: 'lose',
	Losing: 'Lost',
	Loss: 'Loss',
	loss: 'loss',
	LOSS: 'Loss',
	MakeAWithdrawal: 'Make a Withdrawal',
	MakeDeposit: 'Make a Deposit',
	Menu: 'Menu',
	Minimum: 'Minimum',
	minus: 'minus',
	MM_YY: 'MM/YY',
	Mobile: 'Mobile',
	Monthly: 'Monthly',
	monthly: 'monthly',
	MoreInfo: 'More Info',
	MoreMarkets: 'More Markets',
	Multi: 'Multi',
	MyRebuys: 'My Re-buys',
	MyTournaments: 'My Tournaments',
	NameOnCreditCard: 'Name on Debit Card',
	NeedHelp: 'Need Help?',
	Next: 'Next',
	NextStep: 'Next Step',
	NextTournament: 'Next Tournament',
	Night: 'Night',
	No: 'No',
	None: 'None',
	Note: 'Note',
	Now: 'Now',
	Odds: 'Odds',
	OrderBy: 'Order By',
	Other: 'Other',
	Owners: 'Owners',
	P: 'P',
	Paid: 'Paid',
	PartiallyRefunded: 'Partially refunded',
	Password: 'Password',
	Pending: 'Pending',
	pending: 'pending',
	PerEvent: 'Per Event',
	PhoneNumber: 'Phone Number',
	PLACE: 'PLACE',
	Place: 'Place',
	place: 'place',
	Play: 'Play',
	PlayerBets: 'Player Bets',
	PlayerProfile: 'Player Profile',
	PlayersRebuys: 'Players Re-buys',
	PlayTournament: 'Play Tournament',
	PlayWith: 'Play With',
	Popular: 'Popular',
	Position: 'Position',
	PossiblePayout: 'Possible Payout',
	PotentialPayout: 'Potential Payout',
	PotentialWinnings: 'Potential Winnings',
	PrivacyPolicy: 'Privacy Policy',
	Prize: 'Prize',
	PrizeFormat: 'Prize Format',
	PrizePool: 'Prize Pool',
	PrizeTable: 'Prize Table',
	PromoCode: 'Promo Code',
	Promotions: 'Promotions',
	Quinella: 'Quinella',
	Quaddie: 'Quaddie',
	QuaddieBetSlip: 'QuaddieBetSlip',
	race: 'race',
	Race: 'Race',
	races: 'races',
	Rank: 'Rank',
	ReBet: 'ReBet',
	RebuyIntoTournament: 'Re-buy into Tournament',
	RebuyLimit: 'Re-buy Limit',
	Receipt: 'Receipt',
	Recent: 'Recent',
	Ref: 'Ref',
	Refunded: 'Refunded',
	Rejected: 'Rejected',
	removed: 'removed',
	Results: 'Results',
	Retry: 'Retry',
	Scratching: 'Scratching',
	SearchTournaments: 'Search Tournaments',
	Season: 'Season',
	SecurityCode: 'Security Code',
	Selection: 'Selection',
	Selections: 'Selections',
	Send: 'Send',
	Sex: 'Sex',
	Shared: 'Shared',
	Short: 'Short',
	Show: 'Show',
	Single: 'Single',
	SingleBetSlip: 'Single Bet Slip',
	Singles: 'Singles',
	Sire: 'Sire',
	Sledge: 'Sledge',
	SledgeBox: 'Sledge Box',
	Soft: 'Soft',
	Spend: 'Spend',
	spend: 'spend',
	sport: 'sport',
	Sport: 'Sport',
	Sports: 'Sports',
	Stake: 'Stake',
	StartDate: 'Start Date',
	StartPlaying: 'Start Playing',
	Starts: 'Starts',
	Status: 'Status',
	Step: 'Step',
	Support: 'Support',
	Synthetic: 'Synthetic',
	TermsAndConditions: 'Terms and Conditions',
	termsAndConditions: 'terms and conditions',
	TermsOfService: 'Terms Of Service',
	Ticket: 'Ticket',
	'Ticket Purchase': 'Ticket Purchase',
	Tickets: 'Tickets',
	To: 'To',
	to: 'to',
	Today: 'Today',
	togo: 'to go',
	Top: 'Top',
	Total: 'Total',
	TotalRebuys: 'Total Re-buys',
	Tournament: 'Tournament',
	TournamentDetails: 'Tournament Details',
	TournamentEntry: 'Tournament Entry',
	'Tournament Entry': 'Tournament Entry',
	TournamentRebuy: 'Tournament Re-buy',
	tournaments: 'tournaments',
	Tournaments: 'Tournaments',
	TournamentsEntered: 'Tournaments Entered',
	towin: 'to win',
	ToWin: 'To Win',
	Track: 'Track',
	TrackDistance: 'Track Dist',
	Transactions: 'Transactions',
	TrendingTournament: 'Trending Tournaments',
	Trifecta: 'Trifecta',
	TryAgain: 'Try Again',
	Type: 'Type',
	User: 'User',
	Username: 'Username',
	Verified: 'Verified',
	Verify: 'Verify',
	VerifyAmount: 'Verify Amount',
	VerifyCard: 'Verify Card',
	VerifyLater: 'Verify Later',
	via: 'via',
	ViewTournament: 'View Tournament',
	W: 'W',
	weekly: 'weekly',
	Weekly: 'Weekly',
	Welcome: 'Welcome',
	Wet: 'Wet',
	WeWillSendYou: "We'll send you",
	WIN: 'WIN',
	Win: 'Win',
	win: 'win',
	Winning: 'Winning',
	winning: 'winning',
	WinPotential: 'To Potentially Win',
	with: 'with',
	Withdraw: 'Withdraw',
	withdraw: 'withdraw',
	WithdrawableBalance: 'Withdrawable Balance',
	Withdrawal: 'Withdrawal',
	withdrawal: 'withdrawal',
	WithdrawalMethod: 'Withdrawal Method',
	WithdrawalReceipt: 'Withdrawal Receipt',
	Withdrawals: 'Withdrawals',
	Without: 'Without',
	Won: 'Won',
	WON: 'WON',
	Yes: 'Yes',
	YesPlease: 'Yes, please',
	"RacingHomePage__Today's": "Today's",
	"RacingHomePage__Tomorrow's": "Tomorrow's",
	"RacingHomePage__Yesterday's": "Yesterday's",
	'DerivativeSelectionList__Label--FVF': 'Favourite vs Field',
	'DerivativeSelectionList__Label--H2H': 'Head to Head',
	'DerivativeSelectionList__Label--HVH': 'Half vs Half',
	'DerivativeSelectionList__Label--IO': 'Inside vs Outside',
	'DerivativeSelectionList__Label--OVE': 'Odds vs Evens',
	'Racing__Status--abandoned': 'abandoned',
	'Racing__Status--closed': 'closed',
	'Racing__Status--deleted': 'deleted',
	'Racing__Status--interim': 'interim',
	'Racing__Status--paid': 'paid',
	'Racing__Status--paying': 'paying',
	'Racing__Status--selling': 'selling',
	'Racing__Status--suspended': 'suspended',
	'Racing__Status--finished': 'finished',
	'SpeedmapLegend__Value--First-Starter': 'First-Starter',
	'SpeedmapLegend__Value--Backmarker': 'Backmarker',
	'SpeedmapLegend__Value--Leader': 'Leader',
	'SpeedmapLegend__Value--Midfield': 'Midfield',
	'SpeedmapLegend__Value--Off-pace': 'Off-pace',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS}--mobile`]: 'Account Statement',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS}`]: 'Account Transactions',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS}--mobile`]: 'Bonus Statement',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS}`]: 'Bonus Bet Transactions',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_DEFAULT_BET_AMOUNTS}--mobile`]: 'Default Bet Amounts',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_DEFAULT_BET_AMOUNTS}`]: 'Default Bet Amounts',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_DEPOSIT_LIMIT}--mobile`]: 'Set Deposit Limits',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_DEPOSIT_LIMIT}`]: 'Set Deposit Limits',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_DEPOSIT}--mobile`]: 'Make a Deposit',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_DEPOSIT}`]: 'Deposit Funds',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_LOSS_LIMITS}--mobile`]: 'Set Loss Limits',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_LOSS_LIMITS}`]: 'Set Loss Limits',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_REALITY_CHECK}--mobile`]: 'Reality Check',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_REALITY_CHECK}`]: 'Reality Check',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_RESET_PASSWORD}--mobile`]: 'Reset Password',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_RESET_PASSWORD}`]: 'Reset Password',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_GREEN_ID_FORM_UPLOAD}`]: 'Verification',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_GREEN_ID_FORM_UPLOAD}--mobile`]: 'Green Id Verification',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING}--mobile`]: 'Responsible Gambling',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING}`]: 'Responsible Gambling',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_SELF_EXCLUSION}--mobile`]: 'Self Exclusion (Permanent)',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_SELF_EXCLUSION}`]: 'Self Exclusion (Permanent)',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_SPEND_LIMIT}--mobile`]: 'Set Spend Limits',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_SPEND_LIMIT}`]: 'Set Spend Limits',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_TAKE_A_BREAK}--mobile`]: 'Take a Break',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_TAKE_A_BREAK}`]: 'Take a Break',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_USER_HOME}--mobile`]: 'My Profile',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_USER_HOME}`]: 'My Profile',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_WITHDRAW_FUNDS}--mobile`]: 'Withdraw Funds',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_WITHDRAW_FUNDS}`]: 'Withdraw Funds',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_WITHDRAWALS}--mobile`]: 'Cancel Withdrawal',
	[`UserAccountNavList__${USER_ACCOUNT_VERIFY_CREDIT_CARDS_URL}--mobile`]: 'Verify Credit Cards',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_WITHDRAWALS}`]: 'Cancel Withdrawal',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_RNS_ACCOUNT}`]: 'R&S Account',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_RNS_ACCOUNT}--mobile`]: 'R&S Account',
	AccountSummary__AccountNumber: 'Acc No',
	AccountSummary__UserEmail: 'User’s e-mail',
	AccountSummary__LockScreen: 'Lock Screen',
	AccountSummary__MyAccount: 'My Account',
	AccountSummaryNavList__Download: 'Download the mobile app',
	AccountSummaryStatistics__Happy: 'Happy to see you again',
	AccountSummaryStatistics__PendingBets: 'Pending Bets',
	AccountSummaryStatistics__PotentialWinnings: 'Potential Winnings',
	AccountSummaryStatistics__TotalStakes: 'Total Stakes',
	AccountSummaryStatistics__Welcome: 'Welcome back',
	AccountTransactionsCellMulti__Leg: '{{length}} Leg Multi',
	AccountTransactionsCellRacing__GoatMargin: '*GOAT Margin length of {{margin}} added to 2nd placed runner',
	AccountTransactionsCellRebet__MatchStarts: 'Starts in:',
	AdditionalAddressFields__AutoComplete: 'Return to auto complete address',
	AdditionalAddressFields__PostcodeConstraint: '^Minimum 4 numbers required',
	AdditionalAddressFields__TownCity: 'Town/City/Suburb *',
	AuthoriseVerification__CreditCard: 'I give authorisation to verify my credit card.',
	AuthoriseVerification__CreditCardWithBrand: 'I authorise {{brandName}} to verify my credit card.',
	Save__CreditCardWithBrand: 'Save Card details',
	BankAccountForm__AccountHolderName: 'Account Holder Name',
	BankAccountForm__AccountName: 'Account Name',
	BankAccountForm__AccountNumber: 'Account Number',
	BankAccountForm__BankBSB: 'BSB Number',
	BankAccountForm__BankBSBFormat:'999-999',
	BankAccountForm__BankName: 'Bank Name',
	BankAccountForm__BankStateBranch: 'Bank State Branch',
	BankAccountForm__BankBranch:'Bank/Branch',
	BankAccountForm__WhatBankName: 'What is the name of the bank?',
	BetPendingReview__Title: 'Bet Pending Review',
	BetPlacementContainer__AddToMulti: 'Add to Multi',
	BetPlacementContainer__AddToBetSlip: 'Add to Bet Slip',
	BetPlacementContainer__ClearSelection: 'Clear Selections',
	BetPlacementContainer__ChargeEntryButton: 'Buy Ticket & ',
	BetPlacementContainer__ChargeEntryMessage: 'You will be charged {{cost}} Tournament Entry.',
	BetPlacementContainer__ExceededBalance: 'Bet amount exceeded your balance. Amount set to your maximum.',
	BetPlacementContainer__FreeEntryButton: 'Claim Ticket & ',
	BetPlacementContainer__FreeEntryMessage: 'You will NOT be charged Tournament Entry fee.',
	BetPlacementContainer__PlaceOdds: 'Place odds',
	BetPlacementContainer__RacingOddsChange: 'changed - {{last}} to {{current}}',
	BetPlacementContainer__SportOddsChange: 'Odds changed - {{last}} to {{current}}',
	BetPlacementContainer__WinOdds: 'Win odds',
	BetPromptContainer__AnyOrder: 'Any Order',
	BetPromptContainer__BetReceiptTitle: 'Bet Receipt',
	BetPromptContainer__ExactOrder: 'Exact Order',
	BetPromptContainer__QuickDepositTitle: 'Quick Deposit',
	BetReceipt__Continue: 'Continue Betting',
	BetReceipt__Refunded: 'Your bet has been fully refunded',
	BetReceipt__RefundedSingle: 'One of your bets has been fully refunded',
	BetReceipt__Rejected: 'Your bet has been rejected',
	BetReceipt__RejectedSingle: 'One of your bets has been rejected',
	BetReceipt__RejectReason: 'Reject Reason',
	BetReceipt__Trader: 'Trader Comment',
	BetSelectionSingle__BUMPED: 'BUMPED',
	BetSelectionSingle__MarginMessage: 'Add {{margin}}L to 2nd',
	BetSlipButton__Label: 'BetSlip',
	BetSummaryBetItem__MarginMessage: '*Adds {{marginButt}} length(s) to 2nd placed runner',
	BetSummaryBetList__NoBets: 'No bets available to show.',
	BetSummaryTicketList__NoTickets: "You don't have any tickets.",
	BettaBucksAmount__PlayWith: 'Play with',
	BetTermsMessage__TermsMessageStart: 'All bets accepted in accordance with the ',
	BetTermsMessage__TermsMessageEnd: ' - Gamble Responsibly.',
	BonusBetsList__Accepted: 'Accepted',
	BonusBetsList__BonusBetsMessage:
		'Bonus Bets are given for the purpose to promote the website and give new players the opportunity to recreationally enjoy the platform.',
	BPayDeposit__InfoTitle: '{{BPay}} Deposit Information',
	BPayDeposit__Title: 'Deposit with {{BPay}}',
	CancelWithdrawalConfirmation__CanBe: 'can be cancelled.',
	CancelWithdrawalConfirmation__FundsWillAppear: 'Funds will appear in your',
	CancelWithdrawalConfirmation__Success: 'has been successfully cancelled.',
	CancelWithdrawalConfirmation__ThisRequest: 'This withdrawal request of',
	CancelWithdrawalConfirmation__TryAgain: 'Please try again or contact Customer Service for further assistance.',
	CancelWithdrawalConfirmation__UnableToProcess: 'We are unable to process your request.',
	ContactUsPanel__Title: 'Contact Us for Assistance',
	CountdownPanel__Message: 'Claim your bonus bet before the time is up!',
	CreateAccount__Button: 'Create New Account',
	CreateAccount__NewTo: 'New to {{brandName}}?',
	CreditCardVerification__EnterVerification:
		'Enter the verification cents amount that we added to your verification deposit. This can be found on your credit card statement.',
	CreditCardVerification__VerificationError:
		'Oops! Verify Amount entered is incorrect. Please try again: {{verificationAttemptsLeft}}/3 attempts left.',
	CreditCardVerification__VerifyCreditCard:
		'Your credit card needs to be verified prior to making any withdrawal request.',
	DefaultBetAmounts__Accepted: 'Accepted',
	DefaultBetAmounts__BetAmount: 'Bet Amount',
	DefaultBetAmounts__Error: 'Your default amounts have been updated.',
	DefaultBetAmounts__NewDefault: 'New Default Bet Amount',
	DefaultBetAmounts__NotAccepted: 'Not Accepted',
	DefaultBetAmounts__NotSet: 'Not Set',
	DefaultBetAmounts__TournamentBetAmount: 'Tournament Bet Amount',
	DefaultBetAmounts__TournamentLive: 'Tournament Live Bet Warning',
	DefaultBetAmounts__TournamentMessage: 'When placing a real money bet in a tournament, I confirm:',
	DefaultBetAmounts__Update: 'Update Defaults',
	DepositConfirmation__CreditAccount: "We'll credit your account",
	DepositConfirmation__CreditBalance: 'We have credited your{{bonus}} balance.',
	DepositConfirmation__FirstSuccessMessage: '1st Deposit was a success!',
	DepositConfirmation__NoBonusApplied: 'No Deposit Bonus Bet Applied!',
	DepositContainer__AddBalance: "We'll add to your balance",
	DepositContainer__RemoveCard: 'You are about to remove Credit Card ending in',
	DepositForm__Deposit: 'I want to deposit',
	DepositForm__ExceededVerification:
		'You have exceeded verification attempts. We will contact you directly to verify your Credit Card.',
	DepositForm__Minimum: '$10 Min Deposit',
	DepositForm__VerifiedMessage: 'This credit card is now fully verified - thanks!',
	DepositReceipt__SuccessMessage: 'We have credited your account balance.',
	DerivativeSelectionList__Heading: 'Special Racing Markets',
	ErrorBoundaryContainer__Message: 'Oops. Something has gone wrong. Please refresh and try again.',
	ErrorBoundaryContainer__MessageBetPrompt: 'Oops. Something has gone wrong. Please refresh the betslip and try again.',
	ErrorBoundaryContainer__OpenIntercom: 'Open Intercom',
	ErrorBoundaryContainer__RemountDefault: 'We were unable to refresh. Would you like to open a support chat?',
	ErrorBoundaryDefault__Message:
		'Something has gone wrong and we were unable to load this page. Would you like to try again?',
	ErrorPage404__Late: 'There has been a late',
	ErrorPage404__Message:
		'Odds are you stumbled across this page by typing the wrong thing, or perhaps it has just been',
	ErrorPage404__SubMessage: 'Either way, here are some options to help you on your journey.',
	ErrorPage404__Title: 'Page not found',
	ExternalDepositResult__FailMessage: 'Deposit failed! Try again.',
	ExternalDepositResult__SuccessMessage: 'Deposit Successful! We will soon credit your account balance.',
	FirstDeposit__AmountError: 'Amount must be less than or equal to 100',
	FirstDeposit__BillingStatements: "Billing statements will reflect '{{brandName}}'",
	FirstDeposit__Depositing: 'You are depositing',
	FirstDeposit__DepositingCustom: 'You are depositing a custom amount',
	FirstDeposit__Glad: "We're glad you're here, {{first_name}}",
	FirstDeposit__MakeADeposit: 'Make a Deposit',
	FirstDeposit__PreferAnother: 'I prefer another 1st Deposit Option',
	FirstDeposit__Skip: 'Skip First Deposit Bonus Offer',
	FirstDeposit__Spend: 'How much do you want to spend?',
	Footer__Rights: 'All Rights Reserved.',
	FooterNote__One:
		'By accessing, continuing to use, or navigate throughout this site you accept that we will utilise certain browser cookies to improve the experience which you receive with us. {{brandName}} do not use any cookies which interfere with your privacy, but only ones which will improve your experience whilst using our site, please refer to our Privacy Policy for further information on our use of cookies and how you prevent their use should you wish.',
	FooterNote__Two:
		'{{brandName}} is operated by {{brandName}} PTY LTD registered in Australia. {{brandName}} is licenced in Northern Territory, Australia by NT Racing Commission to accept bets via the Internet on Racing, Sport and Entertainment.',
	GetStartedBanner__GetStarted: 'Get Started',
	GetStartedBanner__NotTooLate: "It's not too late!",
	GetStartedBanner__StepDeposit: 'Make a Deposit',
	GetStartedBanner__StepGame: "'Get in the Game'",
	HowToPlayMessage__Heading: 'How do I Play a Tournament?',
	HowToPlayMessage__Point1: 'Start by buying a tournament ticket.',
	HowToPlayMessage__Point2: 'Then place your tournament bets!',
	HowToPlayMessage__Quote: '“Good luck & Good Punting”',
	HowToPlayMessage__QuoteName: 'Todd',
	HowToPlayMessage__QuoteTitle: 'CEO TopBetta',
	HowToPlayTournaments__Heading: 'How to Play a Tournament',
	HowToPlayTournaments__Point1: 'You select which tournament you would like to play in, it’s up to you',
	HowToPlayTournaments__Point3:
		'You have to speculate to accumulate and top the LeaderBoard taking the cash or ticket to an even bigger tournament!',
	HowToPlayTournaments__SubHeading1: 'Select a Tournament',
	HowToPlayTournaments__SubHeading2: 'Place your Bets',
	HowToPlayTournaments__SubHeading3: 'Check the Leaderboard',
	JoinLoginElements__GetStarted: 'Get Started',
	JoinLoginElements__LoginRegister: 'Login or Register',
	JoinLoginElements__Username: 'Email',
	LockedScreen__NotYou: 'Not you?',
	LockedScreen__SignIn: 'Sign in as a different account',
	LockedScreenContainer__Error: 'Login failed. Please check your password.',
	LoggedUserElements__AddFunds: 'Add Funds',
	Login__Chat: "Can't Access Account - Talk to Us",
	Login__ForgotPassword: 'Forgot Password?',
	Login__KeepLoggedIn: 'Keep me logged in',
	Login__Player: "I'm a {{brandName}} player",
	Login__UsernameOrEmail: 'Email',
	LoginContainer__ForgotPassword: 'Forgot Your Password?',
	LoginContainer__LoginOrJoin: 'Login or Join',
	LoginForPromotions__LoginForPromotions: 'Login for promotions',
	MarketGroupings__NoMarketsMessage: 'No open markets available',
	Masthead__KeepGoing: 'Awesome. Keep Going',
	Masthead__LoginError: 'Please fill in the required fields.',
	MastheadContainer__PasswordError: "We can't find a user with that e-mail address.",
	MeetingList__NextRace: 'Next Race',
	MeetingListContainer__NoAURaces: 'No AU/NZ Races Available',
	MeetingListContainer__NoInternationalRaces: 'No International Races Available',
	MobileAuthenticationForm__IsValid:
		'{{brandName}} will send you an SMS with a 4 digit verification code to your mobile device, this will verify your mobile number. Tap Send SMS Code to proceed.',
	MobileAuthenticationForm__SendSMS: 'Send SMS Code',
	MobileAuthenticationForm__SMSVerification: '',
	// 'We will use your mobile number for SMS verification. Standard messaging rates apply.',
	MobileAuthenticationForm__SMSVerificationCode:
		'First, enter your Australian mobile number to receive a one time SMS verification code.',
	//MobileAuthenticationForm__VerifyRealPerson: "Let's verify that you're a real person",
	MobileAuthenticationForm__VerifyRealPerson: 'Lets verify that you’re a real person',
	MobileFooter__PuntersClub: 'Punting Club Terms and Conditions',
	MobileVerificationForm__CodeError: 'Code is not a number',
	MobileVerificationForm__CreateAccount: 'Create Account',
	MobileVerificationForm__NextStep: 'Next Step',
	MobileVerificationForm__DontForget: "Don't Forget",
	MobileVerificationForm__Step1: 'Step 1',
	//MobileVerificationForm__Step3of3: 'Step 3 of 3',
	MobileVerificationForm__Step3of3: 'Step 2',
	MobileVerificationForm__UndoNumber: 'Undo Number',
	MobileVerificationForm__VerificationCode: 'Verification Code',
	MobileVerificationForm__Verified: 'Nice to meet you! {{first_name}}!',
	MobileVerificationForm__VerifiedOne: 'All good - you have a valid mobile number and are human after all.',
	MobileVerificationForm__VerifiedThree: 'Make a deposit and start betting.',
	MobileVerificationForm__VerifiedTwo: 'Lets get to know you better, hit Next Step to begin.',
	MobileVerificationForm__Verify: "Let's verify that you're a real person",
	MobileVerificationForm__VerifyMobile: 'Verify your mobile number using your verification code.',
	MobileVerificationForm__VerifyMobileButton: 'Verify Mobile',
	ModalFooter__Message: "This is a secure encrypted form - you're safe.",
	MoreInfo__Done: 'Got it, thanks!',
	MoreInfo__Five:
		'You may also continue to bet with any cash winnings you receive on that day provided your total {{typeOfLimitLowerCase}} {{limitTypeFactor}} winnings remains under your limit.',
	MoreInfo__Four:
		'From midnight to midnight (AEST), you will be allowed to place bets and enter cash tournaments up to your {{typeOfLimitLowerCase}} limit.',
	MoreInfo__Heading: 'Daily, Weekly & Monthly {{limitType}} Limits',
	MoreInfo__One:
		'A daily {{typeOfLimitLowerCase}} limit means that you will not be able to {{limitTypeText}} an amount greater than this in a 24-hour period.',
	MoreInfo__Seven: 'If you would like to block your access to the site entirely, you can request self-exclusion.',
	MoreInfo__Six:
		'You may lower your {{typeOfLimitLowerCase}} limit at any time, but raising it will only take effect after 7 days.',
	MoreInfo__Three:
		'A monthly {{typeOfLimitLowerCase}} limit means that you will not be able to {{limitTypeText}} an amount greater than this in a 30-day period.',
	MoreInfo__Two:
		'A weekly {{typeOfLimitLowerCase}} limit means that you will not be able to {{limitTypeText}} an amount greater than this in a 7-day period.',
	MultiBetContainer__Balance: 'Bal',
	MultiBetContainer__BetSlip: 'Bet Slip',
	MultiBetContainer__EmptyOne: 'Your Bet Slip is empty.',
	MultiBetContainer__EmptyTwo: 'Bets you add will appear here.',
	MultiBetContainer__ExceedMessage: 'Bet amount has exceeded your account balance.',
	MultiBetContainer__InvalidSelections:
		//'You have selections that are invalid and cannot be combined into a multi bet. You are still able to place single bets.',
		'You have selections that are invalid and cannot be combined into a multi bet. You are still able to place single bets',
	MultiBetContainer__KeepBets: 'Keep Selections',
	MultiBetContainer__KeepBets_disable_placeButton:
		'Oops! Look like your account needs a deposit. Please click on the `Add Funds` option to load up your account then you can play.',
	MultiBetContainer__OddsChange: 'Odds changed from {{oldPrice}} to {{newPrice}}',
	MultiBetContainer__Warning: 'WHAT ARE YOU REALLY GAMBLING WITH?',
	MultiBetContainer__StruckWarning: 'For free and confidential support call 1800 858 858 or visit',
	MultiBetSelectionList__AllSingles: 'Add to all Singles',
	MultiBetSummary__Title: 'Bet Summary',
	MyTournaments__LoginMessage: 'You have to login to view tournaments you have entered.',
	MyTournaments__NotEntered: 'You have not entered any tournaments',
	NextToJumpCard__Upcoming: 'There are no upcoming',
	NextToJumpRaceDetails__View: 'View Full Race Card',
	NextToJumpTournamentDetails__EnterTournament: 'Enter Tournament',
	NextTournamentContainer__NoTournamentsMessage: 'No upcoming tournaments are available',
	PasswordResetContainer__Error: "We can't find a user with that e-mail address.",
	PasswordResetMessage__CreateAccount: 'Create your {{brandName}} account now.',
	PasswordResetMessage__New: 'New here?',
	PasswordResetMessage__Oops: 'Oops! I remember now. ',
	PasswordResetRequestSuccess__Inbox: 'Please Check Your Inbox',
	PasswordResetRequestSuccess__Instructions: 'We have sent the password reset instructions to the email you provided.',
	PasswordResetRequestSuccess__Success: 'Success: Email sent',
	PINAuthentication__Error: 'PINs do not match.',
	PINAuthenticationContainer__ForgotPin: 'Forgot Pin?',
	PINAuthenticationContainer__Message: 'A PIN is a quick way to login using 4 digits',
	PINAuthenticationContainer__NotificationSuccess: 'PIN Authentication setup for this device!',
	PINAuthenticationContainer__Password: 'Use password to login',
	PINAuthenticationContainer__RepeatMessage: 'Repeat the PIN to confirm setup',
	PlayerBetsHomePage__Description: 'Browse and bet on player performance',
	PlayerBetsHomePage__Title: 'Player Performance and Sport Betting Online',
	PlayerProfileBets__BetsPlaced: 'Bets Placed',
	PlayerProfileSummary__AverageBet: 'Avg Bet',
	PlayerProfileSummary__BettingPerformance: 'Betting Performance',
	PlayerProfileSummary__WinRatio: 'Win Ratio',
	PoliForm__Title: 'Deposit with {{poli}}',
	Profile__Address: 'Address',
	Profile__AddressInformation: 'Address Information',
	Profile__Birthday: 'Birthday',
	Profile__Country: 'Country',
	Profile__FirstName: 'First Name',
	Profile__FormDisabledMessage:
		'Should you wish to change any of your personal details, please contact our Customer Services Team and they can assist with this change to your details.',
	Profile__LastName: 'Last Name',
	Profile__MobileNumber: 'Mobile Number',
	Profile__Postcode: 'Postcode *',
	Profile__State: 'State *',
	Profile__Suburb: 'Suburb',
	Profile__Title: 'Personal Information',
	PromoAlert__Title: 'PROMO ALERT',
	Promotions__BetNow: 'Bet Now',
	Promotions__CurrentPromotions: 'Current Promotions',
	Promotions__NoArticles: 'No articles to show.',
	Promotions__NoPromotions: 'No promotions available.',
	Promotions__PromotionUnavailable: 'The promotion you requested is not available.',
	QuickBettingSummaryContainer__Title: 'Summary',
	RaceCard__EarlySpeed: 'Early Speed',
	RaceCard__ComputerTips: 'Computer Tips',
	RaceCard__Form: 'Form',
	RaceCard__HideMyBets: 'Hide my Bets',
	RaceCard__OnSettling: 'On Settling',
	RaceCard__RaceOverview: 'Race Overview',
	RaceCard__RaceExpertSelections: 'Expert Selections',
	RaceCard__ShowMyBets: 'Show my Bets',
	RaceCard__Speedmaps: 'Early Speed',
	RaceCard__HotStats: 'Hot Stats',
	RaceCard_BetBuilder: 'Bet Builder',
	RaceCard__StrikeRate_JH: 'JH%',
	RaceCard__StrikeRate_J: 'J%',
	RaceCard__StrikeRate_T: 'T%',
	RaceCard__StrikeRate_JT: 'JT%',
	Race_identifier: 'R',
	Race_Distance: 'Distance',
	BetBuilderMeeting_title: 'Meeting',
	RaceProductLegend__AllExotics: 'All Exotics',
	RaceProductLegend__PleaseNote: 'Please Note',
	RaceSelectionsList__Flucs: 'Flucs',
	RaceSelectionsList__GoatLength: 'GOAT +Length',
	RaceSelectionsList__MarginMessage: 'GOAT Margin length of {{goatLength}} added to 2nd placed runner',
	RaceSelectionsList__PlaceBet: 'Place Bet',
	RaceSelectionsList__AddToBetSlip: 'Add To Bet Slip',
	RaceSelectionsList__PoolTotals: 'Pool Totals',
	RacingDateSelection__Futures: 'Futures',
	RacingHomePage__Description: 'Bet on horse racing, greyhounds and harness racing online now',
	RacingHomePage__DescriptionBonus: 'Bonus Bet for New Players.',
	Filter_all: 'All',
	RacingHomePage__Next: 'Next',
	RacingHomePage__Races: 'Races',
	BetBuilderPage__Meeting: 'Meeting',
	FeatureHomePage__Races: 'Future Races',
	FeatureHomePage__Races_title: 'Feature Races',
	SportHomePage__Sport: 'Sport',
	Sport_Live_Betting: 'Live Betting',
	RacingHomePage_V3_Titel: 'OUR OFFERS',
	RacingHomePage__Racing: 'Racing',
	RacingHomePage__Today: 'Today', //@HW
	RacingHomePage__Title: 'Horse Racing Betting, Odds & Results',
	NextJumpHomePage_Title: 'Next Jump Horse Racing Betting, Odds', //@HW
	NextJumpHomePage__Description: 'Bet on horse racing, greyhounds and harness racing online now', //@HW
	NextJumpHomePage__DescriptionBonus: 'Bonus Bet for Top 3 New Players.', //@HW
	SportsNextJumpHomePage_Title: 'GRSBet Racing and Sports Betting', //@HW
	SportsNextJumpHomePage__Description: 'Bet on sports event online now', //@HW
	WelcomePage_Title: 'GRSBet Racing and Sports Betting', //@HW
	WelcomePage__Description: 'Bet on Next Jump sports event and racing online now', //@HW
	BetBuilderPage_Title: 'Horse Racing Betting, Odds on Betbuilder', //@HW
	BetBuilderPage_Description: 'Bet on horse racing, online betbuilder now', //@HW
	BetBuilder_Header_Title: 'Bet Builder',
	BetBuilder_win: 'Win',
	BetBuilder_Place: 'Place',
	BetBuilder_2L: '2L+',
	BetBuilder_5L: '5L+',
	BetBuilder_Yes: 'Y',
	BetBuilder_No: 'N',
	BetBuilder_16: '>16%',
	BetBuilder_20: '>20%',
	FeatureHomePage__Today: 'today',
	FeatureHomePage__Tomorrow: 'tomorrow',
	FeatureHomePage__Yesterday: 'yesterday',
	FeatureRacesList_Header: 'feature Races',
	FeatureAllRacesList_Header: 'Other Upcoming feature races',
	NotFoundFeatureRaces: 'Can`t find feature races for ',
	NotFoundUpcomingFeatureRaces: 'Can`t find upcoming feature races',
	Receipt__DepositMethod: 'Deposit Method',
	RegistrationConfirmation__Heading: "Sit tight, we're creating your account!",
	RegistrationConfirmation__Step1: '1 of 3: Creating your account',
	RegistrationConfirmation__Step2: '2 of 3: Initializing your account',
	RegistrationConfirmation__Step3: '3 of 3: Nearly done',
	RegistrationHeader__CreateAccount: 'Create account for full access',
	RegistrationPage__AdultsOnly: 'Adults Only',
	RegistrationPage__BettingLimitError:
		"Oops! We're sorry but something went wrong when setting your betting limit. Please try again later.",
	RegistrationPage__DepositLimitError:
		"Oops! We're sorry but something went wrong when setting your deposit limit. Please try again later.",
	RunnerForm__RunnerComments: 'Runner Comments',
	SelfExclusionConfirmation__ChangedMind: 'Changed my mind',
	SelfExclusionConfirmation__DontLike: "I don't like the site",
	SelfExclusionConfirmation__GamblingProblem: 'Gambling Problem',
	SelfExclusionConfirmation__InsertComment: 'Please insert your comment...',
	SelfExclusionConfirmation__Others: 'Others',
	SelfExclusionPrompt__Account: 'You will NOT be able to login to your account or create a new account.',
	SelfExclusionPrompt__Confirmation: 'Confirmation of permanent self-exclusion will be sent to this email address.',
	SelfExclusionPrompt__Exclude: 'Exclude Myself Now',
	SelfExclusionPrompt__Marketing: 'You will cease to receive marketing communications within 24hours.',
	SelfExclusionPrompt__Pending: 'All pending bets will stand.',
	SelfExclusionPrompt__Proceed: 'What Happens if I Proceed?',
	SelfExclusionPrompt__PuntingClub: 'You will be removed from all active Punting Clubs.',
	SelfExclusionPrompt__StandingBalance:
		'Standing balance will be automatically withdrawn to last active withdrawal method when pending bets are resulted.',
	SelfExclusions__Others: 'Others',
	SelfExclusions__Reason: 'Gambling Problem',
	SetLimit__24HourDay: '24-hour day',
	SetLimit__30Day: '30-day period',
	SetLimit__7Day: '7-day period',
	SetLimit__AdjustLimitMessage: 'Adjust your {{period}} {{limitTypeLowerCase}} limit below.',
	SetLimit__InputLabel: 'New {{periodUpperCase}} {{limitType}} Limit Amount',
	SetLimit__LoseAmount: 'You will not be able to {{limitType}} an amount greater than this in a {{changePeriod}}.',
	SetLimit__LowerLimit:
		'You may lower your {{limitTypeLowerCase}} limit at any time, but raising it will only take effect after 7 days.',
	SetLimit__MoreInfo: 'More Info.',
	SetLimit__NotSet: 'Not Set',
	SetLimit__PendingLimitMessage: 'Your pending {{limitTypeLowerCase}} limit is:',
	SetLimit__RemoveLimit: 'Remove Limit',
	SetLimit__NoLimit: 'No Deposit Limit',
	SetLimit__SetLimit: 'Set Limit',
	SetLimit__Support: 'Support Team',
	SetLimit__UpdateLimit: 'Update Limit',
	SetTimer__Alert: 'We will alert you when you have reached your Reality Check automatically.',
	SetTimer__Check: 'You can change the check if you like',
	SetTimer__SupportTeam: 'Support Team',
	SetTimer__Update: 'Update Reality Check',
	SetTimer__Updated: 'Your Reality Check has been updated.',
	SidebarContainer__MenuOptions: 'Menu Options',
	SidebarSettings__NT: 'Show Next Tournament',
	SidebarSettings__NTJ: 'Show Next to Jump',
	SidebarSettings__NTP: 'Show Next to Play',
	SidebarSettings__Save: 'Save Options',
	SimpleRegistration__HaveAccount: 'Already have an account?',
	SimpleRegistration__Header: 'Registration Form',
	SimpleRegistration__SignIn: 'Sign In',
	SimpleRegistration__SignUpNow: 'Sign Up Now',
	SimpleRegistration__YourDetails: 'Your Details',
	SpeedmapHeader__Barrier: 'Barrier',
	SpeedmapHeader__Lead: 'Lead',
	SportsHomePage__LiveBetsHeading: 'Sports Live Bets',
	SportsHomePage__NoBetsMessage: 'You have no active bets.',
	SportsHomePage__NoBettingMessage: 'Betting is currently disabled on this event',
	SportsHomePage__NoMarketsMessage: 'No available events with open markets.',
	SwapOddsButton__Text: 'Swap Odds',
	TakeABreak__1Day: '1 Day',
	TakeABreak__Long: 'Long Break',
	TakeABreak__Short: 'Short Break',
	TakeABreak__TakeBreak: 'Take {{breakLabel}} Break',
	TakeABreakConfirmation__ChangedMind: 'Changed my mind',
	TakeABreakConfirmation__Title: 'Take {{breakLabel}} Break',
	TakeABreakLong__1YearLabel: '1 Year',
	TakeABreakLong__1YearValue: '1 Year Break',
	TakeABreakLong__3YearLabel: '3 Year',
	TakeABreakLong__3YearValue: '3 Year Break',
	TakeABreakLong__5YearLabel: '5 Year',
	TakeABreakLong__5YearValue: '5 Year Break',
	TakeABreakLong__6MonthsLabel: '6 Months',
	TakeABreakLong__6MonthsValue: '6 Months Break',
	TicketHeader__QualifiedMessage: 'You are qualified.',
	TicketHeader__RemainingTurnoverMessage: 'remaining turnover to qualify.',
	TicketPurchase__MaxExceededMessage: 'You have exceeded the maximum number',
	TimerHelpSection__SessionTime: 'Session time',
	TournamentBets__NoBets: 'You have no bets on this event.',
	TournamentBets__OnThisEvent: 'On this event',
	TournamentBets__OtherUnresulted: 'Other Unresulted',
	TournamentBets__ViewMore: 'View more tournament bets',
	TournamentEventItem__NoMarketsMessage: 'Markets not open for betting yet',
	TournamentHeaderFooter__NoRanking: 'You have no ranking.',
	TournamentInsights__BackedWinner: 'backed the winner.',
	TournamentInsights__Loser: 'Sorry, you missed out!',
	TournamentInsights__Title: 'Tournament Insights',
	TournamentInsights__Winner: "Yeah! You're a winner!",
	TournamentPrizePool__Title: 'Tournament Prize Pool',
	TournamentsHomePage__NoTournamentsMessage:
		'Unfortunately, we are unable to locate any tournaments with that search criteria.',
	TournamentsHomePage__Previous: 'Previously Entered Tournaments',
	TournamentsHomePage__Title: 'Fantasy Racing & Sports Betting Tournaments',
	TournamentSummary__PrizePoolSubText: 'Play for glory to be #1',
	TrendingBets__Heading: 'Trending Bets',
	TrendingBets__NoBetsMessage: 'No trending bets are available',
	TrendingTournaments__NoTournamentsMessage: 'No trending tournaments are available.',
	TrendingTournaments__ViewMore: 'View more trending tournaments',
	UnitStake__BetReceipt: 'Bet Receipt',
	UnitStake__PendingReview: 'Pending Review',
	UnitStake__BetProcessing: 'Bet Processing',
	UnitStake__ViewDetails: 'View Bet Details',
	UnitStake__ViewSelections: 'View Selections',
	UpdatePassword__ConfirmationError: 'Must match new password',
	UpdatePassword__ConfirmPassword: 'Confirm New Password',
	UpdatePassword__EnterCurrent: 'Enter current password',
	UpdatePassword__EnterEmail: 'Enter email address',
	UpdatePassword__EnterNew: 'Enter new password',
	UpdatePassword__GenericError: 'Error occurred. Contact Help Desk.',
	UpdatePassword__NewPassword:
		'New Password (At least 10 characters, at least 1 number, at least 1 upper-case letter and at least 1 lower-case letter.)',
	UpdatePassword__OldPassword: 'Enter your current Password',
	UpdatePassword__PasswordUpdate: 'Your password has been updated.',
	UpdatePassword__SetNew: 'Set New Password',
	UpdatePassword__ShouldMatch: "Passwords don't match",
	UpdatePassword__oldAndNewNotMatch: 'This Password was used previously.',
	UpdatePassword__Step: 'Step {{step}}.',
	UserAccountMenu__ManageAccount: 'Manage your Account',
	UserAccountPage__AccountProfile: 'Account Profile',
	UserAccountPage__CancelWithdrawal: 'Cancel Withdrawal',
	UserAccountPage__DefaultBetAmounts: 'Set Default Bet Amounts',
	UserAccountPage__Upload_Green_Id_Document: 'User Verification',
	UserAccountPage__RNS_Account: 'User RNS Account',
	UserAccountPage__DepositReceipt: 'Deposit Receipt',
	UserAccountPage__MakeDeposit: 'Make a Deposit',
	UserAccountPage__ManageAccount: 'Manage Account',
	UserAccountPage__ResetPassword: 'Reset Password',
	UserAccountPage__SelfExclusion: 'Self Exclusion',
	UserAccountPage__SetDepositLimits: 'Set Deposit Limits',
	UserAccountPage__SetLossLimits: 'Set Loss Limits',
	UserAccountPage__SetSpendLimits: 'Set Spend Limits',
	UserAccountPage__TakeABreak: 'Take A Break',
	UserAccountPage__VerifyCC: 'Verify Credit Cards',
	UserAddressForm__Address: 'Address',
	UserAddressForm__AddressMessage: 'Your personal details must match your ID to start betting.', 
	UserGreenIDForm__AddressMessage: 'Green Id Verification',
	UserAddressForm__ControlLimits: 'Control your deposit & betting limits?',
	UserAddressForm__DepositLimit: 'Deposit Limit ($)',
	UserAddressForm__EnterManually: "Can't find it? Enter address manually",
	UserAddressForm__FirstName: 'First name *',
	UserAddressForm__FirstNameConstraint: '^The first name must be at least 2 characters.',
	MustMatchID: 'Must match ID',	
	UserGreenidForm_FirstName: 'First Name',
	UserGreenidForm_LastName: 'Last Name',
	UserGreenidForm_MidName: 'Middle name',
	UserGreenidForm_DOB: 'Date of birth',
	UserGreenidForm_Passport_Number: 'Passport Number',
	UserGreenidForm_Support_file: 'Upload Support File',
	UserAddressForm__GambleResponsibly: 'GAMBLE RESPONSIBLY:',
	UserAddressForm__GoBack: 'Go Back',
	GreenID_GoBack: 'Skip this stage',
	UserAddressForm__LossLimit: 'Loss Limit ($)',
	UserAddressForm__Outside: 'Outside of Australia',
	UserAddressForm__PlayersNotice: 'Players Notice',
	UserAddressForm__SetLimits:
		'Set limits for the amounts you want to bet or deposit to stay in control of your account. You can change these in your account preferences, after you register.',
	UserAddressForm__Step4of4: 'Step 4',
	//UserAddressForm__Step2of2: 'Step 2 of 2',
	UserAddressForm__Step2of3: 'Step 2 of 3',
	UserAddressForm__Surname: 'Surname *',
	UserAddressForm__SurnameConstraint: '^The last name must be at least 2 characters.',
	UserAddressForm__WeeklyLimits: 'Limit To Weekly Limits',
	UserDetailsForm__18Plus: '18+',
	UserDetailsForm__AdultsOnly: 'ADULTS ONLY',
	UserDetailsForm__DateOfBirth: 'Date of Birth',
	UserDetailsForm__Email: 'Email (You will login with this)',
	UserDetailsForm__HaveAnAccount: 'Have an account?',
	UserDetailsForm__Password:
		'Password (At least 10 characters, at least 1 number, at least 1 upper-case letter and at least 1 lower-case letter.)',
	UserDetailsForm__StartAccount: 'Start Your {{brandName}} Account',
	//UserDetailsForm__Step1of2: 'Step 1 of 2',
	UserDetailsForm__Step1of2: 'Step 2',
	UserDetailsForm__Step1of3: 'Step 3',
	UserDetailsForm__Terms:
		'By creating a new account I accept the terms and conditions, the privacy policy and confirm that I am over {{age}} years of age. It is an offence to gamble if under age.',

	UserDetailsForm__Promo: 'Yes, I would like to receive promotional offers.',
	UserDetailsForm__Username: 'Username (6 characters min)',
	UserTransactionsContainer__FilterTodayBets: 'Today Bets',
	UserTransactionsContainer__FilterOpenBets: 'Open Bets',
	UserTransactionsContainer__FilterAll: 'All Transactions',
	UserTransactionsContainer__FilterBets: 'Bets Placed',
	UserTransactionsContainer__FilterLosing: 'Losing Bets',
	UserTransactionsContainer__FilterRefunded: 'Refund Bets',
	UserTransactionsContainer__FilterTournaments: 'Tournament Entries/Bets',
	UserTransactionsContainer__FilterWinning: 'Winning Bets',
	UserTransactionsContainer__Last: 'Last {{days}}d',
	UserTransactionsContainer__OpenBets: 'Open Bets',
	UserTransactionsContainer__Title: 'Account Dashboard',
	UserTransactionsContainer__UnableToLoadDashboard: 'Unable to load account dashboard',
	UserTransactionsContainer__UnableToLoadTransactions: 'Unable to load account transactions',
	VerificationStatus__Complete: 'Complete Verification',
	VerificationStatus__Tasks: "You've completed {{from}} out of {{to}} verification tasks.",
	VerificationStatus__Title: 'Verification Status',
	VerificationStatus__Why: 'Why do I need to verify my identity?',
	VerifyCardExplanation__AccountVerified: 'This cent value will be added to your account once the card is verified.',
	VerifyCardExplanation__AreAuthorising: 'are authorising {{brandName}}',
	VerifyCardExplanation__CentValue:
		'To verify the credit card you need to obtain the cent value from your credit card statement and enter in when you next make a deposit.',
	VerifyCardExplanation__Done: 'Got it, thanks!',
	VerifyCardExplanation__GiveAuthorisation: 'give authorisation',
	VerifyCardExplanation__MustBeVerified:
		'Your credit card must be verified before any withdrawal requests can be processed.',
	VerifyCardExplanation__Questions: 'If you have any questions, please contact our Customer Services Team.',
	VerifyCardExplanation__VerificationProcess:
		'You {{authorisationMessage}} to add a random cent value between 1-99 to this deposit amount to conduct the verification process.',
	VerifyCardExplanation__VerifyingCreditCard: 'Verifying Your Credit Card',
	WithdrawalPromptContainer__Add: 'Add New Account',
	WithdrawalPromptContainer__Amount: 'Withdrawal Amount',
	WithdrawalPromptContainer__Credit: 'Bank Account',
	WithdrawalPromptContainer__MaxBalance: 'Your maximum withdrawal balance is',
	WithdrawalPromptContainer__PendingNotFound: 'We could not find any pending withdrawals.',
	WithdrawalPromptContainer__ProcessedIn: 'All withdrawals are processed in',
	WithdrawalPromptContainer__RemoveBankAccountMessage: 'You are about to remove this bank account from your account.',
	WithdrawalPromptContainer__VerifyMessage:
		'All credit cards must be verified before a withdrawal request can be submitted.',
	WithdrawalPromptContainer__WantTo: 'I want to withdraw',
	WithdrawalReceipt__RequestSuccess: 'We have received your request and will be processed during business hours.',
	WithdrawalReceipt__RequestSuccessHeader: 'Withdrawal Request Success',
	YouQualifyAlert__Deposit: '*But not for 1st deposit bonus bets',
	YouQualifyAlert__Qualify: 'You Qualify for the Promotion',
	Margin: 'Margin',
	MARGIN: 'MARGIN',
	'AU/NZ': 'AU/NZ',
	'Bet Receipt': 'Bet Receipt',
	'Quick Deposit': 'Quick Deposit',
	'Quick Betting Summary': 'Recent with Settled',
	'Credit Card': 'Credit Card',
	'1 Day': '1 Day',
	'6 Months': '6 Months',
	'PIN Authentication': 'PIN Authentication',
	'is the wrong length (should be %{count} characters)': 'is the wrong length (should be %{count} characters)',
	'is too long (maximum is %{count} characters)': 'is too long (maximum is %{count} characters)',
	'is too short (minimum is %{count} characters)': 'is too short (minimum is %{count} characters)',

	////////////////
	// All plural definitions
	Bet: 'Bet',
	Bet_0: 'Bet',
	Bet_1: 'Bets',
	Bet_2: 'Bets',
	Bet_plural: 'Bets',
	BonusBet: 'Bonus Bet',
	BonusBet_0: 'Bonus Bet',
	BonusBet_1: 'Bonus Bets',
	BonusBet_2: 'Bonus Bets',
	BonusBet_plural: 'Bonus Bets',
	Day: 'Day',
	Day_0: 'Day',
	Day_1: 'Days',
	Day_2: 'Days',
	Day_plural: 'Days',
	market: 'market',
	market_0: 'market',
	market_1: 'markets',
	market_2: 'markets',
	market_plural: 'markets',
	Month: 'Month',
	Month_0: 'Month',
	Month_1: 'Months',
	Month_2: 'Months',
	Month_plural: 'Months',
	Player: 'Player',
	Player_0: 'Player',
	Player_1: 'Players',
	Player_2: 'Players',
	Player_plural: 'Players',
	Rebuy: 'Re-buy',
	Rebuy_0: 'Re-buy',
	Rebuy_1: 'Re-buys',
	Rebuy_2: 'Re-buys',
	Rebuy_plural: 'Re-buys',
	Runner: 'Runner',
	Runner_0: 'Runner',
	Runner_1: 'Runners',
	Runner_2: 'Runners',
	Runner_plural: 'Runners',
	Week: 'Week',
	Week_0: 'Week',
	Week_1: 'Weeks',
	Week_2: 'Weeks',
	Week_plural: 'Weeks',
	Winner: 'Winner',
	Winner_0: 'Winner',
	Winner_1: 'Winners',
	Winner_2: 'Winners',
	Winner_plural: 'Winners',
	Year: 'Year',
	Year_0: 'Year',
	Year_1: 'Years',
	Year_2: 'Years',
	Year_plural: 'Years',
	CompetitionsList__BetCount: '{{count}} bet',
	CompetitionsList__BetCount_0: '{{count}} bet',
	CompetitionsList__BetCount_1: '{{count}} bets',
	CompetitionsList__BetCount_2: '{{count}} bets',
	CompetitionsList__BetCount_plural: '{{count}} bets',
	MarketGroupItem__NumberOfMarkets: '{{count}} market available',
	MarketGroupItem__NumberOfMarkets_0: '{{count}} market available',
	MarketGroupItem__NumberOfMarkets_1: '{{count}} markets available',
	MarketGroupItem__NumberOfMarkets_2: '{{count}} markets available',
	MarketGroupItem__NumberOfMarkets_plural: '{{count}} markets available',
	SetTimer__Hour: '{{count}} hour',
	SetTimer__Hour_0: '{{count}} hour',
	SetTimer__Hour_1: '{{count}} hours',
	SetTimer__Hour_2: '{{count}} hours',
	SetTimer__Hour_plural: '{{count}} hours',
	SetLimit__IncreaseLimit: '<0>Note:</0> You can increase limit after <2>{{count}}</2> day.',
	SetLimit__IncreaseLimit_0: '<0>Note:</0> You can increase limit after <2>{{count}}</2> day.',
	SetLimit__IncreaseLimit_1: '<0>Note:</0> You can increase limit after <2>{{count}}</2> days.',
	SetLimit__IncreaseLimit_2: '<0>Note:</0> You can increase limit after <2>{{count}}</2> days.',
	SetLimit__IncreaseLimit_plural: '<0>Note:</0> You can increase limit after <2>{{count}}</2> days.',
	Sports__Upcoming: 'Upcoming Matches',

	////////////////
	// Remaining translations that use the <Trans> component
	TakeABreakLong__Content: `
		<0>
			If you take a long break from gambling on this site, this is what happens next:
		</0>
		<1>
			All pending bets will stand.
		</1>
		<2>
			Standing balance will be automatically withdrawn to last active withdrawal method when pending bets are	resulted.
		</2>
		<3>
			You will cease to receive marketing communications within 24hours.
		</3>
		<4>
			You will be removed from all active Punting Clubs.
		</4>
		<5>
			You will need to contact Customer Services to re-activate your account after the requested duration is over.
		</5>
		<6>
			How long do you want a long break for?
		</6>
	`,
	TakeABreakShort__Content: `
		<0>
			If you take a short break from gambling on this site, this is what happens next:
		</0>
		<1>
			All pending bets will stand.
		</1>
		<2>
			Account balance remains unchanged. You can request a withdrawal by contacting Customer Services.
		</2>
		<3>
			You will cease to receive marketing communications within 24hours.
		</3>
		<4>
			You will be able to login to your account after the period of break is over.
		</4>
		<5>
			How long do you want a short break for?
		</5>
	`,
	TakeABreakConfirmation__Long: `
		<0>
			I hereby acknowledge:
		</0>
		<1>
			My pending bets will stand.
		</1>
		<2>
			My balance will automatically be withdrawn to my last withdrawal method when my pending bets are resulted.
		</2>
		<3>
			I will cease to receive marketing communications within 24 hours.
		</3>
		<4>
			This action will take effect immediately and cannot be undone.
		</4>
		<5>
			I will need to contact Customer Services to re-activate my account after the requested duration is over.
		</5>
	`,
	TakeABreakConfirmation__Notification: '<0>Note:</0> You will be logged out immediately.',
	TakeABreakConfirmation__Short: `
		<0>
			I hereby acknowledge:
		</0>
		<1>
			My pending bets will stand.
		</1>
		<2>
			My account balance will remain unchanged.
		</2>
		<3>
			I will cease to receive marketing communications within 24 hours.
		</3>
		<4>
			I will not be able to bet with my account.
		</4>
		<5>
			I will not be able to open any new accounts.
		</5>
		<6>
			This action will take effect immediately and cannot be undone.
		</6>
	`,
	SelfExclusionConfirmation__Content: `
		<0>
			I hereby acknowledge:
		</0>
		<1>
			My pending bets will stand.
		</1>
		<2>
			My balance will automatically be withdrawn to my last active withdrawal method when pending bets are resulted.
		</2>
		<3>
			I will not be able to bet with my account.
		</3>
		<4>
			I will not be able to open any new accounts.
		</4>
		<5>
			This action will take effect immediately and cannot be undone.
		</5>
		<6>
			Let us know why?
		</6>
	`,
	RacingDateSelection__Tomorrow: '<0>Tom</0><1>orrow</1><2>.</2>',
	RacingDateSelection__Yesterday: '<0>Yest</0><1>erday</1><2>.</2>',
	LoginForPromotions__LatestSpecials: 'To View the Latest Specials, <1>Sign Up</1> or <3>Login</3> to Your Account.',
	SetTimer__RealityCheck: '{{first_name}}, your current <2>Reality Check</2> is:',
	SelfExclusionPrompt__Notification:
		'<0>Note:</0> If you proceed with Self-Exclusion this action will be <4>Permanent</4>.',
	BankEFTDeposit__Notification:
		'<0>Note: </0>Please ensure your username (<2>{{username}}</2>) or {{brandName}} ID number (<6>{{userId}}</6>) is quoted on the deposit slip.',
	BPayDeposit__Notification:
		'<0>Note: </0>Contact your bank, credit union or building society to make this payment from your cheque, savings, debit or credit card account.',
	HowToPlayMessage__SignupMessage: '<0>PS. You need a</0> <2>{{brandName}} account</2> <4>to play</4>',
	HowToPlayTournaments__Point2:
		'<0>Once you enter, you will receive your <1>{{brandName}}</1>. You place bets with <3>{{brandName}}</3> to increase your chances of winning a tournament.</0>',
	PasswordResetRequest:
		"<0>No Worries</0><1>We all have days like this!</1><2>We'll get you back on track in no time.</2><3>Reset Your Password Easily</3><4>Enter your email (the one you used to register).</4><5></5><6>Please Send Reset Email</6>",
	SelfExclusionConfirmation__Notification: '<0>Note:</0> You will be logged out immediately.',
	CreditCardVerification__Example: 'If the deposit amount was $50.43 - enter 43.<1></1>If it was $50.07 - enter 7.',
	DefaultBetAmounts__BetAmountMessage: 'Your current <1>default bet amount</1> is:',
	DefaultBetAmounts__TournamentBetAmountMessage: 'And your default <1>tournament bet amount</1> is:',
	FirstDeposit__TermsAndConditions: 'Accept <1>terms and conditions</1>',
	MobileVerificationForm__Message:
		'Please enter the four digit verification code that has been sent to <1>{{mobNumber}}</1> so that we can validate your mobile number.',
	MobileVerificationForm__Terms: 'I agree to <1>Terms of Service</1> as published.',
	SetLimit__BlockAccess:
		'If you would like to block your access to the site entirely, you can <2>request self-exclusion</2>.',
	SimpleRegistration__TermsAndConditions: 'Agree to <1>terms and conditions</1>',
	VerifyCardExplanation__WholeDollars: 'This deposit must be in <1>whole dollars</1>.',
};
