import { post, get } from '../../common/Ajax';
import { mergeEntities } from '../../common/actions/actionHelpers';
import { setBodyLoading } from '../application/applicationActions';
import { logoutUser } from '../authentication/authenticationActions';
import { normalizeUsers } from '../entities/schemas/UserSchema';
import { trackGaEvent } from '../trackingPixels/trackingActions';

export const updatePassword = (oldPassword, newPassword, newPasswordConfirmation) => (dispatch, getState) => {
	const state = getState();
	const username = state.entities.users[state.application.authenticatedUser].username;

	const params = {
		password: oldPassword,
		new_password: newPassword,
		new_password_confirmation: newPasswordConfirmation,
	};

	return post('user/update-password', params)
		.then((response) => {
			dispatch(trackGaEvent('user', 'password:change', username));
			dispatch(mergeEntities(normalizeUsers(response.data.data).entities));
			return response;
		})
		.catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			throw error.response.data.errors;
		});
};


export const deactivateAccount = (password) => (dispatch, getState) => {
	const state = getState();
	const username = state.entities.users[state.application.authenticatedUser].username;

	const params = {
		password,
	};
	// dispatch(setBodyLoading(true));
	return post('user/account-toggle', params)
		.then((response) => {
			dispatch(trackGaEvent('user', 'account:deactivate', username));
			dispatch(logoutUser());
			return response;
		})
		.catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			throw error.response.data.errors;
		})
		.finally(() => {
			// dispatch(setBodyLoading(false));
		});
};

export const verifyEmail = async () => async (dispatch, getState) => {
	const state = getState();
	const user_id = state.application.authenticatedUser;
	dispatch(setBodyLoading(true));
	let data, status;
	try {
		const response = await get(`registration/resend-welcome-email/${user_id}`);
		data = response.data, status = 'success';

	} catch (error) {
		document.Sentry && document.Sentry.captureException(error);
		data = '', status = 'error';
	}

	dispatch(setBodyLoading(false));
	return { data, status };
};

global.updatePassword = updatePassword;
