import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces, Trans } from 'react-i18next';

import { spacings, Button, FlexText, Text, LoadingMask, Image, Notification } from '@tbh/ui-kit';

// Styling
const StyledBankEFTDeposit = styled('div')(
	(props) => css`
		label: BankEFTDeposit;

		padding: ${spacings(props).cozy}px;
	`,
);

const StyledBankEFTDeposit__DetailsSection = styled('div')(
	(props) => css`
		label: BankEFTDeposit__DetailsSection;

		display: flex;
		justify-content: space-between;
		align-items: end;
		margin-bottom: ${spacings(props).cozy}px;
		position: relative;
	`,
);

const StyledBankEFTDeposit__Notification = styled(Notification)(
	(props) => css`
		label: BankEFTDeposit__Notification;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const BankEFTDeposit = (props) => {
	const { t, className, username, userId, bankName, bsb, accountNumber, logo, onGotItClick, brandName } = props;

	const componentClasses = cx({
		[className]: className,
	});

	const loading = !bankName || !bsb || !accountNumber;

	return (
		<StyledBankEFTDeposit className={componentClasses}>
			<Text paragraph strong>
				{t('DepositFromBankAccount')}
			</Text>

			<StyledBankEFTDeposit__Notification>
				<Text size="-2">
					<Trans i18nKey="BankEFTDeposit__Notification" values={{ username, brandName, userId }}>
						<strong>Note: </strong>
						Please ensure your username (<strong>{{ username }}</strong>) or {{ brandName }} ID number (
						<strong>{{ userId }}</strong>) is quoted on the deposit slip.
					</Trans>
				</Text>
			</StyledBankEFTDeposit__Notification>

			<StyledBankEFTDeposit__DetailsSection>
				<LoadingMask loading={loading} solid />

				<div
					className={css`
						flex: 1;
					`}
				>
					<Text paragraph strong>
						{t('BankDepositInformation')}
					</Text>
					<Text>{bankName}</Text>

					<FlexText>
						<Text size="-2">
							<strong>{`${t('BSB')}: `}</strong>
						</Text>
						<Text size="-2">{bsb}</Text>
					</FlexText>

					<FlexText>
						<Text size="-2">
							<strong>{`${t('AccountNumber')}: `}</strong>
						</Text>
						<Text size="-2">{accountNumber}</Text>
					</FlexText>
				</div>
				{logo && (
					<Image
						className={css`
							max-width: 75px;
						`}
						src={logo}
						alt="NAB logo"
					/>
				)}
			</StyledBankEFTDeposit__DetailsSection>

			{onGotItClick && (
				<Button type="primary" block action={onGotItClick}>
					{t('GotItThanks')}
				</Button>
			)}
		</StyledBankEFTDeposit>
	);
};

BankEFTDeposit.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** On 'Got it..'click  */
	onGotItClick: PropTypes.func,

	/** User details to notify about deposit slip */
	username: PropTypes.string,
	userId: PropTypes.number,

	/** Bank EFT Data */
	bankName: PropTypes.string,
	bsb: PropTypes.string,
	accountNumber: PropTypes.string,

	/** URL for bank image */
	logo: PropTypes.string,

	/** Brand name */
	brandName: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,
};

BankEFTDeposit.defaultProps = {
	className: '',
	logo: null,
	username: undefined,
	userId: undefined,
	bankName: undefined,
	bsb: undefined,
	accountNumber: undefined,
	onGotItClick: undefined,
	brandName: '',
};

export default withNamespaces()(BankEFTDeposit);
