import { mergeEntities } from '../../../common/actions/actionHelpers';
import { normalizeMeta, denormalizeMeta } from '../schemas/MetaSchema';
import { getIosAppLink } from '../../../store/application/applicationActions';
const URI = require('urijs');

/**
 * Dispatch an action to normalize and merge meta entities
 *
 * @param meta
 */
export const normalizeAndMergeMeta = (meta = []) => (dispatch) => {
	const path = URI().path();

	meta.push(
		{
			tag: 'link',
			id: 'ios-app',
			name: 'ios-app',
			rel: 'alternate',
			href: getIosAppLink(),
		},
		{
			tag: 'link',
			id: 'canonical-meta',
			rel: 'canonical',
			href: window.location.origin + path,
		},
	);

	return dispatch(mergeEntities(normalizeMeta(meta).entities));
};

/**
 * Get the denormalized meta data
 *
 * @params state
 */
export const getDenormalizedMeta = (state) => {
	return denormalizeMeta(state.entities);
};
