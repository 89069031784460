// import {slugify} from '../legacy/core/format';

import { routeTo, scrollToTop, setBodyLoading } from '../store/application/applicationActions';
import {
	USER_ACCOUNT_TRANSACTIONS_URL,
	USER_ACCOUNT_V2_BASE_URL,
	USER_ACCOUNT_V2_SUB_APP,
	// USER_ACCOUNT_DEPOSIT_URL,
	// USER_ACCOUNT_DEPOSIT_LIMIT_URL,
	// USER_ACCOUNT_SETTINGS_URL,
	// USER_ACCOUNT_WITHDRAW_URL,
	// USER_ACCOUNT_BETS_TRANSACTIONS_URL
	// USER_ACCOUNT_TRANSACTIONS_URL,
} from '../pages/UserAccountV2/UserAccountPageConstants';
import {
	USER_ACCOUNT_ALL_TRANSACTIONS_URL,
	USER_ACCOUNT_BETS_TRANSACTIONS_URL,
	USER_ACCOUNT_TOURNAMENTS_TRANSACTIONS_URL,
	USER_ACCOUNT_BET_WINNING_TRANSACTIONS_URL,
	USER_ACCOUNT_BET_LOSING_TRANSACTIONS_URL,
	USER_ACCOUNT_BET_REFUNDED_TRANSACTIONS_URL,
	USER_ACCOUNT_DEPOSITS_TRANSACTIONS_URL,
	USER_ACCOUNT_WITHDRAWALS_TRANSACTIONS_URL,
} from '../pages/UserAccount/UserAccountPageConstants';
import { setUserAccountPageV2 } from '../store/userAccount/userAccountActions';
import { fetchAuthenticatedUser } from '../store/authentication/authenticationActions';
import { getAuthenticatedUser } from '../store/application/applicationSelectors';

const { dispatch, getState } = App.store;

const UserAccountRouter = Backbone.Router.extend({
	routes: {
		[USER_ACCOUNT_V2_BASE_URL]: 'showUserAccount',
		[USER_ACCOUNT_V2_BASE_URL + '/:subPage']: 'showUserAccount',
		[USER_ACCOUNT_V2_BASE_URL + '/:subPage/:filter']: 'showUserAccount',
	},

	showUserAccount: async function (subPage, filter) {

		scrollToTop();

		try{
			dispatch(setBodyLoading(true));
			dispatch(setUserAccountPageV2(subPage, currentFilter));
			App.startSubApp(USER_ACCOUNT_V2_SUB_APP);
			await dispatch(fetchAuthenticatedUser());
		}catch (e) {
			dispatch(routeTo('/welcome'));
		}
		if (!getAuthenticatedUser(getState())) {
			dispatch(routeTo('/welcome'));
			return;
		}
		const filters = [
			USER_ACCOUNT_ALL_TRANSACTIONS_URL,
			USER_ACCOUNT_BETS_TRANSACTIONS_URL,
			USER_ACCOUNT_TOURNAMENTS_TRANSACTIONS_URL,
			USER_ACCOUNT_BET_WINNING_TRANSACTIONS_URL,
			USER_ACCOUNT_BET_LOSING_TRANSACTIONS_URL,
			USER_ACCOUNT_BET_REFUNDED_TRANSACTIONS_URL,
			USER_ACCOUNT_DEPOSITS_TRANSACTIONS_URL,
			USER_ACCOUNT_WITHDRAWALS_TRANSACTIONS_URL,
		];

		const currentFilter = !filter || filters.indexOf(filter) === -1 ? USER_ACCOUNT_TRANSACTIONS_URL : filter;

		if (subPage === USER_ACCOUNT_TRANSACTIONS_URL) {
			dispatch(setUserAccountPageV2(subPage, currentFilter));
			App.store.dispatch(setBodyLoading(false));
			App.startSubApp(USER_ACCOUNT_V2_SUB_APP);
			return;
		}

		if (!subPage) {
			dispatch(setUserAccountPageV2(subPage, currentFilter));
			App.store.dispatch(setBodyLoading(false));
			App.startSubApp(USER_ACCOUNT_V2_SUB_APP);
		} else {
			dispatch(setUserAccountPageV2(subPage));
			App.store.dispatch(setBodyLoading(false));
			App.startSubApp(USER_ACCOUNT_V2_SUB_APP);
		}
	},


});

module.exports = new UserAccountRouter();
