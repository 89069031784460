import { OPEN_MODAL, CLOSE_MODAL, CLOSE_ALL_MODALS } from './modalActionsTypes';
import { replaceObjectInArraySlice } from '../../common/actions/actionHelpers';

/**
 * modals: [{
 *     id: uniqueModalId
 *     Component: ComponentName,
 *     config: { ...config },
 *     props: { ...props },
 * }]
 */
const initialState = {
	modals: [],
};

const modalReducer = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_MODAL: {
			return {
				...state,
				modals: replaceObjectInArraySlice(action.payload, state.modals),
			};
		}

		case CLOSE_MODAL:
			return {
				...state,
				modals: state.modals.filter((item) => item.id !== action.payload),
			};

		case CLOSE_ALL_MODALS:
			return initialState;

		default:
			return state;
	}
};

export default modalReducer;
