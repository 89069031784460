import React from 'react';
import styled, { css, cx } from 'react-emotion';
import RacesDropDown from './RacesDropDown';
import { withNamespaces } from 'react-i18next';
import { MOBILE_MAX_WIDTH } from '../../../../common/constants/Breakpoints';
import { brand, Text } from '@tbh/ui-kit';
import moment from 'moment/moment';
import { StatusBox } from '../MeetingsAndRacesTable/MeetingsAndRacesTable';

const RaceHeaderContainer = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-radius: 4px 4px 0px 0px;
		overflow: visible;
		min-height: 45px;
		background-color: ${brand(props).race_grey_color ? brand(props).race_grey_color : '#1C1F21'};
		padding: 12px;
		gap: 4px;
	`,
);

const HeaderText = styled('div')(
	(props) => css`
		font-size: 12px;
		color: white;
		white-space: nowrap;
	`,
);

const NewRaceHeaderBar = ({
	t,
	className,
	size,
	meetingType,
	meetingId,
	meetingName,
	meetingWeather,
	meetingTrack,
	meetingState,
	meetingCountry,
	raceName,
	raceClass,
	raceDistance,
	raceStartDate,
	raceStatus="",
	rail_position,
	raceNumber,
	CombineMetingGroups,
	HandleMeetingSelect,
	racePrize,
}) => {
	const componentClasses = cx({
		[className]: className,
	});
	/**
	 * Builds the country or state and distance display string
	 *
	 * @returns {string}
	 */
	const buildStateAndDistanceLabel = () => {
		let stateAndDistanceLabel = '';

		// Show the meeting state, if no state show country, if neither then don't show anything
		if (meetingState || meetingCountry) {
			stateAndDistanceLabel += `${meetingState || meetingCountry}`;
		}

		if (raceDistance) {
			stateAndDistanceLabel += ` | ${raceDistance}m`;
		}

		return stateAndDistanceLabel;
	};

	const meetingWeathers = {
		'MOSTLY SUNNY': {
			src: 'https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/sun.png',
			alt: 'Sun',
		},
		CLOUDS: {
			src: 'https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/cloud.png',
			alt: 'Cloud',
		},
		CLOUDY: {
			src: 'https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/cloud.png',
			alt: 'Cloud',
		},
		SNOW: {
			src: 'https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/snow.png',
			alt: 'Snow',
		},
		RAIN: {
			src: 'https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/rain.png',
			alt: 'Rain',
		},
		CLEAR: {
			src: 'https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/sun.png',
			alt: 'Cloud',
		},
		FINE: {
			src: 'https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/sun.png',
			alt: 'Cloud',
		},
	};

	const isMobileWidth = size.width < MOBILE_MAX_WIDTH + 120;

	return (
		<RaceHeaderContainer>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<RacesDropDown
					raceNumber={raceNumber}
					isMobileWidth={isMobileWidth}
					meetingType={meetingType}
					meetingId={meetingId}
					meetingName={meetingName}
				/>
				<StatusBox size="-3" align="center" strong status={raceStatus}>
					{raceStatus.replaceAll('_', ' ').toUpperCase()}
				</StatusBox>
				{/* <div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end',
					}}
				>
					{meetingWeather !== null && meetingWeathers[meetingWeather] && (
						<HeaderText>
							{`${t('AcronymWeather')}: `}
							<img
								src={meetingWeathers[meetingWeather].src}
								alt={meetingWeathers[meetingWeather].alt}
								className={css`
									width: 15px;
									height: 15px;
									margin-left: 3px;
								`}
							/>
						</HeaderText>
					)}
					{meetingTrack && <HeaderText>{`${t('Track')} : ${meetingTrack}`}</HeaderText>}
					{!isMobileWidth && rail_position !== null ? <HeaderText>{`${t('R/P')} ${rail_position}`}</HeaderText> : null}
				</div> */}
			</div>


			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'baseline',
				}}
			>
				<Text
					size={isMobileWidth ? '-1' : '0'}
					type="alternate"
					strong
					style={{
						whiteSpace: 'nowrap',
						maxWidth: isMobileWidth ? '80%' : '100%',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
				>
					
					{` ${raceName}`}.
					<Text size={isMobileWidth ? '-2' : '-2'} type="alternate">
						{`${buildStateAndDistanceLabel()} | ${raceClass} ${racePrize !== 0 && racePrize !== null && '| ' + racePrize}`}
					</Text>
					<Text size={isMobileWidth ? '-2' : '-2'} type="alternate">
						{!isMobileWidth && 'Race Time : '} {moment(raceStartDate).format('h:mm A')} |{' '}
						{!isMobileWidth && `${t('Race Date')} :`}{' '}
						{raceStartDate &&
							moment(raceStartDate)
								.format('DD-MM-YYYY')
								.split('T')[0]}
					</Text>
				</Text>

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end',
					}}
				>
					{meetingWeather !== null && meetingWeathers[meetingWeather] && (
						<HeaderText>
							{`${t('AcronymWeather')}: `}
							<img
								src={meetingWeathers[meetingWeather].src}
								alt={meetingWeathers[meetingWeather].alt}
								className={css`
									width: 15px;
									height: 15px;
									margin-left: 3px;
								`}
							/>
						</HeaderText>
					)}
					{meetingTrack && <HeaderText>{`${t('Track')} : ${meetingTrack}`}</HeaderText>}
					{!isMobileWidth && rail_position !== null ? <HeaderText>{`${t('R/P')} ${rail_position}`}</HeaderText> : null}
				</div>
				{/* <StatusBox size="-3" align="center" strong status={raceStatus}>
					{raceStatus.replaceAll('_', ' ').toUpperCase()}
				</StatusBox> */}
			</div>

			{/* <Text size={isMobileWidth ? '-2' : '-2'} type="alternate">
				{`${buildStateAndDistanceLabel()} | ${raceClass} ${racePrize !== 0 && racePrize !== null && '| ' + racePrize}`}
			</Text> */}

			{/* <Text size={isMobileWidth ? '-2' : '-2'} type="alternate">
				{!isMobileWidth && 'Race Time : '} {moment(raceStartDate).format('h:mm A')} |{' '}
				{!isMobileWidth && `${t('Race Date')} :`}{' '}
				{raceStartDate &&
					moment(raceStartDate)
						.format('DD-MM-YYYY')
						.split('T')[0]}
			</Text> */}

		</RaceHeaderContainer>
	);
};
export default withNamespaces()(NewRaceHeaderBar);
