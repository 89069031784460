/**
 * Receives a normalized slice of state and returns array sorted by the 'name' property
 *
 * @param normalizedStateSlice - normalized slice of state
 * @return {Array}
 */
export const sortStateSliceByName = (normalizedStateSlice) => {
	return Object.values(normalizedStateSlice).sort((a, b) => a.name.localeCompare(b.name));
};



/**
 * Receives normalized slice of state and returns array sorted by 'order' & then 'name' properties
 *   - higher values of 'order' property to be ordered first
 *   - allows for the 'order' property to be undefined
 *   - allows for the 'order' property to be null
 *   - allows for the 'order' property to be a string of numbers or a numeral ... ie. '100' or 100
 *
 * @param normalizedStateSlice - normalized slice of state
 * @returns {Array}
 */
export const sortStateSliceByOrderThenName = (normalizedStateSlice) => {
	return Object.values(normalizedStateSlice).sort((a, b) => {
		const orderComparison = b.order - a.order;

		// If the order compare is a legal number and either order is not null
		if (!Number.isNaN(orderComparison) && a.order && b.order) {
			return orderComparison;
		}

		// If only 'a' has a valid order property then order it first
		if (a.order > 0) {
			return -1;
		}

		// If only 'b' has a valid order property then order it first
		if (b.order > 0) {
			return 1;
		}

		// Otherwise if no order properties then sort by the name property
		return a.name.localeCompare(b.name);
	});
};
