/**
 * This function takes colors in hex format (with or without the hash) and lightens or darkens them with a value.
 * A positive number will lighten.
 * A negative number will darken.
 *
 * Source: https://css-tricks.com/snippets/javascript/lighten-darken-color/
 *
 * @param col
 * @param amt
 * @returns {*}
 * @constructor
 */
const LightenDarkenColor = (col, amt) => {
	let usePound = false;

	if (col[0] === 'r') {
		return col;
	} else if (col[0] === '#') {
		col = col.slice(1);
		usePound = true;
	}

	let num = parseInt(col, 16);

	let r = (num >> 16) + amt;

	if (r > 255) r = 255;
	else if (r < 0) r = 0;

	let b = ((num >> 8) & 0x00ff) + amt;

	if (b > 255) b = 255;
	else if (b < 0) b = 0;

	let g = (num & 0x0000ff) + amt;

	if (g > 255) g = 255;
	else if (g < 0) g = 0;

	/**
	 * In the original implementation there is a bug where sometimes an invalid HEX is produced.
	 * The fix is to pad the HEX code with leading zeros.
	 *
	 * Source: https://css-tricks.com/snippets/javascript/lighten-darken-color/#comment-593819
	 */
	return (usePound ? '#' : '') + String('000000' + (g | (b << 8) | (r << 16)).toString(16)).slice(-6);
};

export default LightenDarkenColor;
