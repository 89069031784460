import { createAction } from '../../common/actions/actionHelpers';
import { serverDateTime } from '../../legacy/core/format';
import moment from 'moment';
import { batchActions } from 'redux-batched-actions';
import { navigate, setBodyLoading, scrollToTop } from '../application/applicationActions';
import { 
    BETBUILDER_HOME_SET_CONTAINER_LOADING_MASK,
	GRS_SHOWING_NTJ_RACES,
	BETBUILDER_HOME_SET_SHOWING_NTJ_RACES,
	BETBUILDER_HOME_SET_SELECTED_MEETING,
	BETBUILDER_HOME_SET_FILTERS_BATCH,
	BETBUILDER_HOME_SET_SHOWING_MEETINGS,
	BETBUILDER_HOME_RESET_FILTERS,
	BETBUILDER_HOME_DONE__BATCH,

	BETBUILDER_HOME_SET_BET_BUILDER_MEETING,
	BETBUILDER_HOM_SET_SELECTED_DATE,
	BETBUILDER_HOME_SET_RACE_LOADING_MASK
} from '../betBuilder/betBuilderHomeActionType';

import {
	fetchBetBulderMeetingWithRaceSelections,
	fetchMeetingsAndRaces,
} from '../entities/actions/MeetingActions';

import { SELECTED_DATE_FORMAT } from '../../pages/BetBuilder/BetBuilderConstants';

/**
 * Fetch data for selected meeting
 *
 * @param meetingId
 * @param setLoadingMask
 * @returns {function(*)}
 */
export const loadBetBuilderMeetingData = (meetingId, setLoadingMask = false) => (dispatch) => {
	if (setLoadingMask) {
		dispatch(setBodyLoading(true));
	}

	return dispatch(fetchBetBulderMeetingWithRaceSelections(meetingId))
		.then((response) => {
			//console.log(response.data.data);
			// const meeting = .find((betBuilderMeeting) => betBuilderMeeting.id == meetingId);
			// if (meeting) {
			// 	return dispatch(setBetBuilderHomeMeetingFilters(meetingId));
			// }
			
			// dispatch(
			// 	batchActions(
			// 		[setBetBuilderHomeMeetingFilters(meetingId), 
			// 			setShowingMeetings(response.meetingIds),	
			// 			//setMeetingLoadingMask(false)
			// 		],
			// 	),
			// );


			//const sport = response.data.data.find((sport) => slugify(sport.name) === sportName);
			// if (sport) {
			// 	let baseCompetitions = sport && sport.base_competitions;
			// 	let firstCompID;
			// 	if (baseCompetitions instanceof Array && baseCompetitions.length > 0) {
			// 		baseCompetitions.sort((bcA, bcB) => bcA.order - bcB.order);

			// 		if (baseCompetitions[0].competitions instanceof Array && baseCompetitions[0].competitions.length > 0) {
			// 			firstCompID = baseCompetitions[0].competitions[0].id;
			// 		} else {
			// 			//In case there is base_competition but no competition.
			// 			return dispatch(setSportsHomeFilters(sport.id, sport.name, baseCompetitions[0].id));
			// 		}
			// 	} else {
			// 		//In case there is no base_competition
			// 		return dispatch(setSportsHomeFilters(sport.id, sport.name));
			// 	}

			// 	return dispatch(loadCompetitionData(firstCompID, sportName));
			// }
		})
		.finally(() => dispatch(setBodyLoading(false)));
};

/**
 * Navigates to the  betbuilder
 * @HW make by hushani
 */
export const navigateToBetbuilderHome = () => (dispatch) => {
	return dispatch(navigate('/betbuilder', { trigger: true }));
};

/**'
 * @param meetingId
 */
export const navigateToBetBuilderMeeting = (meetingId) => (dispatch) => {
	scrollToTop();
	dispatch(setBodyLoading(true), resetRacingFilters(meetingId));
	return dispatch(fetchBetBulderMeetingWithRaceSelections(meetingId)).then((response) => {
		dispatch(
			batchActions(
				[resetRacingFilters(response.data.selected_race, meetingId), setBodyLoading(false)],
				BETBUILDER_HOME_DONE__BATCH,
			),
		);
	});
};



export const setContainerLoadingMask = (isLoading) => createAction(BETBUILDER_HOME_SET_CONTAINER_LOADING_MASK, isLoading);
const setRaceLoadingMask = (isLoading) => createAction(BETBUILDER_HOME_SET_RACE_LOADING_MASK, isLoading);
/**
 * Set in state list of showing Next to Jump Races. This is needed
 * so we keep the entities in the right section and the selection
 * for the component declared in the racing slice oh the state.
 * 
 * set GRS NJ races - http://tst.api.grs.bet/api/v3/racing/next-to-jump-grs
 *
 * @return {Object}
 * @param ntjRaceIds
 */
export const setShowingNTJGRSRaces = (ntjGRSRaceIds = []) => {
	return createAction(GRS_SHOWING_NTJ_RACES, ntjGRSRaceIds);
};

/**
 * Set in state list of showing Next to Jump Races. This is needed
 * so we keep the entities in the right section and the selection
 * for the component declared in the racing slice oh the state.
 *
 * @return {Object}
 * @param ntjRaceIds
 */
export const setShowingNTJRaces = (ntjRaceIds = []) => {
	return createAction(BETBUILDER_HOME_SET_SHOWING_NTJ_RACES, ntjRaceIds);
};


/**
 * Set selected meeting id in state.
 *
 * @param {number} sportId
 * @param meetingName
 * @returns {Object}
 */
export const setSelectedMeeting = (meetingId = null) => {
	return createAction(BETBUILDER_HOME_SET_SELECTED_MEETING, {
		id: meetingId,
	});
};


/**
 *
 * @param {Array} meetingIds set in racing slice
 * @return {Object}
 */
export const setShowingMeetings = (meetingIds = []) => {
	return createAction(BETBUILDER_HOME_SET_SHOWING_MEETINGS, meetingIds);
};

/**
 *  Set state based on fetched data.
 *
 * @param {number} meetingId
 * @param meetingName
 */
export const setBetBuilderHomeMeetingFilters = (
	meetingId = null,
) => (dispatch) => {
	let actions = [];
	actions.push(setSelectedMeeting(meetingId));

	dispatch(batchActions(actions, BETBUILDER_HOME_SET_FILTERS_BATCH));
};


/**
 * Reset racing slice of the state. blank
 *
 * @param {number} meetingId
 */
export const resetRacingFilters = (meetingId) => {
	return createAction(BETBUILDER_HOME_RESET_FILTERS, null, {meetingId });
};

/**
 * action for getting the bet build object from bet builder meetings
 * @param {*} selectedBuilderMeeting 
 */
export const setSelectedBuilderMeeting = (meetingId) => {
	return createAction(BETBUILDER_HOME_SET_BET_BUILDER_MEETING, meetingId);
};



/**
 * Action to set chosen date. The date will be added with user's timezone.
 *
 * @param {string|moment} date YYYY-DD-MM
 */
export const setSelectedDate = (date) => {
	let selectedDate = moment(date);
	return createAction(BETBUILDER_HOM_SET_SELECTED_DATE, date && selectedDate.format(SELECTED_DATE_FORMAT));
};


/**
 * Build moment object from stored data
 * @param state
 * @return {null|moment}
 */
export const getSelectedDate = (state) => {
	if (state.betBuilderHome && state.betBuilderHome.selectedDate) {
		return moment.parseZone(state.betBuilderHome.selectedDate, SELECTED_DATE_FORMAT);
	} else {
		return undefined;
	}
};

/**
 * Show loading mask, fetch features meetings and clear filters.
 * @param {*} newDate
 */
export const navigateToBetBuilderWithDate = (newDate) => (dispatch, getState) => {
	scrollToTop();
	const state = getState();
	let serverDate;
	let setLoadingMask;
	if (newDate) {
		setLoadingMask = setContainerLoadingMask;
		serverDate = serverDateTime(newDate).format('YYYY-MM-DD');
	} else {
		setLoadingMask = setRaceLoadingMask;
		serverDate = getSelectedDate(state) ? serverDateTime(moment(getSelectedDate(state))).format('YYYY-MM-DD') : null;
	}
	
	dispatch(setBodyLoading(true), setContainerLoadingMask(true), setSelectedDate(serverDate), resetRacingFilters(null), setSelectedBuilderMeeting(null));
	return dispatch(fetchMeetingsAndRaces(serverDate)).then((response) => {
		dispatch(
			batchActions(
				[setShowingMeetings(response.meetingIds),  setContainerLoadingMask(false), setBodyLoading(false),setSelectedDate(serverDate),resetRacingFilters(null), setSelectedBuilderMeeting(null)],
				BETBUILDER_HOME_DONE__BATCH,
			),
		);
	});
	
};

/**
 * Show loading mask, fetch features meetings and clear filters.
 * @param {*} newDate
 */
export const navigateToBetBuilderWithMeetingId = (newDate, meetingId) => (dispatch, getState) => {
	scrollToTop();
	const state = getState();
	let serverDate;
	let setLoadingMask;
	if (newDate) {
		setLoadingMask = setContainerLoadingMask;
		serverDate = serverDateTime(newDate).format('YYYY-MM-DD');
	} else {
		setLoadingMask = setRaceLoadingMask;
		serverDate = getSelectedDate(state) ? serverDateTime(moment(getSelectedDate(state))).format('YYYY-MM-DD') : null;
	}
	
	meetingId = Number.parseInt(meetingId);
	dispatch(setBodyLoading(true), setContainerLoadingMask(true), setSelectedDate(serverDate), resetRacingFilters(meetingId), setSelectedBuilderMeeting(meetingId));

	

	

	// return Promise.all([
	// 	dispatch(fetchMeetingsAndRaces(serverDate)),
	// 	dispatch(fetchBetBulderMeetingWithRaceSelections(meetingId)), 
	// ]).then(() => {
	// 	dispatch(
	// 		batchActions([
	// 			//setShowingMeetings(response.meetingIds), 
	// 			setSelectedBuilderMeeting(meetingId),
	// 			setSelectedDate(serverDate),
	// 			],
	// 			BETBUILDER_HOME_DONE__BATCH
	// 			)
	// 			);
	// });
		

	return dispatch(fetchBetBulderMeetingWithRaceSelections(meetingId)).then((response) => {
		dispatch(
			batchActions(
				[ setSelectedBuilderMeeting(response.data.data.id), resetRacingFilters(meetingId),setBodyLoading(false),setSelectedDate(serverDate)],
				BETBUILDER_HOME_DONE__BATCH,
			),
		);
	});
	
};