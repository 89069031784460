import { APPLY_THEME } from './themeActionsTypes';
import { createAction } from '../../common/actions/actionHelpers';
import { openModal } from '../modal/modalActions';

/**
 * Opens the Theme Picker screen
 */
export const openThemePicker = () => (dispatch) => {
	return dispatch(
		openModal({
			id: 'ThemeManagerContainer',
			Component: 'ThemeManagerContainer',
			config: {
				wide: true,
			},
		}),
	);
};

/**
 * Updates the Theme
 *
 * @param newTheme {Object}
 * @returns {Object}
 */
export const updateTheme = (newTheme) => {
	return createAction(APPLY_THEME, newTheme);
};
