import React from 'react';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { spacings, media, brand } from '@tbh/ui-kit';
import {
	FlexText,
	HeaderBar,
	PlotElements,
	StatCell,
	//	Select,
	//	SelectOption
} from '@tbh/ui-kit';
import { StyledNJRaceHeaderBar__Metting__NameLoading } from './../../Carousel/UpCarouselItem/UpCarouselItem.styled';
const StyledRaceHeaderBar = styled(HeaderBar)`
	${(props) => css`
		min-height: 45px;
		border-radius: 4px 4px 0 0;
		background-color: ${brand(props).race_grey_color ? brand(props).race_grey_color : '#1C1F21'};
	`};
`;

const StyledRaceHeaderBar__Heading = styled(FlexText)`
	${(props) => css`
		label: RaceHeaderBar__Heading;

		padding-top: ${spacings(props).compact}px;
		padding-bottom: ${spacings(props).compact}px;
	`};
`;

const StyledRaceHeaderBar__WeatherAndStatus = styled(PlotElements)`
	flex: 0 0 65px;

	${media(
		css`
			flex: 0 0 145px;
		`,
		375,
	)};
`;

const StyledRaceHeaderBar__Status = styled(StatCell)`
	${(props) => css`
		label: RaceHeaderBar__Status;
		flex-direction: row;
		max-width: 120px;
		margin-left: ${spacings(props).compact}px;

		@media (max-width: 440px) {
			flex-direction: column;
		}

		@media (max-width: 390px) {
			flex-direction: row;
		}
	`};
`;

const StyledRaceHeaderBar__RaceNumber = styled('div')`
	${(props) => css`
		label: RaceHeaderBar__RaceNumbe;

		background-color: ${brand(props).rch_4};
		display: inline-block;

		padding: ${spacings(props).compact}px;
		font-size: 30px;
		font-weight: 600;

		text-align: center;
	`};
`;
export const StyledLoading = styled('div')`
	${(props) => css`
		width: 100%;
		height: ${props.height};
		margin-bottom: 5px;
		margin: 10px 15px;
		border-radius: 4px;
		background-color: rgb(54, 69, 79);
		animation: changeColor 1s infinite;
		@keyframes changeColor {
			0% {
				background-color: rgba(54, 69, 79, 0.2);
			}
			25% {
				background-color: rgba(54, 69, 79, 0.6);
			}
			50% {
				background-color: rgba(54, 69, 79, 0.8);
			}
			75% {
				background-color: rgba(54, 69, 79, 0.6);
			}
			100% {
				background-color: rgba(54, 69, 79, 0.4);
			}
		}
	`};
`;

const RaceHeaderBarLoading = (props) => {
	const { className, size } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<StyledRaceHeaderBar
			fluid
			noPadding
			className={componentClasses}
			type="primary"
			nodeLeft={
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<StyledRaceHeaderBar__RaceNumber>{0}</StyledRaceHeaderBar__RaceNumber>
				</div>
			}
			margin={false}
		>
			<StyledRaceHeaderBar__Heading>
				<PlotElements
					align="space-between"
					alignElements="middle"
					className={css`
						overflow: hidden;
						display: flex;
						flex-direction: column;
						align-items: baseline;
						padding: 8px;
					`}
				>
					<StyledNJRaceHeaderBar__Metting__NameLoading width="150px" style={{ borderRadius: '4px' }} />
					<StyledNJRaceHeaderBar__Metting__NameLoading width="170px" style={{ borderRadius: '4px' }} />
					<StyledNJRaceHeaderBar__Metting__NameLoading width="60px" style={{ borderRadius: '4px' }} />
					<StyledNJRaceHeaderBar__Metting__NameLoading width="80px" style={{ borderRadius: '4px' }} />
				</PlotElements>
			</StyledRaceHeaderBar__Heading>
		</StyledRaceHeaderBar>
	);
};

export default withNamespaces()(RaceHeaderBarLoading);
