// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { Trans, withNamespaces } from 'react-i18next';

import { spacings } from '@tbh/ui-kit';

// Components
import { Button, ButtonGroup, Panel, Notification, Select, SelectOption, Text, Textarea } from '@tbh/ui-kit';

const StyledSelfExclusionConfirmation__Options = styled(ButtonGroup)(
	(props) => css`
		label: SelfExclusionConfirmation__Options;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const StyledSelfExclusionConfirmation = styled('div')(
	(props) => css`
	label: ExclusionConfirmation;

`,

);

const SelfExclusionConfirmation = (props) => {
	const { onChange, onCancel, onConfirm, reason, otherReason, onTextareaChange, error, clearError, loading, t } = props;

	const handleCancel = () => {
		clearError();
		onCancel();
	};

	const handleConfirm = () => {
		clearError();
		onConfirm();
	};

	return (
		<StyledSelfExclusionConfirmation>
			{error && (
				<Notification  strong type={Notification.types.COLOUR_DANGER} message={error} buttonAction={clearError} />
			)}

			<Panel padding={Panel.padding.SPACING_COZY}>
				<Trans i18nKey="SelfExclusionConfirmation__Content">
					<Text size="-1" paragraph strong>
						I hereby acknowledge:
					</Text>

					<Text size="-1" paragraph>
						My pending bets will stand.
					</Text>
					<Text size="-1" paragraph>
						My balance will automatically be withdrawn to my last active withdrawal method when pending bets are
						resulted.
					</Text>
					<Text size="-1" paragraph>
						I will not be able to bet with my account.
					</Text>
					<Text size="-1" paragraph>
						I will not be able to open any new accounts.
					</Text>
					<Text size="-1" paragraph>
						This action will take effect immediately and cannot be undone.
					</Text>

					<Text size="-1" paragraph strong>
						Let us know why?
					</Text>
				</Trans>

				<Select name="reason" onChange={onChange} margin="cozy">
					<SelectOption value={t('SelfExclusionConfirmation__GamblingProblem')}>
						{t('SelfExclusionConfirmation__GamblingProblem')}
					</SelectOption>
					<SelectOption value={t('SelfExclusionConfirmation__DontLike')}>
						{t('SelfExclusionConfirmation__DontLike')}
					</SelectOption>
					<SelectOption value={t('SelfExclusionConfirmation__Others')}>
						{t('SelfExclusionConfirmation__Others')}
					</SelectOption>
				</Select>

				{reason === 'Others' && (
					<Textarea
						name="otherReason"
						placeholder={t('SelfExclusionConfirmation__InsertComment')}
						onChange={onTextareaChange}
						value={otherReason}
						margin="cozy"
					/>
				)}

				<StyledSelfExclusionConfirmation__Options center>
					<Button type={Button.types.GHOST} action={handleCancel} flex>
						{t('SelfExclusionConfirmation__ChangedMind')}
					</Button>

					<Button action={handleConfirm} type={Button.types.DANGER} loading={loading} flex>
						{t('Confirm')}
					</Button>
				</StyledSelfExclusionConfirmation__Options>

				<Notification type={Notification.types.COLOUR_INFO} transparent>
					<Text type="info" size="-1">
						<Trans i18nKey="SelfExclusionConfirmation__Notification">
							<strong>Note:</strong> You will be logged out immediately.
						</Trans>
					</Text>
				</Notification>
			</Panel>
		</StyledSelfExclusionConfirmation>
	);
};

SelfExclusionConfirmation.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Hook for when the selected or input values change */
	onChange: PropTypes.func.isRequired,

	/** Action to fire when the Other Reason textarea is changed */
	onTextareaChange: PropTypes.func.isRequired,

	/** Cancel and return to self exclusion prompt screen */
	onCancel: PropTypes.func.isRequired,

	/** Hook for confirming self exclusion and blocking user */
	onConfirm: PropTypes.func.isRequired,

	/** Function to clear the error message */
	clearError: PropTypes.func.isRequired,

	/** Value for the Textarea */
	otherReason: PropTypes.string,

	/** Reason for self exclusion */
	reason: PropTypes.string,

	/** Error message for when the confirmation fails */
	error: PropTypes.string,

	/** If the request is loading or not */
	loading: PropTypes.bool,
};

SelfExclusionConfirmation.defaultProps = {
	otherReason: '',
	reason: false,
	error: null,
	loading: false,
};

export default withNamespaces()(SelfExclusionConfirmation);
