import React, { useEffect, useState } from 'react';

import styled, { css } from 'react-emotion';
import RacingIcon from '../../components/features/Racing/RacingIcon/RacingIcon';
import { ArrowIcon, Text, Ticker } from '@tbh/ui-kit';
import { countdown, serverDateTime } from '../../legacy/core/format';
import { connect } from 'react-redux';
import { SizeMe } from 'react-sizeme';
import moment from 'moment';
import { PageComponentContainer } from '../../components/features/Application/PageComponentContainer/PageComponentContainer';
import { fetchNextToJumpRaces_GRS, nextNtjRace } from '../../store/entities/actions/NextToJumpRaceActions';
import { navigateToRaceWithType } from '../../store/GRSracingHome/GRSracingHomeActions';
import { getNextToJumpRaces } from '../Racing/RacingHome/racingSelectorsGRS';
import { Skeleton } from '../../components/UI/Skeleton';
import {
	RaceStatusEventById,
	RACING_GREYHOUNDS_CODE,
	RACING_HARNESS_CODE,
	RACING_THOROUGHBRED_CODE,
} from '../../common/constants/Racing';
import RaceNextJumpFilter from '../../components/features/Racing/RaceNextJumpV3/RaceNextJumpFilter/RaceNextJumpFilter';
import { routeTo } from '../../store/application/applicationActions';

const StyledNextToJumpItem = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		background-color: #fafafa;
		padding: 4px;
		gap: 8px;
		width: 100%;
		&:active {
			background-color: #f0f0f0;
		}
		&:not(:last-child) {
			border-bottom: 1px solid #666666;
		}

		&:last-child {
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	`,
);

const NextToJumpItem = ({
	id,
	race_number,
	meeting_name,
	distance,
	type,
	country,
	start_datetime,
	event_status_id,
	onClick,
}) => {
	const status = RaceStatusEventById(event_status_id);

	const lessThanOneMinute = moment(start_datetime).diff(serverDateTime(moment()), 'seconds') < 60;
	return (
		<StyledNextToJumpItem key={id} onClick={onClick}>
			<RacingIcon type={type} color="black" />
			<div
				className={css`
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					width: 100%;
				`}
			>
				<div
					className={css`
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						align-items: flex-start;
					`}
				>
					<Text size="-3">
						R{race_number} - {meeting_name}
					</Text>
				</div>
				<Text
					size="-3"
					strong
					uppercase
					style={{
						color: lessThanOneMinute ? '#850000' : 'black',
					}}
				>
					{status === 'selling' ? countdown(serverDateTime(moment(start_datetime))) : status}{' '}
					<ArrowIcon direction={'right'} size="-2" />
				</Text>
			</div>
		</StyledNextToJumpItem>
	);
};

const NextToJumpItemLoading = () => {
	return (
		<StyledNextToJumpItem>
			<Skeleton width="24px" height="24px" black={true} />
			<div
				className={css`
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					width: 100%;
				`}
			>
				<div
					className={css`
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						align-items: flex-start;
					`}
				>
					<Skeleton width="80px" height="16px" black={true} />
				</div>
				<Skeleton width="64px" height="16px" black={true} />
			</div>
		</StyledNextToJumpItem>
	);
};

const StyledHeader = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		padding: 12px;
		background-color: #161a1d;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		width: 100%;
	`,
);

const StyledNextToJumpContainer = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		height: 100%;
		border: 1px solid #666666;
		width: 100%;
		background-color: #fff;
		gap: 8px;
		border-radius: 8px;
		padding: 12px;
	`,
);

const NextToJump = ({ items, navigateToRaceWithType }) => {
	const [raceTypeFilter, setRaceTypeFilter] = useState(RACING_THOROUGHBRED_CODE); // Default to one type

	const handleFilterItemClick = (type) => {
		setRaceTypeFilter(type); // Allow only one filter to be active
	};

	const filteredItems = items.filter((item) => item.type === raceTypeFilter);

	return (
		<PageComponentContainer>
			<SizeMe noPlaceholder>
				{({ size }) => (
					<React.Fragment>
						<StyledNextToJumpContainer>
							<RaceNextJumpFilter
								onFilterItemClick={handleFilterItemClick}
								types={[RACING_THOROUGHBRED_CODE, RACING_HARNESS_CODE, RACING_GREYHOUNDS_CODE]}
								size={size}
								setSelectedType={setRaceTypeFilter}
								selectedType={raceTypeFilter ? raceTypeFilter : RACING_THOROUGHBRED_CODE}
								showAllButton={false}
							/>
							<StyledHeader>
								<Text size="-1" strong type="alternate" transform="uppercase">
									Next to Jump Races
								</Text>
							</StyledHeader>
							{filteredItems.length === 0 && (
								<Text size="-3" style={{ padding: '10px' }}>
									No Races Available
								</Text>
							)}
							{filteredItems.map((item) => (
								<Ticker every={10000} key={item.id}>
									<NextToJumpItem
										{...item}
										onClick={() =>
											navigateToRaceWithType(
												item.type,
												item.start_datetime,
												item.id,
												item.meeting_id,
												item.meeting_name,
											)
										}
									/>
								</Ticker>
							))}
						</StyledNextToJumpContainer>
					</React.Fragment>
				)}
			</SizeMe>
		</PageComponentContainer>
	);
};

const mapStateToProps = (state) => {
	const authenticatedUser = state.application.authenticatedUser;
	const user = state.entities.users[authenticatedUser] || {};

	const settings = user.settings || {};
	return {
		items: getNextToJumpRaces(state),
		raceType: state.grsRacingHome.selectedType,
		raceDate: state.grsRacingHome.selectedDate,
		selectedPage: state.application.selectedPage,
		showN2J: settings.showN2J != undefined ? settings.showN2J : true,
	};
};

const mapDispatchToProps = (dispatch) => ({
	navigateToRaceWithType: (type, date, raceId, meetingId, meetingName, raceNumber) => {
		dispatch(navigateToRaceWithType(type, date, raceId, meetingId));
		App.startSubApp('RacingNewPage');
	},
	goToHome: () => {
		return dispatch(routeTo('/welcome')); // change by @HW 21NOV2019
	},
	updateNextToRaces: (races) => dispatch(nextNtjRace(races)),
	fetchNextToJumpRaces: () => {
		dispatch(fetchNextToJumpRaces_GRS());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(NextToJump);
