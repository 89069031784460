import { theme } from '@tbh/ui-kit';
import { APPLY_THEME } from './themeActionsTypes';

const initialState = { ...theme };
function getRandomInt(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}
const rainbow = () => {
	var r = getRandomInt(0, 255);
	var g = getRandomInt(0, 255);
	var b = getRandomInt(0, 255);
	return 'rgb(' + r + ',' + g + ',' + b + ')';
};

/**
 * Favourites reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const themeReducer = (state = initialState, action) => {
	const newTheme = { ...state };
	switch (action.type) {
		case 'PIZZAZZ':
			return {
				...state,
				brand: {
					...state.brand,
					color_1: rainbow(),
					color_2: rainbow(),
					color_3: rainbow(),
					color_4: rainbow(),
				},
			};

		case 'LOTTOLAND':
			return {
				...state,
				brand: {
					...state.brand,
					color_1: '#000000',
					color_1_light: '#000000',
					color_1_dark: '#000000',
					color_2: '#000000',
					color_2_light: '#000000',
					color_2_dark: '#000000',
          selling_color: '#000000',
				},
			};

		case APPLY_THEME:
			Object.keys(action.payload).forEach((key) => {
				newTheme[key] = {
					...state[key],
					...action.payload[key],
				};
			});

			return newTheme;

		default:
			return state;
	}
};

export default themeReducer;
