import React from 'react';
import PropTypes from 'prop-types';
import Meta from './Meta';

const DescriptionMeta = ({ children }) => {
	return <Meta id="description" content={children} />;
};

DescriptionMeta.propTypes = {
	children: PropTypes.string.isRequired,
};

export default DescriptionMeta;
