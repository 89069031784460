import debounce from 'lodash-es/debounce';

const CircularJSON = require('circular-json');
const REDUCER_LOCAL_STORAGE_KEY = 'tb_reducer_storage';

/**
 * Get reducer stored locally
 *
 * @returns {{}}
 */
const getReducerStoredLocally = () => {
	let reducerStorage = {};
	try {
		const storageItem = localStorage.getItem(REDUCER_LOCAL_STORAGE_KEY);
		reducerStorage = CircularJSON.parse(storageItem) || {};
	} catch (e) {
		console.error(e);
	}

	return reducerStorage;
};

/**
 * Save reducer slice to local storage
 *
 * @param key
 * @param results
 * @param reAttempt
 */
const saveToLocalStorage = (key, results, reAttempt = false) => {
	let storage = getReducerStoredLocally();
	storage[key] = results;
	try {
		localStorage.setItem(REDUCER_LOCAL_STORAGE_KEY, CircularJSON.stringify(storage));
	} catch (e) {
		/*
		 * Clear the key if we have failed to set local storage.
		 * Only attempt this once to avoid infinite loops.
		 */
		if (!reAttempt) {
			saveToLocalStorage(key, {}, true);
		}

		console.error(e);
	}
};

/**
 * Create a reducer that stores its results to local storage
 *
 * @param key
 * @param reducer
 */
const localStorageReducer = (key, reducer) => {
	// Get the default state of the reducer. This needs to happen because we may change the structure of the local storage item,
	// And when it gets pre-loaded, it will not have all of the properties
	let defaultState = reducer(undefined, {});

	return (s, action) => {
		let state = {
			...defaultState,
			...s,
		};

		let results = reducer(state, action);
		if (state && state === results) {
			return results;
		}

		if (state && state !== results) {
			debounce(() => saveToLocalStorage(key, results), 500);
		}
		return results;
	};
};

/**
 * Exports
 */
module.exports = {
	REDUCER_LOCAL_STORAGE_KEY,
	getReducerStoredLocally,
	saveToLocalStorage,
	localStorageReducer,
};
