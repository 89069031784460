// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

// Components
import { Select } from '@tbh/ui-kit';
import { SelectOption } from '@tbh/ui-kit';

const SelectState = (props) => {
	const { onChange, value, t } = props;

	return (
		<Select name="state" label={t('Profile__State')} onChange={onChange} value={value} margin="cozy">
			<SelectOption value="NSW">New South Wales</SelectOption>
			<SelectOption value="ACT">Australian Capital Territory</SelectOption>
			<SelectOption value="NT">Northern Territory</SelectOption>
			<SelectOption value="QLD">Queensland</SelectOption>
			<SelectOption value="SA">South Australia</SelectOption>
			<SelectOption value="TAS">Tasmania</SelectOption>
			<SelectOption value="VIC">Victoria</SelectOption>
			<SelectOption value="WA">Western Australia</SelectOption>
			{/* <SelectOption value="Outside of Australia">{t('UserAddressForm__Outside')}</SelectOption> */}
		</Select>
	);
};

SelectState.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Action for changing the selected state */
	onChange: PropTypes.func.isRequired,

	/** The selected state */
	value: PropTypes.string,
};

SelectState.defaultProps = {
	value: '',
};

export default withNamespaces()(SelectState);
