// Libraries
import { denormalize, normalize } from 'normalizr';
import { nextToJumpSportsSchema } from './Entities';

/**
 * Denormalizes nextToJumpSports.
 *
 * @param entities
 * @param keys
 * @returns {any}
 */
export const denormalizeNextToJumpSports = (entities, keys) => {
	return denormalize(keys || Object.keys(entities.nextToJumpSports), nextToJumpSportsSchema, entities);
};

/**
 * Normalizes nextToJumpSports.
 *
 * @param response
 * @returns {Object}
 */
export const normalizeNextToJumpSports = (response) => {
	return normalize(response, nextToJumpSportsSchema);
};
