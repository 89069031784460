import { slugify } from '../legacy/core/format';
import { RACING_NEW_BASE_URL, RACING_NEW_V5_BASE_URL } from '../pages/GRSNewRacing/RacingNewConstrants';
import { SELECTED_DATE_FORMAT } from '../pages/Racing/RacingConstants';
import { SERVER_DATE_FORMAT } from '../common/constants/DateFormats';
import moment from 'moment';
/**
 * Build route for date. This function will handle cases where we pass 'YYYY-MM-DDZ' format, e.g. '2010-10-10+11',
 * and should respect given timezone.
 *
 * @param date
 * @return {string}
 */
// export const buildRacingGRSRouteToDate = (date) => {
//   const datetime = moment.parseZone(date, SELECTED_DATE_FORMAT);
//   let route = `/${RACING_NEW_BASE_URL}`;

//   if (datetime.isValid()) {
//     route += `/${datetime.format(SERVER_DATE_FORMAT)}`;
//   }
//   return route;
// };

/**
 * Build route for date and type. This function will handle cases where we pass 'YYYY-MM-DDZ' format, e.g. '2010-10-10+11',
 * @param {*} date
 * @param {*} type
 */
export const buildRacingGRSRouteToDateWithType = (type, date) => {
	const datetime = !date
		? moment.parseZone(new Date(), SELECTED_DATE_FORMAT)
		: moment.parseZone(date, SELECTED_DATE_FORMAT);
	let route = `/${RACING_NEW_BASE_URL}`;

	if (type) {
		if (type.length === 1) {
			route += `/${slugify(type.toUpperCase())}`;
		}
	} else {
		//route += '/'+RACING_THOROUGHBRED_CODE.toLowerCase(); // default Thoroughbred
	}

	if (datetime.isValid()) {
		route += `/${datetime.format(SERVER_DATE_FORMAT)}`;
	}

	return route;
};

export const buildRacingRouteFromV5 = () => {
	return `/${RACING_NEW_V5_BASE_URL}`;
};

/**
 *
 * @param type
 * @param meetingName
 * @param raceNumber
 * @param raceId
 * @param meetingId
 * @return {string}
 */
// export const buildGRSRouteToRace = (
//   {id: meetingId, name: meetingName, type} = {},
//   {number: raceNumber, id: raceId} = {}
// ) => {

//   let route = `/${RACING_NEW_BASE_URL}`;

//   // If no Id route to home
//   if (!meetingId || !raceId) {
//     return route;
//   }

//   if (type) {
//     // If 'type' holds type code
//     if (type.length === 1) {
//       route += `/${slugify(RACING_TYPES_LOOKUP[type.toUpperCase()])}`;
//     }
//     else if (type.length > 1) {
//       route += `/${slugify(type)}`;
//     }
//   } else {
//     route += '/unknown';
//   }

//   if (meetingName) {
//     route += `/${slugify(meetingName)}`;
//   } else {
//     route += '/unknown';
//   }

//   route += `/race-${(raceNumber || 0)}-${raceId}-${meetingId}`;

//   return route;
// };

/***
 *
 */
export const buildWithDateGRSRouteToRace = (
	type,
	date,
	{ id: meetingId, name: meetingName, type: meetingType, start_date: meetingDate } = {},
	{ number: raceNumber, id: raceId } = {},
) => {
	const datetime = !date
		? moment.parseZone(new Date(), SELECTED_DATE_FORMAT)
		: moment.parseZone(date, SELECTED_DATE_FORMAT);
	let selectedMeetingDate = meetingDate && moment(meetingDate).format(SERVER_DATE_FORMAT);

	let route = `/${RACING_NEW_BASE_URL}`;

	// If no Id route to home
	if (!meetingId || !raceId) {
		return route;
		//return buildRacingGRSRouteToDateWithType(type,date);
	}
	if (type) {
		if (type.length === 1) {
			route += `/${slugify(type.toUpperCase())}`;
		}
	} else if(meetingType) {
		route += '/' + meetingType.toLowerCase();
	}
	else {
		return route;
	}

	if (datetime.isValid()) {
		route += `/${datetime.format(SERVER_DATE_FORMAT)}`;
	} else {
		route += `/${selectedMeetingDate}`;
	}

	if (meetingName) {
		route += `/${slugify(meetingName)}`;
	} else {
		route += '/unknown';
	}

	route += `/race-${raceNumber || 0}-${raceId}-${meetingId}`;

	return route;
};

export const buildRacingGRSRouteFromState = (state) => {
	const { grsRacingHome, entities } = state;
	const { selectedRace, selectedMeeting, selectedDate, selectedType } = grsRacingHome;

	const datetime = moment.parseZone(selectedDate, SELECTED_DATE_FORMAT);

	// 'Or' includes alternative cases covered by called function
	if (selectedRace || selectedMeeting) {
		const { meetings, races } = entities;
		return buildWithDateGRSRouteToRace(selectedType, datetime, meetings[selectedMeeting], races[selectedRace]);
	} else if (selectedDate && !selectedType) {
		return buildRacingGRSRouteToDateWithType(selectedType, selectedDate);
	} else if (selectedType && selectedDate) {
		// If no meeting or race but there is a date and type, route to date and type
		return buildRacingGRSRouteToDateWithType(selectedType, selectedDate);
	}
	// default case racing v3 with today date
	return `/${RACING_NEW_BASE_URL}`;
};
