import { createContext } from 'react';

import featureTogglesReducer from '../store/featureToggles/featureTogglesReducer';

// Set the default values for the context to the initial state of the reducer
const defaultFeatureContext = featureTogglesReducer(undefined, {});

const FeatureContext = createContext(defaultFeatureContext);

export default FeatureContext;
