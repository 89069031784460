export const CONSTRICTIVE = 1;
export const TIGHT = 4;
export const COMPACT = 8;
export const COZY = 16;
export const COMFORTABLE = 24;
export const SPACIOUS = 32;
export const ROOMY = 40;

export const SPACINGS = {
	constrictive: CONSTRICTIVE,
	tight: TIGHT,
	compact: COMPACT,
	cozy: COZY,
	comfortable: COMFORTABLE,
	spacious: SPACIOUS,
	roomy: ROOMY,
};