import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, typography, ui } from '@tbh/ui-kit';

/**
 * Styling
 */
const StyledBetPositionDetail = styled('div')(
	(props) => css`
		label: BetPositionDetail;

		flex: 1;
		text-align: center;

		${props.isStandout &&
		css`
				display: flex;
				margin-top: ${spacings(props).compact}px;
				text-align: left;
			`};
	`,
);

const StyledBetPositionDetail__Label = styled('div')(
	(props) => css`
		label: BetPositionDetail__Label;
		border-radius: 4px;
		// padding: 2px;
		margin: auto;

		background: ${ui(props).color_info};
		color: ${typography(props).color_1_alternate};
		font-size: 11px;
		font-weight: bold;
		line-height: 16px;
		text-align: center;
		height: 19.2px;
		${props.isStandout &&
		css`
				flex: 0 0 32px;
				// height: 19px;
				line-height: 19.2px;
				margin-right: ${spacings(props).compact}px;
			`};

		${props.isLegs &&
		css`
				margin: unset;
				flex: 0 0 48px;
				// height: 19px;
				line-height: 19.2px;
				margin-right: ${spacings(props).compact}px;
			`};
	`,
);

const StyledBetPositionDetail__Selection = styled('div')(
	(props) => css`
		label: BetPositionDetail__Selection;

		font-size: 10px;
		font-weight: bold;
		line-height: 19.2px;

		${props.isStandout &&
		css`
				flex: 1;
				font-size: 10px;
			`};
	`,
);

const BetPositionDetail = (props) => {
	const { className, selection, label, isStandout, isLegs } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<StyledBetPositionDetail className={componentClasses} isStandout={isStandout}>
			<StyledBetPositionDetail__Label isStandout={isStandout} isLegs={isLegs}>
				{label}
			</StyledBetPositionDetail__Label>
			<StyledBetPositionDetail__Selection isStandout={isStandout}>{selection}</StyledBetPositionDetail__Selection>
		</StyledBetPositionDetail>
	);
};

BetPositionDetail.propTypes = {
	/** The selection details used for the bet position */
	selection: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

	/** The label used for the bet position */
	label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

	/** Are the selections a standout selection */
	isStandout: PropTypes.bool,

	/** Expand label for showing the leg number */
	isLegs: PropTypes.bool,

	/** Extra style class(es) to pass through */
	className: PropTypes.string,
};

BetPositionDetail.defaultProps = {
	label: null,
	isStandout: false,
	isLegs: false,
	className: null,
};

export default BetPositionDetail;
