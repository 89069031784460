import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withSize } from 'react-sizeme';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { brand, spacings, mediaMax, Icon, Heading, PlotElements, Tabs, TabsItem, Text } from '@tbh/ui-kit';

import { routeTo } from '../../../../store/application/applicationActions';
import { MOBILE_MAX_WIDTH } from '../../../../common/constants/Breakpoints';

import DocumentTitle from '../../../controllers/Meta/DocumentTitle';

//import bgImage from '../../../../images/mred.jpg';
import bg404Image from '../../../../images/404page.png';

 const Styled_PlotElements = styled(PlotElements)`

	${(props) =>
		css`
			label: Styled_PlotElements;

			margin: ${spacings(props).tight}px;
			background: #c72128;
			border-color: #c72128;
			color: rgb(255, 255, 255);

			position: relative;
			text-align: center;
			cursor: pointer;
			white-space: nowrap;
			font-size: 14.2222px;
			line-height: 1.5;
			border-radius: 0px;
			padding: 4px 8px;
			outline: none;
			text-decoration: none;
			transition: background-color 0.1s ease-in-out 0s, border-color 0.1s ease-in-out 0s;
			background: #c72128;
			border-width: 1px 1px 2px;
			border-style: solid;
			border-color: #c72128 #c72128 #c72128;
			border-image: initial;
			border-bottom: 2px solid #c72128;
			
		`};

		${mediaMax(
			css`
				height: 20vh;
			`,
			620,
		)};

 `;

const StyledErrorPage404__SplashImage = styled('div')`
	${(props) => css`
		label: ErrorPage404__SplashImage;

		background: no-repeat center url(${bg404Image});
		background-size: cover;
		width: 100%;
		height: 40vh;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: ${spacings(props).cozy}px;
	`};

	${mediaMax(
		css`
			height: 20vh;
		`,
		620,
	)};
`;

const StyledErrorPage404__PageTitle = styled(Heading)`
	${(props) => css`
		label: ErrorPage404__PageTitle;

		color: ${brand(props).color_1};
		opacity: 0.5;
		letter-spacing: 0;
		overflow: hidden;
		font-size: 20vw;
		line-height: 1;
	`};

	${mediaMax(
		css`
			font-size: 30vw;
		`,
		620,
	)};
`;

const StyledErrorPage404__Heading = styled(Heading)`
	${(props) => css`
		label: ErrorPage404__Heading;

		margin-bottom: ${spacings(props).cozy}px;
	`};
`;

const StyledErrorPage404__ActionGroup = styled(Tabs)`
	${(props) => css`
		label: ErrorPage404__ActionGroup;

		margin-top: ${spacings(props).cozy}px;
	`};
`;

const StyledErrorPage404 = styled('div')`
	${(props) => css`
		label: ErrorPage404;

		padding: ${spacings(props).cozy}px;
		
	`};
`;


const Styled_bottam_span = styled('span')`
${(props) => css`
	label: Styled_bottam_span;

	
	color: rgb(255, 255, 255);
	text-transform: uppercase;
	font-weight: bold;
`};
`;

const ErrorPage404 = (props) => {
	const { className, navigationItems, size, t } = props;

	const componentClasses = cx({
		[className]: className,
	});

	const isMobileWidth = size.width < MOBILE_MAX_WIDTH;

	const handleNavigation = (data) => {
		App.store.dispatch(routeTo(data));
	};

	return (
		<StyledErrorPage404 className={componentClasses}>
			<DocumentTitle>{t('ErrorPage404__Title')}</DocumentTitle>
			<Heading type="2" centered>
				{t('ErrorPage404__Late')}{' '}
				<Text size="5" tag="span" transform="uppercase" lineThrough>
					{t('Scratching')}
				</Text>
			</Heading>
			<StyledErrorPage404__SplashImage size={size}>
				{/* <StyledErrorPage404__PageTitle size={size} type="1" noMargin>
					404
				</StyledErrorPage404__PageTitle> */}
			</StyledErrorPage404__SplashImage>
			<Text align="center" paragraph>
				{t('ErrorPage404__Message')}{' '}
			</Text>
			<StyledErrorPage404__Heading type="2" centered>
				<Text align="center" size="5" transform="uppercase" italic strong>
					{t('Abandoned')}
				</Text>
			</StyledErrorPage404__Heading>
			<Text align="center">{t('ErrorPage404__SubMessage')}</Text>

			<StyledErrorPage404__ActionGroup emphasised isColumn={isMobileWidth} justifyAuto wrappingBorder>
				{navigationItems.map((navigationItem) => {
					return (
						<TabsItem key={navigationItem.id} action={handleNavigation} data={navigationItem.route}>
							<Styled_PlotElements>
							
								<Icon icon={navigationItem.icon} />
								&nbsp;
								<Styled_bottam_span>{navigationItem.title}</Styled_bottam_span>
							
							</Styled_PlotElements>
						</TabsItem>
					);
				})}
			</StyledErrorPage404__ActionGroup>
		</StyledErrorPage404>
	);
};

ErrorPage404.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}).isRequired,

	/** List of navigation items to show */
	navigationItems: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			route: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			icon: PropTypes.string,
		}),
	).isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

ErrorPage404.defaultProps = {
	className: '',
};

export default withSize()(withNamespaces()(ErrorPage404));
