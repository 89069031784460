/**
 * Receives normalized sports and returns array sorted by name
 *
 * @param sports - normalized sports
 * @return {Array.<*>}
 */
export const getSportsSortedByName = (sports) => {
	return Object.values(sports).sort((a, b) => a.name.localeCompare(b.name));
};

/**
 * Receives normalized sports and returns array sorted by order
 *
 * @param sports
 * @returns {Array.<*>}
 */
export const getSportsSortedByOrder = (sports) => {
	return Object.values(sports).sort((a, b) => b.order - a.order || a.name.localeCompare(b.name));
};

/**
 * Receives normalized sports and filter them by id
 *
 * @param sports
 * @param sportIds
 */
export const filterSportsById = (sports, sportIds) => {
	return Object.keys(sports)
		.filter((key) => sportIds.includes(parseInt(key)))
		.reduce((obj, key) => {
			return {
				...obj,
				[key]: sports[key],
			};
		}, {});
};
