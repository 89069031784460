import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import titleize from 'underscore.string/titleize';
import moment from 'moment';

import { openNotification, toggleSideBetSlip } from '../../store/application/applicationActions';
import {
  buildBetsForRace,
  buildExoticProducts,
  buildMobileWinPlaceChoices,
  buildRacingExoticResults,
  buildWinPlaceProducts,
  buildWinPlaceResults,
  getFlucsKey,
  getSelectedRace,
  shouldRenderFormsButton,
  racingHomeRaceId,
  getGoatMarginButtLength,
  buildSelectionBetButtons,
} from '../../pages/Racing/RacingHome/racingSelectorsGRS';
import {
  RACING_NON_EXOTIC_BET_TYPE,
  TRACKING_CATEGORY_RACE_ALL_FORM,
  TRACKING_CATEGORY_RACE_MARKET_SELECTOR,
  TRACKING_CATEGORY_RACE_SPEEDMAP_SELECTOR,
  TRACKING_CATEGORY_RACE_DERIVATIVE_SELECTOR,
  TRACKING_CATEGORY_RACE_CARD_PRODUCT_SELECTOR,
  TRACKING_CATEGORY_RACE_RUNNER_FORM,
  RACING_BET_TYPE_QUADDIE,
  RACING_BET_TYPE_DAILY_DOUBLE,
} from '../../common/constants/Racing';
import {
  formatAndAddDerivativeSelectionToBetPrompt,
  formatAndAddExoticSelectionToBetPrompt,
  formatAndAddSingleSelectionToBetPrompt,
  resetBetPrompt,
  resetBetPromptTitle,
  formatExoticSelection,
} from '../../store/betPrompt/betPromptActions';
import { fetchMeetingsAndRacesWithSelectionsForRace } from '../../store/entities/actions/MeetingActions';
import {
  setExoticPools,
  navigateToRace,
  handleClearSelectionsClick,
} from '../../store/GRSracingHome/GRSracingHomeActions';
import { getAuthenticatedUser } from '../../store/application/applicationSelectors';
import { formatAndAddRacingMultiBet } from '../../common/actions/multiActions';
import { trackGaEvent } from '../../store/trackingPixels/trackingActions';
import { isBetReferred } from '../Betting/bettingMemoizedSelectors';
import { triggerEventMessage } from '../../common/actions/widgetActions';
import { quaddiePosition } from '../../common/QuaddiePosition';
import { dailyDoublePosition } from '../../common/DailyDoublePosition';

// Components
import Modal from '../../components/controllers/Modal/Modal';
import DepositContainer from '../Deposit/DepositContainer/DepositContainer';
import DocumentTitle from '../../components/controllers/Meta/DocumentTitle';
import DescriptionMeta from '../../components/controllers/Meta/DescriptionMeta';
import ModalHeader from '../../components/features/Application/ModalHeader/ModalHeader';
import BetPromptContainer from '../Betting/BetPromptContainer/BetPromptContainer';
import NtjSelectionCard from '../../components/features/Racing/NextJumpSelectionCard/NtjSelectionCard';
import BrandContactPhone from '../../components/ui-components/BrandContactPhone/BrandContactPhone';
import ModalFooter from '../../components/features/Application/ModalFooter/ModalFooter';

class RaceCardContainer extends Component {
  static propTypes = {
    /** Translation func provided by withNamespaces HOC */
    t: PropTypes.func.isRequired,

    /** The size of the component - used for media query logic */
    size: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }).isRequired,

    /** De-normalized race and details */
    race: PropTypes.shape({
      id: PropTypes.number.isRequired,
      status: PropTypes.string,
      fixed_odds_enabled: PropTypes.bool,
      number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      start_date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
      exotic_bets_allowed: PropTypes.bool,
      selections: PropTypes.array,
      win_pool_total: PropTypes.number,
      place_pool_total: PropTypes.number,
      products: PropTypes.array,
    }).isRequired,

    /** Open a notification */
    onOpenNotification: PropTypes.func.isRequired,

    /** Action to fire when the bet slip badge is clicked on */
    onSideBetSlipClick: PropTypes.func.isRequired,

    /** Action to fire when the bet slip badge is clicked on */
    isSideBetSlipOpen: PropTypes.bool.isRequired,

    /** Function for hiding bet prompt */
    handleHideBetPrompt: PropTypes.func,

    /** Feature toggle for exotics */
    exoticsEnabled: PropTypes.bool.isRequired,

    /** Has the first race in the bet type pool finished or not */
    quaddiePoolClosed: PropTypes.bool,

    /** Has the first race in the bet type pool finished or not */
    dailyDoublePoolClosed: PropTypes.bool,

    /** Feature toggle for derivatives */
    racingDerivativesEnabled: PropTypes.bool.isRequired,

    /** Feature toggle for speedmaps */
    racingSpeedmapsEnabled: PropTypes.bool.isRequired,

    /** The currently authenticated user */
    user: PropTypes.object,

    /** Computed results to render */
    displayedResults: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    displayedExoticResults: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

    /** Win/Place to build betting buttons */
    winPlaceProducts: PropTypes.arrayOf(
      PropTypes.shape({
        fixed: PropTypes.bool,
        bet_type: PropTypes.string,
      }),
    ),

    /** Exotic products to bet combining runner positions */
    exoticProducts: PropTypes.array,

    /** Product choices to be displayed on small screens */
    winPlaceProductChoices: PropTypes.array,

    /** Fluctuations key to be rendered. False values hides Flucs column */
    fluctuationsKey: PropTypes.string,

    /** Action to build the list of selection bet buttons */
    buildSelectionBetButtons: PropTypes.func.isRequired,

    /** Add single selection when clicking on odds */
    formatAndAddSingleSelectionToBetPrompt: PropTypes.func.isRequired,

    /** Add exotic selection when clicking on Place Bet */
    formatAndAddExoticSelectionToBetPrompt: PropTypes.func.isRequired,

    /** Add derivative selection when clicking on odds */
    formatAndAddDerivativeSelectionToBetPrompt: PropTypes.func.isRequired,

    /** Add to multi bet slip */
    formatAndAddRacingMultiBet: PropTypes.func.isRequired,

    /** Actions for bet prompt */
    resetBetPrompt: PropTypes.func.isRequired,

    /** Reset the bet prompt title back to it's default value */
    resetBetPromptTitle: PropTypes.func.isRequired,

    /** Requirements from the bet prompt slice of state */
    betPrompt: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,

    /** Passed via ownProps from parent, needed for bet placement */
    meetingId: PropTypes.number.isRequired,

    /** Passed via ownProps from parent, needed for bet placement */
    meetingName: PropTypes.string,

    /** Single bets place in this race */
    bets: PropTypes.array,

    /** Context name for prefixing custom events to be fired through middleware */
    eventContext: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

    /** Loading mask status to cover race card section */
    loadingRace: PropTypes.bool,

    /** Indicator whether the bet has been referred */
    betReferred: PropTypes.bool,

    /** Flag to render Forms button or not. Based of 'runner', under Selections. */
    shouldRenderFormsButton: PropTypes.bool,

    /** Extra class(es) to pass through */
    className: PropTypes.string,

    /** REST call to API to fetch and set state of exoticPools */
    updateExoticPools: PropTypes.func,

    /** Tracks bet type selection */
    trackGaMarketType: PropTypes.func,

    /** Tracks when speedmaps are toggled */
    trackGaSpeedmaps: PropTypes.func,

    /** Tracks when derivative markets are filtered */
    trackGaDerivativeType: PropTypes.func,

    /** Tracking all form toggle */
    trackGaAllForm: PropTypes.func,

    /** Tracks single runner form toggle */
    trackGaRunnerForm: PropTypes.func,

    /** Tracks goat product being enabled */
    trackGaGoatType: PropTypes.func,

    /** Navigation to race id */
    navigateToRace: PropTypes.func,

    /** Brand name */
    brandName: PropTypes.string,

    /** Handle state toggle for clearSelections */
    handleClearSelectionsClick: PropTypes.func,

    /** Should checkbox selection state be cleared or not */
    clearSelections: PropTypes.bool,
  };

  static defaultProps = {
    user: null,
    bets: [],
    eventContext: '',
    loadingRace: false,
    meetingName: 'n/a',
    fluctuationsKey: null,
    className: null,
    displayedResults: [],
    displayedExoticResults: [],
    winPlaceProducts: [],
    exoticProducts: [],
    quaddiePoolClosed: false,
    dailyDoublePoolClosed: false,
    winPlaceProductChoices: [],
    betReferred: false,
    shouldRenderFormsButton: false,
    isSideBetSlipOpen: false,
    clearSelections: false,
    updateExoticPools: () => {},
    handleClearSelectionsClick: () => {},
    handleHideBetPrompt: () => {},
    trackGaMarketType: () => {},
    trackGaAllForm: () => {},
    trackGaRunnerForm: () => {},
    trackGaSpeedmaps: () => {},
    trackGaDerivativeType: () => {},
    trackGaGoatType: () => {},
    brandName: '',
    navigateToRace: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      // For bet type filter use
      selectedBetType: RACING_NON_EXOTIC_BET_TYPE,
      boxed: false,
      raceId: props.race.id,

      selectedProduct: props.winPlaceProductChoices[0] && props.winPlaceProductChoices[0].betType,

      // Flag whether the bet prompt container is showing
      showBetPrompt: false,

      // Flag whether the Quick Deposit container is showing
      showQuickDepositPrompt: false,

      // Holds the status of the 'all form' open button
      allFormOpen: false,

      // Hold quaddie selections in memory across legs
      multiLegSelections: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Shared variables
    const selectedExotic = nextProps.betPrompt.exoticDetails ? nextProps.betPrompt.exoticDetails.id : false;

    const notQuaddieLeg = !quaddiePosition(nextProps.race);
    const notDDLeg = !dailyDoublePosition(nextProps.race);

    const notQuaddie = notQuaddieLeg && selectedExotic === RACING_BET_TYPE_QUADDIE;
    const notDailyDouble = notDDLeg && selectedExotic === RACING_BET_TYPE_DAILY_DOUBLE;

    const isQuaddie = prevState.selectedBetType === RACING_BET_TYPE_QUADDIE;
    const isDailyDouble = prevState.selectedBetType === RACING_BET_TYPE_DAILY_DOUBLE;

    const clearQuaddie = isQuaddie && notQuaddieLeg;
    const clearDailyDouble = isDailyDouble && notDDLeg;

    // If new race then clear the betType //
    if (nextProps.race.id !== prevState.raceId) {
      /* hide side bet slip if you change bet type to non quaddie/DD betType */
      notDailyDouble && nextProps.onSideBetSlipClick(true);
      notQuaddie && nextProps.onSideBetSlipClick(true);

      /* reset the selectedBetType to default if navigating from a dd bet and is not a dd race leg */
      const handleSelectedBetType =
        clearDailyDouble || clearQuaddie ? RACING_NON_EXOTIC_BET_TYPE : prevState.selectedBetType;

      /* clear selections if outside of the race group or changing between quaddie/dailydouble bet type */
      const handleSelections = notDailyDouble || notQuaddie ? {} : prevState.multiLegSelections;

      /* fetch exoticPools from meeting again to check exotic_pool_status has turned to "closed" */
      if (isQuaddie || isDailyDouble) {
        nextProps.updateExoticPools(nextProps.meetingId, nextProps.raceId);
      }

      /* return new state */
      return {
        selectedProduct: nextProps.winPlaceProductChoices[0] && nextProps.winPlaceProductChoices[0].betType,
        selectedBetType: handleSelectedBetType,
        multiLegSelections: handleSelections,
        boxed: false,
        raceId: nextProps.race.id,
        quaddiePoolClosed: nextProps.quaddiePoolClosed,
        dailyDoublePoolClosed: nextProps.dailyDoublePoolClosed,
      };
    }

    return null;
  }

  /**
   * Get the bet prompt modal
   *
   * @returns {boolean|XML}
   */
  buildBetPromptModal() {
    // Setup the modal configuration for the bet prompt
    // JASON: please help me finding a way to concentrate this logic somewhere.
    const MODAL_CONFIG = {
      mobileWidth: true,
      noBorder: true,
      hideClose: true,
      preventBackdropClose: true,

      beforeModalClose: () => {
        if (this.props.betReferred) {
          return false;
        }
        this.setState({
          showBetPrompt: false,
        });
      },
    };

    return (
      this.state.showBetPrompt && (
        <Modal
          open={this.state.showBetPrompt}
          componentKey="event-controller__single-bet-prompt"
          store={App.store}
          config={MODAL_CONFIG}
        >
	<div>
		<ModalHeader
              onClose={MODAL_CONFIG.beforeModalClose}
              title={this.props.betPrompt.title}
              aside={<BrandContactPhone />}
            />

		<BetPromptContainer
              eventContext={this.props.eventContext}
              handleCancel={this.handleHideBetPrompt}
              handleQuickDeposit={this.handleShowQuickDepositPrompt}
            />

		<ModalFooter />
	</div>
        </Modal>
      )
    );
  }

  /**
   * Get the side bet prompt
   *
   * @returns {boolean|XML}
   */
  buildSideBetPrompt() {
    const { isSideBetSlipOpen, t } = this.props;

    if (isSideBetSlipOpen) {
      return ReactDOM.createPortal(
	<React.Fragment>
		<ModalHeader title={t('BetSlip')} onClose={this.handleHideSideBetPrompt} />
		<BetPromptContainer showQuickDeposit={false} handleCancel={this.handleHideSideBetPrompt} />
	</React.Fragment>,
        document.getElementById('side-betslip-portal'),
      );
    } else {
      null;
    }
  }

  /**
   * Get the quick deposit prompt
   */
  buildQuickDepositModal() {
    // Setup the modal configuration for the bet prompt
    const MODAL_CONFIG = {
      mobileWidth: true,
      noBorder: true,
      preventBackdropClose: true,
      hideClose: true,
    };

    return (
      this.state.showQuickDepositPrompt && (
        <Modal
          open={this.state.showQuickDepositPrompt}
          componentKey="event-controller__quick-deposit"
          store={App.store}
          config={MODAL_CONFIG}
        >
	<div>
		<ModalHeader
              onClose={this.handleHideQuickDepositPrompt}
              title={this.props.betPrompt.title}
              aside={<BrandContactPhone />}
            />
		<DepositContainer
              isQuickDeposit
              trackingCategory="Quick Deposit"
              handleClose={this.handleHideQuickDepositPrompt}
            />
		<ModalFooter />
	</div>
        </Modal>
      )
    );
  }

  /**
   * Show the bet prompt
   */
  handleShowBetPrompt = () => {
    this.setState({
      showBetPrompt: true,
    });
  };

  /**
   * Hide the bet prompt
   */
  handleHideBetPrompt = () => {
    this.setState({
      showBetPrompt: false,
    });
  };

  /**
   * Hide the side bet prompt
   */
  handleHideSideBetPrompt = () => {
    const { onSideBetSlipClick, handleClearSelectionsClick } = this.props;
    resetBetPrompt();
    onSideBetSlipClick(true);
    handleClearSelectionsClick();
    this.setState({ multiLegSelections: [] });
  };

  /**
   * Show the bet prompt
   */
  handleShowQuickDepositPrompt = () => {
    this.setState({
      showQuickDepositPrompt: true,
    });
  };

  /**
   * Hide the bet prompt
   */
  handleHideQuickDepositPrompt = () => {
    this.props.resetBetPromptTitle();
    this.setState({
      showQuickDepositPrompt: false,
    });
  };

  /**
   * Handles click on odds
   * @param selectionId
   * @param productId
   * @param betType e.g. 'win', 'place''
   */
  handleAddToSingle = (selectionId, productId, betType) => {
    const { meetingId, race, formatAndAddSingleSelectionToBetPrompt, eventContext, user } = this.props;
    formatAndAddSingleSelectionToBetPrompt(selectionId, race.id, meetingId, productId, betType).then(() => {
      triggerEventMessage(eventContext, 'selectionAddedToBetPrompt', true, user);
    });
    this.handleShowBetPrompt();
  };

  /**
   * Handles opening the bet prompt for a derivative selection
   *
   * @param derivativeSelectionId
   * @param derivativeMarketId
   * @param derivativeMarketType
   */
  handleAddDerivative = (derivativeSelectionId, derivativeMarketId, derivativeMarketType) => {
    this.props.formatAndAddDerivativeSelectionToBetPrompt(derivativeSelectionId, derivativeMarketId).then(() => {
      triggerEventMessage(this.props.eventContext, 'selectionAddedToBetPrompt', true, this.props.user);
    });
    this.props.trackGaDerivativeType(derivativeMarketType);

    this.handleShowBetPrompt();
  };
  /**
   * Handles click on plus sign. Build data from de-normalized entities and dispatch action.
   *
   * @param selectionId
   * @param productId
   * @param betType
   */
  handleAddToMulti = (selectionId, productId, betType) => {
    const { meetingId, race, eventContext } = this.props;

    this.props.formatAndAddRacingMultiBet(selectionId, race.id, meetingId, productId, betType).then(() => {
      triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
    });
  };
  /**
   * Handles multi leg selections in local state.
   * For quaddie and daily double bet types.
   *
   * @param {object} selectedCheckboxes
   */
  handleMultiLegSelections = (selectedCheckboxes) => {
    const { meetingId, race } = this.props;
    const { selectedBetType, multiLegSelections } = this.state;
    const boxed = false;

    /* Format selections into correct exotic selections structure */
    const { betSelections } = formatExoticSelection(race, meetingId, selectedBetType, boxed, selectedCheckboxes);

    /* Take existing state and add the newest selections to the array */
    const raceLegSelections = [...multiLegSelections, ...betSelections];

    /* Remove duplicate selections */
    const removeDuplicates = raceLegSelections.reduce((unique, o) => {
      if (!unique.some((selection) => selection.id === o.id && selection.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);

    /* Append to state */
    this.setState({ multiLegSelections: removeDuplicates });

    return removeDuplicates;
  };

  /**
   * Handles exotic bets. Expected input:
   *  {
   *      123: {2: true},
   *      345: {3: true, 4: true}
   *  }
   *
   * @param {object} selectedCheckboxes
   */
  handleAddExotic = (selectedCheckboxes) => {
    const {
      meetingId,
      race,
      quaddiePoolClosed,
      dailyDoublePoolClosed,
      formatAndAddExoticSelectionToBetPrompt,
      onSideBetSlipClick,
      onOpenNotification,
      eventContext,
      user,
      t,
    } = this.props;
    const { selectedBetType, boxed } = this.state;

    const isMultiLegExotic =
      selectedBetType === RACING_BET_TYPE_QUADDIE || selectedBetType === RACING_BET_TYPE_DAILY_DOUBLE;
    const checkboxes = isMultiLegExotic ? this.handleMultiLegSelections(selectedCheckboxes) : selectedCheckboxes;

    try {
      formatAndAddExoticSelectionToBetPrompt(race.id, meetingId, selectedBetType, boxed, checkboxes, eventContext).then(
        () => {
          triggerEventMessage(eventContext, 'selectionAddedToBetPrompt', true, user);
        },
      );

      if (selectedBetType === RACING_BET_TYPE_QUADDIE) {
        quaddiePoolClosed
          ? (onOpenNotification('First race has started. Betting no longer available for this bet type.', 'danger'),
            this.handleHideSideBetPrompt())
          : onSideBetSlipClick();
      } else if (selectedBetType === RACING_BET_TYPE_DAILY_DOUBLE) {
        dailyDoublePoolClosed
          ? (onOpenNotification('First race has started. Betting no longer available for this bet type.', 'danger'),
            this.handleHideSideBetPrompt())
          : onSideBetSlipClick();
      } else {
        this.handleShowBetPrompt();
      }
    } catch (e) {
      onOpenNotification(t(e.message), 'danger');
    }
  };

  /**
   * Saves current selected bet type
   * @param {string} selectedBetType
   * @param {boolean} boxed represents which product accepts boxed state
   * @param {string} selectedProduct
   */
  handleSelectedBetType = (selectedBetType, boxed, selectedProduct) => {
    const { trackGaMarketType, onSideBetSlipClick, isSideBetSlipOpen } = this.props;

    /* close side bet slip if open when changing the bet type */
    if (isSideBetSlipOpen) {
      this.setState({ multiLegSelections: [] });
      onSideBetSlipClick(isSideBetSlipOpen);
    }

    trackGaMarketType(selectedBetType);
    this.setState({ selectedBetType, boxed, selectedProduct });
  };

  /**
   * Navigate to Race given the id
   */
  handleOnRaceClick = (raceId) => {
    const { meetingId } = this.props;
    this.props.navigateToRace(raceId, meetingId);
  };

  render() {
    const {
      size,
      user,
      bets,
      race,
      meetingName,
      loadingRace,
      winPlaceProductChoices,
      fluctuationsKey,
      buildSelectionBetButtons,
      displayedResults,
      displayedExoticResults,
      shouldRenderFormsButton,
      className,
      clearSelections,
      handleClearSelectionsClick,
      exoticsEnabled,
      quaddiePoolClosed,
      dailyDoublePoolClosed,
      racingDerivativesEnabled,
      racingSpeedmapsEnabled,
      trackGaMarketType,
      trackGaAllForm,
      trackGaRunnerForm,
      trackGaSpeedmaps,
      trackGaDerivativeType,
      trackGaGoatType,
      winPlaceProducts,
      brandName,
    } = this.props;

    const { boxed, selectedBetType, selectedProduct } = this.state;

    const containerClasses = cx({
      [className]: className,
    });

    const titles = {
      race: titleize(race.name),
      meeting: titleize(meetingName),
    };

    return (
	<div className={containerClasses}>
		{this.buildBetPromptModal()}
		{this.buildQuickDepositModal()}
		{this.buildSideBetPrompt()}
		<DocumentTitle>{`${titles.meeting} Race ${race.number} ${titles.race} Odds${
          brandName ? ' | ' + brandName : ''
        }`}</DocumentTitle>
		<DescriptionMeta>{`Bet on Race ${race.number} at ${titles.meeting} & View ${
          titles.race
        } odds, runners & results${brandName ? ' at ' + brandName : ''}.`}</DescriptionMeta>

		<NtjSelectionCard
          size={size}
          user={user}
          bets={bets}
          race={race}
          type={race.type}
          boxed={boxed}
          clearSelections={clearSelections}
          handleClearSelections={handleClearSelectionsClick}
          quaddiePoolClosed={quaddiePoolClosed}
          dailyDoublePoolClosed={dailyDoublePoolClosed}
          selectedBetType={selectedBetType}
          selectedProduct={selectedProduct}
          disableDerivatives={!racingDerivativesEnabled}
          disableExotics={!exoticsEnabled}
          disableSpeedmaps={!racingSpeedmapsEnabled}
          displayedResults={displayedResults}
          displayedExoticResults={displayedExoticResults}
          loadingRace={loadingRace}
          fluctuationsKey={fluctuationsKey}
          buildSelectionBetButtons={buildSelectionBetButtons}
          getGoatMarginButtLength={getGoatMarginButtLength}
          shouldRenderFormsButton={shouldRenderFormsButton}
          navigateToRace={this.handleOnRaceClick}
          onBetTypeChange={this.handleSelectedBetType}
          onClickSingle={this.handleAddToSingle}
          onClickExotic={this.handleAddExotic}
          onClickMulti={this.handleAddToMulti}
          onDerivativeSelectionClick={this.handleAddDerivative}
          trackGaMarketType={trackGaMarketType}
          trackGaAllForm={trackGaAllForm}
          trackGaRunnerForm={trackGaRunnerForm}
          trackGaSpeedmaps={trackGaSpeedmaps}
          trackGaDerivativeType={trackGaDerivativeType}
          trackGaGoatType={trackGaGoatType}
          winPlaceProducts={winPlaceProducts}
          winPlaceProductChoices={winPlaceProductChoices}
        />
	</div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const betReferred = isBetReferred(state);
  const exoticsEnabled = state.featureToggles.features.exotics.enabled;

  const exoticPools = state.grsRacingHome.exoticPools
    ? state.grsRacingHome.exoticPools.find((meeting) => meeting.meetingId === props.meetingId || false) &&
    state.grsRacingHome.exoticPools.find((meeting) => meeting.meetingId === props.meetingId || false).exoticPools
    : false;

  const quaddieProduct =
    exoticPools && exoticPools.find((product) => product.bet_type_name === RACING_BET_TYPE_QUADDIE || false);
  const dailyDoubleProduct =
    exoticPools && exoticPools.find((product) => product.bet_type_name === RACING_BET_TYPE_DAILY_DOUBLE || false);

  const quaddiePoolClosed = quaddieProduct ? quaddieProduct.pool_status_code === 'Closed' : false;
  const dailyDoublePoolClosed = dailyDoubleProduct ? dailyDoubleProduct.pool_status_code === 'Closed' : false;

  return {
    betReferred,
    exoticsEnabled,
    quaddiePoolClosed,
    dailyDoublePoolClosed,
    bets: buildBetsForRace(state),
    betPrompt: state.betPrompt,
    brandName: state.acl.brandDetails && state.acl.brandDetails.name,
    user: getAuthenticatedUser(state),
    race: getSelectedRace(state, racingHomeRaceId),
    clearSelections: state.grsRacingHome.clearSelections,
    loadingRace: state.grsRacingHome.loadingRace,
    showQuaddieRaces: state.grsRacingHome.showQuaddieRaces,
    isSideBetSlipOpen: state.application.showSideBetSlip,
    shouldRenderFormsButton: shouldRenderFormsButton(state, racingHomeRaceId),
    winPlaceProducts: buildWinPlaceProducts(state, racingHomeRaceId),
    winPlaceProductChoices: buildMobileWinPlaceChoices(state, racingHomeRaceId),
    fluctuationsKey: getFlucsKey(state, racingHomeRaceId),
    displayedResults: buildWinPlaceResults(state, racingHomeRaceId),
    exoticProducts: exoticsEnabled ? buildExoticProducts(state, racingHomeRaceId) : [],
    displayedExoticResults: exoticsEnabled ? buildRacingExoticResults(state, racingHomeRaceId) : [],
    racingDerivativesEnabled: state.featureToggles.features.racingDerivatives.enabled,
    racingSpeedmapsEnabled: state.featureToggles.features.enableSpeedmaps.enabled,
  };
};

const mapDispatchToProps = (dispatch) => ({
  buildSelectionBetButtons: (prices, displayedBetProducts, betType) =>
    dispatch(buildSelectionBetButtons(prices, displayedBetProducts, betType)),
  formatAndAddDerivativeSelectionToBetPrompt: (derivativeId, marketId) =>
    dispatch(formatAndAddDerivativeSelectionToBetPrompt(derivativeId, marketId)),
  formatAndAddSingleSelectionToBetPrompt: (selectionId, raceId, meetingId, productId, betType) =>
    dispatch(formatAndAddSingleSelectionToBetPrompt(selectionId, raceId, meetingId, productId, betType, false)),
  formatAndAddRacingMultiBet: (selectionId, raceId, meetingId, productId, betType) =>
    dispatch(formatAndAddRacingMultiBet(selectionId, raceId, meetingId, productId, betType)),
  formatAndAddExoticSelectionToBetPrompt: (raceId, meetingId, betType, boxed, selectedCheckboxes) =>
    dispatch(formatAndAddExoticSelectionToBetPrompt(raceId, meetingId, betType, boxed, selectedCheckboxes)),
  resetBetPrompt: () => dispatch(resetBetPrompt()),
  trackGaSpeedmaps: (event) => dispatch(trackGaEvent(TRACKING_CATEGORY_RACE_SPEEDMAP_SELECTOR, 'Select', event)),
  trackGaDerivativeType: (type) => dispatch(trackGaEvent(TRACKING_CATEGORY_RACE_DERIVATIVE_SELECTOR, 'Select', type)),
  trackGaGoatType: (goatProduct) =>
    dispatch(trackGaEvent(TRACKING_CATEGORY_RACE_CARD_PRODUCT_SELECTOR, 'Select', goatProduct)),
  trackGaMarketType: (type) => dispatch(trackGaEvent(TRACKING_CATEGORY_RACE_MARKET_SELECTOR, 'Click', type)),
  trackGaAllForm: () => dispatch(trackGaEvent(TRACKING_CATEGORY_RACE_ALL_FORM, 'View', 'All Form')),
  trackGaRunnerForm: () => dispatch(trackGaEvent(TRACKING_CATEGORY_RACE_RUNNER_FORM, 'View', 'Runner Form')),
  resetBetPromptTitle: (title) => dispatch(resetBetPromptTitle()),
  onOpenNotification: (message) => dispatch(openNotification(message, 'danger')),
  navigateToRace: (raceId, meetingId) => {
    dispatch(navigateToRace(raceId, meetingId));
  },
  onSideBetSlipClick: (isSideBetSlipOpen) => {
    return dispatch(toggleSideBetSlip(isSideBetSlipOpen));
  },
  handleClearSelectionsClick: (clearSelections) => dispatch(handleClearSelectionsClick(clearSelections)),
  updateExoticPools: (meetingId, raceId) =>
    dispatch(fetchMeetingsAndRacesWithSelectionsForRace(meetingId, raceId)).then((response) => {
      const payload = response.exoticPools;
      dispatch(setExoticPools(payload));
    }),
});

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RaceCardContainer),
);
