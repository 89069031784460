import { BET_TYPE_DAILY_DOUBLE, BET_TYPE_QUADDIE } from '../store/entities/constants/BetConstants';
import { dailyDoublePosition } from './DailyDoublePosition';
import { quaddiePosition } from './QuaddiePosition';
import { RunningDouble } from './RunningDouble';
import { TreblePosition } from './Treble';
import { RACING_BET_TYPE_RUNNING_DOUBLE, RACING_BET_TYPE_RUNNING_TREBLE } from './constants/Racing';

export const SelectQuaddieType = (selectedBetType, race,isLastRace=false) => {

    if (selectedBetType === BET_TYPE_QUADDIE) {
        return quaddiePosition(race);
    }

    if (selectedBetType === BET_TYPE_DAILY_DOUBLE) {
        return dailyDoublePosition(race,isLastRace);
    }

    if (selectedBetType === RACING_BET_TYPE_RUNNING_DOUBLE) {
        return RunningDouble(race,isLastRace);
    }

    if(selectedBetType == RACING_BET_TYPE_RUNNING_TREBLE) {
        return TreblePosition(race);
    }
};
