// Libraries
import { denormalize, normalize } from 'normalizr';
import { nextToPlayTournamentsSchema } from './Entities';

/**
 * Denormalizes nextToPlayTournaments
 *
 * @param entities
 * @returns {Array}
 */
export const denormalizeNextToPlayTournaments = (entities, keys) => {
	return denormalize(keys || Object.keys(entities.nextToPlayTournaments), nextToPlayTournamentsSchema, entities);
};

/**
 * Normalizes nextToJumpSports.
 *
 * @param response
 * @returns {Object}
 */
export const normalizeNextToPlayTournaments = (response) => {
	return normalize(response, nextToPlayTournamentsSchema);
};
