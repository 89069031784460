import styled, { css } from 'react-emotion';
import FeatureRacesContainer from '../../../containers/Racing/FeatureRacesContainer';
import UpcomingRacesContainer from '../../../containers/Racing/UpcomingRacesContainer';
import UpcommingSports from '../../../components/features/Sports/UpCommingSports/UpcommingSports';

export const StyledWelcomeLandingHomePage = styled('div')`
	${(props) =>
		css`
			label: WelcomeLanding_Page;
			display: flex;
			flex-direction: column;
			height: 100%;
			width: 100%;
			position: relative;
			z-index: 0; // This stops the loading mask from overlapping the header bar
		`};
`;


export const StyledWelcomePage__UpcomingRaces = styled(UpcomingRacesContainer)`
	${(props) =>
		css`
			label: WelcomePage__UpcomingRaces;
			margin-top: 16px;
		`};
`;

export const StyledWelcomePage__FeatureRaces = styled(FeatureRacesContainer)`
	${(props) =>
		css`
			label: WelcomePage__FeatureRaces;
			margin-top: 16px;
		`};
`;

export const StyledWelcomePage__NextToJumpSportsItem_styled = styled('div')`
	${(props) =>
		css`
			label: StyledWelcomePage__NextToJumpSportsItem_styled;
			padding: 14px;
			border: 1px solid rgb(249, 249, 249);
			box-shadow: rgb(236, 236, 235) 3px 3px 10px 1px;
			border-radius: 4px;
			flex-basis: 100%;
			overflow: hidden;
			-webkit-box-flex: 1;
			flex-grow: 1;
			cursor: pointer;
		`};
`;

export const Up_Coming_Sports = styled(UpcommingSports)`
	${(props) =>
		css`
			label: WelcomePage__Up_Coming_Sports;
			display: flex;
		`};
`;

export const HeaderContainer_Up_Coming_Sports = styled('div')`
	${(props) =>
		css`
			label: Up_Coming_Sports_HeaderContainer_NextToJumpSportsItem;
			display: inline-flex;
			flex-wrap: wrap;
			padding: 6px;
			gap: 8px;
			width: 100%;
		`};
`;

export const Up_Coming_Sports_TitleContainer = styled('div')`
	${(props) =>
		css`
			label: Up_Coming_Sports_TitleContainer;
			display: flex;
			max-width: 75%;
		`};
`;

export const Up_Coming_Sports_TitleRight = styled('div')`
	${(props) =>
		css`
			label: Up_Coming_Sports_TitleRight;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			margin-left: auto;
		`};
`;
export const Up_Coming_Sports_TeamIconContainer = styled('div')`
	${(props) =>
		css`
			label: Up_Coming_Sports_TeamIconContainer;
			display: flex;
			-webkit-box-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			align-items: center;
			width: 32px;
			height: 32px;
			margin: auto 4px;
			flex: 0 0 32px;
		`};
`;

export const Up_Coming_Sports_BetContainer = styled('div')`
	${(props) =>
		css`
			label: Up_Coming_Sports_BetContainer;
			display: flex;
			gap: 4px;
			margin-top: 8px;
			width: 100%;
			border: 1px solid rgb(236, 236, 235);
		`};
`;

export const Up_Coming_Sports_SingleBet = styled('div')`
	${(props) =>
		css`
			label: Up_Coming_Sports_SingleBet;
			display: flex;
			border-left: none;
			border-top: none;
			border-bottom: none;
			border-right: 1px solid rgb(236, 236, 235);
			padding: 6px;
			-webkit-box-flex: 3;
			flex-grow: 3;
			width: 50%;
			flex-direction: row;
			-webkit-box-align: center;
			align-items: center;
			background: rgb(255, 255, 255);
			cursor: pointer;
			position: relative;
		`};
`;
export const Up_Coming_Sports_BetText = styled('div')`
	${(props) =>
		css`
			label: Up_Coming_Sports_BetText;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			-webkit-box-align: center;
			align-items: center;
			min-width: 0px;
			flex: 1 1 auto;
			overflow: hidden;
		`};
`;
export const Up_Coming_Sports_SelectionTitleText = styled('div')`
	label: Up_Coming_Sports_SelectionTitleText;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	-webkit-box-align: center;
	align-items: center;
	min-width: 0px;
	flex: 1 1 auto;
	overflow: hidden;
`;

export const Up_Coming_Sports_OddsText = styled('div')`
	label: Up_Coming_Sports_OddsText;
	padding: 0px;
	line-height: 1.3;
	max-width: 100%;
	color: rgb(34, 34, 34);
	text-transform: none;
	text-decoration: none;
	display: block;
	text-align: left;
	font-weight: 700;
	font-size: 12;
`;
