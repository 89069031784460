import { denormalize, normalize } from 'normalizr';
import { tournamentBetsSchema } from './Entities';

/**
 * Normalize an array of tournament bets
 *
 * @param tournamentBets
 */
const normalizeTournamentBets = (tournamentBets = []) => {
	return normalize(tournamentBets, tournamentBetsSchema);
};

/**
 * Denormalize tournament bets
 *
 * @param entities
 * @param keys
 */
const denormalizeTournamentBets = (entities, keys) => {
	return denormalize(keys || Object.keys(entities.tournamentBets), tournamentBetsSchema, entities);
};

module.exports = {
	tournamentBetsSchema,
	normalizeTournamentBets,
	denormalizeTournamentBets,
};
