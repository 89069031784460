import { get, post } from '../../common/Ajax';
import { createAction } from '../../common/actions/actionHelpers';
import { UPDATE_SPEND_LIMIT } from './spendLimitsActionsTypes';
/**
 * Action to Get the Spend Limit
 *
 * @param period: 'daily', 'weekly' or 'monthly'
 */
export const getSpendLimit = (period) => (dispatch) => {
	return get(`/user/spend-limit?period=${period}`)
		.then((response) => {
			let limit = response.data.data;

			let payload =
				period === 'daily'
					? {
							daily_spend_limit: {
								bet_limit: limit.spend_limit,
								requested_bet_limit: limit.requested_spend_limit,
								requested_date: limit.requested_date,
								days_remaining_to_update: limit.days_remaining_to_update,
								update_limit: limit.update_limit,
							},
					  }
					: period === 'weekly'
					? {
							weekly_spend_limit: {
								bet_limit: limit.spend_limit,
								requested_bet_limit: limit.requested_spend_limit,
								requested_date: limit.requested_date,
								days_remaining_to_update: limit.days_remaining_to_update,
								update_limit: limit.update_limit,
							},
					  }
					: {
							monthly_spend_limit: {
								bet_limit: limit.spend_limit,
								requested_bet_limit: limit.requested_spend_limit,
								requested_date: limit.requested_date,
								days_remaining_to_update: limit.days_remaining_to_update,
								update_limit: limit.update_limit,
							},
					  };
			return dispatch(createAction(UPDATE_SPEND_LIMIT, payload));
		})
		.catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			throw error;
		});
};

/**
 * Action to Set the Spend Limit
 *
 * @param amount: number
 * @param period: 1, 7 or 30
 */
export const setSpendLimit = (amount, period) => () => {
	return post('/user/set-spend-limit', { amount, period })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			throw error;
		});
};

/**
 * Action to Remove the Spend Limit
 *
 * @param period: 'daily', 'weekly' or 'monthly'
 */
export const removeSpendLimit = (period) => () => {
	return post(`/user/remove-spend-limit?period=${period}`)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			throw error;
		});
};

/**
 * Action to Update the Spend Limit
 *
 * @param period: 'daily', 'weekly' or 'monthly'
 */
export const updateSpendLimit = (period) => () => {
	return post(`/user/update-spend-limit?period=${period}`)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			throw error;
		});
};
