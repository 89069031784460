import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { css } from 'react-emotion';

import { Image } from '@tbh/ui-kit';

const cssIntercomIcon = css`
	label: IntercomIcon;

	cursor: pointer;
`;

const IntercomIcon = (props) => {
	const { className, onLaunchIntercom, width } = props;

	const componentClasses = cx(cssIntercomIcon, {
		[className]: className,
	});

	return (
		<Image
			className={componentClasses}
			src={require('../../../../images/intercom-chat-icon.png')}
			alt="Intercom Launcher"
			width={width}
			action={onLaunchIntercom}
		/>
	);
};

IntercomIcon.propTypes = {
	/** Handler for launching the intercom chat */
	onLaunchIntercom: PropTypes.func.isRequired,

	/** The width of the Image */
	width: PropTypes.number,

	/** Extra classes */
	className: PropTypes.string,
};

IntercomIcon.defaultProps = {
	className: '',
	width: 64,
};

export default IntercomIcon;
