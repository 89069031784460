import { get } from '../../../common/Ajax';
import { mergeEntities } from '../../../common/actions/actionHelpers';
import { normalizeVersions } from '../schemas/VersionSchema';

// Define the URL to fetch the version json file from
const versionsUrl = window.location.origin + '/versions.json';

/**
 * Fetch the latest versions details from the versions json file
 *
 * @returns {function(*)}
 */
export const fetchVersions = () => (dispatch) => {
	return get(versionsUrl)
		.then((response) => {
			// Only merge entities if version data was found
			if (response.data.data) {
				dispatch(mergeEntities(normalizeVersions(response.data.data).entities));
				return response;
			}
		})

		.catch((error) => {
			console.error(error);
		});
};
