import React, { useState } from 'react';
import styled from 'react-emotion';

const DropdownContainer = styled('div')`
	position: relative;
	display: inline-block;
	width: 100%;
`;

const DropdownTrigger = styled('div')`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 15px;
	background-color: #fff;
	border: 1px solid #000;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s, border-color 0.3s;

	&:hover {
		background-color: #f9f9f9;
		border-color: #d9d9d9;
	}

	span {
		font-weight: bold;
		color: #000;
	}

	i {
		color: #000;
	}
`;

const DropdownMenu = styled('div')`
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	background-color: #fff;
	border: 1px solid #000;
	border-radius: 5px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	z-index: 1000;
	margin-top: 5px;
`;

const DropdownOption = styled('div')`
	padding: 10px 15px;
	cursor: pointer;
	transition: background-color 0.3s;

	&:first-child {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	&:last-child {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	&:hover {
		background-color: #ff4d4d;
		color: #fff;
	}
`;

const Dropdown = ({ options, onSelect, selectedOption, style }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleToggle = () => {
		setIsOpen(!isOpen);
	};

	const handleOptionClick = (option) => {
		onSelect(option);
		setIsOpen(false);
	};

	return (
		<DropdownContainer style={style}>
			<div className="dropdown">
				<DropdownTrigger onClick={handleToggle}>
					<span>{selectedOption ? selectedOption.title : 'Select an option'}</span>
					<i className={`fas fa-chevron-${isOpen ? 'up' : 'down'}`}></i>
				</DropdownTrigger>
				{isOpen && (
					<DropdownMenu>
						{options.map((option) => (
							<DropdownOption
								key={option.id}
								onClick={() => handleOptionClick(option)}
								style={{
									backgroundColor: selectedOption && selectedOption.id === option.id ? '#ff4d4d' : '#fff',
									color: selectedOption && selectedOption.id === option.id ? '#fff' : '#000',
								}}
							>
								{option.title}
							</DropdownOption>
						))}
					</DropdownMenu>
				)}
			</div>
		</DropdownContainer>
	);
};

export default Dropdown;
