import { post } from '../../common/Ajax';
import { createAction } from '../../common/actions/actionHelpers';
import { SET_REDIRECT_ROUTE, CLEAR_REDIRECT_ROUTE, SET_REGISTRATION_STEP, SET_LOCAL_USER } from './registrationActionsTypes';
import { routeTo, navigate } from '../application/applicationActions';
import { setTheAuthenticatedUser } from '../authentication/authenticationActions';
import { VERIFICATION_RESET, VERIFICATION_SET_TOKEN } from '../verifications/VerificationsActionType';

/**
 * Validates the registration form
 *
 * @param object
 */
export const validateRegistrationForm = (object) => () => {
	return post('/registration/validate', object).then((response) => {
		return response;
	});
};

/**
 * Sends an SMS code to the provided Mobile Number
 *
 * @param msisdn (e.g.: 61499999999)
 */
export const sendSmsCode = async (msisdn) => {
	return await post(`/sms/${msisdn}`);
};

/**
 * Verifies the SMS code sent to the provided Mobile Number
 *
 * @param msisdn
 * @param code
 */
export const verifySmsCode = async (msisdn, code) => {
	return await post(`/sms-verify/${msisdn}/${code}`);
};

export const sendEmailCode = async (email) => {
	return await post('/email', {
		email: email,
	});
};

/**
 * Sends an code to the provided Email
 *
 * @param email (e.g.: abuba@grs.com)
 */
export const verifyEmailCode = async (email, code) => {
	return await post('/email/verify', {
		email: email,
		code: code,
	});
};
/**
 * Creates a new user
 *
 * @param object (country, city, dob, dob_day, dob_month, dob_year, email, first_name, last_name, marketing_opt_in_flag,
 * msisdn, password, postcode, state, street, title, undefined, username, verify_msisdn, _tb_source)
 */
export const createAccount = (object) => (dispatch) => {
	return post('registration/createfull', object).then((response) => {
		// set the authenticated user
		const user = response.data.data;

		// The PinAuthentication is being skipped here as it is dispatch later from the registration page
		dispatch(setTheAuthenticatedUser(user, true));
		return response;
	});
};

/**
 * Creates a new user based on the information coming from a short form
 *
 * @param userObject (country, email, password, username)
 */
export const createAccountFromShortForm = (userObject) => (dispatch) => {
	return post('registration/createfull', userObject).then((response) => {
		// set the authenticated user
		const user = response.data.data;

		// The PinAuthentication is being skipped here as it is dispatch later from the registration page
		dispatch(setTheAuthenticatedUser(user, true));
		return response;
	});
};

/**
 * Goes to the registration page
 * Adds the current route and email to state
 *
 * @param email
 */
export const navigateToRegistration = (email) => (dispatch) => {
	dispatch(createAction(VERIFICATION_RESET, {}));
	dispatch(createAction(SET_REDIRECT_ROUTE, email));
	return dispatch(routeTo('/registration/step-1'));
};

/**
 * Clears the redirect from state
 */
export const clearRedirectRoute = () => (dispatch) => {
	dispatch(createAction(VERIFICATION_RESET, {}));
	return dispatch(createAction(CLEAR_REDIRECT_ROUTE));
};

/**
 * Saves the registration step in state
 *
 * @param step
 */
export const setRegistrationStep = (step) => (dispatch) => {
	dispatch(createAction(SET_REGISTRATION_STEP, step));
};

/**
 * Navigates to a registration step
 *
 * @param step
 */
export const navigateToStep = (step) => (dispatch) => {
	return dispatch(navigate(`/registration/${step}`, { trigger: true }));
};


export const setLocalUser = (user) => (dispatch) => {
	dispatch(createAction(VERIFICATION_SET_TOKEN, null));
	return dispatch(createAction(SET_LOCAL_USER, user));
};
export const navigateToGreenIdVerification = (user) => (dispatch) => {
	return dispatch(navigate('/registration/step-6', { trigger: true }));
};