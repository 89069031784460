import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { spacings } from '@tbh/ui-kit';

import { LineText, Text } from '@tbh/ui-kit';
import SportBettingAdd from '../../SportBettingAdd/SportBettingAdd';

const StyledSelectionItem = styled('div')`
	label: SelectionItem;

	${(props) =>
		css`
			display: flex;
			padding: ${spacings(props).constrictive}px;
		`};
`;

const StyledSelectionItem__Name = styled(Text)`
	label: SelectionItem__Name;

	${(props) => css`
		flex: 1;
		padding: ${spacings(props).compact}px ${spacings(props).compact}px ${spacings(props).compact}px
			${spacings(props).cozy}px;
	`};
`;

const SelectionItem = (props) => {
	const {
		selection_id,
		selection_name,
		selection_price,
		selection_line,
		addToMulti,
		addToSingle,
		buttonSize,
		disabled,
		priceBumpIsSelected,
		productId,
		fetchFixedPriceRollup,
		fetchRollTablePrice,
	} = props;

	let selectionPrice = selection_price;
	let selectionRolls = 0;
	if (priceBumpIsSelected) {
		selectionPrice = fetchFixedPriceRollup(false, selection_price);
		selectionRolls = fetchRollTablePrice(false, selection_price).rolls;
	}

	return (
		<StyledSelectionItem>
			<StyledSelectionItem__Name tag="span" size="-1">
				{selection_name} {<LineText line={selection_line} />}
			</StyledSelectionItem__Name>
			<SportBettingAdd
				key={selection_id}
				priceRollups={selectionRolls}
				className={css`
					float: right;
				`}
				metric={selectionPrice}
				productId={productId}
				selectionId={selection_id}
				hasMulti={!!addToMulti}
				addToSingle={addToSingle}
				addToMulti={addToMulti}
				bettingAvailable={!disabled}
				size={buttonSize}
				emphasisedType={priceBumpIsSelected ? 'background' : null}
			/>
		</StyledSelectionItem>
	);
};

SelectionItem.propTypes = {
	/** Action to add the selection to the bet prompt */
	addToSingle: PropTypes.func.isRequired,

	/** The ID of the selection */
	selection_id: PropTypes.number.isRequired,

	/** The name of the selection */
	selection_name: PropTypes.string.isRequired,

	/** The price of the selection */
	selection_price: PropTypes.number.isRequired,

	/** The productId available for the event */
	productId: PropTypes.number.isRequired,

	/** Action to fetch the rolled up price of a boosted fixed price */
	fetchFixedPriceRollup: PropTypes.func.isRequired,

	/** Action to fetch the amount of rolls for a price */
	fetchRollTablePrice: PropTypes.func.isRequired,

	/** The line of the selection */
	selection_line: PropTypes.any,

	/** Action to add the selection to the multi bet slip */
	addToMulti: PropTypes.func,

	/** The size of the BettingAdd button */
	buttonSize: PropTypes.string,

	/** Disable the BettingAdd button */
	disabled: PropTypes.bool,

	/** If the price bump is selected or not */
	priceBumpIsSelected: PropTypes.bool,
};

SelectionItem.defaultProps = {
	selection_line: null,
	addToMulti: null,
	buttonSize: '1', // Previously '1', changed at Bryan's request
	disabled: false,
	priceBumpIsSelected: false,
};

export default SelectionItem;
