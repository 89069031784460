
import styled, { css } from 'react-emotion';
import { spacings, media } from '@tbh/ui-kit';

import NewNextToJumpRacesContainer from '../../../containers/Racing/NewNextToJumpRacesContainer'; // @HW new file
import NextToJumpRacesContainerV4 from '../../../containers/Racing/NextToJumpRacesContainerV4'; // @HW new file



export const StyledNextJumpHomePage = styled('div')`
	${(props) =>
		css`
			label: NextJumpHomePage;
			margin: ${spacings(props).compact}px;
			position: relative;
			z-index: 0; // This stops the loading mask from overlapping the header bar
		`};
`;

export const StyledNextJumpHomePage__NextToJumpRaces = styled(NewNextToJumpRacesContainer)`
	${(props) =>
		css`
			label: NextJumpHomePage__NextToJumpRaces;

			
			${!props.isRacingHome &&
				css`
					display: none;
				`};
		`};

	${media(
		(props) =>
			!props.isRacingHome &&
			css`
				display: flex;
			`,
		500,
	)};
`;

/**
 * V4 Next2JumpContainer
 */
export const StyledNextJumpHomePage__NextToJumpRacesV4 = styled(NextToJumpRacesContainerV4)`
	${(props) =>
		css`
			label: RacingHomePage__NextToJumpRaces;
			margin-top: ${spacings(props).compact}px;

			${!props.isRacingHome &&
				css`
					display: none;
				`};
		`};

	${media(
		(props) =>
			!props.isRacingHome &&
			css`
				display: flex;
			`,
		500,
	)};
`;

export const StyledNextJumpHomePage__Content = styled('div')`
	label: NextJumpHomePage__Content;
	${media(
  css`
			display: block;
			margin-top:25px;
			margin-bottom:25px;
		`,
  767,
)};
`;
export const StyledNextJumpHomePage__Card__Body = styled('div')`
	${(props) =>
  css`
			label: NextJumpHomePage__Card_Body;
			background-color: #fff;
		`};
`;

export const StyledNextJumpRacingHomePage_Header_section = styled('div')`
	${(props) =>
		css`
			label: NextJumpRacingHomePage_Header_section;
			margin: ${spacings(props).compact}px;
			position: relative;
			z-index: 0; // This stops the loading mask from overlapping the header bar
		`};
`;

export const StyledNextJumpRacingHomePage_maijor_header = styled('div')`
	${(props) =>
		css`
			label: NextJumpRacingHomePage_maijor_header;
			
			border-bottom: 3px solid #c72128;
			clear: both;
			display: flex;
			justify-content: space-between;
		`};
`;

export const StyledNextJumpRacingHomePage_Major_header_h2 = styled('h2')`
	${(props) =>
		css`
			label: NextJumpRacingHomePage_Major_header_h2;
			

			text-transform: uppercase;
			font-weight: bold;
			background: #c72128;
			border-color: #c72128;
			color: white;
			text-transform: uppercase;
			display: inline-block;
			margin: 0;
			padding: 10px;
			height: auto;
			position: relative;
			font-size: 16px;
			letter-spacing: 0.32px;
			text-align: center;
		`};
`;


