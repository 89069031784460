// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings } from '@tbh/ui-kit';

const StyledSvg = styled('svg')`
	label: Svg;

	${(props) => {
		if (props.push) {
			return css`
				margin-right: ${spacings(props).tight * props.push}px;
			`;
		}
	}};
`;

const Svg = (props) => {
	const { name, hrefBase, className, onClick, push, width, height } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<StyledSvg
			className={componentClasses}
			width={width}
			height={height}
			push={push}
			xmlns="http://www.w3.org/2000/svg"
			onClick={onClick}
		>
			<use xlinkHref={`${hrefBase}icon-${name}`} />
		</StyledSvg>
	);
};

Svg.propTypes = {
	/** The Icon name */
	name: PropTypes.string.isRequired,

	/** The base URL for the icon HREF */
	hrefBase: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,

	/** Action for the Svg */
	onClick: PropTypes.func,

	/** Margin-right to add to the svg */
	push: PropTypes.oneOf(['1', '2', '3', '4', '5']),

	/** Height in px */
	height: PropTypes.string,

	/** Width in px */
	width: PropTypes.string,
};

Svg.defaultProps = {
	hrefBase: '#',
	className: '',
	onClick: undefined,
	push: null,
	height: '16',
	width: '16',
};

export default Svg;
