// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

// Components
import { Text, BetSlipButton, HeaderBar } from '@tbh/ui-kit';

const cssIframeMasthead = css`
	label: IframeMasthead;

	justify-content: flex-end;
`;

const IframeMasthead = (props) => {
	const { bets, className, onBetSlipClick, t } = props;

	const componentClasses = cx(cssIframeMasthead, {
		[className]: className,
	});

	return (
		<HeaderBar
			className={componentClasses}
			type={HeaderBar.types.PRIMARY}
			aside={<BetSlipButton onClick={onBetSlipClick} bets={bets} noLabel />}
			meta={
				<Text tag="span" size="-1" strong>
					{t('BetSlip')}
				</Text>
			}
		/>
	);
};

IframeMasthead.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Action to fire when the bet slip badge is clicked on */
	onBetSlipClick: PropTypes.func.isRequired,

	/** The total number of bets in the multi slip */
	bets: PropTypes.number,

	/** Extra classes */
	className: PropTypes.string,
};

IframeMasthead.defaultProps = {
	bets: 0,
	className: '',
};

export default withNamespaces()(IframeMasthead);
