import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
// import backend from 'i18next-xhr-backend';
import { reactI18nextModule } from 'react-i18next';

/**
 * List of the ISO-639 2 letter country codes can be found here
 *  https://www.loc.gov/standards/iso639-2/php/English_list.php
 */
import translationEN from './locales/en/translation';

import translationPL from './locales/pl/translation';
import translationManualPL from './locales/pl/translationManual';

import translationTR from './locales/tr/translation';
import translationManualTR from './locales/tr/translationManual';

import translationJA from './locales/ja/translation';
import translationManualJA from './locales/ja/translationManual';

import translationRU from './locales/ru/translation';
import translationManualRU from './locales/ru/translationManual';


/**
 * The translations
 * When adding a new language you need to update the whitelist ALLOWED_LANGUAGES in applicationConstants
 * You should also ensure the relevant locales are loaded in momentjs-setup and numeral-setup
 */
const resources = {
	en: {
		translation: translationEN,
	},
	pl: {
		translation: { ...translationPL, ...translationManualPL },
	},
	tr: {
		translation: { ...translationTR, ...translationManualTR },
	},
	ja: {
		translation: { ...translationJA, ...translationManualJA },
	},
	ru: {
		translation: { ...translationRU, ...translationManualRU },
	},
};

i18n
	.use(detector) // auto detect language
	// .use(backend)
	.use(reactI18nextModule) // passes i18n down to react-i18next
	.init({
		// Filter the console notifications with 'missingKey' or 'i18n'
		// debug: process.env.NODE_ENV !== 'production',
		debug: false,

		resources,

		// ?lng=pl
		fallbackLng: 'en', // use en if detected lng is not available

		keySeparator: false, // use keys in form messages.welcome

		interpolation: {
			escapeValue: false, // react already saves from xss
		},
	});

export default i18n;
