/* eslint-disable no-debugger */
'use strict';

import {get} from '../../../common/Ajax';
import {normalizeMarketTypeGroups} from '../schemas/MarketTypeGroupSchema';
import {mergeEntities} from '../../../common/actions/actionHelpers';

/**
 * Fetch the market groups, markets, selections for a given event
 *
 * @param event_id
 */
export const fetchCombinedMarketsGroupsWithSelections = (event_id) => (dispatch) => {
	return get('combined/markets/selections', {
		params: {event_id}
	}).then((response) => {
		let marketGroups = response.data.data;

		if(marketGroups.length < 1 ) {
			return;
		}

		let marketIds = [];

		/**
		 * Adds marketTypeGroup id to each received market.
		 * and to event's markets field.
		 */
		marketGroups.forEach((marketGroup) => {
			marketGroup.markets instanceof Array  ?
				marketGroup.markets.forEach((market) => {
					market.markettypegroup = marketGroup.id;

					if (!market.event_id) {
						return;
					}
					marketIds.push(market.id);
				})
			: null ;
		});

		let entities = normalizeMarketTypeGroups(marketGroups).entities;
		entities.events = {
			[event_id]: {
				id: event_id,
				markets: marketIds
			}
		};
			dispatch(mergeEntities(entities));
	});
};
