// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { connect } from 'react-redux';

//Components
import { LoadingMask } from '@tbh/ui-kit';
import TrendingBets from '../../components/features/Betting/TrendingBets/TrendingBets';

//Functions
import { fetchTrendingBets } from '../../store/entities/actions/TrendingBetActions';
import { loadDataForSelections, showBetPromptForTrendingBet } from '../../store/entities/actions/BetActions';
import { selectEvent } from '../../store/sportsHome/sportsHomeActions';
import { buildTrendingBets } from '../../pages/Sports/SportsHome/sportsHomeSelectors';
import { addSelectionToMultiBetSlip } from '../../common/actions/multiActions';
import { validate, rebuildLegs, updateLegStake } from '../../store/betPlacement/multiBetPlacementActions';

class TrendingBetsContainer extends Component {
	static propTypes = {
		trendingBets: PropTypes.array.isRequired,
		trendingBetsCollapsed: PropTypes.bool.isRequired,
		handleShowBetPrompt: PropTypes.func.isRequired,
		showBetPromptForTrendingBet: PropTypes.func.isRequired,
		addTrendingSelectionToBetSlip: PropTypes.func.isRequired,
		trendingBetsLoadingStart: PropTypes.func.isRequired,
		trendingBetsLoadingSuccess: PropTypes.func.isRequired,
		updateLegStake: PropTypes.func.isRequired,
		rebuildLegs: PropTypes.func.isRequired,
		validate: PropTypes.func.isRequired,
		fetchTrendingBets: PropTypes.func.isRequired,
		loadDataForSelections: PropTypes.func.isRequired,
		onClickCollapse: PropTypes.func.isRequired,
		navigateToEvent: PropTypes.func.isRequired,
		className: PropTypes.string,
	};

	static defaultProps = {
		className: null,
	};

	state = {
		trendingBetsLoading: true,
	};

	componentDidMount() {
		this.props.trendingBetsLoadingStart();

		// Load trending bets
		this.props.fetchTrendingBets().finally(() => {
			this.props.loadDataForSelections(this.props.trendingBets);
			this.props.trendingBetsLoadingSuccess();
			this.setState({
				trendingBetsLoading: false,
			});
		});
	}

	/**
	 * Handles when the odds are clicked on a trending bet.
	 *
	 * @param id
	 */
	handleTrendingBetOddsClick = (id) => {
		const { trendingBets, handleShowBetPrompt, showBetPromptForTrendingBet } = this.props;

		const bet = trendingBets.find((tBet) => tBet.id === id);

		showBetPromptForTrendingBet(bet);
		handleShowBetPrompt();
	};

	/**
	 * Handles when the event name is clicked on a trending bet.
	 *
	 * @param id
	 */
	handleTrendingBetEventClick = (id) => {
		const bet = this.props.trendingBets.find((tBet) => tBet.id === id);
		this.props.navigateToEvent(bet.competition_id, bet.event_id);
	};

	/**
	 * Adds all trending bets to the bet slip with the given stake.
	 *
	 * @param stake
	 */
	addTrendingBetsToBetSlip = (stake) => {
		const { trendingBets, updateLegStake, rebuildLegs, validate } = this.props;

		trendingBets.forEach(this.props.addTrendingSelectionToBetSlip);
		validate();
		rebuildLegs();
		updateLegStake(trendingBets.length, stake);
	};

	render() {
		const { trendingBets, className } = this.props;
		const { trendingBetsLoading } = this.state;

		return (
			<div
				className={cx({
					[className]: className,
				})}
			>
				<LoadingMask loading={trendingBetsLoading} />
				<TrendingBets
					bets={trendingBets}
					onClickAdd={this.addTrendingBetsToBetSlip}
					onClickEvent={this.handleTrendingBetEventClick}
					onClickSingle={this.handleTrendingBetOddsClick}
					isCollapsed={this.props.trendingBetsCollapsed}
					onCollapse={this.props.onClickCollapse}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		trendingBetsLoadingStart: ownProps.trendingBetsLoadingStart,
		trendingBetsLoadingSuccess: ownProps.trendingBetsLoadingSuccess,
		trendingBetsCollapsed: ownProps.trendingBetsCollapsed,
		onClickCollapse: ownProps.onClickCollapse,
		trendingBets: buildTrendingBets(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchTrendingBets: () => dispatch(fetchTrendingBets()),
		loadDataForSelections: (selectionsList) => dispatch(loadDataForSelections(selectionsList)),
		showBetPromptForTrendingBet: (bet) => dispatch(showBetPromptForTrendingBet(bet)),
		addTrendingSelectionToBetSlip: (bet) => dispatch(addSelectionToMultiBetSlip(bet, false)),
		navigateToEvent: (competitionId, eventId) => dispatch(selectEvent(competitionId, eventId)),
		updateLegStake: (legId, stake) => dispatch(updateLegStake(legId, stake)),
		rebuildLegs: () => dispatch(rebuildLegs()),
		validate: () => dispatch(validate()),
	};
};

const Container = connect(
	mapStateToProps,
	mapDispatchToProps,
)(TrendingBetsContainer);

Container.propTypes = {
	className: PropTypes.string,
	trendingBetsCollapsed: PropTypes.bool,
	trendingBetsLoadingStart: PropTypes.func,
	trendingBetsLoadingSuccess: PropTypes.func,
	onClickCollapse: PropTypes.func,
};

Container.defaultProps = {
	className: null,
	trendingBetsCollapsed: false,
	trendingBetsLoadingStart: () => undefined,
	trendingBetsLoadingSuccess: () => undefined,
	onClickCollapse: () => undefined,
};

export default Container;
