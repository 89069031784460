import React, { Component } from 'react';
import { Container, StyledSection, StyledSectionTitle } from '../../Components';
import { Text } from '@tbh/ui-kit';

class StatementSection extends Component {
	render() {
		return (
			<div>
				<Text size="1">Statements</Text>
				<Container>
					<Text size="-2">
						The Activity for your {this.props.name} account will be emailed to you every month where a transaction
						occurs.
					</Text>
				</Container>
			</div>
		);
	}
}

export { StatementSection };
