import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings } from '@tbh/ui-kit';
import { Trans, withNamespaces } from 'react-i18next';

// Components
import {
	Button,
	CreditCardSecurityInput,
	Input,
	Text,
	CARD_VERIFICATION_AMOUNT,
	MASK_CC_VERIFY_AMOUNT,
} from '@tbh/ui-kit';

const cssCreditCardVerification__Wrapper = css`
	display: flex;
	flex-direction: column;
	padding: 10px;
	justify-content: space-around;
	align-items: center;
`;

const StyledCreditCardVerification__SecurityInput = styled(CreditCardSecurityInput)`
	${(props) => css`
		label: CreditCardVerification__SecurityInput;

		flex: 0 1 50%;
		margin-right: ${spacings(props).cozy}px;
	`};
`;
const StyledCreditCardVerification__SecurityInputInput = StyledCreditCardVerification__SecurityInput.withComponent(
	Input,
);

const StyledCreditCardVerification__FooterMessage = styled(Text)`
	${(props) => css`
		label: CreditCardVerification__FooterMessage;

		margin-top: ${spacings(props).cozy}px;
	`};
`;

const StyledCreditCardVerification__Button = styled(Button)`
	${(props) => css`
		label: CreditCardVerification__Button;

		margin-top: ${spacings(props).compact}px;
		border-radius: 4px;
		width: 100%;
	`};
`;

const StyledCreditCardVerification__Link = styled('p')`
	border: 1px solid #666666;
	padding: 4px 8px!important;
    border-bottom: 2px solid #666666;
	border-radius: 4px;
	&:hover {
		background-color: #666666;
		color: #ffffff!important;
	}
`;

const CreditCardVerification = (props) => {
	const {
		t,
		className,
		creditCardSecurityCode,
		loadingCardVerification,
		onChange,
		onStartCardVerification,
		onVerifyCardSubmit,
		verificationStep,
		verifyAmount,
		verifyAmountIsValid,
		verificationFailed,
		verificationAttemptsLeft,
		skipCardVerification,
		verifyCreditCardsOnly,
		handleClose
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	// Verification was successful

	if (verificationFailed === false) {
		return (
			<React.Fragment>
				<div className={cssCreditCardVerification__Wrapper}>
					<StyledCreditCardVerification__FooterMessage size="-2" paragraph>
						Verification successful, now you can withdraw your funds with this card.
					</StyledCreditCardVerification__FooterMessage>
					<StyledCreditCardVerification__Button
						type="primary"
						action={() => handleClose()}
						flex
					>
						{t('Continue')}
					</StyledCreditCardVerification__Button>
				</div>
			</React.Fragment>
		);
	}

	return (
		<div className={componentClasses}>
			{(() => {
				switch (verificationStep) {
					case 'verify-card':
						return (
							<React.Fragment>
								<div className={cssCreditCardVerification__Wrapper}>
									{!verifyCreditCardsOnly && (
										<StyledCreditCardVerification__SecurityInput
											onChange={onChange}
											name="creditCardSecurityCode"
											label={t('SecurityCode')}
											value={creditCardSecurityCode}
										/>
									)}
									<StyledCreditCardVerification__Button
										action={onStartCardVerification}
										type="secondary"
										loading={loadingCardVerification}
										flex
									>
										{`${t('VerifyCard')}*`}
									</StyledCreditCardVerification__Button>
								</div>
								<StyledCreditCardVerification__FooterMessage size="-2">
									{`*${t('CreditCardVerification__VerifyCreditCard')}`}
								</StyledCreditCardVerification__FooterMessage>
							</React.Fragment>
						);

					case 'verify-amount':
						return (
							<React.Fragment>
								<div className={cssCreditCardVerification__Wrapper}>
									<StyledCreditCardVerification__SecurityInputInput
										onChange={onChange}
										name="verifyAmount"
										label={t('VerifyAmount')}
										value={verifyAmount}
										constraints={CARD_VERIFICATION_AMOUNT}
										mask={MASK_CC_VERIFY_AMOUNT}
										type="tel"
									/>
									<StyledCreditCardVerification__Button
										type="primary"
										loading={loadingCardVerification}
										action={onVerifyCardSubmit}
										disabled={!verifyAmountIsValid}
										flex
									>
										{t('Verify')}
									</StyledCreditCardVerification__Button>
								</div>
								<div className={css`padding: 0px 12px`}>
									<React.Fragment>
										<StyledCreditCardVerification__FooterMessage size="-2"
											paragraph
											className={css`text-align:justify`}
										>
											{t('CreditCardVerification__EnterVerification')}
										</StyledCreditCardVerification__FooterMessage>
										<Text size="-2" paragraph>
											<Trans i18nKey="CreditCardVerification__Example">
												If the deposit amount was $50.43 - enter 43.
												<br />
												If it was $50.07 - enter 7.
											</Trans>
										</Text>
									</React.Fragment>

									<StyledCreditCardVerification__Button
										action={() => handleClose()}
										size="-2"
										type="plain"
										underline
										block
									>
										{t('VerifyLater')}
									</StyledCreditCardVerification__Button>
								</div>
							</React.Fragment>
						);
					case 'blocked':
						return (
							!verifyCreditCardsOnly && (
								<StyledCreditCardVerification__SecurityInput
									onChange={onChange}
									name="value"
									label={t('SecurityCode')}
									value={creditCardSecurityCode}
									disabled
								/>
							)
						);
				}
			})()}
		</div>
	);
};

CreditCardVerification.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Action to fire when an input is changed */
	onChange: PropTypes.func.isRequired,

	/** loading state for the button */
	loadingCardVerification: PropTypes.bool,

	/** value for the Security Code input */
	creditCardSecurityCode: PropTypes.string,

	/** Which verification step are we rendering */
	verificationStep: PropTypes.oneOf(['verify-card', 'verify-amount', 'blocked']),

	/** value for the Verify Amount input */
	verifyAmount: PropTypes.string,

	/** If the verification code is valid or not */
	verifyAmountIsValid: PropTypes.bool,

	/** If the verification amount process failed or not */
	verificationFailed: PropTypes.bool,

	/** The user has 3 attempts to verify the cent amount */
	verificationAttemptsLeft: PropTypes.number,

	/** Function to skip the verification until later */
	skipCardVerification: PropTypes.func,

	/** Action to start card verification */
	onStartCardVerification: PropTypes.func,

	/** Action to verify amount */
	onVerifyCardSubmit: PropTypes.func,

	/** If only the credit card verification should be displayed or not */
	verifyCreditCardsOnly: PropTypes.bool,

	/** Extra classes */
	className: PropTypes.string,
};

CreditCardVerification.defaultProps = {
	className: '',
	loadingCardVerification: false,
	creditCardSecurityCode: '',
	verificationStep: 'verify-card',
	verifyAmount: '',
	verifyAmountIsValid: false,
	verificationFailed: false,
	verificationAttemptsLeft: null,
	skipCardVerification: undefined,
	onStartCardVerification: () => { },
	onVerifyCardSubmit: () => { },
	verifyCreditCardsOnly: false,
};

export default withNamespaces()(CreditCardVerification);
