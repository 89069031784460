import { createSelector } from 'reselect';

import { denormalizeBaseCompetitions } from '../../../store/entities/schemas/BaseCompetitionSchema';
import { denormalizeNextToJumpSports } from '../../../store/entities/schemas/NextToJumpSportSchema';
import { denormalizeSports } from '../../../store/entities/schemas/SportSchema';
import { getSportsEntities } from '../../../store/entities/schemas/relationships/SportsRelationships';
import { denormalizeTrendingBets } from '../../../store/entities/schemas/TrendingBetSchema';
import { filterBetsByType, filterUnresultedBets } from '../../../store/entities/selectors/BetSelectors';
import { denormalizeBets } from '../../../store/entities/schemas/BetSchema';
import { denormalizeEvents } from '../../../store/entities/schemas/EventSchema';
import { getEventsSortedByStartDate } from '../../../store/entities/selectors/EventSelectors';
import { getMarketTypeGroupsSortedByOrderThenName } from '../../../store/entities/selectors/MarketTypeGroupSelectors';
import { getMarketsSortedByOrderThenName } from '../../../store/entities/selectors/MarketSelectors';
// import { META_PAGE_DESCRIPTION_SPORTS, META_PAGE_TITLE_SPORTS } from '../../../common/constants/Meta';

/**
 *  builds data for the ntj sports grid
 */
export const getNextToJumpSports = createSelector(
	(entities) => entities.nextToJumpSports,
	(nextToJumpSports) => {
		return denormalizeNextToJumpSports({ nextToJumpSports }).sort((a, b) => {
			return a.start_time - b.start_time;
		});
	},
);

/**
 * Builds data for the base competitions carousel.
 */
export const getBaseCompetitions = createSelector(
	(state) => state.sportsHome.selectedSport,
	(state) => getSportsEntities(state.entities),
	(selectedSportId, entities) => {
		//If no sport is selected it's impossible to determine which base competitions to render.
		if (!selectedSportId) {
			return [];
		}
		const sport = denormalizeSports(entities, [selectedSportId]);

		//De-normalization of unknown ids result in array of undefined
		// so it's important assure the entity before assessing it.
		let baseCompetitions = (sport[0] && sport[0].base_competitions) || [];
		baseCompetitions = baseCompetitions.map((bc) => {
			if (bc.icon) {
				return bc;
			}

			return {
				...bc,
				icon: sport[0].icon,
			};
		});
		return baseCompetitions.sort((bcA, bcB) => bcA.order - bcB.order);
	},
);

/**
 * Builds data for the competition selector.
 */
export const buildCompetitions = createSelector(
	(state) => state.sportsHome.selectedBaseCompetition,
	(state) => getSportsEntities(state.entities),
	(baseCompetitionId, entities) => {
		//If no base competition is selected it's impossible to build competition selector.
		if (!baseCompetitionId) {
			return [];
		}
		const baseCompetition = denormalizeBaseCompetitions(entities, [baseCompetitionId]);
		//We need to assure whether a base competition with such id exists.
		return (baseCompetition[0] && baseCompetition[0].competitions) || [];
	},
);

/**
 * Builds data for trending bets in the side bar.
 */
export const buildTrendingBets = createSelector(
	(state) => state.entities.trendingBets,
	(trendingBets) => {
		const tBets = denormalizeTrendingBets({ trendingBets });
		tBets.sort((a, b) => a.odds - b.odds);
		return tBets;
	},
);

/**
 * Build data for Sports Live bets in the sidebar.
 *
 */
export const getBets = createSelector(
	(entities) => entities,
	(entities) => {
		return filterUnresultedBets(filterBetsByType(denormalizeBets(entities), ['sports', 'sport']));
	},
);

/**
 * Memoized selector to get the events
 */
export const getEvents = createSelector(
	(state) => state.entities,
	(state) => state.sportsHome.selectedCompetition,
	(state) => state.sportsHome.selectedEvent,
	(entities, selectedCompetition, selectedEvent) => {
		return getEventById(entities, selectedCompetition, selectedEvent);
	},
);

/**
 * Return whether or not the event is in the future
 *
 * @param event
 * @returns {*|boolean}
 */
export const isFutureEvent = (event) => event.start_date && new Date(event.start_date) >= Date.now();

/**
 * Get event from store by providing the associated competition and event id
 *
 * @param entities
 * @param selectedCompetition
 * @param selectedEvent
 * @returns {*}
 */
export const getEventById = (entities, selectedCompetition, selectedEvent) => {
	const denormalizedEvents = denormalizeEvents(entities).filter((event) => {
		// There is an issue where an event id comes down, but we don't have the rest of the event information. This will
		// filter out the events that don't have a name. This will stop those events presenting to the user, but the issue
		// will need to be fixed
		if (!event || !event.name) {
			return false;
		}

		// Ensure whether we are only showing events in the future.
		return isFutureEvent(event);
	});

	if (!selectedCompetition && !selectedEvent) {
		// Get ntj Events
		const ntjEventIds = Object.keys(entities.nextToJumpSports);
		const ntjEvents = getEventsSortedByStartDate(denormalizedEvents, null, ntjEventIds);
		return ntjEvents.slice(0, 5);
	}

	return getEventsSortedByStartDate(denormalizedEvents, selectedCompetition, selectedEvent);
};

/**
 * Memoized selector for market groups.
 */
export const buildEventMarketGroups = createSelector(getEvents, (events) => {
	const groupings = {};
	events.forEach((event) => {
		if (!event) {
			return;
		}
		// Creates new groupings array for the event
		const eventGroupings = [];

		if (!event.markets) {
			return;
		}

		// Group every market for the event
		event.markets.forEach((market) => {
			const marketTypeGroup = market.markettypegroup;
			const grouping = eventGroupings.find((group) => group.id === marketTypeGroup.id);

			if (grouping) {
				grouping.markets.push(market);
			} else {
				eventGroupings.push({
					id: marketTypeGroup.id,
					name: marketTypeGroup.name,
					markets: [market],
					order: marketTypeGroup.order,
				});
			}

			if (market.sportSelections instanceof Array && market.sportSelections.length > 1) {
				market.sportSelections.sort((selA, selB) => selA.price - selB.price);
			}
		});

		// Sort each of the market groups markets by 'order' then 'name'
		eventGroupings.forEach((group) => {
			group.markets = getMarketsSortedByOrderThenName(group.markets);
		});

		// Sort each of the market groups themselves by 'order' then 'name'
		groupings[event.id] = getMarketTypeGroupsSortedByOrderThenName(eventGroupings);
	});

	return groupings;
});

/**
 * Builds html document title and and description metadata
 */
export const getPageMeta = createSelector(
	(state) => state.entities.sports[state.sportsHome.selectedSport],
	(state) => state.entities.competitions[state.sportsHome.selectedCompetition],
	(state) => state.entities.events[state.sportsHome.selectedEvent],
	(state) => state.acl.brandDetails,
	(sport, competition, event, brandDetails) => {
		const brandName = brandDetails ? brandDetails.name : '';
		let title = `Sport Betting Online ${brandName ? `at ${brandName}` : ''}`;
		let description = `Sport Betting Odds Online ${brandName ? `at ${brandName}` : ''}.`;

		if (sport) {
			title = `${sport.name} Betting Online ${brandName ? `at ${brandName}` : ''}`;
			description = `Browse ${sport.name} Betting Odds Online ${brandName ? `at ${brandName}` : ''} & Claim a ${
				sport.name
			} Bonus Bet.`;
		}

		if (sport && competition) {
			title = `${competition.name} - ${sport.name} Odds${brandName ? ` | ${brandName}` : ''}`;
			description = `Bet on ${competition.name} matches ${brandName ? `at ${brandName}` : ''} & Browse Latest ${
				sport.name
			} Odds.`;
		}

		if (event && competition && sport) {
			title = `${event.name} Odds - ${competition.name}${brandName ? ` | ${brandName}` : ''}`;
			description = `Bet on ${event.name} match odds ${brandName ? `at ${brandName}` : ''} & Claim a ${
				sport.name
			} Bonus Bet.`;
		}

		return {
			title: title,
			description: description,
		};
	},
);

/**
 * Memoized selector to determine whether all the data required is loaded
 */
export const hasDataRequired = createSelector(
	(state) => state.sportsHome,
	(sportsHome) => !(sportsHome.selectedSport && !sportsHome.selectedCompetition),
);
