import { NEXT_TO_JUMP_CHANGE } from './newNextToJumpRacesTypes';

const initialState = {
	races: [],
};

const newNextToJumpRaces = (state = initialState, action) => {
	switch (action.type) {
		case NEXT_TO_JUMP_CHANGE:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default newNextToJumpRaces;