/**
 * Libs
 */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { ui } from '@tbh/ui-kit';
import { slugify } from '../../../../legacy/core/format';

/**
 * Components
 */
import NextSportItem from '../NextSportItem/NextSportItem';
import { Icon } from '@tbh/ui-kit';
import { buildSportRouteForObject } from '../../../../routes/Sports';
import Carousel from '../../Carousel/Carousel';

const StyledNextSportsList = styled(Carousel)`
	label: NextSportsList;

	min-height: 100px;
`;

const StyledNextSportsList__Item = styled(NextSportItem)(
	(props) => css`
		label: NextSportsList__Item;

		border-right: 1px solid ${ui(props).color_4};
	`,
);

const cssNextSportsList__CarouselWrapper = css`
	label: NextSportsList__CarouselWrapper;

	display: flex;
	align-items: stretch;
`;

const cssNextSportsList__CarouselSlide = css`
	label: NextSportsList__CarouselSlide;

	height: auto;
`;

const handleEventClick = (props) => (eventId) => {
	const event = props.items.find((item) => item.event_id === eventId);
	props.onItemClick(event.competition_id, event.event_id);
};

const NextSportsList = (props) => {
	const { className, items } = props;

	const blockClasses = cx({
		[className]: className,
	});

	if (items.length < 1) {
		return false;
	}

	return (
		<StyledNextSportsList
			nextButton
			prevButton
			freeMode
			buttonStyle="1"
			className={blockClasses}
			wrapperClassName={cssNextSportsList__CarouselWrapper}
			slideClassName={cssNextSportsList__CarouselSlide}
		>
			{items.map((item) => (
				<StyledNextSportsList__Item
					link={buildSportRouteForObject(item)}
					key={item.event_id}
					id={item.event_id}
					icon={<Icon color="secondary" icon={slugify(item.sport_name)} />}
					eventName={item.event_name}
					competitionName={item.competition_name}
					timeToGo={item.to_go}
					onClick={handleEventClick(props)}
				/>
			))}
		</StyledNextSportsList>
	);
};

NextSportsList.propTypes = {
	/** List of the next sports */
	items: PropTypes.arrayOf(
		PropTypes.shape({
			/** The Event ID for the sport */
			event_id: PropTypes.number.isRequired,

			/** The competition name that the event belongs to */
			competition_name: PropTypes.string.isRequired,

			/** The event name that the sport belongs to */
			event_name: PropTypes.string.isRequired,

			/** Time until the event starts */
			to_go: PropTypes.string.isRequired,

			/** The sport's name */
			sport_name: PropTypes.string.isRequired,
		}),
	).isRequired,

	/** Handle the sport item being clicked */
	onItemClick: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

NextSportsList.defaultProps = {
	className: null,
};

export default NextSportsList;
