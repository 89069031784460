import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'react-emotion';
import { spacings, Panel } from '@tbh/ui-kit';

import ThemeGroup from '../../components/features/Theme/ThemeGroup/ThemeGroup';
import ThemeJson from '../../components/features/Theme/ThemeJson/ThemeJson';

import { updateTheme } from '../../store/theme/themeActions';

const StyledThemeManagerContainer__Group = styled(ThemeGroup)(
	(props) => css`
		label: ThemeManagerContainer__Group;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const ThemeManagerContainer = (props) => {
	const { onUpdateTheme, theme } = props;

	/**
	 * Updates the theme
	 *
	 * @param value
	 * @param propertyName
	 * @param themeGroupName
	 * @param subItem
	 */
	const handleThemeUpdate = ({ value, propertyName, themeGroupName, subItem = null }) => {
		const newTheme = subItem
			? {
					[themeGroupName]: {
						[subItem]: {
							...theme[themeGroupName][subItem],
							[propertyName]: value,
						},
					},
			  }
			: {
					[themeGroupName]: {
						[propertyName]: value,
					},
			  };

		onUpdateTheme(newTheme);
	};

	return (
		<Panel>
			{Object.entries(theme).map(([themeGroupName, themeGroupContent]) => (
				<StyledThemeManagerContainer__Group
					key={themeGroupName}
					onThemeChange={handleThemeUpdate}
					themeGroup={themeGroupContent}
					themeGroupName={themeGroupName}
				/>
			))}
			<ThemeJson theme={theme} />
		</Panel>
	);
};

ThemeManagerContainer.propTypes = {
	/** Updates the theme store */
	onUpdateTheme: PropTypes.func.isRequired,

	/** Theme store */
	theme: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		theme: state.theme,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateTheme: (newTheme) => {
			return dispatch(updateTheme(newTheme));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ThemeManagerContainer);
