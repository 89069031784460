import {
	SET_REDIRECT_ROUTE,
	CLEAR_REDIRECT_ROUTE,
	SET_REGISTRATION_STEP,
	SET_LOCAL_USER,
} from './registrationActionsTypes';

const initialState = {
	registrationEmail: null,
	redirectRoute: 'welcome',
	route: 'step-1',
	localUser: null,
};

const registration = (state = initialState, action) => {
	switch (action.type) {
		case SET_REDIRECT_ROUTE:
			return {
				...state,
				registrationEmail: action.payload,
				redirectRoute: Backbone.history.getFragment(),
			};

		case CLEAR_REDIRECT_ROUTE:
			return {
				...initialState,
			};

		case SET_REGISTRATION_STEP:
			return {
				...state,
				route: action.payload,
			};
		case SET_LOCAL_USER:
			return {
				...state,
				localUser: action.payload,
			};
		default:
			return state;
	}
};

export default registration;
