import {
	denormalize,
	normalize,
	schema,
} from 'normalizr';

// Schemas
const depositSchema = new schema.Entity('deposits');
const depositsSchema = new schema.Array(depositSchema);

/**
 * Denormalize entities back to a structure similar to the API response.
 *
 * @param {object} entities
 * @param {Array} keys
 * @returns {Array}
 */
export const denormalizeDeposits = (entities, keys = null) => {
	return denormalize(keys || Object.keys(entities.deposits), depositsSchema, entities);
};

/**
 * Normalize structure to suit our application.
 *
 * @returns {Object}
 * @param response
 */
export const normalizeDeposits = (response) => {
	return normalize(response, depositsSchema);
};
