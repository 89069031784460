import { Iframe } from '@tbh/ui-kit';

import AclManagerContainer from '../../Acl/AclManagerContainer';
import DepositsPromptContainer from '../../Deposit/DepositsPromptContainer';
import ExternalDepositResult from '../../../components/features/Deposit/ExternalDepositResult/ExternalDepositResult';
import LoginContainer from '../../Application/Login/LoginContainer';
import MobileAccountSummaryContainer from '../../Application/MobileAccountSummary/MobileAccountSummaryContainer';
import PINAuthenticationContainer from '../../Application/PINAuthentication/PINAuthenticationContainer';
import QuickBettingSummaryContainer from '../../Betting/QuickBettingSummary/QuickBettingSummaryContainer';
import ReminderDialogContainer from '../../ResponsibleGambling/RealityCheck/ReminderDialogContainer';
import ThemeManagerContainer from '../../Theme/ThemeManagerContainer';
import UKLimitConfirmationContainer from '../../ResponsibleGambling/UKLimitConfirmationContainer/UKLimitConfirmationContainer';
import InactivityDialogContainer from '../../Authentication/Inactivy/InactivyDialogConainter';

/**
 * We need to store a look up of the different Modals that can be loaded via an action
 */
const MODAL_COMPONENT_VIEWS = {
	AclManagerContainer,
	DepositsPromptContainer,
	ExternalDepositResult,
	Iframe,
	LoginContainer,
	MobileAccountSummaryContainer,
	PINAuthenticationContainer,
	QuickBettingSummaryContainer,
	ReminderDialogContainer,
	ThemeManagerContainer,
	UKLimitConfirmationContainer,
	InactivityDialogContainer
};

export default MODAL_COMPONENT_VIEWS;
