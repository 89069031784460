import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { resetPassword } from '../../../store/authentication/authenticationActions';
import PasswordResetRequest from '../../../components/features/UserAccount/PasswordResetRequest/PasswordResetRequest';
import PasswordResetRequestSuccess from '../../../components/features/UserAccount/PasswordResetRequestSuccess/PasswordResetRequestSuccess';

import { trackGaEvent } from '../../../store/trackingPixels/trackingActions';
class PasswordResetContainer extends React.Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Action to request the password reset */
		requestPasswordReset: PropTypes.func.isRequired,

		/** Is the reset request being processed */
		isRequestingReset: PropTypes.bool,

		/** Extra classes to the root node */
		className: PropTypes.string,
	};

	static defaultProps = {
		isRequestingReset: false,
		requestResetError: null,
		className: '',
	};

	state = {
		requestedReset: false,
		isRequestingReset: false,
		requestResetError: null,
		email: '',
		emailIsValid: false,
	};

	/**
	 * Handle a change in the email input field
	 *
	 * @param name
	 * @param value
	 * @param valid
	 */
	handleChangeEmail = (name, value, valid) => {
		if (name !== 'email') {
			return;
		}

		this.setState({
			requestResetError: null,
			email: value,
			emailIsValid: valid,
		});
	};

	/**
	 * Handle the reset password callback from form
	 */
	handleResetPassword = () => {
		if (!this.state.email) {
			// Show Message
			return;
		}

		this.setState({
			isRequestingReset: true,
		});

		// Call action to reset password
		this.props
			.requestPasswordReset(this.state.email)

			.then((response) => {
				let error = response.data.error;
				if (response.data.success) {
					// Set state to indicate we have requested password change
					this.setState({
						requestedReset: true,
						requestResetError: null,
					});
				} else if(error) {
					this.setState({
						requestResetError: error,
					});
				} else {
					this.setState({
						requestResetError: this.props.t('PasswordResetContainer__Error'),
					});
				}
			})
			.catch((er) => {
				let error = '';
				if (er.response && er.response.data && er.response.data.errors) {
					error = er.response.data.errors;
				} else {
					error = this.props.t('PasswordResetContainer__Error');
				}
				this.setState({
					requestResetError: error,
				});
			})
			.finally(() => {
				this.setState({
					isRequestingReset: false,
				});
			});
	};

	render() {
		const { className } = this.props;
		const { email, emailIsValid, requestedReset, isRequestingReset, requestResetError } = this.state;

		const containerClasses = cx({
			[className]: className,
		});

		if (requestedReset) {
			return <PasswordResetRequestSuccess className={containerClasses} />;
		}

		return (
			<PasswordResetRequest
				className={containerClasses}
				handleChange={this.handleChangeEmail}
				resetPassword={this.handleResetPassword}
				resetPasswordError={requestResetError}
				loadingResetPassword={isRequestingReset}
				email={email}
				enableRequest={emailIsValid}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		requestPasswordReset: (email) => {
			dispatch(trackGaEvent('Reset Password', 'Request', 'Send Email'));
			return dispatch(resetPassword(email));
		},
	};
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(PasswordResetContainer));
