// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

// Components
import { Input } from '@tbh/ui-kit';

import { MAX_BET_AMOUNT } from '../../../../../store/entities/constants/BetConstants';
import { DEFAULT_CURRENCY_CONFIGURATION } from '../../../../../containers/Application/Currency/CurrencyConstants';

import Format from '../../../../../legacy/core/format';
import env from '../../../../../common/EnvironmentVariables';

import CurrencyDisplay from '../CurrencyDisplay/CurrencyDisplay';
import AclContext from '../../../../../contexts/AclContext';

const StyledCurrencyInput = styled(Input)`
	input::-ms-clear {
		display: none;
	}
`;

class CurrencyInput extends Component {
	/**
	 * Limit the amount that can be input into the currency input
	 *
	 * @param amount
	 * @returns {*}
	 */
	static limitInputAmount = (amount) => {
		if (amount > MAX_BET_AMOUNT) {
			return MAX_BET_AMOUNT;
		}

		return amount;
	};

	static getValuesToSet = (value) => {
		const amount = value ? CurrencyInput.limitInputAmount(Math.floor(Format.dollarsAsCents(value))) : '';
		return {
			value: amount, // Number in cents
			inputValue: value, // Number as integer (cents) formatted as dollars
		};
	};

	static propTypes = {
		/** Hook for when the value of the input changes */
		onChange: PropTypes.func.isRequired,

		/** Name attribute for the input */
		name: PropTypes.string.isRequired,

		/** Value (in cents) to be formatted & displayed */
		value: PropTypes.string.isRequired,

		className: PropTypes.string,

		/** Error message */
		error: PropTypes.string,

		/** Currency type */
		currencyType: PropTypes.string,

		/** Hook for when the input loses focus */
		onBlur: PropTypes.func,

		/** hook for click event */
		onClick: PropTypes.func,

		/** Hook for when the input gains focus */
		onFocus: PropTypes.func,

		/**
		 * If the input should hide the error or not.
		 * The parent component can control when to show the error state with this prop.
		 */
		hideError: PropTypes.bool,

		/** Placeholder text for the input */
		placeholder: PropTypes.node,

		/** Function for when clicking the icon */
		action: PropTypes.func,

		/** Margin-bottom */
		margin: PropTypes.oneOf([null, 'compact', 'cozy']),

		/** Input's size */
		size: PropTypes.oneOf(Object.values(Input.sizes)),
	};

	static defaultProps = {
		onBlur: () => {},
		onFocus: () => {},
		onClick: () => {},
		className: null,
		error: null,
		hideError: null,
		placeholder: null,
		action: null,
		margin: null,
		size: 'medium',
		currencyType: 'currency',
	};

	constructor(props) {
		super(props);

		const currencyConfiguration =
			env('currencyConfiguration', DEFAULT_CURRENCY_CONFIGURATION) || DEFAULT_CURRENCY_CONFIGURATION;
		const currencyFormat = currencyConfiguration.format || DEFAULT_CURRENCY_CONFIGURATION.format;

		this.amountInput = React.createRef();

		this.state = {
			...CurrencyInput.getValuesToSet(Format.centsAsFormat(props.value, currencyFormat)),
			currencyFormat,
			prevProps: props,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const prevProps = prevState.prevProps;
		const value =
			prevProps.value === nextProps.value && parseInt(nextProps.value) === prevState.value
				? prevState.value
				: nextProps.value;

		return {
			...CurrencyInput.getValuesToSet(Format.centsAsFormat(value, prevState.currencyFormat)),
			prevProps: nextProps,
		};
	}

	handleBlur = (event) => {
		this.setState(CurrencyInput.getValuesToSet(Format.centsAsFormat(this.state.value, this.state.currencyFormat)), () =>
			this.props.onBlur(event),
		);
	};

	handleFocus = (event) => {
		this.props.onFocus(event);
	};

	handleChange = (name, value) => {
		// Prevent non-numeric input
		if (!/^\d*\.?\d*$/.test(value)) {
			return;
		}

		const state = CurrencyInput.getValuesToSet(Format.dollarsAsFormat(value, this.state.currencyFormat));
		this.setState(state, () => this.props.onChange(name, state.value));
	};

	handleKeyDown = (e) => {
		// Allow only numeric input, decimal point, and control keys
		if (!/[\d.]/.test(e.key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)) {
			e.preventDefault();
		}

		// Prevent multiple decimal points
		if (e.key === '.' && e.target.value.includes('.')) {
			e.preventDefault();
		}
	};

	handlePaste = (e) => {
		const pasteData = e.clipboardData.getData('text');
		if (!/^\d*\.?\d*$/.test(pasteData)) {
			e.preventDefault();
		}
	};

	render() {
		return (
			<AclContext.Consumer>
				{(acl) => {
					const currencyConfiguration = acl.currencyConfiguration || DEFAULT_CURRENCY_CONFIGURATION;
					const currencyPosition = currencyConfiguration.position || DEFAULT_CURRENCY_CONFIGURATION.position;

					return (
						<StyledCurrencyInput
							{...this.props}
							type="text"
							inputMode="decimal"
							nodeLeft={
								currencyPosition === 'left' && <CurrencyDisplay imageWidth={18} type={this.props.currencyType} />
							}
							nodeRight={
								currencyPosition === 'right' && <CurrencyDisplay imageWidth={18} type={this.props.currencyType} />
							}
							ref={this.amountInput}
							name={this.props.name}
							value={this.state.inputValue || ''}
							onBlur={this.handleBlur}
							onChange={this.handleChange}
							onFocus={this.handleFocus}
							onKeyDown={this.handleKeyDown}
							onPaste={this.handlePaste}
							maxLength={MAX_BET_AMOUNT.toString().length + 3} // Add 3 to allow for money formatting
						/>
					);
				}}
			</AclContext.Consumer>
		);
	}
}

export default CurrencyInput;
