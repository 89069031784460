export const getOSName = () => {
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;
	if (navigator.userAgent.match(/(mobi)/i)) {
		if (/windows phone/i.test(userAgent)) {
			return 'Windows Phone';
		}
		if (/android/i.test(userAgent)) {
			return 'Android';
		}
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return 'iOS';
		}
	} else {
		if (/Macintosh/i.test(userAgent)) {
			return 'Macintosh';
		}
		if (/Windows/i.test(userAgent)) {
			return 'Windows';
		}
		if (/Linux/i.test(userAgent)) {
			return 'Linux';
		}
	}

	return 'unknown';
};

export const getMobileOrDesktop = () => {
	if (navigator.userAgent.match(/(mobi)/i)) {
		return 'Mobile';
	}

	return 'Desktop';
};
