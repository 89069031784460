import {fetchAllSports, fetchSportsBySportName} from '../store/entities/actions/SportsActions';
import {denormalizeSports} from '../store/entities/schemas/SportSchema';
import {buildSportRoute} from '../routes/Sports';
import {denormalizeCompetitions} from '../store/entities/schemas/CompetitionSchema';
import {denormalizeBaseCompetitions} from '../store/entities/schemas/BaseCompetitionSchema';
import {fetchCompetitionWithEvents} from '../store/entities/actions/CompetitionActions';

const {buildSitemapItem} = require('../common/SitemapHelpers');

export const buildSportsSitemapFromAJAX = (store) => {
	let state;

	return new Promise(async (resolve) => {
		await store.dispatch(fetchAllSports());

		state = store.getState();
		let sports = denormalizeSports(state.entities);

		for (let sport of sports) {
			await store.dispatch(fetchSportsBySportName(sport.name));
		}

		state = store.getState();
		let competitions = denormalizeCompetitions(state.entities);

		for (let competition of competitions) {
			await store.dispatch(fetchCompetitionWithEvents(competition.id));
		}

		state = store.getState();
		sports = denormalizeSports(state.entities);

		let baseCompetitions = denormalizeBaseCompetitions(state.entities);

		let routes = [];

		if (Array.isArray(baseCompetitions)) {
			baseCompetitions.forEach((baseCompetition) => {
				let sport = sports.find((sport) => sport.id === baseCompetition.sport_id);
				routes.push(buildSitemapItem(buildSportRoute(sport)));

				if (Array.isArray(baseCompetition.competitions)) {
					baseCompetition.competitions.forEach((competition) => {
						routes.push(buildSitemapItem(buildSportRoute(sport, competition)));

						if (Array.isArray(competition.events)) {
							competition.events.forEach((event) => {
								routes.push(buildSitemapItem(buildSportRoute(sport, competition, event)));
							});
						}
					});
				}
			});
		}

		resolve({
			name: 'Sports',
			map: `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">${routes.join('')}</urlset>`,
		});
	});
};