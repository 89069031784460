import { CLOSE_BET_EXPORT, OPEN_BET_EXPORT } from './betExportAction';

const initialState = {
	selectedBet: null,
};

const betExport = (state = initialState, action) => {
	switch (action.type) {
		case CLOSE_BET_EXPORT:
			return {
				...state,
				selectedBet: null,
			};
		case OPEN_BET_EXPORT:
			return {
				...state,
				selectedBet: action.payload,
			};
		default:
			return state;
	}
};

export default betExport;
