import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { application, spacings, typography, ui, media, Text } from '@tbh/ui-kit';
import { navigateToNextToJumpRaceWithType } from '../../../../../store/GRSracingHome/GRSracingHomeActions';
import { trackNextToJump } from '../../../../../store/GRSracingHome/GRSracingTrackingActions';
import FeatureRaceSelectionDetails from './../FeatureRaceSelectionDetails/FeatureRaceSelectionDetails';
import { ListItem, Button, brand } from '@tbh/ui-kit';
import RaceBettingAdd from '../../../Racing/RaceBettingAdd/RaceBettingAdd';

/**
 * Define all the selection statuses & racing type codes as defined in the racing constants file
 */
import {
	RACING_TYPE_CODES,
	SELECTION_NOT_SCRATCHED_STATUS,
	SELECTION_LATE_SCRATCHED_STATUS,
	SELECTION_STATUSES,
	RACING_BET_TYPE_MARGIN,
	RACING_NON_EXOTIC_BET_TYPE,
} from '../../../../../common/constants/Racing';

const StyledFeatureRaceSelectionListItem = styled(ListItem)`
	${(props) =>
		css`
			label: FeatureRaceSelectionListItem;

			list-style: none;
			padding: ${spacings(props).tight}px 0;

			&:nth-of-type(3n + 1):hover,
			&:hover {
				background: ${ui(props).color_3};
				cursor: pointer;
			}
			${props.selection_status === SELECTION_LATE_SCRATCHED_STATUS &&
			css`
					&:before {
						display: block;
						position: absolute;
						background: ${ui(props).color_5};
						color: ${typography(props).color_1_alternate};
						padding: ${spacings(props).tight}px;
						width: 30px;
						height: 30px;
						border-radius: 20px;
						font-size: ${typography(props)['size_-2']};
						line-height: 24px;
						text-align: center;
						content: 'LS';
					}
				`};
		`};
`;
const StyledFeatureRaceSelectionListItem__Wrapper = styled('div')(
	(props) =>
		css`
			label: FeatureRaceSelectionListItem__Wrapper;

			// Set a min-height so the trends don't overlap
			//min-height: ${spacings(props).roomy}px;
			display: flex;
			align-items: center;
			//padding: ${spacings(props).tight}px;

			display: flex;
			justify-content: space-between;
			max-height: 54px;
		`,
);

const StyledRaceBettingAdd = styled(RaceBettingAdd)(
	(props) => css`
		flex: 0 0 ${application(props).racing.odds_button_width}px;
		max-width: ${application(props).racing.odds_button_width}px;
		margin: 0 ${spacings(props).constrictive}px;
		text-align: center;
		border-radius: 4px !important;

		&:last-child {
			margin-right: 0;
		}

		font-size: 12px;

		${props.scratched &&
		css`
				display: none;
			`};

		${props.betType === RACING_BET_TYPE_MARGIN &&
		css`
				margin: 0;
			`};

		${props.small &&
		css`
				flex: 0 0 ${application(props).racing.odds_button_width_small}px;
				max-width: ${application(props).racing.odds_button_width_small}px;
			`};
	`,
	media(
		(props) => css`
			${props.small &&
			css`
					flex: 0 0 ${application(props).racing.odds_button_width}px;
					max-width: ${application(props).racing.odds_button_width}px;
				`};
		`,
		660,
	),
);

const StyledFatureSelectionPricesList = styled('ul')`
	${(props) =>
		css`
			label: FatureSelectionPricesList;

			padding: ${spacings(props).constrictive}px 0 ${spacings(props).tight}px;
			margin-bottom: 8px;
		`};
`;

const StyledFeaurePriceListItem = styled(ListItem)`
	${(props) =>
		css`
			label: FeaurePriceListItem;

			list-style: none;

			float: right !important;
			padding: 2px 0;
		`};
`;

const StyledRaceSelectionsListItem__Flucs = styled(Text)`
	${(props) =>
		css`
			label: RaceSelectionsListItem__Flucs;

			display: none;
			margin-right: ${spacings(props).compact}px;
			font-size: 12px;
			letter-spacing: 1px;
		`};

	${media(
			css`
			display: block;
			font-size: 12px;
		`,
			660,
		)};
`;

const StyledRaceSelectionsListItem__FlucsChartButon = styled(Button)`
	${(props) =>
		css`
			label: RaceSelectionsListItem__FlucsChartButon;

			display: none;
			background-color: transparent;
		`};

	${media(
			css`
			display: block;
			background-color: transparent;
		`,
			768,
		)};
`;

const Styledot = styled('div')(
	(props) =>
		css`
			height: 25px;
			width: 25px;
			background-color: ${brand(props).color_2_dark};
			border-radius: 50%;
		`,
);

const FeatureRaceSelectionListItem = (props) => {
	const {
		selectionBetButtons,
		id,
		selection_status,
		number,
		name,
		silk,
		jockey,
		barrier,
		weight,
		bettingAvailable,
		last_starts,
		type_code,
		addToMulti,
		className,
		isMobileWidth,
		size,
		trainer,
		// displayedFlucs,
		ShowModal,
		pricesAvailable,
		allocation,
		onClickItem,
		onClickToRace,
		StartDate,
		MettingName,
		meetingId,
		raceId,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	// const allflucs = displayedFlucs.price === "" ? pricesAvailable && displayedFlucs.map(e => e.price).join(',') : [];
	// const AllFlucsPriceArray = displayedFlucs.price === "" ? allflucs && allflucs.split(',') : [];
	const allflucs = [];
	const AllFlucsPriceArray = [];

	// remove duplicate price
	const uniqueArray =
		AllFlucsPriceArray.length > 0 &&
		AllFlucsPriceArray.filter(function (item, pos) {
			return AllFlucsPriceArray.indexOf(item) == pos;
		});

	//const show_top3fluctuation = pricesAvailable && ( (displayedFlucs.slice(-3)).map(e => e.price).join(','));

	const last3FlucsPriceArray = pricesAvailable && uniqueArray.length > 0 && uniqueArray.slice(-3);
	const show_last3fluctuation =
		uniqueArray === false
			? []
			: uniqueArray
				.slice(-3)
				.map((e) => e)
				.join(',');

	// const AllfluctuationsPriceArray = displayedFlucs.price.length > 0 ? displayedFlucs.price.split(',') : [];
	// const AllfluctuationsTimeArray = displayedFlucs.time.length > 0 ? displayedFlucs.time.split(',') : [];

	//  // last 3 flucs odds
	// const last3FlucsPriceArray = AllfluctuationsPriceArray.slice((AllfluctuationsPriceArray.length - 3), AllfluctuationsPriceArray.length);

	// const flusArray = [];
	// AllfluctuationsPriceArray.map( function(Price,ke){
	// 	AllfluctuationsTimeArray.map( function(Ti, index){
	// 		if(ke == index){
	// 			flusArray.push({
	// 				time:Ti,
	// 				price:Price,
	// 			});
	// 		}

	//   });
	// });

	let ShowModle = (fuData, id, number, silk, raceNumber, last_starts, trainer, jockey, weight) => {
		ShowModal(fuData, id, number, silk, raceNumber, last_starts, trainer, jockey, weight);
	};

	//  const fuDataGraph = pricesAvailable && displayedFlucs.map(function (flucs) {
	// 	 // string price covert to numbert using var x = '10.23'*1 like that
	// 	return { fixTime:moment(flucs.time).format('M-D hh:mm') , fixed_odds:flucs.price* 1};
	//   });
	const fuDataGraph = [];

	const raceNumber = number;

	const handleClickToTest = (e) => {
		e.preventDefault();
		props.navigateToRaceWithType(type_code, StartDate, raceId, meetingId);
	};

	return (
		<React.Fragment>
			<StyledFeatureRaceSelectionListItem id={id} className={componentClasses}>
				<StyledFeatureRaceSelectionListItem__Wrapper onClick={handleClickToTest}>
					<div
						className={css`
							flex: 1 1 auto;
							display: flex;
							overflow: hidden;
						`}
					>
						<FeatureRaceSelectionDetails
							number={number}
							name={name}
							silk={silk}
							jockey={jockey}
							trainer={trainer}
							barrier={barrier}
							weight={weight}
							last_starts_string={last_starts}
							selection_status={selection_status}
							type_code={type_code}
							size={size}
							allocation={allocation}
						/>
					</div>

					{last3FlucsPriceArray && last3FlucsPriceArray.length > 0 && (
						<StyledRaceSelectionsListItem__Flucs size={size} align="right" type="primary">
							{show_last3fluctuation}
						</StyledRaceSelectionsListItem__Flucs>
					)}

					{fuDataGraph && fuDataGraph.length > 0 && (
						<StyledRaceSelectionsListItem__FlucsChartButon
							size={size}
							action={() =>
								ShowModle(fuDataGraph, name, number, silk, raceNumber, last_starts, trainer, jockey, weight)
							}
							name={selection_status}
						>
							<i className="fa fa-bar-chart fa-lg" aria-hidden="true" />
						</StyledRaceSelectionsListItem__FlucsChartButon>
					)}

					{selectionBetButtons.map((betButton) => (
						<StyledRaceBettingAdd
							key={`${betButton.index}_${betButton.product_id}_${betButton.product_code}_${betButton.bet_type}`}
							small={isMobileWidth ? selectionBetButtons.length > 1 : selectionBetButtons.length > 2}
							price={betButton.price}
							initialPrice={betButton.initialPrice}
							betType={betButton.bet_type}
							productId={betButton.product_id}
							selectionId={id}
							hasMulti={betButton.hasMulti}
							addToMulti={betButton.hasMulti ? addToMulti : null}
							bettingAvailable={bettingAvailable && betButton.productAvailable}
							priceRollups={betButton.priceRollups}
							emphasisedType={
								betButton.bet_type === RACING_NON_EXOTIC_BET_TYPE && selection_status === SELECTION_NOT_SCRATCHED_STATUS
									? 'background'
									: null
							}
							useWrapper={
								betButton.bet_type === RACING_NON_EXOTIC_BET_TYPE && selection_status === SELECTION_NOT_SCRATCHED_STATUS
							}
							scratched={selection_status !== SELECTION_NOT_SCRATCHED_STATUS}
							size={size}
						/>
					))}
				</StyledFeatureRaceSelectionListItem__Wrapper>
			</StyledFeatureRaceSelectionListItem>
		</React.Fragment>
	);
};

FeatureRaceSelectionListItem.propTypes = {
	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}),

	/** Action to handle the selection being selected for a single bet */
	addToSingle: PropTypes.func.isRequired,

	/** Action to handle the selection being selected for a multi bet */
	addToMulti: PropTypes.func.isRequired,

	/** Boolean indicating if betting is available ie. race isn't closed */
	bettingAvailable: PropTypes.bool.isRequired,

	/** The currently selected bet type */
	betType: PropTypes.string.isRequired,

	/**
	 * This is a code for the type of race it is
	 * ie. T - Thoroughbred | G - Greyhounds | H - Harness
	 */
	type_code: PropTypes.oneOf(RACING_TYPE_CODES).isRequired,

	/**
	 * This will be an array of bet buttons that will be displayed for each selection
	 * The order of display will be from left to right as given by the array.
	 */
	selectionBetButtons: PropTypes.arrayOf(
		PropTypes.shape({
			/** The ID of the product */
			product_id: PropTypes.number.isRequired,
			/** The code of the product */
			product_code: PropTypes.string.isRequired,
			/** The type of bet */
			bet_type: PropTypes.string.isRequired,
			/** The odds/price for the bet */
			price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			/** If a multi bet is available for this product */
			hasMulti: PropTypes.bool,
			/** If the product is available to be bet on */
			productAvailable: PropTypes.bool,
		}),
	).isRequired,

	displayPrices: PropTypes.array,

	/** The following props are race selection details which are pretty much what the name suggests! */
	/** Selections race number */
	id: PropTypes.number.isRequired,

	/** Selections race number */
	number: PropTypes.number.isRequired,

	/** Selections name */
	name: PropTypes.string.isRequired,

	/** Whether or not the component is being viewed in small context */
	isMobileWidth: PropTypes.bool,

	/** Selections race silk */
	silk: PropTypes.string,

	/** Selections race jockey or driver */
	jockey: PropTypes.string,

	/** Selections trainer */
	trainer: PropTypes.string,

	/** Selections race barrier number */
	barrier: PropTypes.string,

	/** Selections race weight or handicap */
	weight: PropTypes.number,

	/** A string of the selections last race starts placings (including spells) */
	last_starts: PropTypes.string,

	/**
	 * This status is used to determine if the race selection has been scratched or late scratched
	 * and if so will render with a new class to visually convey this to the user
	 */
	selection_status: PropTypes.oneOf(SELECTION_STATUSES),

	/** Runner contains the selections form stats and last starts */
	runner: PropTypes.shape(),

	/** Extra class(es) to pass through to the component */
	className: PropTypes.string,

	displayedFlucs: PropTypes.array,

	ShowModal: PropTypes.func,

	pricesAvailable: PropTypes.bool.isRequired,
};

FeatureRaceSelectionListItem.defaultProps = {
	boxed: false,
	isMobileWidth: false,
	silk: null,
	jockey: null,
	trainer: null,
	barrier: null,
	weight: null,
	last_starts: null,
	selection_status: null,
	runner: null,
	displayedFlucs: [],
	selectionBetButtons: [
		{
			price: null,
			hasMulti: false,
			productAvailable: true,
		},
	],
	className: null,
	displayPrices: null,
	size: null,
	topselection: {},
	ShowModal: undefined,
};

const mapDispatchToProps = (dispatch) => ({
	navigateToRaceWithType: (type, date, raceId, meetingId, isNJRacingHome) => {
		dispatch(trackNextToJump('Click', null, isNJRacingHome));
		dispatch(navigateToNextToJumpRaceWithType(type, date, raceId, meetingId));
		App.startSubApp('RacingNewPage');
	},
});

export default connect(undefined, mapDispatchToProps)(FeatureRaceSelectionListItem);
