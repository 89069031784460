import React from 'react';
import styled from 'react-emotion';
import { BiErrorAlt, BiXCircle, BiInfoCircle, BiCheckCircle } from 'react-icons/bi';
import { COLORS, MEDIA_QUERIES } from '../../../constants/themes';

const AlertsWrapper = styled.div`
    border-left: 0.25rem solid #ff6b6b;
    border-radius: 0;
    padding: 12px;
    text-align: left;
    display: flex;
    align-items: center;
    width: 100%;

    ${props => props.status === 'info' && 'background-color: #a5d8ff; border-left: 0.25rem solid #339af0; color: #339af0;'};
    ${props => props.status === 'warning' && 'background-color: #ffd8a8; border-left: 0.25rem solid #ff922b; color: #ff922b;'};
    ${props => props.status === 'error' && 'background-color: #ffc9c9; border-left: 0.25rem solid #ff6b6b; color: #ff6b6b;'};
    ${props => props.status === 'success' && 'background-color: #b2f2bb; border-left: 0.25rem solid #51cf66; color: #51cf66;'};

    @media ${MEDIA_QUERIES.tabletAndUp} {
        width: auto;
    }
`;

const AlertIconWrapper = styled.div`
    display: block;
    text-align: center;
    padding: 4px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    color: inherit;
    background-color: white;
`;

const AlertsInfo = styled.div`
    margin-left: 16px;
`;

const AlertsTitle = styled.h6`
    font-weight: 700;
    font-size: 14px;
    color: inherit;
    margin-bottom: 3px;
`;

const AlertsDescription = styled.p`
    font-weight: 500;
    font-size: 12px;
    color: inherit;
    margin-bottom: 3px;
`;

const AlertsLink = styled('span')`
    display: inline-block;
    font-weight: 500;
    font-size: 12px;
    padding: 3px 6px;
    color: ${COLORS.white};
    border-radius: 3px;
    background-color: #ff6b6b;
    cursor: pointer;
`;

const Alerts = ({ title, description, status, label, onClickChangeToVerificationPage, verificationItem }) => {
    return (
        <AlertsWrapper status={status}>
            <AlertIconWrapper>
                {status === 'info' && <BiInfoCircle size="2em" />}
                {status === 'warning' && <BiErrorAlt size="2em" />}
                {status === 'error' && <BiXCircle size="2em" />}
                {status === 'success' && <BiCheckCircle size="2em" />}
            </AlertIconWrapper>
            <AlertsInfo>
                <AlertsTitle>{title}</AlertsTitle>
                <AlertsDescription>{description}</AlertsDescription>
                {label && (
                    <AlertsLink onClick={() => onClickChangeToVerificationPage(verificationItem)}>{label}</AlertsLink>
                )}
            </AlertsInfo>
        </AlertsWrapper>
    );
};

export default Alerts;

