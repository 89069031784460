/**
 * Created by Josef Frank on 2017/05/17.
 */
'use strict';

// Arrow keys
const KEY_DOWN = 40;
const KEY_LEFT = 37;
const KEY_RIGHT = 39;
const KEY_UP = 38;

const KEY_ENTER = 13;
const KEY_TAB = 9;

module.exports = {
	KEY_DOWN,
	KEY_LEFT,
	KEY_RIGHT,
	KEY_UP,

	KEY_ENTER,
	KEY_TAB,
};