import { schema } from 'normalizr';
import moment from 'moment';
import 'moment-timezone';
import strategies, { requiredFields } from 'normalizr-strategies';

/**
 * @module Entities
 *
 * All entities will be merged into this single file. When defining circular relations, each base entity
 * needs to be defined before you can setup the circular relationships, and breaking into individual
 * modules for each entity using ES6 Modules meant that one entity would not be defined.
 *
 * The convention will be to define the entity in the top section of the file, then setup all relationships
 * using the `define` method provided by normalizr
 *
 * All `schema.Entity` and `schema.Array` should be exported
 */

const addServerTimezone = (...fields) => {
	return {
		strategy: function(config, value) {
			fields.forEach((field) => {
				if (value.hasOwnProperty(field)) {
					value[field] = moment.tz(value[field], 'Australia/Sydney').format();
				}
			});
			return {
				...value,
			};
		},
	};
};

/**f
 * Relation process strategy where the value has the parent id
 *
 * @param localId
 * @param parentId
 * @returns {{}}
 */
export const hasParentId = (localId, parentId = 'id') => {
	return {
		parentId: parentId,
		localAttribute: localId,
		strategy: function(relationObj, value, parent) {
			return {
				...value,
				[relationObj.localAttribute]: parent[relationObj.parentId],
			};
		},
	};
};

/**
 * For each item in the array, generate an id based on sibling and parent properties. Useful for when there is an array of objects
 * and each item doesn't not have an 'id' property. There is a limitation with out entities where when updating (UPDATE_ENTITIES)
 * it required an 'id' attribute to exist
 *
 * @param key - The key the array is under
 * @param siblingFields - Array of fields that appear under the key that will be joined via underscores
 * @param parentFields - Array of fields that appear under the parent that will be joined via underscores
 * @returns {{strategy: strategy}}
 */
export const generateIdsFromProperties = (key, siblingFields = [], parentFields = []) => {
	return {
		strategy: function(config, value) {
			if (value[key]) {
				const mappedParentFields = parentFields.map((field) => value[field]);
				value[key] = value[key].map((item) => {
					const mappedFields = siblingFields.map((field) => item[field]).concat(mappedParentFields);
					return {
						...item,
						id: mappedFields.join('_'),
					};
				});
			}

			return value;
		},
	};
};

/**
 * This strategy extends the existing one to add the 'overrideValue' flag. This flag
 * decides whether an existing field should receive the new value or not.
 * Our Ajax calls for NTJ events already bring the field set for relationship populated,
 * while the other calls don't.
 *
 * Therefore, this strategy will let renaming only where needed and permitted by user.
 */
const renameKeys = (keyMap, deleteKeys = true, overrideValue = false) => {
	return {
		strategy: function(config, value) {
			let keys = Object.keys(keyMap);
			let newEntity = {
				...value,
			};

			keys.forEach((key) => {
				if (overrideValue) {
					newEntity[keyMap[key]] = newEntity[key];
				} else {
					if (!newEntity[keyMap[key]]) {
						newEntity[keyMap[key]] = newEntity[key];
					}
				}

				if (deleteKeys) {
					delete newEntity[key];
				}
				return newEntity;
			});

			return newEntity;
		},
	};
};

/**
 * Process strategy to remove fields from an entity
 *
 * @param fields - Fields that will be removed
 * @returns {{strategy: strategy}}
 */
const removeFields = (...fields) => {
	return {
		strategy: function(config, value) {
			fields.forEach((field) => {
				if (value.hasOwnProperty(field)) {
					delete value[field];
				}
			});
			return {
				...value,
			};
		},
	};
};

/**
 * Process strategy to sort a list of keys on the entity via a custom comparator
 *
 * @param keys
 * @param comparator
 * @returns {{strategy: (function(*, *): {})}}
 */
export const sortKeys = (keys, comparator) => {
	return {
		strategy: function(config, value) {
			let newEntity = {
				...value,
			};

			keys.forEach((key) => {
				if (newEntity[key] && Array.isArray(newEntity[key])) {
					newEntity[key] = newEntity[key].sort(comparator);
				}

				return newEntity;
			});

			return newEntity;
		},
	};
};

/********************
 * Version Entities
 */
const versionSchema = new schema.Entity('versions');
const versionsSchema = new schema.Array(versionSchema);

/********************
 * Article Entities
 */
const articleSchema = new schema.Entity('articles');
const articlesSchema = new schema.Array(articleSchema);

/**
 * This strategy adds default values defined in the keyMap to each object in the entity.
 *
 * @param keyMap
 * @returns {{strategy: strategy}}
 */
const defaultProperties = (keyMap) => {
	return {
		strategy: function(config, value) {
			return {
				...keyMap,
				...value,
			};
		},
	};
};

const nextToPlayTournamentSchema = new schema.Entity(
	'nextToPlayTournaments',
	{},
	{
		processStrategy: strategies(addServerTimezone('start_date')),
	},
);

const nextToPlayTournamentsSchema = new schema.Array(nextToPlayTournamentSchema);

/**
 * Sport Entities
 */
const nextToJumpSportSchema = new schema.Entity(
	'nextToJumpSports',
	{},
	{
		idAttribute: 'event_id',
		processStrategy: strategies(
			addServerTimezone('start_time'),
			renameKeys(
				{
					event_id: 'events',
				},
				false,
			),
		),
	},
);
const nextToJumpSportsSchema = new schema.Array(nextToJumpSportSchema);

const sportSchema = new schema.Entity('sports');
const sportsSchema = new schema.Array(sportSchema);

const baseCompetitionSchema = new schema.Entity(
	'base_competitions',
	{},
	{
		processStrategy: strategies(
			renameKeys(
				{
					sport_id: 'sport',
				},
				false,
			),
		),
	},
);
const baseCompetitionsSchema = new schema.Array(baseCompetitionSchema);

const competitionSchema = new schema.Entity(
	'competitions',
	{},
	{
		processStrategy: strategies(
			addServerTimezone('start_date'),
			renameKeys(
				{
					base_competition_id: 'base_competition',
				},
				false,
			),
		),
	},
);
const competitionsSchema = new schema.Array(competitionSchema);

/**
 * This Schema renames 'market' into 'default_market' so the
 * response from the first API call can be stored properly.
 * The first markets received  are the event default ones.
 */
const eventSchema = new schema.Entity(
	'events',
	{},
	{
		processStrategy: strategies(
			renameKeys(
				{
					markets: 'default_markets',
					competition_id: 'competition',
				},
				false,
			),

			addServerTimezone('start_date'),
			generateIdsFromProperties('products', ['product_id', 'bet_type']),
		),
	},
);
const eventsSchema = new schema.Array(eventSchema);

const marketTypeGroupSchema = new schema.Entity('marketTypeGroups');
const marketTypeGroupsSchema = new schema.Array(marketTypeGroupSchema);

const marketSchema = new schema.Entity(
	'markets',
	{},
	{
		processStrategy: strategies(
			renameKeys({
				selections: 'sportSelections',
			}),
		),
	},
);
const marketsSchema = new schema.Array(marketSchema);

const sportSelectionSchema = new schema.Entity(
	'sportSelections',
	{},
	{
		processStrategy: strategies(hasParentId('event', 'event_id')),
	},
);
const sportSelectionsSchema = new schema.Array(sportSelectionSchema);

const teamSchema = new schema.Entity('teams');
const teamsSchema = new schema.Array(teamSchema);

/********************
 * Sports Relations
 */
nextToJumpSportSchema.define({
	events: eventsSchema,
});

sportSchema.define({
	base_competitions: baseCompetitionsSchema,
});

baseCompetitionSchema.define({
	sport: sportSchema,
	competitions: competitionsSchema,
});

competitionSchema.define({
	events: eventsSchema,
	base_competition: baseCompetitionSchema,
});

eventSchema.define({
	default_markets: marketsSchema,
	markets: marketsSchema,
	// teams: teamsSchema,
	competition: competitionSchema,
});

marketTypeGroupSchema.define({
	markets: marketsSchema,
});

marketSchema.define({
	markettypegroup: marketTypeGroupSchema,
	sportSelections: sportSelectionsSchema,
});

sportSelectionSchema.define({
	team: teamSchema,
	event: eventSchema,
});

/*******************
 * Racing Entities
 */
const nextToJumpRaceSchema = new schema.Entity(
	'nextToJumpRaces',
	{},
	{
		processStrategy: strategies(addServerTimezone('start_datetime')),
	},
);
const nextToJumpRacesSchema = new schema.Array(nextToJumpRaceSchema);


/**
 * This is a separate entity from nextToJumpRaceSchema because the pusher clears out the race selection.
 * The plan is to remove the NTJ card from the sidebar, so this entity won't be necessary for long.
 *
 * @type {schema.Entity}
 */
const nextToJumpRaceSelectionSchema = new schema.Entity(
	'nextToJumpRaceSelections',
	{},
	{
		processStrategy: strategies(addServerTimezone('start_datetime')),
	},
);
const nextToJumpRaceSelectionsSchema = new schema.Array(nextToJumpRaceSelectionSchema);

/**
 * This is a separate entity from nextToJumpRaceSchema because the pusher clears out the race selection.
 * The plan is to remove the NTJ card from the sidebar, so this entity won't be necessary for long.
 *
 * @type {schema.Entity}
 */
 const sideBarDataSchema = new schema.Entity(
	'sideBarData',
	{},
	{
		processStrategy: strategies(addServerTimezone('start_datetime')),
	},
);
const sideBarDatasSchema = new schema.Array(sideBarDataSchema);


/**
 * Feature Races
 * @HW 06Oct2020
 */
const featureRaceSchema = new schema.Entity(
	'featureRaces',
	{},
	{
		processStrategy: strategies(addServerTimezone('start_datetime')),
	},
);
const featureRacesSchema = new schema.Array(featureRaceSchema);

/**
 * Feature Races
 * @HW 06Oct2020
 */
const expertRaceSchema = new schema.Entity(
	'expertRaces',
	{},
	{
		processStrategy: strategies(addServerTimezone('start_datetime')),
	},
);
const expertRacesSchema = new schema.Array(expertRaceSchema);

/**
 * Upcoming All races
 */
const upcomingAllRaceSchema = new schema.Entity(
	'upcomingRaces',
	{},
	{
		processStrategy: strategies(addServerTimezone('start_datetime')),
	},

);
const upcomingAllRacesSchema = new schema.Array(upcomingAllRaceSchema);


const meetingSchema = new schema.Entity(
	'meetings',
	{},
	{
		processStrategy: strategies(addServerTimezone('next_race_date', 'start_date')),
	},
);
const meetingsSchema = new schema.Array(meetingSchema);

/**
 * This processStrategy is meant to add unique ids to results and exotic_results so
 * the updateProperty() under entity.js can find and update existing results.
 *
 * A unique identifier for a result is composed of:
 * "<position><bet type><product id>"
 *
 * A unique identifier for a result is composed of:
 * "<bet type><product id>"
 */
const addIdToResults = () => {
	return {
		strategy: function(config, value) {
			let newValue;

			if (Array.isArray(value.results) && value.results.length) {
				newValue = { ...value };
				newValue.results = value.results.map((result) => {
					result.id = `${result.position || ''}${result.bet_type || ''}${result.product_id || ''}`;
					return result;
				});
			}

			if (Array.isArray(value.exotic_results) && value.exotic_results.length) {
				if (!newValue) {
					newValue = { ...value };
				}
				newValue.exotic_results = value.exotic_results.map((result) => {
					result.id = `${result.bet_type || ''}${result.product_id || ''}`;
					return result;
				});
			}
			return newValue || value;
		},
	};
};

const raceSchema = new schema.Entity(
	'races',
	{},
	{
		processStrategy: strategies(
			addServerTimezone('start_date'),
			addIdToResults(),
			generateIdsFromProperties('products', ['product_id', 'bet_type']),
		),
	},
);

const racesSchema = new schema.Array(raceSchema);

const selectionSchema = new schema.Entity('selections');
const selectionsSchema = new schema.Array(selectionSchema);

const derivativeMarketSchema = new schema.Entity('derivative_markets');
const derivativeMarketsSchema = new schema.Array(derivativeMarketSchema);

const derivativeSelectionSchema = new schema.Entity('derivative_selections');
const derivativeSelectionsSchema = new schema.Array(derivativeSelectionSchema);

const priceSchema = new schema.Entity('prices');
const pricesSchema = new schema.Array(priceSchema);

const runnerSchema = new schema.Entity('runners');
const runnersSchema = new schema.Array(runnerSchema);

const lastStartSchema = new schema.Entity('last_starts');
const lastStartsSchema = new schema.Array(lastStartSchema);

const metaSchema = new schema.Entity('meta');
const metasSchema = new schema.Array(metaSchema);

const navigationItemSchema = new schema.Entity(
	'navigationItems',
	{},
	{
		processStrategy: strategies(
			// Add the default values to each item in the navigation entity
			defaultProperties({
				icon: null,
				mobile: false, // If it needs to be shown on mobile
				order: 99999,
				primary: true, // If it's a primary navigation item
				secondary: false, // If it's a secondary navigation item
				show: true, // Show item. We can hide navigation items using this prop for the affiliates
				showForGuest: true, // Show item for unauthenticated users
				showForUser: true, // Show item for authenticated users
				showOnSidebar: false, // If it needs to be shown on the sidebar
				title: null,
			}),
		),
	},
);
const navigationItemsSchema = new schema.Array(navigationItemSchema);

/********************
 * Racing Relations
 */
meetingSchema.define({
	races: racesSchema,
});

raceSchema.define({
	selections: selectionsSchema,
	derivative_markets: derivativeMarketsSchema,
});

derivativeMarketSchema.define({
	derivative_selections: derivativeSelectionsSchema,
});

derivativeSelectionSchema.define({
	base_selections: selectionsSchema,
});

selectionSchema.define({
	prices: pricesSchema,
	runner: runnerSchema,
});

runnerSchema.define({
	last_starts: lastStartsSchema,
});

/********************
 * Tournament Relations
 */
const ticketSchema = new schema.Entity(
	'tickets',
	{},
	{
		processStrategy: strategies(
			renameKeys(
				{
					tournament_id: 'tournament',
				},
				false,
			),
		),
	},
);
const ticketsSchema = new schema.Array(ticketSchema);

const tournamentSchema = new schema.Entity(
	'tournaments',
	{},
	{
		processStrategy: strategies(addServerTimezone('end_date', 'start_date', 'entries_close_at')),
	},
);
const tournamentsSchema = new schema.Array(tournamentSchema);

const tournamentGroup = new schema.Entity('tournamentGroups');
const tournamentGroupsSchema = new schema.Array(tournamentGroup);

ticketSchema.define({
	tournament: tournamentSchema,
});

tournamentSchema.define({
	ticket: ticketSchema,
	meetings: meetingsSchema,
	competitions: competitionsSchema,
});

tournamentGroup.define({
	tournaments: tournamentsSchema,
});

/********************
 * Bet Relations
 */
const betSelectionSchema = new schema.Entity('bet_selections');
const betSelectionsSchema = new schema.Array(betSelectionSchema);

const betSchema = new schema.Entity('bets');
const betsSchema = new schema.Array(betSchema);

const tournamentBetSchema = new schema.Entity(
	'tournamentBets',
	{},
	{
		processStrategy: strategies(requiredFields('user_id')),
	},
);
const tournamentBetsSchema = new schema.Array(tournamentBetSchema);

betSchema.define({
	bet_selections: betSelectionsSchema,
});

tournamentBetSchema.define({
	bet_selections: betSelectionsSchema,
});

export {
	versionSchema,
	versionsSchema,
	articleSchema,
	articlesSchema,
	sportSchema,
	sportsSchema,
	baseCompetitionSchema,
	baseCompetitionsSchema,
	competitionSchema,
	competitionsSchema,
	derivativeMarketSchema,
	derivativeMarketsSchema,
	derivativeSelectionSchema,
	derivativeSelectionsSchema,
	eventSchema,
	eventsSchema,
	marketTypeGroupSchema,
	marketTypeGroupsSchema,
	marketSchema,
	marketsSchema,
	sportSelectionSchema,
	sportSelectionsSchema,
	teamSchema,
	teamsSchema,
	metasSchema,
	navigationItemsSchema,
	upcomingAllRacesSchema,
	featureRacesSchema,
	expertRacesSchema,
	nextToJumpRaceSchema,
	nextToJumpRacesSchema,
	nextToJumpRaceSelectionsSchema,
	nextToJumpSportsSchema,
	nextToPlayTournamentsSchema,
	meetingSchema,
	meetingsSchema,
	raceSchema,
	racesSchema,
	sideBarDatasSchema,
	selectionSchema,
	selectionsSchema,
	priceSchema,
	pricesSchema,
	runnerSchema,
	runnersSchema,
	lastStartSchema,
	lastStartsSchema,
	ticketSchema,
	ticketsSchema,
	tournamentSchema,
	tournamentsSchema,
	tournamentGroup,
	tournamentGroupsSchema,
	betSelectionSchema,
	betSelectionsSchema,
	betSchema,
	betsSchema,
	tournamentBetSchema,
	tournamentBetsSchema,
};
