import { createAction } from '../../common/actions/actionHelpers';
import {
	PROMOTIONS_PAGE_SET_SELECTED_POST,
	PROMOTIONS_PAGE_SET_LOADING_PROMOTIONS_COMPLETED,
	PROMOTIONS_PAGE_SET_SHOW_TERMS, PROMOTIONS_PAGE_SET_LOADING_PROMOTIONS_STARTED,
	FETCH_PROMOTIONS_START,
	FETCH_PROMOTIONS_SUCCESS,
	FETCH_PROMOTIONS_FAILURE
} from './promotionsPageActionTypes';
import { getPromotionsSortedByDate } from '../../pages/Application/Promotions/PromotionsSelectors';
import { findArticleBySlug } from '../entities/selectors/ArticleSelectors';
import axios from 'axios';
/**
 * Set the selected promotion using an id
 *
 * @param promotionId
 * @returns {{type, payload: *}}
 */
export const setSelectedPost = (promotionId) => {
	return createAction(PROMOTIONS_PAGE_SET_SELECTED_POST, promotionId);
};

/**
 * Set the selected promotion using the promotions slug
 *
 * @param slug
 * @returns {function(*, *)}
 */
export const setSelectedPromotionWithSlug = (slug) => {
	return (dispatch, getState) => {
		const state = getState();

		const orderedPromotions = getPromotionsSortedByDate(state);

		if (slug) {
			// Look for the article in the current list of promotions
			let selectedArticle = findArticleBySlug(orderedPromotions, slug);

			// Set the requested promotion as selected if found in the list
			selectedArticle && dispatch(setSelectedPost(selectedArticle.id));

			return selectedArticle;
		}

		// Sets the default promotion to the first one after ordering
		if (orderedPromotions.length) {
			dispatch(setSelectedPost(orderedPromotions[0].id));
			return orderedPromotions[0];
		}
	};
};

/**
 * Set once the promotions start loading
 *
 * @returns {{type}}
 */
export const setLoadingPromotionsStarted = () => {
	return createAction(PROMOTIONS_PAGE_SET_LOADING_PROMOTIONS_STARTED);
};

/**
 * Set once the promotions have loaded
 *
 * @returns {{type}}
 * @param errorMessage
 */
export const setLoadingPromotionsCompleted = (errorMessage = null) => {
	return createAction(PROMOTIONS_PAGE_SET_LOADING_PROMOTIONS_COMPLETED, errorMessage ? errorMessage : null);
};

/**
 * Toggle whether the terms are showing
 *
 * @returns {{type}}
 * @param showTerms
 */
export const setShowTerms = (showTerms) => {
	return createAction(PROMOTIONS_PAGE_SET_SHOW_TERMS, showTerms);
};
/**
 * Fetch promotions from the API
 *
 * @returns {function(*): Promise<void>}
 */

export const fetchPromotions = (type = 'all') => {
	return async (dispatch) => {
	  dispatch({ type: FETCH_PROMOTIONS_START });
	  try {
		const response = await axios.get(`/promotions?type=${type}`);
		dispatch({ 
		  type: FETCH_PROMOTIONS_SUCCESS, 
		  payload: response.data.data 
		});
	  } catch (error) {
		dispatch({ 
		  type: FETCH_PROMOTIONS_FAILURE, 
		  payload: error.message 
		});
	  }
	};
  };