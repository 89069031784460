import { UPDATE_FIRST_DEPOSIT_BONUSES, SET_ACL_ATTRIBUTES } from './aclActionsTypes';

// TODO: Temporary method of setting the initialState
const initialAcl = {
	...(window ? window.__bm_env || {} : {}),
	...(window && window.__bm_env ? window.__bm_env.acl || {} : {}),
};
delete initialAcl.acl;

const initialState = initialAcl;

const acl = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_FIRST_DEPOSIT_BONUSES:
			return {
				...state,
				firstDepositBonuses: action.payload,
			};

		case SET_ACL_ATTRIBUTES:
			return {
				...state,
				...action.payload,
				currencyConfiguration: action.payload.currencyConfiguration
					? {
							...state.currencyConfiguration,
							...action.payload.currencyConfiguration,
					  }
					: state.currencyConfiguration,
				firstDepositBonuses: action.payload.firstDepositBonuses
					? action.payload.firstDepositBonuses
					: state.firstDepositBonuses,
			};

		default:
			return state;
	}
};

export default acl;
