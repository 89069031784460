import React from 'react';
import { Notification, Text } from '@tbh/ui-kit';
import styled from 'react-emotion';
import { post } from '../../../../../common/Ajax';
import { StyledSection, StyledSectionTitle, StyledSectionButton, Column, Container, Input } from '../../Components';

const StyledNotification = styled(Notification)`
	padding: 0px;
	margin: 5px 0px;
	width: fit-content;
	background-color: #ebebeb;
`;

const validadePasswordAndReturnError = (password) => {
	// Minimum At least 10 characters, at least 1 number, at least 1 upper-case letter and at least 1 lower-case letter.

	if (!password) {
		return 'Password is required';
	}

	if (password.length < 10) {
		return 'Must be at least 10 characters';
	}

	const atLeastOneNumber = /\d/;
	const atLeastOneUpperCase = /[A-Z]/;
	const atLeastOneLowerCase = /[a-z]/;

	if (!atLeastOneNumber.test(password)) {
		return 'Must contain at least one number';
	}

	if (!atLeastOneUpperCase.test(password)) {
		return 'Must contain at least one upper-case letter';
	}

	if (!atLeastOneLowerCase.test(password)) {
		return 'Must contain at least one lower-case letter';
	}

	return null;
};

const ChangePasswordSection = ({ t }) => {
	const [form, setForm] = React.useState({
		oldPassword: '',
		newPassword: '',
		confirmPassword: '',
	});

	const [loading, setLoading] = React.useState(false);

	const [errors, setErrors] = React.useState({
		oldPassword: '',
		newPassword: '',
		confirmPassword: '',
	});

	const [error, setError] = React.useState(undefined);
	const [success, setSuccess] = React.useState(undefined);

	const onChangeOldPassword = (e) => {
		let value = e.target.value;

		setForm({ ...form, oldPassword: value });
		if (!value) {
			setErrors({ ...errors, oldPassword: 'Old password is required' });
		} else {
			setErrors({ ...errors, oldPassword: '' });
			setError('');
		}
	};

	const onChangeNewPassword = (e) => {
		let value = e.target.value;
		setForm({ ...form, newPassword: value });

		setErrors({ ...errors, newPassword: validadePasswordAndReturnError(value) });
	};

	const onChangeConfirmPassword = (e) => {
		let value = e.target.value;
		setForm({ ...form, confirmPassword: value });

		if (value !== form.newPassword) {
			setErrors({ ...errors, confirmPassword: 'Passwords do not match' });
		} else {
			setErrors({ ...errors, confirmPassword: '' });
		}
	};

	const onSubmitUpdatePassword = () => {
		setError('');
		setForm({ oldPassword: '', newPassword: '', confirmPassword: '' });
		setLoading(true);
		post('/user/update-password', {
			password: form.oldPassword,
			new_password: form.newPassword,
			new_password_confirmation: form.confirmPassword,
		})
			.then((response) => {
				setSuccess('Password updated successfully');
			})
			.catch((error) => {
				setError('Old password invalid');
				setErrors({ ...errors, oldPassword: 'Old password invalid' });
			})
			.finally(() => {
				setLoading(false);
			});
	};

	React.useEffect(() => {
		setTimeout(() => {
			setSuccess(undefined);
		}, 1000);
	}, [form.confirmPassword, form.newPassword, setSuccess]);

	const isUpdatePasswordDisabled = errors.oldPassword || errors.newPassword || errors.confirmPassword || error;

	return (
		<div>
			<Text size="1">Change Password</Text>
			<Container>
				<Column justifyContent="flex-start" alignItems="flex-start">
					<Input
						placeholder="Old Password"
						onChange={onChangeOldPassword}
						type="password"
						value={form.oldPassword}
						error={errors.oldPassword}
					/>
					<Input
						placeholder={t('UpdatePassword__NewPassword')}
						type="password"
						value={form.newPassword}
						error={errors.newPassword}
						onChange={onChangeNewPassword}
					/>
					<Input
						type="password"
						placeholder="Confirm New Password"
						value={form.confirmPassword}
						error={errors.confirmPassword}
						onChange={onChangeConfirmPassword}
					/>
					{error && (
						<StyledNotification type="danger">
							<Text size="-2" align="center">
								{error}
							</Text>
						</StyledNotification>
					)}
					{success && (
						<StyledNotification type="success">
							<Text size="-2" align="center">
								{success}
							</Text>
						</StyledNotification>
					)}
				</Column>

				<Column justifyContent="flex-start" alignItems="flex-start">
					<StyledSectionButton
						color="#161a1d"
						fontColor="white"
						onClick={() => onSubmitUpdatePassword()}
						disabled={
							!form.oldPassword || !form.newPassword || !form.newPassword || isUpdatePasswordDisabled || loading
						}
					>
						{loading ? 'Loading...' : 'Save'}
					</StyledSectionButton>
				</Column>
			</Container>
		</div>
	);
};

export default ChangePasswordSection;
