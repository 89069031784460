// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, typography, ui, media, mediaMax } from '@tbh/ui-kit';

// Components
import { Text } from '@tbh/ui-kit';
import AccountSummaryUser from '../AccountSummaryUser/AccountSummaryUser';
import AccountSummaryBalanceStatistics from '../AccountSummaryBalanceStatistics/AccountSummaryBalanceStatistics';
import CurrencyImageContainer from '../../../../../containers/Application/Currency/CurrencyImageContainer';

// Functions
import { centsAsDollars } from '../../../../../legacy/core/format';



const StyledAccountSummaryStatistics = styled('div')`
	label: AccountSummaryStatistics;

	${(props) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		flex-direction: row;
		//border-right: 2px ${ui(props).color_3} solid;
		border-right: 2px #3c3d44 solid;
	`};
`;

const StyledAccountSummaryStatistics__Stakes = styled('div')(
	(props) => css`
		label: AccountSummaryStatistics__Stakes;

		flex-grow: 1;
		margin: 0 ${spacings(props).cozy}px;
	`,
	mediaMax(
		css`
			display: none;
		`,
		620,
	),
);
const StyledAccountSummaryStatistics__Balance = StyledAccountSummaryStatistics__Stakes.withComponent(
	AccountSummaryBalanceStatistics,
);

const StyledAccountSummaryStatistics__Welcome = styled('div')(
	(props) => css`
		label: AccountSummaryStatistics__Welcome;
		flex-grow: 1;
		display: none;
		margin: 0 ${spacings(props).cozy}px;
	`,
	mediaMax(
		css`
			display: none;
		`,
		620,
	),
	media(
		css`
			display: block;
		`,
		1024,
	),
);
const StyledAccountSummaryStatistics__User = styled(
	StyledAccountSummaryStatistics__Welcome.withComponent(AccountSummaryUser),
)(
	mediaMax(
		css`
			display: flex;
			align-self: flex-start;
		`,
		620,
	),
	media(
		css`
			display: none;
		`,
		1024,
	),
);

const StyledAccountSummaryStatistics__WelcomeAuthor = styled(Text)`
	label: AccountSummaryStatistics__WelcomeAuthor;

	${(props) => css`
		color: ${typography(props).color_2};
		margin-top: ${spacings(props).compact}px;
	`};
`;

const StyledAmount_box = styled('div')`
		label: Amount_box;

		border: 1px solid #fff;
		padding: 3px;
		width: 100%;
		color: #fff;
`;

const StyledStakeAmount_box = styled('div')`
		label: StakeAmount_box;

		background: #c72128;
		border: 1px solid #c72128;
		padding: 3px;
		width: 100%;
		color: #fff;
`;

const StyledSuccessAmount_box = styled('div')`
		label: SuccessAmount_box;

		background: #28a745;
		border: 1px solid #28a745;
		padding: 3px;
		width: 100%;
		color: #fff;
`;

const StyledBonusCheck = styled('div')`
  label: BonusCheck;
  position: relative;
  margin-left:5px;
`;

const AccountSummaryStatistics = (props) => {
	const {
		t,
		account_balance,
		cash_balance,
		className,
		first_name,
		free_credit_balance,
		goToAccountTransactions,
		goToAccountTransactionsBonus,
		goToPendingBets,
		id,
		name,
		pending_bets,
		potential_winnings,
		total_stake,
		verified,
		username,
		size,
		cashout_limit,
		checkBonusbet,
		isChecked,
		bailOutLimit,
		pricePumpLimit,
		showPriceBumpModelOpen,
		showBailOutModelOpen,
		togglePriceBump,
		toggleBailout,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});


	const handleMultiToggle = () => {
		checkBonusbet();
	};

	return (
		<StyledAccountSummaryStatistics className={componentClasses}>
			{/* <StyledAccountSummaryStatistics__Welcome size={size}>
				<Text size="-1" strong>
					{t('AccountSummaryStatistics__Welcome')}, {first_name ? first_name : username}
				</Text>
				<Text size="-1">{t('AccountSummaryStatistics__Happy')}!</Text>
				<FeatureContext.Consumer>
					{(featureToggles) => {
						return featureToggles.features.ceoComment && featureToggles.features.ceoComment.enabled ? (
							<StyledAccountSummaryStatistics__WelcomeAuthor size="-1" italic>
								Todd Buckingham, CEO TopBetta
							</StyledAccountSummaryStatistics__WelcomeAuthor>
						) : null;
					}}
				</FeatureContext.Consumer>
			</StyledAccountSummaryStatistics__Welcome> */}
			<StyledAccountSummaryStatistics__Stakes size={size}>

				{/* <div className={css`display:flex;width:100%;justify-content: space-between;padding-bottom: 5px;`}>
					<div className={css`color:#fff;flex: 0 0 30%;`}></div>
					<div className={css`color:#fff;flex: 0 0 25%;`}>
						<Button type={'primary'} data-tip data-for="bailOutTip" className={bailOutLimit  ? css`padding: 0px 4px 0px 4px;background: rgb(55, 155, 55);border: 1px solid rgb(55, 155, 55);` : css`padding: 0px;background: #c72128;border: 1px solid #c72128;`} >{'Bailouts'}</Button>
						
							<ReactTooltip id="bailOutTip" place="bottom" type={pricePumpLimit ? 'success' : 'error'} effect="solid">
							{bailOutLimit ?  bailOutLimit : 0}
      						</ReactTooltip>
					</div>
					<div className={css`color:#fff;flex: 0 0 25%;`}>
						<Button type={'primary'} data-tip data-for="priceBumpTip" className={pricePumpLimit  ? css`padding: 0px 4px 0px 4px;background: rgb(55, 155, 55);border: 1px solid rgb(55, 155, 55);` : css`padding: 0px;background: #c72128;border: 1px solid #c72128;`}>{'PriceBump'}</Button>
						
						<ReactTooltip id="priceBumpTip" place="bottom" type={pricePumpLimit ? 'success' : 'error'} effect="solid">
							{pricePumpLimit ?  pricePumpLimit : 0}
						  </ReactTooltip>
					</div>
				</div> */}
				<div className={css`display:flex;width:100%;justify-content: flex-start;`} >
					<Text size="-1" className={css`color:#fff;flex: 0 0 60%;`}>{'BailOut Limit'}</Text>
					<Text size="-1" align="right" className={css`flex: 0 0 40%;color:#fff;`}>
						<StyledAmount_box>

							{bailOutLimit ? bailOutLimit : 0}
						</StyledAmount_box>
					</Text>
				</div>

				<div className={css`display:flex;width:100%;justify-content: flex-start;`} >
					<Text size="-1" className={css`color:#fff;flex: 0 0 60%;`}>{'PriceBump Limit'}</Text>
					<Text size="-1" align="right" className={css`flex: 0 0 40%;color:#fff;`}>
						<StyledAmount_box>

							{pricePumpLimit ? pricePumpLimit : 0}
						</StyledAmount_box>
					</Text>
				</div>

				{/* <div className={css`display:flex;width:100%;justify-content: space-between;`} >
					<Text size="-1" className={css`color:#fff;flex: 0 0 60%;`}>{t('AccountSummaryStatistics__PendingBets')}</Text>
					<Text size="-1" align="right"  className={css`flex: 0 0 40%;`}>
						<Link action={goToPendingBets} underline>
							<StyledAmount_box>{`${pending_bets}`}</StyledAmount_box>
						</Link>
					</Text>
				</div> */}
				<div className={css`display:flex;width:100%;justify-content: space-between;margin-top:8px;color:#fff;`}>
					<Text size="-1" className={css`color:#fff;flex: 0 0 60%;`}>{t('AccountSummaryStatistics__TotalStakes')}</Text>
					<Text size="-1" align="right" className={css`flex: 0 0 40%;`} >
						<StyledStakeAmount_box>
							<CurrencyImageContainer imageWidth={10} />
							{centsAsDollars(total_stake, true)}
						</StyledStakeAmount_box>

					</Text>
				</div>
				<AccountSummaryBalanceStatistics.StyledAccountSummaryBalanceStatistics__LineThrough />
				<div className={css`display:flex;width:100%;justify-content: space-between;`}>
					<Text size="-1" className={css`color:#fff; flex: 0 0 60%;`}>{t('AccountSummaryStatistics__PotentialWinnings')}</Text>
					<Text size="-1" align="right" type="success" className={css`flex: 0 0 40%;`} >
						<StyledSuccessAmount_box>
							<CurrencyImageContainer imageWidth={10} />
							{centsAsDollars(potential_winnings, true)}
						</StyledSuccessAmount_box>

					</Text>
				</div>




			</StyledAccountSummaryStatistics__Stakes>
			<StyledAccountSummaryStatistics__User id={id} name={name ? name : username} verified={verified} size={size} />
			<StyledAccountSummaryStatistics__Balance
				cash_balance={cash_balance}
				free_credit_balance={free_credit_balance}
				account_balance={account_balance}
				goToAccountTransactions={goToAccountTransactions}
				goToAccountTransactionsBonus={goToAccountTransactionsBonus}
				checkBonusbet={checkBonusbet}
				isChecked={isChecked}
			/>
		</StyledAccountSummaryStatistics>
	);
};

AccountSummaryStatistics.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** Action to fire when the pending bets link is clicked on */
	goToPendingBets: PropTypes.func.isRequired,

	/** The current users' ID */
	id: PropTypes.number.isRequired,

	/** The current users' username */
	username: PropTypes.string.isRequired,

	/** Whether the current user is verified or not */
	verified: PropTypes.bool.isRequired,

	/** The current users' first name */
	first_name: PropTypes.string,

	/** The current users' full name */
	name: PropTypes.string,

	/** The current users' account balance */
	account_balance: PropTypes.number,

	/** The current users' cash balance */
	cash_balance: PropTypes.number,

	/** Extra classes */
	className: PropTypes.string,

	/** The current users' balance for their bonus bets */
	free_credit_balance: PropTypes.number,

	/** The total number of pending/unresulted bets for the current user */
	pending_bets: PropTypes.number,

	/** The total amount that the user has staked and is unresulted */
	total_stake: PropTypes.number,

	/** The total $ amount of potential winnings unresulted for the user */
	potential_winnings: PropTypes.number,

	/** Navigates to the account transactions page */
	goToAccountTransactions: PropTypes.func,

	/** Navigates to the account transactions bonus page */
	goToAccountTransactionsBonus: PropTypes.func,

	bailOutLimit: PropTypes.number,

	pricePumpLimit: PropTypes.number,

	/** Whether or not the price bump is open */
	showPriceBumpModelOpen: PropTypes.bool,

	/** Whether or not the bail out is open */
	showBailOutModelOpen: PropTypes.bool,

	/** Action to toggle the price bump model view */
	togglePriceBump: PropTypes.func.isRequired,

	toggleBailout: PropTypes.func.isRequired,
};

AccountSummaryStatistics.defaultProps = {
	account_balance: 0,
	cash_balance: 0,
	className: '',
	free_credit_balance: 0,
	pending_bets: 0,
	potential_winnings: 0,
	total_stake: 0,
	first_name: null,
	name: null,
	goToAccountTransactions: null,
	goToAccountTransactionsBonus: null,
	cashout_limit: null,
	bailOutLimit: 0,
	pricePumpLimit: 0,
	showPriceBumpModelOpen: false,
	showBailOutModelOpen: false,
};

export default withNamespaces()(AccountSummaryStatistics);
