import React from 'react';
import {
	MeetingCardContainer,
	MeetingCardLeft,
	MeetingContainerWrapper,
	MeetingListContainer,
} from './MeetingListMobile';
import { Skeleton } from '../../../UI/Skeleton';

export const MeetingListMobileLoading = () => {
	return (
		<MeetingListContainer>
			{Array.from({ length: 5 }).map((_, index) => (
				<MeetingContainerWrapper key={index}>
					<MeetingCardContainer >
						<MeetingCardLeft>
							<Skeleton width="24px" height="24px" black={true} />
							<div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
								<Skeleton width="32px" height="8px" black={true} />
								<Skeleton width="24px" height="8px" black={true} />
							</div>
						</MeetingCardLeft>
						<Skeleton width="64px" height="16px" black={true} />
					</MeetingCardContainer>
				</MeetingContainerWrapper>
			))}
		</MeetingListContainer>
	);
};
