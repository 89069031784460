import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { SizeMe } from 'react-sizeme';


//@HW add new RacingV3Home page design
import * as NJHPS from './NextJumpHomePage.styled';

// Containers
import { createErrorBoundary } from '../../../containers/ErrorBoundary/ErrorBoundaryActions';

// Components
import { Panel } from '@tbh/ui-kit';
import DocumentTitle from '../../../components/controllers/Meta/DocumentTitle';
import DescriptionMeta from '../../../components/controllers/Meta/DescriptionMeta';


import Route from '../../../components/controllers/Route/Route';

// Actions
import { fetchActiveBets } from '../../../store/entities/actions/BetActions';
import { buildNextRacingRouteFromState } from '../../../routes/NextJump';
import {
  navigateToNextjumpHome,
} from '../../../store/nextJumpHome/NextJumpHomeActions';
import {
  navigateToNextToJumpRaceWithType,
} from '../../../store/GRSracingHome/GRSracingHomeActions';
import { getNextToJumpRaces, getNextToJumpSellingRace  } from '../../GRSNewRacing/RacingNewHome/racingSelectorsGRS';

import { LoadingSpanner } from '../../../components/features/Application/LoadingSpinner/LoadingSpinner';







class NextJumpHomePage extends Component {
  static propTypes = {
    /** Translation func provided by withNamespaces HOC */
    t: PropTypes.func.isRequired,

    /** Function to mark that the container has stopped loading - NOTE: automatically passed in by a wrapper */
    setContainerHasLoaded: PropTypes.func.isRequired,

    /** Run on mount. */
    fetchActiveBets: PropTypes.func.isRequired,


    /** Navigate to the NTJ race */
    navigateToRaceWithType: PropTypes.func.isRequired,

    /** Show routing and meta components from the page */
    useRouting: PropTypes.bool,

    /** Based of state. Used for routing. */
    currentRoute: PropTypes.string,

    /** Watch for authenticatedUser to fetch their bets. */
    authenticatedUser: PropTypes.number,

    /** Is the app loaded on a phone type device */
    isPhoneDevice: PropTypes.bool,

    /** Next to jump race object */
    nextNtjRace: PropTypes.shape({
      id: PropTypes.number,
      meeting_id: PropTypes.number,
    }),

    /** Brand name */
    brandName: PropTypes.string,

    /** ac; data */
    acl: PropTypes.shape(),

    /** Watch for selected meeting to either render races list or meeting. */
    selectedMeeting: PropTypes.number,

    /** If the Next to Jump Races should be displayed or not */
    showNtjRaces: PropTypes.bool,

    /** Loading mask to cover both meetings and races lists. */
    loadingContainer: PropTypes.bool,

     /** Navigate to next jump home with a given date or not. */
     navigateToNextjumpHome: PropTypes.func.isRequired,


  };

  static defaultProps = {
    brandName: '',
    selectedMeeting: undefined,
    currentRoute: null,
    authenticatedUser: null,
    nextNtjRace: null,
    acl: {},
    isPhoneDevice: false,
    showNtjRaces: true,
    useRouting: true,
    loadingContainer: false,
  };

  // eslint-disable-next-line react/sort-comp
  constructor(props) {
    super(props);


    this.state = {
      isMobile: props.isPhoneDevice,
    };
  }



  componentDidMount() {
    if (this.props.authenticatedUser) {
      this.props.fetchActiveBets();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.authenticatedUser && prevProps.authenticatedUser !== this.props.authenticatedUser) {
      this.props.fetchActiveBets();
    }
  }

  /**
   * Navigates to the selected race
   * @param selectedType
   * @param selectedDate
   * @param raceId
   * @param meetingId
   */
  navigateToRaceWithType = (selectedType,selectedDate,raceId, meetingId) => {
  	this.props.navigateToRaceWithType(selectedType,selectedDate,raceId, meetingId);
  };

   /**
   * Navigates to nextJumpHome, 'next-jump-races' .
   */
  navigateToNextjumpHome = () => {
    this.props.navigateToNextjumpHome();
  };



  render() {
    const {
      t,
      brandName,
      nextNtjRace,
      selectedMeeting,
      currentRoute,
      showNtjRaces,
      useRouting,
      loadingContainer,
    } = this.props;
    const {isMobile} = this.state;


    return (
	<SizeMe>
		{({size}) => (
			<NJHPS.StyledNextJumpHomePage>
				{/* set page title and meta data*/}
				{useRouting && (
				<React.Fragment>
					<Route route={currentRoute}/>
					<DocumentTitle>{`${t('NextJumpHomePage_Title')}${
                  brandName ? ` ${t('at')} ${brandName}` : ''
                }`}</DocumentTitle>
					<DescriptionMeta>
						{`${t('NextJumpHomePage__Description')}${brandName ? ` ${t('with')} ${brandName}` : ''}. ${t(
                    'NextJumpHomePage__DescriptionBonus',
                  )}`}
					</DescriptionMeta>

					<LoadingSpanner style={{display: loadingContainer ? 'block' : 'none'}}/>
				</React.Fragment>
            )}

				<NJHPS.StyledNextJumpHomePage__Content size={size}>

					<NJHPS.StyledNextJumpHomePage__Card__Body size={size}>
						<NJHPS.StyledNextJumpRacingHomePage_Header_section>
							<NJHPS.StyledNextJumpRacingHomePage_maijor_header>
								<NJHPS.StyledNextJumpRacingHomePage_Major_header_h2>{t('NEXT TO JUMP RACES')}</NJHPS.StyledNextJumpRacingHomePage_Major_header_h2>
							</NJHPS.StyledNextJumpRacingHomePage_maijor_header>
						</NJHPS.StyledNextJumpRacingHomePage_Header_section>

						<Panel>
							{/* start  next to jump old container */}
							{!showNtjRaces || (isMobile && selectedMeeting) ? null : (
								<NJHPS.StyledNextJumpHomePage__NextToJumpRaces
                        size={size}
                        isRacingHome={!selectedMeeting}
                        hideRaceNextJumpFilter={Boolean(isMobile)}
                      />
                    )}
							{/* next to jump old container */}
						</Panel>
					</NJHPS.StyledNextJumpHomePage__Card__Body>

				</NJHPS.StyledNextJumpHomePage__Content>
			</NJHPS.StyledNextJumpHomePage>

        )}
	</SizeMe>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let nextNtjRace;
  try {
    nextNtjRace = getNextToJumpSellingRace(state.entities, getNextToJumpRaces(state));

  } catch (e) {
    console.error(e);
  }



  return {
    nextNtjRace,
    selectedMeeting: state.grsRacingHome.selectedMeeting,
    loadingContainer: state.grsRacingHome.loadingContainer,
    currentRoute: buildNextRacingRouteFromState(state),
    authenticatedUser: state.application.authenticatedUser,
    isPhoneDevice: state.application.isPhoneDevice,
    brandName: state.acl.brandDetails && state.acl.brandDetails.name,
    showNtjRaces: state.featureToggles.features.ntjRaces.enabled,

    useRouting:
      typeof ownProps.useRouting === 'undefined'
        ? state.featureToggles.features.urlRouting.enabled
        : ownProps.useRouting,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({

  navigateToRaceWithType: (selectedType,selectedDate,raceId, meetingId) => {
    dispatch(navigateToNextToJumpRaceWithType(selectedType,selectedDate,raceId, meetingId));
  },

  navigateToNextjumpHome: () => dispatch(navigateToNextjumpHome()),
  fetchActiveBets: () => dispatch(fetchActiveBets()),


});

export default withNamespaces()(
  createErrorBoundary(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(NextJumpHomePage),
  ),
);
