import React from 'react';
import { useEffect } from 'react';
import { DEFAULT_COLORS } from './constants/themes';
import { StatusBar } from '@capacitor/status-bar';
import { connect } from 'react-redux';

const AppStatusBar = ({ isMultiBetSlipOpen, showSidebar }) => {
	useEffect(() => {
		let statusBarColor = DEFAULT_COLORS.df_tertiary_active_color;

		if (isMultiBetSlipOpen) {
			statusBarColor = DEFAULT_COLORS.df_secondary_color;
		}

		if (showSidebar) {
			statusBarColor = DEFAULT_COLORS.df_primary_color;
		}

		StatusBar.setBackgroundColor({ color: statusBarColor });
	}, [isMultiBetSlipOpen, showSidebar]);

	return null;
};

const mapStateToProps = (state) => {
	return {
		isMultiBetSlipOpen: state.application.showMultiBetSlip,
		showSidebar: state.application.showSidebar,
	};
};

const mapDispatchToProps = (dispatch) => {};

export default connect(mapStateToProps, mapDispatchToProps)(AppStatusBar);
