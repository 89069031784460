// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

// Components
import { Text, HeaderBar } from '@tbh/ui-kit';
import BrandContactPhone from '../../../../ui-components/BrandContactPhone/BrandContactPhone';

const RegistrationHeader = (props) => {
	return (
		<HeaderBar
			type={HeaderBar.types.PRIMARY}
			className={css`border-radius:4px 4px 0 0;`}
			aside={
				<React.Fragment>
					<BrandContactPhone />

				</React.Fragment>
			}
			asideClassName={css`
				display: inline-flex;
				align-items: center;
				flex: 1;
				justify-content: flex-end;
			`}
		>
			<Text type="alternate">{props.isGreenId ? 'Verification Form': props.t('RegistrationHeader__CreateAccount')}</Text>
		</HeaderBar>
	);
};

RegistrationHeader.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Action for closing the Registration process */
	onClose: PropTypes.func,
};

RegistrationHeader.defaultProps = {
	onClose: null,
};

export default withNamespaces()(RegistrationHeader);
