/**
 * Created by Josef Frank on 2017/03/01.
 */
'use strict';

// Loading moment and timezone
const moment = require('moment');
const m = moment;
require('moment-timezone');

// Constants
const {SERVER_REGION} = require('./constants/DateFormats');

/**
 * Date
 *
 * Contains functions for handling and calculating dates.
 */

/**
 * Returns the time of the server.
 *
 * @returns {moment}
 */
const getLocalTime = () => {
	return moment().tz(SERVER_REGION);
};

/**
 * Returns the timezone of the server.
 *
 * @returns {String}
 */
const getLocalTimezone = ()  => {
	return moment().tz(SERVER_REGION).format('z');
};

/**
 * Convert a date instance or
 * @param datetime
 */
const convertToLocalDateTime = (datetime) => {
	let time = datetime;
	if (!(time instanceof moment)) {
		time = moment(time);
	}
	return time.clone().utcOffset(m().utcOffset());
};

module.exports = {
	convertToLocalDateTime,
	getLocalTime,
	getLocalTimezone,
};