import {
	RACING_TOTE_LABEL,
	RACING_BET_TYPE_QUINELLA,
	RACING_BET_TYPE_EXACTA,
	RACING_BET_TYPE_TRIFECTA,
	RACING_BET_TYPE_FIRST_FOUR,
	RACING_BET_TYPE_QUADDIE,
	RACING_BET_TYPE_DAILY_DOUBLE,
	RACING_BET_TYPE_STRAIGHT_EIGHT,
} from '../../../common/constants/Racing';
import { GOAT_PRODUCT_TYPE_BOOST } from '../../../common/constants/GoatProducts';

export const PLACE_ICON_URL = 'https://grsresource.s3.ap-southeast-2.amazonaws.com/site-resources/icons/place-black.png';
export const WIN_ICON_URL = 'https://grsresource.s3.ap-southeast-2.amazonaws.com/site-resources/icons/win-black.png';


export const ALL = 'ALL';
export const ACTIVE = 'ACTIVE';

export const MAX_BET_AMOUNT = 99999999; // $999,999.99
export const TOTE_PRICE_UNAVAILABLE = RACING_TOTE_LABEL;
export const FIXED_PRICE_UNAVAILABLE = 'TBF/Tote';
export const SELECTION_PRICE_NOT_APPLICABLE = 'n/a';
export const PRICE_DIVIDEND = 'Dividend';

export const DEFAULT_BET_AMOUNT_PLACEHOLDER_TEXT = 'Bet Amount';
export const DEFAULT_BET_PROMPT_TITLE = 'SingleBetSlip';
export const MAX_MULTI_SELECTIONS = 20;

export const BET_MULTI_LEG_NAMES = [
	'Zero',
	'Singles',
	'Double',
	'Treble',
	'4-fold',
	'5-fold',
	'6-fold',
	'7-fold',
	'8-fold',
	'9-fold',
	'10-fold',
	'11-fold',
	'12-fold',
	'13-fold',
	'14-fold',
	'15-fold',
	'16-fold',
	'17-fold',
	'18-fold',
	'19-fold',
	'20-fold',
];
export const toteProducts = [
	'NSW',
	'SUP',
	'TATTS',
	'TOP',
	'OSP',
	'BO4',
	'QLD',
	'GRST',
	'MID',
	'BT',
	'SP',
	'BTSP',
	'BT3SP',
];

export const TOTE_NAMES_BY_PRODUCT = {
	NSW: 'NTOTE',
	SUP: 'VTOTE',
	VIC: 'VTOTE',
	TATTS: 'QTOTE',
	QLD: 'QTOTE',
	TOP: 'BestTote',
	OSP: 'SP',
	SP:  'SP',
	BT: 'BestTote',
	BT3SP:'BT+SP',
	BO4: 'BT+SP',
	GRST: 'MID',
	MID: 'MID',
};

export const TOTE_BOX_NAMES_BY_PRODUCT = {
	NSW: 'NSW',
	TATTS: 'QLD',
	SUP: 'VIC',
	TOP: 'BT',	
	OSP: 'SP',
	BO4: 'BT3SP',
	GRST: 'MID',
	MID: 'MID',
};

export const PRODUCT_TYPE_BOOST = GOAT_PRODUCT_TYPE_BOOST;
export const PRODUCT_TYPE_STANDARD = 'standard';

export const BET_TYPE_RACE = 'race';
export const BET_TYPE_SPORT = 'sport';
export const BET_TYPE_EXOTIC = 'exotic';
export const BET_TYPE_STANDARD = 'standard';
export const BET_TYPE_DERIVATIVE = 'derivative';
export const BET_TYPE_MULTI = 'multi';

// Racing bet types
export const BET_TYPE_WIN = 'win';
export const BET_TYPE_PLACE = 'place';
export const BET_TYPE_ODDSGRID = 'oddsgrid';
export const BET_TYPE_WINPLACE = 'winPlace';
export const BET_TYPE_QUINELLA = RACING_BET_TYPE_QUINELLA;
export const BET_TYPE_EXACTA = RACING_BET_TYPE_EXACTA;
export const BET_TYPE_TRIFECTA = RACING_BET_TYPE_TRIFECTA;
export const BET_TYPE_FIRSTFOUR = RACING_BET_TYPE_FIRST_FOUR;
export const BET_TYPE_QUADDIE = RACING_BET_TYPE_QUADDIE;
export const BET_TYPE_DAILY_DOUBLE = RACING_BET_TYPE_DAILY_DOUBLE;
export const BET_TYPE_STRAIGHT_EIGHT = RACING_BET_TYPE_STRAIGHT_EIGHT;

export const BET_TYPE_GROUP_WINPLACE = {
	id: BET_TYPE_WINPLACE,
	title: 'Win/Place',
	boxed: false,
	numberOfBoxes: 0,
	minSelections: 1,
};

export const BET_TYPE_GROUP_QUINELLA = {
	id: BET_TYPE_QUINELLA,
	title: 'Quinella',
	boxed: false,
	numberOfBoxes: 1,
	minSelections: 2,
};

export const BET_TYPE_GROUP_EXACTA = {
	id: BET_TYPE_EXACTA,
	title: 'Exacta',
	boxed: false,
	numberOfBoxes: 2,
	minSelections: 2,
};

export const BET_TYPE_GROUP_TRIFECTA = {
	id: BET_TYPE_TRIFECTA,
	title: 'Trifecta',
	boxed: true,
	numberOfBoxes: 3,
	minSelections: 3,
};

export const BET_TYPE_GROUP_FIRSTFOUR = {
	id: BET_TYPE_FIRSTFOUR,
	title: 'First Four',
	boxed: true,
	numberOfBoxes: 4,
	minSelections: 4,
};

export const BET_TYPE_GROUP_QUADDIE = {
	id: BET_TYPE_QUADDIE,
	title: 'Quaddie',
	boxed: false,
	numberOfBoxes: 1,
	minSelections: 1,
};

export const BET_TYPE_GROUP_DAILY_DOUBLE = {
	id: BET_TYPE_DAILY_DOUBLE,
	title: 'Daily Double',
	boxed: false,
	numberOfBoxes: 1,
	minSelections: 1,
};

export const BET_TYPE_GROUP_STRAIGHTEIGHT = {
	id: BET_TYPE_STRAIGHT_EIGHT,
	title: "Str8 '8'",
	boxed: true,
	numberOfBoxes: 8,
	minSelections: 8,
};

export const BET_TYPE_GROUPS_EXOTIC_OBJECT = {
	[BET_TYPE_QUINELLA]: BET_TYPE_GROUP_QUINELLA,
	[BET_TYPE_EXACTA]: BET_TYPE_GROUP_EXACTA,
	[BET_TYPE_TRIFECTA]: BET_TYPE_GROUP_TRIFECTA,
	[BET_TYPE_FIRSTFOUR]: BET_TYPE_GROUP_FIRSTFOUR,
	[BET_TYPE_QUADDIE]: BET_TYPE_GROUP_QUADDIE,
	[BET_TYPE_DAILY_DOUBLE]: BET_TYPE_GROUP_DAILY_DOUBLE,
	[BET_TYPE_STRAIGHT_EIGHT]: BET_TYPE_GROUP_STRAIGHTEIGHT,
};

export const BET_TYPE_GROUPS_ALL_OBJECT = {
	[BET_TYPE_WINPLACE]: BET_TYPE_GROUP_WINPLACE,
	[BET_TYPE_QUINELLA]: BET_TYPE_GROUP_QUINELLA,
	[BET_TYPE_EXACTA]: BET_TYPE_GROUP_EXACTA,
	[BET_TYPE_TRIFECTA]: BET_TYPE_GROUP_TRIFECTA,
	[BET_TYPE_FIRSTFOUR]: BET_TYPE_GROUP_FIRSTFOUR,
	[BET_TYPE_STRAIGHT_EIGHT]: BET_TYPE_GROUP_STRAIGHTEIGHT,
};

export const NUM_SELECTIONS_FOR_EXOTIC = {
	[BET_TYPE_QUINELLA]: BET_TYPE_GROUP_QUINELLA.minSelections,
	[BET_TYPE_EXACTA]: BET_TYPE_GROUP_EXACTA.minSelections,
	[BET_TYPE_TRIFECTA]: BET_TYPE_GROUP_TRIFECTA.minSelections,
	[BET_TYPE_FIRSTFOUR]: BET_TYPE_GROUP_FIRSTFOUR.minSelections,
	[BET_TYPE_STRAIGHT_EIGHT]: BET_TYPE_GROUP_STRAIGHTEIGHT.minSelections,
};

export const BET_COMBINATION_SAME_RACE_MULTI = 'Combine Same Race Multi';
