// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, typography, ui } from '@tbh/ui-kit';

// Components
import { Button, Heading, List, ListItem, Panel } from '@tbh/ui-kit';

const StyledMoreInfo = styled(List)(
	(props) => css`
		label: MoreInfo;

		font-size: ${typography(props)['size_-1']};
		padding: ${spacings(props).compact}px 0 0 0;
		border-top: 1px solid ${ui(props).color_4};
		margin: ${spacings(props).cozy}px 0;
	`,
);

const MoreInfo = (props) => {
	const { limitType, closeMoreInfo, t } = props;

	const typeOfLimitLowerCase = t(limitType.toLowerCase());
	const limitTypeText = limitType === 'Loss' ? t('lose') : t('spend');
	const limitTypeFactor = limitType === 'Loss' ? t('minus') : t('including');

	return (
		<Panel padding={Panel.padding.SPACING_COZY}>
			<Heading type="5" centered noMargin>
				{t('MoreInfo__Heading', { limitType })}
			</Heading>

			<StyledMoreInfo padding="2">
				<ListItem>{t('MoreInfo__One', { typeOfLimitLowerCase, limitTypeText })}</ListItem>
				<ListItem>{t('MoreInfo__Two', { typeOfLimitLowerCase, limitTypeText })}</ListItem>
				<ListItem>{t('MoreInfo__Three', { typeOfLimitLowerCase, limitTypeText })}</ListItem>
				<ListItem>{t('MoreInfo__Four', { typeOfLimitLowerCase })}</ListItem>
				<ListItem>{t('MoreInfo__Five', { typeOfLimitLowerCase, limitTypeFactor })}</ListItem>
				<ListItem>{t('MoreInfo__Six', { typeOfLimitLowerCase })}</ListItem>
				<ListItem>{t('MoreInfo__Seven')}</ListItem>
			</StyledMoreInfo>

			<Button action={closeMoreInfo} type="secondary" block>
				{t('MoreInfo__Done')}
			</Button>
		</Panel>
	);
};

MoreInfo.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/**
	 * Type of limit that will appear in the text
	 * Examples: 'deposit', 'loss', 'spend'
	 */
	limitType: PropTypes.string.isRequired,

	/** Hook for redirecting to the previous screen */
	closeMoreInfo: PropTypes.func.isRequired,
};

export default withNamespaces()(MoreInfo);
