import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { spacings, media } from '@tbh/ui-kit';

import { GENERIC_SILK } from '../../../../common/constants/Racing';
import { BET_TYPE_WIN } from '../../../../store/entities/constants/BetConstants';

import { getBetProduct } from '../../../../pages/Racing/RacingHome/racingSelectorsGRS';

import { Image, Text, PlotElements } from '@tbh/ui-kit';
import RaceBettingAdd from '../RaceBettingAdd/RaceBettingAdd';

const StyledRaceSelectionTips = styled(PlotElements)`
	${(props) => css`
		label: RaceSelectionTips;

		margin-bottom: ${spacings(props).cozy}px;
	`};
`;

const StyledRaceSelectionTips__Column = styled(PlotElements)`
	${(props) => css`
		label: RaceSelectionTips__Column;

		margin-right: ${spacings(props).compact}px;

		&:last-child {
			margin-right: 0;
		}
	`};

	${media(
		(props) => css`
			margin-right: ${spacings(props).cozy}px;

			&:last-child {
				margin-right: 0;
			}
		`,
		380,
	)};
`;

const StyledRaceSelectionTips__Runner = styled(PlotElements)`
	${(props) => css`
		label: RaceSelectionTips__Runner;

		margin-bottom: ${spacings(props).tight}px;
	`};

	${media(
		css`
			min-width: 64px;
		`,
		380,
	)};
`;

const RaceSelectionTips = (props) => {
	const {
		t,
		className,
		size,
		selections,
		raceProducts,
		isBettingAvailable,
		buildSelectionBetButtons,
		handleClickSingle,
		handleClickMulti,
		displayRunner,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<StyledRaceSelectionTips className={componentClasses}>
			{selections.map((selection) => {
				const betProduct = getBetProduct(raceProducts, selection.prices, BET_TYPE_WIN);
				const displayedBetProducts = [betProduct];
				const selectionBetButtons = buildSelectionBetButtons(selection.prices, displayedBetProducts, BET_TYPE_WIN);
				return (
					<StyledRaceSelectionTips__Column key={selection.id} size={size} vertical>
						{displayRunner && (
							<StyledRaceSelectionTips__Runner size={size} align="space-around">
								<Text strong size="-1">{`${t('No.')} ${selection.number}`}</Text>
								<Image
									height={24}
									src={selection.silk}
									alt={`${selection.name} racing silk`}
									substituteImageSrc={GENERIC_SILK}
								/>
							</StyledRaceSelectionTips__Runner>
						)}
						{selectionBetButtons.map((betButton) => (
							<RaceBettingAdd
								key={`${selection.id}_${betButton.product_id}`}
								price={betButton.price}
								initialPrice={betButton.initialPrice}
								betType={betButton.bet_type}
								productId={betButton.product_id}
								selectionId={selection.id}
								hasMulti={betButton.hasMulti}
								addToSingle={handleClickSingle}
								addToMulti={betButton.hasMulti ? handleClickMulti : null}
								bettingAvailable={isBettingAvailable && betButton.productAvailable}
								priceRollups={betButton.priceRollups}
								emphasisedType={null}
								useWrapper={false}
								size={size}
							/>
						))}
					</StyledRaceSelectionTips__Column>
				);
			})}
		</StyledRaceSelectionTips>
	);
};

RaceSelectionTips.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}).isRequired,

	/** List of running selections in the race */
	selections: PropTypes.arrayOf(
		PropTypes.shape({
			/** Whether or not the selection is a race tip */
			in_race_comment: PropTypes.bool,

			/** The runner number */
			number: PropTypes.number,
		}),
	).isRequired,

	/** Action to handle the selection being selected for a single bet */
	handleClickSingle: PropTypes.func.isRequired,

	/** Action to handle the selection being selected for a multi bet */
	handleClickMulti: PropTypes.func.isRequired,

	/** Action to build the list of selection bet buttons */
	buildSelectionBetButtons: PropTypes.func.isRequired,

	/** List of betting products on the race */
	raceProducts: PropTypes.arrayOf(
		PropTypes.shape({
			available: PropTypes.bool.isRequired,
			bet_type: PropTypes.string.isRequired,
			fixed: PropTypes.bool.isRequired,
			product_id: PropTypes.number.isRequired,
		}),
	),

	/** Display the runner number and silk above the odds */
	displayRunner: PropTypes.bool,

	/** Whether or not betting is allowed on the race */
	isBettingAvailable: PropTypes.bool,

	/** Extra classes */
	className: PropTypes.string,
};

RaceSelectionTips.defaultProps = {
	className: '',
	raceProducts: [],
	displayRunner: true,
	isBettingAvailable: true,
};

export default withNamespaces()(RaceSelectionTips);
