// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { Trans, withNamespaces } from 'react-i18next';

// Components
import { Button, EmailInput, Notification, Text, Panel } from '@tbh/ui-kit';

const SelfExclusionPrompt = (props) => {
	const { email, excludeUser, handleChange, disabled, t } = props;

	return (
		<div>
			<Notification
				type={Notification.types.COLOUR_DANGER}
				message={
					<Text size="-1" align="center" type="alternate">
						<Trans i18nKey="SelfExclusionPrompt__Notification">
							<Text tag="span" size="-1" type="alternate" strong>
								Note:
							</Text>{' '}
							If you proceed with Self-Exclusion this action will be{' '}
							<Text tag="span" size="-1" type="alternate" strong underline>
								Permanent
							</Text>
							.
						</Trans>
					</Text>
				}
				strong
			/>

			<Panel padding={Panel.padding.SPACING_COZY}>
				<Text size="-1" paragraph strong>
					{t('SelfExclusionPrompt__Proceed')}
				</Text>
				<Text size="-1" paragraph>
					{t('SelfExclusionPrompt__Pending')}
				</Text>
				<Text size="-1" paragraph>
					{t('SelfExclusionPrompt__StandingBalance')}
				</Text>
				<Text size="-1" paragraph>
					{t('SelfExclusionPrompt__Marketing')}
				</Text>
				<Text size="-1" paragraph>
					{t('SelfExclusionPrompt__PuntingClub')}
				</Text>
				<Text size="-1" paragraph>
					{t('SelfExclusionPrompt__Account')}
				</Text>

				<EmailInput name="email" label={t('EmailAddress')} value={email} onChange={handleChange} margin="cozy" disabled />

				<Text size="-1" paragraph>
					{t('SelfExclusionPrompt__Confirmation')}
				</Text>

				<Button action={excludeUser} type="danger" disabled={disabled} block>
					{t('SelfExclusionPrompt__Exclude')}
				</Button>
			</Panel>
		</div>
	);
};

SelfExclusionPrompt.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** User's email */
	email: PropTypes.string.isRequired,

	/** Hook for proceeding to the confirmation page */
	excludeUser: PropTypes.func.isRequired,

	/** Hook for handling changes in the email input */
	handleChange: PropTypes.func.isRequired,

	/** Whether or not to disable the exclusion button */
	disabled: PropTypes.bool,
};

SelfExclusionPrompt.defaultProps = {
	disabled: false,
};

export default withNamespaces()(SelfExclusionPrompt);
