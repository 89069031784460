import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, typography, ui } from '@tbh/ui-kit';

import { List, ListItem, Text, CollapsibleContent, CollapsibleIcon, HeaderBar, Input } from '@tbh/ui-kit';
import BetSelectionAndAmount from '../BetSelection/BetSelectionAndAmount/BetSelectionAndAmount';
import CurrencyInput from '../../Application/Currency/CurrencyInput/CurrencyInput';

const StyledMultiBetSelectionList__HeaderCloseIcon = styled(CollapsibleIcon)(
	(props) => css`
		label: MultiBetSelectionList__HeaderCloseIcon;

		min-width: ${spacings(props).comfortable}px;
	`,
);

const StyledMultiBetSelectionList__BulkBetInput = styled(CurrencyInput)(
	(props) => css`
		label: MultiBetSelectionList__BulkBetInput;

		max-width: 150px;

		${Input.StyledInput__Input} {
			color: ${typography(props).color_1_alternate};
		}
	`,
);

const StyledMultiBetSelectionList__ListItem = styled(ListItem)(
	(props) => css`
		label: MultiBetSelectionList__ListItem;

		&:nth-child(even) {
			background: ${ui(props).color_1};
		}
		margin-bottom: 8px!important;
	`,
);

const cssMultiBetSelectionList__Disabled = css`
	label: MultiBetSelectionList__Disabled;

	opacity: 0.5;
	pointer-events: none;
`;

const BetSlipBumpPrice = styled('div')(
	(props) => css`
		cursor: pointer;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #bb181b;
		&:hover {
			animation: BumpStripeAnimation 10s linear infinite;
			background: repeating-linear-gradient(-45deg, white, white 3px, #bb181b 2px, #bb181b 6px);

			background-size: 200% 200%;
			color: #000000;
		}

		${props.active &&
			css`
				animation: BumpStripeAnimation 10s linear infinite;
				background: repeating-linear-gradient(-45deg, white, white 3px, #bb181b 2px, #bb181b 6px);

				background-size: 200% 200%;
				color: #000000;
			`}

		width: 120px;
		height: 30px;
		border-radius: 2px;
	`,
);

const MultiBetSelectionList = (props) => {
	const {
		t,
		className,
		disabled,
		isCollapsed,
		selections,
		onBetTypeChange,
		onClickSelection,
		onDismiss,
		onRemove,
		onBulkStakeChange,
		onStakeChange,
		onMultiToggle,
		onChangeBetType,
		onSelectionToggle,
		track,
		defaultBetAmount,
		selectionsAreFromSameRace,
		bet,
		cash_out_enabled,
		showPricePump,
		EachBetaction,
		totalBetAmount,

		shouldPulse,
		handleSelectedCupomBet,
		handleBonusBetChange,
		bonus_bet_enabled,
		price_bump_enabled,
		handleUpdateSelection,
		selectedBet,
		handleSelectedBet,
		races,
	} = props;

	const componentClasses = cx(disabled && cssMultiBetSelectionList__Disabled, {
		[className]: className,
	});

	// const title = selectionsAreFromSameRace ? t('Singles') : 'MultiBet Bet';
	const title = t('Singles');

	const shouldShowPriceBump = (selection) => {
		return showPricePump && selection.selectedBetType == 'win';
	};

	const onBetClick = (s) => {
		let data = null;

		if (selectedBet && selectedBet.id == s.id && selectedBet.group == 'selections') return;

		if (
			!selectedBet ||
			(selectedBet.id != s.id && selectedBet.racingBetType != s.racingBetType && selectedBet.group == 'selections')
		) {
			data = {
				...s,
				amount: s.stake,
				group: 'selections',
			};
		}

		handleSelectedBet(data);
	};

	const sortedSelections = [...selections].sort((a, b) => {
		return new Date(a.startTime) - new Date(b.startTime);
	});

	return (
		<div className={componentClasses}>
			<HeaderBar
				action={onSelectionToggle}
				type="primary"
				className={css`
					cursor: pointer;
					height: 60px;
				`}
				nodeRight={<StyledMultiBetSelectionList__HeaderCloseIcon size="-3" isCollapsed={isCollapsed} />}
				aside={
					<StyledMultiBetSelectionList__BulkBetInput
						onChange={onBulkStakeChange}
						value=""
						name="bulkBetAmount"
						placeholder={t('MultiBetSelectionList__AllSingles')}
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}
					/>
				}
			>
				<Text type="alternate" strong size="-1">
					{/* {t('Singles')} ({selections.length}) */}
					{title}
				</Text>
			</HeaderBar>
			<CollapsibleContent header={`${title}`} isCollapsed={isCollapsed}>
				<List
					className={css`
						padding: 4px;
					`}
				>
					{sortedSelections.map((selection) => (
						<StyledMultiBetSelectionList__ListItem key={selection.id}>
							{/* {races.find((r) => r.id == selection.race_id && r.status != 'selling') && (
								<BetNotificationEventClosed />
							)} */}
							<BetSelectionAndAmount
								isClosed={races.find((r) => r.id == selection.race_id && r.status != 'selling')}
								handleSelectedCupomBet={handleSelectedCupomBet}
								selectionsAreFromSameRace={selectionsAreFromSameRace}
								onChangeBetType={() => onChangeBetType(selection, 'priceBump')}
								{...selection}
								isMulti={true}
								disabled={disabled || selection.disabled}
								onBetTypeChange={onBetTypeChange}
								onClickSelection={onClickSelection}
								onDismiss={onDismiss}
								onRemove={() => onRemove(selection)}
								onStakeChange={onStakeChange}
								onMultiToggle={onMultiToggle}
								track={track}
								bet={bet}
								cash_out_enabled={cash_out_enabled}
								handleBonusBetChange={() => handleBonusBetChange(selection.id, 'single')}
								bonus_bet_enabled={bonus_bet_enabled}
								price_bump_enabled={price_bump_enabled}
								selectedBet={
									selectedBet &&
									selectedBet.id === selection.id &&
									selectedBet.racingBetType == selection.racingBetType &&
									selectedBet.group == 'selections'
								}
								handleSelectedBet={() => onBetClick(selection)}
							/>
						</StyledMultiBetSelectionList__ListItem>
					))}
				</List>
			</CollapsibleContent>
		</div>
	);
};

MultiBetSelectionList.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** List of selections */
	selections: PropTypes.array.isRequired,

	user: PropTypes.array.isRequired,

	/** Action to handle the user using the bulk stake input */
	onBulkStakeChange: PropTypes.func.isRequired,

	/** Action if the stake is changed for an individual selection */
	onStakeChange: PropTypes.func.isRequired,

	/** Action to toggle open/closed the selections list */
	onSelectionToggle: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,

	/** Whether to disable the selections or not */
	disabled: PropTypes.bool,

	/** Whether the selections can have their stakes changed */
	editable: PropTypes.bool,

	/** If the selections list is open or closed */
	isCollapsed: PropTypes.bool,

	/** Tracking function for selections */
	track: PropTypes.func,

	/** Action to handle when a bet type changes for a selection */
	onBetTypeChange: PropTypes.func,

	/** Action to handle a selection being clicked on */
	onClickSelection: PropTypes.func,

	/** Function to call when the bet is to be removed from the multi bet slip */
	onRemove: PropTypes.func,

	/** Function for dismissing any error message */
	onDismiss: PropTypes.func,

	/** Function to call when the multi checkbox id toggled */
	onMultiToggle: PropTypes.func,

	defaultBetAmount: PropTypes.number,

	bet: PropTypes.number,
};
MultiBetSelectionList.defaultProps = {
	className: '',
	disabled: false,
	editable: true,
	isCollapsed: false,
	onBetTypeChange: () => {},
	onClickSelection: () => {},
	onRemove: () => {},
	onDismiss: () => {},
	onMultiToggle: () => {},
	track: () => {},
	defaultBetAmount: 0,
	bet: 0,
	// lyabi:'',
};

export default withNamespaces()(MultiBetSelectionList);
