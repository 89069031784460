import 'core-js/stable';
const __svg__ = { path: './images/icons/**/*.svg', name: 'images/icons.logos.svg' };
const IS_CORDOVA = !!process.env.IS_CORDOVA;
const FastClick = require('fastclick');
if ('addEventListener' in document && IS_CORDOVA) {
	document.addEventListener(
		'DOMContentLoaded',
		function() {
			FastClick.attach(document.body);
		},
		false,
	);
}

require('webpack-svgstore-plugin/src/helpers/svgxhr')(__svg__);

require('normalize.css');
require('./legacy/bootstrap');
require('./legacy/main');
