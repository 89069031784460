import styled, { css } from 'react-emotion';
import { spacings, brand, typography, Text, media, Button, StatCell } from '@tbh/ui-kit';
import {
	moreThan2minColor,
	lessThan2MinColor,
	paidColor,
} from '../../../../../store/application/applicationConstants';
import { MEDIA_QUERIES } from '../../../../../constants/themes';

export const StyledRacingFeature__Label = styled(Text)(
	(props) => css`
		label: RacingFeature__Label;

		display: flex;
		//padding: 4px;
		justify-content: center;
		margin-bottom: ${spacings(props).compact}px;
		color: ${typography(props).color_1_alternate};
		// text-align: center;
		text-transform: uppercase;
		font-size: ${typography(props)['size_-2']};;
		line-height: ${typography(props)['size_-2']};
		font-weight: 100;
		align-items: center;
	`,
	(props) =>
		media(
			css`
				font-size:  ${typography(props)['size_-2']};
				line-height: ${typography(props)['size_-2']};
				font-weight: 100;
			`,
			768,
		),
);

export const StyledRacingFeature__raceNumberBox = styled('div')`
	${(props) => css`
		label: StyledRacingFeature__raceNumberBox;

		color: ${typography(props).color_1_alternate};
		background: #666666;
		border: 1px solid #666666;
		border-bottom: 2px solid #5c5c5c;
		text-align: center;
		margin-top: ${spacings(props).tight}px;
		width: 30px;
		display: inline-block;
		padding: 2px;
	`};
`;
export const StyleRaceFeatureNumber = styled('div')`
	${(props) => css`
		label: StyleRaceFeatureNumber;

		color: ${typography(props).color_1_alternate};
		background: #666666;
		border: 1px solid #666666;
		border-bottom: 2px solid #5c5c5c;
		text-align: center;
		width: 30px;
		height: 100%;
		display: inline-block;
		padding: 2px;
		border-radius: 4px;
	`};
`;

export const StyledRaceCard_goToRace = styled(Button)(
	(props) => css`
		label: StyledRaceCard_goToRace;
		cursor: pointer;
		width: 100%;
		background: ${brand(props).rch_4};
		border-radius: 4px;
		border: none;
		// border-style: solid;
		// border-color: ${brand(props).rch_4} ${brand(props).rch_4} ${brand(props).rch_4};
		padding: ${spacings(props).tight}px;
		font-family: ${typography(props).base_font};
		font-size: ${typography(props).size_0};


		// background: linear-gradient(-45deg,rgb(102 42 42),${brand(props).rch_4});
		background: linear-gradient(273deg,${brand(props).rch_4},#bd1d24,#5b0101);
		background-color:rgb(26,27,35);
		background-size: 400% 400%;	
		animation: gradient 1s ease infinite;

						


		// @media (min-width: 1024px) {

		// }

		&:hover {
			// This is to override the specificity of the Text component
			// ${Text} {
			// 	color: ${typography(props).color_1_alternate};
			// }
			animation:none;
			border:none;
			background: #5b0101!important;
		}
	`,
	(props) =>
		media(
			css`
				padding: ${spacings(props).comfortable}px;
			`,
		),
);

export const Styled__TimeToGoBUtton = styled(Button)(
	(props) => css`
          label: Styled__TimeToGoBUtton;

          font-size:  ${typography(props)['size_-2']};
          display: block;
          margin-top: ${spacings(props).tight}px;
        //   background: ${brand(props).rch_4};
        //   border-color: ${brand(props).rch_4};
          background: ${moreThan2minColor};
          border-color: ${moreThan2minColor};
          size:small;
          width:auto;
          height:25px;
          margin-top:-5px;
          position: absolute;
          color:#ffffff;
          font-weight: bold;


          ${props.lessThan2Min === 'less2Min' &&
		props.status === 'selling' &&
		css`
							&& {
								background-color: ${lessThan2MinColor};
								border: 1px solid ${lessThan2MinColor};
							}
						`};

          ${props.status === 'paid' &&
		css`
							&& {
								background-color: ${paidColor};
								border: 1px solid ${paidColor};
								text-transform: uppercase;
							}
						`};

          ${props.status === 'closed' &&
		css`
							&& {
								background-color: ${lessThan2MinColor};
								border: 1px solid ${lessThan2MinColor};
								text-transform: uppercase;
							}
						`};
      `,
);

export const Styled__BlockLoading = styled('div')(
	(props) => css`
		width: 27px;
		height: 18px;
		margin-left: 8px;
		border-radius: 4px;
		background-color: rgb(54, 69, 79);
		animation: changeColor 1s infinite;
	`,
);
export const Styled__BlockLoadingText = styled('div')(
	(props) => css`
		display: block;
		margin-top: 15px;
		border: none !important;
		width: ${props.width};
		border-radius: 4px;
		height: 15px;
		margin-top: 5px;
		color: #ffffff;
		font-weight: bold;
		margin-left: 8px;
		animation: changeColor 1s infinite;
	`,
);

export const Styled__TimeToGoButtonLoading = styled(Button)(
	(props) => css`
		  label: Styled__TimeToGoButtonLoading;
  
		  display: block;
		  margin-top: ${spacings(props).tight}px;
		  // background: ${brand(props).rch_4};
		  // border-color: ${brand(props).rch_4};
		  background: ${moreThan2minColor};
		  border: none!important;;
		  size:small;
		  top: 0;
		  width:35px;
		  height:20px;
		  margin-top:-15px;
		  color:#ffffff;
		  font-weight: bold;
		  animation: buttonLoading 2s infinite;
		  @keyframes buttonLoading {
				0% {
					background-color: rgba(55,155,	55, 0.2);
				}
				25% {
					background-color: rgba(55	,155,	55, 0.6);
				}
				50% {
					background-color: rgba(55	,155,	55, 0.8);
				}
				75% {
					background-color: rgba(55	,155,	55, 0.6);
				}
				100% {
					background-color: rgba(55	,155,	55, 0.4);
				}
			}
			@media ${MEDIA_QUERIES.tabletAndUp} {
				top: 4px;
			}	
	  `,
);

export const StyledRaceFeatureHeader__Status = styled(StatCell)`
	${(props) => css`
		label: RaceHeaderBar__Status;
		flex-direction: row;
		max-width: 150px;
		border-radius: 4px;
		color: #ffff;
		height: fit-content;
		${props.status === 'paying' &&
		css`
				background-color: #32be60;
			`};
		${props.status === 'interim' &&
		css`
				background-color: #32be60;
			`};
		${props.status === 'closed' &&
		css`
				background-color: ${brand(props).rch_4};
				/* background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); */
				/* background:#f48c06; */
			`};

		${props.status === 'paid' &&
		css`
				background-color: #5d97fb;
			`};
		${props.status === 'selling' &&
		css`
				background-color: #ffff;
				color: black;
			`};
		@media (max-width: 440px) {
			flex-direction: column;
		}

		@media (max-width: 390px) {
			flex-direction: row;
		}
	`};
`;

export const StyledRaceFeatureHeader = styled('div')(
	(props) => css`
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: 100%;

		// @media (min-width: 1040px) {
		// 	justify-content: space-around;
		// 	flex-direction: column;
		// }

		flex-direction: row;
		flex-wrap: wrap;

		@media (max-width: 640px) {
			flex-direction: column;
			padding: 6px;
		}
	`,
);
