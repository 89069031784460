// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { Trans, withNamespaces } from 'react-i18next';

import { spacings } from '@tbh/ui-kit';

// Components
import { Button, Input, Text, LoadingMask, Notification, Panel } from '@tbh/ui-kit';
import CurrencyDisplay from '../../Application/Currency/CurrencyDisplay/CurrencyDisplay';
const StyledDefaultBetAmounts__Notification = styled(Notification)(
	(props) => css`
		label: DefaultBetAmounts__Notification;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const StyledPanel = styled(Panel)(
	(props) => css`
		label: DefaultBetAmounts__panel;

		max-width: 100%;
	`,
);

class DefaultBetAmounts extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** User's first name */
		first_name: PropTypes.string.isRequired,

		/** Hook for updating the default bet amounts */
		updateDefaultBetAmounts: PropTypes.func.isRequired,

		/** Function to clear the error message */
		clearMessage: PropTypes.func.isRequired,

		/** Extra classes */
		className: PropTypes.string,

		/** Show or not the confirmation message */
		confirmation: PropTypes.bool,

		/** User's default bet amount */
		default_bet_amount: PropTypes.number,

		/** User's default tournament bet amount */
		default_tournament_bet_amount: PropTypes.number,

		/** User's accepted live bet warning */
		accepted_live_bet_warning: PropTypes.bool,

		/** Error returned from the function updateDefaultBetAmounts */
		error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

		/** If the component is loading or not */
		defaultAmountsLoading: PropTypes.bool,

		/** If the default amount update request is loading or not */
		amountsUpdateLoading: PropTypes.bool,
	};

	static defaultProps = {
		accepted_live_bet_warning: false,
		className: null,
		confirmation: false,
		default_bet_amount: null,
		default_tournament_bet_amount: null,
		error: null,
		defaultAmountsLoading: false,
		amountsUpdateLoading: false,
	};

	constructor(props) {
		super(props);

		this.state = {
			new_default_bet_amount: '',
			new_default_tournament_bet_amount: '',
			accepted_live_bet_warning: props.accepted_live_bet_warning ? props.accepted_live_bet_warning : false,
			new_default_bet_amount_valid: true,
			new_default_tournament_bet_amount_valid: true,
			prevProps: props,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const prevProps = prevState.prevProps;
		const accepted_live_bet_warning =
			prevProps.accepted_live_bet_warning !== nextProps.accepted_live_bet_warning
				? nextProps.accepted_live_bet_warning
				: prevState.accepted_live_bet_warning;

		return {
			accepted_live_bet_warning,
			prevProps: nextProps,
		};
	}

	/**
	 * handle changes in the inputs
	 *
	 * @param name
	 * @param value
	 * @param valid
	 */
	handleChange = (name, value, valid) => {
		this.setState({
			[name]: value,
			[`${name}_valid`]: valid,
		});
	};

	/**
	 * used to change which checkbox is checked
	 */
	handleSelectAcceptance = () => {
		this.setState({
			accepted_live_bet_warning: true,
		});
	};

	/**
	 * used to change which checkbox is checked
	 */
	handleSelectNotAcceptance = () => {
		this.setState({
			accepted_live_bet_warning: false,
		});
	};

	/**
	 * update the Default Bet Amounts
	 */
	handleUpdateDefaults = (event) => {
		this.props.clearMessage();
		event.preventDefault();

		let default_bet_amount = parseInt(this.state.new_default_bet_amount * 100);
		let tournament_bet_amount = parseInt(this.state.new_default_tournament_bet_amount * 100);
		let accepted_live_bet_warning = this.state.accepted_live_bet_warning;

		default_bet_amount = this.state.new_default_bet_amount === '' ? this.props.default_bet_amount : default_bet_amount;

		tournament_bet_amount =
			this.state.new_default_tournament_bet_amount === ''
				? this.props.default_tournament_bet_amount
				: tournament_bet_amount;

		this.props.updateDefaultBetAmounts(default_bet_amount, tournament_bet_amount, accepted_live_bet_warning);
	};

	render() {
		const {
			t,
			className,
			clearMessage,
			confirmation,
			default_bet_amount,
			default_tournament_bet_amount,
			error,
			first_name,
			defaultAmountsLoading,
			amountsUpdateLoading,
		} = this.props;

		const {
			new_default_bet_amount,
			new_default_tournament_bet_amount,
			accepted_live_bet_warning,
			new_default_bet_amount_valid,
			new_default_tournament_bet_amount_valid,
		} = this.state;

		const { handleChange, handleSelectAcceptance, handleSelectNotAcceptance, handleUpdateDefaults } = this;


		const defaultBetAmountsClasses = cx({
			[className]: className,
		});

		let constraints = {
			value: {
				// must be a number
				numericality: {
					// must be greater than or equal to 0
					greaterThanOrEqualTo: 0,
				},
			},
		};

		return (
			<StyledPanel className={defaultBetAmountsClasses}>
				<LoadingMask loading={defaultAmountsLoading} />

				{error || confirmation ? (
					<StyledDefaultBetAmounts__Notification
						strong={Boolean(error)}
						type={confirmation ? Notification.types.COLOUR_SUCCESS : Notification.types.COLOUR_DANGER}
						message={confirmation ? t('DefaultBetAmounts__Error') : error}
						buttonAction={clearMessage}
					/>
				) : null}

				{/* <Text strong paragraph className={css`font-size:15px;`}>
					{t('DefaultBetAmounts__BetAmount')}
				</Text> */}
				<Text size="-1" paragraph>
					<Trans i18nKey="DefaultBetAmounts__BetAmountMessage">
						Your current <strong>default bet amount</strong> is:
					</Trans>
				</Text>
				<Text type="success" size="2" align="center" paragraph strong>
					{default_bet_amount > 0 ? <CurrencyDisplay amount={default_bet_amount} /> : t('DefaultBetAmounts__NotSet')}
				</Text>
				<Input
					name="new_default_bet_amount"
					onChange={handleChange}
					value={new_default_bet_amount}
					label={t('DefaultBetAmounts__NewDefault')}
					type="number"
					constraints={constraints}
					margin="cozy"
				/>

				{/* <Text strong paragraph className={css`font-size:15px;`}>
					{t('DefaultBetAmounts__TournamentBetAmount')}
				</Text>
				<Text size="-1" paragraph>
					<Trans i18nKey="DefaultBetAmounts__TournamentBetAmountMessage">
						And your default <strong>tournament bet amount</strong> is:
					</Trans>
				</Text>
				<Text type="success" size="2" align="center" paragraph strong>
					{default_tournament_bet_amount > 0 ? (
						<CurrencyDisplay amount={default_tournament_bet_amount} />
					) : (
						t('DefaultBetAmounts__NotSet')
					)}
				</Text>
				<Input
					name="new_default_tournament_bet_amount"
					onChange={handleChange}
					value={new_default_tournament_bet_amount}
					label={t('DefaultBetAmounts__NewDefault')}
					type="number"
					constraints={constraints}
					margin="cozy"
				/>

				<Text strong paragraph className={css`font-size:15px;`}>
					{t('DefaultBetAmounts__TournamentLive')}
				</Text>
				<Text size="-1" paragraph>
					{t('DefaultBetAmounts__TournamentMessage')}
				</Text>

				<PlotElements align="space-around">
					<Checkbox
						name="accepted"
						label={t('DefaultBetAmounts__Accepted')}
						id="Accepted"
						action={handleSelectAcceptance}
						checked={accepted_live_bet_warning}
						labelSize="-1"
						margin="cozy"
						inline
					/>
					<Checkbox
						name="not-accepted"
						label={t('DefaultBetAmounts__NotAccepted')}
						id="Not Accepted"
						action={handleSelectNotAcceptance}
						checked={!accepted_live_bet_warning}
						labelSize="-1"
						margin="cozy"
						inline
					/>
				</PlotElements> */}
				<Text size="-2" type="light" style={{ marginBottom: '10px' }}>
					You have not set a Default Bet Amount
				</Text>
				<Text size="-2"  style={{ marginBottom: '10px' }}>
					If you set a Default Bet Amount, it will only be applied to the first selection you add to Bet Slip.
				</Text>
				<Button
					action={handleUpdateDefaults}
					type="danger"
					disabled={!new_default_bet_amount_valid || !new_default_tournament_bet_amount_valid}
					loading={amountsUpdateLoading}
					style={{
						backgroundColor: '#161a1d',
						border: '1px solid #161a1d',
						borderRadius: '4px',
						fontWeight: '500',
						fontSize: '100%',
						padding: '6px 10px',
					}}
					block
				>
					{t('DefaultBetAmounts__Update')}
				</Button>
			</StyledPanel>
		);
	}
}

export default withNamespaces()(DefaultBetAmounts);
