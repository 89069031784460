export const allocationWeightCalculate = (weight, allocationWeight) => {

    const allocation = weight - allocationWeight;
    let showAllocationWeight;


    if(!allocationWeight || allocationWeight === 0) {
        return null;
    }

    if (allocation !== 0) {
        showAllocationWeight = `(a${allocation}kg)`;
    } else {
        showAllocationWeight = null;
    }
    return showAllocationWeight;
};