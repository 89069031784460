import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';

const StyledCarouselSlide = styled('div')(
	(props) => css`
		label: CarouselSlide;

		&:hover {
			background: rgba(0, 0, 0, 0.05);
		}

		${props.autoWidth &&
			css`
				width: auto !important;
			`};
	`,
);
const StyledCarouselSlideSpan = StyledCarouselSlide.withComponent('span');

class CarouselSlide extends React.Component {
	static propTypes = {
		/** Children for the carousel item */
		children: PropTypes.node.isRequired,

		/** If the carousel items shouldn't be links */
		noLinkItems: PropTypes.bool,

		/** Extra classes */
		className: PropTypes.string,

		/** ID of the carousel item */
		itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

		/** Action to fire when the item is clicked on */
		onClick: PropTypes.func,

		/** If the current item is the active icon */
		active: PropTypes.bool,

		/** Whether to set width: auto */
		autoWidth: PropTypes.bool,
	};

	static defaultProps = {
		noLinkItems: false,
		className: '',
		onClick: function() {},
		active: false,
		autoWidth: false,
	};

	constructor() {
		super();
		this.handleClick = this.handleClick.bind(this);
	}

	/**
	 * Handle click on tab
	 */
	handleClick() {
		this.props.onClick(this.props.itemId);
	}

	render() {
		const { children, className, autoWidth, noLinkItems } = this.props;

		const classes = cx('swiper-slide', 'carousel-slide', {
			[className]: className,
		});

		if (noLinkItems) {
			return (
				<StyledCarouselSlide autoWidth={autoWidth} className={classes}>
					{children}
				</StyledCarouselSlide>
			);
		}

		return (
			<StyledCarouselSlideSpan autoWidth={autoWidth} className={classes} onClick={this.handleClick}>
				{children}
			</StyledCarouselSlideSpan>
		);
	}
}

CarouselSlide.StyledCarouselSlide = StyledCarouselSlide;
CarouselSlide.StyledCarouselSlideSpan = StyledCarouselSlideSpan;

export default CarouselSlide;
