export const DEFAULT_CURRENCY_FORMAT = '0,0.00';
export const DEFAULT_CURRENCY_POSITION = 'left';

export const DEFAULT_CURRENCY_NAME = 'AUD';
export const DEFAULT_CURRENCY_SYMBOL = '$';
export const DEFAULT_BONUS_CURRENCY_NAME = 'Bonus';
export const DEFAULT_BONUS_CURRENCY_SYMBOL = '$';
export const DEFAULT_TOURNAMENT_CURRENCY_NAME = 'BettaBucks';
export const DEFAULT_TOURNAMENT_CURRENCY_SYMBOL = '$';

export const DEFAULT_CURRENCY_CONFIGURATION = {
	format: DEFAULT_CURRENCY_FORMAT,
	position: DEFAULT_CURRENCY_POSITION,
	currencyName: DEFAULT_CURRENCY_NAME,
	currencySymbol: DEFAULT_CURRENCY_SYMBOL,
	bonusCurrencyName: DEFAULT_BONUS_CURRENCY_NAME,
	bonusCurrencySymbol: DEFAULT_BONUS_CURRENCY_SYMBOL,
	tournamentCurrencyName: DEFAULT_TOURNAMENT_CURRENCY_NAME,
	tournamentCurrencySymbol: DEFAULT_TOURNAMENT_CURRENCY_SYMBOL,
};