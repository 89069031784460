import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import { Panel, spacings, ui } from '@tbh/ui-kit';
import RaceCardLoading from './../../../components/features/Racing/RaceCard/RaceCardLoading';
import {
	StyledRaceCardContainer_main,
	StyledRaceCardContainer_expertSections,
	StyledRaceCard_ExpertSection,
	StyledRaceCard__SubSectionHeader,
} from './RaceCardContainer';

const StyledRaceTypeMenuContainer = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: column;
		padding: ${spacings().compact}px;
		width: 100%;
		border: 1px solid ${ui(props).color_6};
		border-radius: 0px 0px 4px 4px;
	`,
);

const StyledTypeMenu = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: row;
		gap: ${spacings(props).compact}px;
	`,
);

const StyledType = styled('div')(
	(props) => css`
		cursor: pointer;
		white-space: nowrap;
		display: flex;
		border-radius: 4px;
		padding: ${spacings(props).compact}px;
		border: 1px solid #e0e0e0;
		background-color: #f1f1f1;
		width: 100px;
		height: 36px;
	`,
);

const StyledTypeList = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: row;
		gap: ${spacings(props).compact}px;
		padding: ${spacings(props).compact}px;
		width: 100%;
		white-space: nowrap;
		overflow-x: scroll;

		&::-webkit-scrollbar {
			width: 6px;
			height: 6px;
			border-radius: 12px;
		}

		&::-webkit-scrollbar-track {
			background: #f1f1f1;
			border-radius: 12px;
		}

		&::-webkit-scrollbar-thumb {
			background: #888;
			border-radius: 12px;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: #555;
			border-radius: 12px;
		}
	`,
);

class RaceCardContainerLoading extends Component {
	render() {
		const { size } = this.props;

		return (
			<div>
				<StyledRaceCardContainer_main size={size}>
					<StyledRaceCardContainer_expertSections size={size}>
						<Panel>
							<StyledRaceCard_ExpertSection>
								<StyledRaceCard__SubSectionHeader>
									<span
										className={css`
											color: #fff;
										`}
									>
										<b>{'Expert Tips'}</b>
									</span>
								</StyledRaceCard__SubSectionHeader>
							</StyledRaceCard_ExpertSection>
						</Panel>
					</StyledRaceCardContainer_expertSections>
					<StyledRaceTypeMenuContainer>
						{size.width < 720 ? (
							<StyledTypeList>
								<StyledType />
								<StyledType />
								<StyledType />
							</StyledTypeList>
						) : (
							<StyledTypeMenu>
								<StyledType />
								<StyledType />
								<StyledType />
							</StyledTypeMenu>
						)}
					</StyledRaceTypeMenuContainer>
					<RaceCardLoading size={size} />
				</StyledRaceCardContainer_main>
			</div>
		);
	}
}

export default RaceCardContainerLoading;
