export default {
	Closed: 'Закрыто',
	Custom: 'изготовленный на заказ',
	"Oops! We're sorry but something went wrong. Please try again.":
		'К сожалению! Нам очень жаль, но что-то пошло не так. Пожалуйста, попробуйте еще раз.',
	Dismiss: 'отклонять',
	Position: 'Позиция',
	Daily: 'Ежедневно',
	SetLimit__NotSet: 'Не установлен',
	User: 'пользователь',
	CancelWithdrawal: 'Отменить снятие',
	Cash: 'Денежные средства',
	WON: 'ПОБЕДИЛ',
	Minimum: 'минимальный',
	Track: 'трек',
	PotentialPayout: 'Потенциальная выплат',
	PlayerBets: 'Ставки игрока',
	PINAuthenticationContainer__NotificationSuccess: 'PIN-аутентификация для данного устройства!',
	Login__KeepLoggedIn: 'Не выходить из системы',
	Accept: 'принимать',
	Verified: 'проверенный',
	Login__Player: 'Я - {{brandName}} игрок',
	'To Beat': 'Бить',
	MeetingListContainer__NoInternationalRaces: 'Нет доступных международных гонок',
	'UserAccountNavList__default-bet-amounts': 'Сумма ставки по умолчанию',
	Clear: 'Очистить',
	via: 'с помощью',
	TakeABreakLong__6MonthsValue: '6 месяцев перерыва',
	Loss: 'потеря',
	Rejected: 'Отклонено',
	Rebuy_plural: 'Ребаи',
	Starts: 'Запускает',
	CreditCard: 'Кредитная карта',
	BuyIn: 'Купить в',
	Password: 'пароль',
	Career: 'Карьера',
	Rank: 'Ранг',
	AuthoriseVerification__CreditCardWithBrand: 'Я разрешаю {{brandName}} проверить мою кредитную карту.',
	NameOnCreditCard: 'Имя на кредитной карте',
	SelfExclusionPrompt__Pending: 'Все ожидающие ставки будут стоять.',
	Promotions__NoPromotions: 'Нет доступных рекламных акций.',
	LockedScreen__NotYou: 'Не вы?',
	TotalRebuys: 'Всего повторных покупок',
	Confirmed: 'подтвердил',
	'1st Prize': '1-я премия',
	BetPlacementContainer__ExceededBalance: 'Сумма ставки превысила ваш баланс. Сумма установлена ​​максимально.',
	Account: 'учетная запись',
	Highlights: 'Особенности',
	'First 4': 'Первые 4',
	'UserAccountNavList__loss-limits': 'Установить ограничения на потери',
	FullyRefunded: 'Полностью возвращено',
	MakeDeposit: 'Сделать депозит',
	PasswordResetMessage__Oops: 'К сожалению! Я вспомнил.',
	withdraw: 'изымать',
	Odds: 'шансы',
	NextTournament: 'Следующий турнир',
	'Non Wet': 'Немоть',
	'Not enough selections!': 'Недостаточно выбора!',
	AccountTransactionsCellRacing__GoatMargin: '* GOAT Длина поля {{margin}} добавлена ​​к второму помещенному бегуну',
	SetTimer__Check: 'Вы можете изменить чек, если хотите',
	RacingDateSelection__Futures: 'фьючерсы',
	HowToPlayMessage__QuoteTitle: 'Генеральный директор TopBetta',
	Tournaments: 'Турниры',
	SelfExclusionPrompt__PuntingClub: 'Вы будете удалены из всех активных КлубовPunting.',
	SelfExclusionConfirmation__InsertComment: 'Пожалуйста, вставьте свой комментарий ...',
	standard: 'стандарт',
	BankAccountForm__AccountNumber: 'Номер счета',
	GetStartedBanner__StepGame: '«Входите в игру»',
	Selection: 'выбор',
	To: 'к',
	Sire: 'сир',
	Racing: 'гоночный',
	Sex: 'секс',
	EnterCreditCardDetails: 'Введите данные кредитной карты',
	withdrawal: 'вывод',
	Long: 'Долго',
	Rebuy: 'Докупать',
	AccountSummary__LockScreen: 'Экран блокировки',
	Shared: 'Общий',
	Firm: 'Фирма',
	'Track Distance': 'Расстояние следа',
	INTERNATIONAL: 'МЕЖДУНАРОДНЫЙ',
	Fixed: 'Исправлена',
	Sport: 'спорт',
	AddToBetSlip: 'Добавить в корзину',
	FirstDeposit__DepositingCustom: 'Вы вносите таможенную сумму',
	SetLimit__24HourDay: '24-часовой рабочий день',
	StartDate: 'Дата начала',
	Get: 'Получить',
	EmailAddress: 'Адрес электронной почты',
	'v/s': 'в / с',
	'Re-buys': 'Ребаи',
	'Play with': 'Играть с',
	PasswordResetRequestSuccess__Inbox: 'Пожалуйста, проверьте свой почтовый ящик',
	HowToPlayMessage__Point1: 'Начните с покупки турнирного билета.',
	PINAuthenticationContainer__RepeatMessage: 'Повторите PIN-код для подтверждения настройки',
	Profile__AddressInformation: 'информация об адресе',
	BetPromptContainer__ExactOrder: 'Точный заказ',
	Add: 'добавлять',
	Profile__FirstName: 'Имя',
	Place: 'Место',
	Forgot: 'Забыл',
	Footer__Rights: 'Все права защищены.',
	ChooseCreditCard: 'Выберите кредитную карту',
	'n/a': 'н /',
	TournamentsEntered: 'Введенные турниры',
	PlayerBetsHomePage__Title: 'Производительность игрока и ставки на спорт онлайн',
	Last: 'Прошлой',
	ViewTournament: 'Просмотр турнира',
	lose: 'потерять',
	Monthly: 'ежемесячно',
	TournamentDetails: 'Информация о турнире',
	NextTournamentContainer__NoTournamentsMessage: 'Нет доступных турниров',
	Thoroughbred: 'Thoroughbred',
	LoggedUserElements__AddFunds: 'Добавить фонды',
	'Each Way': 'Каждый путь',
	Break: 'Перерыв',
	'Racing__Status--abandoned': 'заброшенный',
	Username: 'имя пользователя',
	Play: 'Играть',
	Free: 'Свободно',
	AdditionalAddressFields__TownCity: 'Город / Город',
	SelfExclusionConfirmation__GamblingProblem: 'Проблемы с азартными играми',
	Close: 'близко',
	SetTimer__Updated: 'Ваша проверка реальности обновлена.',
	WeWillSendYou: 'Мы отправим вам',
	OrderBy: 'Сортировать по',
	Month_plural: 'Месяцы',
	Receipt__DepositMethod: 'Метод депозита',
	Day_plural: 'дней',
	TrendingTournaments__NoTournamentsMessage: 'Нет трендовых турниров.',
	RegistrationPage__BettingLimitError:
		'К сожалению! Извините, но при настройке лимита ставок что-то пошло не так. Пожалуйста, попробуйте позже.',
	LockedScreen__SignIn: 'Войдите как другую учетную запись',
	RegistrationPage__DepositLimitError:
		'К сожалению! Мы сожалеем, но что-топошло не так, когда вы устанавливаете лимит депозита. Пожалуйста, попробуйте позже.',
	'UserAccountNavList__deposit-limits--mobile': 'Установить лимиты депозита',
	HowToPlayTournaments__Heading: 'Как играть в турнир',
	Balance: 'Остаток средств',
	Colour: 'Цвет',
	'NO RETURN': 'БЕЗ ВОЗВРАТА',
	Comment: 'Комментарий',
	Immediately: 'немедленно',
	EnterTournament: 'Введите турнир',
	Selections: 'Выбор',
	'Last 7 Days': 'Последние 7 дней',
	MyRebuys: 'Мои повторные покупки',
	'UserAccountNavList__account-transactions': 'Сделки саккаунтом',
	SportsHomePage__NoBettingMessage: 'Ставки в настоящее время отключены на этом мероприятии',
	Day: 'День',
	Competitions: 'Соревнования',
	AccountSummaryStatistics__TotalStakes: 'Всего ставок',
	Ended: 'Закончилось',
	QuickBettingSummaryContainer__Title: 'Краткая сводка ставок',
	SingleBetSlip: 'Одиночная ставка',
	BetPlacementContainer__SportOddsChange: 'Изменены коэффициенты- {{last}} до {{current}}',
	PoliForm__Title: 'Депозит с {{poli}}',
	All: 'Все',
	Added: 'добавленной',
	AccountTransactionsCellMulti__Leg: '{{length}} Нога Multi',
	UpdatePassword__NewPassword: 'Ваш новый пароль',
	standout: 'выделяться',
	Box: 'коробка',
	Age: 'Возраст',
	PrizeTable: 'Призовой стол',
	Mobile: 'мобильный',
	including: 'в том числе',
	race: 'гонка',
	'SpeedmapLegend__Value--Off-pace': 'Off-темп',
	Top: 'верхний',
	DailyTournamentSchedule: 'Ежедневный график турниров',
	AccountBlockedTemporarily: 'Временно заблокирован аккаунт',
	'UserAccountNavList__withdrawals--mobile': 'Отменить снятие',
	Menu: 'Меню',
	'Racing__Status--deleted': 'удаленный',
	Short: 'короткий',
	races: 'гонки',
	tournaments: 'турниры',
	BankDepositInformation: 'Информация о банковском депозите',
	RaceSelectionsList__Flucs: 'Flucs',
	BankAccountForm__BankBSB: 'Банк BSB',
	Exotic: 'экзотичный',
	PrivacyPolicy: 'политика конфиденциальности',
	Withdrawal: 'Вывод',
	AvailableCash: 'Доступные наличные',
	BonusBet: 'Бонусная ставка',
	AccountNumber: 'Номер счета',
	Winning: 'выигрыш',
	Bet: 'но',
	MobileVerificationForm__UndoNumber: 'Номер отмены',
	SetTimer__Alert: 'Мы предупреждаем вас, когдавы достигнете своей проверки реальности автоматически.',
	RaceCard__EarlySpeed: 'Ранняя скорость',
	SimpleRegistration__SignUpNow: 'Зарегистрироваться сейчас',
	SportsHomePage__NoMarketsMessage: 'Нет доступных событий с открытыми рынками.',
	'No.': 'Нет.',
	'Available until': 'Доступно до',
	AwesomeThanks: 'Удивительно, спасибо!',
	Adds: 'Добавляет',
	RaceCard__Speedmaps: 'Speedmaps',
	BetSlip: 'КУПОН',
	daily: 'ежедневно',
	AcronymRace: 'р',
	Receipt: 'Чек',
	UpdatePassword__ConfirmPassword: 'Подтвердите новый пароль',
	Player_plural: 'игроки',
	Next: 'следующий',
	HideMarkets: 'Скрыть рынки',
	RebuyLimit: 'Re-Buy Limit',
	'UserAccountNavList__bonus-credit-transactions': 'Бонусные транзакции',
	VerifyAmount: 'Проверить сумму',
	DOB: 'дата рождения',
	SelfExclusionPrompt__Marketing: 'Вы перестанете получать маркетинговые сообщения в течение 24 часов.',
	MoreInfo__One:
		'Ежедневный лимит {{typeOfLimitLowerCase}}означает, что вы не сможете {{limitTypeText}} превышать эту сумму за 24-часовой период.',
	HowToPlayMessage__Point2: 'Затем поместите свои ставки в турнирах!',
	SelfExclusionConfirmation__DontLike: 'Мне не нравится сайт',
	Bank: 'Банка',
	Delete: 'удалять',
	TrendingTournaments__ViewMore: 'Посмотреть больше трендовых турниров',
	AcronymTrainer: 'T',
	Hide: 'Спрятать',
	Transactions: 'операции',
	None: 'Никто',
	SetLimit__7Day: '7-дневный период',
	Tickets: 'Билеты',
	ReBet: 'веревка',
	AdditionalAddressFields__PostcodeConstraint: '^ Требуется минимум 4 номера',
	Recent: 'последний',
	Popular: 'Популярный',
	GetStartedBanner__NotTooLate: 'Еще не поздно!',
	limit: 'предел',
	BackToTop: 'Вернуться к началу',
	Winner: 'победитель',
	MobileVerificationForm__Step3of3: 'Шаг 3 из 3',
	BankAccountForm__AccountName: 'Имя пользователя',
	'UserAccountNavList__responsible-gambling': 'Ответственная азартная игра',
	LockedScreenContainer__Error: 'Ошибка входа. Проверьте свой пароль.',
	Amount: 'Количество',
	Dam: 'дамба',
	MarketGroupings__NoMarketsMessage: 'Нет открытых рынков',
	'Buy In': 'Купить в',
	'UserAccountNavList__self-exclusion--mobile': 'Самоотключение (постоянное)',
	EndDate: 'Дата окончания',
	UserAccountNavList__withdrawals: 'Отменить снятие',
	Login__Chat: 'Не удается получить доступ к учетной записи - поговорите с нами',
	Exotics: 'экзотика',
	Week: 'Неделю',
	'Win/Place': 'Win / Place',
	Closing: 'закрытие',
	StartPlaying: 'Начать играть',
	Expiry: 'истечение',
	Confirm: 'подтвердить',
	'First Four': 'Первая четверка',
	'DerivativeSelectionList__Label--HVH': 'Половина против половины',
	EntryCost: 'Стоимость входа',
	TournamentRebuy: 'Турнир Повторная покупка',
	Send: 'послать',
	TakeABreak__TakeBreak: 'Возьмите {{breakLabel}} Перерыв',
	RegistrationConfirmation__Heading: 'Сидите крепко, мы создаем вашу учетную запись!',
	Bet_plural: 'Пари',
	BankEFT: 'Банк EFT',
	'Say something': 'Скажите что-то',
	'e/w': 'e / w',
	AcronymDriver: 'D',
	BetLimit: 'Предел ставки',
	Other: 'Другой',
	BetPlacementContainer__ChargeEntryButton: 'Купить билет &',
	loss: 'потеря',
	Join: 'Присоединиться',
	win: 'выиграть',
	WithdrawableBalance: 'Снятый баланс',
	PlayWith: 'Играть с',
	Soft: 'Мягкий',
	ID: 'Я БЫ',
	Promotions: 'промо акции',
	spend: 'проводить',
	to: 'в',
	PartiallyRefunded: 'Частично возвращено',
	MoreInfo__Five:
		'Вы также можете продолжать делать ставки с любыми денежными выигрышами, которые вы получаете в этот день, если ваш общий выигрыш {{typeOfLimitLowerCase}} {{limitTypeFactor}} остается под вашим лимитом.',
	Quinella: 'Quinella',
	FilterBy: 'Сортировать по',
	SelfExclusionConfirmation__Others: 'другие',
	'Type something...': 'Введите что-либо...',
	Hidden: 'скрытый',
	'Sorry, you need to login to continue!': 'Извините, вам нужно войти в систему, чтобы продолжить!',
	UpdatePassword__Step: 'Шаг {{step}}.',
	WithdrawalReceipt: 'Получение списания',
	Heavy: 'тяжелый',
	PlayTournament: 'Игровой турнир',
	Harness: 'Упряжь',
	Runner_plural: 'Бегуны',
	BackToBetting: 'Вернуться к оглавлению',
	Profile__Address: 'Адрес',
	TicketHeader__QualifiedMessage: 'Вы квалифицированы.',
	Live: 'Жить',
	RaceCard__Form: 'форма',
	Good: 'Хорошо',
	LOSS: 'потеря',
	AccountSummary__AccountNumber: 'Acc No',
	accepted: 'принято',
	Approved: 'Одобренный',
	PrizeFormat: 'Формат приза',
	fixed: 'фиксированный',
	GREYHOUNDS: 'борзыми',
	Won: 'Победил',
	Date: 'Дата',
	PotentialWinnings: 'Потенциальные выигрыши',
	MoreInfo__Four:
		'С полуночи до полуночи (AEST) вам будет разрешено делать ставки и вводить денежные турниры до вашего предела {{typeOfLimitLowerCase}}.',
	Pending: 'в ожидании',
	CashTournament: 'Наличный турнир',
	TakeABreak__1Day: '1 день',
	Paid: 'оплаченный',
	DepositAmount: 'Сумма вклада',
	BetPromptContainer__BetReceiptTitle: 'Получение чека',
	UpdatePassword__ConfirmationError: 'Должен соответствовать новый пароль',
	SidebarSettings__NTP: 'Показать рядом с игрой',
	LoginContainer__ForgotPassword: 'Забыли пароль?',
	'Bet Amount': 'Сумма ставки',
	Greyhounds: 'Собачьи',
	account: 'учетная запись',
	'Last Month': 'Прошлый месяц',
	PlayerProfileSummary__BettingPerformance: 'Эффективность ставок',
	with: 'с',
	Locked: 'запертый',
	Spend: 'Проводить',
	Promotions__CurrentPromotions: 'Текущие акции',
	PlayerProfile: 'Профиль игрока',
	AcronymKilogram: 'кг',
	Losing: 'проигрыш',
	Trifecta: 'Trifecta',
	AcronymJockey: 'J',
	Login: 'Авторизоваться',
	minus: 'минус',
	Singles: 'одиночный разряд',
	PhoneNumber: 'Номер телефона',
	MoreInfo: 'Больше информации',
	events: 'События',
	Yesterday: 'Вчера',
	JoinLoginElements__GetStarted: 'Начать',
	NeedHelp: 'Нужна помощь?',
	UnitStake__ViewSelections: 'Просмотреть выбор',
	BUMPED: 'столкнулся',
	PlayerProfileSummary__AverageBet: 'Avg Bet',
	ClaimFreeTicket: 'Заявка на бесплатный билет',
	'Not Verified': 'Не подтверждено',
	Apply: 'Применять',
	'FREE Tournament': 'Бесплатный турнир',
	Available: 'Имеется в наличии',
	Ref: 'ссылка',
	'UserAccountNavList__loss-limits--mobile': 'Установить ограничения на потери',
	'To Beat the Field': 'Убить поле',
	Cancel: 'отменить',
	Results: 'Результаты',
	'UserAccountNavList__withdraw-funds': 'Снять Средства',
	SledgeBox: 'Сани Box',
	Exacta: 'Exacta',
	'Terms&Conditions': 'Условия и положения',
	Win: 'Выиграть',
	GotItThanks: 'Понял, спасибо!',
	Logo: 'логотип',
	DepositSuccessful: 'Успешный депозит',
	BetPlacementContainer__WinOdds: 'Выиграть коэффициенты',
	MobileAuthenticationForm__SMSVerification:
		'Мы будем использовать ваш номер мобильного телефона для проверки SMS. Действуют стандартные тарифы обмена сообщениями.',
	HowToPlayMessage__Quote: '«Удачи и хорошей помады»',
	MobileVerificationForm__VerificationCode: 'Код подтверждения',
	Month: 'Месяц',
	PasswordResetMessage__New: 'Новенький тут?',
	TakeABreak__Long: 'Длительный перерыв',
	Week_plural: 'Недели',
	'UserAccountNavList__deposit--mobile': 'Сделать депозит',
	Boxed: 'в штучной упаковке',
	RegistrationConfirmation__Step3: '3 из 3: Почти сделано',
	Current: 'Текущий',
	'DerivativeSelectionList__Label--OVE': 'Коэффициенты против Эвенса',
	PINAuthenticationContainer__ForgotPin: 'Забыли PIN-код?',
	Profile__LastName: 'Фамилия',
	'out of': 'снаружи',
	LoginContainer__LoginOrJoin: 'Вход или Регистрация',
	ModalFooter__Message: 'Это безопасная зашифрованная форма - вы вбезопасности.',
	MeetingList__NextRace: 'Следующая гонка',
	International: 'Международный',
	PINAuthenticationContainer__Password: 'Использовать пароль для входа в систему',
	PrizePool: 'Призовой фонд',
	UpdatePassword__OldPassword: 'Введи свой текущий пароль',
	GetStartedBanner__GetStarted: 'Начать',
	Synthetic: 'синтетика',
	NextStep: 'Следующий шаг',
	'1st Up': '1-й вверх',
	RacingHomePage__Title: 'Ставки на лошадях, коэффициенты и результаты',
	at: 'в',
	'2nd Up': '2-й вверх',
	FirstDeposit__Spend: 'Сколько вы хотите потратить?',
	HowToPlayTournaments__SubHeading3: 'Проверить лидера',
	PlayerProfileSummary__WinRatio: 'Коэффициент выигрыша',
	SimpleRegistration__HaveAccount: 'У вас уже есть учетная запись?',
	"RacingHomePage__Yesterday's": 'вчерашний',
	UpdatePassword__PasswordUpdate: 'Ваш пароль был обновлен.',
	Sports: 'Спортивный',
	AvailableBonusBet: 'Доступная ставка бонуса',
	'UserAccountNavList__spend-limits': 'Установить лимиты расхода',
	AccountSummaryStatistics__Welcome: 'Добро пожаловать',
	SecurityCode: 'Код безопасности',
	Active: 'активный',
	'My Tournaments': 'Мои турниры',
	PromoCode: 'Промо код',
	boxed: 'в штучной упаковке',
	'UserAccountNavList__withdraw-funds--mobile': 'Снять Средства',
	Description: 'Описание',
	THOROUGHBRED: 'скачет',
	FixedOdds: 'Фиксированные коэффициенты',
	Buy: 'купить',
	Show: 'Шоу',
	removed: 'удален',
	MoreInfo__Seven: 'Если вы хотите полностью заблокировать свой доступ к сайту, вы можете запросить самоисключение.',
	MobileVerificationForm__Verified: 'Приятно познакомиться, {{first_name}}!',
	PasswordResetRequestSuccess__Instructions:
		'Мы отправили инструкции по сбросу пароля на указанный вами адрес электронной почты.',
	SelfExclusions__Others: 'другие',
	Winner_plural: 'Победители',
	LIVE: 'ЖИТЬ',
	MultiBetContainer__KeepBets: 'Держать ставки',
	Currency: 'валюта',
	ContinueBetting: 'Продолжить ставки',
	AdditionalAddressFields__AutoComplete: 'Вернуться к адресу автозаполнения',
	Profile__Birthday: 'день рождения',
	PlayersRebuys: 'Игроки повторно покупают',
	'Racing__Status--paid': 'оплаченный',
	Runner: 'полоз',
	'UserAccountNavList__reality-check': 'Проверка на практике',
	'Racing__Status--closed': 'закрыто',
	CreditCardNumber: 'Номер кредитной карты',
	Abandoned: 'заброшенный',
	FooterNote__One:
		'Получая доступ, продолжая использовать или перемещаясь по всему сайту, вы соглашаетесь с тем, что мы будем использовать определенные\nкуки-файлы браузера, чтобы улучшить опыт, который вы получаете с нами. {{brandName}} не используют файлы cookie, которые\nпрепятствуют вашей конфиденциальности, но только те, которые улучшат ваш опыт при использовании нашего сайта, пожалуйста\nобратитесь к нашей Политике конфиденциальности за дополнительной информацией о нашем использовании файлов cookie и о том, как вы предотвращаете их использование.\nты хочешь.',
	Refunded: 'Возвращено',
	DepositFromBankAccount: 'Депозит с банковского счета',
	MultiBetContainer__ExceedMessage: 'Сумма ставки превысила баланс вашего счета.',
	HowToPlayTournaments__Point3:
		'Вы должны спекулировать, чтобы накапливать и возглавлять LeaderBoard, беря наличные или билет на еще больший турнир!',
	Flexi: 'Flexi',
	DepositReceipt: 'Получение депозита',
	BetPlacementContainer__RacingOddsChange: 'изменено - {{last}} до {{current}}',
	SelfExclusions__Reason: 'Проблемы с азартными играми',
	AccountTransactionsCellRebet__MatchStarts: 'Матч начинается с:',
	Season: 'Время года',
	WithdrawalMethod: 'Метод вывода',
	SimpleRegistration__YourDetails: 'Ваши данные',
	MobileAuthenticationForm__IsValid:
		'Спасибо, это выглядит великолепно. Нажмите «Отправить SMS-код», чтобы продолжить.',
	MarketGroupItem__NumberOfMarkets: 'Доступен {{count}} рынок',
	Welcome: 'желанный',
	CustomAmount: 'Пользовательская сумма',
	TournamentsHomePage__NoTournamentsMessage:
		'К сожалению, мы не можем найти какие-либо турниры с этими критериями поиска.',
	SetLimit__InputLabel: 'Новый {{periodUpperCase}} {{limitType}} Предельная сумма',
	SetLimit__AdjustLimitMessage: 'Настройте свой {{period}} {{limitTypeLowerCase}} предел ниже.',
	SelfExclusionPrompt__Confirmation:
		'На этот адрес электронной почты будет отправлено подтверждение о постоянном самоисключении.',
	BankAccountForm__WhatBankName: 'Как называется банк?',
	UserAccountPage__SetLossLimits: 'Установить ограничения на потери',
	UserDetailsForm__DateOfBirth: 'Дата рождения',
	UserAddressForm__FirstName: 'Имя',
	UserAccountPage__CancelWithdrawal: 'Отменить снятие',
	MoreInfo__Six:
		'Вы можете снизить свой {{typeOfLimitLowerCase}} лимит в любое время, но повышение его вступит в силу только через 7 дней.',
	UserAccountPage__SetSpendLimits: 'Установить лимиты расхода',
	MobileVerificationForm__VerifiedOne:
		'Все хорошо - у вас есть действительный номер мобильного телефона и все-таки человек.',
	BonusBet_plural: 'Бонусные ставки',
	Status: 'Статус',
	AcronymRefunded: 'р',
	SetLimit__Support: 'Команда поддержки',
	HowToPlayTournaments__SubHeading2: 'Делайте ваши ставки',
	TakeABreakConfirmation__ChangedMind: 'Изменило мое решение',
	Total: 'Всего',
	HowDoesThisWork: 'Как это работает?',
	Login__UsernameOrEmail: 'Имя пользователя или адрес электронной почты',
	Limit: 'предел',
	Type: 'Тип',
	Change: '+ Изменить',
	Single: 'не замужем',
	Profile__State: 'государственный',
	'Prize Pool': 'Призовой фонд',
	Scratching: 'Царапины',
	No: 'нет',
	UpdatePassword__EnterCurrent: 'Введите текущий пароль',
	UserAccountPage__MakeDeposit: 'Сделать депозит',
	UserAccountPage__DepositReceipt: 'Получение депозита',
	VerifyCardExplanation__Done: 'Понял, спасибо!',
	BettaBucksAmount__PlayWith: 'Играть с',
	UnitStake__BetReceipt: 'Получение чека',
	UserAddressForm__Surname: 'Фамилия',
	UserAccountPage__ManageAccount: 'Управлять счетом',
	UserDetailsForm__18Plus: '18+',
	UserAccountMenu__ManageAccount: 'Управлять учетной записью',
	UnitStake__PendingReview: 'Ожидает оценки',
	EntriesClose: 'Записи Закрыть',
	SidebarSettings__Save: 'Сохранить параметры',
	RaceCard__RaceOverview: 'Обзор гонки',
	VerifyCard: 'Проверить карту',
	'Ticket Purchase': 'Покупка билетов',
	TournamentInsights__Winner: 'Да уж! Вы победитель!',
	PasswordResetMessage__CreateAccount: 'Создайте свою учетную запись {{brandName}}.',
	MakeAWithdrawal: 'Сделать вывод',
	UserAddressForm__DepositLimit: 'Лимит депозита ($)',
	SetLimit__30Day: '30-дневный период',
	BetPlacementContainer__FreeEntryButton: 'Требование Билет &',
	WinPotential: 'Потенциально выиграть',
	RegistrationHeader__CreateAccount: 'Создать учетную запись для полного доступа',
	BillerCode: 'Код биллинга',
	Promotions__BetNow: 'Сделать ставку сейчас',
	VerificationStatus__Title: 'Статус проверки',
	UserTransactionsContainer__OpenBets: 'Открытые ставки',
	UpdatePassword__SetNew: 'Установить новый пароль',
	AccountSummaryStatistics__PotentialWinnings: 'Потенциальные выигрыши',
	WithdrawalReceipt__RequestSuccessHeader: 'Успех запроса на вывод средств',
	SportsHomePage__NoBetsMessage: 'У вас нет активных ставок.',
	UserAddressForm__PlayersNotice: 'Примечание игроков',
	HowToPlayMessage__Heading: 'Как играть в турнир?',
	TimerHelpSection__SessionTime: 'Время сеанса',
	MarketGroupItem__NumberOfMarkets_plural: 'Доступные рынки {{count}}',
	MobileFooter__PuntersClub: 'Условия участия в Punting Club',
	UserTransactionsContainer__FilterLosing: 'Потеря ставок',
	UserTransactionsContainer__FilterWinning: 'Победившие ставки',
	UserAccountPage__TakeABreak: 'Возьмите перерыв',
	VerifyCardExplanation__GiveAuthorisation: 'давать разрешение',
	UpdatePassword__GenericError: 'Произошла ошибка. Обратитесь в службу поддержки.',
	WithdrawalPromptContainer__WantTo: 'Я хочу снять',
	TrendingBets__Heading: 'Тендерные ставки',
	WIN: 'ВЫИГРАТЬ',
	WithdrawalPromptContainer__ProcessedIn: 'Все изъятия обрабатываются в',
	UpdatePassword__ShouldMatch: 'Пароли не совпадают',
	UserTransactionsContainer__UnableToLoadTransactions: 'Невозможно загрузить транзакции с аккаунтом',
	WithdrawalPromptContainer__Amount: 'Сумма выкупа',
	Ticket: 'Билет',
	Race: 'раса',
	SetTimer__SupportTeam: 'Команда поддержки',
	AccountSummary__MyAccount: 'Мой аккаунт',
	SelfExclusionConfirmation__ChangedMind: 'Измениломое решение',
	HARNESS: 'УПРЯЖЬ',
	SpeedmapHeader__Lead: 'вести',
	Weekly: 'еженедельно',
	TournamentSummary__PrizePoolSubText: 'Играть за славу, чтобы быть # 1',
	Owners: 'Владельцы',
	RaceSelectionsList__PlaceBet: 'Сделать ставку',
	RegistrationPage__AdultsOnly: 'Только для взрослых',
	SimpleRegistration__SignIn: 'Войти в систему',
	RaceProductLegend__PleaseNote: 'Пожалуйста, обратите внимание',
	pending: 'в ожидании',
	Step: 'шаг',
	SpeedmapHeader__Barrier: 'барьерный',
	Profile__MobileNumber: 'Мобильный номер',
	togo: 'идти',
	SimpleRegistration__Header: 'Форма регистрации',
	UserTransactionsContainer__UnableToLoadDashboard: 'Не удалось загрузить панель учетных записей',
	Wet: 'Мокрый',
	monthly: 'ежемесячно',
	UserAddressForm__WeeklyLimits: 'Ограничение на недельные лимиты',
	Existing: 'Существующий',
	Dividend: 'дивиденд',
	TournamentsHomePage__Title: 'Турниры',
	UserAddressForm__AddressMessage: 'Добавьте адрес, чтобы можно было заплатить',
	WithdrawalPromptContainer__PendingNotFound: 'Мы не смогли найти ожидающих снятия средств.',
	UserAddressForm__LossLimit: 'Лимит потерь ($)',
	Profile__Suburb: 'предместья',
	Retry: 'Retry',
	UserDetailsForm__Username: 'Имя пользователя (6 символов мин)',
	Entries: 'Записи',
	UserDetailsForm__StartAccount: 'Запустите свою учетную запись {{brandName}}',
	Profile__Postcode: 'почтовый индекс',
	TournamentInsights__Loser: 'Извините, вы пропустили!',
	WithdrawalPromptContainer__RemoveBankAccountMessage:
		'Вы собираетесь удалить этот банковский счет из своей учетной записи.',
	UserAddressForm__ControlLimits: 'Контролировать свои депозиты и лимиты ставок?',
	YouQualifyAlert__Deposit: '* Но не для ставок на 1-й депозит',
	PerEvent: 'За событие',
	SetLimit__UpdateLimit: 'Предел обновления',
	BetPromptContainer__AnyOrder: 'Любой заказ',
	SelfExclusionPrompt__StandingBalance:
		'Постоянный баланс будет автоматически снят до последнего активного метода извлечения, когда будут получены ожидающие ставки.',
	TournamentBets__NoBets: 'У вас нет ставок на это событие.',
	'UserAccountNavList__account-transactions--mobile': 'Выписка по счету',
	UserAddressForm__SetLimits:
		'Установите лимиты для сумм, которые вы хотите поставить или депозит, чтобы оставаться под контролем вашей учетной записи. Вы можете изменить их в настройках своейучетной записи после регистрации.',
	'UserAccountNavList__take-a-break--mobile': 'Сделайте перерыв',
	TournamentEntry: 'Вступление в турнир',
	RunnerForm__RunnerComments: 'Комментарии Runner',
	"RacingHomePage__Tomorrow's": 'Завтра',
	MultiBetContainer__BetSlip: 'Ставки',
	'UserAccountNavList__take-a-break': 'Сделайте перерыв',
	SetLimit__MoreInfo: 'Больше информации.',
	Profile__Title: 'Персональные данные',
	'UserAccountNavList__self-exclusion': 'Самоотключение (постоянное)',
	GetStartedBanner__StepDeposit: 'Сделать депозит',
	length: 'длина',
	Note: 'Заметка',
	PINAuthentication__Error: 'PIN-коды не совпадают.',
	SearchTournaments: 'Поиск турниров',
	BSB: 'BSB',
	Sledge: 'салазки',
	SportsHomePage__LiveBetsHeading: 'Ставки на спорт Live',
	Promotions__NoArticles: 'Нет статей для показа.',
	'SpeedmapLegend__Value--Leader': 'лидер',
	SelfExclusionPrompt__Exclude: 'Исключить Себя сейчас',
	NextToJumpRaceDetails__View: 'Посмотреть полную гоночную карту',
	PasswordResetRequestSuccess__Success: 'Успех: отправлено электронное письмо',
	SetTimer__Hour: '{{count}} час',
	BetPlacementContainer__AddToMulti: 'Добавить в Мульти',
	TournamentBets__OtherUnresulted: 'Другие нерешенные',
	'DerivativeSelectionList__Label--FVF': 'Любимый против поля',
	SetLimit__LowerLimit:
		'Вы можете снизить лимит {{limitTypeLowerCase}} в любое время, но его повышение вступит в силу только через 7 дней.',
	VerifyCardExplanation__MustBeVerified:
		'Ваша кредитная карта должна быть проверена до того, как любые запросы на отзыв могут быть обработаны.',
	FirstDeposit__MakeADeposit: 'Сделать депозит',
	TryAgain: 'Попробуйте снова',
	Night: 'Ночь',
	BankAccountForm__BankName: 'Название банка',
	'UserAccountNavList__deposit-limits': 'Установить лимиты депозита',
	Masthead__KeepGoing: 'Потрясающие. Продолжайте идти',
	MobileVerificationForm__VerifiedTwo: 'Что происходит дальше?',
	RacingHomePage__Racing: 'гоночный',
	TakeABreak__Short: 'Короткий перерыв',
	RacingHomePage__Races: 'Гонки',
	'SpeedmapLegend__Value--Midfield': 'полузащитник',
	MobileAuthenticationForm__SMSVerificationCode:
		'Сначала введите номерсвоего мобильного телефона, чтобы получить код подтверждения SMS.',
	MeetingListContainer__NoAURaces: 'Нет доступных гонок в формате AU / NZ',
	RegistrationConfirmation__Step2: '2 из 3: Инициализация вашей учетной записи',
	TakeABreakLong__1YearValue: '1 годперерыва',
	MobileVerificationForm__VerifyMobile: 'Проверьте свой номер мобильного телефона с помощью кода подтверждения.',
	RaceSelectionsList__MarginMessage: 'GOAT Длина поля {{goatLength}} добавлена ​​к второму помещенному бегуну',
	AccountSummaryStatistics__Happy: 'Рад снова видеть вас',
	HowToPlayTournaments__Point1: 'Вы выбираете, какой турнир вы хотели бы сыграть, это зависит от вас',
	RacingHomePage__Description: 'Ставка на скачки, борзые и гоночные гонки сейчас онлайн',
	SetTimer__Hour_plural: '{{count}} hours',
	TournamentBets__OnThisEvent: 'Об этом событии',
	UserAccountNavList__deposit: 'Депозитные фонды',
	JoinLoginElements__LoginRegister: 'Вход / Регистрация',
	LoginForPromotions__LoginForPromotions: 'Вход для продвижения по службе',
	BankAccountForm__BankStateBranch: 'Отделение банка',
	TakeABreakConfirmation__Title: 'Возьмите {{breakLabel}} Перерыв',
	'DerivativeSelectionList__Label--H2H': 'Одновременно',
	Details: 'подробности',
	towin: 'побеждать',
	Withdraw: 'Изымать',
	TermsAndConditions: 'Условия и положения',
	place: 'место',
	deposit: 'депозит',
	Withdrawals: 'Изъятия',
	'UserAccountNavList__user-home--mobile': 'Мой профайл',
	JoinLoginElements__Username: 'Имя пользователя или адрес электронной почты',
	'Racing__Status--paying': 'платеж',
	YesPlease: 'Да, пожалуйста',
	Logout: 'Выйти',
	HowToPlayMessage__QuoteName: 'Тодд',
	PlayerBetsHomePage__Description: 'Просмотр и ставка на производительность плеера',
	NextToJumpTournamentDetails__EnterTournament: 'Введите турнир',
	BankAccountForm__AccountHolderName: 'имя владельца счета',
	MultiBetContainer__StruckWarning: 'Ваша ставка будет отправлена, если вы продолжите.',
	BetReceipt__Continue: 'Продолжить ставки',
	Today: 'сегодня',
	RacingHomePage__Next: 'следующий',
	Support: 'Служба поддержки',
	Now: 'Сейчас',
	winning: 'выигрыш',
	MM_YY: 'MM / YY',
	Without: 'Без',
	termsAndConditions: 'условия и положения',
	Verify: 'проверить',
	Profile__FormDisabledMessage:
		'Если вы хотите изменить любую вашу личную информацию, обратитесь в нашу службу поддержки клиентов, и они могут помочь с этим изменением в ваших данных.',
	SidebarContainer__MenuOptions: 'Параметры меню',
	Fee: 'плата',
	Year_plural: 'лет',
	RacingHomePage__DescriptionBonus: 'Бонусная ставка для новых игроков.',
	MobileVerificationForm__Verify: 'Давайте проверим, что вы настоящий человек',
	'UserAccountNavList__reality-check--mobile': 'Проверка на практике',
	MobileAuthenticationForm__VerifyRealPerson: 'Давайте проверим, что вы настоящий человек',
	weekly: 'еженедельно',
	RaceSelectionsList__GoatLength: 'GOAT + Длина',
	MultiBetContainer__EmptyTwo: 'Здесь вы можете добавить ставки.',
	RebuyIntoTournament: 'Повторная покупка в турнире',
	Promotions__PromotionUnavailable: 'Запрошенное предложение не доступно.',
	PINAuthenticationContainer__Message: 'PIN-код - это быстрый способ входа в систему с использованием 4-х цифр',
	RaceCard__OnSettling: 'О поселении',
	MobileVerificationForm__DontForget: 'Не забывайте',
	'UserAccountNavList__bonus-credit-transactions--mobile': 'Бонусное заявление',
	SetLimit__RemoveLimit: 'Удалить предел',
	PlayerProfileBets__BetsPlaced: 'Ставки',
	RaceSelectionsList__PoolTotals: 'Итоговые суммы пула',
	MobileVerificationForm__CreateAccount: 'Создать учетную запись',
	MoreInfo__Done: 'Понял, спасибо!',
	Year: 'Год',
	Tournament: 'Турнир',
	'UserAccountNavList__reset-password--mobile': 'Сброс пароля',
	'UserAccountNavList__user-home': 'Мой профайл',
	'UserAccountNavList__spend-limits--mobile': 'Установить лимиты расхода',
	AccountSummaryStatistics__PendingBets: 'Ожидаемые ставки',
	TicketPurchase__MaxExceededMessage: 'Вы превысили максимальное число',
	'DerivativeSelectionList__Label--IO': 'Внутри и снаружи',
	'UserAccountNavList__responsible-gambling--mobile': 'Ответственная азартная игра',
	MyTournaments: 'Мои турниры',
	'Racing__Status--interim': 'промежуточный',
	'UserAccountNavList__default-bet-amounts--mobile': 'Сумма ставки по умолчанию',
	FirstDeposit__PreferAnother: 'Я предпочитаю вариант 1-го депозита',
	TrendingTournament: 'Трендовые турниры',
	MyTournaments__NotEntered: 'Выне ввели ни одного турнира',
	'SpeedmapLegend__Value--Backmarker': 'Назад Marker',
	BetPendingReview__Title: 'Ожидающий просмотр ставок',
	HowToPlayTournaments__SubHeading1: 'Выберите турнир',
	SwapOddsButton__Text: 'Обменный курс',
	MobileAuthenticationForm__SendSMS: 'Отправить SMS-код',
	MultiBetSummary__Title: 'Сводка ставок',
	BetPlacementContainer__PlaceOdds: 'Разместить коэффициенты',
	ErrorPage404__Title: 'Страница не найдена',
	MobileVerificationForm__CodeError: 'Код не является числом',
	MultiBetSelectionList__AllSingles: 'Добавить ко всем синглам',
	RaceCard__ShowMyBets: 'Показать мои ставки',
	DefaultBetAmounts__BetAmount: 'Сумма ставки',
	Masthead__LoginError: 'Заполните необходимые поля.',
	PromoAlert__Title: 'PROMO ALERT',
	DefaultBetAmounts__NotAccepted: 'Не принято',
	DefaultBetAmounts__NotSet: 'Не установлен',
	BetSelectionSingle__BUMPED: 'столкнулся',
	TakeABreakLong__1YearLabel: '1 год',
	DefaultBetAmounts__Accepted: 'Принято',
	CreateAccount__Button: 'Создать новый аккаунт',
	Bets: 'Пари',
	Deposit: 'депозит',
	Player: 'игрок',
	Deposits: 'депозиты',
	Multi: 'много',
	Login__ForgotPassword: 'Забыли пароль?',
	MastheadContainer__PasswordError: 'Мы не можем найти пользователя с этим адресом электронной почты.',
	FirstDeposit__BillingStatements: "Заявления о выставлении счетов будут отражать '{{brandName}}'",
	BetPlacementContainer__ChargeEntryMessage: 'С вас будет взиматься {{cost}} вход в турнир.',
	PasswordResetContainer__Error: 'Мы не можем найти пользователя с этим адресом электронной почты.',
	BetPlacementContainer__FreeEntryMessage: 'Вам не будет взиматься плата за вход в турнир.',
	TournamentInsights__BackedWinner: 'поддержал победителя.',
	MobileVerificationForm__VerifyMobileButton: 'Подтвердить мобильный',
	NextToJumpCard__Upcoming: 'Нет предстоящих',
	CancelWithdrawalConfirmation__CanBe: 'могут быть отменены.',
	TournamentInsights__Title: 'Статистика турниров',
	ExternalDepositResult__FailMessage: 'Депозит не прошел! Попробуйте снова.',
	ErrorBoundaryContainer__OpenIntercom: 'Открыть Интерком',
	DefaultBetAmounts__Update: 'Обновить значения по умолчанию',
	DerivativeSelectionList__Heading: 'Специальные гоночные рынки',
	DefaultBetAmounts__NewDefault: 'Новая ставка ставки по умолчанию',
	FirstDeposit__Depositing: 'Вы депонируете',
	DefaultBetAmounts__TournamentBetAmount: 'Сумма ставки в турнире',
	DepositForm__Deposit: 'Я хочу внести депозит',
	Yes: 'да',
	BonusBetsList__Accepted: 'Принято',
	Distance: 'Расстояние',
	MultiBetContainer__EmptyOne: 'Ваша ставка не пуста.',
	BetReceipt__RejectReason: 'Отклонить причину',
	RegistrationConfirmation__Step1: '1 из 3: Создание вашей учетной записи',
	BetSelectionSingle__MarginMessage: 'Добавить {{margin}} L к второму',
	"RacingHomePage__Today's": 'Сегодняшние',
	ContactUsPanel__Title: 'Свяжитесь с нами для помощи',
	MobileVerificationForm__VerifiedThree: 'Сделайте депозит и начните делать ставки.',
	DefaultBetAmounts__TournamentLive: 'Предупреждение о ставке в турнире',
	ErrorPage404__Late: 'Был поздний',
	CompetitionsList__BetCount_plural: '{{count}} ставки',
	'Racing__Status--selling': 'продажа',
	BPayDeposit__InfoTitle: '{{BPay}} Информация о депозите',
	YouQualifyAlert__Qualify: 'Вы претендуете на продвижение',
	Profile__Country: 'Страна',
	DepositReceipt__SuccessMessage: 'Мы зачислили остаток на ваш счет.',
	'UserAccountNavList__reset-password': 'Сброс пароля',
	TrendingBets__NoBetsMessage: 'Нет доступных ставок трендов',
	CancelWithdrawalConfirmation__FundsWillAppear: 'Фонды появятся в вашем',
	DepositContainer__RemoveCard: 'Вы собираетесь удалить кредитную карту, оканчивающуюся на',
	MultiBetContainer__InvalidSelections:
		'У вас есть варианты, которые являются недопустимыми и не могут быть объединены в несколько ставок. Вы все еще можете делать одиночные ставки.',
	MoreInfo__Two:
		'Еженедельный предел {{typeOfLimitLowerCase}} означает, что вы не сможете {{limitTypeText}} превышать сумму в 7-дневный период.',
	DepositConfirmation__FirstSuccessMessage: '1-й депозит прошел успешно!',
	Prize: 'приз',
	Stake: 'доля',
	FirstDeposit__Skip: 'Скидка на бонус на первый депозит',
	DepositConfirmation__CreditAccount: 'Мы будем кредитовать вашу учетную запись',
	DefaultBetAmounts__Error: 'Ваши суммы по умолчанию были обновлены.',
	DepositConfirmation__NoBonusApplied: 'Бонус за депозит не применяется!',
	TakeABreakLong__6MonthsLabel: '6 месяцев',
	DepositContainer__AddBalance: 'Мы добавим к вашему балансу',
	MoreInfo__Three:
		'Ежемесячный предел {{typeOfLimitLowerCase}} означает, что вы не сможете {{limitTypeText}} превышать это значение втечение 30-дневного периода.',
	CancelWithdrawalConfirmation__UnableToProcess: 'Мы не можем обработать Ваш запрос.',
	BPayDeposit__Title: 'Депозит с {{BPay}}',
	BetReceipt__RefundedSingle: 'Одна из вашихставок была полностью возмещена',
	ErrorBoundaryContainer__Message: 'К сожалению. Что-то пошло не так. Обновите и повторите попытку.',
	FirstDeposit__AmountError: 'Сумма должна быть меньше или равна 100',
	BetReceipt__Rejected: 'Ваша ставка была отклонена',
	ExternalDepositResult__SuccessMessage: 'Депозит успешно! Мы скоро зачислим баланс вашего счета.',
	CountdownPanel__Message: 'Заявите ставку бонуса до истечения времени!',
	DepositForm__ExceededVerification:
		'Вы превысили попытки проверки. Мы свяжемся с вами напрямую, чтобы подтвердить вашу кредитную карту.',
	ErrorBoundaryContainer__RemountDefault: 'Мы не смогли обновиться. Вы хотите открыть чат поддержки?',
	VerifyLater: 'Проверить позже',
	MyTournaments__LoginMessage: 'Чтобы просмотреть турниры, вы должны войти в систему.',
	CreateAccount__NewTo: 'Новое в {{brandName}}?',
	CancelWithdrawalConfirmation__TryAgain:
		'Повторите попытку или обратитесь в службу поддержки клиентов за дополнительной помощью.',
	DepositConfirmation__CreditBalance: 'Мы зачислили ваш баланс {{bonus}}.',
	DefaultBetAmounts__TournamentMessage: 'При размещении ставки на реальные деньги в турнире я подтверждаю:',
	ErrorBoundaryContainer__MessageBetPrompt: 'К сожалению. Что-топошло не так. Обновите betslip и повторите попытку.',
	AuthoriseVerification__CreditCard: 'Я даю разрешение проверить мою кредитную карту.',
	FirstDeposit__Glad: 'Мы рады, что вы здесь, {{first_name}}',
	CreditCardVerification__EnterVerification:
		'Введите сумму контрольных центов, которую мы добавили в ваш депозит подтверждения. Это можно найти в выписке по кредитной карте.',
	ErrorPage404__SubMessage: 'В любом случае, вот несколько вариантов, которые помогут вам в вашем путешествии.',
	ErrorBoundaryDefault__Message:
		'Что-то пошлоне так, и мы не смогли загрузить эту страницу. Вы хотите попробовать еще раз?',
	MoreMarkets: 'Больше рынков',
	DepositForm__Minimum: '$ 10 Мин. Депозит',
	SetTimer__Update: 'Обновить проверку реальности',
	PossiblePayout: 'Возможная выплата',
	SidebarSettings__NTJ: 'Показать рядом с прыжком',
	SidebarSettings__NT: 'Показать следующий турнир',
	RaceProductLegend__AllExotics: 'Все экзотики',
	UserTransactionsContainer__FilterBets: 'Ставки',
	UserTransactionsContainer__FilterAll: 'Все транзакции',
	RaceCard__HideMyBets: 'Скрыть мои ставки',
	CompetitionsList__BetCount: '{{count}} ставка',
	TicketHeader__RemainingTurnoverMessage: 'оставшийся оборот для квалификации.',
	CancelWithdrawalConfirmation__ThisRequest: 'Этот запрос на вывод',
	SelfExclusionPrompt__Proceed: 'Что произойдет, если я продолжу?',
	BetReceipt__Refunded: 'Ваша ставка полностью возмещена',
	BetReceipt__RejectedSingle: 'Одна из ваших ставок была отклонена',
	SelfExclusionPrompt__Account: 'Вы не сможете войти в свою учетную запись или создать новую учетную запись.',
	BetSummaryBetList__NoBets: 'Нет ставок для показа.',
	MultiBetContainer__Balance: 'мяч',
	UserAccountPage__SetDepositLimits: 'Установить лимиты депозита',
	UpdatePassword__EnterEmail: 'Введите адрес электронной почты',
	UserAddressForm__GoBack: 'Вернитесь',
	UnitStake__ViewDetails: 'Просмотреть информацию о ставках',
	SetLimit__PendingLimitMessage: 'Ограниченный предел {{limitTypeLowerCase}}:',
	CancelWithdrawalConfirmation__Success: 'был успешно отменен.',
	DepositForm__VerifiedMessage: 'Эта кредитная карта теперь полностью подтверждена - спасибо!',
	UserAddressForm__Address: 'Адрес',
	UserDetailsForm__HaveAnAccount: 'Иметь аккаунт?',
	CreditCardVerification__VerificationError:
		'К сожалению! Неверная проверка введенной суммы. Повторите попытку: {{verifyAttemptsLeft}} / 3 попытки остались.',
	UpdatePassword__EnterNew: 'Введите новый пароль',
	UserAccountPage__ResetPassword: 'Сброс пароля',
	VerificationStatus__Complete: 'Полная проверка',
	WithdrawalPromptContainer__Add: 'Добавить новую учетную запись',
	WithdrawalPromptContainer__MaxBalance: 'Максимальный баланс вывода',
	UserDetailsForm__AdultsOnly: 'ТОЛЬКО ДЛЯ ВЗРОСЛЫХ',
	BetSummaryBetItem__MarginMessage: '* Добавляет длину {{marginButt}} к второму помещенному бегуну',
	UserDetailsForm__Step1of3: 'Шаг 1 из 3',
	SetLimit__LoseAmount: 'Вы не сможете потерять сумму, большую, чем это, в {{changePeriod}}.',
	WithdrawalPromptContainer__Credit: 'Кредитный банковский счет',
	UserTransactionsContainer__FilterTournaments: 'Турнирные записи / Ставки',
	VerificationStatus__Why: 'Зачем мне проверять мою личность?',
	TournamentHeaderFooter__NoRanking: 'У вас нет рейтинга.',
	TournamentPrizePool__Title: 'Призовой фонд турнира',
	ErrorPage404__Message:
		'Скорее всего, вы наткнулись на эту страницу, набрав неправильную вещь, или, возможно, она только что была',
	TournamentBets__ViewMore: 'Больше ставок на турниры',
	BonusBetsList__BonusBetsMessage:
		'Бонусные ставки делаются с целью продвижения сайта и дают новым игрокам возможность развлекательно насладиться платформой.',
	CreditCardVerification__VerifyCreditCard:
		'Ваша кредитная карта должна быть проверена до подачи любого запроса на снятие средств.',
	UserDetailsForm__Password: 'Пароль (6 символов + 1 номер мин)',
	FooterNote__Two:
		'{{brandName}} управляется {{brandName}} PTY LTD, зарегистрированным в Австралии. {{brandName}} лицензируется в Северной Территории,\nАвстралия NT Racing Commission принимает ставки через Интернет по гоночным, спортивным и развлекательным программам.',
	MultiBetContainer__OddsChange: 'Коэффициенты изменились с {{oldPrice}} на {{newPrice}}',
	MoreInfo__Heading: 'Ежедневный, Еженедельный и Ежемесячный {{limitType}} Пределы',
	UserAddressForm__Step2of3: 'Шаг 2 из 3',
	UserAccountPage__SelfExclusion: 'Самоотключение',
	UserAddressForm__Outside: 'За пределами Австралии',
	UserAccountPage__VerifyCC: 'Проверка кредитных карт',
	UserTransactionsContainer__FilterRefunded: 'Возврат ставок',
	UserAccountPage__DefaultBetAmounts: 'Установить значения ставки по умолчанию',
	UserTransactionsContainer__Title: 'Учетная запись',
	BetSummaryTicketList__NoTickets: 'У вас нет билетов.',
	VerifyCardExplanation__VerifyingCreditCard: 'Проверка кредитной карты',
	UserAddressForm__EnterManually: 'Не можете найти его? Введите адрес вручную',
	TournamentsHomePage__Previous: 'Предыдущие турниры',
	BetPromptContainer__QuickDepositTitle: 'Быстрый депозит',
	UserAccountPage__AccountProfile: 'Профиль учетной записи',
	UserAddressForm__GambleResponsibly: 'ИГРОВАЯ ОТВЕТСТВЕННОСТЬ:',
	VerifyCardExplanation__VerificationProcess:
		'Вы {{authorisationMessage}} добавили случайное значение процента от 1-99 до этой суммы депозита для проведения процесса проверки.',
	VerifyCardExplanation__AreAuthorising: 'авторизуются {{brandName}}',
	VerifyCardExplanation__Questions: 'Если у вас есть какие-либо вопросы, обратитесь в нашу службу поддержки клиентов.',
	WithdrawalPromptContainer__VerifyMessage:
		'Все кредитные карты должны быть проверены до подачи заявки на вывод средств.',
	VerificationStatus__Tasks: 'Вы выполнили {{from}} из {{}} задач проверки.',
	WithdrawalReceipt__RequestSuccess: 'Мы получили ваш запрос и будем обрабатываться в рабочее время.',
	UserDetailsForm__Terms:
		'Создав новую учетную запись, я принимаю условия и положения, политику конфиденциальности и подтверждаю, что я старше {{age}} лет. Это преступление, чтобы играть, если несовершеннолетний.',
	BetReceipt__Trader: 'Трейдер Как',
	UserDetailsForm__Email: 'Электронная почта (вы авторизуетесь с этим)',
	VerifyCardExplanation__CentValue:
		'Чтобы проверить кредитную карту, вам необходимо получить значение цента из выписки по кредитной карте и войти, когда вы сделаете депозит.',
	VerifyCardExplanation__AccountVerified:
		'Это значение будет добавлено в вашу учетную запись после подтверждения карты.',
	UserAddressForm__FirstNameConstraint: '^ Имя должно быть не менее 2 символов.',
	UserAddressForm__SurnameConstraint: '^ Фамилия должна быть не менее2 символов.',
	UserTransactionsContainer__Last: 'Последний {{days}} d',
};
