import moment from 'moment';

/**
 * List of available locales can be found here:
 *  https://github.com/moment/moment/tree/develop/locale
 *
 * List of the ISO-639 2 letter country codes can be found here
 *  https://www.loc.gov/standards/iso639-2/php/English_list.php
 */
import 'moment/locale/en-au';
import 'moment/locale/pl';
import 'moment/locale/tr';
import 'moment/locale/ja';
import 'moment/locale/ru';

/**
 * 2.12.0 deprecated using moment.locale() to change an existing locale. Use moment.updateLocale() instead.
 * http://momentjs.com/docs/#/customization/relative-time/
 */
moment.updateLocale('en', {
	relativeTime: {
		future: '%s',
		past: '-%s',
		s: 'seconds',
		m: '1 min',
		mm: '%d mins',
		h: '1 hour',
		hh: '%d hours',
		d: '1 day',
		dd: '%d days',
		M: '1 month',
		MM: '%d months',
		y: '1 year',
		yy: '%d years',
	},
});
