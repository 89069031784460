import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, brand } from '@tbh/ui-kit';
import { DEFAULT_COLORS } from '../../../constants/themes';
import { connect } from 'react-redux';
import moment from 'moment';

// Actions
import { hideSidebar, routeTo } from '../../../store/application/applicationActions';
import {
	navigateToNextToJumpRaceWithType,
} from '../../../store/GRSracingHome/GRSracingHomeActions';
// Components
// import RacingLeftSidebarNavigation from '../../../components/features/Application/Sidebar/SidebarNavigation/RacingLeftSidebarNavigation';
import FooterNote from '../../../components/features/Application/Footer/FooterNote/FooterNote';

//selectors

// Constants
import {
	NEXT_TO_JUMP_RACING,
	NEXT_TO_JUMP_SPORTS,
	NEXT_TO_JUMP_TOURNAMENTS,
} from '../../../common/constants/SidebarConstants';
import { DEFAULT_BRAND_LOGOS } from '../../../common/constants/TopBetta';
import GRSSideBarnavigation from '../../../components/features/Application/Sidebar/GRSSidebarNavigation/GRSSidebarNavigation';
import { navigateToMeetingsWithTypeAndDate } from './../../../store/GRSracingHome/GRSracingHomeActions';

const StyledRacingLeftSidebarContainer = styled('div')(
	(props) => css`
		label: RacingLeftSidebarContainer;

		position: relative;
		z-index: 12;
		height: 100%;
		max-height:${props.curentRoute !== '/welcome' ? 'calc(100vh - 60px)' : 'calc(100vh - 120px)'} ;

		@media (min-width: 780px) {
			width:100%;
			min-width: 240px;
		}
	`,
	(props) =>
		!props.isMultiBetSlipOpen &&
		css`
			@media (min-width: 768px) and (max-width: 1279px) {
				width: 16.666667%;
				margin-top: -1px;
			}
		`,
);

const StyledRacingLeftSidebarContainer__Content = styled('div')(
	(props) => css`
		label: RacingLeftSidebarContainer__Content;
		height: 100%;
		display: flex;
		flex-direction: column;
		// overflow-y: hidden;
		background: ${brand(props).primary_color ? brand(props).primary_color : DEFAULT_COLORS.df_primary_color};
		position: relative;
    	width: 240px;
	`,
);

const StyledRacingLeftSidebarContainer__FooterNote = styled(FooterNote)(
	(props) => css`
		label: RacingLeftSidebarContainer__FooterNote;

		background-color: ${brand(props).color_2_dark};
		padding: ${spacings(props).compact}px;
	`,
);

const cssRacingLeftSidebarContainer__Content = css`
	label: RacingLeftSidebarContainer__Content;
	// background-color: #070B19;
	flex: auto;
`;

class RacingLeftSidebarContainer extends React.Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Array of ids that represents the bets included in the multi bet slip */
		multiBets: PropTypes.shape({
			racing: PropTypes.arrayOf(PropTypes.number),
			sports: PropTypes.arrayOf(PropTypes.number),
		}),

		/** Handles navigation to the page selected in the sidebar */
		navigateToPage: PropTypes.func,

		/** Navigates to the selected race page with date and type*/
		navigateToRaceWithType: PropTypes.func.isRequired,

		/** Navigates to the selected sport event page */
		navigateToSport: PropTypes.func,

		/** Navigates to the selected tournament page */
		navigateToTournament: PropTypes.func,

		/** Navigation items */
		navigationItems: PropTypes.array.isRequired,

		/** Add the selected race price to the multi bet slip */
		onAddRacingBetToMulti: PropTypes.func.isRequired,

		/** Add the sport selection to the multi bet slip */
		onAddSportBetToMulti: PropTypes.func.isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
		}),

		// Extra class(es) to pass through
		className: PropTypes.string,

		/** The business brand name */
		brandName: PropTypes.string.isRequired,

		gallopsMetingGroups: PropTypes.array.isRequired,

		harnessMetingGroups: PropTypes.array.isRequired,

		greyhoundsMeetingGroups: PropTypes.array.isRequired,

		/** If it's the cordova app or not */
		isCordova: PropTypes.bool,

		/** Next to jump racing items */
		nextToJumpRacingItems: PropTypes.array,

		/** Next to jump racing items */
		nextToJumpSportItems: PropTypes.array,

		/** Remove the bet selection from the multi bet slip */
		onRemoveMultiBetSelection: PropTypes.func.isRequired,

		/** Show the Next to Jump Card for racing, sport or tournament */
		showNextToJump: PropTypes.oneOf([NEXT_TO_JUMP_RACING, NEXT_TO_JUMP_SPORTS, NEXT_TO_JUMP_TOURNAMENTS]),

		currentRoute: PropTypes.string.isRequired,


		/** Undefined for today's races. Type*/

		selectedType: PropTypes.string,

		/** If the bet slip is open or not */
		isMultiBetSlipOpen: PropTypes.bool.isRequired,

		selectedDate: PropTypes.instanceOf(moment),

		selectedPageKey: PropTypes.string,

		//navigateToRSAccount: PropTypes.func,

		/** If the user is logged in or not */
		isUserLoggedIn: PropTypes.bool,
	};

	static defaultProps = {
		className: null,
		multiBets: {
			racing: [],
			sports: [],
		},
		nextToJumpRacingItems: [],
		nextToJumpSportItems: [],
		isCordova: false,
		showNextToJump: NEXT_TO_JUMP_RACING,
		navigateToPage: undefined,
		navigateToSport: undefined,
		navigateToTournament: undefined,
		size: undefined,
		selectedType: null,
		selectedDate: null,
		selectedPageKey: '',
		//navigateToRSAccount:undefined,
		isUserLoggedIn: false,
	};

	constructor(props) {
		super(props);

		this.state = {
			// loading mask for the Next to Jump on the settings view
			loadingNTJSettings: false,
			// loading mask for the Next to Jump on the navigation view
			loadingNTJNavigation: false,
		};
	}

	/**
	 * Return the NTJ items (racing, sports or tournaments) depending on the sidebar settings
	 */
	nextToJumpItems = () => {
		switch (this.props.showNextToJump) {
			case NEXT_TO_JUMP_RACING:
				return this.props.nextToJumpRacingItems;

			case NEXT_TO_JUMP_SPORTS:
				return this.props.nextToJumpSportItems;

			case NEXT_TO_JUMP_TOURNAMENTS:
				return this.props.nextToJumpTournamentItems;
		}
	};

	/**
	 * Handles clicking on a navigation item and navigating to the corresponding page
	 *
	 * @param item (object)
	 */
	handleItemSelect = (item) => {
		this.props.navigateToPage(item.route, item.title);
	};
	/**
	 * Add or remove the selected racing bet to the multi bet slip
	 *
	 * @param price
	 * @param race
	 */
	handleAddRaceToMulti = (price, race, selection_id) => {
		const selectionId = selection_id;
		const racingMultiBets = this.props.multiBets.racing;
		const multiHasSelection = racingMultiBets && racingMultiBets.includes(selectionId);
		// If the bet is already a multi bet, remove it from the multi bet selections
		if (multiHasSelection) {
			return this.props.onRemoveMultiBetSelection(selectionId);
		}
		return this.props.onAddRacingBetToMulti(race, price, selectionId);
	};

	/**
	 * Navigates to the selected race
	 *
	 * @param raceId
	 * @param meetingId
	 */
	handleGoToRace = (type, date, raceId, meetingId) => {
		raceId && meetingId
			? this.props.navigateToRaceWithType(type, date, raceId, meetingId)
			: this.props.navigateToMeetingWithType(date, type);
	};

	//TODO: improve route for each meeting
	render() {
		const {
			className,
			currentRoute,
			sideBarData,
			isMultiBetSlipOpen,
		} = this.props;

		const componentClasses = cx({
			[className]: className,
		});

		const header = document.getElementById('js_header-region');

		const headerHeight = header ? header.clientHeight : null;

		return (
			<StyledRacingLeftSidebarContainer isMultiBetSlipOpen={isMultiBetSlipOpen} curentRoute={currentRoute}>
				<StyledRacingLeftSidebarContainer__Content
					id="toggel_left_side_bar_width"
					className={componentClasses}
					headerHeight={headerHeight}
				>

					<GRSSideBarnavigation
						navigateToPage={this.props.navigateToPage}
						onGoToRace={this.handleGoToRace} sideBarData={sideBarData} />
				

				</StyledRacingLeftSidebarContainer__Content>
			</StyledRacingLeftSidebarContainer>
		);
	}
}

const mapStateToProps = (state) => {
	const authenticatedUser = state.application.authenticatedUser;

	//@HW 28Sept2020


	let today_race = state.sideBarData.today;
	today_race['link'] = '/racing-v3';

	let tomorrow = state.sideBarData.tomorrow;
	tomorrow['link'] = '#';

	let dayAtomorrow = state.sideBarData.dayAfterTomorrow;
	dayAtomorrow['link'] = '#';

	// previous results
	let previousResults = state.sideBarData.previousResults;
	previousResults['link'] = '#';

	let sideBarData = { today: today_race, tomorrow: tomorrow, dayAfterTomorrow: dayAtomorrow,
		 previousResults: previousResults
		};
	//side_bar_link
	return {
		showNextToJump: state.application.sidebar.nextToJumpSettings,
		updateNextToJumpData: state.application.sidebar.updateNextToJumpData,
		gamblingMessage: state.acl.gamblingMessages,
		brandName: state.acl.brandDetails ? state.acl.brandDetails.name : '',
		brandLogos: state.acl.brandLogos || DEFAULT_BRAND_LOGOS,
		isCordova: state.application.isCordova,
		showSidebar: state.application.showSidebar,
		sideBarData: sideBarData,
		currentRoute: state.application.currentRoute,
		selectedPageKey: state.application.selectedPage,
		isUserLoggedIn: Boolean(authenticatedUser),
	};
};

const mapDispatchToProps = (dispatch) => ({
	navigateToPage: (route, label) => {
		dispatch(routeTo(route));
	},
	navigateToRaceWithType: (type, date, raceId, meetingId) => {
		dispatch(navigateToNextToJumpRaceWithType(type, date, raceId, meetingId));
		App.startSubApp('RacingNewPage');
		dispatch(hideSidebar());
	},

	navigateToMeetingWithType: (date, type) => {
		dispatch(navigateToMeetingsWithTypeAndDate(date, type));
		App.startSubApp('RacingNewPage');
		dispatch(hideSidebar());
	},

	// navigateToRSAccount: () => {
	// 	dispatch(routeTo('/user/rns-account'));
	// },
});

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(RacingLeftSidebarContainer));
