import {
	RACING_BET_TYPE_PLACE,
	RACING_BET_TYPE_WIN,
	RACING_EXOTIC_BET_TYPE_ORDER_LOOKUP,
} from '../../../common/constants/Racing';

/**
 * Builds displayable racing results based of race results and available product
 *
 * @param results
 * @param products
 */
export const buildResults = (results, products) => {
	// If there are no results, do nothing
	if (!results || !products || products.length === 0) {
		return;
	}

	// Loop through each of the results get build up a collection
	const resultList = results.reduce((acc, result) => {
		// The product id must be either the win or place product id
		if (result.bet_type === RACING_BET_TYPE_WIN || result.bet_type === RACING_BET_TYPE_PLACE) {
			const productId = result.product_id;
			if (!acc[productId]) {
				acc[productId] = [];
			}

			// look for existing result with selection and product ids.
			let existingResult = acc[productId].find((existingResult) => existingResult.selection_id === result.selection_id);

			// If exists, merge new dividend, otherwise create new result to be displayed
			if (existingResult) {
				existingResult[result.bet_type + '_dividend'] = result.dividend;
			} else {
				acc[productId].push({
					name: result.name,
					number: result.number,
					position: result.position,
					product_id: result.product_id,
					selection_id: result.selection_id,
					[result.bet_type + '_dividend']: result.dividend,
				});
			}
		}

		return acc;
	}, {});

	Object.values(resultList).forEach((productResults) => {
		productResults.sort((a, b) => a.position - b.position);
	});

	return resultList;
};

/**
 * Builds displayable racing exotic results based of race results and available products
 *
 * @param {Array} exoticResults
 * @param {Array} products
 */
export const buildExoticResults = (exoticResults, products) => {
	// If no results, return empty
	if (!exoticResults || !products || products.length === 0) {
		return;
	}

	let exoticProductIds = products.reduce((acc, product) => {
		if (product.bet_type !== RACING_BET_TYPE_WIN && product.bet_type !== RACING_BET_TYPE_PLACE) {
			acc.push(+product.product_id);
		}
		return acc;
	}, []);
	
	// Loop through the exotic results, building up a collection
	const resultList = exoticResults.reduce((acc, result) => {
		// Make sure the id is one of the non win/place ids
		if (exoticProductIds.includes(result.product_id)) {
			// Build up data and add to collection. This is matching the old displayed exotic results structure

			const productId = result.product_id;
			if (!acc[productId]) {
				acc[productId] = [];
			}

			acc[productId].push({
				bet_type: result.bet_type,
				dividend: result.dividend,
				selections: result.selections,
				product_id: result.product_id,
			});
		}

		return acc;
	}, {});

	Object.values(resultList).forEach((productResults) => {
		productResults.sort(
			(a, b) => RACING_EXOTIC_BET_TYPE_ORDER_LOOKUP[a.bet_type] - RACING_EXOTIC_BET_TYPE_ORDER_LOOKUP[b.bet_type],
		);
	});

	return resultList;
};


export const buildRaceResults = (results, products) => {



};