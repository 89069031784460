import actCard from '../../images/verifications/act-card.png';
import southCard from '../../images/verifications/south-card.png';
import westCard from '../../images/verifications/west-card.png';
import tasmaniaCard from '../../images/verifications/tasmania-card.png';
import nswCard from '../../images/verifications/nsw-card.png';
import qldCard from '../../images/verifications/qld-card.png';

export const USER_ACCOUNT_V2_BASE_URL = 'user-v2';
export const USER_ACCOUNT_V2_SUB_APP = 'UserAccountV2';

export const USER_ACCOUNT_OVERVIEW_URL = 'overview';
export const USER_ACCOUNT_DEPOSIT_URL = 'deposit';
export const USER_ACCOUNT_DEPOSIT_LIMIT_URL = 'deposit-limits';
export const USER_ACCOUNT_SETTINGS_URL = 'settings';
export const USER_ACCOUNT_WITHDRAW_URL = 'withdraw';
export const USER_ACCOUNT_TRANSACTIONS_URL = 'transactions';
export const USER_ACCOUNT_BONUS_BET_TRANSACTIONS_URL = 'bonusbets';
export const USER_ACCOUNT_BETS_TRANSACTIONS_URL = 'my-bets';
export const USER_ACCOUNT_BONUS_BET_TRANSACTION_URL = 'bonus-bet';

// Bonus bets filters
export const USER_ACCOUNT_ALL_BONUS_BET_TRANSACTIONS_URL = 'all';
export const USER_ACCOUNT_BETS_BONUS_BET_TRANSACTIONS_URL = 'bets';
export const USER_ACCOUNT_LOSING_BET_BONUS_BET_TRANSACTIONS_URL = 'bet-losing';
export const USER_ACCOUNT_REFUNDED_BET_BONUS_BET_TRANSACTIONS_URL = 'bet-refunded';

export const USER_ACCOUNT_ALL_TRANSACTIONS_URL = 'all';
// export const USER_ACCOUNT_BETS_TRANSACTIONS_URL = 'bets';
export const USER_ACCOUNT_TOURNAMENTS_TRANSACTIONS_URL = 'tournaments';
export const USER_ACCOUNT_BET_WINNING_TRANSACTIONS_URL = 'bet-winning';
export const USER_ACCOUNT_BET_LOSING_TRANSACTIONS_URL = 'bet-losing';
export const USER_ACCOUNT_BET_REFUNDED_TRANSACTIONS_URL = 'bet-refunded';
export const USER_ACCOUNT_DEPOSITS_TRANSACTIONS_URL = 'deposits';
export const USER_ACCOUNT_WITHDRAWALS_TRANSACTIONS_URL = 'withdrawals';
// export const USER_ACCOUNT__DEFAULT_BET_AMOUNTS_URL = 'default-bet-amounts';
//  export const USER_ACCOUNT_TAKE_A_BREAK_URL = 'take-a-break';
//  export const USER_ACCOUNT_SELF_EXCLUSION_URL = 'self-exclusion';
// export const USER_ACCOUNT_LOSS_LIMITS_URL = 'loss-limits';
// export const USER_ACCOUNT_SPEND_LIMITS_URL = 'spend-limits';
// export const USER_ACCOUNT_REALITY_CHECK_URL = 'reality-check';
export const USER_ACCOUNT_RESPONSIBLE_GAMBLING_URL = 'responsible-gambling';
export const USER_ACCOUNT_TODAY_TRANSACTIONS_URL = 'today';
export const USER_ACCOUNT_OPEN_UNTIL_TODAY_TRANSACTIONS_URL = 'open';
export const USER_ACCOUNT_RNS_ACCOUNT_URL = 'rns-account';
export const USER_ACCOUNT_MENU_DEPOSIT = 'Deposit';
export const USER_ACCOUNT_MENU_DEPOSIT_LIMIT = 'Deposit Limit';
export const USER_ACCOUNT_MENU_SETTINGS = 'Settings';
export const USER_ACCOUNT_MENU_WITHDRAW = 'Withdraw';
export const USER_ACCOUNT_MENU_TRANSACTIONS = 'Transactions';
export const USER_ACCOUNT_MENU_BONUS_BET = 'Bonus Bet Transactions';
export const USER_ACCOUNT_MENU_BETS_TRANSACTIONS = 'My Bets';
export const USER_ACCOUNT_MENU_OVERVIEW = 'Overview';
export const USER_ACCOUNT_RESPONSIBLE_GAMBLING = 'Responsible Gambling';
// export const USER_ACCOUNT__DEFAULT_BET_AMOUNTS = 'Default Bet Amounts';
// export const USER_ACCOUNT_TAKE_A_BREAK = 'Take a break';
// export const USER_ACCOUNT_SELF_EXCLUSION = 'Self Exclusion';
// export const USER_ACCOUNT_LOSS_LIMITS = 'Loss Limits';
// export const USER_ACCOUNT_SPEND_LIMITS = 'Spend Limits';
// export const USER_ACCOUNT_REALITY_CHECK = 'Reality Check';

export const USER_ACCOUNT_MENU_OPTIONS = {
	[USER_ACCOUNT_MENU_OVERVIEW]: USER_ACCOUNT_OVERVIEW_URL,
	[USER_ACCOUNT_MENU_SETTINGS]: USER_ACCOUNT_SETTINGS_URL,
	[USER_ACCOUNT_MENU_BETS_TRANSACTIONS]: USER_ACCOUNT_BETS_TRANSACTIONS_URL,
	[USER_ACCOUNT_MENU_DEPOSIT]: USER_ACCOUNT_DEPOSIT_URL,
	[USER_ACCOUNT_MENU_DEPOSIT_LIMIT]: USER_ACCOUNT_DEPOSIT_LIMIT_URL,
	[USER_ACCOUNT_MENU_WITHDRAW]: USER_ACCOUNT_WITHDRAW_URL,
	[USER_ACCOUNT_MENU_TRANSACTIONS]: USER_ACCOUNT_TRANSACTIONS_URL,
	[USER_ACCOUNT_RESPONSIBLE_GAMBLING]: USER_ACCOUNT_RESPONSIBLE_GAMBLING_URL,
	// [USER_ACCOUNT__DEFAULT_BET_AMOUNTS]: USER_ACCOUNT__DEFAULT_BET_AMOUNTS_URL,
	// [USER_ACCOUNT_TAKE_A_BREAK]: USER_ACCOUNT_TAKE_A_BREAK_URL,
	// [USER_ACCOUNT_SELF_EXCLUSION]: USER_ACCOUNT_SELF_EXCLUSION_URL,
	// [USER_ACCOUNT_LOSS_LIMITS]: USER_ACCOUNT_LOSS_LIMITS_URL,
	// [USER_ACCOUNT_SPEND_LIMITS]: USER_ACCOUNT_SPEND_LIMITS_URL,
	// [USER_ACCOUNT_REALITY_CHECK]: USER_ACCOUNT_REALITY_CHECK_URL,
};

export const USER_ACCOUNT_VERIFICATION = 'verification';
export const USER_ACCOUNT_VERIFICATION_URL = 'verification';
export const USER_ACCOUNT_VERIFICATION_AUSTRALIAN_DRIVER_LICENCE = 'Australian Capital Territory Driver Licence';
export const USER_ACCOUNT_VERIFICATION_AUSTRALIAN_DRIVER_LICENCE_URL = 'au-driver-licence-dvs';
export const ID_TYPE_AUSTRALIAN_DRIVER_LICENCE = 'actregodvs';

export const USER_ACCOUNT_VERIFICATION_NSW_DRIVER_LICENCE = 'New South Wales Driver Licence';
export const USER_ACCOUNT_VERIFICATION_NSW_DRIVER_LICENCE_URL = 'nsw-driver-licence-dvs';
export const ID_TYPE_NSW_DRIVER_LICENCE = 'nswregodvs';

export const USER_ACCOUNT_VERIFICATION_QLD_DRIVER_LICENCE = 'Queensland Driver Licence';
export const USER_ACCOUNT_VERIFICATION_QLD_DRIVER_LICENCE_URL = 'qld-driver-licence-dvs';
export const ID_TYPE_QLD_DRIVER_LICENCE = 'qldregodvs';
// qldregodvs

export const USER_ACCOUNT_VERIFICATION_WA_DRIVER_LICENCE = 'Western Australia Driver Licence';
export const USER_ACCOUNT_VERIFICATION_WA_DRIVER_LICENCE_URL = 'wa-driver-licence-dvs';
export const ID_TYPE_WA_DRIVER_LICENCE = 'waregodvs';
// waregodvs

export const USER_ACCOUNT_VERIFICATION_TAS_DRIVER_LICENCE = 'Tasmania Driver Licence';
export const USER_ACCOUNT_VERIFICATION_TAS_DRIVER_LICENCE_URL = 'tas-driver-licence-dvs';
export const ID_TYPE_TAS_DRIVER_LICENCE = 'tasregodvs';
// tasregodvs

export const USER_ACCOUNT_VERIFICATION_SA_DRIVER_LICENCE = 'South Australia Driver Licence';
export const USER_ACCOUNT_VERIFICATION_SA_DRIVER_LICENCE_URL = 'sa-driver-licence-dvs';
export const ID_TYPE_SA_DRIVER_LICENCE = 'saregodvs';
// saregodvs

export const USER_ACCOUNT_VERIFICATION_NT_DRIVER_LICENCE = 'Northern Territory Driver Licence';
export const USER_ACCOUNT_VERIFICATION_NT_DRIVER_LICENCE_URL = 'nt-driver-licence-dvs';
export const ID_TYPE_NT_DRIVER_LICENCE = 'ntregodvs';
// ntregodvs

export const USER_ACCOUNT_VERIFICATION_VIC_DRIVER_LICENCE = 'Victoria Driver Licence';
export const USER_ACCOUNT_VERIFICATION_VIC_DRIVER_LICENCE_URL = 'vic-driver-licence-dvs';
export const ID_TYPE_VIC_DRIVER_LICENCE = 'vicregodvs';
// vicregodvs


export const USER_ACCOUNT_VERIFICATION_AUSTRALIAN_PASSPORT = 'Australian Passport';
export const USER_ACCOUNT_VERIFICATION_AUSTRALIAN_PASSPORT_URL = 'australian-passport';
export const ID_TYPE_AUSTRALIAN_PASSPORT = 'passportdvs';

export const USER_ACCOUNT_VERIFICATION_AUSTRALIAN_DVS_VISA = 'Australian Visa';
export const USER_ACCOUNT_VERIFICATION_AUSTRALIAN_DVS_VISA_URL = 'australian-visa-dvs';
export const ID_TYPE_AUSTRALIAN_DVS_VISA = 'visadvs';
// visadvs

export const USER_ACCOUNT_VERIFICATION_AUSTRALIAN_CENTRELINK = 'Australian Centrelink';
export const USER_ACCOUNT_VERIFICATION_AUSTRALIAN_CENTRELINK_URL = 'australian-centrelink-dvs';
export const ID_TYPE_AUSTRALIAN_CENTRELINK = 'centrelinkdvs';
// centrelinkdvs

export const USER_ACCOUNT_VERIFICATION_IMMICARD_DVS = 'Australian Immicard';
export const USER_ACCOUNT_VERIFICATION_IMMICARD_DVS_URL = 'australian-immicard-dvs';
export const ID_TYPE_IMMICARD_DVS = 'immicarddvs';
// immicarddvs

export const USER_ACCOUNT_VERIFICATION_MEDICARE_CARD_DVS = 'Medicare Card';
export const USER_ACCOUNT_VERIFICATION_MEDICARE_CARD_URL = 'medicare-card-dvs';
export const ID_TYPE_MEDICARE_CARD = 'medicaredvs';

export const USER_ACCOUNT_VERIFICATION_BIRTH_CERTIFICATE_DVS = 'Birth Certificate';
export const USER_ACCOUNT_VERIFICATION_BIRTH_CERTIFICATE_DVS_URL = 'birth-certificate dvs';
export const ID_TYPE_BIRTH_CERTIFICATE = 'birthcertificatedvs';
// birthcertificatedvs

export const CREDIT_CARD_CONST = 'Credit/Debit Card';
export const POLI_CONST = 'POLI';
export const PAYPAL_CONST = 'PayPal';

export const USER_ACCOUNT_VERIFICATION_MENU_OPTIONS = {
	[USER_ACCOUNT_VERIFICATION_AUSTRALIAN_DRIVER_LICENCE]: {
		url: USER_ACCOUNT_VERIFICATION_AUSTRALIAN_DRIVER_LICENCE_URL,
		idType: ID_TYPE_AUSTRALIAN_DRIVER_LICENCE,
		type: 'license',
		country: 'au',
		state: 'act',
		fields: [
			{
				name: 'number',
				title: 'Drivers Licence Number',
			},
			{
				name: 'card_number',
				title: 'Card Number',
			},
		],
		img: actCard,
	},
	[USER_ACCOUNT_VERIFICATION_NSW_DRIVER_LICENCE]: {
		url: USER_ACCOUNT_VERIFICATION_NSW_DRIVER_LICENCE_URL,
		idType: ID_TYPE_NSW_DRIVER_LICENCE,
		type: 'license',
		country: 'au',
		state: 'nsw',
		fields: [
			{
				name: 'number',
				title: 'Drivers Licence Number',
			},
			{
				name: 'card_number',
				title: 'Card Number',
			},
		],
		img: nswCard,
	},
	[USER_ACCOUNT_VERIFICATION_QLD_DRIVER_LICENCE]: {
		url: USER_ACCOUNT_VERIFICATION_QLD_DRIVER_LICENCE_URL,
		idType: ID_TYPE_QLD_DRIVER_LICENCE,
		type: 'license',
		country: 'au',
		state: 'qld',
		fields: [
			{
				name: 'number',
				title: 'Drivers Licence Number',
			},
			{
				name: 'card_number',
				title: 'Card Number',
			},
		],
		img: qldCard,
	},
	[USER_ACCOUNT_VERIFICATION_WA_DRIVER_LICENCE]: {
		url: USER_ACCOUNT_VERIFICATION_WA_DRIVER_LICENCE_URL,
		idType: ID_TYPE_WA_DRIVER_LICENCE,
		type: 'license',
		country: 'au',
		state: 'wa',
		fields: [
			{
				name: 'number',
				title: 'Drivers Licence Number',
			},
			{
				name: 'card_number',
				title: 'Card Number',
			},
		],
		img: westCard,
	},
	[USER_ACCOUNT_VERIFICATION_TAS_DRIVER_LICENCE]: {
		url: USER_ACCOUNT_VERIFICATION_TAS_DRIVER_LICENCE_URL,
		idType: ID_TYPE_TAS_DRIVER_LICENCE,
		type: 'license',
		country: 'au',
		state: 'tas',
		fields: [
			{
				name: 'number',
				title: 'Drivers Licence Number',
			},
			{
				name: 'card_number',
				title: 'Card Number',
			},
		],
		img: tasmaniaCard,
	},
	[USER_ACCOUNT_VERIFICATION_SA_DRIVER_LICENCE]: {
		url: USER_ACCOUNT_VERIFICATION_SA_DRIVER_LICENCE_URL,
		idType: ID_TYPE_SA_DRIVER_LICENCE,
		type: 'license',
		country: 'au',
		state: 'sa',
		fields: [
			{
				name: 'number',
				title: 'Drivers Licence Number',
			},
			{
				name: 'card_number',
				title: 'Card Number',
			},
		],
		img: southCard,
	},
	[USER_ACCOUNT_VERIFICATION_VIC_DRIVER_LICENCE]: {
		url: USER_ACCOUNT_VERIFICATION_SA_DRIVER_LICENCE_URL,
		idType: ID_TYPE_SA_DRIVER_LICENCE,
		type: 'license',
		country: 'au',
		state: 'vic',
		fields: [
			{
				name: 'number',
				title: 'Drivers Licence Number',
			},
			{
				name: 'card_number',
				title: 'Card Number',
			},
		],
		img: southCard,
	},
	[USER_ACCOUNT_VERIFICATION_NT_DRIVER_LICENCE]: {
		url: USER_ACCOUNT_VERIFICATION_SA_DRIVER_LICENCE_URL,
		idType: ID_TYPE_SA_DRIVER_LICENCE,
		type: 'license',
		country: 'au',
		state: 'nt',
		fields: [
			{
				name: 'number',
				title: 'Drivers Licence Number',
			},
			{
				name: 'card_number',
				title: 'Card Number',
			},
		],
		img: southCard,
	},
	[USER_ACCOUNT_VERIFICATION_AUSTRALIAN_PASSPORT]: {
		url: USER_ACCOUNT_VERIFICATION_AUSTRALIAN_PASSPORT_URL,
		idType: ID_TYPE_AUSTRALIAN_PASSPORT,
		type: 'passport',
		country: 'au',
		fields: [
			{
				name: 'number',
				title: 'Number',
			},
		],
	},
	[USER_ACCOUNT_VERIFICATION_AUSTRALIAN_DVS_VISA]: {
		url: USER_ACCOUNT_VERIFICATION_AUSTRALIAN_DVS_VISA_URL,
		idType: ID_TYPE_AUSTRALIAN_DVS_VISA,
		type: 'fgpassport',
		fields: [
			{
				name: 'number',
				title: 'Number',
			},
			{
				name: 'country',
				title: 'Country',
			},
		],
	},
	[USER_ACCOUNT_VERIFICATION_AUSTRALIAN_CENTRELINK]: {
		url: USER_ACCOUNT_VERIFICATION_AUSTRALIAN_CENTRELINK_URL,
		idType: ID_TYPE_AUSTRALIAN_CENTRELINK,
		type: 'centrelink',
		country: 'au',
		fields: [
			{
				name: 'number',
				title: 'Number',
			},
			{
				name: 'card_type',
				title: 'Card Type',
			},
		],
	},
	[USER_ACCOUNT_VERIFICATION_IMMICARD_DVS]: {
		url: USER_ACCOUNT_VERIFICATION_IMMICARD_DVS_URL,
		idType: ID_TYPE_IMMICARD_DVS,
		type: 'immicard',
		country: 'au',
		fields: [
			{
				name: 'number',
				title: 'Number',
			},
		],
	},
	[USER_ACCOUNT_VERIFICATION_MEDICARE_CARD_DVS]: {
		url: USER_ACCOUNT_VERIFICATION_MEDICARE_CARD_URL,
		idType: ID_TYPE_MEDICARE_CARD,
		type: 'medicare',
		country: 'au',
		fields: [
			{
				name: 'number',
				title: 'Number',
			},
			{
				name: 'refrence_number',
				title: 'Refrence Number',
			},
			{
				name: 'card_color',
				title: 'Card Color',
			},
			{
				name: 'expiry_date',
				title: 'Expiry Date',
			},
		],
	},
	[USER_ACCOUNT_VERIFICATION_BIRTH_CERTIFICATE_DVS]: {
		url: USER_ACCOUNT_VERIFICATION_BIRTH_CERTIFICATE_DVS_URL,
		idType: ID_TYPE_BIRTH_CERTIFICATE,
		type: 'birthcertificate',
		country: 'au',
		fields: [
			{
				name: 'number',
				title: 'Number',
			},
			{
				name: 'card_number',
				title: 'Card Number',
			},
			{
				name: 'state',
				title: 'Registration State',
			},
			{
				name: 'reg_date',
				title: 'Registration Date',
			},
			{
				name: 'acquisition_date',
				title: 'Acquisition Date',
			},
			{
				name: 'printed_date',
				title: 'Printed Date',
			},
		],
	},
};

export const AUSTRALIAN_STATES = [
	{ name: 'New South Wales', value: 'NSW' },
	{ name: 'Victoria', value: 'VIC' },
	{ name: 'Queensland', value: 'QLD' },
	{ name: 'South Australia', value: 'SA' },
	{ name: 'Western Australia', value: 'WA' },
	{ name: 'Tasmania', value: 'TAS' },
	{ name: 'Northern Territory', value: 'NT' },
	{ name: 'Australian Capital Territory', value: 'ACT' },
];

export const NS_STATES = [
	{ name: 'Northland', value: 'NTL' },
	{ name: 'Auckland', value: 'AKL' },
	{ name: 'Waikato', value: 'WKO' },
	{ name: 'Bay of Plenty', value: 'BOP' },
	{ name: 'Gisborne', value: 'GIS' },
	{ name: "Hawke's Bay", value: 'HKB' },
	{ name: 'Taranaki', value: 'TKI' },
	{ name: 'Manawatu-Wanganui', value: 'MWT' },
	{ name: 'Wellington', value: 'WGN' },
	{ name: 'Tasman', value: 'TAS' },
	{ name: 'Nelson', value: 'NSN' },
	{ name: 'Marlborough', value: 'MBH' },
	{ name: 'West Coast', value: 'WTC' },
	{ name: 'Canterbury', value: 'CAN' },
	{ name: 'Otago', value: 'OTA' },
	{ name: 'Southland', value: 'STL' },
];

export const AUSTRALIAN_CENTRELINK_CARD_TYPES = [
	{ name: 'Pensioner Concession Card', value: 'PCC' },
	{ name: 'Health Care Card', value: 'HCC' },
	{ name: 'Seniors Health Card', value: 'SHC' },
];

export const AUSTRALIAN_MEDICARE_CARD_COLORS = [
	{ name: 'Blue', value: 'B' },
	{ name: 'Green', value: 'G' },
	{ name: 'Yellow', value: 'Y' },
];
