import {createSelector} from 'reselect';
import {schema} from 'normalizr';

/**
 * Build a schema tree of a given entity schema. You can pass through a Entity or Array Schema
 *
 * @param entitySchema
 * @param keys
 * @param assessed
 * @returns {Array}
 */
export const getSchemaRelations = (entitySchema, keys = [], assessed = []) => {

	if (entitySchema instanceof schema.Array) {
		return getSchemaRelations(entitySchema.schema, keys, assessed);
	} else if (entitySchema instanceof Array && entitySchema.length > 0) {
		return getSchemaRelations(entitySchema[0], keys, assessed);
	}

	if (assessed.indexOf(entitySchema) !== -1) {
		return keys;
	}

	if (entitySchema.schema) {

		if (entitySchema._key && keys.indexOf(entitySchema._key) === -1) {
			keys.push(entitySchema._key);
			assessed.push(entitySchema);
		}

		Object.keys(entitySchema.schema).forEach((schemaKey) => {
			keys = getSchemaRelations(entitySchema.schema[schemaKey], keys, assessed);
		});
	}
	return keys;
};

/**
 * Build a memoized function that extracts keys from an entities slice
 *
 * @param schema
 * @returns {*}
 */
export const buildEntitySchema = (schema) => {
	let keys = getSchemaRelations(schema);
	let selectorFunctions = keys.reduce((acc, key) => {
		acc.push(function (entities) {
			return entities[key];
		});
		return acc;
	}, []);
	return createSelector(selectorFunctions, (...args) => {
		let entities = {};
		keys.forEach((key, index) => {
			entities[key] = args[index];
		});
		return entities;
	});
};