import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { SketchPicker } from 'react-color';
import { spacings, ui, Circle, Input, PlotElements, Popover, Text } from '@tbh/ui-kit';

const StyledThemeItem__Property = styled(Text)(
	(props) => css`
		label: ThemeItem__Property;

		margin-right: ${spacings(props).compact}px;
	`,
);

const StyledThemeItem__Color = styled(Circle)(
	(props) => css`
		label: ThemeItem__Color;

		display: inline-flex;
		background-color: ${props.propertyValue};
		border: 1px solid ${ui(props).color_black};
	`,
);

const StyledThemeItem__Popover = styled(Popover)(
	(props) => css`
		label: ThemeItem__Popover;

		top: ${spacings(props).cozy}px;
		right: ${spacings(props).cozy}px;

		${Popover.StyledPopover__Arrow} {
			display: none;
		}
	`,
);

class ThemeItem extends Component {
	static propTypes = {
		/** Extra classes */
		className: PropTypes.string,

		/** Handler for changing the color in the Color Picker */
		onThemeChange: PropTypes.func,

		/** Property name in the Theme group */
		propertyName: PropTypes.string,

		/** Property value in the Theme group */
		propertyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

		/** Name of the property sub item */
		subItem: PropTypes.string,

		/** Name of the theme group */
		themeGroupName: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		onThemeChange: null,
		propertyName: '',
		propertyValue: '',
		subItem: null,
		themeGroupName: '',
	};

	state = {
		showThemePicker: false,
	};

	/**
	 * Opens/Closes the theme picker popover
	 */
	toggleThemePicker = () => {
		this.setState({
			showThemePicker: !this.state.showThemePicker,
		});
	};

	/**
	 * Handler for changing a property color
	 *
	 * @param color
	 */
	handleColorChange = (color) => {
		const { themeGroupName, propertyName, subItem } = this.props;

		this.props.onThemeChange({
			value: color.hex,
			propertyName: propertyName,
			themeGroupName: themeGroupName,
			subItem: subItem,
		});
	};

	/**
	 * Handler for changing a property value
	 *
	 * @param name
	 * @param value
	 */
	handleInputChange = (name, value) => {
		const { themeGroupName, propertyName, propertyValue, subItem } = this.props;

		this.props.onThemeChange({
			value: typeof propertyValue === 'number' ? parseFloat(value) : value,
			propertyName: propertyName,
			themeGroupName: themeGroupName,
			subItem: subItem,
		});
	};

	renderItem = (type) => {
		const { propertyName, propertyValue } = this.props;

		if (type === 'number' || type === 'string') {
			return (
				<Input
					className={css`
						width: 100%;
					`}
					name={propertyName}
					value={propertyValue.toString()}
					onChange={this.handleInputChange}
					inputSize={Input.sizes.SMALL}
					type={type === 'number' ? 'number' : null}
				/>
			);
		}
	};

	render() {
		const { className, propertyName, propertyValue, themeGroupName } = this.props;

		const { showThemePicker } = this.state;

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<PlotElements className={componentClasses} align="start">
				<StyledThemeItem__Property tag="span" size="-1">
					<Text tag="span" size="-1" strong>
						{propertyName}:
					</Text>
				</StyledThemeItem__Property>

				{themeGroupName === 'brand' || themeGroupName === 'ui' || this.props.propertyName.includes('color') ? (
					<React.Fragment>
						<StyledThemeItem__Property tag="span" size="-1">
							{propertyValue}
						</StyledThemeItem__Property>

						<StyledThemeItem__Color
							onClick={this.toggleThemePicker}
							size={16}
							propertyValue={propertyValue}
							hover
						/>

						<StyledThemeItem__Popover open={showThemePicker} onOutsideClick={this.toggleThemePicker}>
							<SketchPicker color={propertyValue} onChangeComplete={this.handleColorChange} />
						</StyledThemeItem__Popover>
					</React.Fragment>
				) : (
					this.renderItem(typeof propertyValue)
				)}
			</PlotElements>
		);
	}
}

export default ThemeItem;
