import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import URI from 'urijs';
// import rg4js from 'raygun4js';
import env from './common/EnvironmentVariables';
import styled, { css } from 'react-emotion';
import { SizeMe } from 'react-sizeme';
import { application, brand, typography, ui, media } from '@tbh/ui-kit';
import MaxWidthWrapper from './components/MaxWidthWrapper';

// Components
//import Page from './pages/Page';
import Footer from './components/Layouts/Footer/Footer';
//import Navigation from './components/Layouts/Navigation/Navigation';
//import FooterContainer from './containers/Application/Footer/FooterContainer';
//import TrackingPixelContainer from './containers/Tracking/TrackingPixelContainer';
//import AdvertContainer from './containers/Application/Advert/AdvertContainer';
import JsonLDContainer from './containers/Application/Meta/JsonLDContainer';
// import RacingLeftSidebarContainer from './containers/Application/Sidebar/RacingLeftSidebarContainer';
import SidebarContainer from './containers/Application/Sidebar/SidebarContainer';
//import MastheadContainer from './containers/Application/Masthead/MastheadContainer';
import MastheadContainerGRS from './containers/Application/Masthead/MastheadContainerGRS';
// import MultiBetContainer from './containers/Betting/MultiBetContainer/MultiBetContainer';
import LockedScreenContainer from './containers/Authentication/LockedScreen/LockedScreenContainer';
import ToastNotification from './components/features/Application/ToastNotification/ToastNotification';
import NextToJumpRacesContainerV5 from './containers/Racing/NextToJumpRacesContainerV5'; //@HW 21Nov2019
import { LoadingWithoutSpanner } from './components/features/Application/LoadingSpinner/LoadingSpinner'; // @HW 04Dec2019
// Actions
import { fetchAcl, setAclAttributes } from './store/acl/aclActions';
import {
	routeTo,
	checkAndSetPhoneDevice,
	fetchDefaultRolls,
	getVersionInformation,
	setApplicationLoading,
	setupIntercom,
	bootIntercom,
	setScrollbarWidth,
	hideSidebar,
} from './store/application/applicationActions';
import {
	lockScreen,
	fetchAuthenticatedUser,
	openLoginForm,
	tokenAuthentication,
	unlockScreen,
	logoutUser,
} from './store/authentication/authenticationActions';
import { navigateToRegistration } from './store/registration/registrationActions';
import { addWidgetResizeEvent, postPrefixedMessage } from './common/actions/widgetActions';
//import { fetchNavigationItems } from './store/entities/actions/NavigationItemsActions';
// import { fetchVisibleSportsWithGroupBySport } from './store/entities/actions/SportsActions';
import { addClass, getAuthenticatedUser, rootElementSelector } from './store/application/applicationSelectors';

import { mergeAxiosDefaultHeaders, setBaseUrl } from './common/Ajax';
import {
	DEFAULT_LANGUAGE,
	MAIN_APPLICAION_BACKGROUND_COLOR,
	BET_SLIP_MAX_WIDTH,
} from './store/application/applicationConstants';
import NewMultiBetContainer from './containers/Betting/MultiBetContainer/NewMultiBetContainer';
import { BREAKPOINTS, MEDIA_QUERIES, COLORS, FAMILYS, DEFAULT_COLORS } from './constants/themes';
// import { setAuthenticatedUser } from './store/entities/actions/UserActions';
// import NextToJumpRacesContainerV5Loading from './containers/Racing/NextToJumpRacesContainerV5Loading';
import { FEATURE_RACING_BASE_URL, FEATURE_RACING_SUB_APP } from './pages/FeatureRacing/FeatureConstants';
import BetExport from './containers/Betting/BetExport';
import { closeBetExport } from './store/betExport/betExportAction';
import { fetchSideBarMeetingRaces_GRS } from './store/SideBar/SideBarActions';
import PusherSubscribeAuthenticateUser from './components/controllers/Pusher/PusherSubscribeAuthenticateUser';
import RacingLeftSidebarContainer from './containers/Application/Sidebar/RacingLeftSidebarContainer';
import FooterNavigation from './components/Layouts/FooterNavigation/FooterNavigation';
import NextToJump from './pages/NextToJump/NextToJump';
import PendingBetNotifications from './containers/Betting/PendingBetNotifications/PendingBetNotifications';
import InactivityMonitor from './components/features/InactivityMonitor/InactivityMonitor';
import { openModal } from './store/modal/modalActions';
import AppStatusBar from './AppStatusBar';

/**
 * Container Sub Apps
 */
// NOTE: Using commonjs imports as es6modules forces the import order to be correct - see variable hoisting
const Error404Page = require('./pages/Application/Error404/Error404Page').default;
//const TournamentsHomePage = require('./pages/Tournaments/TournamentsHome/TournamentsHomePage').default;  // temp comment @HW2OJuly2020
//const ActiveTournamentPage = require('./pages/Tournaments/ActiveTournament/ActiveTournamentPage').default; // temp comment @HW2OJuly2020
// const RacingHomePage = require('./pages/Racing/RacingHome/RacingHomePage').default;
const SportsHomePage = require('./pages/Sports/SportsHome/SportsHomePage').default;
const RegistrationPage = require('./pages/Authentication/Registration/RegistrationPage').default;
const PlayerBetsHomePage = require('./pages/PlayerBets/PlayerBetsHome/PlayerBetsHomePage').default;
const UserAccountPage = require('./pages/UserAccount/UserAccountPage').default;
const UserAccountV2Page = require('./pages/UserAccountV2/UserAccountV2Page').default;
const PasswordResetPage = require('./pages/Authentication/PasswordReset/PasswordResetPage').default;
const ModalContainer = require('./containers/Generic/ModalContainer/ModalContainer').default;
const RacingNewPage = require('./pages/GRSNewRacing/NewRacingHome/NewRacingHomePage').default;
const GRSNextJump = require('../src/pages/GRSNextJump/NextJumpHome/NextJumpHomePage').default; // @HW12Oct2019
const Welcome = require('./pages/Welcome/WelcomeHome/WelcomeHome').default; // @HW18Oct2019
const SportNextJump = require('./pages/SportNextJump/SportNextJumpHome/SportNextJumpHomePage').default; //@HW20Jan2020
const GRSSports = require('./pages/GRSSports/GRSSportsHome/GRSSportsHome').default; // @HW24Jan2020
const BetBuilder = require('./pages/BetBuilder/BetBuilderHome/BetBuilderHome').default; // @HW04July2020
// const FeatureRacing = require('./pages/FeatureRacing/FeatureRacingHome/FeatureRacingHome').default;
const SportCricket = require('./pages/Sports/SportNewUI/Cricket').default;
const SportUpcomingPage = require('./pages/Sports/SportUpComing/SportUpcomingPage').default;
const RacingV5Page = require('./pages/Racing/Racing-V5/Racingv5').default;
const PoliConfirmation = require('./pages/PaymentConfirmation/PoliConfirmation').default;
const RSTips = require('./pages/RSTips/RSTips').default;
const DeactivatedAccountPage = require('./pages/DeactivatedAccount/DeactivatedAccount').default;
const PromotionsPage = require('./pages/Promotions/Promotions').default;
const LegalPage = require('./pages/LegalPage/LegalPage').default;
//RacingNewV5Page
const PAGES = {
	Error404: Error404Page,
	//Tournaments: TournamentsHomePage, // temp comment @HW2OJuly2020
	Registration: RegistrationPage,
	//activeTournament: ActiveTournamentPage, // temp comment @HW2OJuly2020
	//Racing: RacingHomePage,
	Sports: SportsHomePage,
	PlayerBets: PlayerBetsHomePage,
	UserAccount: UserAccountPage,
	UserAccountV2: UserAccountV2Page,
	PasswordReset: PasswordResetPage,
	Promotions: PromotionsPage,
	RacingNewPage: RacingNewPage,
	//RacingHomeLandingPage: RacingHomeLandingPage,
	// GRSNextJump: GRSNextJump, // @HW12Oct2019
	Welcome: Welcome, //@HW18Oct2019
	// SportNextJump: SportNextJump,
	GRSSports: GRSSports,
	BetBuilder: BetBuilder, // @HW04July2020
	// FeatureRacing: FeatureRacing,
	SportCricket: SportCricket,
	SportUpcoming: SportUpcomingPage,
	RacingNewV5Page: RacingV5Page,
	PoliConfirmation: PoliConfirmation,
	RSTips: RSTips,
	DeactivatedAccount: DeactivatedAccountPage,
	PromotionsPage: PromotionsPage,
	LegalPage: LegalPage,
	NextToJump: NextToJump,
};

const DESKTOP_SIZE = 900;

const StyledRootApplication__Header = styled('header')(
	(props) => css`
		label: RootApplication__Header;

		position: fixed;
		width: 100%;
		// top: 0;
		// right: 0;
		// left: 0;
		z-index: 2021;
		transition: 0.25s all ease-in-out;
		@media (min-width: 1280px) {
			display: flex;
			justify-content: center;
		}
		${props.scrollbarWidth &&
			css`
				right: ${props.scrollbarWidth}px;
			`};
	`,
);

const StyledRootApplication__Main = styled('main')(
	(props) => css`
		label: RootApplication__Main;

		// position: fixed;
		// top: 0;
		// right: 0;
		// bottom: 0;
		// left: 0;
		// min-height: 350px;
		min-height: 100vh;
		
		background: ${COLORS.gray[300]};

		//This bg colour is to match the footer - this is to extend the footer while page is loading
		//The page's main body colour is defined in the 'StyledRootApplication__MainSubappContainer' below
		// background-color: ${MAIN_APPLICAION_BACKGROUND_COLOR};//${brand(props).color_2_dark};

		// padding-top: ${props.mastheadHeight}px;
		overflow: hidden;
		-webkit-overflow-scrolling: touch;
		transition: 0.25s padding-right ease-in-out;

		${props.scrollbarX &&
			css`
				// overflow-x: auto !important;
			`};
		${props.scrollbarY &&
			css`
				// overflow-y: scroll !important;
			`};
	`,
	(props) =>
		media(
			css`
				//padding-top:125px;
				//padding-top: ${props.showNavigation ? 50 + props.mastheadHeight : props.mastheadHeight}px;

				${props.isShowRacingNextJumpOnTop === true &&
					css`
						// padding-top: ${props.showNavigation ? 50 + props.mastheadHeight : props.mastheadHeight}px;
					`};

				${props.isShowRacingNextJumpOnTop === false &&
					css`
						//padding-top: 0px;
						// margin-top: 50px;
					`};
			`,
			300, //DESKTOP_SIZE,
		),
);

const StyledRootApplication__MainNavigationRegion = styled('aside')(
	(props) => css`
		label: RootApplication__MainNavigationRegion;

		position: fixed;
		top: auto;
		bottom: 50px;
		right: 0;
		left: 0;
		height: 0;
		width: auto;
		transition: 0.25s right ease-in-out;
		z-index: 2000;

		${props.scrollbarWidth &&
			css`
				right: ${props.scrollbarWidth}px;
			`};
	`,
	(props) =>
		media(
			css`
				top: ${props.top}px;
			`,
			300,
			//DESKTOP_SIZE,
		),
);

const StyledRootApplicationNJ__MainNavigationRegion = styled('aside')(
	(props) => css`
		label: StyledRootApplicationNJ__MainNavigationRegion;

		position: fixed;
		top: auto;
		right: 0;
		left: 0;
		height: 0;
		width: auto;
		transition: 0.25s right ease-in-out;
		z-index: 2000;
		${props.scrollbarWidth &&
			css`
				right: ${props.scrollbarWidth}px;
			`};
	`,
	(props) =>
		media(
			css`
				top: ${props.top}px;
			`,
			DESKTOP_SIZE,
		),
);

const StyledRootApplication__LockedScreen = styled('div')(
	(props) => css`
		label: RootApplication__LockedScreen;

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: ${MAIN_APPLICAION_BACKGROUND_COLOR};//${brand(props).color_2};
		z-index: 9999999;
	`,
);

const StyledRootApplication__Scrollbar = styled('div')`
	label: RootApplication__Scrollbar;

	width: 100px;
	height: 100px;
	overflow: scroll;
	position: absolute;
	top: -9999px;
`;
const Nope = styled.div``;

const StyledRootApplication = styled('div')(
	(props) => css`
		label: RootApplication;
		position: absolute;
		width: 100%;
		height: 100%;
		font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};
		font-size: ${typography(props)['size_0']};
		font-feature-settings: 'kern', 'liga', 'pnum';
		-webkit-font-smoothing: antialiased;
		line-height: ${typography(props).base_line_height};
		color: ${typography(props).base_color};

		body {
			background-color: ${COLORS.gray[200]};
		}
	`,
	(props) =>
		props.isMultiBetSlipOpen &&
		media(
			css`
				${StyledRootApplication__Main} {
					// padding-right: ${BET_SLIP_MAX_WIDTH};
					//padding-right: ${application(props).bet_slip_width}px;
				}

				${StyledRootApplication__Header} {
					// right: ${BET_SLIP_MAX_WIDTH};
					//right: ${application(props).bet_slip_width}px;
				}

				${StyledRootApplicationNJ__MainNavigationRegion} {
					// right: ${BET_SLIP_MAX_WIDTH};
					//right: ${application(props).bet_slip_width}px;
				}
			`,
			application(props).size.mobile,
		),
	(props) =>
		props.showSideBetSlip &&
		media(
			css`
				${StyledRootApplication__Main} {
					padding-righ: ${BET_SLIP_MAX_WIDTH};
					//padding-right: ${application(props).bet_slip_width}px;
				}

				${StyledRootApplication__Header} {
					righ: ${BET_SLIP_MAX_WIDTH};
					//right: ${application(props).bet_slip_width}px;
				}

				${StyledRootApplicationNJ__MainNavigationRegion} {
					righ: ${BET_SLIP_MAX_WIDTH};
					//right: ${application(props).bet_slip_width}px;
				}
			`,
			application(props).size.mobile,
		),
);

const StyledSideBetContainer = styled('div')(
	(props) => css`
		label: SideBetContainer;

		${props.isOpen &&
			css`
				top: 0;
				right: 0;
				width: 100%;
				height: 100%;
				z-index: 19999;
				display: block;
				max-width: 100%;
				position: fixed;
				overflow: hidden;
				background: rgba(0, 0, 0, 0.2);
				transition: 0.25s all ease-in-out;
				pointer-events: all;
				transform: translateX(0);
			`};

		@media (min-width: ${application(props).size.mobile}px) {
			// width: ${application(props).bet_slip_width}px;
			// transform: translateX(${application(props).bet_slip_width}px);

			width: ${BET_SLIP_MAX_WIDTH};
			transform: translateX(${BET_SLIP_MAX_WIDTH});
			transform: translateX(0);

			${props.isOpen &&
				css`
					transform: translateX(0);
				`};
		}
	`,
);
const StyledContentPage = styled('div')(
	(props) => css`
	label: MainHeader_section;

	width: 100%;
	// background-color: ${MAIN_APPLICAION_BACKGROUND_COLOR};//${brand(props).color_2_dark};
	position: absolute;
    top: 0;
	// overflow-y: scroll;
    height: 100%;
    width: 100%;
	
`,
);
const StyledMainHeader_section = styled('div')(
	(props) => css`
		label: MainHeader_section;

		background-color: ${COLORS.gray[600]};
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		max-width: 1440px;
	`,
);

const StyledSideBetContainer__Content = styled('div')(
	(props) => css`
		label: SideBetContainer__Content;

		${props.isOpen &&
			css`
				background-color: ${MAIN_APPLICAION_BACKGROUND_COLOR};//${brand(props).color_2_dark};//${ui(props).color_2};
				position: relative;
				display: flex;
				flex-direction: column;
				z-index: 1;
				height: 100%;
			`};
	`,
);

const StyledSideBetContainer__Body = styled('div')(
	(props) => css`
		label: MultiBetContainer__Body;

		${props.isOpen &&
			css`
				flex: 1 1 auto;
				overflow-y: auto;
				overflow-x: hidden;
			`};
	`,
);

const StyledLeftMenu2 = styled('div')(
	(props) => css`
		label: LeftMenu2;

		display: ${props.curentRoute === '/racing-v5' ? 'none' : 'block'};
	`,
);

/**
 * Create a React ref for the scrollbar
 *
 * @type {React.RefObject<any>}
 */
const ScrollbarRef = React.createRef();

class RootApplication extends Component {
	static propTypes = {
		/** Whether or not a loading mask should be displayed, as the application is loading */
		isApplicationLoading: PropTypes.bool.isRequired,

		onJoinLogin: PropTypes.func.isRequired,

		/** If the bet slip is open or not */
		isMultiBetSlipOpen: PropTypes.bool.isRequired,

		/** If the bet slip is open or not */
		showSideBetSlip: PropTypes.bool.isRequired,

		/** Action to fetch the navigation items */
		//fetchNavigationItems: PropTypes.func.isRequired,

		//fetchVisibleSportsWithName:PropTypes.func.isRequired,

		/** Boots the Intercom */
		onBootIntercom: PropTypes.func.isRequired,

		/** Fetches the ACL */
		onFetchAcl: PropTypes.func.isRequired,

		/** Gets the authenticated user */
		onFetchAuthenticatedUser: PropTypes.func.isRequired,

		/** Fetches the Default Rolls */
		onFetchDefaultRolls: PropTypes.func.isRequired,

		/** Gets the loaded app version information and sets up the supported state */
		onGetVersionInformation: PropTypes.func.isRequired,

		/** Locks the screen */
		onLockScreen: PropTypes.func.isRequired,

		/** Unlocks the screen */
		onUnlockScreen: PropTypes.func.isRequired,

		/** Navigates to Registration */
		onNavigateToRegistration: PropTypes.func.isRequired,

		/** Toggles whether or not the application is currently loading */
		onSetAppLoading: PropTypes.func.isRequired,

		/** Checks if the app is being loaded by a phone device and then set that in state */
		onSetPhoneDevice: PropTypes.func.isRequired,

		/** Authenticates user using a token */
		onTokenAuthentication: PropTypes.func.isRequired,

		/** The key for the selected page/container/sub-app to display */
		selectedPageKey: PropTypes.string.isRequired,

		/** If the main navigation should be visible or not */
		showNavigation: PropTypes.bool.isRequired,

		/** Should the footer be visible */
		showFooter: PropTypes.bool.isRequired,

		/** Show the adverts container */
		showAdverts: PropTypes.bool.isRequired,

		/** Set changes to the ACL */
		setAclAttributes: PropTypes.func.isRequired,

		/** The scrollbar width for the browser */
		scrollbarWidth: PropTypes.number.isRequired,

		/** Function to set the scrollbar width in the store */
		setScrollbarWidth: PropTypes.func.isRequired,

		/** If the Intercom is booted or not */
		isIntercomBooted: PropTypes.bool,

		/** If the loaded version requires update or not */
		loadedVersionRequiresUpdate: PropTypes.bool,

		/** Height of the Masthead */
		mastheadHeight: PropTypes.number,

		/** If there is a non mandatory update available or not */
		nonMandatoryUpdateAvailable: PropTypes.bool,

		/** If the Locked Screen should be displayed or not */
		showLockedScreen: PropTypes.bool,

		/** Display a scrollbar on the x-axis */
		scrollbarX: PropTypes.bool,

		/** Display a scrollbar on the y-axis */
		scrollbarY: PropTypes.bool,

		/** If the resize event feature is enabled or not */
		resizeEvent: PropTypes.bool,

		/** The currently authenticated user */
		user: PropTypes.object,

		/** If the version update support feature is enabled or not */
		versionUpdateSupport: PropTypes.bool,
		navigateToTodayRacing: PropTypes.func,
	};

	static defaultProps = {
		isIntercomBooted: false,
		loadedVersionRequiresUpdate: false,
		mastheadHeight: 0,
		nonMandatoryUpdateAvailable: false,
		resizeEvent: false,
		showLockedScreen: false,
		scrollbarX: false,
		scrollbarY: true,
		user: null,
		versionUpdateSupport: false,
		navigateToTodayRacing: () => undefined,
	};

	constructor(props) {
		super(props);

		this.state = {
			isBetVisibility: false,
			headerHeight: 0,
			mobileView: window.innerWidth <= 500,
		};

		this.handleBetVisibility = this.handleBetVisibility.bind(this);
		this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
	}

	componentDidMount() {
		const {
			isIntercomBooted,
			loadedVersionRequiresUpdate,
			nonMandatoryUpdateAvailable,
			//fetchNavigationItems,
			//fetchVisibleSportsWithName,
			fetchVisibleSportsWithGroupBySport,
			onBootIntercom,
			onFetchAcl,
			onFetchAuthenticatedUser,
			onFetchDefaultRolls,
			onGetVersionInformation,
			onLockScreen,
			onNavigateToRegistration,
			onSetAppLoading,
			onSetPhoneDevice,
			onTokenAuthentication,
			onUnlockScreen,
			resizeEvent,
			setAclAttributes,
			user,
			versionUpdateSupport,
		} = this.props;

		const uriPath = URI().path();
		const uriQuery = URI().query(true);
		// Set the application loading mask
		// onSetAppLoading(true);

		// Set a custom API url if one is provided as a param
		const environmentLanguage = env('languageCode') || DEFAULT_LANGUAGE;
		const languageCode = uriQuery.lng ? uriQuery.lng : environmentLanguage;
		const apiUrl = uriQuery.api_url ? uriQuery.api_url : null;

		const newAttributes = { languageCode };

		if (apiUrl) {
			newAttributes.apiUrl = apiUrl;
		}

		setAclAttributes(newAttributes);

		if (apiUrl) {
			setBaseUrl();
		}

		// Set the client-brand request header if one is provided as a Query param
		if (uriQuery.brand) {
			const requestHeaders = {
				locale: languageCode,
				'client-brand': uriQuery.brand,
			};
			mergeAxiosDefaultHeaders(requestHeaders);
		}

		window.addEventListener('message', App.receiveWindowMessage, true);
		App.startSentry();
		App.connectPusher(); // add by @HW 03-01-2020
		App.onStart();

		onFetchAcl(uriQuery.affiliate_id, uriQuery.acl_id, uriQuery.base_affiliate_id, uriQuery.base_acl_id).finally(() => {
			if (env('debug')) {
				addClass(rootElementSelector(), 'js_env-development');
			}

			if (env('livechatLicense') && this.props.liveChatEnabled) {
				App.livechat(env('livechatLicense'));
			}

			/**PAGES
			 * Init Raygun
			 */
			// rg4js('enableCrashReporting', true); // Automatic reporting
			// rg4js('apiKey', env('raygunKey'));
			// rg4js('options', {
			// 	debugMode: env('debug'),
			// });
			// if (env('debug')) {
			// 	rg4js('setVersion', `${process.env.PACKAGE_VERSION}_dev`);
			// } else {
			// 	rg4js('setVersion', process.env.PACKAGE_VERSION);
			// }
			setupIntercom();

			let timeout = setInterval(() => {
				if (env('logTrackingEvents', false)) {
					// console.log('INTERCOM:try');
				}
				if (isIntercomBooted) {
					if (env('logTrackingEvents', false)) {
						// console.log('INTERCOM: now booted');
					}
					clearInterval(timeout);
					return;
				}
				onBootIntercom();
			}, 1000);

			// Checks the device the app is being loaded on and adds it to the store
			onSetPhoneDevice();

			/* fetchNavigationItems(); */

			/* fetchVisibleSportsWithName(); */

			/* fetchVisibleSportsWithGroupBySport(); */
			const startupPromises = [
				// Check to see if there is a token or not and load the authenticated user appropriately

				new Promise((resolve, reject) => {
					// if (
					// 	!uriQuery.hasOwnProperty('token') &&
					// !uriPath.includes('deposits')) {
					// 	const params = {
					// 		source: uriQuery.source,
					// 		token: uriQuery.token,
					// 		username: uriQuery.username,
					// 	};

					// 	onTokenAuthentication(params)
					// 		.catch(reject)
					// 		.then(resolve);
					// } else {
					// }
					onFetchAuthenticatedUser()
						.catch(resolve)
						.finally(resolve);
				}),
			];

			// If versions feature is enabled add the version & support checking to the startup promises
			if (versionUpdateSupport) {
				startupPromises.push(
					new Promise((resolve) => {
						onGetVersionInformation().finally(resolve);
					}),
				);
			}

			Promise.all(startupPromises)
				.catch(function(...rest) {
					// console.log(rest);
				})
				.finally(function() {
					// Set the application loading mask
					onSetAppLoading(false);

					// TODO: There was a race condition here whether the prop was set in 'componentDidMount'. This should be moved out of this function and managed elsewhere
					if (App.store.getState().featureToggles.features.resizeEvent.enabled) {
						addWidgetResizeEvent();
					}
					// console.log(versionUpdateSupport);
					// Loaded app version requires a mandatory update
					if (versionUpdateSupport && loadedVersionRequiresUpdate) {
						return App.versionNotSupported();
					}

					// There is a non mandatory update available for the app
					if (versionUpdateSupport && nonMandatoryUpdateAvailable) {
						return App.nonMandatoryUpdateAvailable();
					}

					// Checks if app being loading on a phone type device and sets state
					onSetPhoneDevice();

					if (uriQuery.join) {
						setTimeout(() => {
							onNavigateToRegistration();
						}, 2000);
					}

					if (user && localStorage.getItem('lockScreen')) {
						clearInterval(timeout); // Prevent Intercom from appearing on lock screen
						onLockScreen();
					} else {
						onUnlockScreen();
					}

					onFetchDefaultRolls();

					postPrefixedMessage('ready');
				});
		});
		this.props.fetchSideBarMeetingRaces_GRS();
		this.startSessionTimer();
	}

	componentDidUpdate = () => {
		const scrollbarWidth =
			this.props.scrollbarY && ScrollbarRef.current
				? ScrollbarRef.current.offsetWidth - ScrollbarRef.current.clientWidth
				: 0;

		if (this.props.scrollbarWidth !== scrollbarWidth) {
			this.props.setScrollbarWidth(scrollbarWidth);
		}

		if (this.props.selectedPage === 'RacingNewPage') {
			this.props.onCloseSidebar();
		}

		window.addEventListener('resize', this.handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', this.handleWindowSizeChange);
		};
	};

	handleWindowSizeChange() {
		this.setState({ mobileView: window.innerWidth <= BREAKPOINTS.laptopMin });
	}

	handleBetVisibility() {
		this.setState((prevState) => ({
			isBetVisibility: !prevState.isBetVisibility,
		}));
	}

	/**
	 * Start timer which records the length of user's session
	 */
	startSessionTimer = () => {
		// Reset timer
		this.sessionTimerCount = 0;

		// If timer exists, clear it
		if (this.sessionTimer) {
			clearInterval(this.sessionTimer);
		}

		this.sessionTimer = setInterval(() => {
			++this.sessionTimerCount;
		}, 1000);
	};

	render() {
		const {
			isApplicationLoading,
			isMultiBetSlipOpen,
			showSideBetSlip,
			selectedPageKey,
			mastheadHeight,
			showAdverts,
			showFooter,
			showLockedScreen,
			showNavigation,
			scrollbarX,
			scrollbarY,
			scrollbarWidth,
			currentRoute,
			headerHeight,
		} = this.props;

		// let pageKey = this.state.mobileView && selectedPageKey === 'Welcome' ? 'NextToJump' : selectedPageKey;

		const PageComponent = PAGES[selectedPageKey] || Nope;
		const pagesNextJumpAvailable = ['Welcome', 'RacingNewPage', 'GRSNextJump', 'FeatureRacing', 'Promotions', 'RSTips'];
		const getRacingNextJumpAvailable = (selectedPageKey) => {
			if (pagesNextJumpAvailable.includes(selectedPageKey)) {
				return true;
			}
			return false;
		};
		// Suspense
		// lazy	const

		// const NextToJumpRacesContainerV5A = lazy(() => <NextToJumpRacesContainerV5/>);
		// const applicationContainerScrollTop = document.getElementById("application");

		const isShowRacingNextJumpOnTop = getRacingNextJumpAvailable(selectedPageKey);

		const renderHeaderSection = this.state.mobileView ? (
			<React.Fragment>
				<MastheadContainerGRS handleBetVisibility={this.handleBetVisibility} />
				{/* {isShowRacingNextJumpOnTop && <NextToJumpRacesContainerV5 />} */}
			</React.Fragment>
		) : (
			<React.Fragment>
				{isShowRacingNextJumpOnTop && this.props.showNtjRaces && <NextToJumpRacesContainerV5 />}
				<MastheadContainerGRS handleBetVisibility={this.handleBetVisibility} />
			</React.Fragment>
		);

		// const headerHeight = document.getElementById('headerWrapper') ? document.getElementById('headerWrapper').offsetHeight : 0;

		return (
			<SizeMe>
				{({ size: containerSize }) => (
					<StyledRootApplication
						size={containerSize}
						isMultiBetSlipOpen={isMultiBetSlipOpen}
						showSideBetSlip={showSideBetSlip}
					>
						<AppStatusBar />

						{<PusherSubscribeAuthenticateUser />}

						<div id="scrollTo" />
						<LoadingWithoutSpanner style={{ display: isApplicationLoading ? 'block' : 'none' }} />
						{this.props.betExport && (
							<BetExport
								closeModal={() => this.props.closeBetExport()}
								bet={this.props.betExport}
								taglines={this.props.taglines}
							/>
						)}
						<JsonLDContainer />
						<SidebarContainer footerLists={this.props.footerLists} />

						<StyledSideBetContainer isOpen={showSideBetSlip}>
							<StyledSideBetContainer__Content isOpen={showSideBetSlip}>
								<StyledSideBetContainer__Body isOpen={showSideBetSlip}>
									<div id="side-betslip-portal" />
								</StyledSideBetContainer__Body>
							</StyledSideBetContainer__Content>
						</StyledSideBetContainer>

						{scrollbarY && <StyledRootApplication__Scrollbar innerRef={ScrollbarRef} />}

						<MainApplicationWrapper
							headerHeight={headerHeight}
							// isBetVisibility={isMultiBetSlipOpen || containerSize.width > BREAKPOINTS.laptopMin}
						>
							<MainHeaderWrapper id="headerWrapper">
								{/* {isShowRacingNextJumpOnTop && <NextToJumpRacesContainerV5 />}
								<MastheadContainerGRS handleBetVisibility={this.handleBetVisibility} /> */}
								{renderHeaderSection}
							</MainHeaderWrapper>
							<RacingSidebarWrapper headerHeight={headerHeight}>
								<RacingLeftSidebarContainer
									isMultiBetSlipOpen={isMultiBetSlipOpen || containerSize.width > BREAKPOINTS.laptopMin}
								/>
							</RacingSidebarWrapper>
							<BodyContentWrapper isUserAccount={this.props.selectedPage}>
								<PageComponent />
							</BodyContentWrapper>
							<BetSlipWrapper headerHeight={headerHeight}>
								<BetSlipFixedWrapper headerHeight={headerHeight}>
									{/* {(isMultiBetSlipOpen || containerSize.width > BREAKPOINTS.laptopMin) && <NewMultiBetContainer headerHeight={headerHeight} />} */}
									<NewMultiBetContainer headerHeight={headerHeight} />
								</BetSlipFixedWrapper>
							</BetSlipWrapper>
							<FooterNavigation />
							<FooterContentWrapper id="footerWrapper">
								<Footer
									taglines={this.props.taglines}
									gambleResponsiblyMessage={this.props.gambleResponsiblyMessage}
									gamblingMessages={this.props.gamblingMessages}
									seoContent={this.props.seoContent}
									footerLists={this.props.footerLists}
									abn={this.props.abn}
									brandName={this.props.brandName}
									user={this.props.user}
									loading={this.state.isLoading}
									active={this.props.active}
									logedCount={this.props.logedCount}
									sessionTimeStop={this.props.sessionTimeStop}
									inActivityTmeTimeStop={this.props.inActivityTmeTimeStop}
								/>
							</FooterContentWrapper>
						</MainApplicationWrapper>
						<PendingBetNotifications />
						<ToastNotification />
						<ModalContainer />
						<InactivityMonitor onIdle={this.props.logoutUser} />

						{showLockedScreen && (
							<StyledRootApplication__LockedScreen>
								<LockedScreenContainer />
							</StyledRootApplication__LockedScreen>
						)}
					</StyledRootApplication>
				)}
			</SizeMe>
		);
	}
}

const FooterContentWrapper = styled.div`
	grid-area: footer;
	position: relative;
	z-index: 1;
	@media (max-width: ${BREAKPOINTS.laptopMin}px) {
		margin-bottom: 64px;
    ;
`;

const BetSlipWrapper = styled.aside`
	grid-area: right-column;
	position: relative;
	height: 100%;
	z-index: 19999;
	@media ${MEDIA_QUERIES.laptopAndUp} {
		position: relative;
		z-index: 0;
	}
`;

const BetSlipFixedWrapper = styled.div`
	position: fixed;
	width: 302px;
`;

const BodyContentWrapper = styled.div`
	grid-area: content;
    position: relative;
    width: 100%;
	padding: 16px 0px;
	transition: height 200ms;
	z-index: 12;
	overflow: auto;  // Ensure scrolling when content overflows
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
  	-moz-backface-visibility:    hidden;
  	-ms-backface-visibility:     hidden;
  	-webkit-overflow-scrolling: touch !important;
	// margin-bottom: ${(props) =>
		props.isUserAccount === 'UserAccountV2' || props.isUserAccount === 'LegalPage' ? '16px' : null};
	
	@media (max-width: ${BREAKPOINTS.laptopMin}px) {
		padding: 16px 8px;
		overflow: auto;  // Ensure scrolling on mobile view
		overflow-y: auto;  // Ensure vertical scrolling on mobile view
		-webkit-overflow-scrolling: touch;  // Enable smooth scrolling on mobile view
	}
`;

const RacingSidebarWrapper = styled.aside`
	grid-area: left-column;
	position: fixed;
	top: ${(props) => props.headerHeight}px;
	height: 100%;
	align-self: start;
	z-index: 1;
	background-color: white;
	height: 100%;
	@media (max-width: ${BREAKPOINTS.laptopMin}px) {
		display: none;
	}
`;

const MainHeaderWrapper = styled.div`
	// grid-area: header;
	position: fixed;
	width: 100%;
	max-width: 1440px;
	top: 0;
	place-content: center;
	z-index: 199;
	@media (max-width: ${BREAKPOINTS.laptopMin}px) {
		padding: 0px;
	}
`;

// grid-template-areas:${props => props.isBetVisibility ?
// 	`
// 	"header header header"
// 	"left-column content right-column"
// 	"left-column footer right-column"
// 	`
// 	:
// 	`
// 	"header header"
// 	"left-column content"
// 	"left-column footer"
// 	`
// };
// grid-template-columns: ${props => props.isBetVisibility ? '240px 2fr 302px' : '240px 1fr'};

const MainApplicationWrapper = styled(MaxWidthWrapper)`
	display: grid;
	column-gap: 16px;
	isolation: isolate;
	max-width: 1440px;
	top: ${(props) => props.headerHeight}px;
	@media (max-width: ${BREAKPOINTS.laptopMin}px) {
		// grid-template-columns: 1fr;
		// grid-template-rows: 1fr;
		// grid-template-areas:
		// "header header"
		// "left-column content"
		// "left-column footer";
		display: flex;
		flex-direction: column;
		padding: 0px;
		margin: 0px;
		column-gap: 0px;
		min-height: calc(100% - ${(props) => props.headerHeight}px);
		justify-content: space-between;
	}

	@media (min-width: ${BREAKPOINTS.laptopMin}px) {
		grid-template-columns: 240px 2fr 302px;
		grid-template-rows: 1fr auto;
		grid-template-areas:
			'header header header'
			'left-column content right-column'
			'left-column footer right-column';
	}
`;

const mapStateToProps = (state) => {
	const showNavigation =
		state.featureToggles.features.mainNavigation.enabled &&
		state.application.showNavigation &&
		state.featureToggles.features.masthead.enabled;

	return {
		betExport: state.betExport.selectedBet,
		active: state.realityHoursReducer.active,
		logedCount: state.realityHoursReducer.logedCount,
		selectedPage: state.application.selectedPage,
		footerLists: state.acl.footerGroups,
		gamblingMessages: state.acl.gamblingMessages,
		gambleResponsiblyMessage: state.acl.gambleResponsiblyMessage,
		taglines: state.acl.taglines,
		seoContent: state.acl.seoContent,
		abn: state.acl.ABN,
		brandName: state.acl.brandDetails && state.acl.brandDetails.name,
		currentRoute: state.application.currentRoute,
		isApplicationLoading: state.application.isApplicationLoading,
		isIntercomBooted: state.application.intercomBooted,
		isMultiBetSlipOpen: state.application.showMultiBetSlip,
		showSideBetSlip: state.application.showSideBetSlip,
		loadedVersionRequiresUpdate: state.application.loadedVersionRequiresUpdate,
		mastheadHeight: state.application.mastheadHeight,
		nonMandatoryUpdateAvailable: state.application.nonMandatoryUpdateAvailable,
		resizeEvent: state.featureToggles.features.resizeEvent.enabled,
		scrollbarX:
			state.featureToggles.features.scrollbar.enabled && state.featureToggles.features.scrollbar.value.horizontal,
		scrollbarY:
			state.featureToggles.features.scrollbar.enabled && state.featureToggles.features.scrollbar.value.vertical,
		selectedPageKey: state.application.selectedPage,
		scrollbarWidth: state.application.scrollbarWidth,
		showAdverts: state.featureToggles.features.adverts.enabled && showNavigation,
		showFooter: state.featureToggles.features.showFooter.enabled,
		liveChatEnabled: state.featureToggles.features.liveChat.enabled,
		showLockedScreen: state.application.showLockedScreen,
		showNavigation,
		user: getAuthenticatedUser(state),
		headerHeight: document.getElementById('headerWrapper') ? document.getElementById('headerWrapper').offsetHeight : 0,
		versionUpdateSupport: state.featureToggles.features.versionUpdateSupport.enabled,
		showNtjRaces: state.featureToggles.features.ntjRaces.enabled,
		// loadednextToJump:
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeBetExport: (bet) => {
			dispatch(closeBetExport(bet));
		},
		/* fetchNavigationItems: () => {
			return dispatch(fetchNavigationItems());
		}, */
		// temparory comment by @HW2021Oct19
		/* fetchVisibleSportsWithName:() => {
		  return dispatch(fetchVisibleSportsWithName());
	}, */
		/* fetchVisibleSportsWithGroupBySport:() => {
		  return dispatch(fetchVisibleSportsWithGroupBySport());
	}, */
		fetchSideBarMeetingRaces_GRS: () => {
			return dispatch(fetchSideBarMeetingRaces_GRS());
		},
		onBootIntercom: () => {
			return dispatch(bootIntercom());
		},
		onFetchAcl: (affiliateId, campaignId, baseAffiliateId, baseCampaignId, params) => {
			return dispatch(fetchAcl(affiliateId, campaignId, baseAffiliateId, baseCampaignId, params));
		},
		onFetchAuthenticatedUser: () => {
			return dispatch(fetchAuthenticatedUser());
		},
		onFetchDefaultRolls: () => {
			return dispatch(fetchDefaultRolls());
		},
		onGetVersionInformation: () => {
			return dispatch(getVersionInformation());
		},
		onLockScreen: () => {
			return dispatch(lockScreen());
		},
		onUnlockScreen: () => {
			return dispatch(unlockScreen());
		},
		onNavigateToRegistration: () => {
			return dispatch(navigateToRegistration());
		},
		onJoinLogin: () => {
			return dispatch(openLoginForm());
		},
		onSetAppLoading: (isLoading) => {
			return dispatch(setApplicationLoading(isLoading));
		},
		onSetPhoneDevice: () => {
			return dispatch(checkAndSetPhoneDevice());
		},
		onTokenAuthentication: (params) => {
			return dispatch(tokenAuthentication(params));
		},
		setAclAttributes: (acl) => {
			return dispatch(setAclAttributes(acl));
		},
		setScrollbarWidth: (width) => {
			return dispatch(setScrollbarWidth(width));
		},
		logoutUser: () => {
			dispatch(
				openModal({
					id: 'InactivityDialogContainer',
					Component: 'InactivityDialogContainer',
				}),
			);
			dispatch(logoutUser(true, true));
		},

		navigateToGRSFeature: () => {
			dispatch(routeTo('/' + FEATURE_RACING_BASE_URL));
			App.startSubApp(FEATURE_RACING_SUB_APP);
		},

		goToHome: () => {
			return dispatch(routeTo('/welcome')); // change by @HW 21NOV2019
		},

		sessionTimeStop: () => {
			return dispatch({ type: 'SESSION_TIME_STOP' });
		},
		inActivityTmeTimeStop: () => {
			return dispatch({ type: 'IACTIVITY_TIME_STOP' });
		},

		onCloseSidebar: () => {
			dispatch(hideSidebar());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RootApplication);
