import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, ui, Text } from '@tbh/ui-kit';

import CurrencyNameDisplay from '../Application/Currency/CurrencyNameDisplay/CurrencyNameDisplay';
import CurrencyDisplay from '../Application/Currency/CurrencyDisplay/CurrencyDisplay';

const StyledBettaBucksAmount = styled('div')(
	(props) => css`
		label: BettaBucksAmount;

		background-color: ${ui(props).color_3};
		padding: ${spacings(props).cozy}px;
		display: flex;
		justify-content: space-around;
	`,
);

const BettaBucksAmount = (props) => {
	const { className, bettaBucks, t } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<StyledBettaBucksAmount className={componentClasses}>
			<Text size="-1" tag="span">
				{t('BettaBucksAmount__PlayWith')}{' '}
				<strong>
					<CurrencyDisplay amount={bettaBucks} type={'tournamentCurrency'} />{' '}
					<CurrencyNameDisplay type="tournamentCurrency" />
				</strong>
			</Text>
		</StyledBettaBucksAmount>
	);
};

BettaBucksAmount.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The betta bucks amount */
	bettaBucks: PropTypes.number.isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

BettaBucksAmount.defaultProps = {
	className: '',
};

export default withNamespaces()(BettaBucksAmount);
