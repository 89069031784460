import styled, { css } from 'react-emotion';
import { brand, spacings, typography, ui, LightenDarkenColor, Image, media, mediaMax, Text, Link } from '@tbh/ui-kit';
import NextToJumpRacesContainer from '../../../../containers/Racing/NextToJumpRacesContainer';

import JoinLoginElements from './JoinLoginElements/JoinLoginElements';
//import { MAIN_APPLICAION_BACKGROUND_COLOR } from '../../../../store/application/applicationConstants';
import { BREAKPOINTS, MEDIA_QUERIES, DEFAULT_COLORS } from '../../../../constants/themes';

const MASTHEAD_SMALL_HEIGHT = '70px';
const MASTHEAD_LARGE_HEIGHT = '90px';
const MASTHEAD_GRSBET_HEIGHT = '60px';

// These logo heights have been adjusted to account for logos of any size
const MASTHEAD_LOGO_SMALL_WIDTH = '68px';
const MASTHEAD_LOGO_SMALL_HEIGHT = '50px';

const MASTHEAD_LOGO_MEDIUM_WIDTH = '126px';
const MASTHEAD_LOGO_MEDIUM_HEIGHT = '60px';

const MASTHEAD_LOGO_LARGE_WIDTH = '200px';
const MASTHEAD_LOGO_LARGE_HEIGHT = '65px';
const MASTHEAD_LOGO_GRSBET_WIDTH = '170px';

export const StyledMasthead__KeepGoing = styled(Text)(
	(props) => css`
		label: Masthead__KeepGoing;
		display: block;
		background-color: rgb(118 116 116 / 48%);//${brand(props).color_1};
		border: 1px solid rgb(118 116 116 / 48%);//${brand(props).color_1};
		border-bottom: 2px solid ${LightenDarkenColor(brand(props).color_1, 10)};
		padding: ${spacings(props).cozy}px ${spacings(props).cozy}px;
		line-height: 1.2;
		height: ${MASTHEAD_GRSBET_HEIGHT};
		font-weight: 100;
	`,
);

export const StyledMasthead__Wrapper = styled('div')(
	() => css`
		label: Masthead__Wrapper;
		display: grid;
		height: ${MASTHEAD_GRSBET_HEIGHT};
    	gap: 2px;
    	grid-template-rows: 1fr;
    	grid-template-columns: 220px 3fr 440px;
    	align-items: center;

		@media (max-width: ${BREAKPOINTS.laptopMin}px) {
			grid-template-columns: 1fr 2fr;
		}
	`,
);


export const StyledMasterhead__Wrapper_V1 = styled('div')(
	(props) => css`
    label: Masterhead__Wrapper__V1;
    display: flex;
    justify-content: space-between;
		font-size: ${typography(props)['size_-1']};
		padding: 0 ${spacings(props).compact}px;
		margin: 0 auto;
		${!props.isFullWidth &&
		css`
  	max-width: 100%;
  `};
  `,
	media(
		css`
			height: ${MASTHEAD_LARGE_HEIGHT};
		`,
		481,
	),
);

export const StyledMasthead__Menu = styled('div')(
	(props) => css`
		label: Masthead__Menu;
		flex: 0 0 auto;
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		cursor: pointer;
		margin-right: ${spacings(props).compact}px;

		@media (min-width: 900px) {
			display: none;
		}
	`,
	media(
		css`
			display: none;
		`,
		900,
	),
);

export const StyledMasthead__MenuLines = styled('div')(
	(props) => css`
		label: Masthead__MenuLines;
		z-index: 1;
		height: 3px;
		width: 22px;
		background-color: ${ui(props).color_2};
		position: relative;
		margin-bottom: ${spacings(props).cozy}px;

		&:before,
		&:after {
			position: absolute;
			display: block;
			content: '';
			height: inherit;
			width: 100%;
			background-color: inherit;
		}

		&:before {
			top: 6px;
		}

		&:after {
			top: 12px;
		}
	`,
);

export const StyledMasthead__Logo = styled(Link)(
	css`
		label: Masthead__Logo;

		flex: 1 1 auto;
		display: flex;
		align-self: center;
		cursor: pointer;
		
		@media ${MEDIA_QUERIES.tabletAndUp} {
			padding-left:5px;
			padding-right:5px;
		}
	`,
	media(
		css`
			display: flex;
		`,
		481,//481,
		{ sizeKey: 'mediaSize' },
	),


);
export const StyledMaster__Bets = styled('div')(
	(props) => css`
  label: Masthead__Bets;
  `,

);

export const StyledMasthead__LogoImage = styled(Image)(
	(props) => css`
		label: Masthead__LogoImage;

		flex: 0 1 auto;
		width: auto;
		height: auto;
		max-width: ${props.maxWidth ? props.maxWidth : MASTHEAD_LOGO_SMALL_WIDTH};
		max-height: ${props.maxHeight ? props.maxHeight : MASTHEAD_LOGO_SMALL_HEIGHT};
		object-fit: contain; // Not supported by IE
	`,
	media(
		(props) => css`
			max-width: ${props.maxWidth ? props.maxWidth : MASTHEAD_LOGO_MEDIUM_WIDTH};
			max-height: ${props.maxHeight ? props.maxHeight : MASTHEAD_LOGO_MEDIUM_HEIGHT};
			margin-left: 10px;
		`,
		424,
	),
	media(
		(props) => css`
			max-width: ${props.maxWidth ? props.maxWidth : MASTHEAD_LOGO_GRSBET_WIDTH};
			max-height: ${props.maxHeight ? props.maxHeight : MASTHEAD_LOGO_LARGE_HEIGHT};
			margin-left: 10px;
		`,
		1024,
	),
);

export const StyledMasthead__JoinLoginElements = styled(JoinLoginElements)(
	(props) => css`
		height: 100%;
    	justify-content: center;
		label: Masthead__JoinLoginElements;
		//margin-right: ${spacings(props).cozy}px;
	`,
);

const StyledMasthead = styled('div')(
	(props) => css`
		label: Masthead;
		width: 100%;
		// height: ${MASTHEAD_SMALL_HEIGHT};
		font-size: ${typography(props).size_0};
		background-color: ${brand(props).tertiary_active_color ? brand(props).tertiary_active_color : DEFAULT_COLORS.df_tertiary_active_color};
	`,
	(props) =>
		props.hideMastheadFields &&
		mediaMax(
			css`
				display: block;
			`,
			580,
		),
);

export const StyledMasterhead__Hambuger__Menu = styled('div')(
	css`
		label: StyledMasterhead__Hambuger__Menu;
		flex: 1 1 auto;
		justify-content: flex-start;
		padding-left: 12px;
		//display: none;
		align-self: center;
		cursor: pointer;
		color: #ffffff;
		max-width: 40px;
		@media (min-width: ${BREAKPOINTS.laptopMin}px) {
			display: none;
		}
	`,
	media(
		css`
			display: flex;
      		max-width:40px;
		`,
		279,//481,
		{ sizeKey: 'mediaSize' },
	),
);
export const StyledMasthead__NextToJumpRaces = styled(NextToJumpRacesContainer)`
	${(props) =>
		css`
			label: StyledMasthead__NextToJumpRaces;

			margin-bottom: ${spacings(props).compact}px;
			display: flex;

			${!props.isRacingHome &&
			css`
					display: flex;
					width:800px;
				`};
		`};

	${media(
				(props) =>
					!props.isRacingHome &&
					css`
				display: flex;
			`,
				500,
			)};
`;
export default StyledMasthead;
