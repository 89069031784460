'use strict';

import {denormalize, normalize} from 'normalizr';

import {marketTypeGroupsSchema} from './Entities';

/**
 * Convert normalized MarketTypeGroups entities back to a structure similar to the API response
 *
 * @param entities
 */
export const denormalizeMarketTypeGroups = (entities) => {
	return denormalize(Object.keys(entities.marketTypeGroups), marketTypeGroupsSchema, entities);
};

/**
 * Normalize the MarketTypeGroups structure to suit JS Applications
 *
 * @param response
 */
export const normalizeMarketTypeGroups = (response) => {
	return normalize(response, marketTypeGroupsSchema);
};