import React from 'react';
import { Text } from '@tbh/ui-kit';
import styled from 'react-emotion';

const Background = styled('div')`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	z-index: 2;
`;

const Container = styled('div')`
	flex-direction: column;
	padding: 10px;
	background-color: #161a1d;
	border-radius: 4px;
	width: 95%;
	margin: auto;
	position: relative;
	cursor: pointer;
`;

const BetNotificationEventClosed = ({ onClick }) => {
	return (
		<Background>
			<Container onClick={onClick}>
				<Text size="-1" weight="bold" type={'alternate'}>
					Event is closed for betting
				</Text>
			</Container>
		</Background>
	);
};

export default BetNotificationEventClosed;
