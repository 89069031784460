import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled, { css } from 'react-emotion';
import { spacings, media } from '@tbh/ui-kit';
import { withNamespaces } from 'react-i18next';

import { Button, Table, Text, List, ListItem } from '@tbh/ui-kit';
import Svg from '../../../../ui-components/Svg/Svg';
import CurrencyDisplay from '../../../Application/Currency/CurrencyDisplay/CurrencyDisplay';

import { DAY_DATE_FORMAT, HOUR_MIN_AMPM } from '../../../../../common/constants/DateFormats';

const PENDING_WITHDRAWAL_ROW_BREAKPOINT = 651;

const StyledPendingWithdrawalRow__ListItem = styled(ListItem)(
	(props) => css`
		label: PendingWithdrawalRow__ListItem;

		margin-bottom: ${spacings(props).tight}px;
	`,
);

const StyledPendingWithdrawalRow__Currency = styled(Table.TD)(
	css`
		label: PendingWithdrawalRow__Currency;

		display: flex;
	`,
	media(
		css`
			flex-direction: column;
			justify-content: space-between;
		`,
		PENDING_WITHDRAWAL_ROW_BREAKPOINT,
	),
);

const StyledPendingWithdrawalRow__Description = styled(Table.TD)(
	media(
		css`
			label: PendingWithdrawalRow__Description;

			display: flex;
			justify-content: space-between;
		`,
		PENDING_WITHDRAWAL_ROW_BREAKPOINT,
	),
);

const StyledPendingWithdrawalRow__Icon = styled(Svg)((props) =>
	media(
		css`
			label: PendingWithdrawalRow__Icon;

			display: block;
			margin: -${spacings(props).tight}px ${spacings(props).cozy}px 0;
		`,
		PENDING_WITHDRAWAL_ROW_BREAKPOINT,
	),
);

const PendingWithdrawalRow = (props) => {
	const { t, item, size, onClick } = props;
	const { date, withdrawal_type, id, amount, balance, canceled } = item;

	const handleOnCancel = (e) => {
		e.preventDefault();
		onClick(id);
	};

	const isTabletWidth = size.width < PENDING_WITHDRAWAL_ROW_BREAKPOINT;

	return (
		<React.Fragment>
			<Table.TR density="comfortable">
				{!isTabletWidth && (
					<Table.TD colSpan="2">
						<List>
							<StyledPendingWithdrawalRow__ListItem>
								<Text size="-1">{moment(date).format(DAY_DATE_FORMAT)}</Text>
							</StyledPendingWithdrawalRow__ListItem>
							<StyledPendingWithdrawalRow__ListItem>
								<Text size="-1">{moment(date).format(HOUR_MIN_AMPM)}</Text>
							</StyledPendingWithdrawalRow__ListItem>
						</List>
					</Table.TD>
				)}

				<StyledPendingWithdrawalRow__Description colSpan="5" size={size}>
					<List>
						<StyledPendingWithdrawalRow__ListItem>
							<Text size="-1" strong>
								{t('Withdrawal')}
							</Text>
						</StyledPendingWithdrawalRow__ListItem>
						<StyledPendingWithdrawalRow__ListItem>
							<Text size="-1" capitalize>
								{withdrawal_type}
							</Text>
						</StyledPendingWithdrawalRow__ListItem>
						<StyledPendingWithdrawalRow__ListItem>
							<Text size="-1">#{id}</Text>
						</StyledPendingWithdrawalRow__ListItem>
					</List>
					{/* TODO: Improvement: change svg image based on withdrawal type. */}
					{!isTabletWidth && <StyledPendingWithdrawalRow__Icon name="credit-card" width="24" height="24" size={size} />}
				</StyledPendingWithdrawalRow__Description>

				<StyledPendingWithdrawalRow__Currency colSpan="2" size={size}>
					<Text size="-1" type={canceled ? 'alternate-light' : 'danger'} lineThrough={canceled} strong>
						<CurrencyDisplay amount={amount} />
					</Text>
					{!isTabletWidth && (
						<Button type={Button.types.DANGER} action={handleOnCancel} disabled={canceled}>
							{t('CancelWithdrawal')}
						</Button>
					)}
				</StyledPendingWithdrawalRow__Currency>
				{!isTabletWidth && (
					<Table.TD>
						<Text size="-1" strong>
							<CurrencyDisplay amount={balance} />
						</Text>
					</Table.TD>
				)}
			</Table.TR>
			<Table.TR bottomBorder>
				<Table.TD>
					{isTabletWidth && (
						<Button block type={Button.types.DANGER} action={handleOnCancel}>
							{t('CancelWithdrawal')}
						</Button>
					)}
				</Table.TD>
			</Table.TR>
		</React.Fragment>
	);
};

PendingWithdrawalRow.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** The pending withdrawal item */
	item: PropTypes.shape({
		date: PropTypes.string.isRequired,
		withdrawal_type: PropTypes.string.isRequired,
		id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		amount: PropTypes.number.isRequired,
		balance: PropTypes.number,
		canceled: PropTypes.bool,
	}).isRequired,

	/** Click handler for clicking on the cancel button */
	onClick: PropTypes.func,
};

PendingWithdrawalRow.defaultProps = {
	onClick: () => {},
};

const PendingWithdrawalRowHOC = withNamespaces()(PendingWithdrawalRow);

PendingWithdrawalRowHOC.PENDING_WITHDRAWAL_ROW_BREAKPOINT = PENDING_WITHDRAWAL_ROW_BREAKPOINT;

export default PendingWithdrawalRowHOC;
