import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { spacings, typography, media, Text, brand } from '@tbh/ui-kit';

// get the default silk image
import { SELECTION_NOT_SCRATCHED_STATUS } from '../../../../../common/constants/Racing';

const StyledRaceStrikeRate = styled('div')`
	${(props) =>
		css`
			label: RaceStrikeRate;

			// width: 14%;
			display: flex;
			// padding: ${spacings(props).tight}px 6px;


		`};
	${media(
		css`
			padding-top: 0;
		`,
		660,
	)};
`;

const StyledRaceStrikeRate_Column = styled('div')`
	${(props) =>
		css`
			label: StyledRaceStrikeRate_Column;

			width: 100%;
			display: flex;
			padding-top: ${spacings(props).tight}px;
			gap: 2px;
			text-overflow: ellipsis;
			overflow: hidden;
			${props.scratched &&
				css`
					opacity: 0.2;
					filter: grayscale(100%);
				`};
		`};

	${media(
		css`
			padding-top: 0;
		`,
		660,
	)};
`;

const StyledRaceStrikeRate_percentage = styled('div')`
	${(props) =>
		css`
			label: RaceStrikeRate_percentage;

			flex: 1 1 25%;
			padding: ${spacings(props).tight}px 0;
			width: 24px;
		`};
`;

const StyledRaceStrikeRate_percentage_value = styled('div')`
	${(props) =>
		css`
			label: RaceStrikeRate_percentage_value;
			width: 24px;
			height: 24px;
			background-color: ${brand(props).rch_4};
			background-color: hsl(5deg 7% 67%) !important;
			border-radius: 50%;
			display:flex;
			align-items:center;
			justify-content:center;
		`};
`;

const StyledValue = styled(Text)`
	${(props) =>
		css`
        label: RaceStrikeRate__value;
        text-align: center;
        color: #000000;
        font-size: 10px;//${typography(props)['size_-2']};
        font-weight: 700;
    `};
`;

const RaceStrikeRate = (props) => {
	const {
		t,
		className,
		size,
		selection_status,
		jockey_horse_percentage,
		jockey_percentage,
		trainer_percentage,
		jockey_trainer_percentage,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});
	const scratched = selection_status !== SELECTION_NOT_SCRATCHED_STATUS;
	return (
		<StyledRaceStrikeRate className={componentClasses}>
			{!scratched && (
				<StyledRaceStrikeRate_Column scratched={scratched} size={size}>
					{/* jockey_horse_percentage */}
					<StyledRaceStrikeRate_percentage>
						<StyledRaceStrikeRate_percentage_value>
							<StyledValue>{jockey_horse_percentage}</StyledValue>
						</StyledRaceStrikeRate_percentage_value>
					</StyledRaceStrikeRate_percentage>

					{/*jockey_percentage    */}
					<StyledRaceStrikeRate_percentage>
						<StyledRaceStrikeRate_percentage_value>
							<StyledValue>{jockey_percentage}</StyledValue>
						</StyledRaceStrikeRate_percentage_value>
					</StyledRaceStrikeRate_percentage>

					{/* trainer_percentage*/}
					<StyledRaceStrikeRate_percentage>
						<StyledRaceStrikeRate_percentage_value>
							<StyledValue>{trainer_percentage}</StyledValue>
						</StyledRaceStrikeRate_percentage_value>
					</StyledRaceStrikeRate_percentage>

					{/* jockey_trainer_percentage */}
					<StyledRaceStrikeRate_percentage>
						<StyledRaceStrikeRate_percentage_value>
							<StyledValue>{jockey_trainer_percentage}</StyledValue>
						</StyledRaceStrikeRate_percentage_value>
					</StyledRaceStrikeRate_percentage>
				</StyledRaceStrikeRate_Column>
			)}
		</StyledRaceStrikeRate>
	);
};

RaceStrikeRate.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}),

	/** Extra classes */
	className: PropTypes.string,

	/** The selection status */
	selection_status: PropTypes.oneOf(['not scratched', 'scratched', 'late scratching']),

	/** Whether or not the component is being viewed in small context */
	isMobileWidth: PropTypes.bool,

	jockey_horse_percentage: PropTypes.string,

	jockey_percentage: PropTypes.string,

	trainer_percentage: PropTypes.string,

	jockey_trainer_percentage: PropTypes.string,
};

RaceStrikeRate.defaultProps = {
	size: {},
	className: null,
	selection_status: null,
	isMobileWidth: false,

	jockey_horse_percentage: null,
	jockey_percentage: null,
	trainer_percentage: null,
	jockey_trainer_percentage: null,
};

export default withNamespaces()(RaceStrikeRate);
