import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { spacings, media,} from '@tbh/ui-kit';
import { Button, List} from '@tbh/ui-kit';
import moment from 'moment';


const StyledRaceNextJumpV3SelectionCard = styled('div')(
	(props) => css`
		label: RaceNextJumpV3_selectionCard_Container;
    padding: ${spacings(props).compact}px ${spacings(props).tight}px ${spacings(props).tight}px
			4px;
		box-sizing: border-box;
		height:207px;
	`,
	(props) =>
		media(
			css`
			padding: 0 ${spacings(props).tight}px;
			`,),
);
const StyledRaceNextJumpV3Selection = styled('div')(
	(props) => css`
		label: RaceNextJumpV3_selectionCard;

		display: flex;
		flex-direction:row;
		background-color: #f7f8f8;
		height: auto;
		width: 100%;
		padding: ${spacings(props).tight}px;


	`,
	(props) =>
		media(
			css`
			 padding:  ${spacings(props).comfortable}px;
			`,),
);
const StyledRaceNextJumpV3SelectionFinal = styled('div')(
	(props) => css`
		label: RaceNextJumpV3_selectionCard;

		display: flex;
		flex-direction:row;
		background-color: #f7f8f8;
		height: auto;
		width: 100%;
		padding: ${spacings(props).tight}px;


	`,
	(props) =>
		media(
			css`
			 padding:  ${spacings(props).comfortable}px;
			`,),
);

const StyledNextJumpSelections__NextJumpSelectionsListItems = styled(List)`
	${(props) =>
		css`
			label: NextJumpSelections__NextJumpSelectionsListItems;

			margin-bottom: -4px;
			width:100%;
		`};
`;

const StyledRaceNextJumpV4SelectionFinal = styled('div')(
	(props) => css`
		label: StyledRaceNextJumpV4SelectionFinal;

		cursor: pointer;
		display: flex;
		flex-direction:row;
		background-color: #f7f8f8;
		height: auto;
		width: 100%;
		padding: ${spacings(props).tight}px;
	`,
	(props) =>
		media(
			css`
			 padding:  ${spacings(props).comfortable}px;
			`,),
);

const StyledRaceNextJumpV4Selection_go_to_race_card_Left = styled('div')(
	(props) => css`
			padding-top: ${spacings(props).tight}px;
			display: flex;
			flex-direction: row;
			flex: 0 0 25%;
			justify-content: flex-start;
			overflow: hidden;
			text-overflow: ellipsis;
			background-color: #f7f8f8;
	`,

	(props) =>
		media(
			css`
			 padding:  ${spacings(props).comfortable}px;
			`,),

);
const StyledRaceNextJumpV4Selection_go_to_race_card_right = styled('div')(
	(props) => css`
      label: StyledRaceNextJumpV4Selection_go_to_race_card_right;
			display: flex;
			flex-direction: row;
			flex: 0 0 60%;
			justify-content: space-between;
			overflow: hidden;
			text-overflow: ellipsis;
			background-color:#f7f8f8;
			padding: 5px;
			margin-left:52px;
	`,

	(props) =>
		media(
			css`
			 padding:  ${spacings(props).comfortable}px;
			`,),

);

const StyledCarouselItemV4_go_to_race_card = styled('div')(
	(props) => css`
		label: arouselItemV4_go_to_race_card ;

		align-self: center;
		padding: 0 ${spacings(props).tight}px;
		white-space: nowrap;
		line-height: 1.8;
		width: 100%;
		color: rgb(0, 0, 0);
		//text-transform: uppercase;
		text-decoration: none;
		text-align: left;
		font-weight: 700;
		margin-left: 4px;
		font-size: 13px;
		color: #414141;
	`
 );

 const StyledNextToJumpGoToRaceCard__Button = styled(Button)`
	label: StyledNextToJumpGoToRaceCard__Button;
	${(props) =>
   props.topMargin &&
   css`
			margin-top: ${spacings(props).tight}px;
		`};
`;

class NtjSelectionCard extends Component {
    static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
			height: PropTypes.number,
		}),
		/** Extra class(es) to pass through to the component */
		className: PropTypes.string,

		/** The race ID */
		id: PropTypes.number.isRequired,

        /** If race changes clear state. */
        raceId: PropTypes.number.isRequired,

          /** If meeting id changes clear state. */
        meetingId: PropTypes.number.isRequired,

         /** Array of objects that contain all the selection details */
        selections: PropTypes.arrayOf(
            PropTypes.shape({
                /**
                 * Selections will contain all the selection details but besides 'id' they are not needed to be defined here.
                 * They are defined in the RaceSelectionsListItem
                 */
                id: PropTypes.number.isRequired,

                /** The list of prices for the selection */
                prices: PropTypes.arrayOf(
                    PropTypes.shape({
                        /** The current price for a win bet with this product */
                        win_odds: PropTypes.number,

                        /** The current price for a place bet */
                        place_odds: PropTypes.number,

                        /** The current price for a margin bet */
                        margin_odds: PropTypes.number,

                        /** The code for this product with this product */
                        product_code: PropTypes.string.isRequired,

                        /** The product type, if it is a special product */
                        product_type: PropTypes.string,

                        /** The product id (NOTE: this is not a unique id between products) */
                        product_id: PropTypes.number.isRequired,
                    }),
                ),
            }),
        ).isRequired,

        /** The currently selected bet type */
		betType: PropTypes.string,

		/** Action to perform when the race item is clicked on */
		action: PropTypes.func.isRequired,

		/** Action to add the selection to the single bet prompt */
		clickSingle: PropTypes.func.isRequired,
      	/** Add single selection when clicking on odds */
      	formatAndAddSingleSelectionToBetPrompt: PropTypes.func.isRequired,

        /** Handle when a selection is clicked on to make a bet */
        onClickSingle: PropTypes.func,
        /** Handle when a selection is added to multi */
         onClickMulti: PropTypes.func,
         /** Handle when an exotic selection is clicked on */
         onClickExotic: PropTypes.func,
         /** Single bets place in this race */
		 bets: PropTypes.array,
		 
		 type: PropTypes.string.isRequired,	

    };
    static defaultProps = {
        className: '',
		betType: '',
		size: null,

        bets: [],
        onClickSingle: () => {},
        onClickMulti: () => {},
		onClickExotic: () => {},
		clickSingle: () => {},
    };
    constructor(props){
        super(props);
        {
            this.state = {
                // Race details for prop comparisons
                njRaceId: props.raceId,
                meetingId: props.meetingId,
            };
        }
    }
	/**
	 * Handles the single bet clicks
	 *
	 * @param selectionId
	 * @param productId
	 * @param betType
	 */
	handleSingle = (selectionId, productId, betType) => {
	  const {formatAndAddSingleSelectionToBetPrompt} = this.props;
		//this.props.clickSingle(selectionId, productId, betType);
	};
    render() {
        const {
          t,
          size,
          className,
          raceId,
          meetingId,
          betType,
          id,
          action,
          bets,
          onClickSingle,
          onClickMulti,
          onClickExotic,
          type,
          start_date,
          Metting_name,
          Race_Number,
          selections,
        } = this.props;
		const handleClick = (e) => {
			e.preventDefault();
			const url = 'racing-v3/' + type.toLowerCase() + '/' + moment(start_date).format().split('T')[0] + '/' + Metting_name.replace(/\s/g, '').toLowerCase() + '/' + 'race-' + Race_Number + '-' + raceId + '-' + meetingId;
			window.open(url, '_self');
		};
        return (
	<StyledRaceNextJumpV3SelectionCard>
		
		<StyledRaceNextJumpV4SelectionFinal>
			<StyledRaceNextJumpV4Selection_go_to_race_card_right>
				<StyledNextToJumpGoToRaceCard__Button action={handleClick} type="secondary">
					{t('NextToJumpRaceDetails__View')}
				</StyledNextToJumpGoToRaceCard__Button>
			</StyledRaceNextJumpV4Selection_go_to_race_card_right>
		</StyledRaceNextJumpV4SelectionFinal>
	</StyledRaceNextJumpV3SelectionCard>
        );
    }
}
export default withNamespaces()(NtjSelectionCard);
