import {
	BET_TYPE_QUINELLA,
	BET_TYPE_EXACTA,
	BET_TYPE_TRIFECTA,
	BET_TYPE_FIRSTFOUR,
	BET_TYPE_QUADDIE,
	BET_TYPE_DAILY_DOUBLE,
	BET_TYPE_STRAIGHT_EIGHT,
} from '../store/entities/constants/BetConstants';
import { RACING_BET_TYPE_RUNNING_DOUBLE, RACING_BET_TYPE_RUNNING_TREBLE } from './constants/Racing';

export function parsePositions(input) {
	const parts = input.split(' / ');
	return parts.flatMap((part, index) => {
		const numbers = part.split(', ').map(Number);
		return numbers.map((number) => {
			return {
				position: index + 1,
				number: number,
			};
		});
	});
}

/**
 * Basic factorial function
 *
 * @param n
 * @returns {number}
 */
const factorial = (n) => {
	if (n === 0) {
		return 1;
	}

	return n * factorial(n - 1);
};

/**
 * Return the possible combinations for Boxed Quinella
 *
 * @param selections
 * @param positions
 * @returns {number}
 */
const quinellaFlexi = (selections, positions = 2) => {
	const c = selections.filter((selection) => selection.position == 0);

	if (c.length > 0) {
		let combinations = 0;
		for (let i = 0; i < c.length; i++) {
			for (let j = i + 1; j < c.length; j++) {
				combinations++;
			}
		}
		return combinations;
	}

	const a = selections.filter((selection) => selection.position == 1);
	const b = selections.filter((selection) => selection.position == 2);
	if (b.length === 0) {
		let combinations = 0;
		for (let i = 0; i < a.length; i++) {
			for (let j = i + 1; j < a.length; j++) {
				combinations++;
			}
		}
		return combinations;
	}

	const combinations = [];
	// // Remove repeated selections
	for (let i = 0; i < a.length; i++) {
		for (let j = 0; j < b.length; j++) {
			if (a[i].number != b[j].number && !combinations.find((c) => c.includes(a[i].number) && c.includes(b[j].number))) {
				combinations.push(`${a[i].number},${b[j].number}`);
			}
		}
	}

	return combinations.length;
};

/**
 * Return the number of combinations for a Boxed Exotic
 *
 * @param selections
 * @param positions
 * @returns {number}
 */
const exoticFlexiBoxed = (selections = 0, positions = 2) => {
	if (selections < positions) {
		return 0;
	}

	return factorial(selections) / factorial(selections - positions);
};

/**
 * Return the number of combinations for a Boxed Exacta
 *
 * @param selections
 * @returns {number}
 */
const exactaFlexiBoxed = (selections) => {
	return exoticFlexiBoxed(selections, 2);
};

/**
 * Return the number of combinations for a Boxed Trifecta
 *
 * @param selections
 * @returns {number}
 */
const trifectaFlexiBoxed = (selections) => {
	return exoticFlexiBoxed(selections, 3);
};

/**
 * Return the number of combinations for a Boxed First Four
 *
 * @param selections
 * @returns {number}
 */
const firstfourFlexiBoxed = (selections) => {
	return exoticFlexiBoxed(selections, 4);
};

/**
 * Return the number of combinations for a Boxed Straight Eight
 *
 * @param selections
 * @returns {number}
 */
const straighteightFlexiBoxed = (selections) => {
	return exoticFlexiBoxed(selections, 8);
};

/**
 * Return the number of combinations for an Exacta
 *
 * @param selections
 * @returns {number}
 */
const exactaFlexi = (selections) => {
	const a = selections.filter((selection) => selection.position == 1);
	const b = selections.filter((selection) => selection.position == 2);

	let combinations = 0;
	for (let i of a) {
		for (let j of b) {
			if (i.id !== j.id) {
				combinations++;
			}
		}
	}

	return combinations;
};

/**
 * Return the number of combinations for a Trifecta
 *
 * @param selections
 * @returns {number}
 */
const trifectaFlexi = (selections) => {
	const a = selections.filter((selection) => selection.position == 1);
	const b = selections.filter((selection) => selection.position == 2);
	const c = selections.filter((selection) => selection.position == 3);

	let combinations = 0;
	for (let i of a) {
		for (let j of b) {
			for (let k of c) {
				if (i.id !== j.id && j.id !== k.id && k.id !== i.id) {
					combinations++;
				}
			}
		}
	}

	return combinations;
};

/**
 * Return the number of combinations for a First Four
 *
 * @param selections
 * @returns {number}
 */
const firstfourFlexi = (selections) => {
	const a = selections.filter((selection) => selection.position == 1);
	const b = selections.filter((selection) => selection.position == 2);
	const c = selections.filter((selection) => selection.position == 3);
	const d = selections.filter((selection) => selection.position == 4);

	let combinations = 0;
	for (let i of a) {
		for (let j of b) {
			for (let k of c) {
				for (let h of d) {
					if (i.id !== j.id && i.id !== k.id && i.id !== h.id && j.id !== k.id && j.id !== h.id && k.id !== h.id) {
						combinations++;
					}
				}
			}
		}
	}

	return combinations;
};

/**
 * Return the number of combinations for a Straight Eight
 *
 * @param selections
 * @returns {number}
 */
const straighteightFlexi = (selections) => {
	const a = selections.filter((selection) => selection.position == 1);
	const b = selections.filter((selection) => selection.position == 2);
	const c = selections.filter((selection) => selection.position == 3);
	const d = selections.filter((selection) => selection.position == 4);
	const e = selections.filter((selection) => selection.position == 5);
	const f = selections.filter((selection) => selection.position == 6);
	const g = selections.filter((selection) => selection.position == 7);
	const h = selections.filter((selection) => selection.position == 8);

	let combinations = 0;
	for (let i of a) {
		for (let j of b) {
			for (let k of c) {
				for (let l of d) {
					for (let m of e) {
						for (let n of f) {
							for (let o of g) {
								for (let p of h) {
									if (
										i.id !== j.id &&
										i.id !== k.id &&
										i.id !== l.id &&
										i.id !== m.id &&
										i.id !== n.id &&
										i.id !== o.id &&
										i.id !== p.id &&
										j.id !== k.id &&
										j.id !== l.id &&
										j.id !== m.id &&
										j.id !== n.id &&
										j.id !== o.id &&
										j.id !== p.id &&
										k.id !== l.id &&
										k.id !== m.id &&
										k.id !== n.id &&
										k.id !== o.id &&
										k.id !== p.id &&
										l.id !== m.id &&
										l.id !== n.id &&
										l.id !== o.id &&
										l.id !== p.id &&
										m.id !== n.id &&
										m.id !== o.id &&
										m.id !== p.id &&
										n.id !== o.id &&
										n.id !== p.id &&
										o.id !== p.id
									) {
										combinations++;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	return combinations;
};

/**
 * Return the possible combinations for Quaddie
 *
 * @param selections
 * @returns {number}
 */
const quaddieFlexi = (selections) => {
	let selectionPerRace = {};

	selections.forEach((selection) => {
		if (!selectionPerRace[selection.race_number]) {
			selectionPerRace[selection.race_number] = [];
		}
		selectionPerRace[selection.race_number].push(selection);
	});

	return Object.keys(selectionPerRace).reduce((acc, key) => {
		return acc * selectionPerRace[key].length;
	}, 1);
};

const trebleFlexi = (selections) => {
	return trifectaFlexi(selections);
};

/**
 * Return the possible combinations for Daily Double
 *
 * @param selections
 * @returns {number}
 */
const dailyDoubleFlexi = (selections) => {
	return exactaFlexi(selections);
};

/**
 * Determine how we want to calculate the combination based on the exotic type
 *
 * @param exoticType
 * @param selections
 * @param isBoxed
 * @returns {number}
 */
const calculateCombinations = (exoticType, selections = [], isBoxed = false) => {
	if (exoticType === BET_TYPE_QUINELLA) {
		return quinellaFlexi(selections);
	} else if (isBoxed) {
		switch (exoticType) {
			case BET_TYPE_EXACTA:
				return exactaFlexiBoxed(selections.length);
			case BET_TYPE_TRIFECTA:
				return trifectaFlexiBoxed(selections.length);
			case BET_TYPE_FIRSTFOUR:
				return firstfourFlexiBoxed(selections.length);
			case BET_TYPE_STRAIGHT_EIGHT:
				return straighteightFlexiBoxed(selections.length);
			default:
				return 0;
		}
	} else {
		switch (exoticType) {
			case BET_TYPE_EXACTA:
				return exactaFlexi(selections);
			case BET_TYPE_TRIFECTA:
				return trifectaFlexi(selections);
			case BET_TYPE_FIRSTFOUR:
				return firstfourFlexi(selections);
			case BET_TYPE_STRAIGHT_EIGHT:
				return straighteightFlexi(selections);
			case BET_TYPE_QUADDIE:
				return quaddieFlexi(selections);
			case BET_TYPE_DAILY_DOUBLE:
				return quaddieFlexi(selections);
			case RACING_BET_TYPE_RUNNING_DOUBLE:
				return quaddieFlexi(selections);
			case RACING_BET_TYPE_RUNNING_TREBLE:
				return quaddieFlexi(selections);
			default:
				return 0;
		}
	}
};

/**
 * Validate all race legs have been selected for multi-leg exotic bets
 * Currently used for quaddie and daily double bet types
 *
 * @param selections
 * @param betType
 * @returns {boolean}
 */
const validateMultiLegSelections = (selections, betType) => {
	const leg1 = selections.some((selection) => selection.position === '1');
	const leg2 = selections.some((selection) => selection.position === '2');
	const leg3 = selections.some((selection) => selection.position === '3');
	const leg4 = selections.some((selection) => selection.position === '4');

	const quaddies = leg1 && leg2 && leg3 && leg4;
	const dailyDouble = leg1 && leg2;

	if (betType === 'quaddie') {
		return quaddies || false;
	}

	if (betType === 'dailydouble') {
		return dailyDouble || false;
	}

	return false;
};
// example: "2 / 2, 3 / 1, 2, 3"
const legsByResultString = (resultString) => {
	if (!resultString) {
		return [];
	}

	const legs = resultString.split('/').map((leg) => leg.trim());
	const legsByResult = legs.map((leg) => leg.split(',').map((selection) => selection.trim()));
	return legsByResult;
};

export {
	calculateCombinations,
	validateMultiLegSelections,
	quinellaFlexi,
	exactaFlexi,
	exoticFlexiBoxed,
	exactaFlexiBoxed,
	trifectaFlexiBoxed,
	firstfourFlexiBoxed,
	straighteightFlexiBoxed,
	trifectaFlexi,
	firstfourFlexi,
	quaddieFlexi,
	dailyDoubleFlexi,
	straighteightFlexi,
	legsByResultString,
};
