// MAIN COLORS
export const COLORS = {
	white: '#FFFFFF',
	gray: {
		50: '#f9fafb',
		100: '#f3f4f6',
		200: '#e5e7eb',
		300: '#d1d5db',
		400: '#9ca3af',
		500: '#6b7280',
		600: '#4b5563',
		700: '#374151',
		800: '#1f2937',
		900: '#111827',
		950: '#030712',
	},
	red: {
		300: '#BB181B',
		400: '#A5161A',
		600: '#660809'
	},
	status: {
		info: '#219EBC',
		warning: '#FB8500',
		danger: '#BB181B',
		success: '#00A216'
	}
};

// DEFAULT COLORS
export const DEFAULT_COLORS = {
	df_primary_color: '#006EB0',
	df_primary_hover_color: '#387BA5',
	df_primary_active_color: '#004C8B',
	df_secondary_color: '#660809',
	df_secondary_hover_color: '#BB181B',
	df_secondary_active_color: '#A5161A',
	df_tertiary_color: '#161A1D',
	df_tertiary_hover_color: '#2A2E35',
	df_tertiary_active_color: '#212429',
};

// DEFAULT STATUS COLORS
export const STATUS_COLORS = {
	closed_bg_color: '#C72128',
	closed_text_color: '#ffffff',
	paying_bg_color: '#32BE60',
	paying_text_color: '#FFFFFF',
	selling_bg_color: '#ffffff',
	selling_text_color: '#FFFFFF',
	abandoned_bg_color: '#C0C0C0',
	abandoned_text_color: '#FFFFFF',
	parading_bg_color: '#5D97FB',
	parading_text_color: '#FFFFFF',
	paid_bg_color: '#5D97FB',
	paid_text_color: '#FFFFFF',
	interim_bg_color: '#32BE60',
	interim_text_color: '#FFFFFF',
	at_the_post_bg_color: '#ECBE00',
	at_the_post_text_color: '#FFFFFF',
	going_behind_bg_color: '#ECBE00',
	going_behind_text_color: '#FFFFFF',
	deleted_bg_color: '#C0C0C0',
	deleted_text_color: '#FFFFFF',
};

// FONT WEIGHTS
export const WEIGHTS = {
	normal: 400,
	medium: 500,
	bold: 600,
	exBold: 700,
};

// TYPE_SCALE - FONT-SIZES
export const TYPE_SCALE = {
	header1: '2.5rem',
	header2: '2rem',
	header3: '1.5rem',
	header4: '1.25rem',
	header5: '1.1rem',
	header6: '1rem',
	paragraph: '0.75rem',
	helpText: '0.625rem',
	copyrightText: '0.5rem',
};

// APP MAIN FONT FAMILY
export const FAMILYS = {
};

// SCREEN SIZES
export const BREAKPOINTS = {
	tabletMin: 550,
	laptopMin: 1100,
	desktopMin: 1500,
};

// MEDIA QUERIES
export const MEDIA_QUERIES = {
	tabletAndUp: `(min-width: ${BREAKPOINTS.tabletMin / 16}rem)`,
	laptopAndUp: `(min-width: ${BREAKPOINTS.laptopMin / 16}rem)`,
	desktopAndUp: `(min-width: ${BREAKPOINTS.desktopMin / 16}rem)`,
	tabletOnly: `
	  (min-width: ${BREAKPOINTS.tabletMin / 16}rem) and
	  (max-width: ${(BREAKPOINTS.laptopMin - 1) / 16}rem)`,
};