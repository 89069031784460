import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings } from '@tbh/ui-kit';
import { withNamespaces } from 'react-i18next';

import { Image, Icon, Link, Text, PlotElements, Tabs, TabsItem } from '@tbh/ui-kit';

const StyledErrorBoundaryDefault = styled('div')(
	(props) => css`
		label: ErrorBoundaryDefault;

		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: ${spacings(props).comfortable}px ${spacings(props).cozy}px;
		text-align: center;
		background: #b5b1b1;
	`,
);

const StyledErrorBoundaryDefault__Message = styled(Text)(
	(props) => css`
		label: ErrorBoundaryDefault__Spacious;

		margin-top: ${spacings(props).spacious}px;
		

	`,
);
const StyledErrorBoundaryDefault__ActionGroup = StyledErrorBoundaryDefault__Message.withComponent(Tabs);

const cssErrorBoundaryDefault__Logo = css`
	label: ErrorBoundaryDefault__Logo;

	align-self: center;
	width: 200px;
	min-height: 75px;
	cursor: pointer;
`;

const ErrorBoundaryDefault = (props) => {
	const { className, buttonAction, routeTo, routeToHome, navigationItems, isMobileWidth, brandLogos, t } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<StyledErrorBoundaryDefault className={componentClasses}>
			{brandLogos.main && (
				<Link className={cx(cssErrorBoundaryDefault__Logo)} action={routeToHome}>
					<Image width={200} src={brandLogos.main} alt="Logo" />
				</Link>
			)}
			<StyledErrorBoundaryDefault__Message align="center" size="2">
				{t('ErrorBoundaryDefault__Message')}
			</StyledErrorBoundaryDefault__Message>
			<StyledErrorBoundaryDefault__ActionGroup
				emphasised
				isColumn={isMobileWidth}
				justifyAuto
				wrappingBorder
				padding={Tabs.paddings.SPACING_COMPACT}
			>
				<TabsItem active pointer action={buttonAction}>
					<PlotElements>{t('TryAgain')}</PlotElements>
				</TabsItem>
				{navigationItems.map((navigationItem) => {
					return (
						<TabsItem key={navigationItem.id} action={routeTo} data={navigationItem.route}>
							<PlotElements>
								<Icon icon={navigationItem.icon} />
								&nbsp;
								<span>{navigationItem.title}</span>
							</PlotElements>
						</TabsItem>
					);
				})}
			</StyledErrorBoundaryDefault__ActionGroup>
		</StyledErrorBoundaryDefault>
	);
};

ErrorBoundaryDefault.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Default button action handler */
	buttonAction: PropTypes.func.isRequired,

	/** Link handler for linking elsewhere */
	routeTo: PropTypes.func.isRequired,

	/** Link Handler for the home page */
	routeToHome: PropTypes.func.isRequired,

	/** List of available icons to use */
	brandLogos: PropTypes.object.isRequired,

	/** List of navigation items to show */
	navigationItems: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			route: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			icon: PropTypes.string,
		}),
	),

	/** Whether or not the component is being viewed at mobile width or not */
	isMobileWidth: PropTypes.bool,

	/** Extra classes */
	className: PropTypes.string,
};

ErrorBoundaryDefault.defaultProps = {
	navigationItems: [],
	isMobileWidth: false,
	className: '',
};

export default withNamespaces()(ErrorBoundaryDefault);
