/**
 * Libraries
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import cx from 'classnames/bind';

import {
	formatAndAddSportSelectionToBetPrompt,
	resetBetPrompt,
	resetBetPromptTitle,
} from '../../../store/betPrompt/betPromptActions';
import { addSportSelectionToBetSlip } from '../../../common/actions/multiActions';
import {
	fetchFixedPriceRollup,
	getAuthenticatedUser,
} from '../../../store/application/applicationSelectors';
import { triggerEventMessage } from '../../../common/actions/widgetActions';

/**
 * Containers
 */
import BetPromptNJContainer from '../../../containers/Betting/BetPromptContainer/BetPromptNJContainer';

/**
 * Components
 */
import DepositContainer from '../../../containers/Deposit/DepositContainer/DepositContainer';
import DefaultGRSEventItem from '../../../components/features/Sports/EventItem/DefaultGRSEventItem/DefaultGRSEventItem';
import ModalHeader from '../../../components/features/Application/ModalHeader/ModalHeader';
import BrandContactPhone from '../../../components/ui-components/BrandContactPhone/BrandContactPhone';
import Modal from '../../../components/controllers/Modal/Modal';
import ModalFooter from '../../../components/features/Application/ModalFooter/ModalFooter';

import { isBetReferred } from '../../../containers/Betting/bettingMemoizedSelectors';
import { getBets } from '../../../pages/Sports/SportsHome/sportsHomeSelectors';

import { getNextToJumpEventById } from '../../../store/entities/selectors/EventSelectors';
import { GOAT_PRODUCT_TYPE_BOOST, GOAT_PRODUCT_TYPE_STANDARD } from '../../../common/constants/GoatProducts';
import { PRODUCT_TYPE_STANDARD } from '../../../store/entities/constants/BetConstants';
/**
 * Schemas
 */
import {
	buildRouteForSportEvent,
} from '../../../routes/Sports';
import {
	selectEvent,
} from '../../../store/sportsHome/sportsHomeActions';

class NJSportsCardContainer extends Component {
    

    static defaultProps = {
		eventContext: '',
		betReferred: false,
		authenticatedUser: null,
		onGoToEvent: null,
	};

	state = {
		/** list of events ids with loading mask flag for market groups. */
		eventsLoadingMarkets: {},
		/** Flag whether the bet prompt container is showing */
		showBetPrompt: false,
		/** Flag whether the Quick Deposit container is showing */
		showQuickDepositPrompt: false,
	};
    static PropTypes = {
		/** Translation func provided by withNamespaces HOC */
	   t: PropTypes.func.isRequired,

	   /** The size of the component - used for media query logic */
	   size: PropTypes.shape({
		   width: PropTypes.number,
		   height: PropTypes.number,
	   }).isRequired,
	   
	   eventId: PropTypes.number.isRequired,

		/** De-normalized event and details */
	   event: PropTypes.shape({
		   event_id: PropTypes.number.isRequired,
		   competition_id: PropTypes.number,
		   base_competition_id: PropTypes.number,

		   grsevents: PropTypes.arrayOf(
			   PropTypes.shape({
				   id: PropTypes.number.isRequired,
				   name: PropTypes.string.isRequired,
				   market_count: PropTypes.number,
				   markets: PropTypes.array,
				   start_date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
				   /** The products available for the event */
				   products: PropTypes.arrayOf(
					   PropTypes.shape({
						   product_id: PropTypes.number.isRequired,
						   product_type: PropTypes.string.isRequired,
					   }),
				   ).isRequired,
				   }),
		   ).isRequired,
		   
		   sport_id: PropTypes.number,
		   event_name: PropTypes.string,
		   competition_name: PropTypes.string,
		   base_competition_name: PropTypes.string,
		   sport_name: PropTypes.string,
		   start_time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
		   to_go: PropTypes.string,

	   }).isRequired,

	   /** Load the selected event */
	   selectEvent: PropTypes.func.isRequired,
	   
	   /** Filters array of events to show on the page */
	   events: PropTypes.arrayOf(
		   PropTypes.shape({
			   id: PropTypes.number.isRequired,
			   name: PropTypes.string.isRequired,
			   market_count: PropTypes.number,
			   markets: PropTypes.array,
			   start_date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
			   /** The products available for the event */
			   products: PropTypes.arrayOf(
				   PropTypes.shape({
					   product_id: PropTypes.number.isRequired,
					   product_type: PropTypes.string.isRequired,
				   }),
			   ).isRequired,
		   }),
	   ).isRequired,

	   /** Context name for prefixing custom events to be fired through middleware */
	   eventContext: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
	   
	   /** Navigate to the event page */
	   onGoToEvent: PropTypes.func,

	   // /** Add sport selection to the multi bet slip */
	   addSportSelectionToMulti: PropTypes.func.isRequired,

	   /** Attempt to fetch the logged in users active bets */
	   fetchActiveBets: PropTypes.func.isRequired,
	   
	   /** Reset the bet prompt title back to it's default value */
	   resetBetPromptTitle: PropTypes.func.isRequired,

	   /** Actions for bet prompt */
	   resetBetPrompt: PropTypes.func.isRequired,
	   formatAndAddSportSelectionToBetPrompt: PropTypes.func.isRequired,

	   /** Action to fetch the rolled up price of a boosted fixed price */
	   fetchFixedPriceRollup: PropTypes.func.isRequired,

	   /** Action to fetch the amount of rolls for a price */
	   fetchRollTablePrice: PropTypes.func.isRequired,
	   /** If a bet has been referred to a trader for review */
	   betReferred: PropTypes.bool,
	   
	   /** Watch for authenticatedUser to fetch their bets. */
	   authenticatedUser: PropTypes.number,

	   /** Currently logged in users sport bets */
	   bets: PropTypes.arrayOf(
		   PropTypes.shape({
			   id: PropTypes.number.isRequired,
			   amount: PropTypes.number.isRequired,
			   odds: PropTypes.number.isRequired,
			   bet_type: PropTypes.string.isRequired,
			   bet_selections: PropTypes.array.isRequired,
		   }),
	   ).isRequired,

	   /** Requirements from the bet prompt slice of state */
	   betPrompt: PropTypes.shape({
		   title: PropTypes.string.isRequired,
	   }).isRequired,


	   /** The currently logged in User */
	   user: PropTypes.shape({
		   /** The users' username **/
		   username: PropTypes.string,

		   /** The user's unique laravel session ID */
		   user_token: PropTypes.string,
	   }).isRequired,


   };

	/**
	 * Return the event matched to an event ID
	 *
	 * @param eventId
	 */
	fetchEventById = (eventId) => {
		
		return getNextToJumpEventById(this.props.event.grsevents, eventId);
	};

	/**
	 * Return the product that matches a specific product_type
	 *
	 * @param event
	 * @param productType
	 */
	fetchProductByType = (event, productType = GOAT_PRODUCT_TYPE_STANDARD) => {
		return event.products.find((product) => {
			return product.product_type === productType;
		});
	};

	/**
	 * Return the product that matches a specific product_id
	 *
	 * @param event
	 * @param productID
	 */
	fetchProductByID = (event, productID) => {
		return event.products.find((product) => {
			return product.product_id === productID;
		});
	};

	/**
	 * The onClick on the Carousel item returns the race id passed in.
	 * Find that id in the list of items then call the onClick handler
	 *  go to  event
	 * @param eventId
	 */
	handleClick = (eventId) => {
		const event = this.props.event.find((item) => item.event_id === eventId);
		this.props.onGoToEvent(event.competition_id, event.event_id);

	};

	/**
	 * Call action to navigate to Event
	 *
	 * @param eventId
	 */
	handleNavigateToEvent = (eventId) => {
		const event = this.props.events.find((ev) => ev.event_id === eventId);
		
		return this.props.navigateToEvent(event.competition_id, event.event_id);
	};


	/**
	 * Handle adding a sport selection to the bet prompt
	 *
	 * @param eventId
	 * @param selection
	 * @param market
	 * @param productId
	 */
	handleAddToSingle = (eventId, selection, market, productId = null) => {
		
		const event = this.fetchEventById(eventId);
		
		// If no productId is specified, fetch the default product
		let product;
		if (!productId) {
			product = this.fetchProductByType(event); 
			productId = product.product_id;
		} else {
			product = this.fetchProductByID(event, productId);
		}
 
		// Check if the price has been bumped, and send the rolled price
		const selectionOdds = selection.price;
		const isPriceBumped = product.product_type === GOAT_PRODUCT_TYPE_BOOST;
		const newOdds = isPriceBumped ? this.props.fetchFixedPriceRollup(false, selectionOdds) : selectionOdds;
		
		this.props.resetBetPrompt();
		this.props
			.formatAndAddSportSelectionToBetPrompt(
				selection.id,
				market.id,
				newOdds,
				product.product_id || productId,
				product.product_type || PRODUCT_TYPE_STANDARD,
			)
			.then(() => {
				triggerEventMessage(this.props.eventContext, 'selectionAddedToBetPrompt', true, this.props.user);
			});

		this.handleShowBetPrompt();
	};


	/**
	 * Handle adding a sport selection to the multi slip
	 *
	 * @param eventId
	 * @param selection
	 * @param market
	 * @param productId
	 */
	handleAddToMulti = (eventId, selection, market, productId) => {
		const event = this.fetchEventById(eventId);
		
		// If no productId is specified, fetch the default product
		let product;
		if (!productId) {
			product = this.fetchProductByType(event);
			productId = product.product_id;
		} else {
			product = this.fetchProductByID(event, productId);
		}

		// console.log(product.product_type);
		this.props
			.addSportSelectionToMulti(
				selection.id,
				market.id,
				selection.price,
				product.product_id || productId,
				product.product_type || PRODUCT_TYPE_STANDARD,
			)
			.then(() => {
				triggerEventMessage(this.props.eventContext, 'selectionAddedToBetSlip');
			});
	};

	/**
	 * Get the bet prompt modal
	 *
	 * @returns {boolean|XML}
	 */
	buildBetPromptModal() {
		// Setup the modal configuration for the bet prompt
		const MODAL_CONFIG = {
			mobileWidth: true,
			noBorder: true,
			// preventBackdropClose: this.betSlipDetails.betReferred,
			preventBackdropClose: true,
			hideClose: true,

			beforeModalClose: () => {
				if (this.props.betReferred) {
					return false;
				} else {
					return this.setState({
						showBetPrompt: false,
					});
				}
			},
		};

		return (
			this.state.showBetPrompt && (
				<Modal
					open={this.state.showBetPrompt}
					componentKey="event-controller__single-bet-prompt"
					store={App.store}
					config={MODAL_CONFIG}
				>
					<div>
						<ModalHeader
							onClose={MODAL_CONFIG.beforeModalClose}
							title={this.props.betPrompt.title}
							aside={<BrandContactPhone />}
						/>
						<BetPromptNJContainer
							events={this.props.event.grsevents}
							eventContext={this.props.eventContext}
							handleCancel={this.handleHideBetPrompt}
							handleQuickDeposit={this.handleShowQuickDepositPrompt}
						/>
						<ModalFooter />
					</div>
				</Modal>
			)
		);
	}

	/**
	 * Get the quick deposit prompt
	 */
	buildQuickDepositModal() {
		// Setup the modal configuration for the bet prompt
		const MODAL_CONFIG = {
			mobileWidth: true,
			noBorder: true,
			preventBackdropClose: true,
			hideClose: true,
		};

		return (
			this.state.showQuickDepositPrompt && (
				<Modal
					open={this.state.showQuickDepositPrompt}
					componentKey="event-controller__quick-deposit"
					store={App.store}
					config={MODAL_CONFIG}
				>
					<div>
						<ModalHeader
							onClose={this.handleHideQuickDepositPrompt}
							title={this.props.betPrompt.title}
							aside={<BrandContactPhone />}
						/>
						<DepositContainer
							isQuickDeposit
							trackingCategory="Quick Deposit"
							handleClose={this.handleHideQuickDepositPrompt}
						/>
						<ModalFooter />
					</div>
				</Modal>
			)
		);
	}

	/**
	 * Show the bet prompt
	 */
	handleShowBetPrompt = () => {
		this.setState({
			showBetPrompt: true,
		});
	};

	/**
	 * Hide the bet prompt
	 */
	handleHideBetPrompt = () => {
		this.setState({
			showBetPrompt: false,
		});
	};

	render(){
		const {
			size,
			event,
			className,
		} = this.props;

		const containerClasses = cx({
			[className]: className,
		});

		const isSingleEvent =  1;
		const route = isSingleEvent ? null : buildRouteForSportEvent(event);
		
		return (
			<div className={containerClasses}>
				{this.buildBetPromptModal()}
				<DefaultGRSEventItem
					key={'NJShb-event-' + event.event_id}
					route={route}
					event={event.grsevents[0]}
					eventId={event.event_id}
					eventStart={moment(event.start_time)}
					eventName={event.event_name}
					sportId={event.sport_id}
					sportName={event.sport_name}
					competitionName={event.competition_name}
					markets={Array.isArray(event.grsevents[0].markets) ? event.grsevents[0].markets : [] }
					addToSingle={this.handleAddToSingle}
					addToMulti={this.handleAddToMulti}
					fetchFixedPriceRollup={this.props.fetchFixedPriceRollup}
					onEventClick={!isSingleEvent ? this.handleNavigateToEvent : null} //
					action={this.handleClick}
					size={size}
				/>	
			</div>
		);
	}
}
const  mapStateToProps = (state) => {
    const betReferred = isBetReferred(state);
	const user = getAuthenticatedUser(state);
    return {
        user,
        betReferred: betReferred,
		bets: getBets(state.entities),
		betPrompt: state.betPrompt,
		authenticatedUser: state.application.authenticatedUser,
    };
};
const mapDispatchToProps = (dispatch) => ({
    formatAndAddSportSelectionToBetPrompt: (selectionId, marketId, odds, productId, productType) =>
		dispatch(formatAndAddSportSelectionToBetPrompt(selectionId, marketId, odds, productId, productType)),
	addSportSelectionToMulti: (selectionId, marketId, odds, productId, productType) =>
		dispatch(addSportSelectionToBetSlip(selectionId, marketId, odds, productId, productType, true)),
	resetBetPrompt: () => dispatch(resetBetPrompt()),	
	resetBetPromptTitle: () => dispatch(resetBetPromptTitle()),
	fetchFixedPriceRollup: (isRacing, price) => dispatch(fetchFixedPriceRollup(isRacing, price)),

	navigateToEvent: (competitionId, eventId) => dispatch(selectEvent(competitionId, eventId)),
	selectEvent: (competitionId, eventId) => dispatch(selectEvent(competitionId, eventId)),
});

export default withNamespaces()(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(NJSportsCardContainer),
);




