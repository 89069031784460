import moment from 'moment';
import {SERVER_DATE_FORMAT} from '../common/constants/DateFormats';
import { BETBUILDER_BASE_URL, SELECTED_DATE_FORMAT} from '../pages/BetBuilder/BetBuilderConstants';

/**
 * Builds route to a betbuilder page.
 * this is simple
 * @returns {String}
 */
export const buildRouteBetbuilder = () => {
    return `/${BETBUILDER_BASE_URL}`;
};


export const buildRouteBetbuilderWithDate = (date) => {
	const datetime = moment.parseZone(date, SELECTED_DATE_FORMAT);
	let route = `/${BETBUILDER_BASE_URL}`;

	if (datetime.isValid()) {
		route += `/${datetime.format(SERVER_DATE_FORMAT)}`;
	}

	return route;
};
/**
 *
 * @param type
 * @param meetingName
 * @param raceNumber
 * @param raceId
 * @param meetingId
 * @return {string}
 */
export const buildBetBuilderRouteToMeeting = (
    // {type: type, start_date: date, id: meetingId, name: meetingName, } = {},
    date,
    {id: meetingId} = {},
  ) => {
    const datetime = !date ? moment.parseZone(new Date, SELECTED_DATE_FORMAT) : moment.parseZone(date, SELECTED_DATE_FORMAT);
    let route = `/${BETBUILDER_BASE_URL}`;
  
    // If no Id route to home
    if (!meetingId) {
      return route;
    }


  if (datetime.isValid()) {
    route += `/${datetime.format(SERVER_DATE_FORMAT)}`;
  }

  if (meetingId) {
    route += `/${meetingId}`;
  } 

  
  //  route += `/${meetingId}`;
  
    return route;
  };



  export const buildBetBuilderRouteFromState = (state) => {
    const {betBuilderHome, entities} = state;
    const {selectedMeeting, selectedDate} = betBuilderHome;
  
    const datetime = moment.parseZone(selectedDate, SELECTED_DATE_FORMAT);
    
    // 'Or' includes alternative cases covered by called function
    if (selectedDate && selectedMeeting) {
  
      const {meetings} = entities;
      return buildBetBuilderRouteToMeeting(datetime,meetings[selectedMeeting]);
  
    } else if(selectedDate) {
      // If no meeting or race but there is a date, route to date
      return buildRouteBetbuilderWithDate(selectedDate);
    }
    // default case racing v3 with today date
    return `/${BETBUILDER_BASE_URL}`;
  };
  